import { forwardRef, useEffect, useReducer, useRef, useState } from "react";
import { TPI18N } from "@/services/I18nService";
import { useAppConctextFormDesigner } from "./ContextFormDesignerAdmin";
import {
    AdditionalDataCaseViewModel,
    AdditionalDataViewModel,
} from "@/models/AdditionalData/AdditionalDataModels";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import {
    ChildContainerStyle,
    ChildStyle,
    ContainerHeaderStyle,
    ContainerInputSelectStyle,
    ContainerPanelStyle,
    ContainerPreviewFormStyle,
    ContainerTextBox,
    FormPreviewStyle,
    IcoAddStyle,
    ItemHeaderStyle,
    ItemSelect,
    RightRowContainerStyle,
    RowPreviewStyle,
    SelectStyle,
} from "./StyleFromDesigner";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ComplementsRenderTP, TPKeyValue } from "@/helpers/TPKeyValue";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPRenderAdditionalDataSwitch from "@/components/TPAdditionalData/TPRenderAdditionalDataSwitch";
import {
    FormDesignerRowModel,
    FormesignerInputDTO,
    ItemRow,
} from "@/models/FormDesigner/FormDesigner";
import { TPAddtionalDataUIModes } from "@/components/TPAdditionalData/TPAdditionalDataUImodes";
import { MdCheckCircle, MdPadding } from "react-icons/md";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { FormDesignerService } from "@/services/FormDesignerService";

interface FormDesignerTableModel {
    callBackCommandsBack: Function;
}

const languageDefaul = {
    languageId: "",
    localizedValue: "",
    order: 0,
};

const styleChildPrewview = {
    border: "none",
    padding: "2px",
    borderRadius: "4px",
    minHeight: "90px",
    justifyContent: "start",
    display: "flex",
    alignItems: "end",
};

const FormDesignerPreview = forwardRef(
    ({ callBackCommandsBack }: FormDesignerTableModel) => {
        const resourceSet: string = "FormDesignerComponent";

        const [column, setColumn] = useState([
            { code: 1, columns: 1 },
            { code: 2, columns: 2 },
            { code: 3, columns: 2 },
            { code: 4, columns: 2 },
            { code: 5, columns: 3 },
            { code: 6, columns: 3 },
            { code: 7, columns: 3 },
            { code: 8, columns: 3 },
            { code: 9, columns: 4 },
            { code: 10, columns: 4 },
            { code: 11, columns: 4 },
            { code: 12, columns: 4 },
            { code: 13, columns: 4 },
            { code: 14, columns: 4 },
        ]);

        const [allAdditionalDataService, setAllAdditionalDataService] = useState<
            Array<AdditionalDataViewModel>
        >([]);
        const [baseLanguageOptions, setBaseLanguageOptions] = useState<
            Array<TPKeyValue>
        >([]);
        const [LanguageOptionsForm, setLanguageOptionsForm] = useState<
            Array<TPKeyValue>
        >([]);
        const [listRow, setListRow] = useState(new Array<FormDesignerRowModel>());
        const [isCaseRonndon, setIsCaseRonndon] = useState(false);
        const [activeSelect, setActiveSelect] = useState(false);
        const [languageActive, setLanguageActive] = useState({
            key: "",
            value: "",
        });
        const [casePreview, setCasePreview] = useState<any>();

        const [caseNumber, setCaseNumber] = useState("");
        const [defaultLanguage, setDefaultLanguage] = useState("");
        const [errorNumberCase, setErrorNumberCase] = useState("");
        const [titleLabel, setTitleLabel] = useState("");
        const [randonCaseLabel, setRandonCaseLabel] = useState("");
        const [caseNumberLabel, setCaseNumberLabel] = useState("");
        const [editPlaceholderLabel, sedPlaceholderLabel] = useState("");
        const [selectLabel, setSelectLabel] = useState("");
        const [closePreview, setClosePreview] = useState("");
        const [requireInput, setRequireInput] = useState("");
        const [languageFormLabel, setLanguageFormLabel] = useState("");

        const [render, setRender] = useState(0);

        const loadResourcesAndLoadInfo = async () => {
            setLanguageFormLabel(
                await TPI18N.GetText(resourceSet, "setLanguageFormLabel"),
            );
            setRequireInput(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),
            );
            setErrorNumberCase(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),
            );
            setDefaultLanguage(
                await TPI18N.GetText(resourceSet, "defaultLanguageLabel"),
            );
            setClosePreview(await TPI18N.GetText(resourceSet, "ClosePreview"));
            setCaseNumberLabel(await TPI18N.GetText(resourceSet, "CaseNumberLabel"));
            setTitleLabel(await TPI18N.GetText(resourceSet, "TitlePreviewaLabel"));
            setRandonCaseLabel(await TPI18N.GetText(resourceSet, "RandonCaseLabel"));

            sedPlaceholderLabel(
                await TPI18N.GetText(resourceSet, "EditPlaceholderLabel"),
            );
            setSelectLabel(await TPI18N.GetText(resourceSet, "SelectLabel"));
        };

        const handleIsNull = (listControl: Array<ItemRow>, value: number) => {
            if (listControl == undefined) return false;
            if (!additionalData) return false;
            if (additionalData.length <= 0) return false;
            if (listControl.find((s) => s.order == value) == undefined) return false;
            if (listControl.find((s) => s.order == value)?.isVisible == false) return false;
            return true;
        };

        const handleIsNullIdControl = (
            listControl: Array<ItemRow>,
            value: number,
        ) => {
            if (listControl == undefined) return "";
            if (listControl[value] == undefined) return "";
            return listControl[value].idControl;
        };

        const handleValueDefaul = (listControl: Array<ItemRow>, value: number) => {
            let control = listControl[value];

            if (casePreview != null && casePreview != undefined) {
                var listAditional = new Array();
                let responseData = casePreview.responseData;
                let data = responseData.data[0];

                if (data && data.additionalDataList) {
                    listAditional = data.additionalDataList;

                    if (data) {
                        if (listAditional) {
                            let select = listAditional.find(
                                (s) =>
                                    s.additionalDataId == control.idControl &&
                                    s.categoryId != TPGlobal.S_CADTDATBF,
                            );

                            if (select != undefined && select.value) return select.value;
                        }
                    }
                }
            }

            return "";
        };

        const handleGetRenderer = (listControl: Array<ItemRow>, id: number) => {
            let myKeyValue2: TPKeyValue = {
                key: "",
                value: "",
            };

            if (listControl == undefined) return myKeyValue2;
            if (listControl[id] == undefined) return myKeyValue2;

            let data;
            if (additionalData && additionalData.length > 0) {
                data = [...additionalData].find(
                    (s) => s.id == listControl[id].idControl,
                );

                if (data) {
                    data.localizedDescription = listControl[id].title;
                    data.enableEditingModifyBasicData = listControl[id].isReadOnly;
                    data.isMandatory = listControl[id].isMandatory;
                }

                myKeyValue2.key = listControl[id].idControl;

                myKeyValue2.value2 = {
                    id: data?.id,
                    description: data?.description,
                    validationURL: data?.validationURL,
                    comments: data?.comments,
                    additionalDataTypeId: data?.additionalDataTypeId,
                    additionalDataTypeDescription:
                        data?.additionalDataCategoryDescription,
                    jsonParameters: data?.jsonParameters,
                    categoryId: data?.categoryId,
                    additionalDataCategoryDescription:
                        data?.additionalDataCategoryDescription,
                    enableEditingModifyBasicData: data?.enableEditingModifyBasicData,
                    isSystemRecord: false,
                    localizedDescription: data?.localizedDescription,
                    order: data?.order,
                    isMandatory: data?.isMandatory,
                    value: handleValueDefaul(listControl, id),
                };
            } else {
                myKeyValue2.key = listControl[id].idControl;
                myKeyValue2.value2 = new Array<AdditionalDataCaseViewModel>();
            }

            return myKeyValue2;
        };

        const complementsRenderTP = (listControl: Array<ItemRow>, id: number): ComplementsRenderTP => {
            let complements = new ComplementsRenderTP();

            if (listControl == undefined) return new ComplementsRenderTP;
            if (listControl[id] == undefined) return new ComplementsRenderTP;

            let data;
            if (additionalData && additionalData.length > 0) {
                data = additionalData.find(
                    (s) => s.id == listControl[id].idControl,
                );

                if (data) {
                    data.localizedDescription = listControl[id].title;
                    data.enableEditingModifyBasicData = listControl[id].isReadOnly;
                }

                complements.placeholder = listControl[id].placeholder;

            } else {
                complements.placeholder = "";
            }

            return complements;
        };


        const loadLanguages = () => {
            let i: number;
            let expectedCodes: Array<number> = [200];

            let listLanguages: Array<TPKeyValue> = [];
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: item.name };
                listLanguages.push(keyValueElement);
            }

            if (listLanguages) {
                listLanguages.unshift({ key: "", value: "--" });
                let language = TPGlobal.language;
                let select = listLanguages.find((s) => s.key == language);
                if (select) setLanguageActive(select);
                setLanguageOptionsForm(listLanguages);
            }
        };

        const getCaseByNumber = async (id: string) => {
            setStatusLoading(true);
            let serviceClient = new FormDesignerService();
            let expectedCodes: Array<number> = [200];

            try {
                let responseRequest = await serviceClient.casePreviewById(
                    id,
                    false,
                    false,
                    expectedCodes,
                );

                setCasePreview(responseRequest);
                setStatusLoading(false);
                return responseRequest;
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} getCaseByNumber ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${resourceSet} getCaseByNumber ex`);
                setStatusLoading(false);
                return [];
            }
        };

        const getCaseRonndon = async () => {
            setStatusLoading(true);
            let serviceClient = new FormDesignerService();
            let expectedCodes: Array<number> = [200];

            try {
                let responseRequest = await serviceClient.casePreviewRandow(
                    false,
                    true,
                    expectedCodes,
                );

                let caseNumber = responseRequest.responseData.data[0].caseInfo.caseId;
                setCaseNumber(caseNumber);

                setCasePreview(responseRequest);
                setStatusLoading(false);
                return responseRequest;
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} getCaseRonndon ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${resourceSet} getCaseRonndon ex`);
                setStatusLoading(false);
                return [];
            }
        };

        const changedCaseView = (data: string = "") => {
            if (data != "") getCaseByNumber(data);
            else getCaseRonndon();
        };

        const forzarRecarga = () => {
            setRender((prev) => prev + 1);
        };

        const value = useAppConctextFormDesigner();
        const {
            sectionFormDesingner,
            rowsFormDesingner,
            additionalData,
            setAdditionalData,
            setStatusLoading,
        } = value;


   


        const styleItemColumn = {
            width: "99%",
            minHeight: "90px",
            padding: "3px;",
        };

        useEffect(() => {
            loadLanguages();
            loadResourcesAndLoadInfo();
        }, []);

        useEffect(() => {
            if (rowsFormDesingner)
                if (rowsFormDesingner.rows) setListRow(rowsFormDesingner.rows);
        }, [rowsFormDesingner, casePreview]);

        useEffect(() => {
            forzarRecarga();
        }, [casePreview]);

        return (
            <>
                <ContainerPanelStyle
                    onClick={() => {
                        setActiveSelect(false);
                    }}
                >
                    <ContainerHeaderStyle>
                        <ItemHeaderStyle
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                gap: "10px",
                            }}
                        >
                            <TPCheckBox
                                id="IdCaseNumber"
                                labelText={caseNumberLabel}
                                checked={!isCaseRonndon}
                                onChange={(e: any) => {
                                    setIsCaseRonndon(false);

                                    if (
                                        caseNumber != undefined &&
                                        caseNumber != null &&
                                        caseNumber.replaceAll(" ", "") != ""
                                    ) {
                                        getCaseByNumber(caseNumber);
                                    } else {
                                        setErrorNumberCase(requireInput);
                                    }
                                }}
                            ></TPCheckBox>
                            <TPTextBox
                                id="idNumberCaseText"
                                containerStyle={{ width: "182px" }}
                                textStyle={{ width: "120px" }}
                                type="number"
                                icon={TPIconTypes.default}
                                withIcon={false}
                                value={caseNumber}
                                errorMessage={errorNumberCase}
                                placeholder={editPlaceholderLabel}
                                isTag={false}
                                onChange={(e: any) => {
                                    setCaseNumber(e.target.value);
                                    if (e.target.value.replaceAll(" ", "") != "") {
                                        getCaseByNumber(e.target.value);
                                        setErrorNumberCase("");
                                    } else {
                                        setErrorNumberCase(requireInput);
                                    }
                                }}
                                isHorizontal={true}
                            />

                            <TPCheckBox
                                id="IdIsRandon"
                                labelText={randonCaseLabel}
                                checked={isCaseRonndon}
                                onChange={(e: any) => {
                                    setIsCaseRonndon(true);
                                    setErrorNumberCase("");
                                    changedCaseView();
                                }}
                            ></TPCheckBox>
                        </ItemHeaderStyle>

                        <ItemHeaderStyle>
                            <div
                                style={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <ItemHeaderStyle
                                    isVetical={false}
                                    style={{ alignItems: "end" }}
                                >
                                    <ItemHeaderStyle isVetical={true} width={"200px"}>
                                        <ItemHeaderStyle
                                            isVetical={true}
                                            width={"200px"}
                                            style={{ alignItems: "end" }}
                                        >
                                            <TPLabel
                                                className="form-check-label"
                                                labelText={languageFormLabel}
                                                style={{ width: "100%" }}
                                            />

                                            <ContainerInputSelectStyle
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActiveSelect(!activeSelect);
                                                }}
                                            >
                                                <SelectStyle>
                                                    <ItemSelect>{languageActive.value}</ItemSelect>
                                                    <TPButton
                                                        id="IdButton"
                                                        type={TPButtonTypes.icon}
                                                        onClick={() => { }}
                                                        text={""}
                                                        icon={TPIconTypes.arrowDropDown}
                                                    />
                                                </SelectStyle>

                                                {activeSelect && (
                                                    <ChildContainerStyle
                                                        style={{
                                                            padding: "0px",
                                                            gap: "0px",
                                                            height: "200px",
                                                            bottom: "auto",
                                                            top: "36px",
                                                            width: "210px",
                                                        }}
                                                    >
                                                        {LanguageOptionsForm.map((item, index) => (
                                                            <ItemHeaderStyle
                                                                onClick={() => {
                                                                    setLanguageActive(item);
                                                                }}
                                                                style={{
                                                                    borderBottom: "1px solid #b9b9b9",
                                                                    padding: "8px",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                {item.value}
                                                                {languageActive.key == item.key && (
                                                                    <>
                                                                        <TPLabel
                                                                            className="form-check-label"
                                                                            labelText={defaultLanguage}
                                                                            style={{
                                                                                fontSize: "11px",
                                                                                color: "gray",
                                                                                marginLeft: "3px",
                                                                                marginRight: "3px",
                                                                            }}
                                                                        />
                                                                        <MdCheckCircle style={{ color: "green" }} />
                                                                    </>
                                                                )}
                                                            </ItemHeaderStyle>
                                                        ))}
                                                    </ChildContainerStyle>
                                                )}
                                            </ContainerInputSelectStyle>
                                        </ItemHeaderStyle>
                                    </ItemHeaderStyle>

                                    <TPButton
                                        style={{
                                            backgroundColor: "#A00095",
                                            borderRadius: "4px",
                                            marginLeft: "10px",
                                            color: "white",
                                            padding: "5px",
                                            marginTop: "28px",
                                        }}
                                        type={TPButtonTypes.empty}
                                        text={"1"}
                                        onClick={(e: any) => {
                                            callBackCommandsBack();
                                        }}
                                        icon={TPIconTypes.default}
                                    >
                                        {closePreview}
                                    </TPButton>
                                </ItemHeaderStyle>
                            </div>
                        </ItemHeaderStyle>
                    </ContainerHeaderStyle>
                </ContainerPanelStyle>
                <hr style={{ color: "gray", width: "100%" }}></hr>

                <ContainerPreviewFormStyle
                    onClick={() => {
                        setActiveSelect(false);
                    }}
                >
                    <FormPreviewStyle>
                        <TPPageTitle style={{ color: "black" }}>
                            {rowsFormDesingner.title}
                        </TPPageTitle>

                        {sectionFormDesingner.map((section, indexSection) => (
                            <>
                                <div
                                    style={{
                                        height: "50px",
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingTop: "20px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <TPLabel
                                        className="form-check-label"
                                        labelText={section.title}
                                    />
                                </div>

                                <RowPreviewStyle>
                                    {listRow
                                        .filter((s) => s.idSection == section.id)
                                        .map((row, index) => (
                                            <>
                                                {row.columnTypeIdentifier == 1 && (
                                                    <ChildStyle
                                                        color={rowsFormDesingner.color}
                                                        style={{
                                                            backgroundColor: rowsFormDesingner.color,
                                                            border: "none",
                                                            padding: "8px",
                                                            borderRadius: "4px",
                                                            minHeight: "90px",
                                                            justifyContent: "start",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        {handleIsNull(row.controls, 0) ? (
                                                            <TPRenderAdditionalDataSwitch
                                                                guidControl={handleIsNullIdControl(
                                                                    row.controls,
                                                                    0,
                                                                )}
                                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                                defaultValue={
                                                                    handleGetRenderer(row.controls, 0).value2
                                                                        .value
                                                                }
                                                                itemToRender={handleGetRenderer(
                                                                    row.controls,
                                                                    0,
                                                                )}
                                                                complementsRenderTP={complementsRenderTP(row.controls, 0) }
                                                                ref={(element: any) => { }}
                                                                onChange={() => { }}
                                                            />
                                                        ) : (
                                                            <IcoAddStyle></IcoAddStyle>
                                                        )}
                                                    </ChildStyle>
                                                )}

                                                {row.columnTypeIdentifier == 2 && (
                                                    <ChildStyle
                                                        style={{
                                                            backgroundColor: rowsFormDesingner.color,
                                                            display: "grid",
                                                            gridTemplateColumns: "1fr 1fr",
                                                            border: "none",
                                                            padding: "5px",
                                                            borderRadius: "4px",
                                                            minHeight: "90px",
                                                            justifyContent: "start",
                                                            alignItems: "start",
                                                        }}
                                                    >
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 0) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 0).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle>                                                                   
                                                                </IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 1) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 1).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                    </ChildStyle>
                                                )}

                                                {row.columnTypeIdentifier == 3 && (
                                                    <ChildStyle
                                                        style={{
                                                            backgroundColor: rowsFormDesingner.color,
                                                            display: "grid",
                                                            gridTemplateColumns: "1fr 3fr",
                                                            border: "none",
                                                            padding: "5px",
                                                            borderRadius: "4px",
                                                            minHeight: "90px",
                                                            justifyContent: "start",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 0) ? (
                                                                <div>
                                                                    <TPRenderAdditionalDataSwitch
                                                                        guidControl={handleIsNullIdControl(
                                                                            row.controls,
                                                                            0,
                                                                        )}
                                                                        modeUI={TPAddtionalDataUIModes.Collect}
                                                                        defaultValue={
                                                                            handleGetRenderer(row.controls, 0).value2
                                                                                .value
                                                                        }
                                                                        itemToRender={handleGetRenderer(
                                                                            row.controls,
                                                                            0,
                                                                        )}
                                                                        complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                        ref={(element: any) => { }}
                                                                        onChange={() => { }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 1) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 1).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle>
                                                                    {/*   <TPIcon iconType={TPIconTypes.refresh} style={{}} /> */}
                                                                </IcoAddStyle>
                                                            )}
                                                        </div>
                                                    </ChildStyle>
                                                )}

                                                {row.columnTypeIdentifier == 4 && (
                                                    <ChildStyle
                                                        color={rowsFormDesingner.color}
                                                        style={{
                                                            backgroundColor: rowsFormDesingner.color,
                                                            display: "grid",
                                                            gridTemplateColumns: "3fr 1fr",
                                                            border: "none",
                                                            padding: "5px",
                                                            borderRadius: "4px",
                                                            minHeight: "90px",
                                                            justifyContent: "start",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 0) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 0).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 1) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 1).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                    </ChildStyle>
                                                )}

                                                {row.columnTypeIdentifier == 5 && (
                                                    <ChildStyle
                                                        color={rowsFormDesingner.color}
                                                        style={{
                                                            backgroundColor: rowsFormDesingner.color,
                                                            display: "grid",
                                                            gridTemplateColumns: "1fr 1fr 1fr",
                                                            border: "none",
                                                            padding: "5px",
                                                            borderRadius: "4px",
                                                            minHeight: "90px",
                                                            justifyContent: "start",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 0) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 0).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 1) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 1).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 2) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 2).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                    </ChildStyle>
                                                )}

                                                {row.columnTypeIdentifier == 6 && (
                                                    <ChildStyle
                                                        color={rowsFormDesingner.color}
                                                        style={{
                                                            display: "grid",
                                                            gridTemplateColumns: "1fr 1fr 2fr",
                                                            border: "none",
                                                            padding: "5px",
                                                            borderRadius: "4px",
                                                            minHeight: "90px",
                                                            justifyContent: "start",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 0) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 0).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 1) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 1).value2
                                                                            .value
                                                                    }
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 2) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 2).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                    </ChildStyle>
                                                )}

                                                {row.columnTypeIdentifier == 7 && (
                                                    <ChildStyle
                                                        color={rowsFormDesingner.color}
                                                        style={{
                                                            display: "grid",
                                                            gridTemplateColumns: "1fr 1fr 2fr",
                                                            border: "none",
                                                            padding: "5px",
                                                            borderRadius: "4px",
                                                            minHeight: "90px",
                                                            justifyContent: "start",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 0) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 0).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 1) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 1).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 2) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 0).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                    </ChildStyle>
                                                )}

                                                {row.columnTypeIdentifier == 8 && (
                                                    <ChildStyle
                                                        color={rowsFormDesingner.color}
                                                        style={{
                                                            display: "grid",
                                                            gridTemplateColumns: "2fr 1fr 1fr",
                                                            border: "none",
                                                            padding: "5px",
                                                            borderRadius: "4px",
                                                            minHeight: "90px",
                                                            justifyContent: "start",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 0) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 0).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 1) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 1).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 2) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 2).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                    </ChildStyle>
                                                )}

                                                {row.columnTypeIdentifier == 9 && (
                                                    <ChildStyle
                                                        color={rowsFormDesingner.color}
                                                        style={{
                                                            display: "grid",
                                                            gridTemplateColumns: "1fr 1fr 1fr 1fr",
                                                            border: "none",
                                                            padding: "5px",
                                                            borderRadius: "4px",
                                                            minHeight: "90px",
                                                            justifyContent: "start",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 0) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 0).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 1) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 1).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 2) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 2).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 3) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        3,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 3).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        3,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 3)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                    </ChildStyle>
                                                )}

                                                {row.columnTypeIdentifier == 10 && (
                                                    <ChildStyle
                                                        color={rowsFormDesingner.color}
                                                        style={{
                                                            display: "grid",
                                                            gridTemplateColumns: "1fr 1fr 2fr 2fr",
                                                            border: "none",
                                                            padding: "5px",
                                                            borderRadius: "4px",
                                                            minHeight: "90px",
                                                            justifyContent: "start",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 0) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 0).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 1) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 1).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 2) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 2).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 3) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        3,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 3).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        3,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 3)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                    </ChildStyle>
                                                )}

                                                {row.columnTypeIdentifier == 11 && (
                                                    <ChildStyle
                                                        color={rowsFormDesingner.color}
                                                        style={{
                                                            display: "grid",
                                                            gridTemplateColumns: "1fr 2fr 1fr 2fr",
                                                            border: "none",
                                                            padding: "5px",
                                                            borderRadius: "4px",
                                                            minHeight: "90px",
                                                            justifyContent: "start",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 0) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 0).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 1) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 1).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 2) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 2).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 3) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        3,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 3).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        3,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 3)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                    </ChildStyle>
                                                )}

                                                {row.columnTypeIdentifier == 12 && (
                                                    <ChildStyle
                                                        color={rowsFormDesingner.color}
                                                        style={{
                                                            display: "grid",
                                                            gridTemplateColumns: "1fr 2fr 2fr 1fr",
                                                            border: "none",
                                                            padding: "5px",
                                                            borderRadius: "4px",
                                                            minHeight: "90px",
                                                            justifyContent: "start",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 0) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 0).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 1) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 1).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 2) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 2).value2
                                                                            .value
                                                                    }
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 3) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        3,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 3).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        3,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 3)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                    </ChildStyle>
                                                )}

                                                {row.columnTypeIdentifier == 13 && (
                                                    <ChildStyle
                                                        color={rowsFormDesingner.color}
                                                        style={{
                                                            display: "grid",
                                                            gridTemplateColumns: "2fr 1fr 2fr 1fr",
                                                            border: "none",
                                                            padding: "5px",
                                                            borderRadius: "4px",
                                                            minHeight: "90px",
                                                            justifyContent: "start",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 0) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 0).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 1) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 1).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 2) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 2).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 3) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        3,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 3).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        3,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 3)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                    </ChildStyle>
                                                )}

                                                {row.columnTypeIdentifier == 14 && (
                                                    <ChildStyle
                                                        color={rowsFormDesingner.color}
                                                        style={{
                                                            display: "grid",
                                                            gridTemplateColumns: "2fr 2fr 1fr 1fr",
                                                            border: "none",
                                                            padding: "5px",
                                                            borderRadius: "4px",
                                                            minHeight: "90px",
                                                            justifyContent: "start",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 0) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 0).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        0,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 1) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 1).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        1,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 2) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 2).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        2,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                        <div style={styleItemColumn}>
                                                            {handleIsNull(row.controls, 3) ? (
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={handleIsNullIdControl(
                                                                        row.controls,
                                                                        3,
                                                                    )}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={
                                                                        handleGetRenderer(row.controls, 3).value2
                                                                            .value
                                                                    }
                                                                    itemToRender={handleGetRenderer(
                                                                        row.controls,
                                                                        3,
                                                                    )}
                                                                    complementsRenderTP={complementsRenderTP(row.controls, 3)}
                                                                    ref={(element: any) => { }}
                                                                    onChange={() => { }}
                                                                />
                                                            ) : (
                                                                <IcoAddStyle></IcoAddStyle>
                                                            )}
                                                        </div>
                                                    </ChildStyle>
                                                )}
                                            </>
                                        ))}
                                </RowPreviewStyle>
                            </>
                        ))}
                    </FormPreviewStyle>
                </ContainerPreviewFormStyle>
            </>
        );
    },
);

export default FormDesignerPreview;
