import allThemes from "@/assets/styles/theme";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import React, { FC, useState } from "react";
import ReactDOM from "react-dom";

export type TPModalProps = {
  titleModal: string | JSX.Element;
  acceptLabel?: string | JSX.Element;
  cancelLabel?: string | JSX.Element;
  callBackAnswer: Function;
  callBackData?: any;
  isShown: boolean;
  modalWidth?: string;
  hideFooterButtons?: boolean;
  enableAcceptButton?: boolean;
  id?: string;
  showSecondaryButton?: boolean;
  showCancelButton?: boolean;
  secondaryButtonLabel?: string;
  secondaryAction?: string;
  hiddenHeader?: boolean;
  hideXButton?: boolean;
  isFullscreen?: boolean;
  zIndex?: number;
};

export type TPModalStateType = {
  modalState: TPModalProps;
  children: React.ReactNode;
};

const TPModal: FC<TPModalStateType> = ({ modalState, children }) => {
  const modalRoot = document.getElementById("modal") as HTMLElement;
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;
  
  const defaultZIndex = 1050;
  const modalZIndex = modalState.zIndex || defaultZIndex;

  if (modalState.isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  if (!!modalState?.isFullscreen) {
    styleModal["width"] = "100vw";
    styleModal["height"] = "100vh";
    styleModal["top"] = 0;
    styleModal["left"] = 0;
    styleModal["margin"] = 0;
    styleModal["padding"] = 0;
    styleModal["maxWidth"] = "100%";
    styleModal["maxHeight"] = "100%";
  }

  styleModal["zIndex"] = modalZIndex;
  const backdropStyle = {
    zIndex: modalZIndex - 1,
  };

  return ReactDOM.createPortal(
    <>
      <div
        id={modalState.id}
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div
          className={`modal-dialog ${!!modalState?.isFullscreen ? "modal-fullscreen" : (modalState.modalWidth ? modalState.modalWidth : "modal-lg")} modal-dialog-centered modal-dialog-scrollable`}
          style={!!modalState?.isFullscreen ? { width: "100vw", height: "100vh" } : {}}
        >
          <div className="modal-content" style={!!modalState?.isFullscreen ? { height: "100%" } : {}}>
            {!modalState.hiddenHeader && (
              <div className="modal-header justify-content-between">
                <h5 className="modal-title">{modalState.titleModal}</h5>
                {!modalState.hideXButton && (
                  <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => modalState.callBackAnswer(false, modalState.callBackData)}
                ></button>
                )}
              </div>
            )}
            <div className="modal-body" style={{ height: !!modalState?.isFullscreen ? "calc(100% - 60px)" : "auto" }}>
              {children}
            </div>
            {!modalState.hideFooterButtons && (
              <div className="modal-footer" style={{ display: "flex", flexDirection: "row" }}>
                {modalState.showSecondaryButton && (
                    <div>
                      <TPButton
                        id="IdButton"
                        type={TPButtonTypes.link}
                        onClick={() => modalState.callBackAnswer(false, modalState.secondaryAction)}
                        isDesignSystem
                        style={{
                          paddingLeft:'16px', paddingRight: '16px',
                          backgroundColor:'white', color: allThemes.base.primary,
                          borderColor: 'white', 
                        }}
                      >
                        <u>{modalState.secondaryButtonLabel}</u>
                      </TPButton>
                    </div>
                  )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: modalState.cancelLabel ? "flex-end" : "end",
                    width: "87%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    {
                      (modalState.showCancelButton ?? true) && <TPButton
                        id="IdButton"
                        type={TPButtonTypes.link}
                        onClick={() =>
                          modalState.callBackAnswer(
                            false,
                            modalState.callBackData,
                          )
                        }
                        isDesignSystem
                        style={{
                          paddingLeft: '16px', paddingRight: '16px',
                          backgroundColor: 'white', color: allThemes.base.primary
                        }}
                      >
                        {modalState.cancelLabel}
                      </TPButton>
                    }
                    <TPButton
                      id="IdButton"
                      type={TPButtonTypes.primary}
                      onClick={() =>
                        modalState.callBackAnswer(true, modalState.callBackData)
                      }
                      disabled={modalState.enableAcceptButton === false}
                      isDesignSystem
                      style={{
                        paddingLeft: '16px', paddingRight: '16px'
                      }}
                    >
                      {modalState.acceptLabel}
                    </TPButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={backdropClass} style={backdropStyle}></div>
    </>,
    modalRoot,
  );
};

export default TPModal;
