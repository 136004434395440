import TPModal from "@/layouts/TPModal/TPModal";
import {
  MenuDefinitionListResponseModel,
  MenuDefinitionModel,
  MenuHierarchy,
  MenuReorderModel,
} from "@/models/MenuItems/MenuItemsModels";
import { FC, useEffect, useMemo, useState } from "react";
import "../Assets/MenuDefinitionStyles.css";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { useMenuDefinitionLabels } from "../Assets/MenuDefinitionLabels";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import {
  MenuDefinitionSlice,
  MenuDefinitionSliceModel,
} from "../Assets/MenuDefinitionSlice";
import TPReorderableList from "@/components/TPDragList/TPDragList";
import { TPDragAndDropSectionStyled } from "@/pages/InboundMailboxes/InboundMailboxesAssignRulesStyles";
import { MenuDefinitionService } from "@/services/MenuDefinitionService";
import { useDispatch } from "react-redux";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import {
  DragDropContext,
  Draggable,
  DragStart,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import * as availableIcons from "react-icons/md";
import { IconType } from "react-icons";
import allThemes from "@/assets/styles/theme";

interface MenuDefinitionReorderModal {
  visible?: boolean;
  onClose: Function;
  isPreview?: boolean;
  profile?: string | null;
  profileName?: string;
}

const getHighlight = function(search: string, original: string, menuId: string) {
  const regex = new RegExp(`(.*?)(${search})(.* *)`, "i");
  const matches = original.match(regex) || [original];

  if (matches[2]?.trim().length > 0)
    document.getElementById(menuId)?.scrollIntoView({ behavior: "smooth" });
  
  return matches[2] ? (
    <label>
      {matches[1]}
      <span style={{backgroundColor: allThemes.base.purplePrimary, color: "white"}}>
        {matches[2]}
      </span>
      {matches[3]}
    </label>) : (matches[0])
}

export const MenuDefinitionReorderModal: FC<MenuDefinitionReorderModal> =
  function ({ visible = true, onClose, isPreview, profile, profileName }) {
    const dispatch = useDispatch();
    const { menus } = useSelector((s: StoreModel) => s[MenuDefinitionSlice.name]) as MenuDefinitionSliceModel;
    const { labels } = useMenuDefinitionLabels();
    const [loading, setLoading] = useState(false);
    const [visibleGroups, setVisibleGroups] = useState<Array<string>>([]);
    const [isDraggingGroup, setIsDraggingGroup] = useState(false);
    const [query, setQuery] = useState("");
    const [contextMenus, setContextMenus] = useState<MenuDefinitionListResponseModel[]>(menus);

    const toggleGroupVisibility = function (groupId: string) {
      if (visibleGroups.includes(groupId)) {
        setVisibleGroups(visibleGroups.filter((g) => g != groupId));
      } else {
        setVisibleGroups([...visibleGroups, groupId]);
      }
    };

    const reorderMenus = function(newOrders: MenuReorderModel[]) {
      setLoading(true);
      MenuDefinitionService.reorderMassiveMenus({ orders: newOrders }, true, true, [200])
      .then(() => {
        dispatch(MenuDefinitionSlice.actions.setContentLoaded(false))
        setContextMenus([]);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
    }

    const getGroupParent = function(dropIndex: number, inclusive?: boolean): MenuDefinitionListResponseModel | null {
      for (let i=dropIndex; i >=0; i--) {
        const menu = menus[i];
        if (menu?.section && (i < dropIndex || (inclusive && (i <= dropIndex)))) {
          return menu;
        }
      }
      return null;
    }

    const getItemParent = function(dropIndex: number, inclusive?: boolean): MenuDefinitionListResponseModel | null {
      for (let i=dropIndex; i >=0; i--) {
        const menu = menus[i];
        if (i < dropIndex && menu.section) return null;
        if (menu?.group && (i < dropIndex || (inclusive && (i <= dropIndex)))) {
          return menu;
        }
      }
      return null;
    }

    const handleDragStart = function(result: DragStart) {
      const menu = menus.find(m => m.id === result.draggableId);
      if (menu?.group) setIsDraggingGroup(true);
    }

    const buildReorderMap = function(
      innerMenus: MenuDefinitionListResponseModel[], 
      draggedId: string, 
      source: number, 
      destination: number, 
      parentId: string) : MenuReorderModel[]
    {
      if (source < destination) destination += 0.5;
      if (source > destination) destination -= 0.5;
      
      let reorderMap = innerMenus.map((m, i) => {
        let o = m.id === draggedId ? destination : menus.indexOf(m);
        return {...m, order: o }
      }).sort((mA, mB) => {
          if (mA.order < mB.order) return -1;
          if (mA.order > mB.order) return 1;
          return 0;
        })
      .map((m, i) => (
          { id: m?.id, parent: parentId, order: i+1 }
        ))
      
      return reorderMap;
    }

    const handleDragEnd = function(result: DropResult) {
      setIsDraggingGroup(false);

      if (!result || !result.destination || (result.destination.index === result.source.index)) return;

      const destinationIndex = result.destination.index;
      const sourceIndex = result.source.index;

      const draggedMenu = menus.find(m => m.id === result.draggableId);
      if (!draggedMenu) return;

      if (draggedMenu.group) {
        const parent = getGroupParent(destinationIndex, sourceIndex < destinationIndex);
        if (!parent) return;
        let sectionGroups = menus.filter(m => m.group && m.parentId === parent.id);
        if (draggedMenu.parentId !== parent.id) sectionGroups.push(draggedMenu);
        reorderMenus(buildReorderMap(sectionGroups, draggedMenu.id, sourceIndex, destinationIndex, parent.id));
      }
      if (draggedMenu.item) {
        const parent = getItemParent(destinationIndex, sourceIndex < destinationIndex);
        if (!parent) return;
        let groupItems = menus.filter(m => m.item && m.parentId === parent.id);
        if (draggedMenu.parentId !== parent.id) groupItems.push(draggedMenu);
        reorderMenus(buildReorderMap(groupItems, draggedMenu.id, sourceIndex, destinationIndex, parent.id));
      }
    }

    useEffect(() => {
      if (query.trim().length > 0) {
        setVisibleGroups(menus.filter(m => {
          if (!m.group) return false;
          if (isPreview && profile) {
            return (m.group && m.profiles.find(p => p.id_PROF === profile));
          } else {
            return true;
          }
        }).map(m => m.id));
      } else {
        setVisibleGroups([]);
      }
    }, [query])

    useEffect(() => {
      setContextMenus(menus);
    }, [menus])

    return (
      <TPModal
        modalState={{
          acceptLabel: "",
          callBackAnswer: () => {},
          isShown: visible,
          titleModal: "",
          cancelLabel: "_close",
          hiddenHeader: true,
          enableAcceptButton: false,
          hideXButton: true,
          hideFooterButtons: true,
        }}
      >
        <TPLoadingOverlay active={loading}>
          <div className="menu-def-reorder-modal">
            <div className="menu-def-reorder-modal-title">
              {labels.MenuPreviewReorderTitle}
              {" - "}
              {profile ? `(${profileName || profile})` : labels.ReorderMenu}
            </div>
            {!isPreview && (
              <div className="menu-def-reorder-modal-info">
                <TPIcon
                  iconType={TPIconTypes.alert}
                  style={{ fontSize: "24px" }}
                />
                {labels.ReorderInstructions}
              </div>
            )}
            <div style={{ alignSelf: "flex-start" }}>
              <TPTextBox
                value={query}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                  setQuery(e.target.value)}
                withIcon
                icon={TPIconTypes.search}
                placeholder={labels.Search}
                labelText=""
              />
            </div>
            {contextMenus.length > 0 ? (
              <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
              <TPDragAndDropSectionStyled style={{ width: "100%" }}>
                <div
                  className="row mx-0"
                  style={{ width: "100%", height: "450px", overflow: "auto" }}
                >
                  <Droppable droppableId="menu-def-main-droppable">
                    {(provided, dropSnapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {contextMenus.filter(m => {
                          if (isPreview && profile) {
                            return m.profiles.find(p => p.id_PROF === profile)
                          } else {
                            return true;
                          }
                        }).map((m, idx) => (
                          <Draggable draggableId={m.id} index={idx} isDragDisabled={(m.section?.trim().length > 0) || isPreview}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {m.section && (
                                  <div style={{color: allThemes.base.purplePrimary}} id={m.id}>
                                    <div style={{textWrap: "nowrap"}}>
                                      {getHighlight(query.trim(), m.section, m.id)}
                                     </div>
                                     <div
                                      style={{
                                        width: "100%",
                                        height: 0,
                                        border: `1px solid`,
                                      }}
                                    />
                                  </div>)}
                                {m.group && (
                                  <div 
                                    className="menu-def-reorderable-group"
                                    onClick={() => toggleGroupVisibility(m.id)}
                                    id={m.id}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                        justifyContent: "space-between"
                                      }}
                                    >
                                      {Boolean(availableIcons[m.image as keyof IconType]) ?
                                        (availableIcons[m.image as keyof IconType] as IconType)({fontSize: "24px",color: "#2E2E2E"})
                                        :
                                        <TPIcon iconType={TPIconTypes[m.image as keyof typeof TPIconTypes] as TPIconTypes} />
                                      }
                                      {getHighlight(query.trim(), m.group, m.id)}
                                      {!isPreview && (
                                        <div className="menu-def-group-drag-icon">
                                          <TPIcon
                                            iconType={TPIconTypes.caretUpDown}
                                            style={{ cursor: "grab" }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <TPIcon
                                      iconType={
                                        visibleGroups.includes(m.id)
                                          ? TPIconTypes.caretUp
                                          : TPIconTypes.caretDown
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>)}
                                {m.item && visibleGroups.includes(m.parentId) && (
                                  <div 
                                    className="menu-def-reorderable-item" 
                                    style={{ opacity: isDraggingGroup ? .25 : 1 }}
                                    id={m.id}
                                  >
                                    {getHighlight(query.trim(), m.item, m.id)}
                                    {!isPreview && (
                                      <div className="menu-def-item-drag-icon">
                                        <TPIcon
                                          iconType={TPIconTypes.caretUpDown}
                                          style={{ cursor: "grab" }}
                                        />
                                      </div>
                                    )}
                                  </div>)}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </TPDragAndDropSectionStyled>
            </DragDropContext>
            ) : (
              <b>{labels.GettingMenus}</b>
            )}
            <TPButton
              isDesignSystem
              onClick={() => onClose()}
              style={{
                backgroundColor: "white",
                color: "#780096",
                padding: "11px 16px",
                alignSelf: "flex-end",
              }}
            >
              {labels.Close}
            </TPButton>
          </div>
        </TPLoadingOverlay>
      </TPModal>
    );
  };
