import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";
import { TEmpAdditionalDataCaseViewModel } from "../Cases/CasesInputDTO";
import { RecordLocalizedModel } from "../Global/TPGlobalModels";

export interface AdditionalDataTaskTypeInputDTO {
  taskTypeId: string;
  additionalDataId: string;
  order: number;
  isMandatory: boolean;
}
export class AdditionalDataTaskTypeInputDTOValidator extends Validator<AdditionalDataTaskTypeInputDTO> {
  constructor() {
    super();

    const resourceSet = "TaskTypeAdditionalDataInsertUpdate";

    try {
      this.ruleFor("taskTypeId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTaskTypeIdEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidTaskTypeId")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidTaskTypeIdLength");

      this.ruleFor("additionalDataId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOAdditionalDataIdEmpty")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidAdditionalDataId")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidAdditionalDataIdLength");

      this.ruleFor("order")
        .must((x) => {
          return x.toString().trim() !== "";
        })
        .withMessage(resourceSet + "|InputDTOAdditionalDataOrderNumeric")
        .must((x) => {
          if (x < 0) {
            return false;
          }

          return true;
        })
        .withMessage(resourceSet + "|InputDTOAdditionalDataInvalidOrder");
    } catch (error) {}
  }
}

export interface AdditionalDataInputDTO {
  id: string;
  description: string;
  validationURL: string;
  comments: string;
  additionalDataTypeId: string;
  jsonParameters: string;
  categoryId: string;
  enableEditingModifyBasicData: boolean;
  otherLocalizedValues: Array<RecordLocalizedModel>;
}

export class AAdditionalDataInputDTOValidator extends Validator<AdditionalDataInputDTO> {
  constructor() {
    super();

    const resourceSet = "AdditionalDataInsertUpdateComponent";

    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("comments")
        .maxLength(500)
        .withMessage(resourceSet + "|InputDTOInvalidValidationCommentsLength")
        .must((value) => {
          if (!value) {
            return true;
          } else {
            return TPGlobal.TPSanitize(value) === value;
          }
        })
        .withMessage(resourceSet + "|InputDTOInvalidComments");

      this.ruleFor("additionalDataTypeId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOAdditionalDataTypeIdEmpty")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidAdditionalDataTypeId")
        .maxLength(20)
        .withMessage(
          resourceSet + "|InputDTOInvalidAdditionalDataTypeIdLength",
        );

      this.ruleFor("jsonParameters")
        .must((value) => {
          if (!value) {
            return true;
          } else {
            return TPGlobal.TPSanitize(value) === value;
          }
        })
        .withMessage(resourceSet + "|InputDTOInvalidJsonParameters");

      this.ruleFor("categoryId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOCategoryEmpty")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidCategory")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidCategoryLength");
    } catch (error) {}
  }
}

export interface AdditionalDataCompareInputDTO {
  caseId: number;
  tEmpAdditionalDataValues: TEmpAdditionalDataCaseViewModel[];
}
