export enum TPActiveOptions {
    ALL = 2,
    INACTIVE = 0,
    ACTIVE = 1,
}

export enum TPLanguageFilterEnum {
    All = "A",
    ConfiguredForTPClientUI = "U",
    ConfiguredForCustomerActive = "C",
    ConfiguredForExternalServicesActive = "E"
}

export enum TPButtonTypes {
    primary = "btn-primary",
    secondary = "btn-secondary",
    success = "btn-success",
    danger = "btn-danger",
    warning = "btn-warning",
    info = "btn-info",
    light = "btn-light",
    dark = "btn-dark",
    link = "btn-link",
    empty = "tp-btn-empty",
    icon = "tp-btn-icon",
    toolbar = "tp-btn-toolbar",
    alternative = "tp-btn-alt",
}

export enum TPIconTypes {
    default = "default",
    delete = "delete",
    newEntity = "newEntity",
    refresh = "refresh",
    refreshCircle = "refreshCircle",
    clone = "clone",
    home = "home",
    edit = "edit",
    menu = "menu",
    receipt = "receipt",
    public = "public",
    lock = "lock",
    person = "person",
    personCircle = "personCircle",
    diagram = "diagram",
    expandLess = "expandLess",
    expandMore = "expandMore",
    activeInactive = "activeInactive",
    moreVert = "moreVert",
    chevronRight = "chevronRight",
    chevronLeft = "chevronLeft",
    leftArrow = "leftArrow",
    keyboardArrowDown = "keyboardarrowdown",
    search = "search",
    fileDownload = "fileDownload",
    arrowcircledown = "arrowcircledown",
    parent = "parent",
    gridon = "gridon",
    window = "window",
    libraryAdd = "libraryadd",
    playlistAddCheck = "playlistaddcheck",
    assessment = "assessment",
    accountTree = "accountTree",
    image = "image",
    close = "close",
    notifications = "notifications",
    calendarToday = "calendarToday",
    calendar = "calendar",
    checklist = "checklist",
    filter = "filter",
    settings = "settings",
    fullScreen = "fullScreen",
    fullScreenExit = "fullScreenExit",
    closeFullScreen = "closeFullScreen",
    fitScreen = "fitScreen",
    openInFull = "openInFull",
    logIn = "logIn",
    logOut = "logOut",
    tab = "tab",
    sortByAlpha = "sortByAlpha",
    watchLater = "watchLater",
    checkCircle = "checkCircle",
    swapVert = "swapVert",
    warning = "warning",
    identity = "identity",
    firstPage = "firstPage",
    lastPage = "lastPage",
    favorite = "favorite",
    ticket = "ticket",
    noFavorite = "noFavorite",
    teams = "teams",
    task = "task",
    keyboard = "keyboard",
    personSearch = "personSearch",
    all = "all",
    hourglassTop = "hourglassTop",
    help = "help",
    print = "print",
    autograph = "autograph",
    arrowDropDown = "arrowDropDown",
    arrowDropUp = "arrowDropUp",
    circleInfo = "circleInfo",
    info = "info",
    custom = "custom",
    zoomIn = "zoomIn",
    language = "language",
    email = "email",
    add = "add",
    addCircle = "addCircle",
    import = "import",
    excel = "excel",
    mdDragIndicator = "mdDragIndicator",
    copy = "copy",
    alert = "alert",
    plus = "plus",
    circle = "circle",
    chevron = "chevron",
    dash = "dash",
    more = "more",
    doubleArrowLeft = "doubleArrowLeft",
    doubleArrowRight = "doubleArrowRight",
    loop = "loop",
    downloadTemplate = "downloadTemplate",
    tableView = "tableView",
    cardsView = "cardsView",
    eye = "eye",
    caretDown = "caretDown",
    eventManager = "eventManager",
    swap = "swap",
    cloud = "cloud",
    done = "done",
    download = "download",
    plusAlt = "plusAlt",
    remove = "remove",
    plusCircle = "plusCircle",
    expandRight = "expandRight",
    expandDown = "expandDown",
    chevronRigth = "chevronRigth",
    cockpit = "cockpit",
    star = "start",
    working = "working",
    online = "online",
    ready = "ready",
    worked = "worked",
    offline = "offline",
    work = "work",
    prohibited = "prohibited",
    stop = "stop",
    warningCircleOutline = "warningCircle",
    circleCheckOutline = "circleCheckOutline",
    play = "play",
    copilot = "copilotIcon",
    clock = "clock",
    CircleInfoFill = "CircleInfoFill",
    GreenCircle = "GreenCircle",
    YellowCircle = "YellowCircle",
    RedCircle = "RedCircle",
    sentimentNeutral = "sentimentNeutral",
    sentimentSatisfiedAlt = "sentimentSatisfiedAlt",
    sentimentDissatisfied = "sentimentDissatisfied",
    export = "export",
    touch = "touch",
    clockOut = "clockOut",
    solved = "solved",
    pending = "pending",
    expired = "expired",
    lag = "lag",
    ia = "ia",
    clipboard = "clipboard",
    reader = "reader",
    happy = "happy",
    neutral = "neutral",
    negative = "negative",
    plusGo = "plusGo",
    AddFolder = "AddFolder",
    addFolder2 = "AddFolder2",
    folderCopy = "folderCopy",
    outlineUndo = "outlineUndo",
    OpenFolder = "OpenFolder",
    Folder = "Folder",
    caretUp = "caretUp",
    cross = "cross",
    caretUpDown = 'caretUpDown',
    spellCheck = 'spellCheck',
    AlertTriangle = "AlertTriangle",
    arrowLeftBottom = "arrowLeftBottom",
    bell = "bell",
    bellPlus = "bellPlus",
    trash = "trash",
}
export enum TaskTypeComponentEnum {
    //Decision
    caseResponseDecisionAutoTaskComponent = "CAREDEAUTA",
    caseResponseDecisionRetryTaskComponent = "CAREDERETRY",
    caseResponseDecisionTaskComponent = "CAREDETA",

    //Email
    caseResponseEmailAutoTaskComponent = "CARESEEMAUTA",
    caseResponseEmailTaskComponent = "CARESEEMAIL",

    //Case Exchange
    caseResponseExchangeAutoTaskComponent = "CAREEXCAINAU",
    caseResponseExchangeTaskComponent = "CAREEXCASEIN",

    //Social Media
    caseResponseFacebookGroupTaskComponent = "CAREFBGRRE",
    caseResponseFacebookTaskComponent = "CAREFBRE",
    caseResponseInstagramTaskComponent = "CAREINRE",
    caseResponseInstantMessageTaskComponent = "CAREINMERE",
    caseResponseTweetTaskComponent = "CARETWRE",

    //KB
    caseResponsePublishDocumentTaskComponent = "CAREKBPUDO",

    //Others
    caseResponseAnswerAutoTaskComponent = "CAREAUANTA",
    caseResponseRestartWorkflowTaskComponent = "CARERECUWF",
    caseResponseStandardTaskComponent = "CARESTCO",
    caseResponseSystemTaskComponent = "CARESYTA",

    //External Task
    externalTaskComponent = "CAREEXTA",
}

export enum AdditionalDataTypeEnum {
    date = "DATE",
    datelag = "DATELAG",
    email = "EMAIL",
    integerlist = "INTEGERSLIST",
    internallist = "INTERNALLIST",
    label = "LABEL",
    link = "LINK",
    listvalue = "LISTVALUE",
    numeric = "NUMERIC",
    phone = "PHONE",
    textarea = "TEXTAREA",
    textbox = "TEXTBOX",
    tree = "TREE",
    yesno = "YESNO",
    attachment = "ATTACHMENT",
}

export enum AdditionalDataOpenTextTypesEnum {
    open = "Open",
    whiteList = "WhiteList",
    inputMask = "InputMask",
}

export enum AdditionalDataOpenTextMultilineTypesEnum {
    open = "Open",
    whiteList = "WhiteList",
}

export enum AdditionalDataCategoryEnum {
    FC_BRANCH = "FC_BRANCH",
    S_CUSTCONT = "S_CUSTCONT",
}

export enum AdditionalDataDateTypeEnum {
    dateRange = "DateRange",
    anyDate = "AnyDate",
}

export enum UserGroupTypesEnum {
    update = "gUpdated",
    noUpdate = "gNoUpdated",
    rotating = "gRotating",
    takeNext = "gFishing",
}

export enum TPAssignedOptions {
    ALL = 2,
    ASSIGNED = 0,
    NOTASSIGNED = 1,
}

export enum TPQuickSelectDatesEnum {
    today = "today",
    yesterday = "yesterday",
    currentWeek = "currentWeek",
    lastWeek = "lastWeek",
    currentMonth = "currentMonth",
    lastMonth = "lastMonth",
    currentYear = "currentYear",
    lastTwelveMonths = "lastTwelveMonths",
}

export enum ConfigParametersEnum {
    Document_Type = "S_DOCTYP",
    Custom_Contacts_Relationships = "S_CUCORELATIONSHIP",
}

export enum SystemTaskTypesEnum {
    comment = "COMM",
}

export enum ReportIdNames {
    REPOSTANDAVERAGERESPON = "REPOSTANDAVERAGERESPON",
    REPOSTANDCADE = "REPOSTANDCADE",
    REPOSTANDCADEWITHADDA = "REPOSTANDCADEWITHADDA",
    REPOSTANDCADEWITHTASK = "REPOSTANDCADEWITHTASK",
    REPOSTANTASKCOMPLETED = "REPOSTANTASKCOMPLETED",
    REPOSTANCASETIMERDETAILS = "REPOSTANCASETIMERDETAILS",
    REPOSTANCASETIMERSUMMARY = "REPOSTANCASETIMERSUMMARY",
    REPOSTANACTIVITYTRACKED = "REPOSTANACTIVITYTRACKED",
}

export enum ReportFilterType {
    BaseLevelId = "S_SEBALEIDFILCOL",
    CaseStatus = "S_SECASETATUSFILCOL",
    PrivateCase = "S_SEISPRIVATEFILCOL",
    Classificator = "S_SECLASSID{id}FILCOL", //Reemplace template {id} when you use it
}

export enum CaseSearchByFilterType {
    BaseLevelId = "S_SEBALEIDFILCOL",
    CaseStatus = "S_SECASETATUSFILCOL",
    PrivateCase = "S_SEISPRIVATEFILCOL",
    Classificator = "S_SECLASSID{id}FILCOL", //Reemplace template {id} when you use it
    ParticipateUser = "S_SEPARTICIPAFILCOL",
    TaskTypeFilter = "S_SETASKTYPEFILCOL",
}

export enum taskStatusEnum {
    "Expired" = "EX",
    "InsertedHeaderData" = "IH",
    "Lag" = "LA",
    "OnHold" = "OH",
    "Pending" = "PE",
    "Solved" = "SO",
    "TaskSchedule" = "TS",
    "Alert" = "AL",
}

export enum taskStatusNameEnum {
    "Expired" = "Expired",
    "InsertedHeaderData" = "InsertedHeaderData",
    "Lag" = "Lag",
    "OnHold" = "OnHold",
    "Pending" = "Pending",
    "Solved" = "Solved",
    "TaskSchedule" = "TaskSchedule",
    "Alert" = "Alert",
}
export enum SystemParametersEnum {
    "TEUNI" = "TEUNI", // Term unit
    "S_RERE" = "S_RERE", //Reclassification reason
    "S_RECLAOPT" = "S_RECLAOPT", //Reclassification Options
    "S_RECLASW" = "S_RECLASW", //Reclassification option same workflow
    "REFORETA" = "REFORETA", // Reject reasons
    "S_FORMTYPE" = "S_FORMTYPE", // Form design
    "STRUCTURETYPEPARENT" = "S_STRUCTURETYPE", // Structure type parent
    "EVENTLISTCLAOPT" = "S_EVENTLISTCLAOPT",
    "EVENTLISTINTOUT" = "S_EVENTLISTINTOUT",
    "EVENTFORMTYPE" = "S_EVENTFORMTYPE",
}

export enum ModalSizeEnum {
    "MODALXL" = "modal-xl",
    "MODALSM" = "modal-sm",
    "MODALMD" = "modal-md",
    "MODALLG" = "modal-lg",
}

export enum ActionTypeEnum {
    Reassign,
    Reject,
}

export enum SignalRSubtopicEnum {
    INITIAL_TASKS = "INITIAL_TASKS",
    NEXT_TASKS = "NEXT_TASKS",
    ADD_TASK = "ADD_TASK",
    REJECT_TASK = "REJECT_TASK",
    RECLASSIFY_CASE = "RECLASSIFY_CASE",
    TOASTMESSAGE = "TOASTMESSAGE",
    TOAST_REMINDER = "TOAST_REMINDER",
}

export enum OnExistingCustomerInboundMailBox {
    UseCustomerMostRecentCase = "1",
    UseFixedCustomer = "2",
}

export enum OnNewCustomerInboundMailBox {
    CreateNewCustomer = "1",
    UseFixedCustomer = "2",
}

export enum SSLProtocol {
    TLS1 = "TLS 1.0 or higher",
    TLS1_1 = "TLS 1.1 or higher",
    TLS1_2 = "TLS 1.2 or higher",
    SSL3 = "SSL 3.0",
    SSL2 = "SSL 2.0",
    PCT1 = "PCT 1.0",
}

export enum SecurityType {
    None = "0",
    Auto = "10",
    SslOnConnect = "20",
    StartTls = "30",
    StartTlsWhenAvailable = "40",
}

export enum ConnectionProtocol {
    POP3 = "POP3",
    IMAP = "IMAP",
}

export enum MailboxType {
    // OTHER = "OTHER",
    BASIC = "BASIC",
    EXCHANGEONLINE = "EXCHANGEONLINE",
}

export enum IMAPFolder {
    Flagged = "Flagged",
    Drafts = "Drafts",
    Trash = "Trash",
    Inbox = "Inbox",
    Junk = "Junk",
    Sent = "Sent",
}

export enum InboundMailboxInProcessActionsType {
    ActionDiscard = "1",
    ActionCreateNewCaseApplyingPositiveRules = "2",
    ActionCreateNewCaseWithFixedClassifier = "3",
}

export enum WebServiceClassifierType {
    MailReader = "M",
    Chat = "C",
    ExternalForm = "F",
    QuickClassifiers = "Q",
    SocialMedia = "S",
    ImportMassive = "I",
    CustomerAlert = "A",
    OutboundCampaignSurvey = "O",
}

export enum AttachmentType {
    ATTACHMENT = "ATTACHMENT",
    MAILREADEREML = "MAILREADEREML",
    MAILREADERATTACHMENT = "MAILREADERATTACHMENT",
    MAILREADEREMBEBEDIMAGE = "MAILREADEREMBEBEDIMAGE",
    IMAGE = "IMAGE",
}

export enum InboundMailboxInProcessType {
    InboundMailboxTypeAll = "ALL",
    InboundMailboxTypeJunk = "JUNKMAIL",
    InboundMailboxTypeInProcess = "INPROCESS",
}

export enum RuleActiveType {
    YES = "YES",
    NO = "NO",
}

export enum RuleLogicalOperatorType {
    AND = "AND",
    OR = "OR",
}

export enum RuleActionType {
    CREATECASE = "CREATECASE",
    JUNKMAIL = "JUNKMAIL",
    INPROCESS = "INPROCESS",
}

export enum RuleBaseFieldType {
    FROMNAME = "FROMNAME",
    FROMADDRESS = "FROMADDRESS ",
    SUBJECT = "SUBJECT",
    BODY = "BODY",
    NUMBERRECIPIENTS = "NUMBERRECIPIENTS",
    NAMEATTACHMENT = "NAMEATTACHMENT",
    TOADDRESS = "TOADDRESS",
    CCADDRESS = "CCADDRESS",
    BCCADDRESS = "BCCADDRESS",
}

export enum RuleComparisonOperatorsType {
    EQUAL = "EQUAL",
    GREATER = "GREATER",
    LESS = "LESS",
    GREATEROREQUALTHAN = "GREATEROREQUALTHAN",
    LESSOREQUALTHAN = "LESSOREQUALTHAN",
    DIFFERENT = "DIFFERENT",
    BETWEEN = "BETWEEN",
    BEGINS = "BEGINS",
    NOBEGINS = "NOBEGINS",
    ENDS = "ENDS",
    NOENDS = "NOENDS",
    CONTAINS = "CONTAINS",
    NOCONTAINS = "NOCONTAINS",
    INLISTS = "INLISTS",
    NOTINLISTS = "NOTINLISTS",
    REGULAREXPRESSIONPUNTONET = "REGURALEXPRESSIONPUNTONET",
}

export enum BaseFieldType {
    MAILELEMENT = "MAILELEMENT",
    CUSTOMER = "CUSTOMER",
}

export enum SequenceGeneratorSequencesNameEnum {
    SQTREE = "SQTREE", //Trees
    SQBRAN = "SQBRAN", //Branches
    SQFUNC = "SQFUNC", //Functions
    SQTATY = "SQTATY", //Task Type
    SQINMB = "SQINMB", //Inbound Mailbox
    SQIMAA = "SQIMAA", //Inbound Mailbox Azure Application
    SQIMRD = "SQIMRD", //Inbound Mailbox Rules Definition
    SQEMTE = "SQEMTE", //Email Template
    SQWOTY = "SQWOTY", //Worflow Type
    SQMAAC = "SQMAAC", //Outbound Mail Account
    SQPROF = "SQPROF", //Profiles
    SQROLE = "SQROLE", //Role
    SQCSST = "SQCSST", //Case Custom States
    SQCALE = "SQCALE", //Calendar
    SQGROU = "SQGROU", //Group
    SQSCHE = "SQSCHE", //Schedule
    SQADDA = "SQADDA", //Additional Data
    SQCUTY = "SQCUTY", //Customer Type
    SQBALE = "SQBALE", //Client/Service ==> Base Level
    SQORGA = "SQORGA", //Client ==>Organization
    SQWSCL = "SQWSCL", //Quick Classifier
    SQRELA = "SQRELA", //Sevice ==> Realtion
    SQEVLS = "SQEVLS", //Event Load Structure
    SQEVQU = "SQEVQU", //Event Queue Take Next
    SQEVPJ = "SQEVPR", //Event Projects
    SQADFO = "SQADFO", //Form Design
    SQDILI = "SQDILI", //Distribution List
    SQLIST = "SQLIST", //List
    SQLIIT = "SQLIIT", //List Items
    SQSCRI = "SQSCRI", //Scripts
    SQMEIT = "SQMEIT", //Menu
  SQCTBI = "SQCTBI", //Case toolbar
}

export interface LocalizedTextValue {
    languageId: string;
    localizedValue: string;
    languageName?: string;
    order?: number;
}