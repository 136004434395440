import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";
import { RecordLocalizedModel } from "../Global/TPGlobalModels";

export interface CalendarInputDTO {
  id: string;
  description: string;
  isActive: boolean;
  otherLocalizedValues: Array<RecordLocalizedModel>;
}

export class CalendarInputDTOValidator extends Validator<CalendarInputDTO> {
  constructor() {
    super();

    const resourceSet = "CalendarInsertUpdateComponent";

    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

    } catch (error) {}
  }
}
