import TPGlobal from "@/helpers/TPGlobal";
import { throwAlertError } from "@/modules/core/utils/errors-management";
import {
  CockpitDashboardService,
  TeamViewProps,
  TeamViewResponse,
} from "@/services/SupervisorCockpit/CockpitDashboardService";
import React, { FC, ReactElement, useEffect, useState } from "react";
import {
  CockpitDashboardLabels,
  InfoCardTypes,
  InfoCardValues,
  StatusModel,
  TeamMember,
  TeamMemberParsed,
} from "../../supervisor-cockpit.model";
import CockpitInfoCard from "../../utils/CockpitInfoCard";
import CockpitNoData from "../../utils/CockpitNoData";
import CockpitRefresh from "../../utils/CockpitRefresh";
import CockpitTeamActivityTable from "../../utils/CockpitTeamActivityTable";
import {
  StyledCardContainer,
  StyledDashboardTeamActivity,
} from "../cockpit-dashboard-styles";

type DashboardTeamViewProps = {
  setIsLoadingScreen: Function;
  componentLabels: CockpitDashboardLabels;
};

const DashboardTeamView: FC<DashboardTeamViewProps> = React.memo(
  ({ setIsLoadingScreen, componentLabels }): ReactElement => {
    const component: string = "DashboardTeamView";
    const service = new CockpitDashboardService();

    const excludeCards: InfoCardTypes[] = [
      "worked",
      "event",
      "case",
      "chat",
      "mail",
      "expired",
      "pending",
      "inProcess",
      "closed",
      "history",
      "processing",
    ];

    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
    const [noDataView, setNoDataView] = useState<boolean>(true);

    let baseCards: InfoCardValues = {
      offline: 0,
      working: 0,
      online: 0,
      ready: 0,
      event: 0,
      case: 0,
      chat: 0,
      mail: 0,
      expired: 0,
      worked: 0,
      pending: 0,
      inProcess: 0,
      closed: 0,
      history: 0,
      processing: 0,
    };
    const [infoCards, setInfoCards] = useState<InfoCardValues>(baseCards);
    const [tableData, setTableData] = useState<TeamMemberParsed[]>([]);

    const setTableDataBy = (data: TeamMember[]) => {
      let parsedData: TeamMemberParsed[] = data.map((item) => {
        return {
          userName: item.userName,
          schedule:
            item.onSchedule === "ONSCHEDULE" ? "onSchedule" : "offSchedule",
          status: item.currentStatus.toLowerCase() as InfoCardTypes,
          duration: item.duration,
          pendingTickets: item.pendingTickets,
          queueName: item.queueName,
          userGuid: item.userGuid,
          userId: item.userId,
        };
      });

      setTableData(parsedData);
    };

    const setInfoCardsBy = (statusModels: StatusModel[]) => {
      let cards = baseCards;

      statusModels.forEach((item) => {
        let cardType: InfoCardTypes | undefined =
          item.status.toLowerCase() as InfoCardTypes;
        if (cardType) cards[cardType] = item.totalStatus;
      });

      setInfoCards(cards);
    };

    const getTeamViewDashboard = async (): Promise<TeamViewResponse[]> => {
      const object: TeamViewProps = {
        userId: TPGlobal.currentUserGuid,
      };

      try {
        setIsLoadingScreen(true);
        return await service.getTeamViewData(object);
      } catch (error) {
        setIsLoadingScreen(false);
        throwAlertError(error, component, "getTeamViewDashboard");
        return [];
      }
    };

    const setTeamViewData = () => {
      getTeamViewDashboard().then((res) => {
        const response: TeamViewResponse = res[0];

        setNoDataView(!response);

        if (res && response) {
          response.statusModels && setInfoCardsBy(response.statusModels);
          response.teamMembersModel &&
            setTableDataBy(response.teamMembersModel);
        }

        setIsLoadingScreen(false);
        setIsDataLoaded(true);
      });
    };

    useEffect(() => {
      if (!isDataLoaded) setTeamViewData();
    }, []);

    const RenderInfoCards = React.memo((): ReactElement => {
      let allCards = Object.entries(infoCards).map(([key, value]) => {
        return {
          type: key as InfoCardTypes,
          value: value,
          label: componentLabels.infoCards[key as InfoCardTypes]
        };
      });

      let filteredCards = allCards.filter(
        (item) => !excludeCards.includes(item.type)
      );

      return (
        <StyledCardContainer>
          {filteredCards.map((item, index) => {
            return (
              <CockpitInfoCard
                key={index}
                type={item.type}
                label={item.label}
                value={item.value}
              />
            );
          })}
        </StyledCardContainer>
      );
    });

    return (
      <>
        {noDataView ? (
          <CockpitNoData />
        ) : (
          <>
            <CockpitRefresh
              label={componentLabels.refreshData}
              onClickIcon={setTeamViewData}
            />
            <RenderInfoCards />
            <StyledDashboardTeamActivity>
              <CockpitTeamActivityTable data={tableData} withChildren />
            </StyledDashboardTeamActivity>
          </>
        )}
      </>
    );
  }
);

export default DashboardTeamView;
