import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { FC, useEffect, useState } from "react";
import '../../Assets/styles.css';
import { ScriptAdditionalDataLogic, ScriptLogicStep, ScriptStep } from "@/models/Scripts/ScriptModel";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import { extractTextFromHTML } from "@/helpers/ExtractTextFromHTML";
import { useScriptsLabels } from "../../Assets/labelling";

interface ScriptLinkLogicItemProperties {
  availableNextSteps: ScriptStep[];
  logic?: ScriptAdditionalDataLogic[];
  onLogicAdd: (logic: ScriptAdditionalDataLogic) => void;
}

const ScriptLinkLogicItem: FC<ScriptLinkLogicItemProperties> = function ({ availableNextSteps, logic, onLogicAdd }) {
  const [input, setInput] = useState("");
  const [nextStep, setNextStep] = useState(availableNextSteps.find(a => a.order == -1)?.id || "");
  const [error, setError] = useState("");
  const {labels} = useScriptsLabels();

  useEffect(() => {
    setError(input.match(TPGlobal.regularExpressionURL) ? "" : labels.InvalidURL);
  }, [input])
  return (
    <>
      <TPTextBox
        value={input}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
        placeholder={labels.TypeHere}
        labelText=""
        errorMessage={error}
      />
      <TPSelect
        dataSource={
          availableNextSteps.map(step => {
            return {
              key: String(step.id),
              value: step.order == -1 ?
                extractTextFromHTML(step.description)
                :
                String(step.order).concat('. ', extractTextFromHTML(step.description) || '')
            }
          })
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNextStep(e.target.value)}
        value={nextStep}
        containerStyle={{ width: '100%' }}
      />
      <TPButton
        onClick={() => onLogicAdd({
          key_SSDA: input,
          nextStepId: nextStep,
          value: input
        })}
        isDesignSystem
        style={{
          alignSelf: 'flex-end',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
        disabled={input.trim().length == 0 || error.trim().length > 0}
      >
        {labels.AddLogic}
      </TPButton>
    </>
  )
}

export default ScriptLinkLogicItem;