import { useEffect, useRef, useState } from "react";
import {
    Description,
    DescriptionWrapper,
    HoverMsg,
    UploaderWrapper,
} from "./style";
import { acceptedExt, checkType, getFileSizeMB } from "./utils";

import CloudIcon from "@/assets/images/TPIcons/CloudIcon";
import { TPButtonStyled } from "../bootstrap/components/buttons/tpButtonStyles";
import DrawTypes from "./DrawTypes";
import useDragging from "./useDragging";
import { IoClose } from "react-icons/io5";
import { AiFillCheckCircle } from "react-icons/ai";
import { TPI18N } from "../../services/I18nService";
import TPGlobal from "../../helpers/TPGlobal";

type Props = {
    id?: string;
    name?: string;
    hoverTitle?: string;
    types?: Array<string>;
    classes?: string;
    children?: JSX.Element;
    maxSize?: number;
    minSize?: number;
    fileOrFiles?: Array<File> | File | null;
    disabled?: boolean | false;
    label?: string | JSX.Element | undefined;
    multiple?: boolean | false;
    onSizeError?: (arg0: string) => void;
    onTypeError?: (arg0: string) => void;
    onDrop?: (arg0: File | Array<File>) => void;
    onSelect?: (arg0: File | Array<File>) => void;
    handleChange?: (arg0: File | Array<File> | File) => void;
    onDraggingStateChange?: (dragging: boolean) => void;
    UploadButtonText: string | JSX.Element;
    height?: string | number;
    isSelected?: boolean | false;
    canRemove?: boolean | false;
    newControl?: boolean | false;
};
/**
 *
 * Draw a description on the frame
 * @param currFile - The uploaded file
 * @param uploaded - boolean to check if the file uploaded or not yet
 * @param typeError - boolean to check if the file has type errors
 * @param disabled - boolean to check if input is disabled
 * @param label - string to add custom label
 * @returns JSX Element
 *
 * @internal
 *
 */
const drawDescription = (
    id: string,
    currFile: Array<File> | File | null,
    uploaded: boolean,
    typeError: boolean,
    disabled: boolean | undefined,
    UploadButtonText: string | JSX.Element | undefined
) => {
    return typeError ? (
        <span>File type/size error, Hovered on types!</span>
    ) : (
        <Description>
            {/* {disabled ? (
        <span>Upload disabled</span>
      ) : !currFile && !uploaded ? (
        <>
          {label ? (
            <>
              <span>{label.split(" ")[0]}</span>{" "}
              {label.substr(label.indexOf(" ") + 1)}
            </>
          ) : (
            <>
              <span>Upload</span> or drop a file right here
            </>
          )}
        </>
      ) : (
        <>
          <span>Uploaded Successfully!.</span> Upload another?
        </>
      )} */}

            <div>
                <TPButtonStyled
                    type="button"
                    id={id}
                    className="btn btn-outline-primary"
                >
                    <span style={{ textDecoration: "none" }}>{UploadButtonText}</span>
                </TPButtonStyled>
            </div>
        </Description>
    );
};

/**
   * File uploading main function
   * @param props - {name,
      hoverTitle,
      types,
      handleChange,
      classes,
      children,
      maxSize,
      minSize,
      fileOrFiles,
      onSizeError,
      onTypeError,
      onSelect,
      onDrop,
      onTypeError,
      disabled,
      label,
      multiple,
      onDraggingStateChange,
      UploadButtonText
    }
   * @returns JSX Element
   */
const FileUploader: React.FC<Props> = (props: Props): JSX.Element => {
    const {
        id,
        name,
        hoverTitle,
        types,
        handleChange,
        classes,
        children,
        maxSize,
        minSize,
        fileOrFiles,
        onSizeError,
        onTypeError,
        onSelect,
        onDrop,
        disabled,
        label,
        multiple,
        onDraggingStateChange,
        UploadButtonText,
        height,
        isSelected,
        canRemove,
        newControl
    } = props;
    const labelRef = useRef<HTMLLabelElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [uploaded, setUploaded] = useState(false);
    const [currFiles, setFile] = useState<Array<File> | File | null>(null);
    const [error, setError] = useState(false);
    const componentId = `${id && `${id}-`}upload-file-btn`;
    const [filesType, setFilesType] = useState<string>("");


    const validateFile = (file: File) => {
        if (types && !checkType(file, types)) {
            // types included and type not in them
            setError(true);
            if (onTypeError) onTypeError("File type is not supported");
            return false;
        }
        if (maxSize && getFileSizeMB(file.size) > maxSize) {
            setError(true);
            if (onSizeError) onSizeError("File size is too big");
            return false;
        }
        if (minSize && getFileSizeMB(file.size) < minSize) {
            setError(true);
            if (onSizeError) onSizeError("File size is too small");
            return false;
        }
        return true;
    };

    const loadUtilsResources = async () => {
        setFilesType(await TPI18N.GetText(TPGlobal.globalResourceSet, "SelectOrdrop"));
    }


    const handleChanges = (files: File | Array<File>): boolean => {
        let newFiles1: any = files;
        let newFiles2: File | Array<File>;
        if (newFiles1.length) {
            newFiles2 = [...(files as Array<File>)];
        } else {
            newFiles2 = { ...(files as File) };
        }

        let checkError = false;
        if (files) {
            if (files instanceof File) {
                checkError = !validateFile(files);
            } else {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    checkError = !validateFile(file) || checkError;
                }
            }
            if (checkError) return false;
            if (handleChange) handleChange(newFiles2);
            setFile(newFiles2);

            setUploaded(true);
            setError(false);

            if (inputRef.current) inputRef.current.value = "";

            return true;
        }
        return false;
    };

    const blockEvent = (ev: any) => {
        ev.preventDefault();
        ev.stopPropagation();
    };
    const handleClick = (ev: any) => {
        ev.stopPropagation();
        // eslint-disable-next-line no-param-reassign
        if (inputRef && inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleInputChange = (ev: any) => {
        const allFiles = ev.target.files;
        //const files = multiple ? allFiles : allFiles[0];
        const files = allFiles;
        const success = handleChanges(files);
        if (onSelect && success) onSelect(files);
    };
    const dragging = useDragging({
        labelRef,
        inputRef,
        multiple,
        handleChanges,
        onDrop,
    });

    const handleRemoveFile = () => {
        setFile(null);
        setUploaded(false);
        setError(false);
        if (inputRef.current) {
            inputRef.current.value = "";
        }
        if (handleChange) handleChange([]);
    };

    useEffect(() => {
        loadUtilsResources();
    }, []);

    useEffect(() => {
        onDraggingStateChange?.(dragging);
    }, [dragging]);

    useEffect(() => {
        if (fileOrFiles) {
            setUploaded(true);
            setFile(fileOrFiles);
        } else {
            if (inputRef.current) inputRef.current.value = "";
            setUploaded(false);
            setFile(null);
        }
    }, [fileOrFiles]);

    return (
        <div
            className="d-flex justify-content-center tp-file-uploader"
            style={newControl ? {
                border: "1px dashed rgb(46, 46, 46)",
                borderRadius:"5px"
            } : {
                width: "100%",
            }}
        >
            <UploaderWrapper
                enableBorder={!newControl}
                overRide={children}
                className={`${classes || ""} ${disabled ? "is-disabled" : ""}`}
                ref={labelRef}
                htmlFor={name}
                onClick={blockEvent}
                style={newControl ? {
                    border: "0px dashed white",                    
                } : {
                    width: "100%",
                    height: height || "250px",
                }}
            >
                <input
                    id={componentId}
                    onClick={handleClick}
                    onChange={handleInputChange}
                    accept={acceptedExt(types)}
                    ref={inputRef}
                    type="file"
                    name={name}
                    disabled={disabled}
                    multiple={multiple}
                />
                {(dragging && (!newControl || newControl == undefined)) && (
                    <HoverMsg> <span>{hoverTitle || "Drop Here"}</span> </HoverMsg>
                )}
                {!children && (
                    <div className="tp-file-uploader__container tph100per tpw100per">
                        <div className="tp-file-uploader__container-icon">
                            <div style={{ transform: "scale(1)" }}>
                                {isSelected ? (
                                    <AiFillCheckCircle className="success-icon" />
                                ) : (
                                    <CloudIcon />
                                )}
                            </div>

                            <div>
                                {newControl &&
                                    <>
                                        <span style={{ fontSize: "15px", color: "black" }}>
                                            {filesType + " (" + acceptedExt(types) + ")"}
                                        </span>
                                        <br></br>
                                    </>
                                }
                                <span style={{ fontSize: "15px", color: "#666" }}>
                                    {label ? label : null}
                                </span>
                            </div>

                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: 20
                            }}
                        >
                            <DescriptionWrapper error={error} style={{ flexGrow: 1 }}>
                                {drawDescription(
                                    componentId,
                                    currFiles,
                                    uploaded,
                                    error,
                                    disabled,
                                    UploadButtonText
                                )}
                                {(!newControl || newControl == undefined) &&
                                    <DrawTypes types={types} minSize={minSize} maxSize={maxSize} />
                                }
                            </DescriptionWrapper>
                            {canRemove && isSelected && (
                                <IoClose
                                    className="remove-icon"
                                    onClick={handleRemoveFile}
                                    style={{
                                        marginRight: "10px",
                                        cursor: "pointer",
                                        color: "#666",
                                    }}
                                />
                            )}
                        </div>
                    </div>
                )}

                {children}
            </UploaderWrapper>
        </div >
    );
};
export default FileUploader;
