import TPButton from "@/components/bootstrap/components/buttons/TPButton";

import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import {
    TPActiveOptions,
    TPButtonTypes,
    TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import React, {
    useEffect,
    useState,
    useImperativeHandle,
    useReducer,
} from "react";

import { RecordLocalizedModel } from "../../models/Global/TPGlobalModels";
import TPModalLanguageList from "../../layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "../../layouts/TPModalLanguageList/useModal";



interface dataModel {
    handleChanged?: (id: string, value: string) => void;
    handleChangedName?: (value: string) => void;
    handleChangedList?: (list: Array<RecordLocalizedModel>) => void;
    recordLocalized: RecordLocalizedModel[];
    baseLanguageOptions: TPKeyValue[];
    activeSave: boolean;
    folderName: string;
}





const InputLanguages = ({ handleChanged, handleChangedList, handleChangedName, recordLocalized, baseLanguageOptions, activeSave, folderName }: dataModel) => {
    const resourceSet: string = "InputLanguages.tsx";

    const [recordLocalizedModel, setRecordLocalizedModel] = useState<Array<RecordLocalizedModel>>(recordLocalized);
    const [baseLanguageOptionsModel, setBaseLanguageOptionsModel] = useState<Array<TPKeyValue>>(baseLanguageOptions);

   

    const [languageListLabel, setLanguageListLabel] = useState("");
    const [saveButtonModalLabel, setSaveButtonModalLabel] = useState("");
    const [cancelButtonModalLabel, setCancelButtonModalLabel] = useState("");
    const [nameLabel, setNameLabel] = useState("");
    const [require, setRequiere] = useState("");

    const {
        isOpen: isOpenModalLanguageList,
        openModal: handleOpenModalLanguageList,
        closeModal: handleCloseModalLanguageList,
        saveChanges: handleSaveChangesModalLanguageList,
    } = useModal(false);


    const loadResourcesAndLoadInfo = async () => {

        setLanguageListLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
        );
        setSaveButtonModalLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
        );
        setCancelButtonModalLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        );
        setNameLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "Name"),
        );
        setRequiere(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "inputDtoRequiere"),
        );
    };



    useEffect(() => {
        loadResourcesAndLoadInfo();      
    }, []);

    return (

        <div style={{
            display: "flex",
            alignItems: "end"
            
        }}>
            {recordLocalizedModel &&
                recordLocalizedModel.length > 0 &&
                TPGlobal.TPClientAvailableLanguages.map(
                    (item, index) =>
                        item.id === TPGlobal.TPClientDefaultLanguage && (
                            <div key={`languageItem-${item.id}`}>
                                <TPTextBox
                                    containerStyle={{ width: "660px", minWidth:"500px" }}
                                    type="text"
                                    value={
                                        recordLocalizedModel ?
                                            recordLocalizedModel.find(
                                                (s) => s.languageId == TPGlobal.TPClientDefaultLanguage,
                                            )?.localizedValue : ""
                                    }
                                    placeholder={folderName}
                                    onChange={(e: any) => {
                                        if (handleChangedName) handleChangedName(e.target.value);
                                        let temp = [...recordLocalizedModel];
                                        let newItem =
                                            recordLocalizedModel.find(
                                                (s) => s.languageId == item.id,
                                            );
                                        if (newItem)
                                            newItem.localizedValue = e.target.value;
                                        setRecordLocalizedModel(temp);
                                    }}
                                    labelText={`${nameLabel} (${item.name})`}
                                    isMandatory={true}
                                    />


                            </div>
                        ),
                )}
            {recordLocalizedModel &&
                recordLocalizedModel.length > 0 && (
                    <>
                        <TPButton
                        id="IdButton"
                        type={TPButtonTypes.icon}
                        icon={TPIconTypes.language}
                        text={`+${recordLocalizedModel.length - 1}`}
                        tooltip={languageListLabel}
                        className={"pt-3"}
                        onClick={handleOpenModalLanguageList}
                       
                        style={{ marginTop: "-5px" }}

                        />

                        <TPModalLanguageList
                            id="IdModalLanguageList"
                            isOpen={isOpenModalLanguageList}
                            title={languageListLabel}
                            acceptLabel={saveButtonModalLabel}
                            cancelLabel={cancelButtonModalLabel}
                            saveChanges={handleSaveChangesModalLanguageList}
                            closeModal={handleCloseModalLanguageList}
                        >
                            <div
                                className="row overflow-auto"
                                style={{ height: "200px" }}
                            >
                                {TPGlobal.TPClientAvailableLanguages.map(
                                    (item, index) =>
                                        item.id !== TPGlobal.TPClientDefaultLanguage && (
                                            <div
                                                className="col-12"
                                                key={`languageItem-${item.id}`}
                                            >
                                                <div className="form-group">
                                                    <TPTextBox
                                                        id="IdTextBox"
                                                        isMandatory={index === 0}
                                                        labelText={`${nameLabel} (${item.name})`}
                                                        value={
                                                            recordLocalizedModel.find(
                                                                (s) => s.languageId == item.id,
                                                            )?.localizedValue
                                                        }
                                                        onChange={(e: any) => {
                                                          
                                                            let temp = [...recordLocalizedModel];
                                                            let newItem =
                                                                recordLocalizedModel.find(
                                                                    (s) => s.languageId == item.id,
                                                                );
                                                            if (newItem)
                                                                newItem.localizedValue = e.target.value;                                                         

                                                            setRecordLocalizedModel(temp);
                                                            if (handleChangedList) handleChangedList(temp);
                                                        }}
                                                        containerStyle={{ with: "100%" }}
                                                        maxLength={100}
                                                        errorMessage={""}
                                                    />
                                                </div>
                                            </div>
                                        ),
                                )}
                            </div>
                        </TPModalLanguageList>
                    </>
                )}
        </div>

    );
};

export default InputLanguages;
