import styled from "styled-components";

const colors = {
    primary: "#780096",
    hoverBackground: "#f0e5ff",
    hoverText: "#333",
    selectedText: "#fff",
    sidebarBackground: "#f4f4f4",
    divider: "#d2d2d2",
};

export const StyledIcon = styled.div`
  display: inline-block;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: ${colors.primary};
  }

  svg {
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const Sidebar = styled.div`
  background-color: ${colors.sidebarBackground};
  min-width: 250px;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const IconSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Divider = styled.hr`
  border: none;
  border-top: 1px solid ${colors.divider};
  margin: 1rem 0;
`;

export const SidebarItem = styled.div<{ selected: boolean }>`
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  background-color: ${({ selected }) =>
        selected ? colors.primary : "transparent"};
  color: ${({ selected }) => (selected ? colors.selectedText : "inherit")};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    color: ${({ selected }) =>
        selected ? colors.selectedText : colors.hoverText};
    background-color: ${({ selected }) =>
        selected ? colors.primary : colors.hoverBackground};
  }

  svg {
    margin-right: 0.5rem;
  }
`;

export const MainContent = styled.div`
  flex: 1;
  padding: 1rem;
`;

export const LayoutContainer = styled.div`
  display: flex;
`;
