import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPModal from "@/layouts/TPModal/TPModal";
import useListAdminLabels from "@/pages/EventsManager/ListAdministration/assets/Labeling";
import { FC } from "react";

interface TPConfirmationModalProps {
  visible: boolean
  message: string;
  onAccept: Function;
  onCancel: Function;
  title?: string;
  hideTitle?: boolean;
}

export const TPConfirmationModal: FC<TPConfirmationModalProps> = function ({ 
  visible, 
  message, 
  onAccept, 
  onCancel,
  title,
  hideTitle = false,
}) {
  const { labels } = useListAdminLabels();
  return (
    <TPModal
      modalState={{
        acceptLabel: "",
        callBackAnswer: () => {},
        isShown: visible,
        titleModal: "",
        hideFooterButtons: true,
        hideXButton: true,
        hiddenHeader: true,
        modalWidth: "464px",
      }}
    >
      <div className="delete-list-alert-modal">
        {!hideTitle && (
          <h3>
            <b>{title || labels.SystemAlert}</b>
          </h3>)}
        {message}
        <div className="delete-list-alert-button-pair">
          <TPButton
            onClick={onCancel}
            isDesignSystem
            style={{
              backgroundColor: "white",
              color: "#780096",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            {labels.No}
          </TPButton>
          <TPButton
            onClick={onAccept}
            isDesignSystem
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            {labels.Yes}
          </TPButton>
        </div>
      </div>
    </TPModal>
  );
};
