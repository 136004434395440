import React from "react";
import ReactQuill from "react-quill";
import { DeltaStatic } from "quill";
import * as XLSX from 'xlsx';
// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
const convertArrayOfObjectsToCSV = (array: any, filteredData: any) => {
  let result: any;

  const columnDelimiter = ";";
  const lineDelimiter = "\n";
  const keys = Object.keys(filteredData()[0]);

  // keys = keys.map(
  //   (item: any) => (item = item.toString().replaceAll(";", ":"))
  // );

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item: any) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      if (item[key]) {
        result += item[key].toString().replaceAll(";", ":");
      }

      ctr++;
    });
    result += lineDelimiter;
  });
  return result;
};

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
export const downloadCSV = (array: any, filteredData: any, tableName? : string) => {
  //   const link = document.createElement("a");
  //   let csv = convertArrayOfObjectsToCSV(array, filteredData);
  //   if (csv == null) return;
  //   const filename = "export.csv";

  //   if (!csv.match(/^data:text\/csv/i)) {
  //     debugger;
  //     csv = `data:text/csv;charset=utf-8,${csv}`;
  //   }

  //   link.setAttribute("href", encodeURI(csv));
  //   link.setAttribute("download", filename);
  //   link.click();

  // var content = [
  //   ["#$%#$", "2nd title", "3rd title", "4th title"],
  //   ["a a a", "bb\nb", "cc,c", 'dd"d'],
  //   ["www", "xxx", "yyy", "zzz"],
  // ];

  let data = filteredData();

  const keys = Object.keys(data[0]);
  let finalData = [];
  let ix: number;
  let firstRow = [];
  for (ix = 0; ix <= keys.length - 1; ix++) {
    firstRow.push(keys[ix]);
  }
  finalData.push(firstRow);

  for (ix = 0; ix <= data.length - 1; ix++) {
    let row = Object.keys(data[ix]).map(function (key) {
      return data[ix][key] ? data[ix][key].toString() : "";
    });

    finalData.push(row);
  }
  var content = finalData;

  var finalVal = "";

  for (var i = 0; i < content.length; i++) {
    var value = content[i];

    for (var j = 0; j < value.length; j++) {
      var innerValue = value[j];
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ";";
      finalVal += result;
    }

    finalVal += "\n";
  }

  var pom = document.createElement("a");
  pom.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(finalVal),
  );
  pom.setAttribute("download", (tableName ? tableName : "table-data") + ".csv");
  pom.click();
};

type DataRow = {
  [key: string]: any;
};

export const downloadExcelXLSX = (filteredData: () => DataRow[], tableName: string = "table-data") => {

  const formatHeader = (header: string): string => {
    return header
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const stripHtmlTags = (str: string): string => {
    if (!str) return '';
    return str
        .replace(/<br\s*\/?>/gi, '')
        .replace(/<p\s*.*?>/gi, '')
        .replace(/<\/p>/gi, '')
        .replace(/<[^>]*>/g, '');
  };

  const data: DataRow[] = filteredData();

  if (!data || data.length === 0) {
    console.error("No data to export");
    return;
  }

  const headers: string[] = Object.keys(data[0]).map(formatHeader);

  const processedData: DataRow[] = data.map((row) => {
    const newRow: DataRow = {};
    Object.keys(row).forEach((key) => {
      if (key === "body") {
        newRow[formatHeader(key)] = stripHtmlTags(row[key]);
      } else {
        newRow[formatHeader(key)] = row[key];
      }
    });
    return newRow;
  });


  const worksheet = XLSX.utils.json_to_sheet(processedData, { header: headers });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  XLSX.writeFile(workbook, `${tableName}.xlsx`);
};


export const downloadCSVByArray = (data: any) => {
  const keys = Object.keys(data[0]);
  let finalData = [];
  let ix: number;
  let firstRow = [];
  for (ix = 0; ix <= keys.length - 1; ix++) {
    firstRow.push(keys[ix]);
  }
  finalData.push(firstRow);

  for (ix = 0; ix <= data.length - 1; ix++) {
    let row = Object.keys(data[ix]).map(function (key) {
      return data[ix][key] ? data[ix][key].toString() : "";
    });

    finalData.push(row);
  }
  var content = finalData;

  var finalVal = "";

  for (var i = 0; i < content.length; i++) {
    var value = content[i];

    for (var j = 0; j < value.length; j++) {
      var innerValue = value[j];
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ";";
      finalVal += result;
    }

    finalVal += "\n";
  }

  var pom = document.createElement("a");
  pom.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(finalVal),
  );
  pom.setAttribute("download", "export.csv");
  pom.click();
};

// TPTextBox and TPTextArea specific function to handle invalid character validation and cleanup
export const handleInvalidCharacters = (
  value: string,
  invalidCharRegex: RegExp,
  invalidCharacterLabel: string,
  setErrorMessage: (message: string) => void,
  setInputValue: (value: string) => void,
  onChange: Function,
  e: any
) => {
  const foundInvalidChar = value.match(invalidCharRegex);

  if (foundInvalidChar) {
    setErrorMessage(`${invalidCharacterLabel} "${foundInvalidChar[0]}"`);

    const cleanedValue = value.replace(invalidCharRegex, "");

    setInputValue(cleanedValue);

    onChange && onChange({ ...e, target: { ...e.target, value: cleanedValue } });

    setTimeout(() => {
      setErrorMessage("");
    }, 5000); // 5 seconds delay
  } else {
    setErrorMessage("");

    setInputValue(value);

    onChange && onChange({ ...e, target: { ...e.target, value: value } });
  }
};

// Quill-specific function to handle invalid character validation and cleanup
export const handleInvalidCharactersQuillEditor = ({
  delta,
  content,
  invalidCharRegex,
  invalidCharacterLabel,
  setLocalErrorMessage,
  setInputValue,
  onChange,
  quillRef
}: {
  delta:DeltaStatic;
  content: string;
  invalidCharRegex: RegExp;
  invalidCharacterLabel: string;
  setLocalErrorMessage: (message: string) => void;
  setInputValue: (value: string) => void;
  onChange?: (value: string) => void;
  quillRef:React.RefObject<ReactQuill>;
}) => {
  const insertedText = delta?.ops?.find((op) =>"insert" in op && typeof op.insert === "string")?.insert || "";

  if (invalidCharRegex.test(insertedText)) {
    // Set local error message immediately

    const quill = quillRef.current?.getEditor();
    const currentSelection = quill?.getSelection();
   
    if(currentSelection){
       quill?.deleteText((currentSelection.index-insertedText.length), insertedText.length)
   
       const invalidChar = insertedText.match(invalidCharRegex)
       setLocalErrorMessage(`${invalidCharacterLabel} "${invalidChar[0]}"`);
       
    // Clear the error message after 5 seconds
    setTimeout(() => {
      setLocalErrorMessage("");
    }, 5000);
  }
  } else {
    setInputValue(content);
    onChange && onChange(content); // Call onChange prop if it exists
  }
};

export const capitalizeText = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export const getImagePrefix = (src: string): string => {
  if (src.startsWith("/9j/")) return "data:image/jpeg;base64,";
  if (src.startsWith("iVBORw0KGgo")) return "data:image/png;base64,";
  if (src.startsWith("R0lGOD")) return "data:image/gif;base64,";
  if (src.startsWith("/9A/")) return "data:image/jpg;base64,";
  return "";
};
