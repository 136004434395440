import React, { useEffect, useMemo, useCallback, useState } from "react";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import TPHorizontalTabs from "@/components/TPTabs/TPHorizontalTabs";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import "./styles/ConfigurationGeneral.css";
import CustomerInformationComponent from "./components/CustomerInformationComponent";
import ModulesComponent from "./components/ModulesComponent";
import OutboundMailComponent from "./components/OutboundMailComponent";
import ServicesComponent from "./components/ServicesComponent";
import UsersComponent from "./components/UsersComponent";
import CustomizationsComponent from "./components/CustomizationsComponent";
import {
  ConfigurationProvider,
  useConfiguration,
} from "./context/ConfigurationContext";
import useTranslations from "./hooks/useTranslations";

const ConfigurationGeneralContent: React.FC = () => {
  const { isLoadingScreen } = useConfiguration();

  const RESOURCE_SET = "ConfigurationGeneralComponent";
  const COMPONENT_NAME = "ConfigurationGeneral.tsx";
  const profileTexts = useTranslations(
    {
      pageTitle: "PageTitle",
      customizationsTitle: "CustomizationsTitle",
      customerInformationTitle: "CustomerInformationTitle",
      modulesTitle: "ModulesTitle",
      outboundMailTitle: "OutboundMailTitle",
      servicesTitle: "ServicesTitle",
      usersTitle: "UsersTitle",
      saveButton: "SaveButton",
    },
    RESOURCE_SET,
    COMPONENT_NAME
  );

  const [activeTab, setActiveTab] = useState<number>(0);

  const tabLabels = useMemo(
    () => [
      profileTexts.customizationsTitle,
      profileTexts.customerInformationTitle,
      profileTexts.modulesTitle,
      profileTexts.outboundMailTitle,
      profileTexts.servicesTitle,
      profileTexts.usersTitle,
    ],
    [profileTexts]
  );

  const renderTabContent = useMemo(() => {
    const components = [
      <CustomizationsComponent key="customizations" />,
      <CustomerInformationComponent key="customerInformation" />,
      <ModulesComponent key="modules" />,
      <OutboundMailComponent key="outboundMail" />,
      <ServicesComponent key="services" />,
      <UsersComponent key="users" />,
    ];
    return components[activeTab];
  }, [activeTab]);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  return (
    <ContentVerticalNoTabsStyled>
      <div className="container-fluid">
        <TPLoadingOverlay active={isLoadingScreen}>
          <TPPageTitle>{profileTexts.pageTitle}</TPPageTitle>
          <TPHorizontalTabs
            labels={tabLabels}
            activeTab={activeTab}
            onChange={handleTabChange}
          />
          <div style={{ padding: "20px", minHeight: "700px" }}>
            {renderTabContent}
          </div>
        </TPLoadingOverlay>
      </div>
    </ContentVerticalNoTabsStyled>
  );
};

const ConfigurationGeneral: React.FC = () => (
  <ConfigurationProvider>
    <ConfigurationGeneralContent />
  </ConfigurationProvider>
);

export default ConfigurationGeneral;
