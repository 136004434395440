import TPGlobal from "@/helpers/TPGlobal";
import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { CalendarHeaderStyle } from "./StyeMyReminders";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import { MyRemindersSlice, MyRemindersSliceModel } from "./MyRemindersSlice";
import { EventContentArg, EventSourceInput } from "@fullcalendar/core";
import "./MyRemindersStyles.css";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import { TPI18N } from "@/services/I18nService";
import { taskStatusEnum, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";

interface InputModel {
    callBackCommands: Function;
    active: boolean;
}

type StatusColor = {
    [x in taskStatusEnum]: string;
}

type StatusIcon = {
    [x in taskStatusEnum]: string;
}

const statusColors: StatusColor = {
    "SO": "#B1F2D7",
    "PE": "#FFE4B3",
    "EX": "#FFB3B3",
    "LA": "#efffb3",
    "AL": "",
    "IH": "",
    "OH": "",
    "TS": ""
}

const statusIcons: StatusIcon = {
    "SO": "solved",
    "PE": "pending",
    "EX": "expired",
    "LA": "lag",
    "AL": "",
    "IH": "",
    "OH": "",
    "TS": ""
}

const getStatusIcon = function( status: taskStatusEnum, size?: string ) {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "20px",
                width: "20px",
                borderRadius: "50%",
                backgroundColor: statusColors[status],
                flexShrink: 0
            }}
        >
            <TPIcon 
                iconType={statusIcons[status] as TPIconTypes}
                style={{
                    fontSize: "16px"
                }}
            />
        </div>
    )
}

const MyRemindersCalendar = forwardRef(
    ({ callBackCommands, active }: InputModel, ref) => {
        const { reminders, remindersLoaded: contentLoaded } = useSelector(
            (s: StoreModel) => s[MyRemindersSlice.name]
        ) as MyRemindersSliceModel;
    const casecontext: any = useContext(TPCaseViewerContext);
    const [todayLabel, setTodayLabel] = useState("");
    const calendarRef = useRef<any>(null);

    const loadResources = async function() {
        setTodayLabel(await TPI18N.GetText("Reminders","Today"));
    }

    useEffect(() => {
        loadResources();
    }, [])

    const renderReminder = function (event: EventContentArg) {
        const fixedDate = new Date(event.event.start?.toISOString() || "");
        const view = calendarRef.current?.calendar.currentData.currentViewType;
        return (
            <div 
                className="calendar-reminder"
                style={{
                    fontSize: view === "timeGridDay" ? "16px" : "12px",
                    gap: view  === "timeGridDay" ? "8px" : "2px",
                }}
            >
                {getStatusIcon(event.event.extendedProps.status as taskStatusEnum)}
                <button
                    type="button"
                    className="reminder-case-id-button"
                    onClick={() => casecontext.handleAddNewCaseViewerCallBack(event.event.extendedProps.case)}
                >
                    {event.event.title.length > 15 ? event.event.title.slice(0,15)+"..." : event.event.title}
                </button>
                {event.event.start ? fixedDate.toLocaleTimeString().slice(0,5) : "???"}
            </div>
        );
    };

    return (
        <>
            {active && contentLoaded && (
                <CalendarHeaderStyle>
                    <FullCalendar
                        initialView="dayGridMonth"
                        buttonText={{
                            prevYear: "<<",
                            prev: "<",
                            next: ">",
                            nextYear: ">>",
                            today: todayLabel,
                        }}
                        initialDate={new Date()}
                        themeSystem="Journal"
                        plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                            listPlugin,
                        ]}
                        dayMaxEvents={2}
                        height={750}
                        slotDuration={"00:20:00"}
                        slotEventOverlap={false}
                        allDaySlot={false}
                        slotLabelInterval={"01:00:00"}
                        dayMaxEventRows={2}
                        eventMaxStack={1}
                        slotMinWidth={64}
                        moreLinkText={n => `+${n}`}
                        events={
                            reminders.map((r) => {
                                const fixedDate = new Date(String(r.date).concat('Z'));
                                return {
                                    id: String(r.caseId)+String(r.taskId),
                                    title: r.comments,
                                    date: fixedDate,
                                    extendedProps: {
                                        case: r.caseId,
                                        status: r.statusId
                                    }
                                };
                            }) as EventSourceInput
                        }
                        locale={TPGlobal.language || "en"}
                        locales={allLocales}
                        headerToolbar={{
                            left: "prevYear,prev,next,nextYear today",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        eventContent={renderReminder}
                        ref={calendarRef}
                    />
                </CalendarHeaderStyle>
            )}
        </>
    );
});

export default MyRemindersCalendar;
