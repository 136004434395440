import NoDataImg from "@/assets/images/TPImages/tp-image-grid.png";
import NoResultsImg from "@/assets/images/no-data.png";
import { TPChip } from "@/components/TPChip/TPChip";
import { TPPaginator } from "@/components/TPPaginator/TPPaginator";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPModal from "@/layouts/TPModal/TPModal";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { StyledNoDataImage } from "@/modules/core/components/dynamic-table/dynamic-table-styles";
import { GroupsService } from "@/services/GroupsService";
import { UserGroupService } from "@/services/UserGroupService";
import "assets/images/TPImages/tp-image-grid.png";
import { FC, useEffect, useState } from "react";
import "../../assets/Styles.css";
import { useQueuesAdminSelectOptions } from "../../assets/controller";
import useQueueAdminLabels from "../../assets/labels";
import QueueAdminUserCheckbox from "../UserCheckbox";
import TablePager from "@/modules/core/components/dynamic-table/TablePager";

interface QueueAssignUsersTabProperties {
  groupId: number;
  tabCallback: Function;
  active: boolean;
  updateCallback: Function;
}

interface QueueAssignModel {
  id: string;
  name: string;
  email: string;
  selected: boolean;
  active: boolean;
}

const QueueAssignUsersTab: FC<QueueAssignUsersTabProperties> = function ({
  groupId,
  tabCallback,
  updateCallback,
  active,
}) {
  const { labels, labelsLoaded } = useQueueAdminLabels();
  const [usersLoaded, setUsersLoaded] = useState<boolean>(false);

  const [assignedUsers, setAssignedUsers] = useState<QueueAssignModel[]>([]);
  const [filteredAssignedUsers, setFilteredAssignedUsers] = useState<
    QueueAssignModel[]
  >([]);
  const [assignedUsersPage, setAssignedUsersPage] = useState<number>(1);
  const [assignedUsersRowsPerPage, setAssignedUsersRowsPerPage] =
    useState<number>(12);
  const [assignedUsersQuery, setAssignedUsersQuery] = useState<string>("");

  const [allUnassignedSelected, setAllUnassignedSelected] =
    useState<boolean>(false);
  const [unassignedUsers, setUnassignedUsers] = useState<QueueAssignModel[]>(
    []
  );
  const [filteredUnassignedUsers, setFilteredUnassignedUsers] = useState<
    QueueAssignModel[]
  >([]);
  const [unassignedUsersPage, setUnassignedUsersPage] = useState<number>(1);
  const [unassignedUsersRowsPerPage, setUnassignedUsersRowsPerPage] =
    useState<number>(12);
  const [unassignedUsersQuery, setUnassignedUsersQuery] = useState<string>("");

  const [profileFilter, setProfileFilter] = useState<string>("");
  const [teamFilter, setTeamFilter] = useState<string>("");
  const [superiorFilter, setSuperiorFilter] = useState<string>("");
  const [functionsFilter, setFunctionsFilter] = useState<string>("");
  const [nameFilter, setNameFilter] = useState<string>("");

  const [dataSorted, setDataSorted] = useState<boolean>(true);
  const [contentLoaded, setContentLoaded] = useState<boolean>(false);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [cancelWarningVisible, setCancelWarningVisible] =
    useState<boolean>(false);
  const [deselectedUser, setDeselectedUser] = useState<QueueAssignModel | null>(
    null
  );
  const [canAssign, setCanAssign] = useState(false);
  const [processingAssign, setProcessingAssign] = useState(false);

  const {
    teamsFilterOptions,
    profileFilterOptions,
    superiorFilterOptions,
    functionsFilterOptions,
  } = useQueuesAdminSelectOptions();

  const redrawTable = function () {
    setContentLoaded(false);
    setFilteredAssignedUsers(
      assignedUsers.filter(
        (user) =>
          user.name?.toLowerCase().includes(assignedUsersQuery.toLowerCase()) ||
          user.email
            ?.toLocaleLowerCase()
            .includes(assignedUsersQuery.toLowerCase())
      )
    );

    setFilteredUnassignedUsers(
      unassignedUsers.filter(
        (user) =>
          (user.name
            ?.toLowerCase()
            .includes(unassignedUsersQuery.toLowerCase()) ||
            user.email
              ?.toLocaleLowerCase()
              .includes(unassignedUsersQuery.toLowerCase())) &&
          !assignedUsers.find((assigned) => assigned.id == user.id)
      )
    );

    if (
      assignedUsersPage >
      Math.ceil(filteredAssignedUsers.length / assignedUsersRowsPerPage)
    ) {
      setAssignedUsersPage(1);
    }
    if (
      unassignedUsersPage >
      Math.ceil(filteredUnassignedUsers.length / unassignedUsersRowsPerPage)
    ) {
      setUnassignedUsersPage(1);
    }
    setContentLoaded(true);
  };

  useEffect(() => {
    setAssignedUsersPage(1);
  }, [assignedUsersQuery]);

  useEffect(() => {
    setUnassignedUsersPage(1);
    if (unassignedUsersQuery.trim().length > 0 && unassignedUsers.length > 0)
      setNoDataFound(true);
  }, [unassignedUsersQuery]);

  const handleMassiveUpdate = function () {
    if (!canAssign) return;
    setCanAssign(false);
    setProcessingAssign(true);
    setAllUnassignedSelected(false);
    const groupServiceInstance = new GroupsService();
    const unassignedSelected = filteredUnassignedUsers.filter(
      (user) => user.selected
    );

    if (unassignedSelected.length > 0) {
      groupServiceInstance
        .assignUsersToGroup(
          {
            idGroup: groupId,
            usersIds: unassignedSelected.map((user) => Number(user.id)),
          },
          true,
          true,
          [200]
        )
        .then(() => {
          setContentLoaded(false);
          setCanAssign(true);
          updateCallback({
            result: "ReloadGrid",
          });
        })
        .catch((err) => console.error(err))
        .finally(() => setProcessingAssign(false));
    }
  };

  const unassignUserById = function (id: any) {
    const groupServiceInstance = new GroupsService();
    groupServiceInstance
      .unassignUserToGroup(String(groupId), id, true, true, [200])
      .then(() => {
        if (filteredUnassignedUsers.length > 0) getUsersByFilter();
        setContentLoaded(false);
        updateCallback({
          result: "ReloadGrid",
        });
      });
  };

  const getUsersByFilter = function () {
    setContentLoaded(false);
    const userGroupServiceInstance = new UserGroupService();
    userGroupServiceInstance
      .getUsersByFilters(
        {
          groupId: groupId,
          id_FUNC:
            functionsFilter.replace("--", "").length > 0
              ? functionsFilter
              : null,
          id_PROF:
            profileFilter.replace("--", "").length > 0 ? profileFilter : null,
          name:
            nameFilter.replace("--", "").length > 0
              ? nameFilter.trim().toLocaleLowerCase()
              : null,
          superior_Id_USER:
            superiorFilter.replace("--", "").length > 0 ? superiorFilter : null,
          team_Id_BRAN:
            teamFilter.replace("--", "").length > 0 ? teamFilter : null,
        },
        false,
        false,
        [200]
      )
      .then((response) => {
        setContentLoaded(true);
        if (response && response.length > 0) {
          setNoDataFound(false);
          setUnassignedUsers(
            response.map((item) => {
              return {
                email: item.user.email,
                id: item.user.id,
                name: item.user.name,
                selected: false,
                active: item.user.isActive,
              } as QueueAssignModel;
            })
          );
        } else {
          setUnassignedUsers([]);
          setNoDataFound(true);
        }
      });
  };

  useEffect(() => {
    if (contentLoaded) return;
    const groupServiceInstance = new GroupsService();
    groupServiceInstance
      .getAssignedUsers(String(groupId), false, false, [200])
      .then((response) =>
        setAssignedUsers(
          response.map((item) => {
            return {
              id: item.user.id,
              name: item.user.name,
              email: item.user.email,
              selected: true,
              active: item.user.isActive,
            } as QueueAssignModel;
          })
        )
      );
  }, [contentLoaded]);

  useEffect(() => {
    redrawTable();
  }, [
    assignedUsers,
    unassignedUsers,
    assignedUsersPage,
    assignedUsersRowsPerPage,
    unassignedUsersPage,
    unassignedUsersRowsPerPage,
    assignedUsersQuery,
    unassignedUsersQuery,
  ]);

  useEffect(() => {
    if (!dataSorted) {
      setDataSorted(true);
      setAssignedUsers([...assignedUsers.map((user) => user)]);
      setUnassignedUsers([...unassignedUsers.map((user) => user)]);
    }
  }, [dataSorted]);

  useEffect(() => {
    setFilteredUnassignedUsers([
      ...filteredUnassignedUsers.map((user) => {
        return {
          ...user,
          selected: allUnassignedSelected,
        } as QueueAssignModel;
      }),
    ]);
    setUnassignedUsers([
      ...unassignedUsers.map((user) => {
        return {
          ...user,
          selected: allUnassignedSelected,
        } as QueueAssignModel;
      }),
    ]);
  }, [allUnassignedSelected]);

  useEffect(() => {
    setCanAssign(
      filteredUnassignedUsers.length > 0 &&
        filteredUnassignedUsers.filter((user) => user.selected).length > 0
    );
  }, [filteredUnassignedUsers]);

  return (
    <>
      {cancelWarningVisible && (
        <TPModal
          modalState={{
            id: "cancel-user-assign-modal",
            acceptLabel: labels.Yes,
            callBackAnswer: () => setCancelWarningVisible(false),
            cancelLabel: labels.No,
            isShown: true,
            titleModal: "",
            hideFooterButtons: true,
            modalWidth: "512px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              gap: "24px",
              padding: "24px",
            }}
          >
            <h5>
              <b>{labels.SystemAlert}</b>
            </h5>
            <label>{labels.CancelConfirm}</label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "50%",
                gap: "16px",
              }}
            >
              <TPButton
                isDesignSystem
                onClick={() => {
                  setCancelWarningVisible(false);
                }}
                style={{
                  backgroundColor: "white",
                  color: "purple",
                  paddingTop: "11px",
                  paddingBottom: "11px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {labels.No}
              </TPButton>
              <TPButton
                isDesignSystem
                onClick={() => {
                  tabCallback({
                    command: "delete",
                    recordId: String(groupId),
                  });
                  setCancelWarningVisible(false);
                }}
                style={{
                  paddingTop: "11px",
                  paddingBottom: "11px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {labels.Yes}
              </TPButton>
            </div>
          </div>
        </TPModal>
      )}
      {deselectedUser && (
        <TPModal
          modalState={{
            id: "cancel-user-assign-modal",
            acceptLabel: labels.Yes,
            callBackAnswer: () => setDeselectedUser(null),
            cancelLabel: labels.No,
            isShown: true,
            titleModal: "",
            hideFooterButtons: true,
            modalWidth: "512px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              gap: "24px",
              padding: "24px",
            }}
          >
            <h5>
              <b>{labels.SystemAlert}</b>
            </h5>
            <label>
              {labels.DeselectConfirm} {deselectedUser?.name}
              {Boolean(deselectedUser?.email) && " / " + deselectedUser?.email}?
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "50%",
                gap: "16px",
              }}
            >
              <TPButton
                isDesignSystem
                onClick={() => {
                  setDeselectedUser(null);
                }}
                style={{
                  backgroundColor: "white",
                  color: "purple",
                  paddingTop: "11px",
                  paddingBottom: "11px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {labels.No}
              </TPButton>
              <TPButton
                isDesignSystem
                onClick={() => {
                  unassignUserById(deselectedUser.id);
                  setDeselectedUser(null);
                }}
                style={{
                  paddingTop: "11px",
                  paddingBottom: "11px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {labels.Yes}
              </TPButton>
            </div>
          </div>
        </TPModal>
      )}
      {active && (
        <TPLoadingOverlay
          active={(!usersLoaded && !contentLoaded) || processingAssign}
        >
          {assignedUsers.length > 0 && (
            <div
              id="queue-general-info-container"
              className="queue-admin-tab"
              style={{ width: "100%", height: "min-content" }}
            >
              <div id={`queue-assign-users-accordion`} className="accordion">
                <div className="accordion-item">
                  <div className="accordion-header">
                    <button
                      id="queue-assigned-users-show-button"
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#queue-assigned-users"
                    >
                      <label style={{ color: "#253788" }}>
                        {labels.UsersAssigned}
                      </label>
                      <TPChip
                        label={assignedUsers.length}
                        backgroundColor="#FF0082"
                        accentColor="#FFFFFF"
                      />
                    </button>
                  </div>
                </div>
                <div
                  id="queue-assigned-users"
                  className="accordion-collapse collapse show"
                >
                  <div
                    className="accordion-body"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      width: "100%",
                    }}
                  >
                    <div className="top-table-controls">
                      <TPTextBox
                        onChange={(event: any) =>
                          setAssignedUsersQuery(event.target.value)
                        }
                        value={assignedUsersQuery}
                        icon={TPIconTypes.search}
                        withIcon
                        iconClick={() => {}}
                        containerStyle={{ width: "25%" }}
                        placeholder={labels.Search}
                      />
                    </div>
                    {filteredAssignedUsers.length > 0 ? (
                      <>
                        <div
                          style={{
                            overflowY: "scroll",
                            overflowX: "hidden",
                            padding: "16px",
                          }}
                          id="queue-assigned-users-table"
                          className="form-grid3"
                        >
                          {filteredAssignedUsers
                            .slice(
                              (assignedUsersPage - 1) *
                                assignedUsersRowsPerPage,
                              (assignedUsersPage - 1) *
                                assignedUsersRowsPerPage +
                                assignedUsersRowsPerPage
                            )
                            .map((user) => (
                              <QueueAdminUserCheckbox
                                email={user.email}
                                name={user.name}
                                active={user.active}
                                selected={user.selected}
                                onChange={(event: any) => {
                                  const checked = event.target.checked;
                                  if (!checked) setDeselectedUser(user);
                                  setDataSorted(false);
                                }}
                              />
                            ))}
                        </div>
                        <TablePager
                          allLabel=""
                          allResults={filteredAssignedUsers.length}
                          nextPageLabel={labels.NextPage}
                          ofLabel=""
                          onPagerChange={(page) => setAssignedUsersPage(page)}
                          onRowsPerPageChange={(e) =>
                            setAssignedUsersRowsPerPage(
                              parseInt(e.target.value)
                            )
                          }
                          page={assignedUsersPage}
                          pageLabel="page"
                          resultsPerPage={
                            filteredAssignedUsers.slice(
                              (assignedUsersPage - 1) *
                                assignedUsersRowsPerPage,
                              (assignedUsersPage - 1) *
                                assignedUsersRowsPerPage +
                                assignedUsersRowsPerPage
                            ).length
                          }
                          rowsPerPage={assignedUsersRowsPerPage}
                          selectedResults={0}
                          totalPages={Math.ceil(
                            filteredAssignedUsers.length /
                              assignedUsersRowsPerPage
                          )}
                          customRowsPerPageOptions={[12, 24, 48, 96]}
                        />
                      </>
                    ) : (
                      <>
                        <StyledNoDataImage>
                          <img src={NoDataImg} alt="no-data" />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ textAlign: "center" }}>
                              {labels.NoDataFound}
                            </div>
                          </div>
                        </StyledNoDataImage>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <br />
          <h5>
            <b>{labels.SearchNewUsers}</b>
          </h5>
          <div id="unassigned-users-table">
            {/* <div
              className="top-table-controls"
              style={{
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <div
                id="queue-unassigned-users-profile-filter"
                style={{ width: "100%" }}
              >
                <TPSelect
                  dataSource={profileFilterOptions}
                  onChange={(event: any) =>
                    setProfileFilter(event.target.value)
                  }
                  value={profileFilter}
                  labelText={labels.Profile}
                />
              </div>

              <div
                id="queue-unassigned-users-team-filter"
                style={{ width: "100%" }}
              >
                <TPSelect
                  dataSource={teamsFilterOptions}
                  onChange={(event: any) => setTeamFilter(event.target.value)}
                  value={teamFilter}
                  labelText={labels.Teams}
                />
              </div>

              <div
                id="queue-unassigned-users-functions-filter"
                style={{ width: "100%" }}
              >
                <TPSelect
                  dataSource={functionsFilterOptions}
                  onChange={(event: any) =>
                    setFunctionsFilter(event.target.value)
                  }
                  value={functionsFilter}
                  labelText={labels.Functions}
                />
              </div>

              <div
                id="queue-unassigned-users-schedule-filter"
                style={{ width: "100%" }}
              >
                <TPSelect
                  dataSource={superiorFilterOptions}
                  onChange={(event: any) =>
                    setSuperiorFilter(event.target.value)
                  }
                  value={superiorFilter}
                  labelText={labels.Superior}
                />
              </div>

              <div
                id="queue-unassigned-users-name-filter"
                style={{ width: "100%" }}
              >
                <TPTextBox
                  onChange={(event: any) => setNameFilter(event.target.value)}
                  value={nameFilter}
                  labelText={labels.Name}
                />
              </div>

              <TPButton
                type={TPButtonTypes.primary}
                onClick={() => getUsersByFilter()}
                isDesignSystem
                style={{
                  paddingTop: "11px",
                  paddingBottom: "11px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {labels.Apply}
              </TPButton>
            </div> */}
            <div className="container">
              <div className="row">
                <div
                  className="col-2"
                  id="queue-unassigned-users-profile-filter"
                >
                  <TPSelect
                    dataSource={profileFilterOptions}
                    onChange={(event: any) =>
                      setProfileFilter(event.target.value)
                    }
                    value={profileFilter}
                    labelText={labels.Profile}
                  />
                </div>

                <div className="col-2" id="queue-unassigned-users-team-filter">
                  <TPSelect
                    dataSource={teamsFilterOptions}
                    onChange={(event: any) => setTeamFilter(event.target.value)}
                    value={teamFilter}
                    labelText={labels.Teams}
                  />
                </div>
                <div
                  className="col-2"
                  id="queue-unassigned-users-functions-filter"
                >
                  <TPSelect
                    dataSource={functionsFilterOptions}
                    onChange={(event: any) =>
                      setFunctionsFilter(event.target.value)
                    }
                    value={functionsFilter}
                    labelText={labels.Functions}
                  />
                </div>
                <div
                  className="col-2"
                  id="queue-unassigned-users-schedule-filter"
                >
                  <TPSelect
                    dataSource={superiorFilterOptions}
                    onChange={(event: any) =>
                      setSuperiorFilter(event.target.value)
                    }
                    value={superiorFilter}
                    labelText={labels.Superior}
                  />
                </div>
                <div className="col-2" id="queue-unassigned-users-name-filter">
                  <TPTextBox
                    onChange={(event: any) => setNameFilter(event.target.value)}
                    value={nameFilter}
                    labelText={labels.Name}
                  />
                </div>
                <div className="col-2 mt-4">
                  <TPButton
                    type={TPButtonTypes.primary}
                    onClick={() => getUsersByFilter()}
                    isDesignSystem
                    style={{
                      paddingTop: "11px",
                      paddingBottom: "11px",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                  >
                    {labels.Apply}
                  </TPButton>
                </div>
              </div>
            </div>

            {unassignedUsers.length > 0 && (
              <div
                className="top-table-controls"
                style={{ justifyContent: "space-between" }}
              >
                {filteredUnassignedUsers.length > 0 && (
                  <TPCheckBox
                    checked={allUnassignedSelected}
                    onChange={(event: any) =>
                      setAllUnassignedSelected(event.target.checked)
                    }
                    labelText={labels.SelectAll}
                  />
                )}
                <TPTextBox
                  onChange={(event: any) =>
                    setUnassignedUsersQuery(event.target.value)
                  }
                  value={unassignedUsersQuery}
                  icon={TPIconTypes.search}
                  withIcon
                  iconClick={() => {}}
                  containerStyle={{ width: "25%" }}
                  placeholder={labels.Search}
                />
              </div>
            )}
            {filteredUnassignedUsers.length > 0 ? (
              <div
                id="queue-unassigned-users-table"
                style={{
                  overflowY: "scroll",
                  overflowX: "hidden",
                  padding: "16px",
                }}
                className="form-grid3"
              >
                {filteredUnassignedUsers
                  .slice(
                    (unassignedUsersPage - 1) * unassignedUsersRowsPerPage,
                    (unassignedUsersPage - 1) * unassignedUsersRowsPerPage +
                      unassignedUsersRowsPerPage
                  )
                  .map((user) => (
                    <QueueAdminUserCheckbox
                      email={user.email}
                      name={user.name}
                      selected={user.selected}
                      onChange={(event: any) => {
                        user.selected = event.target.checked;
                        setDataSorted(false);
                      }}
                      active={user.active}
                    />
                  ))}
              </div>
            ) : (
              <>
                {noDataFound ? (
                  <StyledNoDataImage>
                    <img src={NoResultsImg} alt="no-data" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        {labels.NoDataFound}
                      </div>
                    </div>
                  </StyledNoDataImage>
                ) : (
                  <StyledNoDataImage>
                    <img src={NoDataImg} alt="no-data" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        {labels.NothingToSeeYet}
                      </div>
                      <b style={{ textAlign: "center" }}>
                        {labels.SearchToGetResults}
                      </b>
                    </div>
                  </StyledNoDataImage>
                )}
              </>
            )}
            {filteredUnassignedUsers.length > 0 && (
              <TablePager
                allLabel=""
                allResults={filteredUnassignedUsers.length}
                nextPageLabel={labels.NextPage}
                ofLabel=""
                onPagerChange={(page) => setUnassignedUsersPage(page)}
                onRowsPerPageChange={(e) =>
                  setUnassignedUsersRowsPerPage(parseInt(e.target.value))
                }
                page={unassignedUsersPage}
                pageLabel="page"
                resultsPerPage={
                  filteredUnassignedUsers.slice(
                    (unassignedUsersPage - 1) * unassignedUsersRowsPerPage,
                    (unassignedUsersPage - 1) * unassignedUsersRowsPerPage +
                      unassignedUsersRowsPerPage
                  ).length
                }
                rowsPerPage={unassignedUsersRowsPerPage}
                selectedResults={
                  filteredUnassignedUsers.filter((user) => user.selected).length
                }
                totalPages={Math.ceil(
                  filteredUnassignedUsers.length / unassignedUsersRowsPerPage
                )}
                customRowsPerPageOptions={[12, 24, 48, 96]}
              />
            )}
            <br />
          </div>
          <div className="option-button-pair">
            <TPButton
              onClick={() => setCancelWarningVisible(true)}
              isDesignSystem
              style={{
                backgroundColor: "white",
                color: "purple",
                paddingTop: "11px",
                paddingBottom: "11px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              {labels.Cancel}
            </TPButton>
            <TPButton
              onClick={() => handleMassiveUpdate()}
              type={TPButtonTypes.primary}
              disabled={
                filteredUnassignedUsers.length == 0 ||
                filteredUnassignedUsers.filter((user) => user.selected)
                  .length == 0 ||
                !contentLoaded ||
                !canAssign
              }
              isDesignSystem
              style={{
                paddingTop: "11px",
                paddingBottom: "11px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              {labels.AssignUsers}
            </TPButton>
          </div>
        </TPLoadingOverlay>
      )}
    </>
  );
};

export default QueueAssignUsersTab;
