import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import {FC, ReactElement, useEffect, useRef, useState} from "react";

import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";

import { TPTableCondensedContainer } from "@/components/bootstrap/content/tables/tpTableStyles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  SequenceGeneratorSequencesNameEnum,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import {
  SchedulesInputDTO,
  SchedulesInputDTOValidator,
} from "@/models/Schedules/SchedulesInputDTO";
import { ScheduleViewModel } from "@/models/Schedules/SchedulesModels";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { SchedulesService } from "@/services/SchedulesService";
import { SequenceService } from "@/services/SequenceService";
import {MultilingualTextBoxEvents} from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};

type InsertUpdateStateType = {
  id: string;
  recordLanguageList: Array<TPKeyValue>;
  isActive: boolean;
  nameErrorMessages: Array<string>;
  idErrorMessage: string;
  isActiveMonday: boolean;
  isActiveTuesday: boolean;
  isActiveWednesday: boolean;
  isActiveThursday: boolean;
  isActiveFriday: boolean;
  isActiveSaturday: boolean;
  isActiveSunday: boolean;
  isActiveHoliday: boolean;
  isActiveHalfHoliday: boolean;
  selectedStartMonday: number;
  selectedEndMonday: number;
  selectedStartTuesday: number;
  selectedEndTuesday: number;
  selectedStartWednesday: number;
  selectedEndWednesday: number;
  selectedStartThursday: number;
  selectedEndThursday: number;
  selectedStartFriday: number;
  selectedEndFriday: number;
  selectedStartSaturday: number;
  selectedEndSaturday: number;
  selectedStartSunday: number;
  selectedEndSunday: number;
  selectedStartHoliday: number;
  selectedEndHoliday: number;
  selectedStartHalfHoliday: number;
  selectedEndHalfHoliday: number;
  errorMessageInitialHourMonday: string;
  errorMessageEndHourMonday: string;
  errorMessageInitialHourTuesday: string;
  errorMessageEndHourTuesday: string;
  errorMessageInitialHourWednesday: string;
  errorMessageEndHourWednesday: string;
  errorMessageInitialHourThursday: string;
  errorMessageEndHourThursday: string;
  errorMessageInitialHourFriday: string;
  errorMessageEndHourFriday: string;
  errorMessageInitialHourSaturday: string;
  errorMessageEndHourSaturday: string;
  errorMessageInitialHourSunday: string;
  errorMessageEndHourSunday: string;
  errorMessageInitialHourHoliday: string;
  errorMessageEndHourHoliday: string;
  errorMessageInitialHourHalfHoliday: string;
  errorMessageEndHourHalfHoliday: string;
};

const SchedulesInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
}): ReactElement => {
  //#region  Init
  const componentFileName: string = "SchedulesInsertUpdate.tsx";

  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //Screen resources
  const ResourceSet: string = "ScheduleInsertUpdateComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [idLabel, setIdLabel] = useState("");
  const [nameLabel, setNameLabel] = useState("");
  const [isActiveLabel, setIsActiveLabel] = useState("");
  const [dayLabel, setDayLabel] = useState("");
  const [isWorkingDayLabel, setIsWorkingDayLabel] = useState("");
  const [startTimeLabel, setStartTimeLabel] = useState("");
  const [endTimeLabel, setEndTimeLabel] = useState("");
  const [mondayLabel, setMondayLabel] = useState("");
  const [tuesdayLabel, setTuesdayLabel] = useState("");
  const [wednesdayLabel, setWednesdayLabel] = useState("");
  const [thursdayLabel, setThursdayLabel] = useState("");
  const [fridayLabel, setFridayLabel] = useState("");
  const [saturdayLabel, setSaturdayLabel] = useState("");
  const [sundayLabel, setSundayLabel] = useState("");
  const [holidayLabel, setHolidayLabel] = useState("");
  const [halfHolidayLabel, setHalfHolidayLabel] = useState("");
  const [copyFromPreviousLabel, setCopyFromPreviousLabel] = useState("");

  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");

  const [referenceId, setReferenceId] = useState("");

  const nameInputRef = useRef<MultilingualTextBoxEvents>();
  //Screen state
  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }
  let insertUpdateInitialState: InsertUpdateStateType = {
    id: recordId,
    recordLanguageList: [],
    isActive: true,
    nameErrorMessages: initialErrorMessages,
    idErrorMessage: "",
    isActiveMonday: true,
    isActiveTuesday: true,
    isActiveWednesday: true,
    isActiveThursday: true,
    isActiveFriday: true,
    isActiveSaturday: true,
    isActiveSunday: false,
    isActiveHoliday: false,
    isActiveHalfHoliday: false,
    selectedStartMonday: 0,
    selectedEndMonday: 0,
    selectedStartTuesday: 0,
    selectedEndTuesday: 0,
    selectedStartWednesday: 0,
    selectedEndWednesday: 0,
    selectedStartThursday: 0,
    selectedEndThursday: 0,
    selectedStartFriday: 0,
    selectedEndFriday: 0,
    selectedStartSaturday: 0,
    selectedEndSaturday: 0,
    selectedStartSunday: 0,
    selectedEndSunday: 0,
    selectedStartHoliday: 0,
    selectedEndHoliday: 0,
    selectedStartHalfHoliday: 0,
    selectedEndHalfHoliday: 0,
    errorMessageInitialHourMonday: "",
    errorMessageEndHourMonday: "",
    errorMessageInitialHourTuesday: "",
    errorMessageEndHourTuesday: "",
    errorMessageInitialHourWednesday: "",
    errorMessageEndHourWednesday: "",
    errorMessageInitialHourThursday: "",
    errorMessageEndHourThursday: "",
    errorMessageInitialHourFriday: "",
    errorMessageEndHourFriday: "",
    errorMessageInitialHourSaturday: "",
    errorMessageEndHourSaturday: "",
    errorMessageInitialHourSunday: "",
    errorMessageEndHourSunday: "",
    errorMessageInitialHourHoliday: "",
    errorMessageEndHourHoliday: "",
    errorMessageInitialHourHalfHoliday: "",
    errorMessageEndHourHalfHoliday: "",
  };
  const [insertUpdateState, setInsertUpdateState] = useState(
    insertUpdateInitialState,
  );

  //Multilanguage const
  const multilanguageTableName: String = "SCHEdule";
  const multilanguageFieldName: String = "Description_SCHE";

  let hoursKeyValue: Array<TPKeyValue> = [];
  let iCounter: number = 0.0;
  while (iCounter <= 24.0) {
    let oneKeyValue: TPKeyValue = {
      key: iCounter.toString(),
      value: TPGlobal.HHMM_FromNumber(iCounter),
    };
    hoursKeyValue.push(oneKeyValue);
    iCounter = iCounter + 0.25;
  }
  //#endregion

  const loadResourcesAndLoadInfo = async () => {
    let i: number;
    //resources state
    setTitleLabel(await TPI18N.GetText(ResourceSet, "Title" + mode + "Label"));
    setSubTitleLabel(await TPI18N.GetText(ResourceSet, "SubtitleLabel"));
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel",
      ),
    );
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );

    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );
    setIdLabel(await TPI18N.GetText(ResourceSet, "IdLabel"));
    setNameLabel(await TPI18N.GetText(ResourceSet, "DescriptionLabel"));
    setIsActiveLabel(await TPI18N.GetText(ResourceSet, "IsActiveLabel"));

    setDayLabel(await TPI18N.GetText(ResourceSet, "DayTitleLabel"));
    setIsWorkingDayLabel(
      await TPI18N.GetText(ResourceSet, "IsWorkingDayTitleLabel"),
    );

    setStartTimeLabel(await TPI18N.GetText(ResourceSet, "StartTimeTitleLabel"));
    setEndTimeLabel(await TPI18N.GetText(ResourceSet, "EndTimeTitleLabel"));
    //Monday
    setMondayLabel(await TPI18N.GetText(ResourceSet, "MondayLabel"));
    //Tuesday
    setTuesdayLabel(await TPI18N.GetText(ResourceSet, "TuesdayLabel"));
    //Wednesday
    setWednesdayLabel(await TPI18N.GetText(ResourceSet, "WednesdayLabel"));
    //Thursday
    setThursdayLabel(await TPI18N.GetText(ResourceSet, "ThursdayLabel"));
    //Friday
    setFridayLabel(await TPI18N.GetText(ResourceSet, "FridayLabel"));
    //Saturday
    setSaturdayLabel(await TPI18N.GetText(ResourceSet, "SaturdayLabel"));
    //Sunday
    setSundayLabel(await TPI18N.GetText(ResourceSet, "SundayLabel"));
    //Holiday
    setHolidayLabel(await TPI18N.GetText(ResourceSet, "HolidayLabel"));
    //HalfHoliday
    setHalfHolidayLabel(await TPI18N.GetText(ResourceSet, "HalfHolidayLabel"));
    //Copy from previous
    setCopyFromPreviousLabel(
      await TPI18N.GetText(ResourceSet, "CopyFromPreviousButton"),
    );

    //screen state

    if (mode == "Update") {
      await getScheduleById(recordId);
    }
    if (mode == "Insert") {
      let newInsertUpdateState = { ...insertUpdateState };
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }
  };

  const handleOnIdChange = (newId: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.id = newId;
    newInsertUpdateState.idErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.nameErrorMessages[index] = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleIsActiveChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActive = !newInsertUpdateState.isActive;
    setInsertUpdateState(newInsertUpdateState);
  };

  //#region monday
  const handleIsWorkingMondayChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActiveMonday = !newInsertUpdateState.isActiveMonday;
    newInsertUpdateState.errorMessageInitialHourMonday = "";
    newInsertUpdateState.errorMessageEndHourMonday = "";
    if (!newInsertUpdateState.isActiveMonday) {
      newInsertUpdateState.selectedStartMonday = 0;
      newInsertUpdateState.selectedEndMonday = 0;
    }
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleMondayStartChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedStartMonday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourMonday = "";
    newInsertUpdateState.errorMessageEndHourMonday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleMondayEndChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedEndMonday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourMonday = "";
    newInsertUpdateState.errorMessageEndHourMonday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  //#endregion

  //#region tuesday
  const handleIsWorkingTuesdayChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActiveTuesday =
      !newInsertUpdateState.isActiveTuesday;
    newInsertUpdateState.errorMessageInitialHourTuesday = "";
    newInsertUpdateState.errorMessageEndHourTuesday = "";
    if (!newInsertUpdateState.isActiveTuesday) {
      newInsertUpdateState.selectedStartTuesday = 0;
      newInsertUpdateState.selectedEndTuesday = 0;
    }
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleTuesdayStartChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedStartTuesday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourTuesday = "";
    newInsertUpdateState.errorMessageEndHourTuesday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleTuesdayEndChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedEndTuesday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourTuesday = "";
    newInsertUpdateState.errorMessageEndHourTuesday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  //#endregion

  //#region Wednesday
  const handleIsWorkingWednesdayChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActiveWednesday =
      !newInsertUpdateState.isActiveWednesday;
    newInsertUpdateState.errorMessageInitialHourWednesday = "";
    newInsertUpdateState.errorMessageEndHourWednesday = "";
    if (!newInsertUpdateState.isActiveWednesday) {
      newInsertUpdateState.selectedStartWednesday = 0;
      newInsertUpdateState.selectedEndWednesday = 0;
    }
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleWednesdayStartChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedStartWednesday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourWednesday = "";
    newInsertUpdateState.errorMessageEndHourWednesday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleWednesdayEndChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedEndWednesday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourWednesday = "";
    newInsertUpdateState.errorMessageEndHourWednesday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  //#endregion

  //#region Thursday
  const handleIsWorkingThursdayChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActiveThursday =
      !newInsertUpdateState.isActiveThursday;
    newInsertUpdateState.errorMessageInitialHourThursday = "";
    newInsertUpdateState.errorMessageEndHourThursday = "";
    if (!newInsertUpdateState.isActiveThursday) {
      newInsertUpdateState.selectedStartThursday = 0;
      newInsertUpdateState.selectedEndThursday = 0;
    }
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleThursdayStartChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedStartThursday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourThursday = "";
    newInsertUpdateState.errorMessageEndHourThursday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleThursdayEndChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedEndThursday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourThursday = "";
    newInsertUpdateState.errorMessageEndHourThursday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  //#endregion

  //#region Friday
  const handleIsWorkingFridayChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActiveFriday = !newInsertUpdateState.isActiveFriday;
    newInsertUpdateState.errorMessageInitialHourFriday = "";
    newInsertUpdateState.errorMessageEndHourFriday = "";
    if (!newInsertUpdateState.isActiveFriday) {
      newInsertUpdateState.selectedStartFriday = 0;
      newInsertUpdateState.selectedEndFriday = 0;
    }
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleFridayStartChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedStartFriday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourFriday = "";
    newInsertUpdateState.errorMessageEndHourFriday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleFridayEndChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedEndFriday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourFriday = "";
    newInsertUpdateState.errorMessageEndHourFriday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  //#endregion

  //#region Saturday
  const handleIsWorkingSaturdayChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActiveSaturday =
      !newInsertUpdateState.isActiveSaturday;
    newInsertUpdateState.errorMessageInitialHourSaturday = "";
    newInsertUpdateState.errorMessageEndHourSaturday = "";
    if (!newInsertUpdateState.isActiveSaturday) {
      newInsertUpdateState.selectedStartSaturday = 0;
      newInsertUpdateState.selectedEndSaturday = 0;
    }
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleSaturdayStartChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedStartSaturday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourSaturday = "";
    newInsertUpdateState.errorMessageEndHourSaturday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleSaturdayEndChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedEndSaturday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourSaturday = "";
    newInsertUpdateState.errorMessageEndHourSaturday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  //#endregion

  //#region Sunday
  const handleIsWorkingSundayChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActiveSunday = !newInsertUpdateState.isActiveSunday;
    newInsertUpdateState.errorMessageInitialHourSunday = "";
    newInsertUpdateState.errorMessageEndHourSunday = "";
    if (!newInsertUpdateState.isActiveSunday) {
      newInsertUpdateState.selectedStartSunday = 0;
      newInsertUpdateState.selectedEndSunday = 0;
    }
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleSundayStartChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedStartSunday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourSunday = "";
    newInsertUpdateState.errorMessageEndHourSunday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleSundayEndChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedEndSunday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourSunday = "";
    newInsertUpdateState.errorMessageEndHourSunday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  //#endregion

  //#region Holiday
  const handleIsWorkingHolidayChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActiveHoliday =
      !newInsertUpdateState.isActiveHoliday;
    newInsertUpdateState.errorMessageInitialHourHoliday = "";
    newInsertUpdateState.errorMessageEndHourHoliday = "";
    if (!newInsertUpdateState.isActiveHoliday) {
      newInsertUpdateState.selectedStartHoliday = 0;
      newInsertUpdateState.selectedEndHoliday = 0;
    }
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleHolidayStartChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedStartHoliday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourHoliday = "";
    newInsertUpdateState.errorMessageEndHourHoliday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleHolidayEndChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedEndHoliday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourHoliday = "";
    newInsertUpdateState.errorMessageEndHourHoliday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  //#endregion

  //#region HalfHoliday
  const handleIsWorkingHalfHolidayChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActiveHalfHoliday =
      !newInsertUpdateState.isActiveHalfHoliday;
    newInsertUpdateState.errorMessageInitialHourHalfHoliday = "";
    newInsertUpdateState.errorMessageEndHourHalfHoliday = "";
    if (!newInsertUpdateState.isActiveHalfHoliday) {
      newInsertUpdateState.selectedStartHalfHoliday = 0;
      newInsertUpdateState.selectedEndHalfHoliday = 0;
    }
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleHalfHolidayStartChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedStartHalfHoliday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourHalfHoliday = "";
    newInsertUpdateState.errorMessageEndHourHalfHoliday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleHalfHolidayEndChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.selectedEndHalfHoliday = parseFloat(e.target.value);
    newInsertUpdateState.errorMessageInitialHourHalfHoliday = "";
    newInsertUpdateState.errorMessageEndHourHalfHoliday = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  //#endregion

  //copy from previous
  const handleCopyHoursClick = (index: number) => {
    let newInsertUpdateState = { ...insertUpdateState };
    switch (index) {
      case 2: //tuesday
        newInsertUpdateState.selectedStartTuesday =
          newInsertUpdateState.selectedStartMonday;
        newInsertUpdateState.selectedEndTuesday =
          newInsertUpdateState.selectedEndMonday;
        newInsertUpdateState.errorMessageInitialHourTuesday = "";
        break;
      case 3: //Wednesday
        newInsertUpdateState.selectedStartWednesday =
          newInsertUpdateState.selectedStartTuesday;
        newInsertUpdateState.selectedEndWednesday =
          newInsertUpdateState.selectedEndTuesday;
        newInsertUpdateState.errorMessageInitialHourWednesday = "";
        break;
      case 4: //Thursday
        newInsertUpdateState.selectedStartThursday =
          newInsertUpdateState.selectedStartWednesday;
        newInsertUpdateState.selectedEndThursday =
          newInsertUpdateState.selectedEndWednesday;
        newInsertUpdateState.errorMessageInitialHourThursday = "";
        break;
      case 5: //friday
        newInsertUpdateState.selectedStartFriday =
          newInsertUpdateState.selectedStartThursday;
        newInsertUpdateState.selectedEndFriday =
          newInsertUpdateState.selectedEndThursday;
        newInsertUpdateState.errorMessageInitialHourFriday = "";
        break;
      case 6: //saturday
        newInsertUpdateState.selectedStartSaturday =
          newInsertUpdateState.selectedStartFriday;
        newInsertUpdateState.selectedEndSaturday =
          newInsertUpdateState.selectedEndFriday;
        newInsertUpdateState.errorMessageInitialHourSaturday = "";
        break;
      case 7: //sunday
        newInsertUpdateState.selectedStartSunday =
          newInsertUpdateState.selectedStartSaturday;
        newInsertUpdateState.selectedEndSunday =
          newInsertUpdateState.selectedEndSaturday;
        newInsertUpdateState.errorMessageInitialHourSunday = "";
        break;
      case 8: //holiday
        newInsertUpdateState.selectedStartHoliday =
          newInsertUpdateState.selectedStartSunday;
        newInsertUpdateState.selectedEndHoliday =
          newInsertUpdateState.selectedEndSunday;
        break;
      case 9: //halfholiday
        newInsertUpdateState.selectedStartHalfHoliday =
          newInsertUpdateState.selectedStartHoliday;
        newInsertUpdateState.selectedEndHalfHoliday =
          newInsertUpdateState.selectedEndHoliday;
        break;
    }
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleOkButtonClick = async () => {
    if (nameInputRef.current?.isInvalid()) {
      nameInputRef.current?.markAsTouched();
      return;
    }

    const languages = nameInputRef.current?.getValue();
    const mainLanguage = languages?.find(({id}) => id === TPGlobal.TPClientDefaultLanguage);
    let i: number;
    let n: number;
    let newInsertUpdateState = { ...insertUpdateState };
    let otherErrors: boolean = false;
    let recordInputDTO: SchedulesInputDTO = {
      // id: insertUpdateState.id,
      id: mode === "Insert" ? referenceId : recordId,
      description: mainLanguage?.value!,
      otherLocalizedValues: [
        {
          languageId: mainLanguage?.id!,
          localizedValue: mainLanguage?.value ?? "",
          order: 1
        },
        ...languages!
            .filter(({id}) => id !== TPGlobal.TPClientDefaultLanguage)
            .map(({id, value}, order) => ({languageId: id!, localizedValue: value ?? "", order: order + 2}))
      ],
      initialHourMonday: insertUpdateState.selectedStartMonday,
      endHourMonday: insertUpdateState.selectedEndMonday,
      initialHourTuesday: insertUpdateState.selectedStartTuesday,
      endHourTuesday: insertUpdateState.selectedEndTuesday,
      initialHourWednesday: insertUpdateState.selectedStartWednesday,
      endHourWednesday: insertUpdateState.selectedEndWednesday,
      initialHourThursday: insertUpdateState.selectedStartThursday,
      endHourThursday: insertUpdateState.selectedEndThursday,
      initialHourFriday: insertUpdateState.selectedStartFriday,
      endHourFriday: insertUpdateState.selectedEndFriday,
      initialHourSaturday: insertUpdateState.selectedStartSaturday,
      endHourSaturday: insertUpdateState.selectedEndSaturday,
      initialHourSunday: insertUpdateState.selectedStartSunday,
      endHourSunday: insertUpdateState.selectedEndSunday,
      initialHourHoliday: insertUpdateState.selectedStartHoliday,
      endHourHoliday: insertUpdateState.selectedEndHoliday,
      initialHourHalfHoliday: insertUpdateState.selectedStartHalfHoliday,
      endHourHalfHoliday: insertUpdateState.selectedEndHalfHoliday,
      workMonday: insertUpdateState.isActiveMonday,
      workTuesday: insertUpdateState.isActiveTuesday,
      workWednesday: insertUpdateState.isActiveWednesday,
      workThursday: insertUpdateState.isActiveThursday,
      workFriday: insertUpdateState.isActiveFriday,
      workSaturday: insertUpdateState.isActiveSaturday,
      workSunday: insertUpdateState.isActiveSunday,
      workHoliday: insertUpdateState.isActiveHoliday,
      workHalfHoliday: insertUpdateState.isActiveHalfHoliday,
      isActive: insertUpdateState.isActive,
    };

    let inputDTOValidator = new SchedulesInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      if (resultValidator.id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.id,
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }

      //monday
      if (resultValidator.initialHourMonday) {
        newInsertUpdateState.errorMessageInitialHourMonday =
          await TPI18N.GetResource(resultValidator.initialHourMonday);
      } else {
        newInsertUpdateState.errorMessageInitialHourMonday = "";
      }
      if (resultValidator.endHourMonday) {
        newInsertUpdateState.errorMessageEndHourMonday =
          await TPI18N.GetResource(resultValidator.endHourMonday);
      } else {
        newInsertUpdateState.errorMessageEndHourMonday = "";
      }
      //tuesday
      if (resultValidator.initialHourTuesday) {
        newInsertUpdateState.errorMessageInitialHourTuesday =
          await TPI18N.GetResource(resultValidator.initialHourTuesday);
      } else {
        newInsertUpdateState.errorMessageInitialHourTuesday = "";
      }
      if (resultValidator.endHourTuesday) {
        newInsertUpdateState.errorMessageEndHourTuesday =
          await TPI18N.GetResource(resultValidator.endHourTuesday);
      } else {
        newInsertUpdateState.errorMessageEndHourTuesday = "";
      }
      //Wednesday
      if (resultValidator.initialHourWednesday) {
        newInsertUpdateState.errorMessageInitialHourWednesday =
          await TPI18N.GetResource(resultValidator.initialHourWednesday);
      } else {
        newInsertUpdateState.errorMessageInitialHourWednesday = "";
      }
      if (resultValidator.endHourWednesday) {
        newInsertUpdateState.errorMessageEndHourWednesday =
          await TPI18N.GetResource(resultValidator.endHourWednesday);
      } else {
        newInsertUpdateState.errorMessageEndHourWednesday = "";
      }
      //Thursday
      if (resultValidator.initialHourThursday) {
        newInsertUpdateState.errorMessageInitialHourThursday =
          await TPI18N.GetResource(resultValidator.initialHourThursday);
      } else {
        newInsertUpdateState.errorMessageInitialHourThursday = "";
      }
      if (resultValidator.endHourThursday) {
        newInsertUpdateState.errorMessageEndHourThursday =
          await TPI18N.GetResource(resultValidator.endHourThursday);
      } else {
        newInsertUpdateState.errorMessageEndHourThursday = "";
      }
      //Friday
      if (resultValidator.initialHourFriday) {
        newInsertUpdateState.errorMessageInitialHourFriday =
          await TPI18N.GetResource(resultValidator.initialHourFriday);
      } else {
        newInsertUpdateState.errorMessageInitialHourFriday = "";
      }
      if (resultValidator.endHourFriday) {
        newInsertUpdateState.errorMessageEndHourFriday =
          await TPI18N.GetResource(resultValidator.endHourFriday);
      } else {
        newInsertUpdateState.errorMessageEndHourFriday = "";
      }
      //Saturday
      if (resultValidator.initialHourSaturday) {
        newInsertUpdateState.errorMessageInitialHourSaturday =
          await TPI18N.GetResource(resultValidator.initialHourSaturday);
      } else {
        newInsertUpdateState.errorMessageInitialHourSaturday = "";
      }
      if (resultValidator.endHourSaturday) {
        newInsertUpdateState.errorMessageEndHourSaturday =
          await TPI18N.GetResource(resultValidator.endHourSaturday);
      } else {
        newInsertUpdateState.errorMessageEndHourSaturday = "";
      }
      //Sunday
      if (resultValidator.initialHourSunday) {
        newInsertUpdateState.errorMessageInitialHourSunday =
          await TPI18N.GetResource(resultValidator.initialHourSunday);
      } else {
        newInsertUpdateState.errorMessageInitialHourSunday = "";
      }
      if (resultValidator.endHourSunday) {
        newInsertUpdateState.errorMessageEndHourSunday =
          await TPI18N.GetResource(resultValidator.endHourSunday);
      } else {
        newInsertUpdateState.errorMessageEndHourSunday = "";
      }
      //Holiday
      if (resultValidator.initialHourHoliday) {
        newInsertUpdateState.errorMessageInitialHourHoliday =
          await TPI18N.GetResource(resultValidator.initialHourHoliday);
      } else {
        newInsertUpdateState.errorMessageInitialHourHoliday = "";
      }
      if (resultValidator.endHourHoliday) {
        newInsertUpdateState.errorMessageEndHourHoliday =
          await TPI18N.GetResource(resultValidator.endHourHoliday);
      } else {
        newInsertUpdateState.errorMessageEndHourHoliday = "";
      }
      //HalfHoliday
      if (resultValidator.initialHourHalfHoliday) {
        newInsertUpdateState.errorMessageInitialHourHalfHoliday =
          await TPI18N.GetResource(resultValidator.initialHourHalfHoliday);
      } else {
        newInsertUpdateState.errorMessageInitialHourHalfHoliday = "";
      }
      if (resultValidator.endHourHalfHoliday) {
        newInsertUpdateState.errorMessageEndHourHalfHoliday =
          await TPI18N.GetResource(resultValidator.endHourHalfHoliday);
      } else {
        newInsertUpdateState.errorMessageEndHourHalfHoliday = "";
      }
      setInsertUpdateState(newInsertUpdateState);
      return;
    }
    //other monday
    if (
      insertUpdateState.isActiveMonday &&
      insertUpdateState.selectedEndMonday <=
        insertUpdateState.selectedStartMonday
    ) {
      otherErrors = true;
      newInsertUpdateState.errorMessageInitialHourMonday =
        await TPI18N.GetResource(ResourceSet + "|InputDTOHoursError");
    }
    //other tuesday
    if (
      insertUpdateState.isActiveTuesday &&
      insertUpdateState.selectedEndTuesday <=
        insertUpdateState.selectedStartTuesday
    ) {
      otherErrors = true;
      newInsertUpdateState.errorMessageInitialHourTuesday =
        await TPI18N.GetResource(ResourceSet + "|InputDTOHoursError");
    }
    //other Wednesday
    if (
      insertUpdateState.isActiveWednesday &&
      insertUpdateState.selectedEndWednesday <=
        insertUpdateState.selectedStartWednesday
    ) {
      otherErrors = true;
      newInsertUpdateState.errorMessageInitialHourWednesday =
        await TPI18N.GetResource(ResourceSet + "|InputDTOHoursError");
    }
    //other Thursday
    if (
      insertUpdateState.isActiveThursday &&
      insertUpdateState.selectedEndThursday <=
        insertUpdateState.selectedStartThursday
    ) {
      otherErrors = true;
      newInsertUpdateState.errorMessageInitialHourThursday =
        await TPI18N.GetResource(ResourceSet + "|InputDTOHoursError");
    }
    //other Friday
    if (
      insertUpdateState.isActiveFriday &&
      insertUpdateState.selectedEndFriday <=
        insertUpdateState.selectedStartFriday
    ) {
      otherErrors = true;
      newInsertUpdateState.errorMessageInitialHourFriday =
        await TPI18N.GetResource(ResourceSet + "|InputDTOHoursError");
    }
    //other Saturday
    if (
      insertUpdateState.isActiveSaturday &&
      insertUpdateState.selectedEndSaturday <=
        insertUpdateState.selectedStartSaturday
    ) {
      otherErrors = true;
      newInsertUpdateState.errorMessageInitialHourSaturday =
        await TPI18N.GetResource(ResourceSet + "|InputDTOHoursError");
    }
    //other Sunday
    if (
      insertUpdateState.isActiveSunday &&
      insertUpdateState.selectedEndSunday <=
        insertUpdateState.selectedStartSunday
    ) {
      otherErrors = true;
      newInsertUpdateState.errorMessageInitialHourSunday =
        await TPI18N.GetResource(ResourceSet + "|InputDTOHoursError");
    }
    //other Holiday
    if (
      insertUpdateState.isActiveHoliday &&
      insertUpdateState.selectedEndHoliday <=
        insertUpdateState.selectedStartHoliday
    ) {
      otherErrors = true;
      newInsertUpdateState.errorMessageInitialHourHoliday =
        await TPI18N.GetResource(ResourceSet + "|InputDTOHoursError");
    }
    //other HalfHoliday
    if (
      insertUpdateState.isActiveHalfHoliday &&
      insertUpdateState.selectedEndHalfHoliday <=
        insertUpdateState.selectedStartHalfHoliday
    ) {
      otherErrors = true;
      newInsertUpdateState.errorMessageInitialHourHalfHoliday =
        await TPI18N.GetResource(ResourceSet + "|InputDTOHoursError");
    }
    if (otherErrors) {
      setInsertUpdateState(newInsertUpdateState);
      return;
    }
    if (mode == "Insert") {
      await insertSchedule(recordInputDTO);
    } else {
      await updateSchedule(recordInputDTO);
    }
  };

  const insertSchedule = async (inputDTO: SchedulesInputDTO) => {
    let serviceClient = new SchedulesService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertSchedule(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertSchedule ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} insertSchedule ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateSchedule = async (inputDTO: SchedulesInputDTO) => {
    let serviceClient = new SchedulesService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.updateSchedule(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateSchedule ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updateSchedule ex`);
      setIsLoadingScreen(false);
    }
  };

  const getScheduleById = async (pRecordId: string) => {
    let serviceClient = new SchedulesService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getScheduleById(
        pRecordId,
        false,
        true,
        expectedCodes,
      );

      let recordInfo: ScheduleViewModel;
      recordInfo = { ...responseRequest };
      setOriginalRecordDescription(
        recordInfo.description.length <= 100
          ? recordInfo.description
          : recordInfo.description.substring(0, 100) + "...",
      );
      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.recordLanguageList = [];
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      newInsertUpdateState.isActive = recordInfo.isActive;
      newInsertUpdateState.recordLanguageList[0].value = recordInfo.description;
      recordLanguagesList = await getRecordLanguageList(pRecordId);
      if (recordLanguagesList.length == 0) {
        TPLog.Log(
          `Error ${componentFileName} getScheduleById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty",
        );
        console.error(
          `Error ${componentFileName} getScheduleById getRecordLanguageList is empty`,
        );
        setIsLoadingScreen(false);
        return;
      }
      for (
        i = 0;
        i <= newInsertUpdateState.recordLanguageList.length - 1;
        i++
      ) {
        for (j = 0; j <= recordLanguagesList.length - 1; j++) {
          if (
            newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ==
            recordLanguagesList[j].languageId.toLowerCase()
          ) {
            newInsertUpdateState.recordLanguageList[i].value =
              recordLanguagesList[j].recordDescription;
            break;
          }
        }
      }
      //Monday
      newInsertUpdateState.isActiveMonday = recordInfo.workMonday;
      newInsertUpdateState.selectedStartMonday = recordInfo.initialHourMonday;
      newInsertUpdateState.selectedEndMonday = recordInfo.endHourMonday;
      //Tuesday
      newInsertUpdateState.isActiveTuesday = recordInfo.workTuesday;
      newInsertUpdateState.selectedStartTuesday = recordInfo.initialHourTuesday;
      newInsertUpdateState.selectedEndTuesday = recordInfo.endHourTuesday;
      //Wednesday
      newInsertUpdateState.isActiveWednesday = recordInfo.workWednesday;
      newInsertUpdateState.selectedStartWednesday =
        recordInfo.initialHourWednesday;
      newInsertUpdateState.selectedEndWednesday = recordInfo.endHourWednesday;
      //Thursday
      newInsertUpdateState.isActiveThursday = recordInfo.workThursday;
      newInsertUpdateState.selectedStartThursday =
        recordInfo.initialHourThursday;
      newInsertUpdateState.selectedEndThursday = recordInfo.endHourThursday;
      //Friday
      newInsertUpdateState.isActiveFriday = recordInfo.workFriday;
      newInsertUpdateState.selectedStartFriday = recordInfo.initialHourFriday;
      newInsertUpdateState.selectedEndFriday = recordInfo.endHourFriday;
      //Saturday
      newInsertUpdateState.isActiveSaturday = recordInfo.workSaturday;
      newInsertUpdateState.selectedStartSaturday =
        recordInfo.initialHourSaturday;
      newInsertUpdateState.selectedEndSaturday = recordInfo.endHourSaturday;
      //Sunday
      newInsertUpdateState.isActiveSunday = recordInfo.workSunday;
      newInsertUpdateState.selectedStartSunday = recordInfo.initialHourSunday;
      newInsertUpdateState.selectedEndSunday = recordInfo.endHourSunday;
      //Holiday
      newInsertUpdateState.isActiveHoliday = recordInfo.workHoliday;
      newInsertUpdateState.selectedStartHoliday = recordInfo.initialHourHoliday;
      newInsertUpdateState.selectedEndHoliday = recordInfo.endHourHoliday;
      //HalfHoliday
      newInsertUpdateState.isActiveHalfHoliday = recordInfo.workHalfHoliday;
      newInsertUpdateState.selectedStartHalfHoliday =
        recordInfo.initialHourHalfHoliday;
      newInsertUpdateState.selectedEndHalfHoliday =
        recordInfo.endHourHalfHoliday;

      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getScheduleById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getScheduleById ex`);
      setIsLoadingScreen(false);
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQSCHE,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  //Only once to set resources and load schedule in update mode

  useEffect(() => {
    loadResourcesAndLoadInfo();
    mode === "Insert" && generalAutomaticId();
  }, []);

  return (
    //#region  Render
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            {mode == "Update" ? (
              <TPPageSubTitle>
                {subTitleLabel}&nbsp;
                {recordId}&nbsp;/&nbsp;
                {originalRecordDescription}
              </TPPageSubTitle>
            ) : null}
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <TPPageSection>
              <div className="row">
                <div className="col-10">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={idLabel}
                      isMandatory={true}
                      // value={insertUpdateState.id}
                      value={mode === "Insert" ? referenceId : recordId}
                      onChange={
                        mode === "Insert"
                          ? (e: any) => handleOnIdChange(e.target.value)
                          : () => {
                              TPGlobal.foo();
                            }
                      }
                      maxLength={20}
                      // disabled={mode !== "Insert"}
                      disabled
                      errorMessage={insertUpdateState.idErrorMessage}
                    />
                  </div>
                </div>
              </div>
              <MultilingualTextBox
                  ref={nameInputRef}
                  maxLength={200}
                  style={{ width: "calc(50% + 35px)" }}
                  value={insertUpdateState.recordLanguageList?.map(({key, value}) => ({id: key, value}))}
              />
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-10">
            <TPPageSection>
              <div className="row">
                <div className="col-12 text-center">
                  <TPTableCondensedContainer className="tpcondensed-table tpw100per">
                    <thead>
                      <tr>
                        <th>{dayLabel}</th>
                        <th>{isWorkingDayLabel}</th>
                        <th></th>
                        <th>{startTimeLabel}</th>
                        <th>{endTimeLabel}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-start">{mondayLabel}</td>
                        <td>
                          <TPCheckBox
                            id="IdCheckBox"
                            className="tpcenter"
                            labelText={""}
                            checked={insertUpdateState.isActiveMonday}
                            onChange={(e: any) => handleIsWorkingMondayChange()}
                          ></TPCheckBox>
                        </td>
                        <td width={"250"}></td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveMonday}
                            onChange={handleMondayStartChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedStartMonday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageInitialHourMonday
                            }
                          ></TPSelect>
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveMonday}
                            onChange={handleMondayEndChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedEndMonday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageEndHourMonday
                            }
                          ></TPSelect>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">{tuesdayLabel}</td>
                        <td>
                          <TPCheckBox
                            id="IdCheckBox"
                            className="tpcenter"
                            labelText={""}
                            checked={insertUpdateState.isActiveTuesday}
                            onChange={(e: any) =>
                              handleIsWorkingTuesdayChange()
                            }
                          ></TPCheckBox>
                        </td>
                        <td>
                          {insertUpdateState.isActiveMonday &&
                          insertUpdateState.isActiveTuesday ? (
                            <div className="tpcenter">
                              <TPButton
                                id="IdButton"
                                className="tpcenter"
                                type={TPButtonTypes.icon}
                                onClick={() => handleCopyHoursClick(2)}
                                text={copyFromPreviousLabel}
                                icon={TPIconTypes.clone}
                              />
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveTuesday}
                            onChange={handleTuesdayStartChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedStartTuesday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageInitialHourTuesday
                            }
                          ></TPSelect>
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveTuesday}
                            onChange={handleTuesdayEndChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedEndTuesday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageEndHourTuesday
                            }
                          ></TPSelect>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">{wednesdayLabel}</td>
                        <td>
                          <TPCheckBox
                            id="IdCheckBox"
                            className="tpcenter"
                            labelText={""}
                            checked={insertUpdateState.isActiveWednesday}
                            onChange={(e: any) =>
                              handleIsWorkingWednesdayChange()
                            }
                          ></TPCheckBox>
                        </td>
                        <td>
                          {insertUpdateState.isActiveTuesday &&
                          insertUpdateState.isActiveWednesday ? (
                            <div className="tpcenter">
                              <TPButton
                                id="IdButton"
                                className="tpcenter"
                                type={TPButtonTypes.icon}
                                onClick={() => handleCopyHoursClick(3)}
                                text={copyFromPreviousLabel}
                                icon={TPIconTypes.clone}
                              />
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveWednesday}
                            onChange={handleWednesdayStartChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedStartWednesday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageInitialHourWednesday
                            }
                          ></TPSelect>
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveWednesday}
                            onChange={handleWednesdayEndChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedEndWednesday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageEndHourWednesday
                            }
                          ></TPSelect>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">{thursdayLabel}</td>
                        <td>
                          <TPCheckBox
                            id="IdCheckBox"
                            className="tpcenter"
                            labelText={""}
                            checked={insertUpdateState.isActiveThursday}
                            onChange={(e: any) =>
                              handleIsWorkingThursdayChange()
                            }
                          ></TPCheckBox>
                        </td>
                        <td>
                          {insertUpdateState.isActiveWednesday &&
                          insertUpdateState.isActiveThursday ? (
                            <div className="tpcenter">
                              <TPButton
                                id="IdButton"
                                className="tpcenter"
                                type={TPButtonTypes.icon}
                                onClick={() => handleCopyHoursClick(4)}
                                text={copyFromPreviousLabel}
                                icon={TPIconTypes.clone}
                              />
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveThursday}
                            onChange={handleThursdayStartChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedStartThursday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageInitialHourThursday
                            }
                          ></TPSelect>
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveThursday}
                            onChange={handleThursdayEndChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedEndThursday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageEndHourThursday
                            }
                          ></TPSelect>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">{fridayLabel}</td>
                        <td>
                          <TPCheckBox
                            id="IdCheckBox"
                            className="tpcenter"
                            labelText={""}
                            checked={insertUpdateState.isActiveFriday}
                            onChange={(e: any) => handleIsWorkingFridayChange()}
                          ></TPCheckBox>
                        </td>
                        <td>
                          {insertUpdateState.isActiveThursday &&
                          insertUpdateState.isActiveFriday ? (
                            <div className="tpcenter">
                              <TPButton
                                id="IdButton"
                                className="tpcenter"
                                type={TPButtonTypes.icon}
                                onClick={() => handleCopyHoursClick(5)}
                                text={copyFromPreviousLabel}
                                icon={TPIconTypes.clone}
                              />
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveFriday}
                            onChange={handleFridayStartChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedStartFriday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageInitialHourFriday
                            }
                          ></TPSelect>
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveFriday}
                            onChange={handleFridayEndChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedEndFriday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageEndHourFriday
                            }
                          ></TPSelect>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">{saturdayLabel}</td>
                        <td>
                          <TPCheckBox
                            id="IdCheckBox"
                            className="tpcenter"
                            labelText={""}
                            checked={insertUpdateState.isActiveSaturday}
                            onChange={(e: any) =>
                              handleIsWorkingSaturdayChange()
                            }
                          ></TPCheckBox>
                        </td>
                        <td>
                          {insertUpdateState.isActiveFriday &&
                          insertUpdateState.isActiveSaturday ? (
                            <div className="tpcenter">
                              <TPButton
                                id="IdButton"
                                className="tpcenter"
                                type={TPButtonTypes.icon}
                                onClick={() => handleCopyHoursClick(6)}
                                text={copyFromPreviousLabel}
                                icon={TPIconTypes.clone}
                              />
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveSaturday}
                            onChange={handleSaturdayStartChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedStartSaturday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageInitialHourSaturday
                            }
                          ></TPSelect>
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveSaturday}
                            onChange={handleSaturdayEndChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedEndSaturday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageEndHourSaturday
                            }
                          ></TPSelect>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">{sundayLabel}</td>
                        <td>
                          <TPCheckBox
                            id="IdCheckBox"
                            className="tpcenter"
                            labelText={""}
                            checked={insertUpdateState.isActiveSunday}
                            onChange={(e: any) => handleIsWorkingSundayChange()}
                          ></TPCheckBox>
                        </td>
                        <td>
                          {insertUpdateState.isActiveSaturday &&
                          insertUpdateState.isActiveSunday ? (
                            <div className="tpcenter">
                              <TPButton
                                id="IdButton"
                                className="tpcenter"
                                type={TPButtonTypes.icon}
                                onClick={() => handleCopyHoursClick(7)}
                                text={copyFromPreviousLabel}
                                icon={TPIconTypes.clone}
                              />
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveSunday}
                            onChange={handleSundayStartChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedStartSunday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageInitialHourSunday
                            }
                          ></TPSelect>
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveSunday}
                            onChange={handleSundayEndChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedEndSunday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageEndHourSunday
                            }
                          ></TPSelect>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">{holidayLabel}</td>
                        <td>
                          <TPCheckBox
                            id="IdCheckBox"
                            className="tpcenter"
                            labelText={""}
                            checked={insertUpdateState.isActiveHoliday}
                            onChange={(e: any) =>
                              handleIsWorkingHolidayChange()
                            }
                          ></TPCheckBox>
                        </td>
                        <td>
                          {insertUpdateState.isActiveSunday &&
                          insertUpdateState.isActiveHoliday ? (
                            <div className="tpcenter">
                              <TPButton
                                id="IdButton"
                                className="tpcenter"
                                type={TPButtonTypes.icon}
                                onClick={() => handleCopyHoursClick(8)}
                                text={copyFromPreviousLabel}
                                icon={TPIconTypes.clone}
                              />
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveHoliday}
                            onChange={handleHolidayStartChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedStartHoliday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageInitialHourHoliday
                            }
                          ></TPSelect>
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveHoliday}
                            onChange={handleHolidayEndChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedEndHoliday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageEndHourHoliday
                            }
                          ></TPSelect>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">{halfHolidayLabel}</td>
                        <td>
                          <TPCheckBox
                            id="IdCheckBox"
                            className="tpcenter"
                            labelText={""}
                            checked={insertUpdateState.isActiveHalfHoliday}
                            onChange={(e: any) =>
                              handleIsWorkingHalfHolidayChange()
                            }
                          ></TPCheckBox>
                        </td>
                        <td>
                          {insertUpdateState.isActiveHoliday &&
                          insertUpdateState.isActiveHalfHoliday ? (
                            <div className="tpcenter">
                              <TPButton
                                id="IdButton"
                                className="tpcenter"
                                type={TPButtonTypes.icon}
                                onClick={() => handleCopyHoursClick(9)}
                                text={copyFromPreviousLabel}
                                icon={TPIconTypes.clone}
                              />
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveHalfHoliday}
                            onChange={handleHalfHolidayStartChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedStartHalfHoliday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageInitialHourHalfHoliday
                            }
                          ></TPSelect>
                        </td>
                        <td>
                          <TPSelect
                            id="IdSelect"
                            disabled={!insertUpdateState.isActiveHalfHoliday}
                            onChange={handleHalfHolidayEndChange}
                            dataSource={hoursKeyValue}
                            value={insertUpdateState.selectedEndHalfHoliday}
                            labelText={""}
                            isHorizontal={true}
                            errorMessage={
                              insertUpdateState.errorMessageEndHourHalfHoliday
                            }
                          ></TPSelect>
                        </td>
                      </tr>
                    </tbody>
                  </TPTableCondensedContainer>
                </div>
              </div>
              <div className="row">
                <div className="col-10 mt-3">
                  <div className="form-group">
                    <TPCheckBox
                      id="IdCheckBox"
                      labelText={isActiveLabel}
                      checked={insertUpdateState.isActive}
                      onChange={(e: any) => handleIsActiveChange()}
                    ></TPCheckBox>
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-10">
            <TPPageAcceptCancelButtonsContainer>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={handleOkButtonClick}
              >
                {saveButtonLabel}
              </TPButton>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.link}
                onClick={handleCancelButtonClick}
                className={"ms-2"}
              >
                {cancelButtonLabel}
              </TPButton>
            </TPPageAcceptCancelButtonsContainer>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>

    //#endregion
  );
};

export default SchedulesInsertUpdate;
