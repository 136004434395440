import OfflineDotIcon from "@/assets/images/TPIcons/OfflineDotIcon";
import OnlineDotIcon from "@/assets/images/TPIcons/OnlineDotIcon";
import ReadyDotIcon from "@/assets/images/TPIcons/ReadyDotIcon";
import WorkingDotIcon from "@/assets/images/TPIcons/WorkingDotIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ActiveTeamMembersService } from "@/services/ActiveTeamMembersService";
import { TPI18N } from "@/services/I18nService";
import { FC, useEffect, useState } from "react";
import { CockpitView, InfoCardTypes, TeamMemberParsed } from "../supervisor-cockpit.model";
import CockpitTeamActivityTable from "../utils/CockpitTeamActivityTable";
import "./active-team-members-styles.css";
import { GroupsService } from "@/services/GroupsService";
import { EventProjectService } from "@/services/EventProjectService";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";

type ATMPprops = {
    view: CockpitView;
    lastGroup: string;
    lastProject: string;
    lastQueue: string;
    resetFromUser: () => void;
    goToUserPage: (employee: TeamMemberParsed, groupId: number) => void;
    changeTitle?: (title: string) => void;
    loadingCallback?: (status: boolean) => void;
    setLast: (type: "group" | "project" , id: string, queue?: string,) => void;
}

interface TeamActivityFilters {
    working: boolean;
    ready: boolean;
    online: boolean;
    offline: boolean;
}

const ActiveTeamMembersPeople: FC<ATMPprops> = ({
    view,
    resetFromUser,
    lastGroup,
    lastProject,
    lastQueue,
    goToUserPage,
    changeTitle,
    loadingCallback,
    setLast
}) => {

    const componentFileName = "ActiveTeamMembersPeople.tsx";

    const resourceSet = "ActiveTeamMembersPeople";

    const [isResourcesLoaded, setIsResourcesLoaded] = useState<boolean>(true);


    const [myTeamLabel, setMyTeamLabel] = useState<string>("")
    const [workingLabel, setWorkingLabel] = useState<string>("");
    const [readyLabel, setReadyLabel] = useState<string>("");
    const [onlineLabel, setOnlineLabel] = useState<string>("");
    const [offlineLabel, setOfflineLabel] = useState<string>("");
    const [selectGroupLabel, setSelectGroupLabel] = useState<string>("");
    const [projectLabel, setProjectLabel] = useState<string>("");
    const [queueLabel, setQueueLabel] = useState<string>("");
    const [applyButton, setApplyButton] = useState<string>("");

    const loadResources = async () => {
        setWorkingLabel(await TPI18N.GetText(resourceSet, "workingLabel"));
        setReadyLabel(await TPI18N.GetText(resourceSet, "readyLabel"));
        setOnlineLabel(await TPI18N.GetText(resourceSet, "onlineLabel"));
        setOfflineLabel(await TPI18N.GetText(resourceSet, "offlineLabel"));
        setSelectGroupLabel(await TPI18N.GetText(resourceSet, "selectGroupLabel"));
        setProjectLabel(await TPI18N.GetText(resourceSet, "projectLabel"));
        setQueueLabel(await TPI18N.GetText(resourceSet, "queueLabel"));
        setMyTeamLabel(await TPI18N.GetText(resourceSet, "myTeamLabel"));
        setApplyButton(await TPI18N.GetText(resourceSet, "applyButton"));
    }

    const [working, setWorking] = useState(0);
    const [ready, setReady] = useState(0);
    const [online, setOnline] = useState(0);
    const [offline, setOffline] = useState(0);

    const [groupData, setGroupData] = useState<Array<TPKeyValue>>([])

    const [projectData, setProjectData] = useState<Array<TPKeyValue>>([])

    const [queueData, setQueueData] = useState<Array<TPKeyValue>>([])

    const [teamTableData, setTeamTableData] = useState<Array<TeamMemberParsed>>([]);
    const [groupTableData, setGroupTableData] = useState<Array<TeamMemberParsed>>([]);
    const [eventTableData, setEventTableData] = useState<Array<TeamMemberParsed>>([]);

    const [groupValue, setGroupValue] = useState("");
    const [projectValue, setProjectValue] = useState("");
    const [queueValue, setQueueValue] = useState("");

    const initialCheckBoxStatus: TeamActivityFilters = {
        working: true,
        ready: true,
        online: true,
        offline: true,
    };

    const [checkBoxStatus, setCheckBoxStatus] = useState(initialCheckBoxStatus);

    const changeCheckBoxStatus = (key: keyof typeof initialCheckBoxStatus) => {
        let newCheckBoxStatus = { ...checkBoxStatus };
        newCheckBoxStatus[key] = !newCheckBoxStatus[key];
        setCheckBoxStatus(newCheckBoxStatus);
    }

    const getProjects = async () => {
        loadingCallback && loadingCallback(true);
        const projectsService = new EventProjectService();

        let expectedCodes = [200];

        try {
            let responseRequest = await projectsService.getProjectsBySuperior(
                TPGlobal.currentUserGuid,
                false,
                true,
                expectedCodes
            );

            if (responseRequest) {
                let Data = [];

                for (let i = 0; i < responseRequest.length; i++) {
                    Data.push({
                        key: responseRequest[i].id,
                        value: responseRequest[i].name
                    });
                }

                setProjectData(Data);

                if (lastProject.length > 0 && Data.find(s => s.key == lastProject)) {
                    setProjectValue(lastProject);
                } else {
                    setProjectValue(Data[0].key);
                } 
            }
            loadingCallback && loadingCallback(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getProjects ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getProjects ex`);
            loadingCallback && loadingCallback(false);
        }
    }

    const getQueuesByProject = async () => {
        loadingCallback && loadingCallback(true);
        const ATMService = new ActiveTeamMembersService();

        let expectedCodes = [200];

        try {
            let responseRequest = await ATMService.getQueueByProjectId(
                projectValue,
                false,
                true,
                expectedCodes
            );

            if (responseRequest) {

                let Data: Array<TPKeyValue> = [];

                for (let i = 0; i < responseRequest.length; i++) {
                    Data.push({
                    key: responseRequest[i].groupId,
                    value: responseRequest[i].user.firstName
                    });
                }

                setQueueData(Data);

                if (lastQueue.length > 0 && responseRequest.find(s => s.groupId == lastQueue)) {
                    setQueueValue(lastQueue);
                    resetFromUser();
                } else {
                    setQueueValue(Data[0].key);
                }
            }

            loadingCallback && loadingCallback(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getQueuesByProject ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getQueuesByProject ex`);
            loadingCallback && loadingCallback(false);
        }
    }

    const getGroups = async () => {
        loadingCallback && loadingCallback(true);
        const groupsService = new GroupsService();
        let expectedCodes = [200, 404];

        try{
            let responseRequest = await groupsService.getGroupsBySuperior(
                TPGlobal.currentUserGuid,
                false,
                true,
                expectedCodes                                
            )

            if (responseRequest) {
                let Data = [];

                for (let i = 0; i < responseRequest.length; i++) {
                    Data.push({
                        key: responseRequest[i].groupId,
                        value: responseRequest[i].user.firstName
                    });
                }

                setGroupData(Data);

                if (lastGroup.length > 0 && Data.find(s => s.key == lastGroup)) {
                    setGroupValue(lastGroup);
                    resetFromUser();
                } else {
                    setGroupValue(Data[0].key);
                }
            }

            loadingCallback && loadingCallback(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getGroups ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getGroups ex`);
            loadingCallback && loadingCallback(false);
        }
    }

    const getTeamData = async () => {
        loadingCallback && loadingCallback(true);
        let ATMService = new ActiveTeamMembersService();

        let expectedCodes = [200, 404];

        try {
            let responseRequest = await ATMService.getTeamData(
                TPGlobal.currentUserGuid,
                false,
                true,
                expectedCodes
            );

            if (responseRequest) {
                let newData: TeamMemberParsed[] = [];
                for (let i = 0; i < responseRequest.length; i++) {
                    newData.push({
                        userGuid: responseRequest[i].userGuid,
                        userId: responseRequest[i].userId,
                        userName: responseRequest[i].userName,
                        schedule: responseRequest[i].onSchedule === "ONSCHEDULE" ? "onSchedule" : "offSchedule",
                        status: responseRequest[i].currentStatus.toLowerCase() as InfoCardTypes,
                        duration: responseRequest[i].duration,
                        pendingTickets: responseRequest[i].pendingTickets,
                        queueName: responseRequest[i].queueName
                    })
                }

                setTeamTableData(newData);
                loadingCallback && loadingCallback(false);
            }

        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getTeamData ex`);
            loadingCallback && loadingCallback(false);
        }
    }

    const getGroupData = async () => {
        loadingCallback && loadingCallback(true);
        let ATMService = new ActiveTeamMembersService();

        let expectedCodes = [200, 404];

        let inputDTO = {
            idGroupUser: groupValue
        }

        try {
            let responseRequest = await ATMService.getGroupData(
                inputDTO,
                false,
                true,
                expectedCodes
            )

            if (responseRequest) {
                let newData: TeamMemberParsed[] = [];
                for (let i = 0; i < responseRequest.length; i++) {
                    newData.push({
                        userGuid: responseRequest[i].userGuid,
                        userId: responseRequest[i].userId,
                        userName: responseRequest[i].userName,
                        schedule: responseRequest[i].onSchedule === "ONSCHEDULE" ? "onSchedule" : "offSchedule",
                        status: responseRequest[i].currentStatus.toLowerCase() as InfoCardTypes,
                        duration: responseRequest[i].duration,
                        pendingTickets: responseRequest[i].pendingTickets,
                        queueName: responseRequest[i].queueName
                    })
                }

                setGroupTableData(newData);
                loadingCallback && loadingCallback(false);
            }

        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getTeamData ex`);
            loadingCallback && loadingCallback(false);
        }
    }

    const getEventData = async () => {
        loadingCallback && loadingCallback(true);
        let ATMService = new ActiveTeamMembersService();

        let expectedCodes = [200, 404];

        let inputDTO = {
            idEventProject: projectValue,
            idQueue: queueValue != "" ? queueValue : 0
        }

        try {
            let responseRequest = await ATMService.getEventData(
                inputDTO,
                false,
                false,
                expectedCodes
            )

            if (responseRequest) {
                let newData: TeamMemberParsed[] = [];
                for (let i = 0; i < responseRequest.length; i++) {
                    newData.push({
                        userGuid: responseRequest[i].userGuid,
                        userId: responseRequest[i].userId,
                        userName: responseRequest[i].userName,
                        schedule: responseRequest[i].onSchedule === "ONSCHEDULE" ? "onSchedule" : "offSchedule",
                        status: responseRequest[i].currentStatus.toLowerCase() as InfoCardTypes,
                        duration: responseRequest[i].duration,
                        pendingTickets: responseRequest[i].pendingTickets,
                        queueName: responseRequest[i].queueName
                    })
                }

                setEventTableData(newData);
                loadingCallback && loadingCallback(false);
            }

        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getTeamData ex`);
            loadingCallback && loadingCallback(false);
        }
    }

    useEffect(() => {
        if (changeTitle && view == "group") {
            changeTitle(groupData.find(item => item.key == groupValue)?.value);
        }
    }, [groupValue]);

    useEffect(() => {
        projectValue !== "" && getQueuesByProject();
        if (changeTitle && view == "event") {
            changeTitle(projectData.find(item => item.key == projectValue)?.value);
        }
    }, [projectValue]);

    useEffect(() => {
        if (view == "group" && changeTitle) {
            changeTitle && changeTitle(groupData.find(item => item.key == groupValue)?.value);
        } else if (view == "team" && changeTitle) {
            changeTitle && changeTitle(myTeamLabel);
        } else if (view == "event" && changeTitle) {
            changeTitle && changeTitle(projectData.find(item => item.key == projectValue)?.value);
        }
    }, [view, myTeamLabel]);

    useEffect(() => {
        if (view == "team") {
            setWorking(teamTableData.filter(item => item.status === "working").length);
            setReady(teamTableData.filter(item => item.status === "ready").length);
            setOnline(teamTableData.filter(item => item.status === "online").length);
            setOffline(teamTableData.filter(item => item.status === "offline").length);
        }

        if (view == "group") {
            setWorking(groupTableData.filter(item => item.status === "working").length);
            setReady(groupTableData.filter(item => item.status === "ready").length);
            setOnline(groupTableData.filter(item => item.status === "online").length);
            setOffline(groupTableData.filter(item => item.status === "offline").length);
        }

        if (view == "event") {
            setWorking(eventTableData.filter(item => item.status === "working").length);
            setReady(eventTableData.filter(item => item.status === "ready").length);
            setOnline(eventTableData.filter(item => item.status === "online").length);
            setOffline(eventTableData.filter(item => item.status === "offline").length);
        }
    }, [view, teamTableData, groupTableData, eventTableData]);

    useEffect(() => {
        if (view == "team") {
            getTeamData();
        }
    }, [view]);

    useEffect(() => {
        loadResources();
        getProjects();
        getGroups();
    }, [])

    const reloadGrids = () => {
        view == "team" && getTeamData();
        view == "group" && getGroupData();
        view == "event" && getEventData();
    }

    const handleCallback = (employee: TeamMemberParsed) => {
        if (view !== "team") {
            setLast(view == "group" ? "group" : "project", view == "group" ? groupValue : projectValue, view == "event" ? queueValue : "");
        }
        goToUserPage(employee, view == "team" ? 0 : (view == "group" ? Number(groupValue) : Number(queueValue)));
    }

    return (
        <>
            {view == "group" && (
                <div style={{ display: 'flex', flexDirection: 'row',  gap: '20px', alignItems: 'flex-end'}}>
                    <div style={{ marginTop: "20px", width: "250px" }}>
                        <TPSelect
                            isMandatory={true}
                            value={groupValue}
                            labelText={selectGroupLabel}
                            onChange={(e: any) => {
                                setGroupValue(e.target.value);
                            }}
                            dataSource={groupData}
                        />
                    </div>
                    <TPButton
                        id='search-tickets'
                        isDesignSystem
                        onClick={getGroupData}
                        style={{ marginBottom: '3px', padding: "10px 18px" }}
                    >
                        {applyButton}
                    </TPButton>
                </div>
                
                
            )}
            {view == "event" && (
                <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: 'flex-end' }}>
                    <div style={{ marginTop: "20px", width: "250px" }}>
                        <TPSelect
                            isMandatory={true}
                            value={projectValue}
                            labelText={projectLabel}
                            onChange={(e: any) => setProjectValue(e.target.value)}
                            dataSource={projectData}
                        />
                    </div>
                    <div style={{ marginTop: "20px", width: "250px" }}>
                        <TPSelect
                            isMandatory={true}
                            value={queueValue}
                            labelText={queueLabel}
                            onChange={(e: any) => setQueueValue(e.target.value)}
                            dataSource={queueData}
                        />
                    </div>
                    <TPButton
                        id='search-tickets'
                        isDesignSystem
                        onClick={getEventData}
                        style={{ marginBottom: '3px', padding: "10px 18px" }}
                    >
                        {applyButton}
                    </TPButton>
                </div>

            )}

            <div className="checkbox-container">
                <TPCheckBox
                    labelText={<div style={{ display: "flex", alignItems: "center", gap: "5px", flexDirection: "row" }}>
                        <WorkingDotIcon />
                        <p style={{ marginBottom: "0px", fontWeight: "500", fontSize: "15px" }}>{workingLabel} {working}</p>
                    </div>}
                    checked={checkBoxStatus.working}
                    onChange={() => changeCheckBoxStatus("working")}
                />
                <TPCheckBox
                    labelText={<div style={{ display: "flex", alignItems: "center", gap: "5px", flexDirection: "row" }}>
                        <ReadyDotIcon />
                        <p style={{ marginBottom: "0px", fontWeight: "500", fontSize: "15px" }}>{readyLabel} {ready}</p>
                    </div>}
                    checked={checkBoxStatus.ready}
                    onChange={() => changeCheckBoxStatus("ready")}
                />
                <TPCheckBox
                    labelText={<div style={{ display: "flex", alignItems: "center", gap: "5px", flexDirection: "row" }}>
                        <OnlineDotIcon />
                        <p style={{ marginBottom: "0px", fontWeight: "500", fontSize: "15px" }}>{onlineLabel} {online}</p>
                    </div>}
                    checked={checkBoxStatus.online}
                    onChange={() => changeCheckBoxStatus("online")}
                />
                <TPCheckBox
                    labelText={<div style={{ display: "flex", alignItems: "center", gap: "5px", flexDirection: "row" }}>
                        <OfflineDotIcon />
                        <p style={{ marginBottom: "0px", fontWeight: "500", fontSize: "15px" }}>{offlineLabel} {offline}</p>
                    </div>}
                    checked={checkBoxStatus.offline}
                    onChange={() => changeCheckBoxStatus("offline")}
                />
            </div>
            <br />
            <TPLoadingOverlay active={false}>
                <CockpitTeamActivityTable view={view} filters={checkBoxStatus} callback={handleCallback} data={view == "team" ? teamTableData : (view == "group" ? groupTableData : eventTableData)}  reloadCallback={reloadGrids} fromTeamActivity={true}/>
            </TPLoadingOverlay>
        </>
    )
}

export default ActiveTeamMembersPeople;