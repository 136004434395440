import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import { PreviewModalLabels } from "@/models/EmailTemplates/EmailTemplateModels";
import { StyledDialogContent } from "@/modules/core/design-system/design-system-styles";
import { Alert, Dialog, DialogProps } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";

type PreviewEmailModalProps = {
  status: boolean;
  email: string;
  componentLabels: PreviewModalLabels;
  handleModalClose: Function;
  type?: "inbound" | "outbound";
  children?: React.ReactNode;
  isReview?: boolean;
  handleCompleteAction: Function;
  readOnly?: boolean;
};

function PreviewEmailModal({
  status,
  email,
  componentLabels,
  handleModalClose,
  type = "inbound",
  children,
  isReview,
  handleCompleteAction,
  readOnly = false,
}: PreviewEmailModalProps): ReactElement {
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose: DialogProps["onClose"] = () => {
    setOpen(false);
    handleModalClose();
  };

  const completeActionClicked = () => {
    handleCompleteAction();
    handleClose({}, "backdropClick");
  }

  const ButtonsManagement = (): JSX.Element => {
    return (
      <>
        <TPButton
          id="cancel-process"
          customType={ButtonCustomType.secondary}
          onClick={handleClose}
          style={{ padding: "0 16px" }}
          isDesignSystem
        >
          {componentLabels.cancel}
        </TPButton>
        <TPButton
          id={`${isReview ? "save-and-send-email" : "replace-email"}`}
          onClick={completeActionClicked}
          style={{ padding: "0 16px" }}
          isDesignSystem
        >
          {isReview ? componentLabels.saveAndSend : componentLabels.replace}
        </TPButton>
      </>
    )
  }

  useEffect(() => {
    setOpen(status);
    status ? handleClickOpen() : handleModalClose();
  }, [status]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ fontFamily: "Noto Sans" }}
      maxWidth="lg"
      aria-labelledby="case-status-dialog"
      disablePortal
    >
      <StyledDialogContent>
        <div className="email-content" style={{ marginLeft: 0 }}>
          {children && !readOnly ? (
            <>
              {children}
            </>
          ) : (
            <>
              <p className="title">{componentLabels.titlePreview}</p>
              <Alert
                sx={{ fontFamily: "Noto Sans", fontSize: "14px" }}
                severity="info"
              >
                {type === "inbound"
                  ? componentLabels.inboundAlert
                  : componentLabels.outboundAlert}
              </Alert>
              <div
                className="email"
                dangerouslySetInnerHTML={{ __html: email }}
              ></div>
            </>
          )}
          <div className="actions">
            {type === "inbound" ? (
              <ButtonsManagement />
            ) : (
              <>
                {isReview && !readOnly ? (
                  <ButtonsManagement />
                ) : (
                  <TPButton
                    id="close-preview"
                    onClick={handleClose}
                    style={{ padding: "0 16px" }}
                    isDesignSystem
                  >
                    {componentLabels.close}
                  </TPButton>
                )}
              </>
            )}
          </div>
        </div>
      </StyledDialogContent>
    </Dialog>
  );
}

export default PreviewEmailModal;
