import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import {
  AdditionalDataInputDTO,
  AdditionalDataTaskTypeInputDTO,
} from "@/models/AdditionalData/AdditionalDataInputDTO";
import {
  AdditionalDataExportModel,
  AdditionalDataImportResponse,
  AdditionalDataTaskTypeViewModel,
  AdditionalDataViewModel,
} from "@/models/AdditionalData/AdditionalDataModels";
import { AdditionalDataTypeInfoViewModel } from "@/models/AdditionalDataType/AdditionalDataTypeModels";

export class AdditionalDataService {
  serviceFileName: string = "AdditionalDataService.ts";
  public async getAll(
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<AdditionalDataViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additionaldata";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getAll ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getAll`);
      throw new Error(`Error ${this.serviceFileName} getAll`);
    }
  }
  public async getByTypeId(
    typeId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<AdditionalDataViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/additionaldata/type/${typeId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getByTypeId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getByTypeId`);
      throw new Error(`Error ${this.serviceFileName} getByTypeId`);
    }
  }

  public async getAllByTypeId(
    codes: Array<string>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<AdditionalDataViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/additionaldata/codes/${codes.join("|")}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getByTypeId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getByTypeId`);
      throw new Error(`Error ${this.serviceFileName} getByTypeId`);
    }
  }

  public async deleteById(
    additionalDataId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additionaldata/" + additionalDataId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteById`);
      throw new Error(`Error ${this.serviceFileName} deleteById`);
    }
  }
  public async insertAdditionalData(
    inputDTO: AdditionalDataInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additionaldata";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertAdditionalData ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertAdditionalData`);
      throw new Error(`Error ${this.serviceFileName} insertAdditionalData`);
    }
  }
  public async updateAdditionalData(
    inputDTO: AdditionalDataInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additionaldata";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateAdditionalData ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateAdditionalData`);
      throw new Error(`Error ${this.serviceFileName} updateAdditionalData`);
    }
  }
  public async getAdditionalDataById(
    AdditionalDataId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<AdditionalDataViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additionaldata/";
    url = url + AdditionalDataId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        false,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getAdditionalDataById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getAdditionalDataById`);
      throw new Error(`Error ${this.serviceFileName} getAdditionalDataById`);
    }
  }
  public async getByClassifiers(
    branch1: string,
    branch2: string,
    branch3: string,
    branch4: string,
    branch5: string,
    filterIsActive: string,
    filterIsVisible: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<AdditionalDataViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additionaldata/get-by-classifier";
    url = url + "?branchid1=" + branch1;
    url = url + "&branchid2=" + branch2;
    url = url + "&branchid3=" + branch3;
    url = url + "&branchid4=" + branch4;
    url = url + "&branchid5=" + branch5;
    url = url + "&FilterIsActive=" + filterIsActive;
    url = url + "&FilterIsVisible=" + filterIsVisible;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getByClassifiers ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getByClassifiers`);
      throw new Error(`Error ${this.serviceFileName} getByClassifiers`);
    }
  }
  public async GetByParameters(
    searchParameter: string,
    sourceFilterCategoryId: string,
    filterCategory: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<AdditionalDataViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additionaldata/parameters";
    url = url + "?searchParameter=" + searchParameter;
    url = url + "&sourceFilterCategoryId=" + sourceFilterCategoryId;
    url = url + "&filterCategory=" + filterCategory;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} GetByParameters ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} GetByParameters`);
      throw new Error(`Error ${this.serviceFileName} GetByParameters`);
    }
  }

  public async getDataByIds(
    idsList: string[],
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<AdditionalDataExportModel>> {
    const httpclient = new TPHTTPService();
    const url =
      TPGlobal.baseAPIGateway +
      "api/tenant/" +
      TPGlobal.tenantHex +
      "/additionaldata/export";
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    try {
      const response: HTTPResponse = await httpclient.postData(
        url,
        { ids: idsList },
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getDataByIds ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getDataByIds`);
      throw new Error(`Error ${this.serviceFileName} getDataByIds`);
    }
  }

  public async importAdditionalData(
    data: AdditionalDataExportModel[],
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<AdditionalDataImportResponse>> {
    const httpclient = new TPHTTPService();
    const url =
      TPGlobal.baseAPIGateway +
      "api/tenant/" +
      TPGlobal.tenantHex +
      "/additionaldata/import";
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    const modifiedData = data.map((item) => {
      return {
        ...item,
        jsonParametersUtf8: TPGlobal.stringToUTF8(
          item.jsonParametersUtf8,
        ).toString(),
      };
    });

    try {
      const response: HTTPResponse = await httpclient.postData(
        url,
        modifiedData,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );

      return response.responseData.results;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} importAdditionalData ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} importAdditionalData`);
      throw new Error(`Error ${this.serviceFileName} importAdditionalData`);
    }
  }
}

export class AdditionalDataTaskTypeService {
  serviceFileName: string = "AdditionalDataTaskTypeService.ts";
  public async getByTaskTypeId(
    taskTypeId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<AdditionalDataTaskTypeViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additionaldatatasktype?TaskTypeId=";
    url = url + taskTypeId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getByTaskTypeId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getByTaskTypeId`);
      throw new Error(`Error ${this.serviceFileName} getByTaskTypeId`);
    }
  }
  public async deleteAdditionalDataTaskTypeById(
    taskTypeId: string,
    additionalDataId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/additionaldatatasktype/${taskTypeId}/${additionalDataId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteAdditionalDataTaskTypeById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} deleteAdditionalDataTaskTypeById`,
      );
      throw new Error(
        `Error ${this.serviceFileName} deleteAdditionalDataTaskTypeById`,
      );
    }
  }
  public async insertAdditionalDataTaskType(
    intpuDTO: AdditionalDataTaskTypeInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additionaldatatasktype";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        intpuDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertAdditionalDataTaskType ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} insertAdditionalDataTaskType`,
      );
      throw new Error(
        `Error ${this.serviceFileName} insertAdditionalDataTaskType`,
      );
    }
  }
  public async updateAdditionalDataTaskType(
    intpuDTO: AdditionalDataTaskTypeInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additionaldatatasktype";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        intpuDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateAdditionalDataTaskType ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} updateAdditionalDataTaskType`,
      );
      throw new Error(
        `Error ${this.serviceFileName} updateAdditionalDataTaskType`,
      );
    }
  }
  public async getAdditionalDataByIdTaskTypeAndIdAdda(
    additionaldatatasktypeId: string,
    addaId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<AdditionalDataTaskTypeViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/additionaldatatasktype/${additionaldatatasktypeId}/${addaId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getAdditionalDataByIdTaskTypeAndIdAdda ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getAdditionalDataByIdTaskTypeAndIdAdda`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getAdditionalDataByIdTaskTypeAndIdAdda`,
      );
    }
  }

  //get by task id
  public async getByCaseTaskId(
    taskId: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<AdditionalDataTypeInfoViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additionaldatatasktype/by-task-id/";
    url = url + taskId.toString();

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getByTaskTypeId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getByTaskTypeId`);
      throw new Error(`Error ${this.serviceFileName} getByTaskTypeId`);
    }
  }
}
