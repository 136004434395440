import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useState } from "react";
import {
  CockpitDashboardLabels,
  CockpitView,
} from "../supervisor-cockpit.model";
import CockpitSubDate from "../utils/CockpitSubDate";
import CockpitTabs from "../utils/CockpitTabs";
import {
  StyledDashboardContent,
  StyledViewTab,
} from "./cockpit-dashboard-styles";
import DashboardEventView from "./components/DashboardEventView";
import DashboardGroupsView from "./components/DashboardGroupView";
import DashboardTeamView from "./components/DashboardTeamView";

interface CockpitDashboardCallBacks {
  callBackCommands: Function;
}

const CockpitDashboard = React.forwardRef(
  ({ callBackCommands }: CockpitDashboardCallBacks, ref) => {
    const component: string = "CockpitDashboard";

    const [componentLabels, setComponentLabels] =
      useState<CockpitDashboardLabels>({} as CockpitDashboardLabels);

    const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(false);
    const [isResourcesLoaded, setIsResourcesLoaded] = useState<boolean>(false);
    const [view, setView] = useState<CockpitView>("team");

    const loadUtilsResources = async () => {
      setComponentLabels({
        title: await TPI18N.GetText(component, "Title"),
        apply: await TPI18N.GetText(TPGlobal.globalResourceSet, "ApplyFilterText"),
        goToDetail: await TPI18N.GetText(component, "GoToDetails"),
        project: await TPI18N.GetText("WallboardComponent", "SectionFilterSelectProjectLabel"),
        totalProjects: await TPI18N.GetText(component, "TotalProjects"),
        queue: await TPI18N.GetText("WallboardComponent", "SectionFilterSelectQueueLabel"),
        totalQueues: await TPI18N.GetText(component, "TotalQueues"),
        selectGroup: await TPI18N.GetText(component, "SelectGroup"),
        group: await TPI18N.GetText("WallboardComponent", "AccordionGroupLabel"),
        totalGroups: await TPI18N.GetText(component, "TotalGroups"),
        filterPlaceholder: await TPI18N.GetText("WallboardComponent", "SectionFilterSelectChoosePlaceholderLabel"),
        employee: await TPI18N.GetText(component, "EmployeeRanking"),
        lastDays: await TPI18N.GetText(component, "LastDays"),
        goToWallboards: await TPI18N.GetText(component, "GoToWallboards"),
        refreshData: await TPI18N.GetText(component, "RefreshData"),
        selectOthersLabel: await TPI18N.GetText(component, "SelectOthersLabel"),
        groupsTable: {
          projectName: await TPI18N.GetText("ProjectNewComponent", "FormInputNameLabel"),
          groupName: await TPI18N.GetText(component, "GroupName"),
          total: await TPI18N.GetText("ProjectDetailComponent", "TableColumnTotalCELabel"),
          ready: await TPI18N.GetText("ProjectDetailComponent", "TableColumnCEReadyLabel"),
          online: await TPI18N.GetText("ProjectDetailComponent", "TableColumnOnlineLabel"),
          working: await TPI18N.GetText("ProjectDetailComponent", "TableColumnWorkingLabel"),
          offline: await TPI18N.GetText(component, "Offline"),
          totalTickets: await TPI18N.GetText("GroupTakeNext", "CasesLabel"),
          workedTickets: await TPI18N.GetText("ProjectDetailComponent", "TableColumnWorkedLabel"),
          pendingTickets: await TPI18N.GetText("ProjectDetailComponent", "TableColumnPendingLabel"),
          type: await TPI18N.GetText("ProjectDetailComponent", "TableColumnTypeLabel"),
          customerExpert: await TPI18N.GetText("ProjectDetailComponent", "TableParentColumnCELabel"),
          tickets: await TPI18N.GetText("ProjectDetailComponent", "TableParentColumnTicketsLabel"),
          events: await TPI18N.GetText("ProjectDetailComponent", "TableColumnEventsLabel")
        },
        infoCards: {
          offline: await TPI18N.GetText(component, "Offline"),
          working: await TPI18N.GetText(component, "Working"),
          online: await TPI18N.GetText(component, "Online"),
          ready: await TPI18N.GetText(component, "Ready"),
          worked: await TPI18N.GetText(component, "Worked"),
          case: await TPI18N.GetText(component, "Case"),
          event: await TPI18N.GetText(component, "Event"),
          chat: await TPI18N.GetText(component, "Chat"),
          mail: await TPI18N.GetText(component, "Mail"),
          expired: await TPI18N.GetText(component, "Expired"),
          pending: await TPI18N.GetText(component, "Pending"),
          inProcess: await TPI18N.GetText(component, "InProgress"),
          closed: await TPI18N.GetText(component, "Closed"),
          history: await TPI18N.GetText(component, "History"),
          processing: await TPI18N.GetText(component, "Processing"),
        },
        groupTypes: {
          UPGRADEABLE: await TPI18N.GetText(TPGlobal.globalResourceSet, "UPGRADEABLE"),
          ROTABLE: await TPI18N.GetText(TPGlobal.globalResourceSet, "ROTABLE"),
          TAKENEXT: await TPI18N.GetText(TPGlobal.globalResourceSet, "TAKENEXT"),
          EVENTTAKENEXT: await TPI18N.GetText(TPGlobal.globalResourceSet, "EVENTTAKENEXT"),
          NORMAL: await TPI18N.GetText(TPGlobal.globalResourceSet, "NORMAL"),
          BACKOFFICE: await TPI18N.GetText("GroupTakeNext", "BACKOFFICE"),
          FRONTOFFICE: await TPI18N.GetText("GroupTakeNext", "FRONTOFFICE")
        }
      });
    };

    useEffect(() => {
      loadUtilsResources().then(() => setIsResourcesLoaded(true));
    }, [])

    return (
      <TPLoadingOverlay active={isLoadingScreen}>
        {isResourcesLoaded && (
          <div className="row">
            <div className="col">
              <CIMTitleSection isCIM width="100%">
                <TPPageTitle style={{ margin: 0 }}>
                  {componentLabels.title}
                  <CockpitSubDate />
                </TPPageTitle>
                <CockpitTabs activeView={view} handleViewChange={setView} />
              </CIMTitleSection>

              <StyledViewTab hide={view === "team"}>
                <StyledDashboardContent>
                  <DashboardTeamView
                    componentLabels={componentLabels}
                    setIsLoadingScreen={setIsLoadingScreen}
                  />
                </StyledDashboardContent>
              </StyledViewTab>

              <StyledViewTab hide={view === "group"}>
                <StyledDashboardContent>
                  <DashboardGroupsView
                    componentLabels={componentLabels}
                    setIsLoadingScreen={setIsLoadingScreen}
                  />
                </StyledDashboardContent>
              </StyledViewTab>

              <StyledViewTab hide={view === "event"}>
                <StyledDashboardContent>
                  <DashboardEventView
                    componentLabels={componentLabels}
                    setIsLoadingScreen={setIsLoadingScreen}
                    callBackCommands={callBackCommands}
                  />
                </StyledDashboardContent>
              </StyledViewTab>
            </div>
          </div>
        )}
      </TPLoadingOverlay>
    );
  }
);

export default CockpitDashboard;
