import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageTitle
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  SequenceGeneratorSequencesNameEnum,
  SystemParametersEnum,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { TPI18N } from "@/services/I18nService";
import {FC, ReactElement, useEffect, useRef, useState} from "react";

import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { Clone } from "@/models/Workflow/WorkflowTypeClone";
import {
  WorkflowTypeInputDTO,
  WorkflowTypeInputDTOValidator,
} from "@/models/Workflow/WorkflowTypeInputDTO";
import { WorkflowTypeViewModel } from "@/models/Workflow/WorkflowTypeModels";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { ParametersService } from "@/services/ParametersService";
import { SequenceService } from "@/services/SequenceService";
import { WorkflowTypeService } from "@/services/WorkflowTypeService";
import {MultilingualTextBoxEvents} from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";

type InsertCloneProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};

type InsertUpdateStateType = {
  id: string;
  recordLanguageList: Array<TPKeyValue>;
  promiseLimit: string;
  isDirectSolution: boolean;
  isActive: boolean;
  //validator
  idErrorMessage: string;
  promiseLimitErrorMessage: string;
  promiseLimitUnitErrorMessage: string;
  descriptionErrorMessages: Array<string>;
};

enum commandsEnum {
  "create_home" = 0,
  "new_vertical_tab" = 1,
  "update_vertical_tab" = 2,
  "vertical_tab_click" = 3,
  "vertical_tab_close" = 4,
  "insertupdate_cancel_or_ok" = 5,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

const WorkflowDeailClone: FC<InsertCloneProps> = ({
  mode,
  recordId,
  callBackResult,
}): ReactElement => {
  //#region  Init
  const componentFileName: string = "WorkflowTypeClone.tsx";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  const [tempIdValue, setTempIdValue] = useState("");
  const [tempDescription, setTempDescription] = useState("");

  //Screen resources
  const resourceSet: string = "WorkflowTypeCloneComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [idLabel, setIdLabel] = useState("");
  const [newIdLabel, setnewIdLabel] = useState("");
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [isActiveLabel, setIsActiveLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");
  const [referenceId, setReferenceId] = useState("");
  const nameInputRef = useRef<MultilingualTextBoxEvents>();
  const [realMode, setRealMode] = useState(mode);
  const [realRecordId, setRealRecordId] = useState(recordId);

  const [selectedPromiseLimitUnit, setSelectedPromiseLimitUnit] = useState("");

  //Screen state
  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }
  let insertUpdateInitialState: InsertUpdateStateType = {
    id: recordId,
    promiseLimit: "",
    isDirectSolution: false,
    isActive: true,
    recordLanguageList: [],
    idErrorMessage: "",
    descriptionErrorMessages: [],
    promiseLimitUnitErrorMessage: "",
    promiseLimitErrorMessage: "",
  };

  const [cloneState, setCloneState] = useState(insertUpdateInitialState);

  //Multilanguage const
  const multilanguageTableName: String = "WOrkflowTYpe";
  const multilanguageFieldName: String = "Description_WOTY";
  //#endregion

  const loadResourcesAndLoadInfo = async () => {
    let i: number;
    //resources state
    setTitleLabel(await TPI18N.GetText(resourceSet, "TitleCloneLabel"));

    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );
    setIdLabel(await TPI18N.GetText(resourceSet, "IdCloneLabel"));
    setnewIdLabel(await TPI18N.GetText(resourceSet, "NewIdCloneLabel"));
    setDescriptionLabel(
      await TPI18N.GetText(resourceSet, "DescriptionCloneLabel"),
    );
    setIsActiveLabel(await TPI18N.GetText(resourceSet, "IsActiveCloneLabel"));

    //Load PromiseLimit Units
    await getTermUnitList();
    //screen state
    if (realMode == "Clone") {
      await getById(realRecordId);
    }
  };

  const getById = async (pRecordId: string) => {
    let serviceClient = new WorkflowTypeService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getById(
        pRecordId,
        false,
        true,
        expectedCodes,
      );

      let recordInfo: WorkflowTypeViewModel;
      recordInfo = { ...responseRequest };
      setTempIdValue(pRecordId);
      setTempDescription(recordInfo.description);

      let newInsertUpdateState = { ...cloneState };

      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }

      newInsertUpdateState.id = "";
      setCloneState(newInsertUpdateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getFunctionById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getFunctionById ex`);
      setIsLoadingScreen(false);
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const getTermUnitList = async () => {
    let parametersService = new ParametersService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await parametersService.getByParentIdAndFilterIsActive(
          SystemParametersEnum.TEUNI,
          TPActiveOptions.ACTIVE.toString(),
          false,
          true,
          expectedCodes,
        );

      let newTypeKeyValueList: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return {
            key: item.id,
            value: item.localizedDescription,
          };
        },
      );
      newTypeKeyValueList.unshift({ key: "", value: "--" });
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getTermUnitList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getTermUnitList ex`);
      return;
    }
  };

  const handleOnIdChange = (newId: string) => {
    let newInsertUpdateState = { ...cloneState };
    newInsertUpdateState.id = newId;
    newInsertUpdateState.idErrorMessage = "";
    setCloneState(newInsertUpdateState);
  };
  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...cloneState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.descriptionErrorMessages[index] = "";
    setCloneState(newInsertUpdateState);
  };

  const handleIsActiveChange = () => {
    let newInsertUpdateState = { ...cloneState };
    newInsertUpdateState.isActive = !cloneState.isActive;
    setCloneState(newInsertUpdateState);
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "cancel_general", recordId: recordId });
  };

  const handleOkButtonClick = async () => {
    if (nameInputRef.current?.isInvalid()) {
      nameInputRef.current?.markAsTouched();
      return;
    }

    const languages = nameInputRef.current?.getValue();
    const mainLanguage = languages?.find(({id}) => id === TPGlobal.TPClientDefaultLanguage);
    let isInValid: boolean = false;
    let i: number;
    let n: number;

    let recordInputDTO: WorkflowTypeInputDTO = {
      // id: cloneState.id,
      id: referenceId,
      promiseLimit: +cloneState.promiseLimit ?? null,
      promiseLimitUnit: selectedPromiseLimitUnit
        ? selectedPromiseLimitUnit
        : null,
      isDirectSolution: cloneState.isDirectSolution,
      isActive: cloneState.isActive,
      otherLocalizedValues: [
        {
          languageId: mainLanguage?.id!,
          localizedValue: mainLanguage?.value ?? "",
          order: 1
        },
        ...languages!
            .filter(({id}) => id !== TPGlobal.TPClientDefaultLanguage)
            .map(({id, value}, order) => ({languageId: id!, localizedValue: value ?? "", order: order + 2}))
      ],
      description: mainLanguage?.value!,
    };


    let inputDTOValidator = new WorkflowTypeInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...cloneState };

      if (resultValidator.id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.id,
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }
      if (resultValidator.description) {
        newInsertUpdateState.descriptionErrorMessages[0] =
          await TPI18N.GetResource(resultValidator.description);
      } else {
        newInsertUpdateState.descriptionErrorMessages[0] = "";
      }
      setCloneState(newInsertUpdateState);
      isInValid = true;
    }

    if (!recordInputDTO.promiseLimitUnit) {
      recordInputDTO.promiseLimit = null;
    }

    if (!isInValid) {
      if (realMode == "Clone") {
        await clone(recordInputDTO);
      }
    }
  };

  const clone = async (inputDTO: WorkflowTypeInputDTO) => {
    let serviceClient = new WorkflowTypeService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let clone = new Clone(
        inputDTO.id,
        tempIdValue,
        inputDTO.description,
        inputDTO.isActive,
        inputDTO.otherLocalizedValues,
      );

      let responseRequest = await serviceClient.clone(
        clone,
        true,
        true,
        expectedCodes,
      );

      if (responseRequest.responseResult) {
        callBackResult({
          result: "clone_general_insert_and_change_mode",
          payload: inputDTO.id,
        });
        //change mode
        setTitleLabel(await TPI18N.GetText(resourceSet, "TitleCloneLabel"));
        setRealRecordId(inputDTO.id);
        setRealMode("Clone");
      }
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} insert ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} insert ex`);
    }
    setIsLoadingScreen(false);
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQWOTY,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        // callBackResult({ result: "OK", recordId: recordId });
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  //Only once to set resources
  useEffect(() => {
    loadResourcesAndLoadInfo();
    generalAutomaticId();
  }, []);
  console.log("line 454>>>", mode, recordId);
  return (
    //#region  Render
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col-10">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-10">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdOldTextBox"
                      labelText={idLabel}
                      isMandatory={false}
                      value={tempDescription + " (" + tempIdValue + ")"}
                      onChange={() => {}}
                      maxLength={20}
                      disabled={true}
                      errorMessage={""}
                    />

                    <TPTextBox
                      id="IdTextBox"
                      labelText={newIdLabel}
                      isMandatory={true}
                      // value={cloneState.id}
                      value={referenceId}
                      onChange={(e: any) => handleOnIdChange(e.target.value)}
                      maxLength={20}
                      disabled={true}
                      errorMessage={cloneState.idErrorMessage}
                    />
                  </div>
                </div>
              </div>
              <MultilingualTextBox
                  ref={nameInputRef}
                  maxLength={200}
                  style={{ width: "calc(50% + 35px)" }}
                  value={cloneState.recordLanguageList?.map(({key, value}) => ({id: key, value}))}
              />
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPCheckBox
                      id="IdCheckBox"
                      labelText={isActiveLabel}
                      checked={cloneState.isActive}
                      onChange={(e: any) => handleIsActiveChange()}
                    ></TPCheckBox>
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-6">
            <TPPageAcceptCancelButtonsContainer>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={handleOkButtonClick}
              >
                {saveButtonLabel}
              </TPButton>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.link}
                onClick={handleCancelButtonClick}
                className={"ms-2"}
              >
                {cancelButtonLabel}
              </TPButton>
            </TPPageAcceptCancelButtonsContainer>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>

    //#endregion
  );
};

export default WorkflowDeailClone;
