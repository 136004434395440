import TPGlobal from "@/helpers/TPGlobal";
import { ComplementsRenderTP, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useImperativeHandle, useState } from "react";
import TPTextBox from "../bootstrap/forms/textbox/TPTextBox";
import { TPAddtionalDataUIModes } from "./TPAdditionalDataUImodes";

export type TPAdditionalDataOpenTextMultilineProps = {
    idControl: string;
    modeUI: TPAddtionalDataUIModes;
    labelText: string;
    labelStyles?: Array<TPKeyValue>;
    isMandatory: boolean;
    defaultValue: string;
    onValueChange?: Function | null;
    phoneValType: string;
    minLength: number;
    maxLength: number;
    phoneRegExpre: string;
    whiteList: string;
    complementsRenderTP?: ComplementsRenderTP;
};

const TPAdditionalDataPhone = React.forwardRef(
    (
        {
            idControl,
            modeUI,
            labelText,
            labelStyles = [],
            isMandatory,
            defaultValue,
            onValueChange = null,
            phoneValType,
            minLength,
            maxLength,
            phoneRegExpre,
            whiteList,
            complementsRenderTP
        }: TPAdditionalDataOpenTextMultilineProps,
        ref,
    ) => {
        //#region  Init
        //state
        const [textValue, setTextValue] = useState(defaultValue);
        const [errorMessageValue, setErrorMessageValue] = useState("");
        const [characterInvalidErrorMessage, setCharacterInvalidErrorMessage] =
            useState("");
        const [
            invalidRegularExpresionErrorMessage,
            setInvalidRegularExpresionErrorMessage,
        ] = useState("");
        //Screen resources
        const [invalidLengthErrorMessage, setInvalidLengthErrorMessage] =
            useState("");
        const [invalidCharactersErrorMessage, setInvalidCharactersErrorMessage] =
            useState("");
        const [mandatoryErrorMessage, setMandatoryErrorMessage] = useState("");
        //#endregion

        const loadResources = async () => {
            setInvalidLengthErrorMessage(
                await TPI18N.GetText(
                    TPGlobal.globalResourceSet,
                    "InputDTOInvalidLength",
                ),
            );
            setInvalidCharactersErrorMessage(
                await TPI18N.GetText(
                    TPGlobal.globalResourceSet,
                    "InputDTOInvalidIdChar",
                ),
            );
            setMandatoryErrorMessage(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),
            );
            setCharacterInvalidErrorMessage(
                await TPI18N.GetText(
                    TPGlobal.globalResourceSet,
                    "CharacterInvalidErrorMessage",
                ),
            );
            setInvalidRegularExpresionErrorMessage(
                await TPI18N.GetText(
                    TPGlobal.globalResourceSet,
                    "InvalidRegularExpresionErrorMessage",
                ),
            );
        };

        useImperativeHandle(ref, () => ({
            getValueFromParent() {
                return textValue;
            },
            validateFromParent() {
                switch (phoneValType) {
                    case "ADVANCE":
                        try {
                            new RegExp(phoneRegExpre);
                        } catch (e) {
                            setErrorMessageValue(invalidRegularExpresionErrorMessage);
                            return false;
                        }
                        let regexp = new RegExp(phoneRegExpre);
                        if (isMandatory && (!textValue || !regexp.test(textValue))) {
                            setErrorMessageValue(invalidRegularExpresionErrorMessage);
                            return false;
                        }
                        break;
                    default:
                        if (isMandatory && (!textValue || textValue.trim() === "")) {
                            setErrorMessageValue(mandatoryErrorMessage);
                            return false;
                        }
                        if (textValue && textValue.trim() !== "") {
                            if (textValue.trim().length > maxLength) {
                                setErrorMessageValue(invalidLengthErrorMessage);
                                return false;
                            }
                            if (textValue.trim().length < minLength) {
                                setErrorMessageValue(invalidLengthErrorMessage);
                                return false;
                            }
                            let objValid: any = validateWhitelist(textValue);
                            if (!objValid.result) {
                                setErrorMessageValue(
                                    `${characterInvalidErrorMessage}: ${objValid.car}`,
                                );
                                return false;
                            }
                            if (textValue !== TPGlobal.TPSanitize(textValue)) {
                                setErrorMessageValue(invalidCharactersErrorMessage);
                                return false;
                            }
                        }
                        break;
                }

                return true;
            },
        }));

        const validateWhitelist = (chars: string): any => {
            let found: boolean;
            let car: string;
            chars = chars.replace(/(?:\r\n|\r|\n)/g, "");
            for (let i = 0; i < chars.length; i++) {
                car = chars[i];
                found = false;
                for (let j = 0; j < whiteList.length; j++) {
                    const element = whiteList[j];
                    if (car == element) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    return { result: false, car: car };
                }
            }
            return { result: true, car: "" };
        };

        const handlerOnTextChange = (e: any) => {
            setErrorMessageValue("");
            setTextValue(e.target.value);
            if (onValueChange) {
                onValueChange(e.target.value, idControl);
            }
        };

        const renderCollectMode = () => {
            let labelStyle: any = null;
            let i: number;
            for (i = 0; i <= labelStyles.length - 1; i++) {
                if (labelStyle == null) {
                    labelStyle = {};
                }
                labelStyle[labelStyles[i].key] = labelStyles[i].value;
            }

            return (
                //todo labelstyles
                //todo horizontal
                <div className="form-group">
                    <TPTextBox
                        id="IdTextBox"
                        labelText={labelText}
                        isMandatory={isMandatory}
                        value={textValue}
                        onChange={handlerOnTextChange}
                        maxLength={maxLength <= 0 ? undefined : maxLength}
                        errorMessage={errorMessageValue}
                        placeholder={complementsRenderTP ? complementsRenderTP.placeholder : ""}
                    />
                </div>
            );
        };

        const renderViewMode1 = () => {
            let labelStyle: any = null;
            let i: number;
            for (i = 0; i <= labelStyles.length - 1; i++) {
                if (labelStyle == null) {
                    labelStyle = {};
                }
                labelStyle[labelStyles[i].key] = labelStyles[i].value;
            }

            return (
                //todo labelstyles
                //todo horizontal
                <div className="form-group">
                    <TPTextBox
                        id="IdTextBox"
                        disabled={true}
                        labelText={labelText}
                        isMandatory={isMandatory}
                        value={textValue}
                        onChange={handlerOnTextChange}
                        maxLength={maxLength}
                        errorMessage={errorMessageValue}
                        placeholder={complementsRenderTP ? complementsRenderTP.placeholder : ""}
                    ></TPTextBox>
                </div>
            );
        };

        const renderViewMode2 = () => {
            let labelStyle: any = null;
            let i: number;
            for (i = 0; i <= labelStyles.length - 1; i++) {
                if (labelStyle == null) {
                    labelStyle = {};
                }
                labelStyle[labelStyles[i].key] = labelStyles[i].value;
            }

            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col">
                            <span className="tpbold" style={labelStyle}>
                                {`${labelText}: `}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-break">
                            <span>{textValue}</span>
                        </div>
                    </div>
                </React.Fragment>
            );
        };

        useEffect(() => {
            //load validation error messages
            loadResources();
        }, []);

        useEffect(() => {
            setTextValue(defaultValue);
        }, [defaultValue]);

        return (
            <React.Fragment>
                {modeUI == TPAddtionalDataUIModes.Collect
                    ? renderCollectMode()
                    : modeUI == TPAddtionalDataUIModes.View1
                        ? renderViewMode1()
                        : renderViewMode2()}
            </React.Fragment>
        );
    },
);

export default TPAdditionalDataPhone;
