import React, { useEffect, useState } from "react";

import ParentFolder from "./ImagesAdminParentFolder";
import {
    ImagesAdminContainerFolderDivStyle,
    ContainerSliderStyle,
    MenuFolderStyle,
} from "./StyleImageAdmin";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import { DataType } from "./useStatusImagesAdmin";
import { Data, LocalizedValues, RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";
import { TPButtonTypes, TPIconTypes } from "../../models/Global/TPGlobalEnums";
import TPIcon from "../../components/bootstrap/extend/TPIcons/TPIcon";
import TPTextBox from "../../components/bootstrap/forms/textbox/TPTextBox";
import TPButton from "../../components/bootstrap/components/buttons/TPButton";
import { TPI18N } from "../../services/I18nService";
import TPGlobal from "../../helpers/TPGlobal";
import InputLanguages from "./InputLanguages";
import { RecordLocalizedModel } from "../../models/Global/TPGlobalModels";
import { TPKeyValue } from "../../helpers/TPKeyValue";
import { MultilanguageFieldValueViewModel } from "../../models/multilanguage/MultilanguageFieldValueModel";
import { MultilanguageFieldValueService } from "../../services/MultilanguageFieldValueService";
import { LanguagesViewModel } from "../../models/Languages/LanguagesViewModel";
import { TPLog, TPLogType } from "../../helpers/TPLog";
import TPModalMsgAlertOk from "../../layouts/TPModalMsgAlertOk/TPModalMsgAlertOk";
import TPCheckBox from "../../components/bootstrap/forms/checkbox/TPCheckBox";

type ContainerFolderProps = {
    folders: Array<RecursiveRoutes>;

};

type ContainerFolderArgs = {
    mode?: string;
    startFolder?: string;

};

const multilanguageTableName: String = "IMAGes";
const multilanguageFieldName: String = "FolderName_IMAG";

const ContainerFolder = ({ mode, startFolder }: ContainerFolderArgs) => {
    const componentFileName: string = "ContainerFolder.tsx";
    const resourceSet: string = "ImagesAdmin";

    const [listFolderTemp, setListFolderTemp] = useState<Array<RecursiveRoutes>>([]);
    const [folderName, setFolderName] = useState<string>("");
    const [folderCode, setFolderCode] = useState<string>("");
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [activeNewFolder, setActiveNewFolder] = useState<boolean>(false);
    const [activeFolderDelete, setActiveFolderDelete] = useState<boolean>(false);
    const [activeFile, setActiveFile] = useState<boolean>(true);

    //Folder
    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [titlAddFolderLabel, setTitlAddFolderLabel] = useState("");
    const [titlUpdateFolderLabel, setTitlUpdateFolderLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");

    const [optionNoLabel, setOptionNoLabel] = useState("");
    const [optionYesLabel, setOptionYesLabel] = useState("");
    const [isActivelabel, setIsActivelabel] = useState("");


    const [msgDelete, setMsgDelete] = useState("");
    const [msgDisable, setMsgDisable] = useState("");


    const [recordLocalizedModel, setRecordLocalizedModel] = useState<Array<RecordLocalizedModel>>([]);
    const [baseLanguageOptions, setBaseLanguageOptions] = useState<Array<TPKeyValue>>([]);

    const loadResources = async () => {
        setSaveButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
        );
        setTitlAddFolderLabel(
            await TPI18N.GetText(resourceSet, "TitlAddFolderLabel"),
        );
        setTitlUpdateFolderLabel(
            await TPI18N.GetText(resourceSet, "TitlUpdateFolderLabel"),
        );
        setCancelButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        );

        setMsgDisable(
            await TPI18N.GetText(resourceSet, "MsgDeleteItemLabel"),
        );

        setOptionNoLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"),
        );

        setOptionYesLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes"),
        );
        setIsActivelabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "Active"),
        );
    };

    const value = useAppConctextImageAdmin();
    const {
        status,
        selectedItem,
        listFolderModel,
        handleSelected,
        handleInitializeFolders,
        handleNewFolder,
        containerFolderArgs,
        setContainerFolderArgs,
        selectedFolderParent,
        handleUpdateFolder,
        DeleteFileOrFolderById
    } = value;

    const handleOkFolder = () => {
        if (isUpdate) {
            if (selectedFolderParent) {
                selectedFolderParent.folderNameLocalizedValues = handleLocalizedValues(recordLocalizedModel);
                selectedFolderParent.folderCode = (folderCode ? folderCode : "");
                selectedFolderParent.isActive = activeFile;
            }
            handleUpdateFolder(selectedFolderParent);
        }
        else {
            handleAddFolder();
        }
        handleCancelOnClick();
    }

    const handleAddFolder = () => {
        let folder = new Data();
        folder.folderCode = folderCode;
        folder.folderName = folderName;
        folder.isActive = activeFile;
        folder.folderNameLocalizedValues = handleLocalizedValues(recordLocalizedModel);
        handleNewFolder(selectedFolderParent, folder);
    }

    const handleActiveNewFolder = () => {
        setIsUpdate(false);
        setFolderName("");
        setFolderCode("");
        setActiveNewFolder(true);
        setRecordLocalizedModel([]);
        let listLanguages = new Array<RecordLocalizedModel>();

        TPGlobal.TPClientAvailableLanguages.forEach((e) => {
            listLanguages.push({
                languageId: e.id,
                localizedValue: "",
                order: e.order
            });

        });
        setRecordLocalizedModel(listLanguages);
    }

    const handleDeleteFolder = () => {       
        if (!selectedFolderParent || (selectedFolderParent && selectedFolderParent.childrens.length<=0)) {
            DeleteFileOrFolderById(selectedFolderParent.id + "", DataType.folder);          
            handleCancelOnClick();
        }
    }

    const handleFolderActiveDelete = () => {
        setActiveFolderDelete(true);
    }

    const handleCancelOnClick = () => {
        setFolderName("");
        setFolderCode("");
        setActiveNewFolder(false);
        setActiveFolderDelete(false);
    }

    const handleActiveEditFolder = () => {
        setIsUpdate(true);
        if (selectedFolderParent && selectedFolderParent.id) {
            getRecordLanguageList(selectedFolderParent.id + "");
            setFolderName(selectedFolderParent.folderName);
            setFolderCode(selectedFolderParent.folderCode);
            setActiveFile(selectedFolderParent.isActive);
            setActiveNewFolder(true);
        }
    }

    const handleRecordLocalizedModel = (list: Array<LocalizedValues>) => {
        let temp = new Array<RecordLocalizedModel>();
        if (list) {
            let temp = list.map((s: LocalizedValues, index) => {
                return {
                    languageId: s.languageId,
                    localizedValue: s.localizedValue,
                    order: index
                }
            });
        }
        return temp;

    }

    const handleLocalizedValues = (list: Array<RecordLocalizedModel>) => {
        let temp = new Array<LocalizedValues>();
        if (list) {
            temp = list.filter(s => s.localizedValue != "").map((s: LocalizedValues, index) => {
                return {
                    languageId: s.languageId,
                    localizedValue: s.localizedValue,
                    order: index
                }
            });
        }
        return temp;

    }

    const loadLanguages = () => {
        let i: number;
        let expectedCodes: Array<number> = [200];

        let listLanguages: Array<TPKeyValue> = [];
        for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
            let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
            let keyValueElement: TPKeyValue = { key: item.id, value: item.name };
            listLanguages.push(keyValueElement);
        }

        if (!recordLocalizedModel)
            setRecordLocalizedModel(new Array<RecordLocalizedModel>);

        let listFormName = [...recordLocalizedModel];
        for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
            let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
            let keyValueElement: RecordLocalizedModel = {
                languageId: item.id,
                localizedValue: "",
                order: i,
            };
            listFormName.push(keyValueElement);
        }

        setRecordLocalizedModel(listFormName);

        if (listLanguages) {
            listLanguages.unshift({ key: "", value: "--" });
            let language = TPGlobal.language;
        }

    };

    const getRecordLanguageList = async (
        pRecordId: String,
    ): Promise<Array<MultilanguageFieldValueViewModel>> => {
        let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest =
                await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
                    multilanguageTableName,
                    multilanguageFieldName,
                    pRecordId,
                    false,
                    true,
                    expectedCodes,
                );

            let recordLocalizedModelTemp = [...recordLocalizedModel];

            recordLocalizedModelTemp.forEach((r) => {
                if (responseRequest) {
                    const foundRecord = responseRequest.find(s => s.languageId == r.languageId);

                    if (foundRecord) {
                        r.languageId = foundRecord.languageId || "";
                        r.localizedValue = foundRecord.recordDescription || "";
                    }
                }
            });

            setRecordLocalizedModel(recordLocalizedModelTemp);

            let recordInfo: Array<MultilanguageFieldValueViewModel>;
            recordInfo = [...responseRequest];

            return recordInfo;
        } catch (error) {
            TPLog.Log(
                `Error ParentFolder getRecordLanguageList ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ParentFolder getRecordLanguageList ex`);
            return [];
        }
    };

    const handleValidate = () => {
        if (!recordLocalizedModel) return true;
        if (recordLocalizedModel.length <= 0) return true;
        if (recordLocalizedModel.find(s => s.languageId == TPGlobal.TPClientDefaultLanguage)?.localizedValue == "") return true;

        return false;
    }

    const capitalizeFirstLetter = (text: string): string => {
        if (!text) return "";
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    useEffect(() => {
        setListFolderTemp(listFolderModel);
    }, [listFolderModel, mode]);

    useEffect(() => {
        loadLanguages();
        loadResources();
    }, []);

    useEffect(() => {
        let temp = { ...containerFolderArgs };
        temp.mode = mode;
        temp.startFolder = startFolder;
        setContainerFolderArgs(temp)
    }, [mode, startFolder]);

    return (
        <>
            {activeFolderDelete &&
                <ContainerSliderStyle
                    style={{ paddingRight: "0px", position: "fixed" }}
                >
                    <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div style={{
                            backgroundColor: "white",
                            padding: "20px",
                            borderRadius: "5px",
                            height: "168px",
                            width: "464px"
                        }} className="modal-content">
                            <div className="modal-body"
                                style={{
                                    paddingTop: "1.5px",
                                    gap: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center"
                                }}>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                    }}
                                >
                                    {msgDisable}
                                </div>

                            </div>
                            <br></br>
                            <div className="modal-footer">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            gap: "20px",
                                            alignItems: "center",

                                        }}
                                    >
                                        <  TPButton
                                            type={TPButtonTypes.empty}
                                            style={{
                                                border: "1px solid rgb(120, 0, 150)",
                                                color: "rgb(120, 0, 150)",
                                                padding: "5px",
                                                minWidth: "56px"
                                            }}
                                            onClick={() => handleCancelOnClick()}
                                        >
                                            {optionNoLabel}
                                        </TPButton>

                                        <TPButton
                                            type={TPButtonTypes.primary}
                                            onClick={() => handleDeleteFolder()}
                                            style={{ minWidth: "56px", maxWidth: "56px", padding: "7px" }}
                                        >
                                            {optionYesLabel}
                                        </TPButton>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContainerSliderStyle>
            }

            {activeNewFolder ? (
                <ContainerSliderStyle
                    style={{ paddingRight: "0px", position: "fixed" }}
                >
                    <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div style={{
                            backgroundColor: "white",
                            padding: "32px",
                            borderRadius: "5px",
                            height: "auto",
                            width: "768px",
                            minWidth: "550px"

                        }} className="modal-content">
                            <div className="modal-header justify-content-between">
                                <h5
                                    style={{
                                        fontSize: "20px",
                                        marginBottom: "24px",
                                        color: "rgba(160, 0, 149, 1)",
                                        fontWeight: "700"
                                    }}
                                >{isUpdate ?
                                    capitalizeFirstLetter(titlUpdateFolderLabel) :
                                    capitalizeFirstLetter(titlAddFolderLabel)}</h5>
                            </div>
                            <div className="modal-body"
                                style={{
                                    paddingTop: "1.5px",
                                    gap: "24px",
                                    display: "flex",
                                    flexDirection: "column"
                                }}>

                                <InputLanguages
                                    folderName={folderName}
                                    recordLocalized={recordLocalizedModel}
                                    baseLanguageOptions={baseLanguageOptions}
                                    handleChangedName={(e: any) => {
                                        setFolderName(e);
                                    }}
                                    handleChangedList={(e: any) => {
                                        setRecordLocalizedModel(e);
                                    }}
                                    activeSave={true}
                                ></InputLanguages>

                                <TPTextBox
                                    id="id-code-folder-new"
                                    isMandatory={false}
                                    labelText={"Code"}
                                    value={folderCode}
                                    onChange={(e: any) => {
                                        setFolderCode(e.target.value);
                                    }}
                                    maxLength={100}
                                    placeholder={""}
                                    errorMessage={""}
                                    containerStyle={{ width: "660px", minWidth: "550px" }}
                                />

                                <div>
                                    <TPCheckBox
                                        id="active-image"
                                        checkboxStyle={{}}
                                        labelText={isActivelabel}
                                        checked={activeFile}
                                        onChange={(e: any) => { setActiveFile(!activeFile); }}
                                    ></TPCheckBox>
                                </div>
                            </div>
                            <br></br>
                            <div className="modal-footer">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            gap: "20px",
                                            alignItems: "center",

                                        }}
                                    >

                                        <  TPButton
                                            type={TPButtonTypes.empty}
                                            style={{
                                                border: "1px solid rgb(120, 0, 150)",
                                                color: "rgb(120, 0, 150)",
                                                padding: "5px",
                                                minWidth: "79px"
                                            }}
                                            onClick={() => handleCancelOnClick()}
                                        >
                                            {cancelButtonLabel}
                                        </TPButton>

                                        <TPButton
                                            id="new-upload"
                                            isDesignSystem={true}
                                            onClick={() => handleOkFolder()}
                                            disabled={handleValidate()}
                                            style={{
                                                padding: "5px",
                                                minWidth: "65px"
                                            }}
                                        >
                                            {saveButtonLabel}
                                        </TPButton>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContainerSliderStyle >
            ) : null}

            <ImagesAdminContainerFolderDivStyle >
                {mode !== "view" &&
                    <MenuFolderStyle>
                        <TPIcon iconType={TPIconTypes.addFolder2}
                            style={{ cursor: "pointer" }} onClick={handleActiveNewFolder}
                        />
                        <TPIcon iconType={TPIconTypes.edit} onClick={handleActiveEditFolder}
                            style={!(selectedFolderParent && selectedFolderParent.id) ? { color: "#80808091" } : { cursor: "pointer" }} />
                        <TPIcon iconType={TPIconTypes.delete} onClick={handleFolderActiveDelete}
                            style={!(selectedFolderParent && selectedFolderParent.id) ? { color: "#80808091" } : { cursor: "pointer" }}
                        />
                    </MenuFolderStyle>
                }
                {mode === "view" ? (
                    <>
                        {listFolderTemp.filter(folder => folder.isActive).map(folder => (
                            <ParentFolder folders={folder} key={folder.id} />
                        ))}
                    </>
                ) : (
                    <>
                        {listFolderTemp.map(folder => (
                            <ParentFolder folders={folder} key={folder.id} />
                        ))}
                    </>
                )}
            </ImagesAdminContainerFolderDivStyle>
        </>
    );
};
export default ContainerFolder;
