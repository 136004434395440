import { FC, useImperativeHandle, useMemo, useState } from "react";
import "./resources/TaskReminderStyles.css";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TaskReminderModal } from "./TaskReminderModal";
import allThemes from "@/assets/styles/theme";
import { useTaskReminderLabels } from "./resources/TaskReminderLabels";

interface TaskReminderButtonProperties {
  taskId: number;
  caseId: number;
  date?: string;
  visible?: boolean;
  disabled?: boolean;
}

export const TaskReminderButton: FC<TaskReminderButtonProperties> = function ({ 
  taskId, 
  caseId, 
  date, 
  disabled = false, 
  visible = true,
}) {

  const {labels} = useTaskReminderLabels();
  const [modalVisible, setModalVisible] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const fixedDate = useMemo(() => {
    if (date && Date.parse(date)) {
      const newDate = new Date(date);
      newDate.setUTCDate(newDate.getDate());
      newDate.setUTCHours(newDate.getHours());
      newDate.setUTCMinutes(newDate.getMinutes());
      return newDate.toLocaleString();
    }
    return null;
  }, [date])

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {modalVisible && (
        <TaskReminderModal
        taskId={taskId}
        caseId={caseId}
        onAction={() => setModalVisible(false)}
        />
      )}
      {visible && (
        <button 
          className="task-reminder"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={(e) => {
            setModalVisible(true);
            e.stopPropagation();
          }}
          disabled={disabled}
        >
          <TPIcon 
            iconType={(!date && isHovering) ? TPIconTypes.bellPlus : TPIconTypes.bell} 
            style={{ color : date ? allThemes.base.primary : (isHovering ? "#CF0000" : "#E6E6E6"), fontSize: "20px" }} 
          />
          {fixedDate ? (
            <div className="name"><strong className="task-reminder-info">{labels.Reminder}:</strong><br />
              {fixedDate}
            </div>
          ) : (
            <div className="new-task-reminder">{labels.NewReminder}</div>
          )}
        </button>
      )}
    </div>
  )
}