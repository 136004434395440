import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

export const Timer = ({ initialSeconds }: { initialSeconds: number }) => {
    const [seconds, setSeconds] = useState(initialSeconds);

    useEffect(() => {
        const interval = setInterval(() => {
        setSeconds((prev) => prev + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatTime = (totalSeconds: number) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    };

    return (
        <Typography 
            sx={{
                fontFamily: "Noto Sans",
                fontSize: "14px",
                fontWeight: "medium",
                userSelect: "none",
            }}
            >
            {formatTime(seconds)}
        </Typography>
    )
};