import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
    TPPageSection,
    TPPageSectionTitle,
    TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ClientAdminViewModel } from "@/models/Client/ClientAdminViewModel";
import {
    ClientNewCustomerInputDTO,
    ClientNewCustomerInputDTOValidator
} from "@/models/Client/ClientNewCustomerInputDTO";
import { CustomerTypeViewModel } from "@/models/CustomerType/CustomerTypeModels";
import {
    ConfigParametersEnum,
    TPActiveOptions,
    TPButtonTypes,
} from "@/models/Global/TPGlobalEnums";
import { ClientService } from "@/services/ClientService";
import { CustomerTypeService } from "@/services/CustomerTypeService";
import { TPI18N } from "@/services/I18nService";
import { ParametersService } from "@/services/ParametersService";
import { FC, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import FormView from "../../pages/FormDesigner/FormView/FormView";
import CustomerInsertUpdateForm from "../../pages/CustomerForm/CustomerInsertUpdateForm";
import { isArray } from "util";

type TPModalNewClientProps = {
    mode: string;
    acceptLabel?: string;
    cancelLabel: string;
    isShown: boolean;
    callBackAnswer: Function;
    subsidiaryOrgId: number;
};

interface screenStateProps {
    customerType: string;
    documentType: string;
    document: string;
    name: string;
    lastName: string;
    email: string;
    phone: string;
    internalCode: string;

    //validaciones
    customerTypeErrorMessage: string;
    documentTypeErrorMessage: string;
    documentErrorMessage: string;
    nameErrorMessage: string;
    lastNameErrorMessage: string;
    emailErrorMessage: string;
    phoneErrorMessage: string;
    internalCodeErrorMessage: string;
    [key: string]: any;
}



enum ClientNewCustomerFields {
    DocumentTypeId = "documentTypeId",
    Document = "document",
    Name = "name",
    LastName = "lastName",
    PreferredEmail = "preferredEmail",
    PreferredPhone = "preferredPhone",
    InternalCode = "internalCode",
    SubsidiaryOrganizationId = "subsidiaryOrganizationId",
    AdditionalInfo = "additionalInfo",
    IsActive = "isActive",
    TypistIdGuid = "typistIdGuid",
    SubsidiaryName = "subsidiaryName",
    PreferredAddress = "preferredAddress",
    PreferredAddressInfo = "preferredAddressInfo",
    Geography = "geography",
    OrganizationId = "organizationId",
    RelationId = "relationId",
    RegionId = "regionId",
    ChannelId = "channelId",
    CustomerTypeId = "customerTypeId",
    AdditionalData = "additionalData"
}

const TPModalNewClient: FC<TPModalNewClientProps> = ({
    mode,
    acceptLabel,
    cancelLabel,
    isShown,
    callBackAnswer,
    subsidiaryOrgId,
}) => {
    let classModal: string;
    let styleModal: any = {};
    let backdropClass: string;
    const [isLoadingScreen, setIsLoadingScreen] = useState(false);

    if (isShown) {
        classModal = "modal show";
        styleModal["display"] = "block";
        styleModal["zIndex"] = "1057";
        backdropClass = "modal-backdrop show";
    } else {
        classModal = "modal";
        styleModal["display"] = "none";
        backdropClass = "";
    }

    const componentFileName: string = "TPModalNewClient.tsx";
    const resourceSet: string = "TPModalNewClient";
    const screenStateInitialState: screenStateProps = {
        customerType: "",
        documentType: "",
        document: "",
        name: "",
        lastName: "",
        email: "",
        phone: "",
        internalCode: "",
        customerTypeErrorMessage: "",
        documentTypeErrorMessage: "",
        documentErrorMessage: "",
        nameErrorMessage: "",
        lastNameErrorMessage: "",
        emailErrorMessage: "",
        phoneErrorMessage: "",
        internalCodeErrorMessage: "",
    };
    const [screenState, setScreenState] = useState<screenStateProps>(
        screenStateInitialState
    );
    const [customerTypeAllList, setCustomerTypeAllList] = useState<Array<any>>(
        []
    );
    const [customerTypeList, setCustomerTypeList] = useState<Array<TPKeyValue>>(
        []
    );
    const [documentsTypeList, setDocumentsTypeList] = useState<Array<TPKeyValue>>(
        []
    );
    const [formViewValueKey, setFormViewValueKey] = useState<Array<TPKeyValue>>();
    const [jsonParameters, setJsonParametersAdditionalData] = useState<Array<TPKeyValue>>();

    const [customerFormId, setCustomerFormId] = useState("");
    const [typeCustomer, settypeCustomer] = useState("");
    const [customerFormJson, setCustomerFormJson] = useState("");
    const [formReadyToSave, setFormReadyToSave] = useState(false);
    const [tryingToSaveForm, setTryingToSaveForm] = useState(false);

    //screen reosurces
    const [titleModal, setTitleModal] = useState("");
    const [customerInfoSectionLabel, setCustomerInfoSectionLabel] = useState("");
    const [otherInfoSectionLabel, setOtherInfoSectionLabel] = useState("");
    const [noteInsertMode, setNoteInsertMode] = useState("");

    const [customerTypeId, setCustomerTypeId] = useState("");
    const [customerTypeLabel, setCustomerTypeLabel] = useState("");
    const [documentTypeLabel, setDocumentTypeLabel] = useState("");
    const [documentLabel, setDocumentLabel] = useState("");
    const [nameLabel, setNameLabel] = useState("");
    const [lastNameLabel, setLastNameLabel] = useState("");
    const [emailLabel, setEmailLabel] = useState("");
    const [phoneLabel, setPhoneLabel] = useState("");
    const [notSavedNotification, setNotSavedNotification] = useState("");
    const [
        phoneNumberShouldContainOnlyNumbersLabel,
        setPhoneNumberShouldContainOnlyNumbersLabel,
    ] = useState("");
    const [internalCodeLabel, setInternalCodeLabel] = useState("");
    const [isUNKCustomer, setIsUNKCustomer] = useState(false);

    const documentTypeUNK: string = "UNK";

    const loadResources = async () => {
        setCustomerTypeLabel(
            await TPI18N.GetText(resourceSet, "CustomerTypeLabel")
        );
        setDocumentTypeLabel(
            await TPI18N.GetText(resourceSet, "DocumentTypeLabel")
        );
        setDocumentLabel(await TPI18N.GetText(resourceSet, "DocumentLabel"));
        setNameLabel(await TPI18N.GetText(resourceSet, "NameLabel"));
        setLastNameLabel(await TPI18N.GetText(resourceSet, "LastNameLabel"));
        setEmailLabel(await TPI18N.GetText(resourceSet, "EmailLabel"));
        setPhoneLabel(await TPI18N.GetText(resourceSet, "PhoneLabel"));
        setPhoneNumberShouldContainOnlyNumbersLabel(
            await TPI18N.GetText(
                resourceSet,
                "PhoneNumberShouldContainOnlyNumbersLabel"
            )
        );
        setInternalCodeLabel(
            await TPI18N.GetText(resourceSet, "InternalCodeLabel")
        );
        setTitleModal(await TPI18N.GetText(resourceSet, `Title${mode}Modal`));
        setCustomerInfoSectionLabel(
            await TPI18N.GetText(resourceSet, "CustomerInfoSectionLabel")
        );
        setOtherInfoSectionLabel(
            await TPI18N.GetText(resourceSet, "OtherInfoSectionLabel")
        );
        setNoteInsertMode(await TPI18N.GetText(resourceSet, "NoteInsertMode"));
        setNotSavedNotification(await TPI18N.GetText(resourceSet, "NotCustomerUpdateNotification"));
        setNotSavedNotification(await TPI18N.GetText(resourceSet, "CustomerUpdateNotification"));
        //Load Customer Type
        let customerTypeService = new CustomerTypeService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest = await customerTypeService.getCustomerTypeByFilter(
                TPActiveOptions.ACTIVE.toString(),
                false,
                true,
                expectedCodes
            );
            setCustomerTypeAllList(responseRequest);
            let newFilteredCustomerTypeList: Array<CustomerTypeViewModel> = [];

            if (mode === "Insert") {
                newFilteredCustomerTypeList = responseRequest.filter(
                    (x: CustomerTypeViewModel) => x.canCreate
                );
            } else {
                newFilteredCustomerTypeList = responseRequest.filter(
                    (x: CustomerTypeViewModel) => x.canUpdate
                );
            }

            let newCustomerTypeList: Array<TPKeyValue> =
                newFilteredCustomerTypeList.map(function (item) {
                    return { key: item.id, value: item.description ? item.description: item.localizedDescription };
                });
            newCustomerTypeList.unshift({ key: "", value: "--" });           
            setCustomerTypeList(newCustomerTypeList);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} loadResources getCustomerTypeByFilter ex`,
                TPLogType.ERROR,
                error
            );
            console.error(
                `Error ${componentFileName} loadResources getCustomerTypeByFilter ex`
            );
        }

        //Load document Type
        let parameterService = new ParametersService();
        expectedCodes = [200];
        try {
            let responseRequest =
                await parameterService.getByParentIdAndFilterIsActive(
                    ConfigParametersEnum.Document_Type,
                    TPActiveOptions.ACTIVE.toString(),
                    false,
                    true,
                    expectedCodes
                );

            let newDocumentsTypeList: Array<TPKeyValue> = responseRequest.map(
                function (item) {
                    return { key: item.id, value: item.localizedDescription };
                }
            );
            newDocumentsTypeList = newDocumentsTypeList.filter((x) => x.key !== "AN");
            newDocumentsTypeList.unshift({ key: "", value: "--" });
            setDocumentsTypeList(newDocumentsTypeList);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} loadResources getByParentIdAndFilterIsActive ex`,
                TPLogType.ERROR,
                error
            );
            console.error(
                `Error ${componentFileName} loadResources getByParentIdAndFilterIsActive ex`
            );
        }
      
        if (mode === "Update") {
            await getCustomerBySubsidiaryOrganizationId(subsidiaryOrgId);
            await getAditionalDataBySubsidiaryOrganizationId(subsidiaryOrgId);
        }
    };
    //123
    const getCustomerBySubsidiaryOrganizationId = async (
        subsidiaryOrgId: number
    ) => {
        let serviceClient = new ClientService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoadingScreen(true);

            let responseRequest =
                await serviceClient.getClientBySubsidiaryOrganizationId(
                    subsidiaryOrgId,
                    false,
                    true,
                    expectedCodes
                );

            let recordInfo: Array<ClientAdminViewModel>;
            recordInfo = [...responseRequest];

            if (recordInfo) {
                settypeCustomer(recordInfo[0].customerTypeId);
            }

            let newScreenStateState: screenStateProps = { ...screenState };
            newScreenStateState.customerType = recordInfo[0].customerTypeId;
            newScreenStateState.documentType = recordInfo[0].documentType;
            newScreenStateState.document = recordInfo[0].document;
            newScreenStateState.email = recordInfo[0].preferredEmail;
            newScreenStateState.internalCode = recordInfo[0].internalCode;
            newScreenStateState.lastName = recordInfo[0].lastName;
            newScreenStateState.name = recordInfo[0].name;
            newScreenStateState.phone = recordInfo[0].preferredPhone;

            setScreenState(newScreenStateState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getCustomerBySubsidiaryOrganizationId ex`,
                TPLogType.ERROR,
                error
            );
            console.error(
                `Error ${componentFileName} getCustomerBySubsidiaryOrganizationId ex`
            );
            setIsLoadingScreen(false);
        }
    };

    const getAditionalDataBySubsidiaryOrganizationId = async (
        subsidiaryOrgId: number
    ) => {
        let serviceClient = new ClientService();
        let expectedCodes: Array<number> = [200, 400, 404];

        try {
            setIsLoadingScreen(true);

            let responseRequest =
                await serviceClient.getAditionalDataBySubsidiaryOrganizationId(
                    subsidiaryOrgId,
                    false,
                    true,
                    expectedCodes
                );
                          
            let recordInfo: Array<any>;
            recordInfo = [...responseRequest];

            if (recordInfo && Array.isArray(recordInfo)) {
                let temp = recordInfo.map((e: any) => {

                    return {
                        key: e.id,
                        value: e.value
                    }

                });
                setCustomerFormJson(JSON.stringify(temp));
                setFormViewValueKey(temp);
            }

            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getCustomerBySubsidiaryOrganizationId ex`,
                TPLogType.ERROR,
                error
            );
            console.error(
                `Error ${componentFileName} getCustomerBySubsidiaryOrganizationId ex`
            );
            setIsLoadingScreen(false);
            setFormViewValueKey([]);
        }
    };




    const handleLoadFormId = () => {
        if (typeCustomer != "" && customerTypeAllList.length > 0) {
            let temp = customerTypeAllList.find(s => s.id == typeCustomer);
            if (temp)
                setCustomerFormId(temp.customerFormId);
        }
    }

    const handleGetDataTypeCustomer = (mode: ClientNewCustomerFields) => {


        if (customerFormJson && customerFormJson != "") {
            if (ClientNewCustomerFields.DocumentTypeId == mode) return handleGetControlTypeCustomerById(TPGlobal.ControlFormTypeCustomer.S_DBDOTY);
            if (ClientNewCustomerFields.Document == mode) return handleGetControlTypeCustomerById(TPGlobal.ControlFormTypeCustomer.S_DBDOCU);
            if (ClientNewCustomerFields.Name == mode) return handleGetControlTypeCustomerById(TPGlobal.ControlFormTypeCustomer.S_DBNAME);
            if (ClientNewCustomerFields.LastName == mode) return handleGetControlTypeCustomerById(TPGlobal.ControlFormTypeCustomer.S_DBLANA);
            if (ClientNewCustomerFields.PreferredEmail == mode) return handleGetControlTypeCustomerById(TPGlobal.ControlFormTypeCustomer.S_DBEMAIL);
            if (ClientNewCustomerFields.PreferredPhone == mode) return handleGetControlTypeCustomerById(TPGlobal.ControlFormTypeCustomer.S_DBPH1);
            if (ClientNewCustomerFields.InternalCode == mode) return handleGetControlTypeCustomerById(TPGlobal.ControlFormTypeCustomer.S_DBINCODE);
            if (ClientNewCustomerFields.IsActive == mode) return handleGetControlTypeCustomerById(TPGlobal.ControlFormTypeCustomer.S_DBACTIVE);

        }
        return "";
    }

    const handleGetDataModelJsonProperty = (mode: ClientNewCustomerFields) => {

        if (jsonParameters) {

            let data = jsonParameters.forEach((e: TPKeyValue) => {
                let parametes = JSON.parse(e.value);
                if (parametes && parametes.modelProperty + "".toLowerCase() == mode.toLowerCase()) {
                    if (customerFormJson && customerFormJson) {
                        let additionalData: Array<TPKeyValue> = JSON.parse(customerFormJson);
                        let selected = additionalData.find(s => s.key == e.key);
                        if (selected && selected.value) return selected.value;
                    }

                }
            })

        }

        return null;
    }


    const handleGetDataProperty = (modeProperty: ClientNewCustomerFields) => {
        let newScreenStateState: screenStateProps = { ...screenState };
 
        if (modeProperty == ClientNewCustomerFields.CustomerTypeId) {
            if (mode == "Intert")
                return customerTypeId;
            else
                return newScreenStateState.customerType;
        }

        return "";
    }


    const handleGetControlTypeCustomerById = (id: string) => {

        let additionalData: Array<TPKeyValue> = JSON.parse(customerFormJson);

        if (additionalData) {
            let temp = additionalData.find(k => k.key == id);           
            if (temp) return temp.value + "";
        }

        return "";
    }


    //123
    const handlerOnOKClick = async () => {
        setTryingToSaveForm(true);

        let recordInputDTO: ClientNewCustomerInputDTO = {

            documentTypeId: handleGetDataTypeCustomer(ClientNewCustomerFields.DocumentTypeId),
            document: handleGetDataTypeCustomer(ClientNewCustomerFields.Document),
            name: handleGetDataTypeCustomer(ClientNewCustomerFields.Name),
            lastName: handleGetDataTypeCustomer(ClientNewCustomerFields.LastName),
            internalCode: handleGetDataTypeCustomer(ClientNewCustomerFields.InternalCode),
            isActive: handleGetDataTypeCustomer(ClientNewCustomerFields.IsActive).toLowerCase() == "1",
            additionalData: new Array(),
            customerTypeId: handleGetDataProperty(ClientNewCustomerFields.CustomerTypeId),
            typistIdGuid: TPGlobal.currentUserGuid,
            preferredEmail: handleGetDataTypeCustomer(ClientNewCustomerFields.PreferredEmail),
            preferredPhone: handleGetDataTypeCustomer(ClientNewCustomerFields.PreferredPhone),

            subsidiaryOrganizationId:  subsidiaryOrgId,
            additionalInfo: handleGetDataModelJsonProperty(ClientNewCustomerFields.AdditionalInfo),
            subsidiaryName: handleGetDataModelJsonProperty(ClientNewCustomerFields.SubsidiaryName),
            preferredAddress: handleGetDataModelJsonProperty(ClientNewCustomerFields.PreferredAddress),
            preferredAddressInfo: handleGetDataModelJsonProperty(ClientNewCustomerFields.PreferredAddressInfo),
            geography: handleGetDataModelJsonProperty(ClientNewCustomerFields.Geography),
            organizationId: handleGetDataModelJsonProperty(ClientNewCustomerFields.OrganizationId),
            relationId: handleGetDataModelJsonProperty(ClientNewCustomerFields.RelationId),
            regionId: handleGetDataModelJsonProperty(ClientNewCustomerFields.RegionId),
            channelId: handleGetDataModelJsonProperty(ClientNewCustomerFields.ChannelId),
        };


        if (customerFormJson && customerFormJson != "") {
            let data = JSON.parse(customerFormJson) as Array<TPKeyValue>;

            if (data) {
                recordInputDTO.additionalData = data.map((e) => {
                    return {
                        id: e.key,
                        value: e.value
                    }
                });
            }
        }
        setTryingToSaveForm(true);
        if (!formReadyToSave) return null;


        if (mode === "Insert") {
            await insertNewClient(recordInputDTO);
        } else {
            await updateClient(recordInputDTO);
        }

        setScreenState(screenStateInitialState);
    };

    const insertNewClient = async (inputDTO: ClientNewCustomerInputDTO) => {
        let serviceClient = new ClientService();
        let expectedCodes: Array<number> = [200];


        let Id_SUOR: string;
        let Id_SUBS: string;
        let Id_CLIE: string;
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.insertNewClient(
                inputDTO,
                true,
                true,
                expectedCodes
            );
            setIsLoadingScreen(false);

            if (responseRequest.responseResult) {
                //get id_suor
                Id_SUOR = responseRequest.responseData.keyList.filter(
                    (x: TPKeyValue) => x.key === "id_SUOR"
                )[0]["value"];
                Id_SUBS = responseRequest.responseData.keyList.filter(
                    (x: TPKeyValue) => x.key === "id_SUBS"
                )[0]["value"];
                Id_CLIE = responseRequest.responseData.keyList.filter(
                    (x: TPKeyValue) => x.key === "id_CLIE"
                )[0]["value"];
                callBackAnswer(true, Id_SUOR, Id_SUBS, Id_CLIE);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} insertNewClient ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} insertNewClient ex`);
            setIsLoadingScreen(false);
        }
    };

    const updateClient = async (inputDTO: ClientNewCustomerInputDTO) => {
        let serviceClient = new ClientService();
        let expectedCodes: Array<number> = [200];
        let Id_SUOR: string;
        try {
            setIsLoadingScreen(true);

            if (customerFormJson && customerFormJson != "") {
                let data = JSON.parse(customerFormJson) as Array<TPKeyValue>;

                if (data) {
                    inputDTO.additionalData = data.map((e) => {
                        return {
                            id: e.key,
                            value: e.value
                        }
                    });
                }

            }

            let responseRequest = await serviceClient.updateClient(
                inputDTO,
                true,
                true,
                expectedCodes
            );

            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                //get id_suor
                Id_SUOR = responseRequest.responseData.keyList.filter(
                    (x: TPKeyValue) => x.key === "Id_SUOR"
                )[0]["value"];
                //todo
                callBackAnswer(true, Id_SUOR, null, null);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} updateClient ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} updateClient ex`);
            setIsLoadingScreen(false);
        }
    };

    const handleOnCancelOrCloseClick = () => {
        setScreenState(screenStateInitialState);
        callBackAnswer(false, "", "", "");
    };

    const handleInputTextChange = (propertyName: string, newValue: any) => {
        let newScreenState = { ...screenState };
        newScreenState[propertyName] = newValue;
        let temp = customerTypeAllList.find(s => s.id == newValue);
        if (temp)
            setCustomerFormId(temp.customerFormId);

        setCustomerTypeId(newValue);

        newScreenState[propertyName + "ErrorMessage"] = "";
        setScreenState(newScreenState);
    };

    //const handleInputTextChange = (propertyName: string, newValue: any) => {
    //    // Define validation rules based on propertyName
    //    switch (propertyName) {
    //        case "phone":
    //            if (/^[0-9]*$/.test(newValue)) {
    //                setScreenState({
    //                    ...screenState,
    //                    [propertyName]: newValue,
    //                    [propertyName + "ErrorMessage"]: "",
    //                });
    //            } else {
    //                setScreenState({
    //                    ...screenState,
    //                    [propertyName]: newValue,
    //                    [propertyName + "ErrorMessage"]:
    //                        phoneNumberShouldContainOnlyNumbersLabel,
    //                });
    //            }
    //            break;
    //        // Add cases for other specific fields as needed
    //        default:
    //            let newScreenState = { ...screenState };
    //            newScreenState[propertyName] = newValue;
    //            newScreenState[propertyName + "ErrorMessage"] = "";
    //            setScreenState(newScreenState);
    //            break;
    //    }
    //};




    const handleJsonDataCustomer = (
        json: string
    ) => {       
        setCustomerFormJson(json);
    }

    const handleFormReadyToSave = (
        mode: boolean
    ) => {
        setFormReadyToSave(mode);
    }

    const handleDocumentTypeInputTextChange = (
        propertyName: string,
        newValue: any
    ) => {
        let newScreenState = { ...screenState };
        newScreenState[propertyName] = newValue;
        newScreenState[propertyName + "ErrorMessage"] = "";
        if (newValue === documentTypeUNK) {
            newScreenState["document"] = "";
            newScreenState[document + "ErrorMessage"] = "";
            setIsUNKCustomer(true);
        } else {
            setIsUNKCustomer(false);
        }
        setScreenState(newScreenState);
    };


    useEffect(() => {
        handleLoadFormId();
    }, [typeCustomer, customerTypeAllList]);

    useEffect(() => {
        loadResources();
        if (mode === "Insert")
            setFormViewValueKey([]);
    }, [mode]);

    return (
        <>
            <div
                className={classModal}
                tabIndex={-1}
                data-bs-backdrop="static"
                style={styleModal}
            >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header justify-content-between">
                            <TPPageTitle className={"modal-title"} style={{ margin: "0" }}>
                                {titleModal}
                            </TPPageTitle>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => {
                                    handleOnCancelOrCloseClick();
                                }}
                            ></button>
                        </div>
                        <div className="modal-body" style={{ height: "auto" }}>
                            <TPLoadingOverlay active={isLoadingScreen} top={"200px"} isModal>
                                <div className="row">
                                    <div className="col-6">
                                        <TPPageSectionTitle>
                                            {customerInfoSectionLabel}
                                        </TPPageSectionTitle>
                                    </div>
                                </div>
                                <TPPageSection>
                                    {mode === "Insert" && (
                                        <div className="row">
                                            <div className="col">
                                                <div className="d-flex mt-2 ">
                                                    <span className="alert alert-info tpw100per">
                                                        {noteInsertMode}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <TPSelect
                                                    id="IdSelect"
                                                    isMandatory={true}
                                                    labelText={customerTypeLabel}
                                                    onChange={(e: any) => {
                                                        setCustomerFormId("");
                                                        handleInputTextChange(
                                                            "customerType",
                                                            e.target.value
                                                        )
                                                    }
                                                    }
                                                    dataSource={customerTypeList}
                                                    value={screenState.customerType}
                                                    disabled={mode === "Insert" ? false : true}
                                                    errorMessage={screenState.customerTypeErrorMessage}
                                                ></TPSelect>
                                            </div>
                                        </div>
                                    </div>

                                    {(customerFormId && customerFormId != "") &&
                                        <CustomerInsertUpdateForm
                                            handleJsonParameters={(e: any) => { setJsonParametersAdditionalData(e) }}
                                            handleJsonDataCustomer={(e: any) => { handleJsonDataCustomer(e) }}
                                            handleFormReadyToSave={(e: any) => { handleFormReadyToSave(e) }}
                                            customerFormId={customerFormId}
                                            formViewValueKey={formViewValueKey}
                                            tryingToSaveForm={tryingToSaveForm}
                                            disableEdit={(acceptLabel == undefined)}
                                            subsidiaryOrgId={subsidiaryOrgId}
                                            mode={mode}
                                        />
                                    }


                                </TPPageSection>

                            </TPLoadingOverlay>
                        </div>
                        <div className="modal-footer">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    width: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "nowrap",
                                        gap: "20px",
                                        alignItems: "center",
                                    }}
                                >
                                    {acceptLabel &&
                                        <TPButton
                                            type={TPButtonTypes.primary}
                                            onClick={() => {
                                                handlerOnOKClick();
                                            }}
                                        >
                                            {acceptLabel}
                                        </TPButton>
                                    }

                                    <TPButton
                                        type={TPButtonTypes.link}
                                        onClick={() => {
                                            handleOnCancelOrCloseClick();
                                        }}
                                    >
                                        {cancelLabel}
                                    </TPButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ zIndex: 1056 }} className={backdropClass}></div>
        </>
    );
};

export default TPModalNewClient;
