import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import { StyledActionButtons, StyledDialogContent } from "@/modules/core/design-system/design-system-styles";
import { Dialog, DialogProps } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import StatusCircle, { CaseStatus, CaseStatusResources, CaseStatusType } from "./StatusCircle";

type CaseStatusModalProps = {
    status: boolean;
    componentLabels: any;
    handleModalClose: Function;
};

function CaseStatusModal({
    status,
    componentLabels,
    handleModalClose,
}: CaseStatusModalProps): ReactElement {
    const resource: string = "ModalTaskStatusConventionComponent";

    const resources: CaseStatusResources = {
        solved: "Solved",
        pending: "Pending",
        expired: "Expired",
        lag: "DateLag",
    }

    const [open, setOpen] = useState<boolean>(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose: DialogProps["onClose"] = () => {
        setOpen(false);
        handleModalClose();
    };

    useEffect(() => {
        setOpen(status);
        status ? handleClickOpen() : handleModalClose();
    }, [status]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{ fontFamily: "Noto Sans" }}
            maxWidth="xl"
            aria-labelledby="case-status-dialog"
        >
            <StyledDialogContent>
                <p className="title">{componentLabels.title}</p>
                <div className="content">
                    {Object.keys(CaseStatus).map((value) => (
                        <div className="content-item" key={value}>
                            <div className="content-item-status">
                                <StatusCircle type={value as CaseStatusType} />
                            </div>
                            <div className="content-item-title">
                                <TPLanguage
                                    resourceSet={resource}
                                    resourceId={resources[value as CaseStatusType]}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <StyledActionButtons isErrorFile={false} style={{ marginTop: 0 }}>
                    <TPButton
                        id="ok-dialog"
                        isDesignSystem
                        onClick={handleClose}
                        style={{ padding: "1px 18px" }}
                    >
                        {componentLabels.ok}
                    </TPButton>
                </StyledActionButtons>
            </StyledDialogContent>
        </Dialog>
    );
}

export default CaseStatusModal;
