import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPModal from "@/layouts/TPModal/TPModal";
import { FC, useEffect, useMemo, useState } from "react";
import { useCaseToolbarLabels } from "./Assets/CaseToolbarLabels";
import "./Assets/CaseToolbarStyles.css";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  CaseToolbarItem,
  CaseToolbarItemOrder,
} from "@/models/CaseToolbar/CaseToolbarModel";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { TPDragAndDropSectionStyled } from "../InboundMailboxes/InboundMailboxesAssignRulesStyles";
import { CaseToolbarService } from "@/services/CaseToolbarService";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { IconType } from "react-icons";
import * as availableIcons from "react-icons/md";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CaseToolbarSlice } from "./Assets/CaseToolbarSlice";
import { useDispatch } from "react-redux";

interface CaseToolbarItemReorderModalProps {
  profileId: string;
  items: CaseToolbarItem[];
  onClose: Function;
}

export const CaseToolbarItemReorderModal: FC<CaseToolbarItemReorderModalProps> =
  function ({ profileId, onClose, items }) {
    const dispatch = useDispatch();
    const { labels } = useCaseToolbarLabels();
    const [loading, setLoading] = useState(false);
    const [itemOrders, setItemOrders] = useState<CaseToolbarItemOrder[]>([]);
    const displayItems = useMemo<CaseToolbarItem[]>(
      () =>
        items
          .filter((i) => i.profiles.find((p) => p.id_PROF === profileId))
          .map((i) => ({
            ...i,
            order: itemOrders.find((o) => o.id_CTBI === i.id)?.order_PCTI || 0,
          }))
          .sort((iA, iB) => {
            if (iA.order < iB.order) return -1;
            if (iA.order > iB.order) return 1;
            return 0;
          })
          ,
      [items, itemOrders]
    );

    const getItemOrders = async function () {
      if (!profileId || items.length === 0) return;
      setLoading(true);
      CaseToolbarService.getItemOrdersByProfile(
        profileId,
        false,
        true,
        [200, 404]
      )
        .then((orders) => setItemOrders(orders))
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    };

    const reorder: OnDragEndResponder = function (result) {
      if (!result || !result.destination) return;
      let destIndex = result.destination.index;
      if (destIndex === result.source.index) return;
      destIndex = destIndex + (result.destination.index > result.source.index ? 0.5 : -0.5);

      let newOrders: CaseToolbarItemOrder[] = structuredClone(itemOrders);
      newOrders.forEach((o) => {
        if (o.id_CTBI !== result.draggableId) {
          if (o.order_PCTI <= destIndex) {
            o.order_PCTI -= 1;
          } else {
            o.order_PCTI += 1;
          }
        } else {
          o.order_PCTI = destIndex;
        }
      });
      newOrders = newOrders
        .sort((oA, oB) => {
          if (oA.order_PCTI < oB.order_PCTI) return -1;
          if (oA.order_PCTI > oB.order_PCTI) return 1;
          return 0;
        })
        .map((o, i) => ({ ...o, order_PCTI: i }));

      if (newOrders.length > 0) {
        setLoading(true);
        CaseToolbarService.reorderByProfile({
          id_PROF: profileId,
          jsonOrders: newOrders.map(o => ({ id_CTBI: o.id_CTBI, order_PCTI: String(o.order_PCTI) }))
        }, true, true, [200])
        .catch(err => console.error(err))
        .finally(() => {
          setItemOrders([]);
          getItemOrders();
          dispatch(CaseToolbarSlice.actions.setContentLoaded(false));
          setLoading(false);
        })
      }
    };

    useEffect(() => {
      getItemOrders();
    }, []);

    return (
      <TPModal
        modalState={{
          callBackAnswer: () => {},
          isShown: true,
          titleModal: "_case_toolbar_preview",
          hideFooterButtons: true,
          hideXButton: true,
          hiddenHeader: true,
        }}
      >
        <TPLoadingOverlay active={loading}>
          <div id="case-toolbar-reorder-modal-container">
            <TPPageTitle style={{ margin: "0" }}>
              {labels.CaseToolbarPreview}
            </TPPageTitle>
            <div id="case-toolbar-reorder-modal-inner">
              <div id="case-toolbar-reorder-modal-left">
                {labels.Group}
                <div id="case-toolbar-reorder-modal-groups">
                  <div className="case-toolbar-reorder-modal-group-option">
                    {labels.CaseToolbar}
                  </div>
                </div>
                {labels.Items}
                <div id="case-toolbar-reorder-modal-items">
                  {itemOrders.length > 0 && (
                    <DragDropContext onDragEnd={reorder}>
                      <TPDragAndDropSectionStyled
                        style={{ width: "100%", height: "100%" }}
                      >
                        <div
                          className="row mx-0"
                          style={{
                            width: "100%",
                            height: "100%",
                            overflow: "auto",
                          }}
                        >
                          <Droppable droppableId="case-toolbar-droppable">
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{ padding: 0 }}
                              >
                                {displayItems
                                .map((item, idx) => (
                                  <Draggable draggableId={item.id} index={idx}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="case-toolbar-item-draggable"
                                      >
                                        {item.localizedDescription ||
                                          item.description ||
                                          item.id ||
                                          "???"}
                                        <TPIcon
                                          iconType={TPIconTypes.mdDragIndicator}
                                          className="case-toolbar-draggable-icon"
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      </TPDragAndDropSectionStyled>
                    </DragDropContext>)}
                </div>
              </div>
              <div id="case-toolbar-reorder-modal-right">
                <div id="case-toolbar-reorder-modal-preview">
                  <div id="case-toolbar-reorder-modal-preview-title">
                    {labels.Preview}
                  </div>
                  <div id="case-toolbar-reorder-modal-preview-items">
                    {displayItems.map((item) => (
                      <div className="case-toolbar-reorder-modal-preview-item">
                        {Boolean(item.image) &&
                          Boolean(
                            availableIcons[item.image as keyof IconType]
                          ) &&
                          (
                            availableIcons[
                              item.image as keyof IconType
                            ] as IconType
                          )({ fontSize: "24px", color: "#2E2E2E" })}
                        {item.localizedDescription || item.description || "???"}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div id="case-toolbar-reorder-modal-footer">
              <TPButton
                onClick={onClose}
                isDesignSystem
                style={{
                  padding: "11px 16px",
                }}
              >
                {labels.Ok}
              </TPButton>
            </div>
          </div>
        </TPLoadingOverlay>
      </TPModal>
    );
  };
