import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";

export type OrganizationsInputDTO = {
  id: string;
  name: string;
  image: string;
  isActive: boolean;
  nameLocalizedValues: Array<RecordLocalizedModel>;
};

export class OrganizationsInputDTOValidator extends Validator<OrganizationsInputDTO> {
  constructor() {
    super();

    const resourceSet = "OrganizationInsertUpdateComponent";

    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("image")
        .must((image) => {
          return TPGlobal.TPSanitize(image) == image;
        })
        .withMessage(resourceSet + "|InputDTOInvalidImage")
        .maxLength(200)
        .withMessage(resourceSet + "|InputDTOInvalidImageLength");
    } catch (error) {}
  }
}
