import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";
import { useEffect, useState } from "react";

const RESOURCE_SET = "MenuDefinition";

interface MenuDefinitionLabels {
  MenuDefAdmin: string;
  ReorderMenu: string;
  AddNew: string;
  Activate: string;
  Order: string;
  Section: string;
  Group: string;
  Item: string;
  URLLink: string;
  Image: string;
  NewItem: string;
  NewGroup: string;
  NewSection: string;
  ItemName: string;
  ItemType: string;
  GroupName: string;
  SectionName: string;
  PublicKeyExternalApp: string;
  Parameters: string;
  ShowAsActive: string;
  ApplyToProfiles: string;
  Cancel: string;
  Save: string;
  Yes: string;
  No: string;
  Search: string;
  Id: string;
  MenuLocalizedNames: string;
  Name: string;
  SelectIcon: string;
  MenuPreviewReorderTitle: string;
  ReorderInstructions: string;
  Close: string;
  Update: string;
  InsertParameters: string;
  Edit: string;
  Delete: string;
  GettingMenus: string;
  GetMenusError: string;
  GetMenuError: string;
  CreateSectionSuccess: string;
  CreateSectionError: string;
  CreateGroupSuccess: string;
  CreateGroupError: string;
  CreateItemSuccess: string;
  CreateItemError: string;
  UpdateSectionSuccess: string;
  UpdateSectionError: string;
  UpdateGroupSuccess: string;
  UpdateGroupError: string;
  UpdateItemSuccess: string;
  UpdateItemError: string;
  DeleteSectionSuccess: string;
  DeleteSectionError: string;
  DeleteGroupSuccess: string;
  DeleteGroupError: string;
  DeleteItemSuccess: string;
  DeleteItemError: string;
  LanguageList: string;
  Active: string;
  DeleteConfirm: string;
}

export const useMenuDefinitionLabels = function () {

  const [labels, setLabels] = useState<MenuDefinitionLabels>({} as MenuDefinitionLabels);

  const getLabels = async function () {
    setLabels({
      Activate: await TPI18N.GetText(RESOURCE_SET, "Activate"),
      AddNew: await TPI18N.GetText(RESOURCE_SET, "AddNew"),
      ApplyToProfiles: await TPI18N.GetText(RESOURCE_SET, "ApplyToProfiles"),
      Cancel: await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      Close: await TPI18N.GetText(RESOURCE_SET, "Close"),
      Group: await TPI18N.GetText(RESOURCE_SET, "Group"),
      Id: await TPI18N.GetText(TPGlobal.globalResourceSet, "Id"),
      Image: await TPI18N.GetText(RESOURCE_SET, "Image"),
      Item: await TPI18N.GetText(RESOURCE_SET, "Item"),
      ItemName: await TPI18N.GetText(RESOURCE_SET, "ItemName"),
      GroupName: await TPI18N.GetText(RESOURCE_SET, "GroupName"),
      SectionName: await TPI18N.GetText(RESOURCE_SET, "SectionName"),
      ItemType: await TPI18N.GetText(RESOURCE_SET, "ItemType"),
      MenuDefAdmin: await TPI18N.GetText(RESOURCE_SET, "MenuDefAdmin"),
      MenuLocalizedNames: await TPI18N.GetText(RESOURCE_SET, "MenuLocalizedNames"),
      MenuPreviewReorderTitle: await TPI18N.GetText(RESOURCE_SET, "MenuPreviewReorderTitle"),
      Name: await TPI18N.GetText(RESOURCE_SET, "Name"),
      NewGroup: await TPI18N.GetText(RESOURCE_SET, "NewGroup"),
      NewItem: await TPI18N.GetText(RESOURCE_SET, "NewItem"),
      NewSection: await TPI18N.GetText(RESOURCE_SET, "NewSection"),
      No: await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"),
      Order: await TPI18N.GetText(RESOURCE_SET, "Order"),
      Parameters: await TPI18N.GetText(RESOURCE_SET, "Parameters"),
      PublicKeyExternalApp: await TPI18N.GetText(RESOURCE_SET, "PublicKeyExternalApp"),
      ReorderInstructions: await TPI18N.GetText(RESOURCE_SET, "ReorderInstructions"),
      ReorderMenu: await TPI18N.GetText(RESOURCE_SET, "ReorderMenu"),
      Save: await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
      Search: await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      Section: await TPI18N.GetText(RESOURCE_SET, "Section"),
      SelectIcon: await TPI18N.GetText(RESOURCE_SET, "SelectIcon"),
      ShowAsActive: await TPI18N.GetText(TPGlobal.globalResourceSet, "ShowAsActive"),
      URLLink: await TPI18N.GetText(RESOURCE_SET, "URLLink"),
      Yes: await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes"),
      Update: await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
      InsertParameters: await TPI18N.GetText(RESOURCE_SET, "InsertParameters"),
      Edit: await TPI18N.GetText(TPGlobal.globalResourceSet, "Edit"),
      Delete: await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      GettingMenus: await TPI18N.GetText(RESOURCE_SET, "GettingMenus"),
      GetMenusError: await TPI18N.GetText(RESOURCE_SET, "GetMenusError"),
      CreateSectionSuccess: await TPI18N.GetText(RESOURCE_SET, "CreateSectionSuccess"),
      CreateSectionError: await TPI18N.GetText(RESOURCE_SET, "CreateSectionError"),
      CreateGroupSuccess: await TPI18N.GetText(RESOURCE_SET, "CreateGroupSuccess"),
      CreateGroupError: await TPI18N.GetText(RESOURCE_SET, "CreateGroupError"),
      CreateItemSuccess: await TPI18N.GetText(RESOURCE_SET, "CreateItemSuccess"),
      CreateItemError: await TPI18N.GetText(RESOURCE_SET, "CreateItemError"),
      GetMenuError: await TPI18N.GetText(RESOURCE_SET, "GetMenuError"),
      UpdateSectionSuccess: await TPI18N.GetText(RESOURCE_SET, "UpdateSectionSuccess"),
      UpdateSectionError: await TPI18N.GetText(RESOURCE_SET, "UpdateSectionError"),
      UpdateGroupSuccess: await TPI18N.GetText(RESOURCE_SET, "UpdateGroupSuccess"),
      UpdateGroupError: await TPI18N.GetText(RESOURCE_SET, "UpdateGroupError"),
      UpdateItemSuccess: await TPI18N.GetText(RESOURCE_SET, "UpdateItemSuccess"),
      UpdateItemError: await TPI18N.GetText(RESOURCE_SET, "UpdateItemError"),
      DeleteSectionSuccess: await TPI18N.GetText(RESOURCE_SET, "DeleteSectionSuccess"),
      DeleteSectionError: await TPI18N.GetText(RESOURCE_SET, "DeleteSectionError"),
      DeleteGroupSuccess: await TPI18N.GetText(RESOURCE_SET, "DeleteGroupSuccess"),
      DeleteGroupError: await TPI18N.GetText(RESOURCE_SET, "DeleteGroupError"),
      DeleteItemSuccess: await TPI18N.GetText(RESOURCE_SET, "DeleteItemSuccess"),
      DeleteItemError: await TPI18N.GetText(RESOURCE_SET, "DeleteItemError"),
      LanguageList: await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
      Active: await TPI18N.GetText(TPGlobal.globalResourceSet, "Active"),
      DeleteConfirm: await TPI18N.GetText(RESOURCE_SET, "DeleteConfirm"),
    })
  }

  useEffect(() => {
    getLabels();
  }, [])

  return { labels };
}