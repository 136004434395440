import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPChip } from "@/components/TPChip/TPChip";
import {
  CIMTitleSection,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { TaskTypeViewModel } from "@/models/TaskType/TaskTypeModels";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { TPI18N } from "@/services/I18nService";
import { TaskTypeService } from "@/services/TaskTypeService";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<TaskTypeViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

interface TaskTypeAdminInterface {
  callBackCommands: Function;
}

const TaskTypeAdmin = React.forwardRef(
  ({ callBackCommands }: TaskTypeAdminInterface, ref) => {
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    const componentFileName: string = "TaskTypeAdmin.tsx";
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const ResourceSet: string = "TaskTypeAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [nameColumnLabel, setNameColumnLabel] = useState("");
    const [componentNameColumnLabel, setComponentNameColumnLabel] = useState("");
    const [parameterColumnLabel, setParameterColumnLabel] = useState("");
    const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");
    const [isDecsionTaskColumnLabel, setIsDecisionTaskColumnLabel] = useState("");
    const [expiresColumnLabel, setExpiresColumnLabel] = useState("");
    const [isAddableColumnLabel, setIsAddableColumnLabel] = useState("");
    const [isCommentRequiredLabel, setIsCommentRequiredLabel] = useState("");
    const [isSystemRecordColumnLabel, setIsSystemRecordColumnLabel] = useState("");

    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");
    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );

    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
      columnsAreLoaded: true,
    };

    const loadResourcesAndTaskTypeFilter = async () => {
      setDeleteQuestion(await TPI18N.GetText(ResourceSet, "RecordDeleteConfirm"));
      setDeleteTitle(await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"));
      setDeleteOkLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"));
      setDeleteCancelLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"));
      setDeleteLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"));
      setUpdateLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"));
      setRefreshLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"));
      setNewLabel(await TPI18N.GetText(ResourceSet, "NewButton"));
      setExportLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"));
      setSearchLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"));
      setThereAreNoRecordsToShow(await TPI18N.GetText(TPGlobal.globalResourceSet, "DataTableNoCurrentData"));
      setTitleLabel(await TPI18N.GetText(ResourceSet, "TitleLabel"));
      setFilterLabel(await TPI18N.GetText(ResourceSet, "FilterIsActiveLabel"));
      setIdColumnLabel(await TPI18N.GetText(ResourceSet, "Id"));
      setNameColumnLabel(await TPI18N.GetText(ResourceSet, "Name"));
      setComponentNameColumnLabel(await TPI18N.GetText(ResourceSet, "ComponentName"),);
      setParameterColumnLabel(await TPI18N.GetText(ResourceSet, "Parameter"));
      setIsActiveColumnLabel(await TPI18N.GetText(ResourceSet, "IsActive"));
      setIsDecisionTaskColumnLabel(await TPI18N.GetText(ResourceSet, "IsDecision"),);
      setExpiresColumnLabel(await TPI18N.GetText(ResourceSet, "Escalates"));
      setIsAddableColumnLabel(await TPI18N.GetText(ResourceSet, "IsAddable"));
      setIsCommentRequiredLabel(await TPI18N.GetText(ResourceSet, "IsCommentRequired"));
      setIsSystemRecordColumnLabel(await TPI18N.GetText(ResourceSet, "IsSystemRecord"));
      setYesLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes"));
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));
      setExportLabel(await TPI18N.GetText("DynamicTable", "Export"));
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(ResourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(ResourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(ResourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };

    const reloadDataGrid = async (selectedFilter: string) => {
      let serviceClient = new TaskTypeService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.getTaskTypeByFilter(
          selectedFilter,
          false,
          true,
          expectedCodes,
        );
        let newAdminState: AdminStateType = {
          ...adminState,
        };
        newAdminState.gridData = [...responseRequest];
        setIsLoadingScreen(false);
        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
      }
    };
    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new TaskTypeService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteTaskTypeById(
            callBackData.recordId,
            true,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

	const filteredData = () => {
		let searcheableColumns: Array<string> = ['id', 'localizedDescription'];
		let i: number;
		let search: string;
		search = adminState.searchPattern.trim();
		return adminState.gridData.filter(function (item, index) {
			if (search == '' || search.length <= 2) {
				return item;
			}
			for (i = 0; i <= searcheableColumns.length - 1; i++) {
				let itemany: any;
				itemany = item;
				if (
					itemany[searcheableColumns[i]] &&
					itemany[searcheableColumns[i]].toString().toLowerCase().includes(search.toLowerCase())
				) {
					return item;
				}
			}
		});
	};

    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        default:
          return prevState;
      }
    }

    useEffect(() => {
      loadResourcesAndTaskTypeFilter()
        .then(function () {
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndTaskTypeFilter ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndTaskTypeFilter ex`,
          );
        });
    }, []);

    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    return (
      <>
        <TPModalQuestion
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <CIMTitleSection>
                <TPPageTitle style={{margin: 0}}>{titleLabel}</TPPageTitle>
                <TPButton
                  id="TaskDefinitionAdminNewButton"
                  key="TaskDefinitionAdminNewButton"
                  type={TPButtonTypes.primary}
                  withIcon={TPIconTypes.add}
                  onClick={handleNewClick}
                  iconAtRight={false}
                  isDesignSystem
                  style={{ padding: "11px 16px" }}
                  orientationIcon="left"
                >
                  {newLabel}
                </TPButton>
              </CIMTitleSection>
              <DynamicTable
                data={filteredData().map(entry => {
                  return {
                    id: entry.id,
                    description: entry.description,
                    localizedDescription: entry.localizedDescription,
                    localizedComponent: entry.localizedComponent,
                    jsonParameters: entry.jsonParameters,
                    isActive: entry.isActive,
                    isDecision: entry.isDecision,
                    escalates: entry.escalates,
                    isAddable: entry.isAddable,
                    isCommentRequired: entry.isCommentRequired,
                    isSystemRecord: entry.isSystemRecord
                  } as TaskTypeViewModel
                })}
                columnNames={{
                  id: idColumnLabel,
                  description: (nameColumnLabel + ` (${TPGlobal.TPClientAvailableLanguages[0].name})`),
                  localizedDescription: (nameColumnLabel + ` (${TPGlobal.getLanguageDescriptionByCode(TPGlobal.language)})`),
                  localizedComponent: componentNameColumnLabel,
                  jsonParameters: parameterColumnLabel,
                  isActive: isActiveColumnLabel,
                  isDecision: isDecsionTaskColumnLabel,
                  escalates: expiresColumnLabel,
                  isAddable: isAddableColumnLabel,
                  isCommentRequired: isCommentRequiredLabel,
                  isSystemRecord: isSystemRecordColumnLabel
                }}
                columnStyles={{
                  id: (entry, value) => (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', color: 'purple' }}
                      onClick={(e) => handleUpdateClick(entry.item.id)}
                    >
                      <b><u>{entry.item.id}</u></b>
                    </button>
                  ),
                  jsonParameters: (entry, value) => {
                    const requiredParameters = JSON.parse(entry.item["jsonParameters"]);
                    return entry.item["localizedComponent"] === "Decision Type - Automatic" ?
                        <div>
                            {Array.isArray(requiredParameters)  && requiredParameters?.map((rule: any, index: any) => (
                          <div key={index}>
                            <div>
                              Rule Number: {rule.RuleNumber}
                              Base Field: {rule.BaseField}
                              Comparison Operator: {rule.ComparisonOperator}
                              Reference Value: {rule.ReferenceValue}
                            </div>
                          </div>
                        ))}
                      </div> : <></>
                  },
                  isActive: ({ item, value }) => (
                    <TPChip backgroundColor={value ? '#B1F2D7' : '#FFD7DD'} label={value ? yesLabel : noLabel} />
                  ),
                  isDecision: ({ item, value }) => (
                    <TPChip backgroundColor={value ? '#B1F2D7' : '#FFD7DD'} label={value ? yesLabel : noLabel} />
                  ),
                  escalates: ({ item, value }) => (
                    <TPChip backgroundColor={value ? '#B1F2D7' : '#FFD7DD'} label={value ? yesLabel : noLabel} />
                  ),
                  isAddable: ({ item, value }) => (
                    <TPChip backgroundColor={value ? '#B1F2D7' : '#FFD7DD'} label={value ? yesLabel : noLabel} />
                  ),
                  isCommentRequired: ({ item, value }) => (
                    <TPChip backgroundColor={value ? '#B1F2D7' : '#FFD7DD'} label={value ? yesLabel : noLabel} />
                  ),
                  isSystemRecord: ({ item, value }) => (
                    <TPChip backgroundColor={value ? '#B1F2D7' : '#FFD7DD'} label={value ? yesLabel : noLabel} />
                  ),
                }}
                onIconClicked={icon => {
                  if (icon == TPIconTypes.loop || icon == TPIconTypes.refresh) handleRefreshClick();
                }}
                exportNameFile={"Task Type"}
                minorOptions={[
                  {
                    key: deleteLabel,
                    onOptionChange: (e) => handleDeleteClick(e.id),
                    type: 'delete',
                    icon: TPIconTypes.delete
                  },
                  {
                    key: updateLabel,
                    type: "edit",
                    icon: TPIconTypes.edit,
                    onOptionChange: (e: { id: string }) => handleUpdateClick(e.id),
                  },
                ]}
                withPreferences
              />
            </div>
          </div>
          
        </TPLoadingOverlay>
      </>
    );
  },
);

export default TaskTypeAdmin;
