import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModal from "@/layouts/TPModal/TPModal";
import { ActionTypeEnum, ModalSizeEnum, SystemParametersEnum, TPActiveOptions, TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { CalculateTaskResponsibleInputDTO } from "@/models/Task/CalculateTaskResponsibleInputDTO";
import { ReassignTaskInputDTO, ReassignTaskInputDTOValidator } from "@/models/Task/ReassignTaskInputDTO";
import { TaskViewModel } from "@/models/Task/TaskModels";
import { TaskRejectInputDTO, TaskRejectInputDTOValidator } from "@/models/Task/TaskRejectInputDTO";
import { TaskTypeViewModel } from "@/models/TaskType/TaskTypeModels";
import { TPI18N } from "@/services/I18nService";
import { ParametersService } from "@/services/ParametersService";
import { TaskService } from "@/services/TaskService";
import { UserService } from "@/services/UserService";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { FC, useEffect, useState } from "react";

interface TPYesNoTaskInterface {
    currentTask: any;
    currentActionType: ActionTypeEnum;
    actionsTaskCallBack?: Function;
    isShown: boolean;
    closeCallBack: Function;
}

type modalReassignScreenType = {
    newResponsibleGuidUser: Array<TPKeyValue>;
    report: string;
    newResponsibleGuidUserErrorMessage: string;
    reportErrorMessage: string;
    [x: string]: any;
};

type modalRejectScreenType = {
    reasonId: string;
    comments: string;
    reasonIdErrorMessage: string;
    commentsErrorMessage: string;
    [x: string]: any;
};

const ReassignRejectModal: FC<TPYesNoTaskInterface> = ({ currentTask, currentActionType, actionsTaskCallBack, isShown, closeCallBack }) => {

    const componentFileName: string = "ReassignRejectModal.tsx";

    const resourceSetReassignModal: string = "ReassignTaskModal";
    const resourceSetRejectModal: string = "RejectTaskModal";

    const [isLoadingModalScreen, setIsLoadingModalScreen] = useState(true);
    const [isShownActionModal, setIsShownActionModal] = useState(false);

    const [taskTitleModal, setTaskTitleModal] = useState("");
    const [modalAcceptLabel, setModalAcceptLabel] = useState("");
    const [modalCancelLabel, setModalCancelLabel] = useState("");
    const [toLabel, setToLabel] = useState("");
    const [emptyLabel, setEmptyLabel] = useState("");
    const [
        calculateTaskResponsibleButtonLabel,
        setCalculateTaskResponsibleButtonLabel,
    ] = useState("");
    const [commentsLabel, setCommentsLabel] = useState("");
    const [reasonsLabel, setReasonsLabel] = useState("");

    const loadResourcesAndTaskInfo = async () => {
        setCalculateTaskResponsibleButtonLabel(
            await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "CalculateTaskResponsibleButtonLabel",
            ),
        );

        setToLabel(await TPI18N.GetText(resourceSetReassignModal, "ToLabel"));
        setModalAcceptLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
        );
        setModalCancelLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        );
        setCommentsLabel(
            await TPI18N.GetText(resourceSetReassignModal, "CommentsLabel"),
        );
        setReasonsLabel(
            await TPI18N.GetText(resourceSetRejectModal, "ReasonsLabel"),
        );
    };

    const [reasonList, setReasonList] = useState<Array<TPKeyValue>>([]);
    
    const [autocompleteToTopNOptions, setAutocompleteToTopNOptions] = useState<
            Array<TPKeyValue>
        >([]);

    const initialModalReassignScreenState: modalReassignScreenType = {
        newResponsibleGuidUser: [],
        report: "",
        newResponsibleGuidUserErrorMessage: "",
        reportErrorMessage: "",
    };
    const [modalReassignScreenState, setModalReassignScreenState] = useState<modalReassignScreenType>(initialModalReassignScreenState);

    const initialModalRejectScreenState: modalRejectScreenType = {
        reasonId: "",
        comments: "",
        reasonIdErrorMessage: "",
        commentsErrorMessage: "",
    };
    const [modalRejectScreenState, setModalRejectScreenState] = useState<modalRejectScreenType>(initialModalRejectScreenState);

    const [autocompleteToOptions, setAutocompleteToOptions] = useState<Array<TPKeyValue>>([]);

    const handleToChange = (newSelectedValue: Array<TPKeyValue>) => {
        let newmodalReassignScreenState = { ...modalReassignScreenState };
        newmodalReassignScreenState.newResponsibleGuidUser = newSelectedValue;
        newmodalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
        setModalReassignScreenState(newmodalReassignScreenState);
    };

    const handleRejectCommentOnChange = (newValue: string) => {
        let newModalRejectScreenState = { ...modalRejectScreenState };
        newModalRejectScreenState.comments = newValue;
        newModalRejectScreenState.commentsErrorMessage = "";
        setModalRejectScreenState(newModalRejectScreenState);
    };

    const handleOnReasonIdChange = (e: any) => {
        let newModalRejectScreenState = { ...modalRejectScreenState };
        newModalRejectScreenState.reasonId = e.target.value;
        newModalRejectScreenState.reasonIdErrorMessage = "";
        setModalRejectScreenState(newModalRejectScreenState);
    };

    const handleReassignsCommentOnChange = (newValue: string) => {
        let newModalReassignScreenState = { ...modalReassignScreenState };
        newModalReassignScreenState.report = newValue;
        newModalReassignScreenState.reportErrorMessage = "";
        setModalReassignScreenState(newModalReassignScreenState);
    };

    const reassignTask = async (
        inputDTO: ReassignTaskInputDTO,
    ): Promise<boolean> => {
        let serviceClient = new TaskService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoadingModalScreen(true);

            let responseRequest = await serviceClient.reassignTaskResponsible(
                inputDTO,
                true,
                true,
                expectedCodes,
            );
            setIsLoadingModalScreen(false);
            if (responseRequest.responseResult) {
                return true;
            }
            return true;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} reassignTask ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} reassignTask ex`);
            setIsLoadingModalScreen(false);
            return false;
        }
    };

    const rejectTask = async (
        inputDTO: TaskRejectInputDTO,
    ): Promise<boolean> => {
        let serviceClient = new TaskService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoadingModalScreen(true);

            let responseRequest = await serviceClient.rejectTask(
                inputDTO,
                true,
                true,
                expectedCodes,
            );
            setIsLoadingModalScreen(false);
            if (responseRequest.responseResult) {
                return true;
            }
            return false;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} rejectTask ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} rejectTask ex`);
            setIsLoadingModalScreen(false);
            return false;
        }
    };

    const handleCallbackAnserModal = async (confirm: boolean, data: any) => {
        if (confirm) {
            switch (currentActionType) {
                case ActionTypeEnum.Reassign:
                    let recordInputDTO: ReassignTaskInputDTO = {
                        taskId: currentTask.id,
                        newResponsibleGuidUser:
                            modalReassignScreenState.newResponsibleGuidUser.length > 0
                                ? modalReassignScreenState.newResponsibleGuidUser[0].key
                                : "",
                        report: modalReassignScreenState.report,
                    };
                    let inputDTOValidator = new ReassignTaskInputDTOValidator();
                    let resultValidator = inputDTOValidator.validate(recordInputDTO);
                    if (!TPGlobal.TPIsEmpty(resultValidator)) {
                        let newModalReassignScreenState = { ...modalReassignScreenState };
                        var listPropertyNames = Object.keys(resultValidator);
                        if (listPropertyNames) {
                            for (let index = 0; index < listPropertyNames.length; index++) {
                                const element = listPropertyNames[index];
                                if (resultValidator[element]) {
                                    newModalReassignScreenState[element + "ErrorMessage"] =
                                        await TPI18N.GetResource(
                                            resultValidator[element] as string,
                                        );
                                } else {
                                    newModalReassignScreenState[element + "ErrorMessage"] = "";
                                }
                            }
                        }
                        setModalReassignScreenState(newModalReassignScreenState);
                        return;
                    }

                    if (await reassignTask(recordInputDTO)) {
                        if (actionsTaskCallBack) {
                            actionsTaskCallBack(currentActionType);
                        }
                    }
                    break;
                case ActionTypeEnum.Reject:
                    let recordInputRejectTaskDTO: TaskRejectInputDTO = {
                        id: currentTask.id,
                        reasonId: modalRejectScreenState.reasonId,
                        comments: modalRejectScreenState.comments,
                        typistGuidUser: TPGlobal.currentUserGuid,
                    };
                    let inputDTORejectTaskValidator = new TaskRejectInputDTOValidator();
                    let resultValidatorRejectTask =
                        inputDTORejectTaskValidator.validate(recordInputRejectTaskDTO);
                    if (!TPGlobal.TPIsEmpty(resultValidatorRejectTask)) {
                        let newModalRejectScreenState = { ...modalRejectScreenState };
                        var listPropertyNamesRejectTask = Object.keys(
                            resultValidatorRejectTask,
                        );
                        if (listPropertyNamesRejectTask) {
                            for (
                                let index = 0;
                                index < listPropertyNamesRejectTask.length;
                                index++
                            ) {
                                const element = listPropertyNamesRejectTask[index];
                                if (resultValidatorRejectTask[element]) {
                                    newModalRejectScreenState[element + "ErrorMessage"] =
                                        await TPI18N.GetResource(
                                            resultValidatorRejectTask[element] as string,
                                        );
                                } else {
                                    newModalRejectScreenState[element + "ErrorMessage"] = "";
                                }
                            }
                        }
                        setModalRejectScreenState(newModalRejectScreenState);
                        return;
                    }

                    if (await rejectTask(recordInputRejectTaskDTO)) {
                        if (actionsTaskCallBack) {
                            actionsTaskCallBack(currentActionType);
                        }
                    }
                    break;
            }
        }
        setIsShownActionModal(false);
        setModalReassignScreenState(initialModalReassignScreenState);
        setModalRejectScreenState(initialModalRejectScreenState);
    };

    const handleToOnAutocompleteQuery = async (query: string) => {
        let tasktypeService = new UserService();
        let expectedCodes: Array<number> = [200, 404];

        try {
            //Load users by search
            let responseRequest =
                await tasktypeService.getActiveUsersBySearchParameter(
                    query,
                    false,
                    true,
                    expectedCodes,
                );
            let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
                function (item) {
                    return {
                        key: item.userGuid,
                        value: `${item.name}`,
                    };
                },
            );
            setAutocompleteToOptions(newToKeyValueList);
            return newToKeyValueList;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(
                `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
            );
            return [];
        }
    };

    const handleToOnAutocompleteKeyDown = (event: any) => {
        //left arrow 37
        //right arror 39
        //enter 13
        //home 36
        //end  35
        // if (
        //   event.keyCode != 37 &&
        //   event.keyCode != 39 &&
        //   event.keyCode != 13 &&
        //   event.keyCode != 35 &&
        //   event.keyCode != 36
        // ) {
        //   setAutocompleteToOptions([]);
        //   let newModalReassignScreenState = { ...modalReassignScreenState };
        //   newModalReassignScreenState.newResponsibleGuidUser = [];
        //   newModalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
        //   setModalReassignScreenState(newModalReassignScreenState);
        // }
        const inputValue = event.target.value;
        if (inputValue.length === 1) {
            handleToOnAutocompleteQuery("");
        }
    };

    const handleAutoCompleteTopNClick = async () => {
        let newTopNOptions: Array<TPKeyValue> = [];
        if (autocompleteToTopNOptions.length === 0) {
            newTopNOptions = await handleToOnAutocompleteQuery("");

            if (newTopNOptions.length >= 1) {
                //save on cache
                setAutocompleteToTopNOptions([...newTopNOptions]);
                setAutocompleteToOptions([...newTopNOptions]);
                let newModalReassignScreenState = { ...modalReassignScreenState };
                newModalReassignScreenState.newResponsibleGuidUser = [];
                newModalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
                setModalReassignScreenState(newModalReassignScreenState);
            }
        } else {
            //use cached values;
            setAutocompleteToOptions([...autocompleteToTopNOptions]);
            let newModalReassignScreenState = { ...modalReassignScreenState };
            newModalReassignScreenState.newResponsibleGuidUser = [];
            newModalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
            setModalReassignScreenState(newModalReassignScreenState);
        }
    };

    const handleCalculateTaskResponsible = async () => {
        let inputDTO: CalculateTaskResponsibleInputDTO = {
            taskId: currentTask.id,
            typistGuidUser: TPGlobal.currentUserGuid,
        };

        let serviceClient = new TaskService();
        let expectedCodes: Array<number> = [200];

        setIsLoadingModalScreen(true);
        try {
            let responseRequest = await serviceClient.CalculateTaskResponsible(
                inputDTO,
                false,
                true,
                expectedCodes,
            );

            if (responseRequest && responseRequest.length >= 1) {
                let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
                    function (item) {
                        return {
                            key: item.userGuid,
                            value: `${item.firstName} ${item.lastName}`,
                        };
                    },
                );

                let newmodalReassignScreenState = { ...modalReassignScreenState };
                newmodalReassignScreenState.newResponsibleGuidUser =
                    newToKeyValueList;
                newmodalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
                setModalReassignScreenState(newmodalReassignScreenState);
            }

            setIsLoadingModalScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} handleCalculateTaskResponsible ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(
                `Error ${componentFileName} handleCalculateTaskResponsible ex`,
            );
            setIsLoadingModalScreen(false);
        }
    };

    const getReasonsDatalist = async () => {
        let parametersService = new ParametersService();
        let expectedCodes: Array<number> = [200];

        try {
            let responseRequest =
                await parametersService.getByParentIdAndFilterIsActive(
                    SystemParametersEnum.REFORETA,
                    TPActiveOptions.ACTIVE.toString(),
                    false,
                    true,
                    expectedCodes,
                );
            if (responseRequest) {
                let newReasonList: Array<TPKeyValue> = responseRequest.map(
                    function (item) {
                        return { key: item.id, value: item.localizedDescription };
                    },
                );
                newReasonList.unshift({ key: "", value: "--" });
                setReasonList(newReasonList);
            } else {
                //todo logs
                return null;
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getReasonsDatalist ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getReasonsDatalist ex`);
            return null;
        }
    };

    const handleOnActionClick = async (actionName: ActionTypeEnum) => {
        setIsShownActionModal(true);
        switch (actionName) {
            case ActionTypeEnum.Reassign:
                setTaskTitleModal(
                    await TPI18N.GetText(
                        resourceSetReassignModal,
                        "ReassignTaskTitleModal",
                    ),
                );
                setIsLoadingModalScreen(false);
                break;
            case ActionTypeEnum.Reject:
                setTaskTitleModal(
                    await TPI18N.GetText(
                        resourceSetRejectModal,
                        "RejectTaskTitleModal",
                    ),
                );
                getReasonsDatalist();
                setIsLoadingModalScreen(false);
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        loadResourcesAndTaskInfo();
    }, []);

    useEffect(() => {
        if (isShown) {
            handleOnActionClick(currentActionType);
        }
    }, [isShown]);

    useEffect(() => {
        if (!isShownActionModal) {
            closeCallBack();
        }
    }, [isShownActionModal]);

    return (
        <>
        <TPModal
                    modalState={{
                        titleModal: `${taskTitleModal} - ${currentTask?.taskTypeLocalizedDescription}`,
                        acceptLabel: modalAcceptLabel,
                        cancelLabel: modalCancelLabel,
                        callBackAnswer: handleCallbackAnserModal,
                        callBackData: null,
                        isShown: isShownActionModal,
                        modalWidth: ModalSizeEnum.MODALLG,
                    }}
                >
                    <TPLoadingOverlay active={isLoadingModalScreen}>
                        {currentActionType === ActionTypeEnum.Reassign && (
                            <>
                                <div className="row">
                                    <div className="col-8">
                                        <TPAutoComplete
                                            isMandatory={true}
                                            labelText={toLabel}
                                            onValueChange={handleToChange}
                                            onSearch={(query: string) => {
                                                handleToOnAutocompleteQuery(query);
                                            }}
                                            isLoading={false}
                                            options={autocompleteToOptions}
                                            withIcon={true}
                                            emptyLabel={emptyLabel}
                                            onKeyDown={handleToOnAutocompleteKeyDown}
                                            selected={modalReassignScreenState.newResponsibleGuidUser}
                                            errorMessage={
                                                modalReassignScreenState.newResponsibleGuidUserErrorMessage
                                            }
                                            downArrowClick={handleAutoCompleteTopNClick}
                                        ></TPAutoComplete>
                                    </div>
                                    <div className="col-4 mt-4">
                                        <TPButton
                                            type={TPButtonTypes.primary}
                                            onClick={handleCalculateTaskResponsible}
                                        >
                                            {calculateTaskResponsibleButtonLabel}
                                        </TPButton>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <TPTextArea
                                            id="IdTextArea"
                                            labelText={commentsLabel}
                                            isMandatory={true}
                                            onChange={(e: any) =>
                                                handleReassignsCommentOnChange(e.target.value)
                                            }
                                            value={modalReassignScreenState.report}
                                            rows={7}
                                            errorMessage={modalReassignScreenState.reportErrorMessage}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {currentActionType === ActionTypeEnum.Reject && (
                            <>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <TPSelect
                                                id="IdSelect"
                                                isMandatory={true}
                                                labelText={reasonsLabel}
                                                onChange={handleOnReasonIdChange}
                                                dataSource={reasonList}
                                                value={modalRejectScreenState.reasonId}
                                                errorMessage={
                                                    modalRejectScreenState.reasonIdErrorMessage
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <TPTextArea
                                            id="IdTextArea"
                                            labelText={commentsLabel}
                                            isMandatory={true}
                                            onChange={(e: any) =>
                                                handleRejectCommentOnChange(e.target.value)
                                            }
                                            value={modalRejectScreenState.comments}
                                            rows={7}
                                            errorMessage={modalRejectScreenState.commentsErrorMessage}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </TPLoadingOverlay>
                </TPModal>
        </>
    )
}

export default ReassignRejectModal;