import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable, { CustomColumnHeaderElements, HeaderAlignment, PreferencesLabels } from "@/modules/core/components/dynamic-table/DynamicTable";
import { FC, ReactNode, useEffect, useState } from "react";
import { useCaseToolbarLabels } from "./Assets/CaseToolbarLabels";
import { CaseToolbarItem, CaseToolbarListItem } from "@/models/CaseToolbar/CaseToolbarModel";
import { CaseToolbarService } from "@/services/CaseToolbarService";
import { ProfileService } from "@/services/ProfileService";
import { useDispatch } from "react-redux";
import { CaseToolbarSlice, CaseToolbarSliceModel } from "./Assets/CaseToolbarSlice";
import { ColumnStyles, CustomColumnNames } from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPChip } from "@/components/TPChip/TPChip";
import allThemes from "@/assets/styles/theme";
import TPGlobal from "@/helpers/TPGlobal";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import { CaseToolbarItemReorderModal } from "./CaseToolbarItemReorderModal";
import { TPConfirmationModal } from "@/components/TPModalAlert/ConfirmationModal/TPConfirmationModal";

interface CaseToolbarProperties {
  verticalTabCallback: Function;
}

export const CaseToolbarHome: FC<CaseToolbarProperties> = function({ verticalTabCallback }) {

  const dispatch = useDispatch();
  const { labels } = useCaseToolbarLabels();
  const { contentLoaded, availableProfiles } = useSelector((s: StoreModel) => s[CaseToolbarSlice.name]) as CaseToolbarSliceModel;
  const [toolbarItems, setToolbarItems] = useState<Array<CaseToolbarItem>>([]);
  const [toolbarItemList, setToolbarItemList] = useState<Array<CaseToolbarListItem>>([]);
  const [loading, setLoading] = useState(false);
  const [profilesLoading, setProfilesLoading] = useState(false);
  const [chosenProfile, setChosenProfile] = useState("");
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);

  const addNewToolbar = function(itemId?: string, itemName?: string) {
    itemId ? 
      verticalTabCallback({
        command: 'update',
        recordId: itemId,
        recordDescription: itemName || "",
        languageId: TPGlobal.language
      })
      :
      verticalTabCallback({ command: 'new', payload: { recordId: '--', subTitle: labels.Item } });
  }

  const getAvailableProfiles = async function() {
    setProfilesLoading(true);
    const profileServiceInstance = new ProfileService();
    profileServiceInstance.getProfilesByFilter("1", false, true, [200, 404])
    .then(profiles => {
      if (profiles)
        dispatch(CaseToolbarSlice.actions.setAvailableProfiles(
          profiles.map(p => ({ key: p.id, value: p.localizedDescription || p.description || '???' }))))
    })
    .catch(err => console.error(err))
    .finally(() => setProfilesLoading(false))
  }

  const getBooleanChip = function (isTrue: boolean): ReactNode {
    return (
      <TPChip
        backgroundColor={isTrue ? allThemes.base.trueGreen : allThemes.base.falseRed}
        label={isTrue ? labels.Yes : labels.No}
      />
    )
  }

  const getIdLinkStyle = function (actualId: string, displayValue: string) {
    return (
      <button
        type="button"
        className="case-toolbar-link-button"
        onClick={() => addNewToolbar(actualId, displayValue)}
      >
        <u>{displayValue}</u>
      </button>
    );
  }

  const transformItems = function(items: Array<CaseToolbarItem>): Array<CaseToolbarListItem> {
    if (!items) return [];
    const newItems: Array<CaseToolbarListItem> = [];
    items.forEach(i => {
      const newIt: CaseToolbarListItem = {
        ...i,
        localizedDescription: i.localizedDescription || i.description || '???'
      }
      availableProfiles.forEach(p => {
        newIt[p.key] = Boolean(i.profiles.find(itemProf => itemProf.id_PROF === p.key));
      })
      newItems.push(newIt);
    })
    return newItems;
  }

  const getColumnNames = function(): CustomColumnNames<CaseToolbarListItem> {
    const mainObj: CustomColumnNames<CaseToolbarListItem> = {
      localizedDescription: labels.Item
    }
    availableProfiles.forEach(p => {
      mainObj[p.key] = ""
    })
    return mainObj;
  }

  const getPreferencesLabels = function(): PreferencesLabels<CaseToolbarListItem> {
    const mainObj: PreferencesLabels<CaseToolbarListItem> = {
      localizedDescription: labels.Item
    }
    availableProfiles.forEach(p => {
      mainObj[p.key] = p.value
    })
    return mainObj;
  }

  const getHeaderButtons = function(): CustomColumnHeaderElements<CaseToolbarListItem> {
    const mainObj: CustomColumnHeaderElements<CaseToolbarListItem> = {}
    availableProfiles.forEach(p => {
      mainObj[p.key] = (
        <button
          type="button"
          className="case-toolbar-link-button"
          onClick={(e) => {
            e.stopPropagation();
            setChosenProfile(p.key)
          }}
        >
          <u>{p.value}</u>
        </button>
      )
    })
    return mainObj;
  }

  const applyRowStyles = function (): ColumnStyles<CaseToolbarListItem> {
    const newRow: ColumnStyles<CaseToolbarListItem> = {
      localizedDescription: (row) => (<>{getIdLinkStyle(row.item.id, row.item.localizedDescription)}</>),
    };
    availableProfiles.forEach(p => newRow[p.key] = (row) => (
      <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {getBooleanChip(row.item[p.key] || false)}
      </div>
    ))
    return newRow;
  }

  const getHeaderAlignments = function(): HeaderAlignment<CaseToolbarListItem> {
    const headersAlignments: HeaderAlignment<CaseToolbarListItem> = {};
    availableProfiles.forEach(p => headersAlignments[p.key] = "center");
    return headersAlignments;
  }

  const getAllToolbarItems = async function() {
    setLoading(true);
    CaseToolbarService.getAllItems(false, true, [200, 404])
    .then(items => setToolbarItems(items))
    .catch(err => console.error(err))
    .finally(() => setLoading(false));
  }

  const deleteItem = async function(itemId: string) {
    setLoading(true);
    setItemToDelete(null);
    CaseToolbarService.deleteItem(itemId, true, true, [200, 404])
      .then(items => dispatch(CaseToolbarSlice.actions.setContentLoaded(false)))
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
    verticalTabCallback({
      command: 'delete',
      recordId: itemId
    })
  }

  useEffect(() => {
    setToolbarItemList(transformItems(toolbarItems))
  }, [toolbarItems, availableProfiles])

  const loadData = function() {
    getAvailableProfiles();
    getAllToolbarItems();
    dispatch(CaseToolbarSlice.actions.setContentLoaded(true));
  }

  useEffect(() => {
    if (!contentLoaded) loadData();
  }, [contentLoaded])

  useEffect(() => {
    loadData();
  }, [])
  return (
    <>
      <TPConfirmationModal
        message={labels.DeleteConfirm}
        onAccept={() => itemToDelete && deleteItem(itemToDelete)}
        onCancel={() => setItemToDelete(null)}
        visible={itemToDelete !== null}
      />
      {chosenProfile && (
        <CaseToolbarItemReorderModal 
          items={toolbarItems}
          onClose={() => setChosenProfile("")} 
          profileId={chosenProfile} 
        />)}
      <TPLoadingOverlay active={loading || profilesLoading}>
        <div className="row">
          <div className="col">
            <CIMTitleSection style={{overflow:"visible"}}>
              <TPPageTitle style={{ margin: "0" }}>{labels.CaseToolbar}</TPPageTitle>
              <TPButton
                id="new-case-toolbar-button"
                style={{ padding: "1px 16px", gap: "8px" }}
                onClick={() => addNewToolbar()}
                isDesignSystem
              >
                <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <TPIcon iconType={TPIconTypes.add} />
                  {labels.NewItem}
                </div>
              </TPButton>
            </CIMTitleSection>
            <DynamicTable
              id="case-toolbar"
              data={toolbarItemList}
              hiddenColumns={["id","image","isActive","description","profiles",
                "type","urlLink","localizedDescriptions","order"]}
              minorOptions={[
                {
                  key: labels.Edit,
                  onOptionChange: row => verticalTabCallback({ 
                    command: 'update',
                    recordId: row.id, 
                    languageId: TPGlobal.language,
                    recordDescription: row.localizedDescription
                  }),
                  type: 'edit',
                  icon: TPIconTypes.edit
                },
                {
                  key: labels.Delete,
                  onOptionChange: row => setItemToDelete(row.id),
                  type: 'delete',
                  icon: TPIconTypes.delete
                },
              ]}
              onIconClicked={i => {
                if (i === TPIconTypes.refresh || i === TPIconTypes.loop)
                  getAllToolbarItems();
              }}
              hideExport
              withPreferences
              columnStyles={{...applyRowStyles()}}
              columnNames={{...getColumnNames()}}
              customHeaderElements={{...getHeaderButtons()}}
              preferencesLabels={{...getPreferencesLabels()}}
              headerAlignment={{ ...getHeaderAlignments() }}
            />
          </div>
        </div> 
      </TPLoadingOverlay>
    </>
  )
}