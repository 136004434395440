import { useState, useCallback, useEffect } from "react";
import { TPI18N } from "@/services/I18nService";
import { TPLog, TPLogType } from "@/helpers/TPLog";

interface Translations {
    [key: string]: string;
}

const useTranslations = (initialTexts: Translations, resourceSet: string, componentName: string) => {
    const [profileTexts, setProfileTexts] = useState<Translations>(initialTexts);

    const loadTranslations = useCallback(async () => {
        try {
            const translatedTexts = Object.fromEntries(
                await Promise.all(
                    Object.entries(initialTexts).map(async ([key, translationKey]) => {
                        try {
                            const translation = await TPI18N.GetText(
                                resourceSet,
                                translationKey
                            );
                            return [key, translation || translationKey];
                        } catch (error) {
                            TPLog.Log(
                                `Error ${componentName} getTranslation for key: ${translationKey}`,
                                TPLogType.ERROR,
                                error
                            );
                            return [key, translationKey];
                        }
                    })
                )
            );
            setProfileTexts(translatedTexts);
        } catch (error) {
            TPLog.Log(
                `Error ${componentName} loadTranslations`,
                TPLogType.ERROR,
                error
            );
        }
    }, [initialTexts, resourceSet, componentName]);

    useEffect(() => {
        loadTranslations();
    }, []);

    return profileTexts;
};

export default useTranslations;
