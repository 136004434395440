import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { generateId } from "@/helpers/IdGenerator";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { areEqual } from "@/helpers/ValidateChanges";
import TPModal from "@/layouts/TPModal/TPModal";
import {
  SequenceGeneratorSequencesNameEnum,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import {
  LocalizedScriptName,
  ScriptHeader,
  ScriptHeaderRequestModel,
  ScriptsMode,
} from "@/models/Scripts/ScriptModel";
import { StoreModel } from "@/redux/store";
import { ScriptsService } from "@/services/Scripts/ScriptsService";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  ScriptsManagementSlice,
  ScriptsManagementSliceModel,
} from "../../ScriptsManagementSlice";
import { useScriptsLabels } from "../../Assets/labelling";

interface ScriptGeneralInfoTab {
  mode: ScriptsMode;
  verticalTabUpdateCallback: (scriptId: string, scriptDesc?: string) => void;
  visible: boolean;
  scriptId: string;
  scriptName?: string;
  onNameInput?: (name: string) => void;
}

interface ScriptGeneralInfoFormHandlerModel {
  id: (newId: string) => void;
  name: (newName: string) => void;
  isActive: (newStatus: boolean) => void;
  localizedName: (newName: string, language: string) => void;
  language: (code: string) => void;
}

const blankScriptHeader: ScriptHeader = {
  id: "",
  isActive: true,
  isFavorite: false,
  language: {
    id: "",
    name: "",
  },
  localizedName: [
    {
      languageId: "en",
      localizedValue: "",
      order: null,
    },
  ],
  name: "",
};

const formToRequest = function (
  header: ScriptHeader
): ScriptHeaderRequestModel {
  return {
    id: header.id,
    isActive: header.isActive,
    isFavorite: header.isFavorite,
    languageId: header.language.id,
    localizedNames: header.localizedName,
    name: header.name,
  };
};

export const ScriptGeneralInfoTab: FC<ScriptGeneralInfoTab> = function ({
  mode,
  visible,
  scriptId,
  scriptName,
  onNameInput,
  verticalTabUpdateCallback,
}) {
  const dispatch = useDispatch();
  const { labels } = useScriptsLabels();
  const [formInitialState, setFormInitialState] = useState<ScriptHeader>(
    structuredClone(blankScriptHeader)
  );
  const [currentForm, setCurrentForm] = useState<ScriptHeader>(
    structuredClone(blankScriptHeader)
  );
  const [localizedNamesModalForm, setLocalizedNamesModalForm] = useState<
    LocalizedScriptName[]
  >([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [languageOptions, setLanguageOptions] = useState<TPKeyValue[]>(
    TPGlobal.TPClientAvailableLanguages.map((lang) => {
      return { key: lang.id, value: lang.name };
    })
  );

  const formHandler: ScriptGeneralInfoFormHandlerModel = {
    id: (value) => {
      setCurrentForm((oldForm) => {
        return {
          ...oldForm,
          id: value,
        };
      });
    },
    isActive: (value) => {
      setCurrentForm((oldForm) => {
        return {
          ...oldForm,
          isActive: value,
        };
      });
    },
    language: (code: string) => {
      setCurrentForm((oldForm) => {
        return {
          ...oldForm,
          language: {
            id: code,
            name: languageOptions.find((lang) => lang.key == code)?.value || "",
          },
        };
      });
    },
    localizedName: (value, language) => {
      let localizedNames = [...localizedNamesModalForm];
      const targetLocalizedName = localizedNames.find(
        (name) => name.languageId == language
      );
      if (targetLocalizedName) {
        localizedNames = localizedNames.map((name) => {
          return name.languageId == language
            ? ({
                ...targetLocalizedName,
                localizedValue: value,
              } as LocalizedScriptName)
            : name;
        });
        if (value.trim().length == 0)
          localizedNames = localizedNames.filter(
            (name) => name.languageId != language
          );
      } else {
        if (value.trim().length > 0) {
          localizedNames.push({
            languageId: language,
            localizedValue: value,
            order: null,
          });
        }
      }
      setLocalizedNamesModalForm([...localizedNames]);
    },
    name: (value) => {
      setCurrentForm((oldForm) => {
        return {
          ...oldForm,
          name: value,
          localizedName: oldForm.localizedName.map((name) => {
            return name.languageId == TPGlobal.language
              ? ({
                  ...name,
                  localizedValue: value,
                } as LocalizedScriptName)
              : name;
          }),
        };
      });
      formHandler.localizedName(value, TPGlobal.language);
    },
  };

  const createScript = function () {
    setContentLoading(true);
    ScriptsService.createScript(formToRequest(currentForm), true, true, [200])
      .then(() => {
        dispatch(ScriptsManagementSlice.actions.setScriptsLoaded(false));
        verticalTabUpdateCallback(currentForm.id, currentForm.name);
      })
      .catch((err) => console.error(err))
      .finally(() => setContentLoading(false));
  };

  const updateScript = function () {
    setContentLoading(true);
    ScriptsService.updateScript(formToRequest(currentForm), true, true, [200])
      .then(() => {
        dispatch(ScriptsManagementSlice.actions.setScriptsLoaded(false));
      })
      .catch((err) => console.error(err))
      .finally(() => setContentLoading(false));
  };

  const cloneScript = function () {
    setContentLoading(true);
    ScriptsService.cloneScript(
      {
        scriptId_Base: scriptId,
        id: currentForm.id,
        languageId: currentForm.language.id,
        localizedNames: currentForm.localizedName,
        name: currentForm.name,
      },
      true,
      true,
      [200]
    )
      .then((response: any) => {
        if (response.responseCode == 200) {
          dispatch(ScriptsManagementSlice.actions.setScriptsLoaded(false));
          verticalTabUpdateCallback(currentForm.id, currentForm.name);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setContentLoading(false));
  };

  useEffect(() => {
    if (mode == ScriptsMode.new || mode == ScriptsMode.clone) {
      setContentLoading(true);
      generateId(SequenceGeneratorSequencesNameEnum.SQSCRI)
        .then((id) => {
          if (id) {
            formHandler.id(id);
            formHandler.language(TPGlobal.language);
            formHandler.localizedName("", TPGlobal.language);
            formHandler.name(scriptName || "");
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setContentLoading(false));
    }
    if (scriptId && mode == ScriptsMode.update) {
      setContentLoading(true);
      ScriptsService.getScriptById(scriptId, false, true, [200])
        .then((script) => {
          if (!languageOptions.find((lang) => lang.key == script.language.id)) {
            setLanguageOptions([
              ...languageOptions,
              { key: script.language.id, value: script.language.name },
            ]);
          }
          setFormInitialState({ ...script });
          setCurrentForm({ ...script });
          setLocalizedNamesModalForm([...script.localizedName]);
        })
        .catch((err) => console.error(err))
        .finally(() => setContentLoading(false));
    }
    if (scriptId && mode == ScriptsMode.clone) {
      setContentLoading(true);
      ScriptsService.getScriptById(scriptId, false, true, [200])
        .then((script) => {
          if (!languageOptions.find((lang) => lang.key == script.language.id)) {
            setLanguageOptions([
              ...languageOptions,
              { key: script.language.id, value: script.language.name },
            ]);
          }
          setFormInitialState({ ...script });
          setCurrentForm({ ...script });
          setLocalizedNamesModalForm([...script.localizedName]);
          formHandler.name(script.name);
          generateId(SequenceGeneratorSequencesNameEnum.SQSCRI)
            .then((id) => {
              if (id) {
                formHandler.id(id);
              }
            })
            .catch((err) => console.error(err))
            .finally(() => setContentLoading(false));
        })
        .catch((err) => console.error(err))
        .finally(() => setContentLoading(false));
    }
  }, []);

  useEffect(() => {
    onNameInput && onNameInput(currentForm.name.trim());
    setChangesMade(!areEqual(formInitialState, currentForm));
  }, [currentForm]);

  return visible ? (
    <>
      <TPModal
        children={
          <>
            <div className="localized-script-names-modal">
              {TPGlobal.TPClientAvailableLanguages.filter(
                (lang) => lang.id != TPGlobal.language
              ).map((lang) => (
                <TPTextBox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    formHandler.localizedName(e.target.value, lang.id)
                  }
                  value={
                    localizedNamesModalForm.find(
                      (name) => name.languageId == lang.id
                    )?.localizedValue || ""
                  }
                  labelText={`${labels.Name} (${lang.name})`}
                />
              ))}
            </div>
          </>
        }
        modalState={{
          acceptLabel: labels.Ok,
          callBackAnswer: (saved: boolean) => {
            setModalVisible(false);
            if (saved) {
              setCurrentForm({
                ...currentForm,
                localizedName: structuredClone(localizedNamesModalForm),
              });
            } else {
              setLocalizedNamesModalForm([...currentForm.localizedName]);
            }
          },
          isShown: modalVisible,
          titleModal: labels.ScriptLocalizedNames,
          cancelLabel: labels.Cancel,
        }}
      />
      <TPLoadingOverlay active={contentLoading}>
        <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
          <div
            id="script-management-general-info-tab"
            className="script-tab"
            style={{ width: "60%" }}
          >
            {/* <div className="script-form-row">
              <div style={{ width: "100%" }}>
                <TPTextBox
                  onChange={() => {}}
                  value={currentForm.id}
                  labelText={labels.Id}
                  isMandatory
                  disabled
                />
              </div>
              <div style={{ width: "100%" }}>
                <TPTextBox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    formHandler.name(String(e.target.value))
                  }
                  value={currentForm.name}
                  labelText={labels.Name}
                  isMandatory
                  placeholder={labels.NameExample}
                />
              </div>
            </div> */}

            <div className="container p-0">
              <div className="row">
                <div className="col-6">
                  <TPTextBox
                    onChange={() => {}}
                    value={currentForm.id}
                    labelText={labels.Id}
                    isMandatory
                    disabled
                  />
                </div>
                <div className="col-6">
                  <TPTextBox
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      formHandler.name(String(e.target.value))
                    }
                    value={currentForm.name}
                    labelText={labels.Name}
                    isMandatory
                    placeholder={labels.NameExample}
                  />
                </div>
              </div>
            </div>

            <div className="script-form-row">
              <div style={{ width: "100%" }}>
                <TPSelect
                  dataSource={languageOptions}
                  isMandatory
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    formHandler.language(e.target.value)
                  }
                  value={currentForm.language.id}
                  labelText={labels.Language}
                />
              </div>
            </div>
            <div
              className="script-form-row"
              style={{ justifyContent: "space-between" }}
            >
              <TPCheckBox
                checked={currentForm.isActive}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  formHandler.isActive(e.target.checked)
                }
                labelText={labels.ShowAsActive}
              />
              <TPButton
                onClick={() => {
                  if (mode == ScriptsMode.new) createScript();
                  if (mode == ScriptsMode.update) updateScript();
                  if (mode == ScriptsMode.clone) cloneScript();
                }}
                isDesignSystem
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                disabled={
                  !changesMade ||
                  currentForm.id.trim().length == 0 ||
                  currentForm.name.trim().length == 0
                }
              >
                {labels.SaveHeader}
              </TPButton>
            </div>
          </div>
          <button
            type="button"
            style={{
              border: "none",
              background: "none",
              display: "flex",
              alignItems: "center",
              gap: "4px",
              height: "32px",
              marginTop: "25px",
            }}
            onClick={() => setModalVisible(true)}
          >
            <TPIcon
              iconType={TPIconTypes.language}
              style={{ fontSize: "24px" }}
            />
            +{TPGlobal.TPClientAvailableLanguages.length - 1}
          </button>
        </div>
      </TPLoadingOverlay>
    </>
  ) : (
    <></>
  );
};
