import React from "react";
import { TPDescriptionText } from "./TPDescriptionStyles";

interface TPDescriptionProps {
  text?: string | React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const TPDescription: React.FC<TPDescriptionProps> = ({
  text,
  children,
  className,
  style,
}) => {
  return (
    <TPDescriptionText className={className} style={style}>
      {text !== undefined
        ? typeof text === "string" && text.includes("<") && text.includes(">")
          ? <span dangerouslySetInnerHTML={{ __html: text }} />
          : text
        : children}
    </TPDescriptionText>
  );
};

export default TPDescription;
