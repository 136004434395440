import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
} from "react";
import TPTextBox from "../textbox/TPTextBox";
import TPButton from "../../components/buttons/TPButton";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPGlobal from "@/helpers/TPGlobal";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { styles } from "./MultilingualTextBoxStyle";
import {
  LanguageGroupValues,
  LanguageModel,
  MultilingualTextBoxAction,
  MultilingualTextBoxActionType,
  MultilingualTextBoxEvents,
  multilingualTextBoxInitialState,
  MultilingualTextBoxMessages,
  MultilingualTextBoxProps,
  MultilingualTextBoxState,
  MultilingualTextBoxTypes,
} from "./MultilingualTextBoxModel";
import { TPI18N } from "@/services/I18nService";

/**
 * multilingual text box component
 */
const MultilingualTextBox = forwardRef(
  (
    {
      id = "MultilingualTextBox",
      key = "MultilingualTextBox",
      style = {} as CSSProperties,
      children,
      labels = {} as MultilingualTextBoxMessages,
      value,
      maxLength = 100,
      type = MultilingualTextBoxTypes.MODULE,
      requiredLanguages = [],
      onChangeLanguages,
      onChange,
      optional
    }: MultilingualTextBoxProps,
    ref
  ) => {
    /**
     * ATTRIBUTES
     */
    /**
     * primary language
     */
    const TpLanguage =
        type === MultilingualTextBoxTypes.MODULE
            ? TPGlobal.TPClientDefaultLanguage
            : TPGlobal.language;
    /**
     * list of available languages
     */
    const TpLanguages = TPGlobal.TPClientAvailableLanguages.map(
        ({id, name}) => ({id, value: name})
    );
    const TpMainLanguage = TPGlobal.TPClientDefaultLanguage
    const TpRequiredLanguages = [
      TpLanguage,
      ...requiredLanguages.filter((language) => language !== TpLanguage),
    ];
    /**
     * Modal resources
     */
    const { isOpen, openModal, closeModal, saveChanges } = useModal(false);
    /**
     * state of the value at the component
     */
    const [state, dispatch] = useReducer(
      command,
      multilingualTextBoxInitialState
    );
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(
      ref,
      () =>
        ({
          getValue() {
            return getValue();
          },
          setValue(valueSend: Array<LanguageModel>) {
            dispatch({
              type: MultilingualTextBoxActionType.setValues,
              payload: valueSend,
            });
          },
          isInvalid() {
            return isInvalid();
          },
          markAsTouched() {
            dispatch({ type: MultilingualTextBoxActionType.markAsTouched });
          },
        }) as MultilingualTextBoxEvents
    );
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
      loadResources();
      if (value) {
        dispatch({
          type: MultilingualTextBoxActionType.setValues,
          payload: value,
        });
      } else {
        dispatch({ type: MultilingualTextBoxActionType.setValues });
      }
    }, []);
    /**
     * event on component close
     */
    useEffect(
      () => () => {
        dispatch({ type: MultilingualTextBoxActionType.clean });
      },
      []
    );
    /**
     *
     */
    useEffect(
        () => {
          dispatch({
            type: MultilingualTextBoxActionType.setValues,
            payload: value,
          });
        },
        [value]
    );
    /**
     * event when change some value
     */

    /**
     * EVENT LISTENERS END
     */
    /**
     * FUNCTIONS
     */
    type LanguageModelWithOrder = LanguageModel & { order?: number };
    function command(
      s: MultilingualTextBoxState,
      action: MultilingualTextBoxAction
    ) {
      switch (action.type) {
        case MultilingualTextBoxActionType.setValues:
          return (() => {
            let v: Array<LanguageModelWithOrder>;

            if (action.payload) {

              v = action.payload.map(({ id, value }: any) => ({
                id,
                value: value !== '' ? value : s.values?.[id] ?? null,
                order: 0,
              }));


              TpLanguages.forEach((language) => {
                if (!v.find((l) => l.id === language.id)) {
                  v.push({ id: language.id, value: s.values?.[language.id] ?? null, order: 0 });
                }
              });
            } else {

              if (!s.values || Object.keys(s.values).length === 0) {
                v = TpLanguages.map(({ id }) => ({ id, value: null, order: 0 }));
              } else {
                v = TpLanguages.map(({ id }) => ({
                  id,
                  value: s.values[id] ?? null,
                  order: 0,
                }));
              }
            }


            const primaryLanguageId = TpMainLanguage;
            v.sort((a, b) => (a.id === primaryLanguageId ? -1 : b.id === primaryLanguageId ? 1 : 0));


            v.forEach((item, index) => {
              item.order = index;
            });

            const values: LanguageGroupValues = {};
            const required: LanguageGroupValues<boolean> = {};
            const index: LanguageGroupValues<number> = {};

            v.forEach(({ id, value }, i) => {
              values[id] = value;
              index[id] = i;
              required[id] = TpRequiredLanguages.includes(id);
            });

            return {
              ...s,
              values,
              stateValues: {},
              required,
              index,
            } as MultilingualTextBoxState;
          })();


        case MultilingualTextBoxActionType.setMessages:
          return {
            ...s,
            messages: action.payload,
          } as MultilingualTextBoxState;
        case MultilingualTextBoxActionType.markAsTouched:
          return (() => {
            const values = { ...s.values };

            TpRequiredLanguages.forEach((language) => values[language] = "");

            return {
              ...s,
              values,
            } as MultilingualTextBoxState;
          })();
        case MultilingualTextBoxActionType.changeValue:
          return (() => {
            const values = { ...s.values };
            values[action.payload.languageId] = action.payload.value;

            return {
              ...s,
              values,
            } as MultilingualTextBoxState;
          })();
        case MultilingualTextBoxActionType.setStateValues:
          return {
            ...s,
            stateValues: { ...s.values },
          } as MultilingualTextBoxState;
        case MultilingualTextBoxActionType.cleanStateValues:
          return {
            ...s,
            stateValues: {},
          } as MultilingualTextBoxState;
        case MultilingualTextBoxActionType.clean:
          return { ...multilingualTextBoxInitialState };
        default:
          return { ...s } as MultilingualTextBoxState;
      }
    }
    /**
     * load resources
     */
    async function loadResources() {
      const m = {} as MultilingualTextBoxMessages;

      if (!labels?.inputLabel) {
        m.inputLabel = await TPI18N.GetText(TPGlobal.globalResourceSet, "Name");
      }
      if (!labels?.errorInputLabel) {
        m.errorInputLabel = await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "NameIsRequired"
        );
      }
      if (!labels?.iconButtonTooltipLabel) {
        m.iconButtonTooltipLabel = await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "LanguageList"
        );
      }
      if (!labels?.modalTitleLabel) {
        m.modalTitleLabel = await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "LanguageList"
        );
      }
      if (!labels?.modalOkButtonLabel) {
        m.modalOkButtonLabel = await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "SaveButton"
        );
      }
      if (!labels?.modalCancelButtonLabel) {
        m.modalCancelButtonLabel = await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "CancelButton"
        );
      }

      dispatch({ type: MultilingualTextBoxActionType.setMessages, payload: m });
    }
    /**
     * get value
     */
    function getValue(): Array<LanguageModel> {
      const result = Object.entries(state.values).map(([id, value]) => ({
        id,
        value,
      }));

      result.sort((a, b) => state.index[a.id]! - state.index[b.id]!);

      return result;
    }
    /**
     * is invalid
     */
    function isInvalid(): boolean {
      return TpRequiredLanguages.some(
        (language) => !state.values[language]
      );
    }
    /**
     * handle language change
     * @param languageId language identifier
     * @param value typed value
     */
    function onChangeValueLanguageHandler(languageId: string, value: string) {
      dispatch({
        type: MultilingualTextBoxActionType.changeValue,
        payload: { languageId, value },
      });
    }
    /**
     * on click icon button handler
     */
    function onClickIconButtonHandler() {
      dispatch({ type: MultilingualTextBoxActionType.setStateValues });
      openModal();
    }
    /**
     * on click cancel button handler
     */
    function onClickCancelButtonHandler() {
      Object.entries(state.stateValues).map(([key, value]) => {
        dispatch({
          type: MultilingualTextBoxActionType.changeValue,
          payload: { languageId: key, value },
        });
      });
      dispatch({ type: MultilingualTextBoxActionType.cleanStateValues });
      closeModal();
    }

    function onClickSaveButtonHandler() {
      Object.entries(state.values).map(([key, value]) => {
        dispatch({
          type: MultilingualTextBoxActionType.changeValue,
          payload: { languageId: key, value },
        });
      });
      if (onChangeLanguages) {
        const result = Object.entries(state.values).map(([id, value]) => ({
          id,
          value,
        }));

        result.sort((a, b) => state.index[a.id]! - state.index[b.id]!);

        onChangeLanguages(result)
      }
      closeModal();
    }
    /**
     * FUNCTIONS END
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
      <div
        id={id}
        key={key}
        style={{
          ...styles.container,
          ...style,
        }}
      >
        <div
          id={`${id}InputContainer`}
          key={`${id}InputContainer`}
          style={styles.inputContainer}
        >
          <TPTextBox
            id={`${id}Input`}
            key={`${id}Input`}
            isMandatory={!optional && state.required[TpLanguage]!}
            labelText={`${state.messages?.inputLabel} (${TpLanguages.find((language) => language.id === TpLanguage)?.value})`}
            maxLength={maxLength}
            value={state.values[TpLanguage] ?? ""}
            onChange={({ target }: any) =>{
              onChangeValueLanguageHandler(TpLanguage, target.value);
              onChange ? onChange(target.value) : null

            }}
            errorMessage={
              state.required[TpLanguage] && state.values[TpLanguage] === ""
                ? state.messages?.errorInputLabel
                : ""
            }
          />
        </div>

        <TPButton
          id={`${id}IconButton`}
          key={`${id}IconButton`}
          style={styles.iconButton}
          type={TPButtonTypes.icon}
          icon={TPIconTypes.language}
          text={`+${TpLanguages.length - 1}`}
          tooltip={state.messages?.iconButtonTooltipLabel}
          onClick={onClickIconButtonHandler}
        />

        {isOpen && (
          <TPModalLanguageList
            id={`${id}Modal`}
            key={`${id}Modal`}
            isOpen={isOpen}
            title={`${state.messages?.modalTitleLabel}`}
            acceptLabel={`${state.messages?.modalOkButtonLabel}`}
            cancelLabel={`${state.messages?.modalCancelButtonLabel}`}
            saveChanges={onClickSaveButtonHandler}
            closeModal={onClickCancelButtonHandler}
          >
            <div
              id={`${id}ModalContent`}
              key={`${id}ModalContent`}
              style={styles.modalContent}
            >
              {TpLanguages.filter((language) => language.id !== TpLanguage).map(
                (language) => (
                  <TPTextBox
                    id={`${id}ModalInput${language.id}`}
                    key={`${id}ModalInput${language.id}`}
                    isMandatory={state.required[language.id]!}
                    labelText={`${state.messages?.inputLabel} (${language.value})`}
                    maxLength={maxLength}
                    value={state.values[language.id] ?? ""}
                    onChange={({ target }: any) =>
                      onChangeValueLanguageHandler(language.id, target.value)
                    }
                    errorMessage={
                      state.required[language.id] &&
                      state.values[language.id] === ""
                        ? state.messages?.errorInputLabel
                        : ""
                    }
                  />
                )
              )}
            </div>
          </TPModalLanguageList>
        )}
      </div>
    );
  }
);

export default MultilingualTextBox;
