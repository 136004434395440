import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import TPGeneralContext from "@/contexts/TPGeneralContext";
import CaseViewer from "@/pages/CaseViewer/CaseViewer";
import { StoreModel, TypeOf } from "@/redux/store";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TabsCaseViewerSlice,
  TabsCaseViewerStateModel,
} from "./_redux/TabsCaseViewerSlice";
import {
  ContentVerticalTabStyled,
  MenuVerticalTabStyled,
} from "./menuVerticalTabStyled";
import VerticalTabCase from "./VerticalTabCase";

export default function VerticalTabsCaseViewerContainer() {
  const dispatch = useDispatch();

  const { cases, ids, seconds, focus, result } = useSelector(
    (s: StoreModel) => s[TabsCaseViewerSlice.name],
  ) as TabsCaseViewerStateModel;

  const caseViewerContext: any = React.useContext(TPCaseViewerContext);
  const generalContext: any = React.useContext(TPGeneralContext);

  useEffect(() => {
    const notifyInterval = setInterval(
      () => dispatch(TabsCaseViewerSlice.actions.notify()),
      10000,
    );
    return () => clearInterval(notifyInterval);
  }, []);

  useEffect(() => {
    if (
      TypeOf(result, TabsCaseViewerSlice.actions.notify) &&
      JSON.stringify(seconds) !== "{}"
    )
      dispatch(TabsCaseViewerSlice.actions.notifyLoading({ ids, seconds }));
  }, [result]);

  useEffect(() => {
    const casee = caseViewerContext.currentCase;
    if (cases.find((c) => c === casee) === undefined)
      dispatch(
        TabsCaseViewerSlice.actions.addCase({
          userGuid: generalContext.globalUserInfo.userGuid,
          caseNumber: String(casee),
        }),
      );
  }, [caseViewerContext.currentCase]);

  return (
    <>
      <MenuVerticalTabStyled>
        <ul>{cases?.map((casee) => <VerticalTabCase caseNumber={casee} />)}</ul>
      </MenuVerticalTabStyled>
      <ContentVerticalTabStyled>
        <ul>
          {cases?.map((casee) => (
            <li key={"verticalTabContent" + casee}>
              <div
                className={`container-fluid ${casee === focus ? "  active" : ""}`}
              >
                <CaseViewer caseNumberToView={Number(casee)}></CaseViewer>
              </div>
            </li>
          ))}
        </ul>
      </ContentVerticalTabStyled>
    </>
  );
}
