import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { SentimentsLabels } from "@/models/EmailTemplates/EmailTemplateModels";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
  StyledSentimentIcon,
  StyledSentimentSection,
  StyledToggle,
} from "@/pages/CaseViewer/case-viewer-styles";
import { FormControlLabel } from "@mui/material";
import { ReactElement } from "react";

export type Sentiment = "positive" | "neutral" | "negative";

export type SentimentsPercentage = {
  [key in Sentiment]: string;
}
type SentimentAnalysisSectionProps = {
  onToggleChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  percentages?: SentimentsPercentage;
  checked?: boolean;
  componentLabels: SentimentsLabels;
};

type SentimentIcons = {
  [key in Sentiment]: SimpleIcon;
}

type SimpleIcon = {
  id: string,
  alt: string,
  icon: TPIconTypes
}

function SentimentAnalysisSection({
  onToggleChange,
  percentages,
  checked,
  componentLabels
}: SentimentAnalysisSectionProps): ReactElement {
  const icons: SentimentIcons = {
    positive: {
      id: "positive",
      alt: componentLabels.positive,
      icon: TPIconTypes.happy
    },
    neutral: {
      id: "neutral",
      alt: componentLabels.neutral,
      icon: TPIconTypes.neutral
    },
    negative: {
      id: "negative",
      alt: componentLabels.negative,
      icon: TPIconTypes.negative
    }
  }

  return (
    <StyledSentimentSection>
      <div className="emotion">
        <p>{componentLabels.title}:</p>
        <div className="sentiments-container">
          {
            Object.values(icons).map(sentiment => {
              const percentage: string = percentages ? percentages[sentiment.id as Sentiment] : "0";
              return (
                <StyledSentimentIcon key={sentiment.id}>
                  <TPIcon iconType={sentiment.icon} tooltip={sentiment.alt} isTooltip />
                  <p className="percentage">{percentage}%</p>
                </StyledSentimentIcon>
              )
            })
          }
        </div>
      </div>
      <FormControlLabel
        sx={{
          "& .MuiFormControlLabel-label": {
            fontSize: "14px",
            marginBottom: "1px",
            userSelect: "none",
            color: "#2e2e2e",
          },
        }}
        control={
          <StyledToggle
            checked={checked}
            onChange={onToggleChange}
            sx={{
              padding: "0",
              height: "16px",
              width: "29px",
              marginRight: "6px",
            }}
          />
        }
        label={componentLabels.textAnalysis}
      />
    </StyledSentimentSection>
  );
}

export default SentimentAnalysisSection;
