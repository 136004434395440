import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import UserProfile from "@/modules/core/design-system/user-data/UserProfile";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { ColumnStyles, CustomColumnNames } from "@/pages/Projects/ProjectDetail/ProjectDetailDynamicTable";
import { ActiveTeamMembersService } from "@/services/ActiveTeamMembersService";
import { TPI18N } from "@/services/I18nService";
import React, { FC, useEffect, useState } from "react";
import { CockpitView, InfoCardLabels, InfoCardTypes, TeamMemberParsed } from "../supervisor-cockpit.model";
import CockpitInfoCard from "../utils/CockpitInfoCard";
import { TeamActivityTickets } from "./ActiveTeamMembersTickets";
import ReassignModal from "./ConfirmationModal";

type props = {
    user: TeamMemberParsed;
    changeTitle: (title: string) => void;
    view: CockpitView
    loadingCallback: (status: boolean) => void;
    groupId: number;
};

const ActiveTeamMembersUserView: FC<props> = ({ user, changeTitle, view, loadingCallback, groupId }) => {

    const componentFileName = "ActiveReamMembersUserView.tsx";

    const resourceSet = "ActiveTeamMembersUserView";

    const parent = "CockpitDashboard";

    const caseContext: any = React.useContext(TPCaseViewerContext);

    const [openModal, setOpenModal] = useState<boolean>(false);

    const [casesCheck, setCasesCheck] = useState<boolean>(true);
    const [eventsCheck, setEventsCheck] = useState<boolean>(true);

    const [openCasesLabel, setOpenCasesLabel] = useState<string>("");

    const [ticketLabel, setTicketLabel] = useState<string>("");
    const [statusLabel, setStatusLabel] = useState<string>("");
    const [creationDateLabel, setCreationDateLabel] = useState<string>("");
    const [customerLabel, setCustomerLabel] = useState<string>("");

    const [casesLabel, setCasesLabel] = useState<string>("");
    const [eventsLabel, setEventsLabel] = useState<string>("");

    const [onSchedule, setOnSchedule] = useState<string>("");
    const [offSchedule, setOffSchedule] = useState<string>("");

    const [reassignCasesLabel, setReassignCasesLabel] = useState<string>("");

    const [infoCardLabels, setInfoCardLabels] = useState<InfoCardLabels>({} as InfoCardLabels);

    const loadResources = async () => {
        setOpenCasesLabel(await TPI18N.GetText(resourceSet, "openCasesLabel"));
        setReassignCasesLabel(await TPI18N.GetText(resourceSet, "reassignCasesLabel"));
        setTicketLabel(await TPI18N.GetText("ActiveTeamMembersTickets", "ticketLabel"));
        setStatusLabel(await TPI18N.GetText("ActiveTeamMembersTickets", "statusLabel"));
        setCreationDateLabel(await TPI18N.GetText("ActiveTeamMembersTickets", "creationDateLabel"));
        setCustomerLabel(await TPI18N.GetText("ActiveTeamMembersTickets", "customerLabel"));
        setCasesLabel(await TPI18N.GetText("ActiveTeamMembersTickets", "casesLabel"));
        setEventsLabel(await TPI18N.GetText("ActiveTeamMembersTickets", "eventsLabel"));
        setOnSchedule(await TPI18N.GetText("CockpitTeamActivityTable", "OnSchedule"));
        setOffSchedule(await TPI18N.GetText("CockpitTeamActivityTable", "OffSchedule"));
        setInfoCardLabels({
            offline: await TPI18N.GetText(parent, "Offline"),
            working: await TPI18N.GetText(parent, "Working"),
            online: await TPI18N.GetText(parent, "Online"),
            ready: await TPI18N.GetText(parent, "Ready"),
            worked: await TPI18N.GetText(parent, "Worked"),
            case: await TPI18N.GetText(parent, "Case"),
            event: await TPI18N.GetText(parent, "Event"),
            chat: await TPI18N.GetText(parent, "Chat"),
            mail: await TPI18N.GetText(parent, "Mail"),
            expired: await TPI18N.GetText(parent, "Expired"),
            pending: await TPI18N.GetText(parent, "Pending"),
            inProcess: await TPI18N.GetText(parent, "InProgress"),
            closed: await TPI18N.GetText(parent, "Closed"),
            history: await TPI18N.GetText(parent, "History"),
            processing: await TPI18N.GetText(parent, "Processing"),
        })
    }

    const [tableData, setTableData] = useState<Array<TeamActivityTickets>>([]);

    const getTickets = async () => {
        loadingCallback && loadingCallback(true);
        let ATMService = new ActiveTeamMembersService();

        let expectedCodes = [200];

        let inputDTO = {
            idUser: user.userId,
            searchType: view + "view",
        }

        try {
            let responseRequest = await ATMService.getUserTickets(
                inputDTO,
                false,
                false,
                expectedCodes
            )

            if (responseRequest) {
                let newData: TeamActivityTickets[] = [];
                for (let i = 0; i < responseRequest.length; i++) {
                    newData.push({
                        id: Number(responseRequest[i].ticketId),
                        ticket: responseRequest[i].ticket.toLowerCase() as "case" | "event" | "chat" | "mail",
                        status: responseRequest[i].statusId as "PE" | "PR" | "EX" | "CL" | "IP" | "HI" | "AL" | "OH" | "RE" | "SO" | "RC",
                        statusDescription: responseRequest[i].statusDescription,
                        creationDate: new Date(responseRequest[i].creationDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }),
                        customer: responseRequest[i].customer,
                        assignTo: responseRequest[i].userName,
                        idAssignTo: user.userId,
                        idGroup: responseRequest[i].idGroup,
                    })
                }

                setTableData(newData);
                loadingCallback && loadingCallback(false);
            }

        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getTeamData ex`);
            loadingCallback && loadingCallback(false);
        }
    }

    const getInfoCardLabel = (item: TeamActivityTickets) => {
        if (item.ticket == "case") {
            return item.statusDescription;
        } else {
            let label = infoCardLabels["expired"];

            item.status == "PE" && (label = infoCardLabels["pending"]);
            item.status == "PR" && (label = infoCardLabels["processing"]);
            item.status == "IP" && (label = infoCardLabels["inProcess"]);
            item.status == "EX" && (label = infoCardLabels["expired"]);
            item.status == "CL" && (label = infoCardLabels["closed"]);
            item.status == "HI" && (label = infoCardLabels["history"]);

            return label;
        }
    }

    const getInfoCardType = (item: TeamActivityTickets) => {
        if (item.ticket == "case") {
            let type: InfoCardTypes = "offline";

            item.status == "AL" && (type = "case");
            item.status == "EX" && (type = "expired");
            item.status == "OH" && (type = "online");
            item.status == "PE" && (type = "pending");
            item.status == "RE" && (type = "offline");
            item.status == "SO" && (type = "ready");
            item.status == "RC" && (type = "working");

            return type;
        } else {
            let type: InfoCardTypes = "offline";
            const eventStatusEnums: { value: string, type: InfoCardTypes }[] = [
                {
                    value: "PE",
                    type: "pending"
                },
                {
                    value: "PR",
                    type: "processing"
                },
                {
                    value: "IP",
                    type: "inProcess"
                },
                {
                    value: "EX",
                    type: "expired"
                },
                {
                    value: "CL",
                    type: "closed"
                },
                {
                    value: "HI",
                    type: "history"
                }
            ]
    
            const status = eventStatusEnums.find(status => status.value == item.status);

            if (status) {
                type = status.type;
            }
    
            return type;
        }
    }

    const customColumns: ColumnStyles<TeamActivityTickets> = {
        id: ({ item, value }) => (
            <TablePrimaryItem
                value={value}
                onClick={() => item.ticket == "case" ? caseContext.handleAddNewCaseViewerCallBack(value) : (item.ticket == "event" && caseContext.showTakeNextQueue(item.idGroup || groupId, value, true))}
            />
        ),
        ticket: ({ value, item }) => <CockpitInfoCard type={value} label={infoCardLabels[item.ticket]} isSmallCard />,
        status: ({ item, value }) => <CockpitInfoCard type={getInfoCardType(item)} label={getInfoCardLabel(item)} isSmallCard />,
        customer: ({ value }) => value == "NULL" ? "" : value,
    }

    const [selectedRows, setSelectedRows] = useState<Array<TeamActivityTickets>>([]);

    useEffect(() => {
        loadResources();
        changeTitle(user.userName);
        getTickets();
    }, []);

    const handleClose = (status: boolean) => {
        setOpenModal(status);
    }

    const callback = () => {
        getTickets()
    }

    const columnNames: CustomColumnNames<TeamActivityTickets> = {
        ticket: ticketLabel,
        status: statusLabel,
        creationDate: creationDateLabel,
        customer: customerLabel,
    }

    useEffect(() => {
        console.log(groupId);
    }, [groupId]);

    const filterData = () => {
        let toFilter;

        toFilter = tableData;

        if (view == "event") {
            toFilter = toFilter.filter((item: TeamActivityTickets) => {
                return item.idGroup == groupId;
            })
        }
        

        let filters = {
            cases: casesCheck,
            event: eventsCheck,
        }

        return toFilter.filter((item: TeamActivityTickets) => {
            return (
                (filters.cases && item.ticket == "case") ||
                (filters.event && item.ticket == "event")
            );
        });
    };

    const showCheckboxes = () => {
        let data: TeamActivityTickets[];

        data = tableData;

        if (data.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const [open, setOpen] = useState(false);

    return (
        <>
            <div className="user-info-container">
                <div className="user-info-container-inner">
                    <div style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
                        <UserProfile
                            completeName={user.userName}
                            customSize={{ font: "16px", size: "34px" }}
                        />
                        <p style={{ margin: 0 }}>{user.userName}</p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "0.6em" }}>
                        <div
                            className="icon-content"
                            style={{
                                backgroundColor: user.schedule === "onSchedule" ? "#A1D66D" : "#989898",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "24px",
                                height: "24px",
                            }}
                        >
                            <TPIcon
                                style={{
                                    color: "white",
                                    height: "18px",
                                    width: "18px",
                                    fontSize: "18px",
                                }}
                                iconType={
                                    TPIconTypes[user.schedule === "onSchedule" ? "work" : "prohibited"]
                                }
                            />
                        </div>
                        <p style={{ margin: 0 }}>
                            {user.schedule === "onSchedule" ? onSchedule : offSchedule}
                        </p>
                    </div>
                    <CockpitInfoCard type={user.status} label={infoCardLabels[user.status]} isSmallCard />
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "space-between", gap: "1.5em" }}>
                        <p style={{ margin: 0 }}>{openCasesLabel}</p>
                        <TablePrimaryItem
                            value={user.pendingTickets.toString()}
                            onClick={() => { }}
                        />
                    </div>
                </div>
            </div>
            <br />
            <DynamicTable
                id="active-team-members-user-view"
                data={filterData()}
                columnStyles={customColumns}
                columnNames={columnNames}
                selectable
                onSelectionChange={(e) => setSelectedRows(e)}
                withPreferences
                disableSelectionCheckbox={(row) => row.ticket == "event" ? true : false}
                hiddenColumns={["idAssignTo", "assignTo", "statusDescription", "idGroup"]}
                onIconClicked={icon => {
                    if (icon == TPIconTypes.loop || icon == TPIconTypes.refresh) callback();
                }}
                additionalCheckboxes={
                    showCheckboxes() ?
                        [
                            {
                                checked: casesCheck,
                                key: "cases",
                                label: casesLabel,
                                onChange: () => setCasesCheck(!casesCheck),
                            },
                            {
                                checked: eventsCheck,
                                key: "events",
                                label: eventsLabel,
                                onChange: () => setEventsCheck(!eventsCheck),
                            },
                        ] : []
                }
            />
            <div className="reassign-button-container">
                <TPButton
                    id="reassign-tickets"
                    isDesignSystem
                    customType={ButtonCustomType.secondary}
                    onClick={() => setOpenModal(true)}
                    disabled={selectedRows.length < 1 || selectedRows.some((row) => row.ticket === "event")}
                    style={{ padding: "1px 18px" }}
                >
                    {reassignCasesLabel}
                </TPButton>
            </div>
            {openModal && <ReassignModal handleModalClose={handleClose} selectedRows={selectedRows} hasEvents={selectedRows.some((row) => row.ticket === "event")} callback={callback} />}
        </>
    )
};

export default ActiveTeamMembersUserView