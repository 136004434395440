import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";

export class EmailReaderReportsService {
  serviceFileName: string = "EmailReaderReportsService.ts";

  public async getActiveInboundMailboxes(
    filterIsActive: number = 1, // Filtro para mostrar solo buzones activos (por defecto 1)
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number> = [200],
  ): Promise<any> {
    const httpclient = new TPHTTPService();
    const url: string = `/inboundmailbox?filterIsActive=${filterIsActive}`;
    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );

      return response.responseData?.data || [];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getActiveInboundMailboxes ex`,
        TPLogType.ERROR,
        error,
      );

      throw new Error(
        `Error ${this.serviceFileName} getActiveInboundMailboxes`,
      );
    }
  }

  public async generateEmailReaderReport(
    reportPayload: {
      ReportId: string;
      UserGuid: string;
      StartRecord: number;
      EndRecord: number;
      StartDate: string;
      EndDate: string;
      DateType: string;
      Parameter1: string;
      Parameter2?: string;
      TempReportFilter?: Array<any>;
      isDetailReport: boolean;
    },
    showPositiveMessage: boolean = true,
    showNegativeMessage: boolean = true,
    expectedCodes: Array<number> = [200],
  ): Promise<any> {
    const httpclient = new TPHTTPService();
    const url: string = `/report`;
    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let response: HTTPResponse;

    try {
      response = await httpclient.postData(
        finalURL,
        reportPayload,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );

      return response.responseData || {};
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} generateEmailReaderReport ex`,
        TPLogType.ERROR,
        error,
      );

      throw new Error(
        `Error ${this.serviceFileName} generateEmailReaderReport`,
      );
    }
  }
}
