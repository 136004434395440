import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { DataTableContainer, TableContainer, tableStyles } from "@/components/bootstrap/content/tables/tpTableStyles";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPPageFirstRow, TPPageSearchContainer } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { taskStatusEnum, TPActiveOptions, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import * as FileSaver from "file-saver";
import { forwardRef, useEffect, useImperativeHandle, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";

import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { GroupDashboardDataService } from "@/services/GroupDashboardService";
import { RightRowContainerStyle, RowConfigStyle } from "../FormDesigner/StyleFromDesigner";
import { useAppGroupDashboard } from "./ContextGroupDashboard";
import { LocationLanguagesGroupDashboard, LocationNameGlobal, LocationNameTableCase } from "./GroupDashboardLocalization";
import TPIcon from "../../components/bootstrap/extend/TPIcons/TPIcon";
import { CaseAndTaskModel, GroupDashboardCaseModel, TaskToReassign } from "../../models/GroupDashboard/GroupDashboard";
import DynamicTable, { ExportCustomValues } from "@/modules/core/components/dynamic-table/DynamicTable";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";


interface InputModel {
    callBackCommands: Function;
    callBackCount: Function;
    mode: string;
    recordId: string;
    active: boolean;
}

type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<any>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
    selectedRows: string[];
};

const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    selectedRows: [],
    searchPattern: "",
};

enum commandsEnum {
    "set_filterIsLoaded" = 0,
    "setup_grid_columns" = 1,
    "reload_grid" = 2,
    "change_selectedFilter" = 3,
    "change_search_pattern" = 4,
    "row_select" = 5
}

type commandType = {
    type: commandsEnum;
    payload: any;
};

let initialStateFilter: Array<TPKeyValue> = [];

const GroupDashboardTable = forwardRef(
    ({ callBackCommands, callBackCount, active }: InputModel, ref) => {
        const resourceSet: string = "GroupDashboard";

        const [data, setData] = useState<GroupDashboardCaseModel[]>([]);
        const [activeSave, setActiveSave] = useState(false);
        const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
        const [autocompleteToOptions, setAutocompleteToOptions] = useState<Array<TPKeyValue>>([]);
        const [selectedAutocompleteTaskTypeOption, setSelectedAutocompleteTaskTypeOption] = useState<Array<TPKeyValue>>([]);

        const [erroNewResponsable, setErroNewResponsable] = useState("");
        const [currenCaseManagerName, setCurrenCaseManagerName] = useState("");
        const [currenCaseManagerKey, setCurrenCaseManagerKey] = useState("");
        const [exportLabel, setExportLabel] = useState("");

        const [location, setLocation] = useState(new LocationNameTableCase);
        const [locationGlobal, setLocationGlobal] = useState(new LocationNameGlobal);
        const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

        const loadResourcesAndCustomerTypeFilter = async () => {
            let temp = new LocationLanguagesGroupDashboard();
            await temp.handlelocationCase(resourceSet);
            setLocation(temp.getLocationNameTable);
            setExportLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'ExportLabel'));

            await temp.locationGlobal(TPGlobal.globalResourceSet);
            setLocationGlobal(temp.getLocationNameGlobal);

            let newFilterKeyValue: Array<TPKeyValue> = [];
            newFilterKeyValue.push({
                key: TPActiveOptions.ALL.toString(),
                value: await TPI18N.GetText(resourceSet, "All"),
            });
            newFilterKeyValue.push({
                key: TPActiveOptions.ACTIVE.toString(),
                value: await TPI18N.GetText(resourceSet, "Active"),
            });
            newFilterKeyValue.push({
                key: TPActiveOptions.INACTIVE.toString(),
                value: await TPI18N.GetText(resourceSet, "Inactive"),
            });
            setFilterKeyValue(newFilterKeyValue);
        }



        const handleStatus = (item: string) => {
            switch (item) {
                case taskStatusEnum.Solved:
                    return (
                        <TPIcon

                            iconType={TPIconTypes.checkCircle}
                            style={{ color: "#69c169", fontSize: "20px" }}
                            inline={true}
                        />
                    );
                case taskStatusEnum.Pending:
                    return (
                        <TPIcon
                            iconType={TPIconTypes.watchLater}
                            style={{ color: "rgb(11 122 187)", fontSize: "20px" }}
                            inline={true}
                        />
                    );
                case taskStatusEnum.Expired:
                    return (
                        <TPIcon
                            iconType={TPIconTypes.warning}
                            style={{ color: "red", fontSize: "20px" }}
                            inline={true}
                        />
                    );
                case taskStatusEnum.Lag:
                    return (
                        <TPIcon
                            iconType={TPIconTypes.hourglassTop}
                            style={{ color: "#e57348", fontSize: "20px" }}
                            inline={true}
                        />
                    );
            }
        }

        const setupGridColumns = (prevState: AdminStateType) => {
            try {
                let newState: AdminStateType;
                newState = { ...prevState };
                let newColumns: Array<any> = [];

                const allRowsSelected = newState.selectedRows.length == data.length && data.length > 0;
                newColumns.push({
                    width: "100px",
                    center: true,
                    name: (
                        <div id="add-data-admin-select-all" className="checkbox-label">
                            <TPCheckBox
                                checked={allRowsSelected}
                                onChange={() =>
                                    dispatchCommand({
                                        type: commandsEnum.row_select,
                                        payload: allRowsSelected ?
                                            []
                                            :
                                            data.map((entry: any) => entry.caseId),
                                    })
                                }
                            />
                        </div>
                    ),
                    cell: (row: { [x: string]: any }) => {
                        const selected = newState.selectedRows.includes(row["caseId"]);
                        return (
                            <div id={`add-data-admin-checkbox-${row["caseId"]}`}>
                                <TPCheckBox
                                    checked={selected || allRowsSelected}
                                    onChange={() => {
                                        dispatchCommand({
                                            type: commandsEnum.row_select,
                                            payload: selected ?
                                                newState.selectedRows.filter((entry: string) => entry != row["caseId"])
                                                :
                                                [...newState.selectedRows, row["caseId"]],
                                        })
                                    }
                                    }
                                />
                            </div>
                        );
                    },
                    selector: (row: { [x: string]: any }) => row["selected"],
                    sortable: false,
                });


                newColumns.push({
                    width: "90px",
                    center: true,
                    name: location.columnStatus,
                    selector: (row: { [x: string]: any }) => row["isActive"],
                    cell: (row: { [x: string]: any }) => {
                        return (
                            handleStatus(row["stateId"])
                        );
                    },
                });


                newColumns.push({
                    width: "133px",
                    center: true,
                    name: location.columnCustomState,
                    cell: (row: { [x: string]: any }) => {
                        return (
                            handleStatus(row["customStateId"])
                        );
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });


                newColumns.push({
                    width: "133px",
                    center: true,
                    name: location.columnCaseNumber,
                    cell: (row: { [x: string]: any }) => {
                        return (
                            <a
                                onClick={(id: any) => callBackCommands(row["caseId"])}
                                className="dropdown-item"
                                href="#"
                                style={{ textDecoration: "caseId" }}
                            >
                                {row["caseId"]}
                            </a>
                        )
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });

                newColumns.push({
                    width: "120px",
                    center: true,
                    name: location.columnPromiseDate,
                    cell: (row: { [x: string]: any }) => {
                        return row["promiseDate"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });

                newColumns.push({
                    width: "100px",
                    center: true,
                    name: location.columnTerm,
                    cell: (row: { [x: string]: any }) => {
                        return handleFormatTerm(row["term"]);
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });

                newColumns.push({
                    width: "125px",
                    center: true,
                    name: location.columnResponsible,
                    cell: (row: { [x: string]: any }) => {
                        return row["responsibleFullName"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });


                newColumns.push({
                    width: "150px",
                    center: true,
                    name: location.columnCaseHandingStarted,
                    cell: (row: { [x: string]: any }) => {
                        return handleFormatTerm(row["dateWriterRead"]);
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });

                newColumns.push({
                    width: "180px",
                    center: false,
                    name: location.columnCaseHandingTime,
                    cell: (row: { [x: string]: any }) => {
                        return handleFormatTerm(row["timeLabor"]);
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });

                newColumns.push({
                    width: "180px",
                    center: false,
                    name: location.columnCurrentTask,
                    cell: (row: { [x: string]: any }) => {
                        return row["taskTypeDescription"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });

                newColumns.push({
                    width: "150px",
                    center: true,
                    name: location.columnTimeInCurrentTask,
                    cell: (row: { [x: string]: any }) => {
                        return handleFormatTerm(row["workingTaskTime"]);
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });


                newState.gridColumns = [...newColumns];
                return newState;
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} setupGridColumns ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${resourceSet} setupGridColumns ex`);
                return prevState;
            }
        };

        const [adminState, dispatchCommand] = useReducer(
            doCommand,
            initialStateBLL
        );

        const [isLoading, setIsLoading] = useState(false);

        function doCommand(prevState: AdminStateType, command: commandType) {
            switch (command.type) {
                case commandsEnum.set_filterIsLoaded:
                    let newStateFilter: AdminStateType;
                    newStateFilter = { ...prevState };
                    newStateFilter.filterIsLoaded = true;
                    return newStateFilter;
                case commandsEnum.setup_grid_columns:
                    let newStateColumns: AdminStateType = setupGridColumns(prevState);
                    newStateColumns.columnsAreLoaded = true;
                    return newStateColumns;
                case commandsEnum.reload_grid:
                    let newStateGrid: AdminStateType;
                    newStateGrid = { ...prevState };
                    newStateGrid.gridData = command.payload;
                    return newStateGrid;
                case commandsEnum.change_selectedFilter:
                    let newStateChangeFilter: AdminStateType;
                    newStateChangeFilter = { ...prevState };
                    newStateChangeFilter.selectedFilter = command.payload;
                    return newStateChangeFilter;
                case commandsEnum.change_search_pattern:
                    let newStatePattern: AdminStateType;
                    newStatePattern = { ...prevState };
                    newStatePattern.searchPattern = command.payload;
                    return newStatePattern;
                case commandsEnum.row_select:
                    let newStateSelection: AdminStateType = setupGridColumns(prevState);
                    newStateSelection.selectedRows = command.payload;
                    return newStateSelection;
                default:
                    return prevState;
            }
        }

        const filteredData = () => {

            let searcheableColumns: Array<string> = [
                "caseId",
                "taskTypeDescription",
                "responsibleFullName",
                "isActive",
                "caseId"
            ];
            let i: number;
            let search: string;
            search = adminState.searchPattern.trim();
            if (!adminState || adminState.gridData == null || adminState.gridData == undefined) return adminState.gridData;
            return adminState.gridData.filter(function (item) {
                if (search == "" || search.length <= 2) {
                    return item;
                }
                for (i = 0; i <= searcheableColumns.length - 1; i++) {
                    let itemany: any;
                    itemany = item;
                    if (
                        itemany[searcheableColumns[i]] &&
                        itemany[searcheableColumns[i]]
                            .toString()
                            .toLowerCase()
                            .includes(search.toLowerCase())
                    ) {
                        return item;
                    }
                }
            });
        };

        const handleSearchPatternChange = (newValue: string) => {
            let command1: commandType = {
                type: commandsEnum.change_search_pattern,
                payload: newValue,
            };
            dispatchCommand(command1);

        };




        const handleToOnAutocompleteQuery = async (query: string) => {
            let tasktypeService = new GroupDashboardDataService();
            let expectedCodes: Array<number> = [200, 404];
            setAutocompleteToOptions(new Array<TPKeyValue>);
            setSelectedAutocompleteTaskTypeOption(new Array<TPKeyValue>);
            if (groupSelected == undefined) {
                query = "";
                setAutocompleteToOptions([]);
            }
            try {
                let responseRequest =
                    await tasktypeService.getUsersResponsibleByFilter(
                        groupSelected.key,
                        query,
                        false,
                        true,
                        expectedCodes
                    );
                let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
                    function (item: any) {
                        return {
                            key: item.userId,
                            value: `${item.firstNameUser} ${item.lastNameUser}`,
                        };
                    }
                );

                let filter = newToKeyValueList.filter(s => s.key.toString().trim().toLocaleLowerCase() != currenCaseManagerName.trim().toLocaleLowerCase());

                setAutocompleteToOptions(filter);
                return filter
            }
            catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} handleToOnAutocompleteQuery ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${resourceSet} handleToOnAutocompleteQuery ex`
                );
                return [];
            }
        };

        const handleOnAutocompleteKeyDown = (event: any) => {
            if (
                event.keyCode != 9 &&
                event.keyCode != 37 &&
                event.keyCode != 39 &&
                event.keyCode != 13 &&
                event.keyCode != 35 &&
                event.keyCode != 36 &&
                event.keyCode != 38 &&
                event.keyCode != 40
            ) {

                setCurrenCaseManagerKey("");
                handleToOnAutocompleteQuery("");
                setAutocompleteToOptions([]);
                setSelectedAutocompleteTaskTypeOption([]);
                setActiveSave(false);
                handleValidate();
            }
        };

        const onValueChangeTPAutoComplete = (e: any) => {
            if (e != undefined && e[0] && e.length > 0) {
                setSelectedAutocompleteTaskTypeOption(e);
                if (Array.isArray(e)) {
                    setCurrenCaseManagerKey(e[0].key + "");

                }

                handleValidate();
            }
        }

        const handleLoad = () => {
            let command1: commandType = {
                type: commandsEnum.setup_grid_columns,
                payload: null,
            };
            dispatchCommand(command1);
        }

        useImperativeHandle(ref, () => ({
            refreshGridFromParent() {
            },
        }));

        const handleValidate = async () => {

            setActiveSave(false);
            let temp = [...adminState.selectedRows];
            if (!groupSelected) {
                return "";
            }
            if (groupSelected.key == "") {
                return "";
            }
            if (temp.length <= 0) return "";
            if (currenCaseManagerKey == "") return "";
            setActiveSave(true);
        }

        const reassignCaseInGroup = async () => {
            setLoading(true);
            let tasktypeService = new GroupDashboardDataService();
            let expectedCodes: Array<number> = [200, 404];

            let dataCaseAndTask = new TaskToReassign();
            dataCaseAndTask.groupIdUser = Number.parseInt(groupSelected.value);
            dataCaseAndTask.guidUser = currenCaseManagerKey;
            dataCaseAndTask.taskToReassign = new Array<CaseAndTaskModel>();
            let temp = [...adminState.selectedRows];

            if (temp) {
                temp.forEach((e: any) => {
                    if (Array.isArray(data)) {
                        let item = data.find(s => s.caseId == e);
                        if (item)
                            dataCaseAndTask.taskToReassign.push(new CaseAndTaskModel(item.caseId, item.taskId));
                    }
                });
            }
            dispatchCommand({
                type: commandsEnum.row_select,
                payload: [],
            });

            try {
                let responseRequest =
                    await tasktypeService.reassignCaseInGroup(
                        dataCaseAndTask,
                        true,
                        true,
                        expectedCodes
                    );
                let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
                    function (item: any) {
                        return {
                            key: item.userId,
                            value: `${item.firstNameUser} ${item.lastNameUser}`,
                        };
                    }
                );

                let data = { ...adminState };
                data.selectedRows = [];
                dispatchCommand({
                    type: commandsEnum.row_select,
                    payload: [],
                });

                loadData(groupSelected.key)
                let filter = newToKeyValueList.filter(s => s.value.toString().trim().toLocaleLowerCase() != currenCaseManagerKey.trim().toLocaleLowerCase());
                setLoading(false);
                return filter
            }
            catch (error) {
                setLoading(false);
                TPLog.Log(
                    `Error ${resourceSet} handleToOnAutocompleteQuery ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${resourceSet} handleToOnAutocompleteQuery ex`
                );
                return [];
            }
        }

        const handleFormatTerm = (term: number): JSX.Element => {
            let days: number;
            let hours: number;
            let minutes: number;
            let negative: boolean = false;

            if (term < 0) {
                negative = true;
                term = Math.abs(term);
            }

            days = Math.floor(term / (24 * 60));
            term = term % (24 * 60);
            hours = Math.floor(term / 60);
            minutes = term % 60;

            const formattedTime = (
                <>
                    {days > 0 && `${days} d `}
                    {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}
                </>
            );

            return (
                <span style={negative ? { color: "red", marginBottom: 0 } : {}}>
                    {negative && '-'}
                    {formattedTime}
                </span>
            );
        }

        const handleDataFilter = (item: any): string => {

            let dataTemp = [...data];
            let filter = dataTemp.find(s => s.caseId == item.caseId);
            if (!filter) return "";

            return filter.stateDescription;
        }

        const customExport: ExportCustomValues<GroupDashboardCaseModel> = {
            stateId: ({ value, item }) => (handleDataFilter(item))
        };
        function loadData(id: string) {
            setLoading(true);
            const { getCasesByFilter } = new GroupDashboardDataService();
            let expectedCodes: Array<number> = [200];
            getCasesByFilter(id, false, false, expectedCodes)
                .then((dataList: any) => {
                    setData(dataList);
                    if (dataList) {
                        callBackCount(dataList.length);
                        let command1: commandType = {
                            type: commandsEnum.reload_grid,
                            payload: dataList.map((entry: any) => {
                                return { ...entry, selected: false };
                            }),
                        };
                        dispatchCommand(command1);
                    }
                })
                .catch(err => console.error(err))
                .finally(() => {
                    setLoading(false);
                })
        }

        const value = useAppGroupDashboard();
        const { setLoading, groupSelected } = value;

        useEffect(() => {
            loadResourcesAndCustomerTypeFilter()
                .then(function () {
                    //set filter is loaded
                    let command1: commandType = {
                        type: commandsEnum.set_filterIsLoaded,
                        payload: null,
                    };
                    dispatchCommand(command1);
                })
                .catch(function (error) {
                    TPLog.Log(
                        `Error ${resourceSet} loadResourcesAndCustomerTypeFilter ex`,
                        TPLogType.ERROR,
                        error
                    );
                    console.error(
                        `Error ${resourceSet} loadResourcesAndCustomerTypeFilter ex`
                    );
                });
        }, []);

        useEffect(() => {
            if (adminState.filterIsLoaded) handleLoad();
        }, [adminState.filterIsLoaded]);

        useEffect(() => {
            dispatchCommand({
                type: commandsEnum.row_select,
                payload: adminState.selectedRows,
            });
        }, [adminState.selectedRows]);

        useEffect(() => {
            setSelectedAutocompleteTaskTypeOption([]);
            handleLoad();
            if (groupSelected && groupSelected.value != "") {
                loadData(groupSelected.key);
            }
        }, [groupSelected]);

        useEffect(() => {
            handleValidate();
        }, [adminState, currenCaseManagerKey]);

        return (
            <>
                <br></br>
                {active &&
                    <TPLoadingOverlay active={isLoading}>
                        <div>
                            <div style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-end",
                                gap: "5px"
                            }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px", width: "380px" }}>
                                    <TPLabel
                                        isMandatory={false}
                                        labelText={location.responsible}
                                    />
                                    <TPAutoComplete
                                        containerStyle={{ display: "flex", justifyContent: "center", aligItems: "center" }}
                                        isMandatory={true}
                                        labelText={""}
                                        onValueChange={(e: any) => { onValueChangeTPAutoComplete(e); }}
                                        onSearch={(event: any) => { handleToOnAutocompleteQuery(event); }} // handleToOnAutocompleteQuery(event)
                                        isLoading={false}
                                        options={autocompleteToOptions}
                                        withIcon={true}
                                        emptyLabel={""}
                                        onKeyDown={
                                            (event: any) => {
                                                handleOnAutocompleteKeyDown(event)
                                            }
                                        }
                                        selected={
                                            selectedAutocompleteTaskTypeOption
                                        }
                                        errorMessage={erroNewResponsable}
                                        downArrowClick={handleToOnAutocompleteQuery}
                                    />
                                </div>
                                <TPButton
                                    id="IdButton"
                                    type={TPButtonTypes.empty}
                                    icon={TPIconTypes.custom}
                                    onClick={() => {

                                        if (activeSave)
                                            reassignCaseInGroup();
                                        else {
                                            setErroNewResponsable("");

                                            if (currenCaseManagerKey == "")
                                                setErroNewResponsable(locationGlobal.require);

                                        }
                                    }}
                                    style={(currenCaseManagerKey == "") ?
                                        { backgroundColor: "#80808085", color: "white", height: "35px" }
                                        :
                                        { backgroundColor: "#a00095", color: "white", height: "35px" }}
                                >
                                    {location.btnReassignment}
                                </TPButton>
                                <br />
                            </div>
                            <div className="row">
                                <div className="col">
                                    <DynamicTable
                                        id="Group_stats_case"
                                        hideAllTableOnNoData
                                        data={filteredData().map(row => {
                                            return {
                                                selected: row.selected,
                                                stateId: row.stateId,
                                                customStateId: (row.customStateId != null ? row.customStateId : ""),
                                                caseId: row.caseId,
                                                promiseDate: row.promiseDate,
                                                term: row.term,
                                                responsibleFullName: row.responsibleFullName,
                                                dateWriterRead: row.dateWriterRead ? row.dateWriterRead.split("T")[0] : "",
                                                timeLabor: row.timeLabor,
                                                taskTypeDescription: row.taskTypeDescription ? row.taskTypeDescription : "",
                                                workingTaskTime: row.workingTaskTime
                                            }
                                        })}
                                        hiddenColumns={['selected', 'dateWriterRead', 'taskTypeDescription','workingTaskTime']}
                                        selectable
                                        columnNames={{
                                            stateId: location.columnStatus?.toUpperCase(),
                                            customStateId: location.columnCustomState?.toUpperCase(),
                                            caseId: location.columnCaseNumber?.toUpperCase(),
                                            promiseDate: location.columnPromiseDate?.toUpperCase(),
                                            term: location.columnTerm?.toUpperCase(),
                                            responsibleFullName: location.columnResponsible?.toUpperCase(),
                                            dateWriterRead: location.columnCaseHandingStarted?.toUpperCase(),
                                            timeLabor: location.columnCaseHandingTime?.toUpperCase(),
                                            taskTypeDescription: location.columnCurrentTask?.toUpperCase(),
                                            workingTaskTime: location.columnTimeInCurrentTask?.toUpperCase()
                                        }}
                                        columnStyles={{
                                            stateId: (row) => (
                                                <>
                                                    {handleStatus(row.item.stateId)}
                                                </>
                                            ),
                                            caseId: row => (
                                                <button
                                                    type="button"
                                                    style={{ border: 'none', background: 'none', color: 'purple' }}
                                                    onClick={() => callBackCommands(row.item.caseId)}
                                                >
                                                    <b><u>{row.item.caseId}</u></b>
                                                </button>
                                            ),
                                            promiseDate: row => (
                                                <span>{row.value}</span>
                                            ),                                           
                                            timeLabor: row => (
                                                <span>{handleFormatTerm(row.value)}</span>
                                            )
                                            , term: row => (
                                                <span>{handleFormatTerm(row.value)}</span>
                                            ),
                                            workingTaskTime: row => (
                                                <span>{handleFormatTerm(row.value)}</span>
                                            )


                                        }}
                                        onSelectionChange={(selectedRows) =>
                                            dispatchCommand({
                                                type: commandsEnum.row_select,
                                                payload: selectedRows,
                                            })
                                        }
                                        searchPosition="right"
                                        onIconClicked={(icon) => {
                                            if (icon == TPIconTypes.refresh || icon == TPIconTypes.loop)
                                                loadData(groupSelected.key);
                                        }}
                                      
                                    />
                                </div>
                            </div>
                        </div >
                    </TPLoadingOverlay>}
            </>
        );
    }
);

export default GroupDashboardTable;
