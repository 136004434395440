import React, { useEffect, useMemo, useRef, useState } from "react";
import TPVerticalTabs from "@/components/TPTabs/TPVerticalTabs";
import useTranslations from "../hooks/useTranslations";
import { useConfiguration } from "../context/ConfigurationContext";
import TPDescription from "@/components/bootstrap/forms/TPDescription/TPDescription";
import FileUploader from "@/components/TPDragAndDropUploadFile/FileUploader";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { TPFieldContainer } from "../common/TPFieldContainer/TPFieldContainer";
import LanguageService from "@/services/LanguageService";
import {
  TPButtonTypes,
  TPLanguageFilterEnum,
} from "@/models/Global/TPGlobalEnums";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import "./../styles/CustomizationsStyle.css";
import { AiFillCheckCircle, AiOutlineCheckCircle } from "react-icons/ai";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";

const CustomizationsComponent: React.FC = () => {
  const RESOURCE_SET = "ConfigurationGeneralComponent";
  const COMPONENT_NAME = "CustomizationsComponent.tsx";

  const initialText = useMemo(
    () => ({
      logoTitle: "LogoTitle",
      languagesTitle: "LanguagesTitle",
      weekDateTitle: "WeekDateTitle",
      dropFilesHereLabel: "DropFilesHereLabel",
      dropFilesHereSubLabel: "DropFilesHereSubLabel",
      uploadFileButtonLabel: "UploadFileButtonLabel",
      currentLogoDescription: "CurrentLogoDescription",
      languagesDescription: "LanguagesDescription",
      weekDateDescription: "WeekDateDescription",
      firstDayOfWeekLabel: "FirstDayOfWeekLabel",
      DateFormatLabel: "DateFormatLabel",
      DateTimeFormatLabel: "DateTimeFormatLabel",
      completedStatus: "CompletedStatus",
      logoPreview: "LogoPreview",
      defaultLanguageLabel: "DefaultLanguageLabel",
      saveButton: "SaveButton",
    }),
    []
  );

  const translationsText = useTranslations(
    initialText,
    RESOURCE_SET,
    COMPONENT_NAME
  );

  const tabLabels = useMemo(
    () => [
      translationsText.logoTitle,
      translationsText.languagesTitle,
      translationsText.weekDateTitle,
    ],
    [translationsText]
  );

  const tabContent = useMemo(
    () => [
      <LogoUploadConfiguration
        key="logo"
        translationsText={translationsText}
      />,
      <LanguagesConfiguration
        key="languages"
        translationsText={translationsText}
      />,
      <WeekDateConfiguration
        key="weekDate"
        translationsText={translationsText}
      />,
    ],
    [translationsText]
  );

  return <TPVerticalTabs tabLabels={tabLabels} tabContent={tabContent} />;
};

export default CustomizationsComponent;

const LogoUploadConfiguration: React.FC<{ translationsText: any }> = ({
  translationsText,
}) => {
  const { keyValues, someChange, handleSave, changeValueForKey } =
    useConfiguration();

  const [fileName, setFileName] = useState<string>("");
  const [base64String, setBase64String] = useState<string>("");

  const handleUploadFilesDraggedChange = (filesList: any) => {
    if (filesList.length > 0) {
      const file = filesList[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (reader.result) {
          setBase64String(
            reader.result.toString().replace("data:", "").replace(/^.+,/, "")
          );
        }
      };
      setFileName(filesList[0].name);
    } else if (Array.isArray(filesList)) {
      setFileName("");
      setBase64String("");
      changeValueForKey("companyLogo", "");
    }
  };

  useEffect(() => {
    if (!!base64String) changeValueForKey("companyLogo", base64String);
  }, [base64String]);

  return (
    <div className="col">
      <TPDescription text={translationsText.currentLogoDescription} />

      <FileUploader
        label={
          <div>
            <p style={{ margin: 0 }}>
              {!!base64String ? fileName : translationsText.dropFilesHereLabel}
            </p>
            <small
              style={{
                fontSize: "14px",
                color: !!base64String ? "#009a58" : "#999",
                fontWeight: !!base64String ? "bold" : "300",
              }}
            >
              {!!base64String
                ? translationsText.completedStatus
                : translationsText.dropFilesHereSubLabel}
            </small>
          </div>
        }
        multiple={true}
        handleChange={handleUploadFilesDraggedChange}
        UploadButtonText={translationsText.uploadFileButtonLabel}
        maxSize={500}
        isSelected={!!base64String}
        canRemove
        types={["jpg", "png", "jpeg"]}
      />

      <div className="logo-preview">
        <strong>{translationsText.logoPreview}</strong>

        {keyValues["companyLogo"]?.value && !base64String && (
          <img
            src={"data:image/png;base64, " + keyValues["companyLogo"]?.value}
            alt="Uploaded Logo"
            className="preview-image"
          />
        )}

        {base64String && (
          <img
            src={`data:image/png;base64, ${base64String}`}
            alt="Uploaded Logo"
            className="preview-image"
          />
        )}
      </div>

      <TPButton
        id="IdButton"
        onClick={handleSave}
        type={TPButtonTypes.primary}
        className="fixed-save-button"
        disabled={!someChange}
        style={
          !someChange
            ? {
                backgroundColor: "#e6e6e6",
                borderColor: "#efefef",
                color: "#989898",
                cursor: "not-allowed",
              }
            : {}
        }
      >
        {translationsText.saveButton}
      </TPButton>
    </div>
  );
};

const LanguagesConfiguration: React.FC<{ translationsText: any }> = ({
  translationsText,
}) => {
  const { someChange, keyValues, errorState, changeValueForKey, handleSave } =
    useConfiguration();
  const [languages, setLanguages] = useState<TPKeyValue[]>([]);

  useEffect(() => {
    getLanguages();
  }, []);

  const getLanguages = async () => {
    const languageClient = new LanguageService();
    const expectedCodes: Array<number> = [200, 404];
    const response = await languageClient.getLanguageByFilter(
      TPLanguageFilterEnum.ConfiguredForTPClientUI,
      false,
      true,
      expectedCodes
    );

    const languagesData = response.responseData.data;
    const languagesToSelectConverted: Array<TPKeyValue> = languagesData.map(
      (k: any) => ({ key: k.id, value: k.name })
    );
    languagesToSelectConverted.unshift({ key: "", value: "--" });
    setLanguages(languagesToSelectConverted);
  };

  return (
    <div className="col">
      <TPDescription text={translationsText.languagesDescription} />

      <TPFieldContainer>
        <TPSelect
          id="IdSelect"
          labelText={translationsText.defaultLanguageLabel}
          isMandatory={true}
          value={keyValues["defaultLanguage"]?.value || ""}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            changeValueForKey("defaultLanguage", e.target.value)
          }
          errorMessage={errorState.defaultLanguageErrorMessage}
          dataSource={languages}
        />
      </TPFieldContainer>

      <TPButton
        id="IdButton"
        onClick={handleSave}
        type={TPButtonTypes.primary}
        className="fixed-save-button"
        disabled={!someChange}
        style={
          !someChange
            ? {
                backgroundColor: "#e6e6e6",
                borderColor: "#efefef",
                color: "#989898",
                cursor: "not-allowed",
              }
            : {}
        }
      >
        {translationsText.saveButton}
      </TPButton>
    </div>
  );
};

const WeekDateConfiguration: React.FC<{ translationsText: any }> = ({
  translationsText,
}) => {
  const { keyValues, errorState, someChange, handleSave, changeValueForKey } =
    useConfiguration();

  return (
    <div className="col">
      <TPDescription text={translationsText.weekDateDescription} />

      <TPFieldContainer>
        <TPSelect
          id="IdSelect"
          labelText={translationsText.DateFormatLabel}
          isMandatory={true}
          value={keyValues["dateFormat"]?.value}
          onChange={(e: any) => changeValueForKey("dateFormat", e.target.value)}
          errorMessage={errorState.dateFormatErrorMessage}
          dataSource={[
            { key: "", value: "..." },
            {
              key: "yyyy/MM/dd",
              value: "yyyy/MM/dd",
            },
            {
              key: "MM/dd/yyyy",
              value: "MM/dd/yyyy",
            },
            {
              key: "dd/MM/yyyy",
              value: "dd/MM/yyyy",
            },
          ]}
        />
      </TPFieldContainer>

      <TPFieldContainer>
        <TPSelect
          id="IdSelect"
          labelText={
            translationsText.DateTimeFormatLabel
          }
          isMandatory={true}
          value={keyValues["dateTimeFormat"]?.value}
          onChange={(e: any) =>
            changeValueForKey("dateTimeFormat", e.target.value)
          }
          errorMessage={errorState.dateTimeFormatErrorMessage}
          dataSource={[
            { key: "", value: "..." },
            {
              key: "yyyy/MM/dd HH:mm:ss",
              value: "yyyy/MM/dd HH:mm:ss",
            },
            {
              key: "MM/dd/yyyy HH:mm:ss",
              value: "MM/dd/yyyy HH:mm:ss",
            },
            {
              key: "dd/MM/yyyy HH:mm:ss",
              value: "dd/MM/yyyy HH:mm:ss",
            },
          ]}
        />
      </TPFieldContainer>

      <TPFieldContainer>
        <TPSelect
          id="IdSelect"
          labelText={translationsText.firstDayOfWeekLabel}
          isMandatory={true}
          value={keyValues["getFirstDayOfWeek"]?.value}
          onChange={(e: any) =>
            changeValueForKey("getFirstDayOfWeek", e.target.value)
          }
          errorMessage={errorState.getFirstDayOfWeekErrorMessage}
          dataSource={[
            { key: "", value: "..." },
            {
              key: "0",
              value: "Sunday",
            },
            {
              key: "1",
              value: "Monday",
            },
          ]}
        />
      </TPFieldContainer>

      <TPButton
        id="IdButton"
        onClick={handleSave}
        type={TPButtonTypes.primary}
        className="fixed-save-button"
        disabled={!someChange}
        style={
          !someChange
            ? {
                backgroundColor: "#e6e6e6",
                borderColor: "#efefef",
                color: "#989898",
                cursor: "not-allowed",
              }
            : {}
        }
      >
        {translationsText.saveButton}
      </TPButton>
    </div>
  );
};
