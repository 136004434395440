import allThemes from "@/assets/styles/theme";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { ClientAdminViewModel } from "@/models/Client/ClientAdminViewModel";
import { ClientBasicViewModel } from "@/models/Client/ClientBasicViewModel";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { ClientService } from "@/services/ClientService";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCanMerge,
  setMainCustomerData,
  setMergeCustomerData,
} from "../redux/MergeCustomersSlice";
import { MergeCustomersState } from "../redux/MergeCustomersStore";
import useLabels from "../styles/labels";
import "../styles/Styles.css";
import CustomerInfoTabs from "./CustomerInfoTabs";

interface CustomerSearchAccordionProperties {
  title: string;
  id: string;
  type: "main" | "merge";
  dataCallback?: () => ClientAdminViewModel;
}

enum FilterOptions {
  Document = "document",
  Name = "name",
  Phone = "phone",
  Email = "email",
  InternalCode = "internal_code",
  AdditionalData = "additional_data",
}

const CustomerSearchAccordion: FC<CustomerSearchAccordionProperties> =
  function ({ type, dataCallback, id, title }) {
    const dispatch = useDispatch();
    const { labels, labelsLoaded } = useLabels();
    const { mainCustomerData, mergeCustomerData } = useSelector(
      (state: MergeCustomersState) => state.mainData
    );
    const [loadingContent, setLoadingContent] = useState<boolean>(false);
    const [tabsVisible, setTabsVisible] = useState<boolean>(false);

    const [currentQuery, setCurrentQuery] = useState<string>("");
    const [currentFilter, setCurrentFilter] = useState<string>(
      FilterOptions.Document
    );
    const [querySubmitted, setQuerySubmitted] = useState<boolean>(false);
    const [customersData, setCustomersData] = useState<ClientBasicViewModel[]>(
      []
    );
    const [resultQuery, setResultQuery] = useState<string>("");
    const [filteredCustomerData, setFilteredCustomerData] = useState<
      ClientBasicViewModel[]
    >([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const [filterSelectOptions, setFilterSelectOption] = useState<TPKeyValue[]>(
      []
    );

    useEffect(() => {
      if (labelsLoaded)
        setFilterSelectOption([
          { key: FilterOptions.Document, value: labels["document"] },
          { key: FilterOptions.Name, value: labels["name"] },
          { key: FilterOptions.Phone, value: labels["preferredPhone"] },
          { key: FilterOptions.Email, value: labels["preferredEmail"] },
          { key: FilterOptions.InternalCode, value: labels["internalCode"] },
        ]);
    }, [labels]);

    useEffect(() => {
      if (currentPage > filteredCustomerData.length / rowsPerPage)
        setCurrentPage(1);
    }, [rowsPerPage]);

    const searchCustomers = async function (): Promise<void> {
      setLoadingContent(true);
      setTabsVisible(false);
      setQuerySubmitted(true);
      dispatch(setCanMerge(false));
      if (currentQuery.trim().length > 0) {
        const clientServiceInstance = new ClientService();
        switch (currentFilter) {
          case FilterOptions.Document:
            clientServiceInstance
              .getClientByDocumentBasic(currentQuery.trim(), false, false, [
                200,
              ])
              .then((response) => {
                setCustomersData(response);
                setLoadingContent(false);
              })
              .catch((error) => console.warn(error))
              .finally(() => setLoadingContent(false));
            break;
          case FilterOptions.Name:
            clientServiceInstance
              .getClientByNamesBasic(currentQuery.trim(), false, false, [200])
              .then((response) => {
                setCustomersData(response);
                setLoadingContent(false);
              })
              .catch((error) => console.warn(error))
              .finally(() => setLoadingContent(false));
            break;
          case FilterOptions.Email:
            clientServiceInstance
              .getClientByEmailBasic(currentQuery.trim(), false, false, [200])
              .then((response) => {
                setCustomersData(response);
                setLoadingContent(false);
              })
              .catch((error) => console.warn(error))
              .finally(() => setLoadingContent(false));
            break;
          case FilterOptions.Phone:
            clientServiceInstance
              .getClientByPhoneBasic(currentQuery.trim(), false, false, [200])
              .then((response) => {
                setCustomersData(response);
                setLoadingContent(false);
              })
              .catch((error) => console.warn(error))
              .finally(() => setLoadingContent(false));
            break;
          case FilterOptions.InternalCode:
            clientServiceInstance
              .getClientByInternalCodeBasic(currentQuery.trim(), false, false, [
                200,
              ])
              .then((response) => {
                setCustomersData(response);
                setLoadingContent(false);
              })
              .catch((error) => console.warn(error))
              .finally(() => setLoadingContent(false));
            break;
        }
      }
    };

    useEffect(() => {
      setFilteredCustomerData(customersData);
    }, [customersData]);

    useEffect(() => {
      if (resultQuery.trim().length > 0) {
        const lowercaseQuery = resultQuery.toLowerCase();
        setFilteredCustomerData([
          ...customersData.filter(
            (data) =>
              data.document.toLowerCase().includes(lowercaseQuery) ||
              data.name.toLowerCase().includes(lowercaseQuery) ||
              data.clientEmail.toLowerCase().includes(lowercaseQuery) ||
              data.clientPhone.toLowerCase().includes(lowercaseQuery) ||
              data.internalCode.toLowerCase().includes(lowercaseQuery) ||
              data.documentType.toLowerCase().includes(lowercaseQuery)
          ),
        ]);
      } else setFilteredCustomerData([...customersData]);
    }, [resultQuery]);

    console.log(filteredCustomerData);

    return (
      <TPLoadingOverlay active={loadingContent}>
        <div id={`${type}-search-accordion`} className="accordion">
          <div className="accordion-item">
            <div className="accordion-header">
              <button
                className="accordion-button"
                style={{ border: "none", backgroundColor: "#F4F4F4" }}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#${id}-customer-search`}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <TPIcon
                    iconType={TPIconTypes.person}
                    style={{ color: allThemes.base.primary }}
                  />
                  {title}
                </div>
              </button>
            </div>
          </div>
          <div
            id={`${id}-customer-search`}
            className="accordion-collapse collapse show"
          >
            <div className="accordion-body">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                
                <div className="container">
                  <div className="row">
                    <div className="col-4">
                      <TPSelect
                        onChange={(event: any) =>
                          setCurrentFilter(event.target.value)
                        }
                        dataSource={filterSelectOptions}
                        value={currentFilter}
                        labelText={labels.SearchBy}
                        minWidth={256}
                      />
                    </div>
                    <div className="col-3 mt-4">
                      <TPTextBox
                        onChange={(event: any) =>
                          setCurrentQuery(event.target.value)
                        }
                        value={currentQuery}
                        withIcon
                        icon={TPIconTypes.search}
                        placeholder={labels.Search}
                        containerStyle={{ width: "256px" }}
                      />
                    </div>
                    <div className="col-5 mt-4">
                      <TPButton
                        onClick={() => searchCustomers()}
                        type={TPButtonTypes.primary}
                        disabled={currentQuery.trim().length == 0}
                        isDesignSystem
                        style={{
                          paddingLeft: "16px",
                          paddingRight: "16px",
                          height: "35px",
                        }}
                      >
                        {labels.Search}
                      </TPButton>
                    </div>
                  </div>
                </div>
                {querySubmitted && !tabsVisible && (
                  <>
                    <DynamicTable
                      data={filteredCustomerData.map((item) => {
                        return {
                          id: item.id,
                          internalCode: item.internalCode,
                          documentTypeDescription: item.documentTypeDescription,
                          document: item.document,
                          name: item.name,
                          lastName: item.lastName,
                          customerTypeDescription: item.customerTypeDescription,
                          clientPhone: item.clientPhone,
                          clientEmail: item.clientEmail,
                          isActive: item.isActive,
                          subsidiaryId: item.subsidiaryId,
                          subsidiaryName: item.subsidiaryName,
                          subsidiaryOrganizationId:
                            item.subsidiaryOrganizationId,
                          geography: item.geography,
                          customerTypeId: item.customerTypeId,
                          thumbnail: item.thumbnail,
                          documentType: item.documentType,
                        } as ClientBasicViewModel;
                      })}
                      hiddenColumns={[
                        "isActive",
                        "subsidiaryId",
                        "subsidiaryName",
                        "subsidiaryOrganizationId",
                        "geography",
                        "customerTypeId",
                        "thumbnail",
                        "documentType",
                      ]}
                      columnStyles={{
                        id: (entry) => (
                          <button
                            type="button"
                            style={{
                              border: "none",
                              background: "none",
                              color: allThemes.base.primary,
                            }}
                            onClick={() => {
                              setLoadingContent(true);
                              const clientServiceInstance = new ClientService();
                              clientServiceInstance
                                .getClientBySubsidiaryOrganizationId(
                                  entry.item.subsidiaryOrganizationId,
                                  false,
                                  false,
                                  [200]
                                )
                                .then((response) => {
                                  type == "main"
                                    ? dispatch(setMainCustomerData(response[0]))
                                    : dispatch(
                                        setMergeCustomerData(response[0])
                                      );
                                  setLoadingContent(false);
                                  setTabsVisible(true);
                                })
                                .catch((error) => console.error(error));
                            }}
                          >
                            <b>
                              <u>{entry.item.id}</u>
                            </b>
                          </button>
                        ),
                        customerTypeDescription: (entry) => (
                          <div>{entry.item.customerTypeDescription || ""}</div>
                        ),
                        document: (entry) => (
                          <div>{entry.item.document || ""}</div>
                        ),
                        documentTypeDescription: (entry) => (
                          <div>{entry.item.documentTypeDescription || ""}</div>
                        ),
                        name: (entry) => <div>{entry.item.name || ""}</div>,
                        lastName: (entry) => (
                          <div>{entry.item.lastName || ""}</div>
                        ),
                        clientEmail: (entry) => (
                          <div>{entry.item.clientEmail || ""}</div>
                        ),
                        clientPhone: (entry) => (
                          <div>{entry.item.clientPhone || ""}</div>
                        ),
                        internalCode: (entry) => (
                          <div>{entry.item.internalCode || ""}</div>
                        ),
                      }}
                      onIconClicked={(icon) => {
                        if (
                          icon == TPIconTypes.loop ||
                          icon == TPIconTypes.refresh
                        )
                          searchCustomers();
                      }}
                      columnNames={{
                        id: labels["id"]?.toUpperCase(),
                        internalCode: labels["internalCode"]?.toUpperCase(),
                        clientEmail: labels["preferredEmail"]?.toUpperCase(),
                        clientPhone: labels["preferredPhone"]?.toUpperCase(),
                        customerTypeDescription:
                          labels["customerTypeDescription"]?.toUpperCase(),
                        document: labels["document"]?.toUpperCase(),
                        documentTypeDescription:
                          labels["documentType"]?.toUpperCase(),
                        lastName: labels["lastName"]?.toUpperCase(),
                        name: labels["name"]?.toUpperCase(),
                      }}
                      exportNameFile={"Merge Customer"}
                    />
                  </>
                )}
                {querySubmitted && tabsVisible && (
                  <CustomerInfoTabs
                    customer={
                      type == "main" ? mainCustomerData : mergeCustomerData
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </TPLoadingOverlay>
    );
  };

export default CustomerSearchAccordion;
