import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import {
  TaskTypeAdditionalDataModel,
  TaskTypeAdditionalDataType,
} from "@/models/TaskType/TaskTypeAdditionalDataModel";
import { AdditionalDataFormService } from "@/services/AdditionalDataFormService";
import { TaskTypeService } from "@/services/TaskTypeService";
import {
  Dispatch,
  SetStateAction,
  CSSProperties,
  useState,
  useEffect,
} from "react";
import {TPI18N} from "@/services/I18nService";
import TPGlobal from "@/helpers/TPGlobal";

interface AdditionalDataRelateAdminFormModalProps {
  showAdditionalFormModal: boolean;
  setShowAdditionalFormModal: Dispatch<SetStateAction<boolean>>;
  taskTypeId: string;
  loadDataSource: () => void;
  handlePreview: (preview: any) => void;
}

const styles = {
  modal: {
    padding: "30px",
    gap: "20px",
    overflow: 'visible',
    position: 'relative',
  } as CSSProperties,
  sectionTitle: {
    display: "flex",
    flexDirection: "row",
  } as CSSProperties,
  sectionContent: {} as CSSProperties,
  sectionAction: {
    display: "flex",
    flexDirection: "row",
    placeContent: "flex-end",
    gap: "10px",
  } as CSSProperties,
  button: {
    padding: "1px 18px",
  } as CSSProperties,
};

export default function AdditionalDataRelateAdminFormModal({
  showAdditionalFormModal,
  setShowAdditionalFormModal,
  taskTypeId,
  loadDataSource,
  handlePreview,
}: AdditionalDataRelateAdminFormModalProps) {

  const resourceSet: string = "AdditionalDataRelateAdminFormModal";
  /**                          AdditionalDataRelateAdminFormModal
   * input auto complete
   */
  const [autoInput, setAutoInput] = useState<Array<TPKeyValue>>([]);
  /**
   * input auto complete
   */
  const [options, setOptions] = useState<Array<TPKeyValue>>([]);
  const [newFormTitleLabel, setNewFormTitleLabel] = useState("");
  const [formDesignTitleLabel, setFormDesignTitleLabel] = useState("");
  const [saveLabel, setSaveLabel] = useState("");
  const [cancelLabel, setCancelLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");


  const loadResourcesAndFilter = async () => {
    setNewFormTitleLabel(
        await TPI18N.GetText(resourceSet, "NewFormTitleLabel")
    )
    setFormDesignTitleLabel(
        await TPI18N.GetText(resourceSet, "FormDesignTitleLabel")
    )
    setSaveLabel(
        await TPI18N.GetText(resourceSet, "SaveLabel")
    )
    setCancelLabel(
        await TPI18N.GetText(resourceSet, "CancelLabel")
    )
    setEmptyLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel")
    )
  }

  //Run only once to load resources and active filters
  useEffect(() => {
    loadResourcesAndFilter()
  }, []);

  useEffect(() => {
    if (showAdditionalFormModal) {
      getAdditionalDataForm();
    } else {
      clean();
    }
  }, [showAdditionalFormModal]);
  /**
   * clean
   */
  function clean() {
    setAutoInput([]);
    setOptions([]);
  }
  /**
   * get forms
   */
  function getAdditionalDataForm() {
    const { getByFiltersIsActive } = new AdditionalDataFormService();

    getByFiltersIsActive("1", "S_USERSADD", false, true, [200, 404])
      .then((response) =>
        setOptions(
          response.map((item) => ({ ...item, key: item.id, value: item.description }))
        )
      )
      .catch((error) => console.error(error));
  }
  function handleOnClickPreview() {
    setShowAdditionalFormModal(false);
    const item: any = autoInput[0];
    handlePreview({
      type: TaskTypeAdditionalDataType.FORM,
      dataSources: [
        {
          id: item.id,
          type: TaskTypeAdditionalDataType.FORM,
          description: item.description,
          order: null,
          isMandatory: null,
          json: (item as any)?.jsonDesigner
            ? JSON.parse((item as any)?.jsonDesigner)
            : null,
        } as TaskTypeAdditionalDataModel,
      ],
    });
  }
  function handleCancel() {
    setShowAdditionalFormModal(false);
  }
  function handleSave() {
    const { saveAdditionalForm } = new TaskTypeService();

    saveAdditionalForm(taskTypeId, autoInput[0].key)
      .then(() => {
        loadDataSource();
        setShowAdditionalFormModal(false);
      })
      .catch((error) => console.error(error));
  }
  /**
   * RENDER
   */
  return (
    <>
      {showAdditionalFormModal && (
        <div
          className={showAdditionalFormModal ? "modal show" : "modal"}
          tabIndex={-1}
          data-bs-backdrop="static"
          style={
            showAdditionalFormModal ? { display: "block" } : { display: "none" }
          }
        >
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content" style={styles.modal}>
              <div style={styles.sectionTitle}>
                <h5 className="modal-title">{newFormTitleLabel}</h5>
              </div>

              <div style={styles.sectionContent }>
                <TPAutoComplete
                  id="AdditionalDataRelateAdminFormModalInputAutoComplete"
                  labelText={formDesignTitleLabel}
                  onValueChange={(value: Array<TPKeyValue>) =>
                    setAutoInput(value)
                  }
                  onSearch={(query: string) => options}
                  isLoading={false}
                  options={options}
                  withIcon={true}
                  emptyLabel={emptyLabel}
                  onKeyDown={(event: any) => {}}
                  selected={autoInput}
                  downArrowClick={() => {}}
                />
              </div>

              <div style={styles.sectionAction}>
                <TPButton
                  customType={ButtonCustomType.secondary}
                  onClick={handleCancel}
                  style={styles.button}
                  isDesignSystem
                >
                  {cancelLabel}
                </TPButton>

                <TPButton
                  onClick={handleSave}
                  style={styles.button}
                  isDesignSystem
                >
                  {saveLabel}
                </TPButton>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={showAdditionalFormModal ? "modal-backdrop show" : ""}
      ></div>
    </>
  );
}
