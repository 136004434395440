import { LocalizedTextValue } from "../Global/TPGlobalEnums"

export enum CaseToolbarItemType {
  MODAL = "MODAL",
}

export interface CaseToolbarProfile {
  id_PROF: string
  id_CTBI: string
  order_PCTI: number
}

export interface CaseToolbarItem {
  id: string
  description: string
  type: string
  urlLink: string
  image: string
  isActive: boolean
  localizedDescription: string
  localizedDescriptions: LocalizedTextValue[]
  profiles: CaseToolbarProfile[]
  order: number
}

export interface CaseToolbarItemRequest {
  id: string
  description: string
  type: string
  urlLink: string
  image: string
  isActive: boolean
  descriptionLocalizedValues: LocalizedTextValue[]
  profiles: CaseToolbarProfile[]
}

export interface CaseToolbarListItem extends Omit<CaseToolbarItem, 'profiles'> {
  [profile: string]: any;
}

export interface CaseToolbarUserItemResponse {
  id_CTBI: string
  description_CTBI: string
  localizedDescription: string
  type_CTBI: string
  urlLink_CTBI: string
  image_CTBI: string
  order_PCTI: string
}

export interface CaseToolbarItemOrder {
  id_CTBI: string;
  order_PCTI: number;
}

export interface CaseToolbarReorderModel {
  id_PROF: string;
  jsonOrders: Array<{ id_CTBI: string, order_PCTI: string }>
}