import { TPDragAndDropSectionStyled } from "@/pages/InboundMailboxes/InboundMailboxesAssignRulesStyles";
import { FC } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

interface TPReorderableListDraggable {
  id: string,
  content: string | React.ReactElement
}

interface TPReorderableListProperties {
  data: Array<TPReorderableListDraggable>;
  onDragEnd?: Function;
  style?: any;
  headers?: { label: string, width: string | number, centered: boolean }[];
  enabled?: boolean;
  width?: string | number;
  gap?: number;
}

const grid = 8;

const TPReorderableList: FC<TPReorderableListProperties> = function ({ data, onDragEnd, style, headers, enabled=true, width }) {

  const handleOnDragEnd = function (result: DropResult) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (onDragEnd) onDragEnd(result.source.index, result.destination.index);
  }

  return (
    <div style={{width: width}}>
      {headers && (
        <div
        className="tp-reorderable-list-header"
        style={{ borderBottom: '2px solid #c0c0c0', width: '100%', display: 'flex', flexDirection: 'row' }}
      >
        {headers?.map(header => (
          <h6
            style={{
              width: header.width,
              display: 'flex',
              justifyContent: header.centered ? 'center' : 'flex-start',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            <b>{header.label}</b>
          </h6>
        ))}
      </div>)}
      <DragDropContext  onDragEnd={(e) => handleOnDragEnd(e)} >
        <TPDragAndDropSectionStyled>
          <div className="row mx-0">
            <Droppable isDropDisabled={!enabled} droppableId="tp-reorderable-list">
              {(provided, snapshot) => (
                <div
                  className="tp-reorderable-list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={style}
                >
                  {data.map((item, index) => {
                    return typeof item === 'string' ?
                      String(item).length > 0 &&
                      <Draggable isDragDisabled={!enabled} key={item} draggableId={item} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            item
                          </div>
                        )}
                      </Draggable>
                      :
                      <Draggable isDragDisabled={!enabled} key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {item.content}
                          </div>
                        )}
                      </Draggable>
                  }
                  )}
                </div>
              )}
            </Droppable>
          </div>
        </TPDragAndDropSectionStyled>
      </DragDropContext>
    </div>
  )
}

export default TPReorderableList;