import { CSSProperties } from "react";

/**
 * SPECIFIC COMPONENT MODELS
 */
/**
 * multilingual text box messages
 */
export interface MultilingualTextBoxMessages {
  /**
   * input label
   */
  inputLabel?: string;
  /**
   * error input label
   */
  errorInputLabel?: string;
  /**
   * icon button tooltip label
   */
  iconButtonTooltipLabel?: string;
  /**
   * modal title label
   */
  modalTitleLabel?: string;
  /**
   * modal ok button label
   */
  modalOkButtonLabel?: string;
  /**
   * modal cancel button label
   */
  modalCancelButtonLabel?: string;
}
/**
 * multilingual text box type
 */
export enum MultilingualTextBoxTypes {
  /**
   * Prioritizes the language with which you logged in
   */
  TENANT = "TENANT",
  /**
   * prioritizes the language configured in the Modules
   *
   * This is the default value
   */
  MODULE = "MODULE",
}
/**
 * multilingual text box props
 */
export interface MultilingualTextBoxProps {
  /**
   * identifier
   */
  id?: string;
  /**
   * key
   */
  key?: string;
  /**
   * style
   */
  style?: CSSProperties;
  /**
   * children of the component
   */
  children?: any;
  /**
   * messages
   */
  labels?: MultilingualTextBoxMessages;
  /**
   * value
   */
  value?: Array<LanguageModel> | null;
  /**
   * max length
   */
  maxLength?: number;
  /**
   * type
   */
  type?: MultilingualTextBoxTypes;
  /**
   * required languages
   * Example: ["en", "es", "fr"]
   */
  requiredLanguages?: Array<string>;
  /**
   * onChange event
   * @param value list of languages ​​with their entered value
   */
  onChange?: (value: Array<LanguageModel>) => void;
  onChangeLanguages?: (value: Array<LanguageModel>) => void;
  optional?: boolean;
}
/**
 * multilingual text box events
 */
export interface MultilingualTextBoxEvents {
  /**
   * get current value
   * @returns list of languages ​​with their entered value
   */
  getValue: () => Array<LanguageModel>;
  /**
   * set value
   * @param value list of languages ​​with their entered value
   */
  setValue: (value: Array<LanguageModel>) => void;
  /**
   * is invalid
   */
  isInvalid: () => boolean;
  /**
   * mark as touched
   */
  markAsTouched: () => void;
}
/**
 * language model
 */
export interface LanguageModel {
  /**
   * identifier
   */
  id: string;
  /**
   * value
   */
  value: string | null;
}
/**
 * multilingual text box state
 */
export interface LanguageGroupValues<T = string> {
  /**
   *
   */
  [key: string]: T | null;
}
/**
 * multilingual text box state
 */
export interface MultilingualTextBoxState {
  /**
   * messages
   */
  messages: MultilingualTextBoxMessages;
  /**
   * value
   */
  values: LanguageGroupValues;
  /**
   * state
   */
  stateValues: LanguageGroupValues;
  /**
   * required
   */
  required: LanguageGroupValues<boolean>;
  /**
   * index
   */
  index: LanguageGroupValues<number>;
}
/**
 *
 */
export enum MultilingualTextBoxActionType {
  /**
   * set value
   */
  setValues = "multilingualTextBox/setValues",
  /**
   * 
   */
  setMessages = "multilingualTextBox/setMessages",
  /**
   * 
   */
  markAsTouched = "multilingualTextBox/markAsTouched",
  /**
   * 
   */
  changeValue = "multilingualTextBox/changeValue",
  /**
   * 
   */
  setStateValues = "multilingualTextBox/setStateValues",
  /**
   * 
   */
  cleanStateValues = "multilingualTextBox/cleanStateValues",
  /**
   * clean
   */
  clean = "multilingualTextBox/clean",
}
/**
 * multilingual text box action
 */
export interface MultilingualTextBoxAction {
  /**
   * type
   */
  type: MultilingualTextBoxActionType;
  /**
   * payload
   */
  payload?: any;
}
export const multilingualTextBoxInitialState = {
  messages: {},
  values: {},
  stateValues: {},
  required: {},
  index: {},
} as MultilingualTextBoxState;
/**
 * SPECIFIC COMPONENT MODELS END
 */
