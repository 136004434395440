import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { ComponentResponse, MenuDefinitionModel, MenuItemType, MenuLevels, MenuOnLevel, MenuTypes } from "@/models/MenuItems/MenuItemsModels";
import React, { FC, useEffect, useState } from "react";
import "./Assets/MenuDefinitionStyles.css";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { LocalizedTextValue, SequenceGeneratorSequencesNameEnum, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPGlobal from "@/helpers/TPGlobal";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import SearchSelect from "@/modules/core/design-system/selects/SearchSelect";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import CockpitRemovableItem from "../SupervisorCockpit/utils/CockpitRemovableItem";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import allThemes from "@/assets/styles/theme";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import { MenuDefinitionSlice, MenuDefinitionSliceModel } from "./Assets/MenuDefinitionSlice";
import { TPLocalizedNamesModal } from "@/components/TPLocalizedNamesModal/TPLocalizedNamesModal";
import { TPIconStyledSelect } from "@/components/TPIconStyledSelect/TPIconStyledSelect";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPCustomAutocomplete from "@/components/TPCustomAutocomplete/TPCustomAutocomplete";
import { MenuDefinitionService } from "@/services/MenuDefinitionService";
import { useMenuDefinitionLabels } from "./Assets/MenuDefinitionLabels";
import { useDispatch } from "react-redux";
import { SequenceService } from "@/services/SequenceService";
import { showToast, TPToastTypes } from "@/components/bootstrap/components/toasts/TPToast";
import { ComponentService } from "@/services/ComponentService";

const COMPONENT_TYPE = "MENUITEMLINK";

interface MenuDefinitionAdminProperties {
  verticalTabCallback: Function;
  menuId: string;
  menuName?: string;
  newMenu?: string;
};

const blankMenu: MenuDefinitionModel = {
  parentId: "",
  icon: "",
  id: "",
  isActive: true,
  itemType: "",
  level: MenuLevels.ITEM,
  name: "",
  localizedNames: [],
  parameters: "",
  profiles: [],
  publicKeyExternalApp: "",
  url: "",
  order: "1"
};

const isFormValid = function (form: MenuDefinitionModel): boolean {
  return !(
    form.id?.trim().length === 0 ||
    form.name?.trim().length === 0 ||
    (form.level !== MenuLevels.SECTION && (
      form.parentId?.trim().length === 0
    )) ||
    (form.level === MenuLevels.ITEM && (
      form.parentId?.trim().length === 0 ||
      form.itemType?.trim().length === 0 ||
      form.url?.trim().length === 0
    )) ||
    form.profiles.length === 0
  );
}

type MenuDefinitionFormModel = {
  [x in keyof MenuDefinitionModel]: (value: MenuDefinitionModel[x]) => void;
}

export const MenuDefinitionMain: FC<MenuDefinitionAdminProperties> = function ({ verticalTabCallback, menuId, menuName, newMenu }) {
  const dispatch = useDispatch();
  const { availableProfiles, menus } = useSelector((s: StoreModel) => s[MenuDefinitionSlice.name]) as MenuDefinitionSliceModel;
  const [selectedProfiles, setSelectedProfiles] = useState<TPKeyValue[]>([]);
  const [isLocalizedNamesModalOpen, setIsLocalizedNamesModalOpen] = useState(false);
  const [availableParents, setAvailableParents] = useState<Array<MenuOnLevel>>([]);
  const [loading, setLoading] = useState(false);
  const { labels } = useMenuDefinitionLabels();
  const [components, setComponents] = useState<Array<ComponentResponse>>([]);
  const [componentsLoading, setComponentsLoading] = useState(false);

  const [mainForm, setMainForm] = useState<MenuDefinitionModel>({
    ...structuredClone(blankMenu),
    localizedNames: TPGlobal.TPClientAvailableLanguages.map(l => ({
      languageId: l.id,
      localizedValue: ""
    } as LocalizedTextValue))
  });

  const handleMainFormChange: MenuDefinitionFormModel = {
    parentId: (newGroup) => setMainForm({ ...mainForm, parentId: newGroup }),
    icon: (newIcon) => setMainForm({ ...mainForm, icon: newIcon }),
    id: (newId) => {
      let newLevel = mainForm.level;
      if (newMenu === MenuTypes.item) newLevel = MenuLevels.ITEM;
      if (newMenu === MenuTypes.group) newLevel = MenuLevels.GROUP;
      if (newMenu === MenuTypes.section) newLevel = MenuLevels.SECTION;
      setMainForm({ ...mainForm, id: newId, level: newLevel });
    },
    isActive: (value) => setMainForm({ ...mainForm, isActive: value }),
    itemType: (newItemType) => setMainForm({ ...mainForm, itemType: newItemType }),
    level: (newLevel) => setMainForm({ ...mainForm, level: newLevel }),
    localizedNames: (newNames) => setMainForm({ ...mainForm, localizedNames: newNames }),
    name: (newName) => setMainForm({
      ...mainForm, name: newName,
      localizedNames: [
        ...mainForm.localizedNames.map(n => {
          return n.languageId === TPGlobal.language ?
            { ...n, localizedValue: newName } as LocalizedTextValue : n
        })
      ]
    }),
    parameters: (newParams) => setMainForm({ ...mainForm, parameters: newParams }),
    profiles: (newProfiles) => setMainForm({ ...mainForm, profiles: newProfiles }),
    publicKeyExternalApp: (newKey) => setMainForm({ ...mainForm, publicKeyExternalApp: newKey }),
    url: (newUrl) => setMainForm({ ...mainForm, url: newUrl }),
  }

  const fetchNewId = async function() {
    setLoading(true);
    const seqServiceInstance = new SequenceService();
    seqServiceInstance.generalAutomaticId(false, false, [200], SequenceGeneratorSequencesNameEnum.SQMEIT)
    .then((response) => {if (response) handleMainFormChange.id(response.responseData.data[0].sequenceCode)})
    .catch(err => console.error(err))
    .finally(() => setLoading(false));
  }

  const getMenuById = async function() {
    setLoading(true);
    MenuDefinitionService.getById(menuId, false, false, [200, 404])
    .then((m) => {
      if (m) {
        const fixedResponse: MenuDefinitionModel = {
          icon: m.image,
          id: m.id,
          isActive: m.isActive,
          itemType: m.type,
          level: m.level as MenuLevels,
          localizedNames: m.localizedDescription,
          name: m.description,
          parameters: m.urlLinkParameters,
          parentId: m.parent,
          profiles: m.profiles.filter(p => p.isActive_PRMI).map(p => p.id_PROF),
          publicKeyExternalApp: m.externalPublicKey,
          url: m.urlLink,
          order: String(m.order)
        }
        setMainForm(fixedResponse);
        setSelectedProfiles([...m.profiles.map(p => ({ key: p.id_PROF, value: p.name }))]);
        if (m.level == MenuLevels.ITEM) getMenusOnLevel(MenuLevels.GROUP);
        if (m.level == MenuLevels.GROUP) getMenusOnLevel(MenuLevels.SECTION);
      } else {
        showToast(labels.GetMenuError, TPToastTypes.error);
      }
    })
    .catch(err => {
      console.error(err);
      showToast(labels.GetMenuError, TPToastTypes.error);
    })
    .finally(() => setLoading(false));
  }

  const createMenu = async function() {
    setLoading(true);
    MenuDefinitionService.createMenu({
      description: mainForm.name,
      descriptionLocalizedValues: mainForm.localizedNames.map(l => (
        {
          ...l,
          localizedValue: 
            l.languageId === TPGlobal.TPClientDefaultLanguage && 
            l.localizedValue.trim().length === 0 ?
            mainForm.name : l.localizedValue
        }
      )),
      externalPublicKey: mainForm.publicKeyExternalApp,
      id: mainForm.id,
      image: mainForm.icon,
      isActive: mainForm.isActive,
      level: mainForm.level,
      order: "1",
      parent: mainForm.level == MenuLevels.SECTION ? null : mainForm.parentId,
      profiles: mainForm.profiles.map(p => ({
        Id_PROF: p,
        Id_MEIT: mainForm.id,
        IsActive_PRMI: true
      })),
      type: mainForm.itemType,
      urlLink: mainForm.url,
      urlLinkParameters: mainForm.parameters
    }, false, false, [200])
    .then(r => {
      if (r.responseData.responseCode === 200) {
        verticalTabCallback({
          command: 'delete',
          recordId: mainForm.level
        })
        verticalTabCallback({ 
          command: 'update',
          recordId: mainForm.id, 
          languageId: TPGlobal.language,
          recordDescription: mainForm.name
        })
        dispatch(MenuDefinitionSlice.actions.setContentLoaded(false));
        let msg = "";
        if (mainForm.level === MenuLevels.SECTION) msg = labels.CreateSectionSuccess;
        if (mainForm.level === MenuLevels.GROUP) msg = labels.CreateGroupSuccess;
        if (mainForm.level === MenuLevels.ITEM) msg = labels.CreateItemSuccess;
        showToast(msg, TPToastTypes.success);
      } else {
        let msg = "";
        if (mainForm.level === MenuLevels.SECTION) msg = labels.CreateSectionError;
        if (mainForm.level === MenuLevels.GROUP) msg = labels.CreateGroupError;
        if (mainForm.level === MenuLevels.ITEM) msg = labels.CreateItemError;
        showToast(msg, TPToastTypes.error);
      }
    })
    .catch(err => {
      console.error(err);
      let msg = "";
      if (mainForm.level === MenuLevels.SECTION) msg = labels.CreateSectionError;
      if (mainForm.level === MenuLevels.GROUP) msg = labels.CreateGroupError;
      if (mainForm.level === MenuLevels.ITEM) msg = labels.CreateItemError;
      showToast(msg, TPToastTypes.error);
    })
    .finally(() => setLoading(false));
  }

  const updateMenu = async function() {
    setLoading(true);
    MenuDefinitionService.updateMenu({
      description: mainForm.name,
      descriptionLocalizedValues: mainForm.localizedNames.map(l => (
        {
          ...l,
          localizedValue: 
            l.languageId === TPGlobal.TPClientDefaultLanguage && 
            l.localizedValue.trim().length === 0 ?
            mainForm.name : l.localizedValue
        }
      )),
      externalPublicKey: mainForm.publicKeyExternalApp,
      id: mainForm.id,
      image: mainForm.icon,
      isActive: mainForm.isActive,
      level: mainForm.level,
      order: mainForm.order || "1",
      parent: mainForm.level == MenuLevels.SECTION ? null : mainForm.parentId,
      profiles: mainForm.profiles.map(p => ({
        Id_PROF: p,
        Id_MEIT: mainForm.id,
        IsActive_PRMI: true
      })),
      type: mainForm.itemType,
      urlLink: mainForm.url,
      urlLinkParameters: mainForm.parameters
    }, false, false, [200])
    .then(r => {
      if (r.responseData.responseCode === 200) {
        dispatch(MenuDefinitionSlice.actions.setContentLoaded(false));
        let msg = "";
        if (mainForm.level === MenuLevels.SECTION) msg = labels.UpdateSectionSuccess;
        if (mainForm.level === MenuLevels.GROUP) msg = labels.UpdateGroupSuccess;
        if (mainForm.level === MenuLevels.ITEM) msg = labels.UpdateItemSuccess;
        showToast(msg, TPToastTypes.success);
      } else {
        let msg = "";
        if (mainForm.level === MenuLevels.SECTION) msg = labels.UpdateSectionError;
        if (mainForm.level === MenuLevels.GROUP) msg = labels.UpdateGroupError;
        if (mainForm.level === MenuLevels.ITEM) msg = labels.UpdateItemError;
        showToast(msg, TPToastTypes.error);
      }
    })
    .catch(err => {
      console.error(err);
      let msg = "";
      if (mainForm.level === MenuLevels.SECTION) msg = labels.UpdateSectionError;
      if (mainForm.level === MenuLevels.GROUP) msg = labels.UpdateGroupError;
      if (mainForm.level === MenuLevels.ITEM) msg = labels.UpdateItemError;
      showToast(msg, TPToastTypes.error);
    })
    .finally(() => setLoading(false));
  }

  const removeSelectedProfile = function (profile: TPKeyValue) {
    setSelectedProfiles([
      ...selectedProfiles.filter(p => p !== profile)
    ]);
  }

  const getComponents = async function () {
    setComponentsLoading(true);
    const componentServiceInstance = new ComponentService();
    await componentServiceInstance.GetByTypeIsActive("1",COMPONENT_TYPE, false, true, [200, 404])
      .then((c) => setComponents(c))
      .catch(err => console.error(err))
      .finally(() => setComponentsLoading(false))
  }

  const getParentInputLabel = function (level: MenuLevels) {
    if (level === MenuLevels.ITEM) return labels.Group;
    if (level === MenuLevels.GROUP) return labels.Section;
    return "???";
  }

  const getTitle = function () {
    if (newMenu === MenuLevels.ITEM) return labels.NewItem;
    if (newMenu === MenuLevels.GROUP) return labels.NewGroup;
    if (newMenu === MenuLevels.SECTION) return labels.NewSection;
    let label = "";
    if (mainForm.level === MenuLevels.SECTION) label = labels.Section;
    if (mainForm.level === MenuLevels.GROUP) label = labels.Group;
    if (mainForm.level === MenuLevels.ITEM) label = labels.Item;
    if (menuId?.trim().length > 0) return String(labels.Update).concat(" ", label || "???");
    return "???";
  }

  const getNameLabel = function () {
    if (newMenu === MenuLevels.ITEM) return labels.ItemName;
    if (newMenu === MenuLevels.GROUP) return labels.GroupName;
    if (newMenu === MenuLevels.SECTION) return labels.SectionName;
    return labels.Name;
  }

  const getMenusOnLevel = async function (level: MenuLevels) {
    if (!level) return;
    // setLoading(true);
    setAvailableParents(menus.filter(m => (
      (level === MenuLevels.SECTION && m.section) ||
      (level === MenuLevels.GROUP && m.group)
    ))
    .filter(m => m.isActive)
    .map(m => ({ id_MEIT: m.id, localizedDescription_MEIT: m.section || m.group || m.item })))
    // await MenuDefinitionService.getAllMenusOnLevel(level, false, true, [200, 404])
    //   .then(m => m && setAvailableParents(m))
    //   .catch(err => console.error(err))
    //   .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (!selectedProfiles) return;
    handleMainFormChange.profiles([...selectedProfiles.map(p => String(p.key))]);
  }, [selectedProfiles])

  useEffect(() => {
    if (mainForm.level === MenuLevels.ITEM) getMenusOnLevel(MenuLevels.GROUP);
    if (mainForm.level === MenuLevels.GROUP) getMenusOnLevel(MenuLevels.SECTION);
  }, [mainForm.level])

  useEffect(() => {
    if (mainForm.level === MenuLevels.ITEM) getMenusOnLevel(MenuLevels.GROUP);
    if (mainForm.level === MenuLevels.GROUP) getMenusOnLevel(MenuLevels.SECTION);
  }, [menus])

  useEffect(() => {
    if (menuId) {
      handleMainFormChange.id(menuId);
      if (!newMenu) {
        getMenuById();
      } else {
        fetchNewId();
      }
    }
    getComponents();
  }, [])

  return (
    <>
      {isLocalizedNamesModalOpen && (
        <TPLocalizedNamesModal
          title={labels.LanguageList}
          genericLabel={labels.Name}
          isOpen
          onAction={(data) => {
            setIsLocalizedNamesModalOpen(false);
            if (data) handleMainFormChange.localizedNames(data);
          }}
          initialValues={mainForm.localizedNames}
        />)}
      <TPLoadingOverlay active={loading || componentsLoading}>
        <div className="row menu-def-main-form-container">
          <div className="col menu-def-main-form-inner-container">
            <CIMTitleSection style={{ margin: 0, padding: 0 }}>
              <TPPageTitle style={{ margin: 0, padding: 0 }}>{getTitle()}</TPPageTitle>
            </CIMTitleSection>
            <div className="menu-def-form">
              <div style={{ width: "100%" }}>
                <TPTextBox
                  id="menu-def-form-id-input"
                  disabled
                  value={mainForm.id || ""}//isNewForm(menuId) ? "" : menuId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleMainFormChange.id(e.target.value)}
                  labelText={labels.Id}
                />
              </div>
              <div style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
                <div style={{ width: "100%", marginRight: "16px", flexShrink: 0 }}>
                  <TPTextBox
                    id="menu-def-form-name-input"
                    value={mainForm.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleMainFormChange.name(e.target.value)}
                    labelText={getNameLabel()}
                    isMandatory
                  />
                </div>
                <button
                  type="button"
                  className="menu-def-language-button"
                  onClick={() => setIsLocalizedNamesModalOpen(true)}
                >
                  <TPIcon
                    iconType={TPIconTypes.language}
                    style={{ fontSize: "24px" }}
                  />
                  +{TPGlobal.TPClientAvailableLanguages.length - 1}
                </button>
              </div>
              {(mainForm.level === MenuLevels.ITEM || mainForm.level === MenuLevels.GROUP) && (
                <>
                  <div className="menu-def-form-row">
                    <div style={{ width: "100%" }}>
                      <TPSelect
                        id="menu-def-form-group-input"
                        dataSource={[
                          { key: "", value: "--" },
                          ...availableParents.map(p => (
                            { key: p.id_MEIT, value: p.localizedDescription_MEIT }
                          ))
                        ]}
                        isMandatory
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleMainFormChange.parentId(e.target.value)}
                        value={mainForm.parentId}
                        labelText={getParentInputLabel(mainForm.level)}
                      />
                    </div>
                    {mainForm.level === MenuLevels.ITEM && (
                      <div style={{ width: "100%" }}>
                        <TPSelect
                          id="menu-def-form-item-type-input"
                          dataSource={[
                            { key: "", value: "--" },
                            ...Object.entries(MenuItemType).map(t => (
                              {key: t[1], value: t[0]}
                            ))
                          ]}
                          isMandatory
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleMainFormChange.itemType(e.target.value)}
                          value={mainForm.itemType}
                          labelText={labels.ItemType}
                        />
                      </div>)}
                  </div>
                  {mainForm.level === MenuLevels.ITEM && (
                    <>
                      <div className="menu-def-form-row">
                        <div style={{ width: "100%" }}>
                          <TPCustomAutocomplete
                            input={mainForm.url}
                            onQuery={(text) =>
                              handleMainFormChange.url(text)}
                            title={labels.URLLink}
                            mandatory
                            dropdownWidth={"min-content"}
                            suggestionTimeout={100}
                          >
                            {components.filter(c => c.component?.trim().length > 0).map(m =>
                            (
                              <button
                                key={m.component}
                                type="button"
                                className="menu-def-autocomplete-button"
                                onClick={() => handleMainFormChange.url(m.component)}
                              >
                                <div style={{ fontSize: "11px", pointerEvents: "none" }}>{m.localizedDescription}</div>
                                <div style={{ pointerEvents: "none" }}>{m.component}</div>
                              </button>
                            )
                            )}
                          </TPCustomAutocomplete>
                        </div>
                        <div style={{ width: "100%" }}>
                          <TPTextBox
                            id="menu-def-form-public-key-input"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleMainFormChange.publicKeyExternalApp(e.target.value)}
                            value={mainForm.publicKeyExternalApp}
                            labelText={labels.PublicKeyExternalApp}
                          />
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <TPTextArea
                          id="menu-def-form-params-input"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleMainFormChange.parameters(e.target.value)}
                          rows={5}
                          value={mainForm.parameters}
                          labelText={labels.Parameters}
                          placeholder={labels.InsertParameters}
                        />
                      </div>
                    </>)}
                </>
              )}
              <TPCheckBox
                checked={mainForm.isActive}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleMainFormChange.isActive(e.target.checked)}
                labelText={labels.ShowAsActive}
              />
              {mainForm.level === MenuLevels.GROUP && (
                <>
                  <TPIconStyledSelect
                    onIconSelected={(icon) => handleMainFormChange.icon(icon)}
                    selectedIcon={mainForm.icon}
                    width={"min-content"}
                    label={labels.SelectIcon}
                  />
                </>)}
              <div style={{ width: "100%", display: "flex", justifyContent: "stretch" }}>
                <SearchSelect
                  options={availableProfiles}
                  optionSelected={selectedProfiles}
                  handleMultiChange={(profiles) => setSelectedProfiles(profiles)}
                  id={`menu-def-search-select-${menuId}`}
                  isMulti
                  isMandatory
                  orientation="vertical"
                  label={labels.ApplyToProfiles}
                  width="100%"
                  style={{width:"100%"}}
                />
              </div>
              <div className="menu-def-selected-profiles-grid">
                {selectedProfiles.map((profile, idx) => (
                  <CockpitRemovableItem
                    item={{
                      key: profile.key,
                      value: profile.value,
                      type: "groups"
                    }}
                    onRemoveItem={() => removeSelectedProfile(profile)}
                    key={`${profile.key}-idx`}
                  />
                ))}
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <div className="menu-def-option-buttons">
                  <TPButton
                    isDesignSystem
                    onClick={() => {
                      newMenu ?
                        verticalTabCallback({
                          command: 'delete',
                          recordId: newMenu
                        })
                        :
                        verticalTabCallback({
                          command: 'delete',
                          recordId: mainForm.id
                        })
                    }}
                    style={{
                      backgroundColor: "white",
                      color: allThemes.base.purplePrimary,
                      padding: "11px 16px"
                    }}
                  >
                    {labels.Cancel}
                  </TPButton>
                  <TPButton
                    isDesignSystem
                    onClick={() => {
                      newMenu ? createMenu() : updateMenu();
                    }}
                    style={{ padding: "11px 16px" }}
                    disabled={!isFormValid(mainForm)}
                  >
                    {labels.Save}
                  </TPButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TPLoadingOverlay>
    </>
  )
}