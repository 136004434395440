import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { IFrequentAnswer } from "@/pages/FrequentAnswers/interfaces/IFrequentAnswer";

export class FrequentAnswerServices {
    serviceFileName: string = "FrequentAnswerServices.ts";

    public async insertFrequentAnswer(
        body: {
            GuidId: string;
            Id: string;
            Language: string;
            Description: string;
            Template: string;
            IsActive: boolean;
            Guid_USER?: string;
            Parent_GuidId?: string;
            Type: string;
            IsPublic: boolean;
        },
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<void> {
        const httpclient = new TPHTTPService();
        const url: string = "/frequent-answer";

        const finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        try {
            await httpclient.postData(
                finalURL,
                body,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} insertFrequentAnswer ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} insertFrequentAnswer`);
            throw new Error(`Error ${this.serviceFileName} insertFrequentAnswer`);
        }
    }

    public async getFrequentAnswerByGuid(
        guidId: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<any> {
        const httpclient = new TPHTTPService();
        const url: string = `/frequent-answer/guid/${guidId}`;

        const finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        try {
            const response: HTTPResponse = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getFrequentAnswerByGuid ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} getFrequentAnswerByGuid`);
            throw new Error(`Error ${this.serviceFileName} getFrequentAnswerByGuid`);
        }
    }

    public async getFrequentAnswersByFilterIsActive(
        filterIsActive: number,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<IFrequentAnswer[]> {
        const httpclient = new TPHTTPService();
        const url: string = `/frequent-answer?FilterIsActive=${filterIsActive}`;

        const finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        try {
            const response: HTTPResponse = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getFrequentAnswersByFilterIsActive ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} getFrequentAnswersByFilterIsActive`);
            throw new Error(`Error ${this.serviceFileName} getFrequentAnswersByFilterIsActive`);
        }
    }

    public async updateFrequentAnswer(
        body: {
            GuidId: string;
            Id: string;
            Language: string;
            Description: string;
            Template: string;
            IsActive: boolean;
            Guid_USER?: string;
            Parent_GuidId?: string;
            Type: string;
            IsPublic: boolean;
        },
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<void> {
        const httpclient = new TPHTTPService();
        const url: string = "/frequent-answer";

        const finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        try {
            await httpclient.putData(
                finalURL,
                body,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} updateFrequentAnswer ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} updateFrequentAnswer`);
            throw new Error(`Error ${this.serviceFileName} updateFrequentAnswer`);
        }
    }

    public async deleteFrequentAnswer(
        guidId: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<void> {
        const httpclient = new TPHTTPService();
        const url: string = `/frequent-answer/guid/${guidId}`;

        const finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        try {
            await httpclient.deleteData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} deleteFrequentAnswer ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} deleteFrequentAnswer`);
            throw new Error(`Error ${this.serviceFileName} deleteFrequentAnswer`);
        }
    }

    public async getRandomCase(
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<any> {
        const httpclient = new TPHTTPService();
        const url: string = `/cases/random-case/`;

        const finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        try {
            const response: HTTPResponse = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getRandomCase ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} getRandomCase`);
            throw new Error(`Error ${this.serviceFileName} getRandomCase`);
        }
    }

    public async renderFrequentAnswerTemplate(
        body: {
            InputText: string;
            CaseId: string;
            LanguageId: string;
            UserSessionGuid: string;
        },
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<any> {
        const httpclient = new TPHTTPService();
        const url: string = `/frequent-answer/render-template`;

        const finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        try {
            const response: HTTPResponse = await httpclient.postData(
                finalURL,
                body,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} renderFrequentAnswerTemplate ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} renderFrequentAnswerTemplate`);
            throw new Error(`Error ${this.serviceFileName} renderFrequentAnswerTemplate`);
        }
    }

}
