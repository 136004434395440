import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  ReactNode,
} from "react";
import {
  ConfigurationSettings,
  ErrorState,
  KeyValue,
} from "../models/ConfigurationModels";
import ConfigurationService from "@/services/ConfigurationService";
import {
  ConfigurationValuesInputDTO,
  ConfigurationValuesMassiveInputDTO,
} from "@/models/Configuration/ConfigurationValuesInputDTOModel";
import { TPI18N } from "@/services/I18nService";
import { useCopilot } from "@/contexts/TPCopilotContext";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPGlobal from "@/helpers/TPGlobal";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";

interface ConfigurationContextProps {
  keyValues: KeyValue;
  errorState: any;
  configurationSettings: ConfigurationSettings;
  someChange: boolean;
  isLoadingScreen: boolean;
  setIsLoadingScreen: (value: boolean) => void;
  setSomeChange: (value: boolean) => void;
  setKeyValues: (values: any) => void;
  setErrorState: (values: any) => void;
  setConfigurationSettings: (settings: ConfigurationSettings) => void;
  changeValueForKey: (key: string, newValue: string) => void;
  handleSave: () => void;
  reloadData: () => void;
  saveFieldsConfiguration: (fieldsToUpdate: Record<string, any>) => void;
}

const ConfigurationContext = createContext<
  ConfigurationContextProps | undefined
>(undefined);

const COMPONENT_NAME = "configurationContext";

export const ConfigurationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [someChange, setSomeChange] = useState(false);
  const [keyValues, setKeyValues] = useState<KeyValue>({});
  const [errorState, setErrorState] = useState<ErrorState>({});
  const [configurationSettings, setConfigurationSettings] =
    useState<ConfigurationSettings>({});
  const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(false);
  const resourceSet: string = "TPConfigKeyValueContainer";

  const loadInitialData = useCallback(async () => {
    const serviceClient = new ConfigurationService();
    const expectedCodes: Array<number> = [200, 404];

    try {
      setIsLoadingScreen(true);

      const responseRequest = await serviceClient.getAllConfigurationKeys(
        false,
        true,
        expectedCodes
      );

      if (responseRequest && responseRequest.responseData) {
        const configurationValueList: Array<KeyValue> = [
          ...responseRequest.responseData.data,
        ];

        const configurationValues: KeyValue = Object.fromEntries(
          configurationValueList.map(({ key, ...rest }: any) => [
            key.charAt(0).toLowerCase() + key.slice(1),
            rest,
          ])
        );
        setKeyValues(configurationValues);
      }
    } catch (error) {
      console.error("Error loading configuration data:", error);
    } finally {
      setIsLoadingScreen(false);
    }
  }, []);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const changeValueForKey = useCallback((key: string, newValue: string) => {
    setSomeChange(true);
    setKeyValues((prevKeyValues) => ({
      ...prevKeyValues,
      [key]: { value: newValue },
    }));
    setErrorState((prevErrorState) => ({
      ...prevErrorState,
      [`${key}ErrorMessage`]: "",
    }));
  }, []);

  const updateMassive = useCallback(
    async (inputDTO: ConfigurationValuesMassiveInputDTO) => {
      const serviceClient = new ConfigurationService();
      const expectedCodes: Array<number> = [200];

      try {
        setIsLoadingScreen(true);

        const updatedValues = inputDTO.values.map((value) => ({
          ...value,
          value: TPGlobal.stringToUTF8(value.value as string).toString(),
        }));

        inputDTO.values = updatedValues;

        await serviceClient.updateMassive(inputDTO, true, true, expectedCodes);

        const defaultLanguage = inputDTO.values.find(
          ({ key }) => key === "defaultLanguage"
        )?.value;

        if (defaultLanguage) {
          TPGlobal.TPClientDefaultLanguage = defaultLanguage;
        }

        setSomeChange(false);
      } catch (error) {
        TPLog.Log(
          `Error ${COMPONENT_NAME} updateMassive ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${COMPONENT_NAME} updateMassive ex`, error);
      } finally {
        setIsLoadingScreen(false);
      }
    },
    [setIsLoadingScreen]
  );

  const handleSave = useCallback(async () => {
    let recordInputDTO: ConfigurationValuesMassiveInputDTO = {
      values: [],
    };

    const excludedFields = [
      "MailReaderCustomHeaders",
      "AdminCustomerProducts",
      "cognitiveAnalysisAzureKey",
      "companyLogo",
      "customStatus",
    ];

    const keyNamesList = Object.keys(keyValues);

    keyNamesList.forEach((keyName) => {
      const newConfiguration: ConfigurationValuesInputDTO = {
        key: keyName,
        value: keyValues[keyName]?.value,
        notes: keyValues[keyName]?.notes || "",
        isPublic: !!keyValues[keyName]?.isPublic,
        dummy: keyValues[keyName]?.dummy || "",
      };

      recordInputDTO.values.push(newConfiguration);
    });

    let changeState = false;
    let newErrorState = { ...errorState };

    const validateField = async (field: string, messageKey: string) => {
      if (!keyValues[field]?.value) {
        newErrorState[`${field}ErrorMessage`] = await TPI18N.GetText(
          resourceSet,
          messageKey
        );

        showToast(newErrorState[`${field}ErrorMessage`], TPToastTypes.error);

        changeState = true;
      }
    };

    const fieldsToValidate = keyNamesList.filter(
      (field) => !excludedFields.includes(field)
    );

    for (const field of fieldsToValidate) {
      if (field === "customStatus") {
        const customStatusValue = keyValues[field]?.value;
        try {
          const parsedCustomStatus = JSON.parse(customStatusValue || "[]");

          for (const item of parsedCustomStatus) {
            if (!item.statusName.trim() || !item.statusType.trim()) {
              newErrorState[`${field}ErrorMessage`] = await TPI18N.GetText(
                resourceSet,
                `${field}Invalid`
              );
              showToast(
                newErrorState[`${field}ErrorMessage`],
                TPToastTypes.error
              );
              changeState = true;
              break;
            }
          }
        } catch (error) {
          console.error("Failed to parse customStatus:", error);
          newErrorState[`${field}ErrorMessage`] = await TPI18N.GetText(
            resourceSet,
            `${field}ParseError`
          );
          showToast(newErrorState[`${field}ErrorMessage`], TPToastTypes.error);
          changeState = true;
        }
      } else if (
        field === "userTreeBranchTeamMapping" ||
        field === "userTreeBranchAreaMapping"
      ) {
        try {
          const treeBranchValue = keyValues[field]?.value;
          const parsedTreeBranch = JSON.parse(treeBranchValue || "{}");

          if (
            !parsedTreeBranch.TreeCode.trim() ||
            !parsedTreeBranch.ParentCode.trim()
          ) {
            newErrorState[`${field}ErrorMessage`] = await TPI18N.GetText(
              resourceSet,
              `${field}Invalid`
            );
            showToast(
              newErrorState[`${field}ErrorMessage`],
              TPToastTypes.error
            );
            changeState = true;
          }
        } catch (error) {
          console.error(`Failed to parse ${field}:`, error);
          newErrorState[`${field}ErrorMessage`] = await TPI18N.GetText(
            resourceSet,
            `${field}ParseError`
          );
          showToast(newErrorState[`${field}ErrorMessage`], TPToastTypes.error);
          changeState = true;
        }
      } else {
        await validateField(field, `${field}Empty`);
      }
    }

    if (changeState) {
      setErrorState(newErrorState);
      return;
    }

    await updateMassive(recordInputDTO);
  }, [keyValues, errorState, resourceSet, updateMassive, TPI18N]);

  const saveFieldsConfiguration = useCallback(
    async (fieldsToUpdate: Record<string, any>) => {
      if (!fieldsToUpdate || Object.keys(fieldsToUpdate).length === 0) {
        console.warn("No fields provided for saving.");
        return;
      }

      const recordInputDTO: ConfigurationValuesMassiveInputDTO = {
        values: Object.entries(fieldsToUpdate).map(([key, value]) => ({
          key,
          value,
          notes: "",
          isPublic: false,
          dummy: "",
        })),
      };

      try {
        setIsLoadingScreen(true);

        await updateMassive(recordInputDTO);
      } catch (error) {
        TPLog.Log("Error saving fields", TPLogType.ERROR, error);
        console.error("Error saving fields", error);
      } finally {
        setIsLoadingScreen(false);
      }
    },
    [updateMassive, setIsLoadingScreen, setSomeChange]
  );

  const reloadData = useCallback(async () => {
    await loadInitialData();
  }, [loadInitialData]);

  return (
    <ConfigurationContext.Provider
      value={{
        keyValues,
        errorState,
        configurationSettings,
        someChange,
        isLoadingScreen,
        setIsLoadingScreen,
        setSomeChange,
        setKeyValues,
        setErrorState,
        setConfigurationSettings,
        changeValueForKey,
        handleSave,
        saveFieldsConfiguration,
        reloadData,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

export const useConfiguration = (): ConfigurationContextProps => {
  const context = useContext(ConfigurationContext);
  if (context === undefined) {
    throw new Error(
      "useConfiguration must be used within a ConfigurationProvider"
    );
  }
  return context;
};
