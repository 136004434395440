import DefaultAvatarIcon from "@/assets/images/TPIcons/DefaultAvatarIcon";
import NewCustomerIcon from "@/assets/images/TPIcons/NewCustomerIcon";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPAddtionalDataUIModes } from "@/components/TPAdditionalData/TPAdditionalDataUImodes";
import TPRenderAdditionalDataSwitch from "@/components/TPAdditionalData/TPRenderAdditionalDataSwitch";
import TPBranchSelection2 from "@/components/TPBranchSelection/TPBranchSelection2";
import { TPEditor } from "@/components/TPEditor/TPEditor";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalSearchClient, {
    TPModalSearchCustomerState,
} from "@/layouts/TPModalSearchClient/TPModalSearchClient";
import {
    AdditionalDataCaseViewModel,
    AdditionalDataViewModel,
} from "@/models/AdditionalData/AdditionalDataModels";
import { TEmpAdditionalDataCaseViewModel } from "@/models/Cases/CasesInputDTO";
import {
    ReclassifyCaseInputDTO,
    ReclassifyCaseInputDTOValidator,
} from "@/models/Cases/ReclassifyCaseInputDTO";
import { ClientAdminViewModel } from "@/models/Client/ClientAdminViewModel";
import {
    SystemParametersEnum,
    TPActiveOptions,
    TPButtonTypes,
    WebServiceClassifierType,
} from "@/models/Global/TPGlobalEnums";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { OrganizationsViewModel } from "@/models/Organizations/OrganizationsModels";
import { OrganizationsRelationsViewModel } from "@/models/OrganizationsRelations/OrganizationsRelationsModels";
import { QuickClassifierViewModel } from "@/models/QuickClassifier/QuickClassifierViewModel";
import { RelationsWithRestrictionsViewModel } from "@/models/Relations/RelationsModels";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import { WorkflowTypeViewModel } from "@/models/Workflow/WorkflowTypeModels";
import { AdditionalDataCaseService } from "@/services/AdditionalDataCaseService";
import { AdditionalDataService } from "@/services/AdditionalDataService";
import { CaseService } from "@/services/CaseService";
import { ClientService } from "@/services/ClientService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";
import { OrganizationsService } from "@/services/OrganizationsService";
import { ParametersService } from "@/services/ParametersService";
import { QuickClassifierService } from "@/services/QuickClassifierService";
import { RelationsService } from "@/services/RelationsService";
import { TreeService } from "@/services/TreeService";
import { WorkflowTypeService } from "@/services/WorkflowTypeService";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { searchCiteriaEnum } from "../NewCase/NewCase";
import { DiagramFullScreenModalType } from "../Workflow/WorkflowDetailAdmin";
import { CaseInfoStateType } from "./CaseViewer";

interface ReclassifyCaseProps {
    caseId: number;
    caseInfo: CaseInfoStateType;
    currentBaseLevelId: string;
    currentClientId: number;
    currentSubsidiaryId: number;
    currentSubsidiaryOrganizationId: number;
    children: JSX.Element;
}
type screenStateType = {
    caseGuid: string;
    baseLevelId: string;
    quickClassifierId: string;
    reclassificationReasonId: string;
    reclassificationComments: string;
    messageComments: string;

    reclassificationOptionId: string;
    reclassificationOptionSameWorkflow: string;

    //customer
    currentCustomerId: number;
    currentSubsidiaryId: number;
    currentSubsidiaryOrgId: number;

    currentTree1: string;
    currentTree2: string;
    currentTree3: string;
    currentTree4: string;
    currentTree5: string;

    mustSelectLastLevelBranch1: boolean;
    mustSelectLastLevelBranch2: boolean;
    mustSelectLastLevelBranch3: boolean;
    mustSelectLastLevelBranch4: boolean;
    mustSelectLastLevelBranch5: boolean;

    currentLabelTree1: string;
    currentLabelTree2: string;
    currentLabelTree3: string;
    currentLabelTree4: string;
    currentLabelTree5: string;

    currentBranch1: string;
    currentBranch2: string;
    currentBranch3: string;
    currentBranch4: string;
    currentBranch5: string;

    currentDescriptionBranch1: string;
    currentDescriptionBranch2: string;
    currentDescriptionBranch3: string;
    currentDescriptionBranch4: string;
    currentDescriptionBranch5: string;

    defaultBranchHierarchyDescription1: string;
    defaultBranchHierarchyDescription2: string;
    defaultBranchHierarchyDescription3: string;
    defaultBranchHierarchyDescription4: string;
    defaultBranchHierarchyDescription5: string;

    //key: additional data id
    //value: addtional data value
    //value2: other properties
    //value3: should show
    additionalDataValues: Array<TPKeyValue>;
    showAdditionalData: boolean;

    currentWorkflowType: string;
    currentWorkflowDescription: string;

    classify1BranchIdErrorMessage: string;
    classify2BranchIdErrorMessage: string;
    classify3BranchIdErrorMessage: string;
    classify4BranchIdErrorMessage: string;
    classify5BranchIdErrorMessage: string;

    baseLevelIdErrorMessage: string;
    organizationIdErrorMessage: string;
    relationIdErrorMessage: string;
    quickClassifierIdErrorMessage: string;
    reclassificationOptionIdErrorMessage: string;
    reclassificationReasonIdErrorMessage: string;
    classificationCommentsErrorMessage: string;
    commentsErrorMessage: string;
    [key: string]: any;
};

enum currentAccordionsEnum {
    "NONE" = 0,
    "CURRENT_CLASSIFICATION" = 1,
    "NEW_CLASSIFICATION" = 2,
}

enum commandsEnum {
    change_property_value,
    change_current_organization_relation,
    change_current_branch,
    organization_relation_clear,
    change_quick_classifier,
    change_additional_data_value,
    change_CurrentCustomer,
}
type commandType = {
    type: commandsEnum;
    payload: any;
};

const ReclassifyCase = React.forwardRef(
    (
        {
            caseId,
            caseInfo,
            currentBaseLevelId,
            currentClientId,
            currentSubsidiaryId,
            currentSubsidiaryOrganizationId,
            children,
        }: ReclassifyCaseProps,
        ref
    ) => {
        //screen loading
        const [isLoadingScreen, setIsLoadingScreen] = useState(true);
        const componentFileName: string = "ReclassifyCase.tsx";
        const resourceSet: string = "ReclassifyCaseComponent";
        const resourceSetNewCase: string = "NewCaseComponent";
        //#region initail states
        const initialScreenState: screenStateType = {
            caseGuid: uuidv4(),
            baseLevelId: "",
            quickClassifierId: "",
            quickClassifierIdErrorMessage: "",
            reclassificationOptionId: "", //"S_RECLAOPTDISO",
            reclassificationOptionSameWorkflow: "S_RECLASWOPTCCS",

            currentCustomerId: 0,
            currentSubsidiaryId: 0,
            currentSubsidiaryOrgId: 0,

            currentTree1: "",
            currentTree2: "",
            currentTree3: "",
            currentTree4: "",
            currentTree5: "",
            mustSelectLastLevelBranch1: false,
            mustSelectLastLevelBranch2: false,
            mustSelectLastLevelBranch3: false,
            mustSelectLastLevelBranch4: false,
            mustSelectLastLevelBranch5: false,
            currentLabelTree1: "",
            currentLabelTree2: "",
            currentLabelTree3: "",
            currentLabelTree4: "",
            currentLabelTree5: "",
            currentBranch1: "",
            currentBranch2: "",
            currentBranch3: "",
            currentBranch4: "",
            currentBranch5: "",
            currentDescriptionBranch1: "",
            currentDescriptionBranch2: "",
            currentDescriptionBranch3: "",
            currentDescriptionBranch4: "",
            currentDescriptionBranch5: "",
            defaultBranchHierarchyDescription1: "",
            defaultBranchHierarchyDescription2: "",
            defaultBranchHierarchyDescription3: "",
            defaultBranchHierarchyDescription4: "",
            defaultBranchHierarchyDescription5: "",

            baseLevelIdErrorMessage: "",
            organizationIdErrorMessage: "",
            relationIdErrorMessage: "",
            additionalDataValues: [],
            reclassificationReasonId: "",
            reclassificationComments: "",
            messageComments: "",
            reclassificationReasonIdErrorMessage: "",
            reclassificationOptionIdErrorMessage: "",
            classificationCommentsErrorMessage: "",
            commentsErrorMessage: "",
            currentWorkflowType: "",
            currentWorkflowDescription: "",
            showAdditionalData: false,
            classify1BranchIdErrorMessage: "",
            classify2BranchIdErrorMessage: "",
            classify3BranchIdErrorMessage: "",
            classify4BranchIdErrorMessage: "",
            classify5BranchIdErrorMessage: "",
        };

        //Screen Resources
        const [dummyClassfierLabel, setDummyClassfierLabel] = useState("");
        const [modalAcceptLabel, setModalAcceptLabel] = useState("");
        const [modalCancelLabel, setModalCancelLabel] = useState("");
        const [modalSelectedBranchLabel, setModalSelectedBranchLabel] =
            useState("");
        const [modalTitleLabel, setModalTitleLabel] = useState("");
        const [emptyLabel, setEmptyLabel] = useState("");
        const [newReclassificationCaseLabel, setNewReclassificationCaseLabel] =
            useState("");
        const [organizationsRelationsLabel, setOrganizationsRelationsLabel] =
            useState("");
        const [quickClassfierLabel, setQuickClassfierLabel] = useState("");
        const [selectOptionToApplyLabel, setSelectOptionToApplyLabel] =
            useState("");
        const [ifSameWorkflowLabel, setIfSameWorkflowLabel] = useState("");
        const [arrAdditionalDataCase, setArrAdditionalDataCase] =
            useState<Array<AdditionalDataCaseViewModel>>();
        const [newCustomerButtonLabel, setNewCustomerButtonLabel] = useState("");
        const [
            selectSearchCriteriaErrorMessageLabel,
            setSelectSearchCriteriaErrorMessageLabel,
        ] = useState("");
        const [
            selectSearchCriteriaErrorMessage,
            setSelectSearchCriteriaErrorMessage,
        ] = useState("");
        const [anonymousCustomerLabel, setAnonymousCustomerLabel] = useState("");
        const [searchCustomerLabel, setSearchCustomerLabel] = useState("");
        const [
            selectSearchValueErrorMessageLabel,
            setSelectSearchValueErrorMessageLabel,
        ] = useState("");
        const [searchCriteriaLabel, setSearchCriteriaLabel] = useState("");
        const [searchCriteriaValueLabel, setSearchCriteriaValueLabel] =
            useState("");
        const [selectAdditionalDataLabel, setSelectAdditionalDataLabel] =
            useState("");
        const [customerInformationLabel, setCustomerInformationLabel] =
            useState("");

        // Customer search description labels
        const [customerResultsNamesLabel, setCustomerResultsNamesLabel] =
            useState("");
        const [customerResultsTypeLabel, setCustomerResultsTypeLabel] =
            useState("");
        const [
            customerResultsDocumentTypeLabel,
            setCustomerResultsDocumentTypeLabel,
        ] = useState("");
        const [customerResultsDocumentLabel, setCustomerResultsDocumentLabel] =
            useState("");
        const [customerResultsPhoneLabel, setCustomerResultsPhoneLabel] =
            useState("");
        const [customerResultsEmailLabel, setCustomerResultsEmailLabel] =
            useState("");
        const [
            customerResultsInternalCodeLabel,
            setCustomerResultsInternalCodeLabel,
        ] = useState("");
        const [editCustomerButtonLabel, setEditCustomerButtonLabel] = useState("");
        const [searchAgainCustomerButtonLabel, setSearchAgainCustomerButtonLabel] =
            useState("");

        //References
        const childRefArray: any = useRef([]);
        const branch1SelectorRef: any = useRef(null);
        const branch2SelectorRef: any = useRef(null);
        const branch3SelectorRef: any = useRef(null);
        const branch4SelectorRef: any = useRef(null);
        const branch5SelectorRef: any = useRef(null);
        const editorRef = useRef<any>(null);
        const classificationCommentsEditorRef = useRef<any>(null);

        //#region const declarations
        const [alreadyHaveCustomer, setAlreadyHaveCustomer] = useState(true);

        const [isAnonymousCustomer, setIsAnonymousCustomer] = useState(false);
        const [canCreateAnyCustomer, setCanCreateAnyCustomer] = useState(false);

        const [searchCriteriaList, setSearchCriteriaList] = useState<
            Array<TPKeyValue>
        >([]);
        const [currentSearchCriteria, setCurrentSearchCriteria] =
            useState<searchCiteriaEnum>(searchCiteriaEnum.none);
        const [currentSearchCustomerValue, setCurrentSearchCustomerValue] =
            useState("");
        const [cancelAlreadyHaveCustomer, setCancelAlreadyHaveCustomer] =
            useState(false);
        const [selectSearchValueErrorMessage, setSelectSearchValueErrorMessage] =
            useState("");

        const [objCustomerInfo, setObjCustomerInfo] =
            useState<ClientAdminViewModel | null>(null);

        const initialDiagramModal: DiagramFullScreenModalType = {
            classModalDiagram: "modal",
            styleModalDiagram: { display: "none" },
            backdropClass: "",
            title: "",
        };

        const [diagramModal, setDiagramModal] =
            useState<DiagramFullScreenModalType>(initialDiagramModal);

        const [currentWorkflowImage, setCurrentWorkflowImage] = useState("");

        // Customer search data values (Form)
        const [dataCustomerResultsNames, setDataCustomerResultsNames] =
            useState("");
        const [dataCustomerResultsType, setDataCustomerResultsType] = useState("");
        const [
            dataCustomerResultsDocumentType,
            setDataCustomerResultsDocumentType,
        ] = useState("");
        const [dataCustomerResultsDocument, setDataCustomerResultsDocument] =
            useState("");
        const [dataCustomerResultsPhone, setDataCustomerResultsPhone] =
            useState("");
        const [dataCustomerResultsEmail, setDataCustomerResultsEmail] =
            useState("");
        const [
            dataCustomerResultsInternalCode,
            setDataCustomerResultsInternalCode,
        ] = useState("");

        //#region New client modal
        const [saveButtonLabel, setSaveButtonLabel] = useState("");
        const [cancelButtonLabel, setCancelButtonLabel] = useState("");
        const [isShownModalNewClient, setIsShownModalNewClient] = useState(false);
        const [clientMode, setClientMode] = useState("Insert");
        //#endregion New client modal

        //modal search client
        const [searchClientModalTitle, setSearchClientModalTitle] = useState("");
        const [okLabel, setOkLabel] = useState("");
        const [cancelLabel, setCancelLabel] = useState("");
        const initialModalSearchState: TPModalSearchCustomerState = {
            isShown: false,
            initialSearchCriteria: searchCiteriaEnum.none,
            initialSearchValue: "",
            initialAddtionaDataSearchId: "",
        };
        const [modalSearchCustomerState, setModalSearchCustomerState] =
            useState<TPModalSearchCustomerState>(initialModalSearchState);

        const [quickClassifiersListKeyValue, setQuickClassifiersListKeyValue] =
            useState<Array<TPKeyValue>>([]);

        const [organizationsRelationsKeyValue, setOrganizationsRelationsKeyValue] =
            useState<Array<TPKeyValue>>([]);

        const [reclassificationReasonKeyValue, setReclassificationReasonKeyValue] =
            useState<Array<TPKeyValue>>([]);

        const [
            reclassificationOptionsKeyValue,
            setReclassificationOptionsKeyValue,
        ] = useState<Array<TPKeyValue>>([]);

        const [sameWorkflowOptionsKeyValue, setSameWorkflowOptionsKeyValue] =
            useState<Array<TPKeyValue>>([]);
        //#end region

        //handle ref
        useImperativeHandle(ref, () => ({
            async reclassifyCase() {
                let bolR: boolean = await internalReclassifyCase();
                return bolR;
            },
        }));

        //#region Functions
        const loadResourcesAndOrganizationAndRelation = async () => {
            setDummyClassfierLabel(
                await TPI18N.GetText(resourceSetNewCase, "ClassifierLabel")
            );
            setModalAcceptLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
            );
            setModalCancelLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
            );
            setModalSelectedBranchLabel(
                await TPI18N.GetText(
                    TPGlobal.globalResourceSet,
                    "ModalSelectedBranchLabel"
                )
            );
            setModalTitleLabel(
                await TPI18N.GetText(
                    resourceSetNewCase,
                    "SelectBranchClassificatorTitleLabel"
                )
            );
            setEmptyLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel")
            );

            setNewReclassificationCaseLabel(
                await TPI18N.GetText(resourceSet, "NewReclassifyCaseLabel")
            );
            setOrganizationsRelationsLabel(
                await TPI18N.GetText(resourceSet, "OrganizationsRelationsLabel")
            );
            setQuickClassfierLabel(
                await TPI18N.GetText(resourceSet, "QuickClassfierLabel")
            );
            setSelectOptionToApplyLabel(
                await TPI18N.GetText(resourceSet, "SelectOptionToApplyLabel")
            );
            setIfSameWorkflowLabel(
                await TPI18N.GetText(resourceSet, "IfSameWorkflowLabel")
            );
            setCustomerInformationLabel(
                await TPI18N.GetText(resourceSet, "CustomerInformationLabel")
            );

            // Cargando información del cliente
            await realChangeCurrentCustomer(currentSubsidiaryOrganizationId);

            //New customer search resources
            setNewCustomerButtonLabel(
                await TPI18N.GetText(resourceSetNewCase, "NewCustomerButtonLabel")
            );
            setSelectSearchCriteriaErrorMessageLabel(
                await TPI18N.GetText(
                    resourceSetNewCase,
                    "SelectSearchCriteriaErrorMessageLabel"
                )
            );
            setAnonymousCustomerLabel(
                await TPI18N.GetText(resourceSetNewCase, "AnonymousCustomerLabel")
            );
            setSearchCustomerLabel(
                await TPI18N.GetText(resourceSetNewCase, "SearchCustomerLabel")
            );
            setSelectSearchValueErrorMessageLabel(
                await TPI18N.GetText(
                    resourceSetNewCase,
                    "SelectSearchValueErrorMessageLabel"
                )
            );
            setSearchCriteriaLabel(
                await TPI18N.GetText(resourceSetNewCase, "SearchCriteriaLabel")
            );
            setSearchCriteriaValueLabel(
                await TPI18N.GetText(resourceSetNewCase, "SearchCriteriaValueLabel")
            );
            setSelectAdditionalDataLabel(
                await TPI18N.GetText(resourceSetNewCase, "SelectAdditionalDataLabel")
            );
            setSearchClientModalTitle(
                await TPI18N.GetText(resourceSetNewCase, "SearchClientModalTitle")
            );
            setOkLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"));
            setCancelLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
            );

            setCustomerResultsNamesLabel(
                await TPI18N.GetText(resourceSetNewCase, "CustomerResultsNamesLabel")
            );
            setCustomerResultsTypeLabel(
                await TPI18N.GetText(resourceSetNewCase, "CustomerResultsTypeLabel")
            );
            setCustomerResultsDocumentTypeLabel(
                await TPI18N.GetText(
                    resourceSetNewCase,
                    "CustomerResultsDocumentTypeLabel"
                )
            );
            setCustomerResultsDocumentLabel(
                await TPI18N.GetText(resourceSetNewCase, "CustomerResultsDocumentLabel")
            );
            setCustomerResultsEmailLabel(
                await TPI18N.GetText(resourceSetNewCase, "CustomerResultsEmailLabel")
            );
            setCustomerResultsPhoneLabel(
                await TPI18N.GetText(resourceSetNewCase, "CustomerResultsPhoneLabel")
            );
            setCustomerResultsInternalCodeLabel(
                await TPI18N.GetText(
                    resourceSetNewCase,
                    "CustomerResultsInternalCodeLabel"
                )
            );

            setSearchCriteriaList([
                {
                    key: searchCiteriaEnum.none,
                    value: "--",
                },
                {
                    key: searchCiteriaEnum.docnumber,
                    value: await TPI18N.GetText(
                        resourceSetNewCase,
                        "SearchDocumentNumberLabel"
                    ),
                },
                {
                    key: searchCiteriaEnum.internalcode,
                    value: await TPI18N.GetText(
                        resourceSetNewCase,
                        "SearchInternalCodeLabel"
                    ),
                },
                {
                    key: searchCiteriaEnum.names,
                    value: await TPI18N.GetText(
                        resourceSetNewCase,
                        "SearchNameOrLastNameLabel"
                    ),
                },
                {
                    key: searchCiteriaEnum.email,
                    value: await TPI18N.GetText(resourceSetNewCase, "SearchEmailLabel"),
                },
                {
                    key: searchCiteriaEnum.phone,
                    value: await TPI18N.GetText(resourceSetNewCase, "SearchPhoneLabel"),
                }, //todo enable
                // {
                //   key: searchCiteriaEnum.additionaldata,
                //   value: await TPI18N.GetText(resourceSetNewCase, "SearchAdditionalDataLabel"),
                // },
            ]);

            //New client Modal
            setSaveButtonLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton")
            );
            setCancelButtonLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
            );

            await getOrganizationsRelationsList();
            let newReclassificationValueList = await getKeyValueParams(
                SystemParametersEnum.S_RERE
            );

            if (newReclassificationValueList) {
                setReclassificationReasonKeyValue([...newReclassificationValueList]);
            }
            let newReclassificationOptionsValueList: Array<TPKeyValue> | null =
                await getKeyValueParams(SystemParametersEnum.S_RECLAOPT);
            if (
                newReclassificationOptionsValueList &&
                newReclassificationOptionsValueList.length > 0
            ) {
                newReclassificationOptionsValueList.shift();
                setReclassificationOptionsKeyValue([
                    ...newReclassificationOptionsValueList,
                ]);
            }

            let newSameWorkflowOptionsKeyValue: Array<TPKeyValue> | null =
                await getKeyValueParams(SystemParametersEnum.S_RECLASW);
            if (
                newSameWorkflowOptionsKeyValue &&
                newSameWorkflowOptionsKeyValue.length > 0
            ) {
                newSameWorkflowOptionsKeyValue.shift();
                setSameWorkflowOptionsKeyValue([...newSameWorkflowOptionsKeyValue]);
            }

            // Loading additional data case
            let newArrAdditionalDataCase: Array<AdditionalDataCaseViewModel> | null =
                await getAdditionalDataByCaseId(caseInfo.caseNumber);
            if (newArrAdditionalDataCase && newArrAdditionalDataCase.length > 0) {
                setArrAdditionalDataCase(newArrAdditionalDataCase);
            }

            // Loading current classifiers
            await loadCurrentCaseClassifiers();
        };

        const loadCurrentCaseClassifiers = async () => {
            let newWorkflowType: string;
            let newWorkflowDescription: string;
            let additionalDataList: Array<AdditionalDataViewModel> | null = null;
            let newAdditionalDataList: Array<AdditionalDataViewModel> = [];
            let workflowData: WorkflowTypeViewModel | null;

            newWorkflowType = "";
            newWorkflowDescription = "";

            await changeOrganizationRelation(caseInfo.baseLevelId);
            // Branch 1 to 5
            await changeBranch(
                1,
                caseInfo.classificator1,
                caseInfo.classificator1Value,
                caseInfo.classificator1Value
            );
            await changeBranch(
                2,
                caseInfo.classificator2,
                caseInfo.classificator2Value,
                caseInfo.classificator2Value
            );
            await changeBranch(
                3,
                caseInfo.classificator3,
                caseInfo.classificator3Value,
                caseInfo.classificator3Value
            );
            await changeBranch(
                4,
                caseInfo.classificator4,
                caseInfo.classificator4Value,
                caseInfo.classificator4Value
            );
            await changeBranch(
                5,
                caseInfo.classificator5,
                caseInfo.classificator5Value,
                caseInfo.classificator5Value
            );

            // Call
            workflowData = await getWorkflowType(
                caseInfo.baseLevelId,
                caseInfo.classificator1,
                caseInfo.classificator2,
                caseInfo.classificator3,
                caseInfo.classificator4,
                caseInfo.classificator5
            );
            if (workflowData) {
                newWorkflowType = workflowData.id;
                newWorkflowDescription =
                    workflowData.localizedDescription &&
                        workflowData.localizedDescription !== ""
                        ? workflowData.localizedDescription
                        : workflowData.description;
            }
            // Additional data
            additionalDataList = await getAdditionalDataForClassification(
                caseInfo.classificator1,
                caseInfo.classificator2,
                caseInfo.classificator3,
                caseInfo.classificator4,
                caseInfo.classificator5
            );
            if (additionalDataList && additionalDataList.length >= 1) {
                newAdditionalDataList = [...additionalDataList];
            }

            let command1: commandType = {
                type: commandsEnum.change_current_branch,
                payload: {
                    treeNumber: 1,
                    newBranch: caseInfo.classificator1,
                    newDescriptionBranch: caseInfo.classificator1Value,
                    newWorkflowType: newWorkflowType,
                    newWorkflowDescription: newWorkflowDescription,
                    newAdditionalDataList: newAdditionalDataList,
                    newBranchHierachyDescription: caseInfo.classificator1Value,
                },
            };
            dispatch(command1);
        };

        //called to search a customer
        const getCustomerByClientIdSubsidiaryIdAndSubOrg = async (
            subsidiaryOrganizationId: number
        ) => {
            let serviceClient = new ClientService();
            let expectedCodes: Array<number> = [200];
            let i: number;
            try {
                setIsLoadingScreen(true);
                let responseRequest =
                    await serviceClient.getClientBySubsidiaryOrganizationId(
                        subsidiaryOrganizationId,
                        false,
                        true,
                        expectedCodes
                    );
                setIsLoadingScreen(false);
                return responseRequest[0];
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`
                );
                setIsLoadingScreen(false);
                return null;
            }
        };

        //called when change customer serch criteria
        const handleChangeCustormerSearchValue = (e: any) => {
            let newValue: string = e.target.value;
            setCurrentSearchCustomerValue(newValue);
            setSelectSearchValueErrorMessage("");
            clearCustomerData();
        };

        //called to show new customer modal
        const handleNewCustomerClick = () => {
            setClientMode("Insert");
            setIsShownModalNewClient(true);
        };

        //called when change customer serch criteria
        const handleCustomerSearchCriteriaChange = (e: any) => {
            let newValue: string = e.target.value;
            setCurrentSearchCriteria(newValue as searchCiteriaEnum);
            setSelectSearchCriteriaErrorMessage("");
            clearCustomerData();
        };

        //called when change customer after search/insert/modify or select anonymous
        const changeCurrentCustomerId = (
            newCurrentCustomerId: number,
            newCurrentSubsidiaryId: number,
            newCurrentSubsidiaryOrgId: number
        ) => {
            // let command1: commandType = {
            //   type: commandsEnum.change_CurrentCustomer,
            //   payload: {
            //     newCurrentCustomerId: newCurrentCustomerId,
            //     newCurrentSubsidiaryId: newCurrentSubsidiaryId,
            //     newCurrentSubsidiaryOrgId: newCurrentSubsidiaryOrgId,
            //   },
            // };

            dispatch({
                type: commandsEnum.change_CurrentCustomer,
                payload: {
                    newCurrentCustomerId: newCurrentCustomerId,
                    newCurrentSubsidiaryId: newCurrentSubsidiaryId,
                    newCurrentSubsidiaryOrgId: newCurrentSubsidiaryOrgId,
                },
            });
        };

        //called to clear customer state
        const clearCustomerData = () => {
            changeCurrentCustomerId(
                currentClientId,
                currentSubsidiaryId,
                currentSubsidiaryOrganizationId
            );
        };

        //called to handle search customer
        const handleSearchOnClick = async () => {
            let bolExit: boolean = false;

            clearCustomerData();

            //validate
            if (currentSearchCriteria === searchCiteriaEnum.none) {
                setSelectSearchCriteriaErrorMessage(
                    selectSearchCriteriaErrorMessageLabel
                );
                bolExit = true;
            }
            if (currentSearchCustomerValue.trim() === "") {
                setSelectSearchValueErrorMessage(selectSearchValueErrorMessageLabel);
                bolExit = true;
            }
            //todo add data
            if (bolExit) {
                return;
            }

            //perform real search
            let newModalSearchCustomerState: TPModalSearchCustomerState = {
                isShown: true,
                initialSearchCriteria: currentSearchCriteria,
                initialSearchValue: currentSearchCustomerValue,
                initialAddtionaDataSearchId: "", //todo
            };

            setModalSearchCustomerState(newModalSearchCustomerState);
        };

        //called to handle search again
        const handleSearchAgainOnClick = () => {
            setCurrentSearchCriteria(searchCiteriaEnum.none);
            setCurrentSearchCustomerValue("");
            setSelectSearchCriteriaErrorMessage("");
            setSelectSearchValueErrorMessage("");
            setAlreadyHaveCustomer(false);
            setCancelAlreadyHaveCustomer(true);
        };

        //called to handle cancel customer
        const handleCancelCustomerOnClick = () => {
            setCurrentSearchCriteria(searchCiteriaEnum.none);
            setCurrentSearchCustomerValue("");
            setSelectSearchCriteriaErrorMessage("");
            setSelectSearchValueErrorMessage("");
            setAlreadyHaveCustomer(true);
            setCancelAlreadyHaveCustomer(false);
        };

        //called to handle anonymous
        const handleAnonymousOnChange = async () => {
            if (!isAnonymousCustomer) {
                setCurrentSearchCriteria(searchCiteriaEnum.none);
                setCurrentSearchCustomerValue("");
                setSelectSearchCriteriaErrorMessage("");
                setSelectSearchValueErrorMessage("");
            }
            await realChangeCurrentCustomer(0);

            setIsAnonymousCustomer(!isAnonymousCustomer);
        };

        //callback to handle customer search
        const callBackModalCustomerSearch = async (
            successResult: boolean,
            selectedClient: any,
            usedSearchCriteria: string,
            usedSearchValue: string
            //todo adddata
        ) => {
            setModalSearchCustomerState(initialModalSearchState);
            if (successResult) {
                setCurrentSearchCriteria(usedSearchCriteria as searchCiteriaEnum);
                setCurrentSearchCustomerValue(usedSearchValue);
                setCancelAlreadyHaveCustomer(false);

                // let objClient: ClientAdminViewModel;
                // objClient = JSON.parse(selectedClient);

                await realChangeCurrentCustomer(
                    selectedClient.subsidiaryOrganizationId
                );
            } else {
                //todo logs
            }
        };

        //real actions after change customer
        const realChangeCurrentCustomer = async (
            subsidiaryOrganizationId: number
        ) => {
            // Change current customer
            setDataCustomerResultsNames("");
            setDataCustomerResultsType("");
            setDataCustomerResultsDocumentType("");
            setDataCustomerResultsDocument(""); //todo document description
            setDataCustomerResultsEmail("");
            setDataCustomerResultsPhone("");
            setDataCustomerResultsInternalCode("");

            let fullCustomerData: ClientAdminViewModel | null;
            fullCustomerData = await getCustomerByClientIdSubsidiaryIdAndSubOrg(
                subsidiaryOrganizationId
            );
            if (fullCustomerData) {
                changeCurrentCustomerId(
                    fullCustomerData.id,
                    fullCustomerData.subsidiaryId,
                    fullCustomerData.subsidiaryOrganizationId
                );

                setDataCustomerResultsNames(fullCustomerData.customerName);
                setDataCustomerResultsType(fullCustomerData.customerTypeDescription);
                setDataCustomerResultsDocumentType(
                    fullCustomerData.documentTypeDescription
                );
                setDataCustomerResultsDocument(fullCustomerData.document); //todo document description
                setDataCustomerResultsEmail(fullCustomerData.preferredEmail);
                setDataCustomerResultsPhone(fullCustomerData.preferredPhone);
                setDataCustomerResultsInternalCode(fullCustomerData.internalCode);
                setAlreadyHaveCustomer(true);
            } else {
                //todo logs
            }
        };

        //get relations list bases on selected organization
        const getRelationsList = async (newOrganization: string) => {
            let serviceClient = new RelationsService();
            let expectedCodes: Array<number> = [200];
            let i: number;
            try {
                let responseRequest =
                    await serviceClient.getRelationsByOrganizationAndFilter(
                        newOrganization,
                        TPActiveOptions.ALL.toString(),
                        false,
                        false,
                        expectedCodes
                    );

                let newRelationsListState: Array<TPKeyValue> = [];
                let relationsListData: Array<RelationsWithRestrictionsViewModel>;
                relationsListData = responseRequest;
                for (i = 0; i <= relationsListData.length - 1; i++) {
                    let keyvalue: TPKeyValue = {
                        key: relationsListData[i].baseLevelId,
                        value: relationsListData[i].localizedName
                            ? relationsListData[i].localizedName
                            : relationsListData[i].name,
                    };
                    newRelationsListState.push(keyvalue);
                }
                return newRelationsListState;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getRelationsList ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} getRelationsList ex`);
                return [];
            }
        };

        const changeOrganizationRelation = async (newBaseLevelId: string) => {            
            handleClear();
            if (!newBaseLevelId) {
                setQuickClassifiersListKeyValue([]);
                dispatch({
                    type: commandsEnum.organization_relation_clear,
                    payload: null,
                });
                return;
            }
            let newRelationId: string = "";
            let baseLevelData: OrganizationsRelationsViewModel | null = null;

            let newLabelTree1: string | null = "";
            let newLabelTree2: string | null = "";
            let newLabelTree3: string | null = "";
            let newLabelTree4: string | null = "";
            let newLabelTree5: string | null = "";

            let newTree1: string = "";
            let newTree2: string = "";
            let newTree3: string = "";
            let newTree4: string = "";
            let newTree5: string = "";

            let mustSelectLastBranch1: boolean = false;
            let mustSelectLastBranch2: boolean = false;
            let mustSelectLastBranch3: boolean = false;
            let mustSelectLastBranch4: boolean = false;
            let mustSelectLastBranch5: boolean = false;

            let classificationData: any;
            setIsLoadingScreen(true);

            baseLevelData = await getBaseLevelInfo(newBaseLevelId);
            if (baseLevelData === null) {
                //todo logs
                return;
            }          
            newRelationId = baseLevelData.relationId;
            if (newRelationId) {
                //clasificators
                classificationData = await getClassificatorsConfiguration(
                    newRelationId,
                    newBaseLevelId
                );
                if (classificationData === null) {
                    //todo logs
                    return;
                }
                newTree1 = classificationData.newTree1;
                newTree2 = classificationData.newTree2;
                newTree3 = classificationData.newTree3;
                newTree4 = classificationData.newTree4;
                newTree5 = classificationData.newTree5;
                mustSelectLastBranch1 = classificationData.mustSelectLastBranch1;
                mustSelectLastBranch2 = classificationData.mustSelectLastBranch2;
                mustSelectLastBranch3 = classificationData.mustSelectLastBranch3;
                mustSelectLastBranch4 = classificationData.mustSelectLastBranch4;
                mustSelectLastBranch5 = classificationData.mustSelectLastBranch5;
                newLabelTree1 = classificationData.newLabelTree1;
                newLabelTree2 = classificationData.newLabelTree2;
                newLabelTree3 = classificationData.newLabelTree3;
                newLabelTree4 = classificationData.newLabelTree4;
                newLabelTree5 = classificationData.newLabelTree5;

                await getQuickClassifiersForOrganizationRelation(newBaseLevelId);
            } else {
                setQuickClassifiersListKeyValue([]);
            }
            dispatch({
                type: commandsEnum.change_current_organization_relation,
                payload: {
                    newBaseLevelId: newBaseLevelId,
                    newTree1: newTree1,
                    newTree2: newTree2,
                    newTree3: newTree3,
                    newTree4: newTree4,
                    newTree5: newTree5,

                    newMustSelectLastLevelBranch1: mustSelectLastBranch1,
                    newMustSelectLastLevelBranch2: mustSelectLastBranch2,
                    newMustSelectLastLevelBranch3: mustSelectLastBranch3,
                    newMustSelectLastLevelBranch4: mustSelectLastBranch4,
                    newMustSelectLastLevelBranch5: mustSelectLastBranch5,

                    newLabelTree1: newLabelTree1,
                    newLabelTree2: newLabelTree2,
                    newLabelTree3: newLabelTree3,
                    newLabelTree4: newLabelTree4,
                    newLabelTree5: newLabelTree5,
                },
            });
            setIsLoadingScreen(false);
        };

        const handleClear = () => {

            dispatch({
                type: commandsEnum.organization_relation_clear,
                payload: null,
            });

            dispatch({
                type: commandsEnum.change_property_value,
                payload: {
                    propertyName: "quickClassifierId",
                    newPropertyValue: false,
                },
            });

            dispatch({
                type: commandsEnum.change_property_value,
                payload: {
                    propertyName: "reclassificationOptionId",
                    newPropertyValue: false,
                },
            });

           dispatch({
                type: commandsEnum.change_property_value,
                payload: {
                    propertyName: "reclassificationOptionSameWorkflow",
                    newPropertyValue: false,
                },
            });
          
        }

        const handleOrganizationRelationChange = async (e: any) => {
            let newBaseLevelId: string = e.target.value;
            await changeOrganizationRelation(newBaseLevelId);
        };

        //get quick classificators for a baselevel
        const getQuickClassifiersForOrganizationRelation = async (
            newBaseLevel: string
        ): Promise<Array<TPKeyValue>> => {
            let newQuickClassifierList: Array<TPKeyValue> = [];
            let serviceClient = new QuickClassifierService();
            let expectedCodes: Array<number> = [200];
            let quickData: Array<QuickClassifierViewModel> = [];
            try {
                //todo use another method to get only valid branches
                //due to must select last level branch
                let responseRequest =
                    await serviceClient.getQuickClassifiersByBaseLevel(
                        newBaseLevel,
                        false,
                        false,
                        expectedCodes
                    );
                quickData = responseRequest;
                if (quickData) {
                    for (let i: number = 0; i <= quickData.length - 1; i++) {
                        if (
                            quickData[i].isActive &&
                            quickData[i].type == WebServiceClassifierType.QuickClassifiers
                        ) {
                            newQuickClassifierList.push({
                                key: quickData[i].id,
                                value: quickData[i].localizedDescription
                                    ? quickData[i].localizedDescription
                                    : quickData[i].description,
                                value2: {
                                    classifier1Id: quickData[i].classifier1Id,
                                    classifier2Id: quickData[i].classifier2Id,
                                    classifier3Id: quickData[i].classifier3Id,
                                    classifier4Id: quickData[i].classifier4Id,
                                    classifier5Id: quickData[i].classifier5Id,
                                },
                                value3: {
                                    localizedClassifier1: quickData[i].localizedClassifier1,
                                    localizedClassifier2: quickData[i].localizedClassifier2,
                                    localizedClassifier3: quickData[i].localizedClassifier3,
                                    localizedClassifier4: quickData[i].localizedClassifier4,
                                    localizedClassifier5: quickData[i].localizedClassifier5,
                                },
                                value4: {
                                    localizedHierarchyClassifier1:
                                        quickData[i].localizedHierarchyClassifier1,
                                    localizedHierarchyClassifier2:
                                        quickData[i].localizedHierarchyClassifier2,
                                    localizedHierarchyClassifier3:
                                        quickData[i].localizedHierarchyClassifier3,
                                    localizedHierarchyClassifier4:
                                        quickData[i].localizedHierarchyClassifier4,
                                    localizedHierarchyClassifier5:
                                        quickData[i].localizedHierarchyClassifier5,
                                },
                            });
                        }
                    }
                    if (newQuickClassifierList.length >= 1) {
                        let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
                        newQuickClassifierList.unshift(intitialKeyValue);
                    }
                }
                setQuickClassifiersListKeyValue(newQuickClassifierList);
                return newQuickClassifierList;
            } catch (error) {
                newQuickClassifierList = [];
                setQuickClassifiersListKeyValue(newQuickClassifierList);
                TPLog.Log(
                    `Error ${componentFileName} getQuickClassifiersForOrganizationRelation ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${componentFileName} getQuickClassifiersForOrganizationRelation ex`
                );
                newQuickClassifierList = [];
                return newQuickClassifierList;
            }
        };

        const handleQuickClassifierOnChange = async (e: any) => {       
            const newValue: string = e.target.value;
            let index: number;
            let branch1: string;
            let branch2: string;
            let branch3: string;
            let branch4: string;
            let branch5: string;
            let branchDescription1: string;
            let branchDescription2: string;
            let branchDescription3: string;
            let branchDescription4: string;
            let branchDescription5: string;
            let branchHierarchyDescription1: string;
            let branchHierarchyDescription2: string;
            let branchHierarchyDescription3: string;
            let branchHierarchyDescription4: string;
            let branchHierarchyDescription5: string;

            let calculateWorkflowAndAdditionalData: boolean = true;
            let newWorkflowType: string;
            let newWorkflowDescription: string;
            let additionalDataList: Array<AdditionalDataViewModel> | null = null;
            let newAdditionalDataList: Array<AdditionalDataViewModel> = [];

            branch1 = "";
            branch2 = "";
            branch3 = "";
            branch4 = "";
            branch5 = "";
            branchDescription1 = "";
            branchDescription2 = "";
            branchDescription3 = "";
            branchDescription4 = "";
            branchDescription5 = "";

            newWorkflowType = "";
            newWorkflowDescription = "";
            if (newValue != "") {
                index = quickClassifiersListKeyValue.findIndex(
                    (x: TPKeyValue) => x.key === newValue
                );
                if (index !== -1) {
                    if (
                        quickClassifiersListKeyValue[index].value2.classifier1Id &&
                        quickClassifiersListKeyValue[index].value2.classifier1Id !== "" &&
                        screenState.currentTree1 !== TPGlobal.Tree.NA_TreeCode
                    ) {
                        branch1 = quickClassifiersListKeyValue[index].value2.classifier1Id;
                        branchDescription1 =
                            quickClassifiersListKeyValue[index].value3.localizedClassifier1;
                        branchHierarchyDescription1 =
                            quickClassifiersListKeyValue[index].value4
                                .localizedHierarchyClassifier1;
                    } else {
                        branchHierarchyDescription1 = "";
                    }
                    if (
                        quickClassifiersListKeyValue[index].value2.classifier2Id &&
                        quickClassifiersListKeyValue[index].value2.classifier2Id !== "" &&
                        screenState.currentTree2 !== TPGlobal.Tree.NA_TreeCode
                    ) {
                        branch2 = quickClassifiersListKeyValue[index].value2.classifier2Id;
                        branchDescription2 =
                            quickClassifiersListKeyValue[index].value3.localizedClassifier2;
                        branchHierarchyDescription2 =
                            quickClassifiersListKeyValue[index].value4
                                .localizedHierarchyClassifier2;
                    } else {
                        branchHierarchyDescription2 = "";
                    }

                    if (
                        quickClassifiersListKeyValue[index].value2.classifier3Id &&
                        quickClassifiersListKeyValue[index].value2.classifier3Id !== "" &&
                        screenState.currentTree3 !== TPGlobal.Tree.NA_TreeCode
                    ) {
                        branch3 = quickClassifiersListKeyValue[index].value2.classifier3Id;
                        branchDescription3 =
                            quickClassifiersListKeyValue[index].value3.localizedClassifier3;
                        branchHierarchyDescription3 =
                            quickClassifiersListKeyValue[index].value4
                                .localizedHierarchyClassifier3;
                    } else {
                        branchHierarchyDescription3 = "";
                    }

                    if (
                        quickClassifiersListKeyValue[index].value2.classifier4Id &&
                        quickClassifiersListKeyValue[index].value2.classifier4Id !== "" &&
                        screenState.currentTree4 !== TPGlobal.Tree.NA_TreeCode
                    ) {
                        branch4 = quickClassifiersListKeyValue[index].value2.classifier4Id;
                        branchDescription4 =
                            quickClassifiersListKeyValue[index].value3.localizedClassifier4;
                        branchHierarchyDescription4 =
                            quickClassifiersListKeyValue[index].value4
                                .localizedHierarchyClassifier4;
                    } else {
                        branchHierarchyDescription4 = "";
                    }

                    if (
                        quickClassifiersListKeyValue[index].value2.classifier5Id &&
                        quickClassifiersListKeyValue[index].value2.classifier5Id !== "" &&
                        screenState.currentTree5 !== TPGlobal.Tree.NA_TreeCode
                    ) {
                        branch5 = quickClassifiersListKeyValue[index].value2.classifier5Id;
                        branchDescription5 =
                            quickClassifiersListKeyValue[index].value3.localizedClassifier5;
                        branchHierarchyDescription5 =
                            quickClassifiersListKeyValue[index].value4
                                .localizedHierarchyClassifier5;
                    } else {
                        branchHierarchyDescription5 = "";
                    }

                    if (
                        screenState.currentTree1 !== TPGlobal.Tree.NA_TreeCode &&
                        branch1 === ""
                    ) {
                        calculateWorkflowAndAdditionalData = false;
                    }
                    if (
                        screenState.currentTree2 !== TPGlobal.Tree.NA_TreeCode &&
                        branch2 === ""
                    ) {
                        calculateWorkflowAndAdditionalData = false;
                    }
                    if (
                        screenState.currentTree3 !== TPGlobal.Tree.NA_TreeCode &&
                        branch3 === ""
                    ) {
                        calculateWorkflowAndAdditionalData = false;
                    }
                    if (
                        screenState.currentTree4 !== TPGlobal.Tree.NA_TreeCode &&
                        branch4 === ""
                    ) {
                        calculateWorkflowAndAdditionalData = false;
                    }
                    if (
                        screenState.currentTree5 !== TPGlobal.Tree.NA_TreeCode &&
                        branch5 === ""
                    ) {
                        calculateWorkflowAndAdditionalData = false;
                    }

                    //calculate workflow
                    if (calculateWorkflowAndAdditionalData) {
                        let workflowData: WorkflowTypeViewModel | null;
                        workflowData = await getWorkflowType(
                            screenState.baseLevelId,
                            branch1,
                            branch2,
                            branch3,
                            branch4,
                            branch5
                        );
                        if (workflowData) {
                            newWorkflowType = workflowData.id;
                            newWorkflowDescription =
                                workflowData.localizedDescription &&
                                    workflowData.localizedDescription !== ""
                                    ? workflowData.localizedDescription
                                    : workflowData.description;
                        }
                    }
                    //calculate additional data list
                    if (calculateWorkflowAndAdditionalData) {
                        additionalDataList = await getAdditionalDataForClassification(
                            branch1,
                            branch2,
                            branch3,
                            branch4,
                            branch5
                        );
                        if (additionalDataList && additionalDataList.length >= 1) {
                            newAdditionalDataList = [...additionalDataList];
                        }
                    }

                    //dispatch command to change 5 classifiers at a time

                    let command1: commandType = {
                        type: commandsEnum.change_quick_classifier,
                        payload: {
                            newQuickClassifierId: newValue,
                            newbranch1: branch1,
                            newbranch2: branch2,
                            newbranch3: branch3,
                            newbranch4: branch4,
                            newbranch5: branch5,
                            newDescriptionBranch1: branchDescription1,
                            newDescriptionBranch2: branchDescription2,
                            newDescriptionBranch3: branchDescription3,
                            newDescriptionBranch4: branchDescription4,
                            newDescriptionBranch5: branchDescription5,
                            newHierarchyDescriptionBranch1: branchHierarchyDescription1,
                            newHierarchyDescriptionBranch2: branchHierarchyDescription2,
                            newHierarchyDescriptionBranch3: branchHierarchyDescription3,
                            newHierarchyDescriptionBranch4: branchHierarchyDescription4,
                            newHierarchyDescriptionBranch5: branchHierarchyDescription5,
                            newWorkflowType: newWorkflowType,
                            newWorkflowDescription: newWorkflowDescription,
                            newAdditionalDataList: newAdditionalDataList,
                            newBranch1ErrorMessage: branch1
                                ? ""
                                : screenState.classify1BranchIdErrorMessage,
                            newBranch2ErrorMessage: branch2
                                ? ""
                                : screenState.classify2BranchIdErrorMessage,
                            newBranch3ErrorMessage: branch3
                                ? ""
                                : screenState.classify3BranchIdErrorMessage,
                            newBranch4ErrorMessage: branch4
                                ? ""
                                : screenState.classify4BranchIdErrorMessage,
                            newBranch5ErrorMessage: branch5
                                ? ""
                                : screenState.classify5BranchIdErrorMessage,
                        },
                    };
                    dispatch(command1);
                }
            } else {
                dispatch({
                    type: commandsEnum.change_property_value,
                    payload: {
                        propertyName: "quickClassifierId",
                        newPropertyValue: "",
                    },
                });
            }
        };

        //get classificators for a baselevel
        const getClassificatorsConfiguration = async (
            newRelation: string,
            newBaseLevel: string
        ) => {
            let newTree1: string;
            let newTree2: string;
            let newTree3: string;
            let newTree4: string;
            let newTree5: string;

            let mustSelectLastBranch1: boolean;
            let mustSelectLastBranch2: boolean;
            let mustSelectLastBranch3: boolean;
            let mustSelectLastBranch4: boolean;
            let mustSelectLastBranch5: boolean;

            let newLabelTree1: string | null;
            let newLabelTree2: string | null;
            let newLabelTree3: string | null;
            let newLabelTree4: string | null;
            let newLabelTree5: string | null;

            let baseLevelData: OrganizationsRelationsViewModel | null = null;
            let treeData: TreeViewModel | null = null;
            let localizedField: Array<MultilanguageFieldValueViewModel> = [];
            let i;
            let found: boolean;

            newTree1 = "";
            newTree2 = "";
            newTree3 = "";
            newTree4 = "";
            newTree5 = "";

            mustSelectLastBranch1 = false;
            mustSelectLastBranch2 = false;
            mustSelectLastBranch3 = false;
            mustSelectLastBranch4 = false;
            mustSelectLastBranch5 = false;

            newLabelTree1 = "";
            newLabelTree2 = "";
            newLabelTree3 = "";
            newLabelTree4 = "";
            newLabelTree5 = "";
            baseLevelData = await getBaseLevelInfo(newBaseLevel);
            if (baseLevelData === null) {
                //todo logs
                return null;
            }
            newTree1 = baseLevelData.tree1Id;
            newTree2 = baseLevelData.tree2Id;
            newTree3 = baseLevelData.tree3Id;
            newTree4 = baseLevelData.tree4Id;
            newTree5 = baseLevelData.tree5Id;
            //get tree Info 1
            if (newTree1 !== TPGlobal.Tree.NA_TreeCode) {
                treeData = await getTreeInfo(newTree1);
                if (treeData === null) {
                    //todo logs
                    return null;
                } else {
                    mustSelectLastBranch1 = treeData.mustSelectLastBranch;
                    //get label from multilanguage field value
                    localizedField = await getRecordLanguageList(
                        newBaseLevel + "_C1",
                        "BAseLEvel",
                        "Tree1_Classify1Caption"
                    );
                    if (localizedField === null || localizedField.length === 0) {
                        //todo logs
                        return null;
                    }
                    found = false;
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (localizedField[i].languageId === TPGlobal.language) {
                            newLabelTree1 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        for (i = 0; i <= localizedField.length - 1; i++) {
                            if (
                                localizedField[i].languageId ===
                                TPGlobal.TPClientAvailableLanguages[0].id
                            ) {
                                newLabelTree1 = localizedField[i].recordDescription;
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            //todo logs
                            return null;
                        }
                    }
                }
            }
            if (newTree2 !== TPGlobal.Tree.NA_TreeCode) {
                //get tree Info 2
                treeData = await getTreeInfo(newTree2);
                if (treeData === null) {
                    //todo logs
                    return null;
                } else {
                    mustSelectLastBranch2 = treeData.mustSelectLastBranch;
                    //get label from multilanguage field value
                    localizedField = await getRecordLanguageList(
                        newBaseLevel + "_C2",
                        "BAseLEvel",
                        "Tree2_Classify2Caption"
                    );
                    if (localizedField === null || localizedField.length === 0) {
                        //todo logs
                        return null;
                    }
                    found = false;
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (localizedField[i].languageId === TPGlobal.language) {
                            newLabelTree2 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        for (i = 0; i <= localizedField.length - 1; i++) {
                            if (
                                localizedField[i].languageId ===
                                TPGlobal.TPClientAvailableLanguages[0].id
                            ) {
                                newLabelTree2 = localizedField[i].recordDescription;
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            //todo logs
                            return null;
                        }
                    }
                }
            }
            if (newTree3 !== TPGlobal.Tree.NA_TreeCode) {
                //get tree Info 3
                treeData = await getTreeInfo(newTree3);
                if (treeData === null) {
                    //todo logs
                    return null;
                } else {
                    mustSelectLastBranch3 = treeData.mustSelectLastBranch;
                    //get label from multilanguage field value
                    localizedField = await getRecordLanguageList(
                        newBaseLevel + "_C3",
                        "BAseLEvel",
                        "Tree3_Classify3Caption"
                    );
                    if (localizedField === null || localizedField.length === 0) {
                        //todo logs
                        return null;
                    }
                    found = false;
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (localizedField[i].languageId === TPGlobal.language) {
                            newLabelTree3 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        for (i = 0; i <= localizedField.length - 1; i++) {
                            if (
                                localizedField[i].languageId ===
                                TPGlobal.TPClientAvailableLanguages[0].id
                            ) {
                                newLabelTree3 = localizedField[i].recordDescription;
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            //todo logs
                            return null;
                        }
                    }
                }
            }
            if (newTree4 !== TPGlobal.Tree.NA_TreeCode) {
                //get tree Info 4
                treeData = await getTreeInfo(newTree4);
                if (treeData === null) {
                    //todo logs
                    return null;
                } else {
                    mustSelectLastBranch4 = treeData.mustSelectLastBranch;
                    //get label from multilanguage field value
                    localizedField = await getRecordLanguageList(
                        newBaseLevel + "_C4",
                        "BAseLEvel",
                        "Tree4_Classify4Caption"
                    );
                    if (localizedField === null || localizedField.length === 0) {
                        //todo logs
                        return null;
                    }
                    found = false;
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (localizedField[i].languageId === TPGlobal.language) {
                            newLabelTree4 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        for (i = 0; i <= localizedField.length - 1; i++) {
                            if (
                                localizedField[i].languageId ===
                                TPGlobal.TPClientAvailableLanguages[0].id
                            ) {
                                newLabelTree4 = localizedField[i].recordDescription;
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            //todo logs
                            return null;
                        }
                    }
                }
            }
            if (newTree5 !== TPGlobal.Tree.NA_TreeCode) {
                //get tree Info 5
                treeData = await getTreeInfo(newTree5);
                if (treeData === null) {
                    //todo logs
                    return null;
                } else {
                    mustSelectLastBranch5 = treeData.mustSelectLastBranch;
                    //get label from multilanguage field value
                    localizedField = await getRecordLanguageList(
                        newBaseLevel + "_C5",
                        "BAseLEvel",
                        "Tree5_Classify5Caption"
                    );
                    if (localizedField === null || localizedField.length === 0) {
                        //todo logs
                        return null;
                    }
                    found = false;
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (localizedField[i].languageId === TPGlobal.language) {
                            newLabelTree5 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        for (i = 0; i <= localizedField.length - 1; i++) {
                            if (
                                localizedField[i].languageId ===
                                TPGlobal.TPClientAvailableLanguages[0].id
                            ) {
                                newLabelTree5 = localizedField[i].recordDescription;
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            //todo logs
                            return null;
                        }
                    }
                }
            }
            return {
                newTree1,
                newTree2,
                newTree3,
                newTree4,
                newTree5,
                mustSelectLastBranch1,
                mustSelectLastBranch2,
                mustSelectLastBranch3,
                mustSelectLastBranch4,
                mustSelectLastBranch5,
                newLabelTree1,
                newLabelTree2,
                newLabelTree3,
                newLabelTree4,
                newLabelTree5,
            };
        };

        //get base level info (organization and relation pair)
        const getBaseLevelInfo = async (BaseLevelId: string) => {
            let serviceClient = new OrganizationsRelationsService();
            let expectedCodes: Array<number> = [200];
            let baseLevelData: OrganizationsRelationsViewModel | null = null;
            try {
                let responseRequest = await serviceClient.getById(
                    BaseLevelId,
                    false,
                    true,
                    expectedCodes
                );

                baseLevelData = responseRequest;
                return baseLevelData;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getBaseLevelInfo ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} getBaseLevelInfo ex`);
                return null;
            }
        };

        //get a tree info
        const getTreeInfo = async (treeId: string) => {
            let serviceClient = new TreeService();
            let expectedCodes: Array<number> = [200];
            let treeData: TreeViewModel | null = null;
            try {
                let responseRequest = await serviceClient.getTreeById(
                    treeId,
                    false,
                    true,
                    expectedCodes
                );
                treeData = responseRequest;
                return treeData;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getTreeInfo ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} getTreeInfo ex`);
                return null;
            }
        };

        //get multilanguage field value
        const getRecordLanguageList = async (
            recordId: string,
            multilanguageTableName: string,
            multilanguageFieldName: string
        ): Promise<Array<MultilanguageFieldValueViewModel>> => {
            let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
            let expectedCodes: Array<number> = [200];
            try {
                let responseRequest =
                    await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
                        multilanguageTableName,
                        multilanguageFieldName,
                        recordId,
                        false,
                        true,
                        expectedCodes
                    );

                let recordInfo: Array<MultilanguageFieldValueViewModel>;
                recordInfo = [...responseRequest];
                return recordInfo;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getRecordLanguageList ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} getRecordLanguageList ex`);
                return [];
            }
        };

        const changeBranch = async (
            index: number,
            branchId: string,
            branchDescription: string,
            branchHierachyDescription: string
        ): Promise<void> => {   
            let branch1: string;
            let branch2: string;
            let branch3: string;
            let branch4: string;
            let branch5: string;
            let calculateWorkflowAndAdditionalData: boolean = true;
            let newWorkflowType: string;
            let newWorkflowDescription: string;
            let additionalDataList: Array<AdditionalDataViewModel> | null = null;
            let newAdditionalDataList: Array<AdditionalDataViewModel> = [];

            branch1 = "";
            branch2 = "";
            branch3 = "";
            branch4 = "";
            branch5 = "";
            newWorkflowType = "";
            newWorkflowDescription = "";

            //check to calculate workflow and additional data
            if (screenState.currentTree1 !== TPGlobal.Tree.NA_TreeCode) {
                if (index == 1) {
                    branch1 = branchId;
                } else {
                    branch1 = screenState.currentBranch1;
                }
                if (branch1 === "") {
                    calculateWorkflowAndAdditionalData = false;
                }
            }
            if (screenState.currentTree2 !== TPGlobal.Tree.NA_TreeCode) {
                if (index == 2) {
                    branch2 = branchId;
                } else {
                    branch2 = screenState.currentBranch2;
                }
                if (branch2 === "") {
                    calculateWorkflowAndAdditionalData = false;
                }
            }
            if (screenState.currentTree3 !== TPGlobal.Tree.NA_TreeCode) {
                if (index == 3) {
                    branch3 = branchId;
                } else {
                    branch3 = screenState.currentBranch3;
                }
                if (branch3 === "") {
                    calculateWorkflowAndAdditionalData = false;
                }
            }
            if (screenState.currentTree4 !== TPGlobal.Tree.NA_TreeCode) {
                if (index == 4) {
                    branch4 = branchId;
                } else {
                    branch4 = screenState.currentBranch4;
                }
                if (branch4 === "") {
                    calculateWorkflowAndAdditionalData = false;
                }
            }
            if (screenState.currentTree5 !== TPGlobal.Tree.NA_TreeCode) {
                if (index == 5) {
                    branch5 = branchId;
                } else {
                    branch5 = screenState.currentBranch5;
                }
                if (branch5 === "") {
                    calculateWorkflowAndAdditionalData = false;
                }
            }
            //calculate workflow
            if (calculateWorkflowAndAdditionalData) {
                let workflowData: WorkflowTypeViewModel | null;
                workflowData = await getWorkflowType(
                    screenState.baseLevelId,
                    branch1,
                    branch2,
                    branch3,
                    branch4,
                    branch5
                );
                if (workflowData) {
                    newWorkflowType = workflowData.id;
                    newWorkflowDescription =
                        workflowData.localizedDescription &&
                            workflowData.localizedDescription !== ""
                            ? workflowData.localizedDescription
                            : workflowData.description;
                }
            }
            //calculate additional data list
            if (calculateWorkflowAndAdditionalData) {
                additionalDataList = await getAdditionalDataForClassification(
                    branch1,
                    branch2,
                    branch3,
                    branch4,
                    branch5
                );
                if (additionalDataList && additionalDataList.length >= 1) {
                    newAdditionalDataList = [...additionalDataList];
                }
            }

            let command1: commandType = {
                type: commandsEnum.change_current_branch,
                payload: {
                    treeNumber: index,
                    newBranch: branchId,
                    newDescriptionBranch: branchDescription,
                    newWorkflowType: newWorkflowType,
                    newWorkflowDescription: newWorkflowDescription,
                    newAdditionalDataList: newAdditionalDataList,
                    newBranchHierachyDescription: branchHierachyDescription,
                },
            };
            dispatch(command1);
        };

        //handle classificator branch change
        const handleOnBranchChange = async (
            index: number,
            branchId: string,
            branchDescription: string,
            branchHierachyDescription: string
        ) => {
            await changeBranch(
                index,
                branchId,
                branchDescription,
                branchHierachyDescription
            );
        };

        //get workflow based on classificators and base level
        const getWorkflowType = async (
            baseLevelId: string,
            branch1: string,
            branch2: string,
            branch3: string,
            branch4: string,
            branch5: string
        ) => {
            let serviceClient = new WorkflowTypeService();
            let expectedCodes: Array<number> = [200, 404];
            let workflowTypeData: WorkflowTypeViewModel | null = null;
            try {
                setIsLoadingScreen(true);
                let responseRequest = await serviceClient.getByBaseLevelAndClassifiers(
                    baseLevelId,
                    branch1,
                    branch2,
                    branch3,
                    branch4,
                    branch5,
                    false,
                    true,
                    expectedCodes
                );

                workflowTypeData = responseRequest;
                setIsLoadingScreen(false);
                return workflowTypeData;
            } catch (error) {
                setIsLoadingScreen(false);
                TPLog.Log(
                    `Error ${componentFileName} getWorkflowType ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} getWorkflowType ex`);
                return null;
            }
        };

        //get additional data based on classificators
        const getAdditionalDataForClassification = async (
            branch1: string,
            branch2: string,
            branch3: string,
            branch4: string,
            branch5: string
        ) => {
            let serviceClient = new AdditionalDataService();
            let expectedCodes: Array<number> = [200, 404];
            let additionalData: Array<AdditionalDataViewModel> | null = null;
            try {
                setIsLoadingScreen(true);
                let responseRequest = await serviceClient.getByClassifiers(
                    branch1,
                    branch2,
                    branch3,
                    branch4,
                    branch5,
                    "1", //active
                    "1", //visible
                    false,
                    true,
                    expectedCodes
                );

                additionalData = responseRequest;
                setIsLoadingScreen(false);
                return additionalData;
            } catch (error) {
                setIsLoadingScreen(false);
                TPLog.Log(
                    `Error ${componentFileName} getAdditionalDataForClassification ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${componentFileName} getAdditionalDataForClassification ex`
                );
                return null;
            }
        };

        //get additional data by case id
        const getAdditionalDataByCaseId = async (caseNumber: number) => {
            let additionalDataCaseService = new AdditionalDataCaseService();
            let expectedCodes: Array<number> = [200, 404];
            let additionalData: Array<AdditionalDataCaseViewModel> | null = null;

            try {
                setIsLoadingScreen(true);
                let responseRequest = await additionalDataCaseService.getByCaseId(
                    caseNumber,
                    false,
                    true,
                    expectedCodes
                );

                additionalData = responseRequest;
                setIsLoadingScreen(false);
                return additionalData;
            } catch (error) {
                setIsLoadingScreen(false);
                TPLog.Log(
                    `Error ${componentFileName} getAdditionalDataByCaseId ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${componentFileName} getAdditionalDataByCaseId ex`
                );
                return null;
            }
        };

        //get Base Level list
        const getOrganizationsRelationsList = async () => {
            let serviceClient = new OrganizationsService();
            let expectedCodes: Array<number> = [200];
            let i: number;
            let j: number;
            setIsLoadingScreen(true);
            try {
                let responseRequest = await serviceClient.getOrganizationsByFilter(
                    TPActiveOptions.ACTIVE.toString(),
                    false,
                    true,
                    expectedCodes
                );
                let newOrganizationsRelationsListState: Array<TPKeyValue> = [];
                let organizationsListData: Array<OrganizationsViewModel>;
                organizationsListData = responseRequest;
                for (i = 0; i <= organizationsListData.length - 1; i++) {
                    let relationsList: Array<TPKeyValue> = [];
                    try {
                        relationsList = await getRelationsList(organizationsListData[i].id);
                    } catch (error) { }
                    if (relationsList) {
                        for (j = 0; j <= relationsList.length - 1; j++) {
                            let descriptionOrganization: string;

                            descriptionOrganization = organizationsListData[i].localizedName
                                ? organizationsListData[i].localizedName
                                : organizationsListData[i].name;
                            let keyvalue: TPKeyValue = {
                                key: relationsList[j].key,
                                value: descriptionOrganization + " / " + relationsList[j].value,
                            };
                            newOrganizationsRelationsListState.push(keyvalue);
                        }
                    }
                }
                let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
                newOrganizationsRelationsListState.unshift(intitialKeyValue);

                setOrganizationsRelationsKeyValue(newOrganizationsRelationsListState);
                setIsLoadingScreen(false);
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getOrganizationsRelationsList ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${componentFileName} getOrganizationsRelationsList ex`
                );
                setIsLoadingScreen(false);
            }
        };

        const handleViewDiagram = async () => {
            let imageData: any;
            imageData = await getWorkflowDiagram();
            if (imageData) {
                setCurrentWorkflowImage(imageData.value);
                let newState: DiagramFullScreenModalType = {
                    classModalDiagram: "modal show",
                    styleModalDiagram: { display: "block" },
                    backdropClass: "modal-backdrop show",
                    title: screenState.currentWorkflowDescription,
                };
                setDiagramModal(newState);
            }
        };

        //get workflow diagram
        const getWorkflowDiagram = async () => {
            let serviceClient = new WorkflowTypeService();
            let expectedCodes: Array<number> = [200];

            try {
                setIsLoadingScreen(true);
                let responseRequest = await serviceClient.getDiagramByWorkflowType(
                    screenState.currentWorkflowType,
                    "TB", //top bottom
                    false,
                    true,
                    expectedCodes
                );
                setIsLoadingScreen(false);
                return responseRequest;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getWorkflowDiagram ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} getWorkflowDiagram ex`);
                setIsLoadingScreen(false);
                return null;
            }
        };

        const getKeyValueParams = async (
            parentId: SystemParametersEnum
        ): Promise<Array<TPKeyValue> | null> => {
            let parametersService = new ParametersService();
            let expectedCodes: Array<number> = [200];
            try {
                let responseRequest =
                    await parametersService.getByParentIdAndFilterIsActive(
                        parentId,
                        TPActiveOptions.ACTIVE.toString(),
                        false,
                        true,
                        expectedCodes
                    );

                let newTypeKeyValueList: Array<TPKeyValue> = responseRequest.map(
                    function (item) {
                        return {
                            key: item.id,
                            value: item.localizedDescription,
                        };
                    }
                );
                newTypeKeyValueList.unshift({ key: "", value: "--" });
                return newTypeKeyValueList;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getKeyValueParams ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} getKeyValueParams ex`);
                return null;
            }
        };

        const handleReclassificationReasonOnChange = async (e: any) => {
            dispatch({
                type: commandsEnum.change_property_value,
                payload: {
                    propertyName: "reclassificationReasonId",
                    newPropertyValue: e.target.value,
                },
            });
        };

        const internalValidation = async (): Promise<boolean> => {
            let isValid: boolean = true;
            let newTempAdditionalDataValues: Array<TEmpAdditionalDataCaseViewModel> =
                [];
            let comments: string;
            let reclassificationComments: string;

            comments = classificationCommentsEditorRef.current.props.value;
            comments = comments === null ? "" : comments.trim();
            comments = TPGlobal.TPSanitizeWithoutLinks(comments).trim();

            reclassificationComments = editorRef.current.props.value;
            reclassificationComments =
                reclassificationComments === null
                    ? ""
                    : reclassificationComments.trim();
            reclassificationComments = TPGlobal.TPSanitizeWithoutLinks(
                reclassificationComments
            ).trim();

            newTempAdditionalDataValues = getFinalAdditonalDataValues();
            let inputDTO: ReclassifyCaseInputDTO = {
                caseId: caseId,
                clientId: screenState.currentClientId,
                subsidiaryId: screenState.currentSubsidiaryId,
                subsidiaryOrganizationId: screenState.currentSubsidiaryOrgId,
                baseLevelId: screenState.baseLevelId,
                classify1BranchId: screenState.currentBranch1,
                classify2BranchId: screenState.currentBranch2,
                classify3BranchId: screenState.currentBranch3,
                classify4BranchId: screenState.currentBranch4,
                classify5BranchId: screenState.currentBranch5,
                comments: comments,
                reclassificationComments: reclassificationComments,
                reclassificationReasonId: screenState.reclassificationReasonId,
                reclassificationOptionId: screenState.reclassificationOptionId,
                reclassificationOptionSameWorkflowId:
                    screenState.reclassificationOptionSameWorkflow,
                typistGuidUser: TPGlobal.currentUserGuid,
                tEmpAdditionalDataCase: newTempAdditionalDataValues,
                guidCase: screenState.caseGuid,
            };
            let inputDTOValidator = new ReclassifyCaseInputDTOValidator();
            let resultValidator = inputDTOValidator.validate(inputDTO);
            if (!TPGlobal.TPIsEmpty(resultValidator)) {
                isValid = false;
                var listPropertyNames = Object.keys(resultValidator);
                if (listPropertyNames) {
                    for (let index = 0; index < listPropertyNames.length; index++) {
                        const element = listPropertyNames[index];
                        let payload: any = new Object();
                        if (resultValidator[element]) {
                            payload.propertyName = element + "ErrorMessage";
                            payload.newPropertyValue = await TPI18N.GetResource(
                                resultValidator[element] as string
                            );
                        } else {
                            payload.propertyName = element + "ErrorMessage";
                            payload.newPropertyValue = "";
                        }
                        let command: commandType = {
                            type: commandsEnum.change_property_value,
                            payload: payload,
                        };
                        dispatch(command);
                    }
                }
            }

            //validate additional data
            if (!validateAdditonalData()) {
                isValid = false;
            }
            return isValid;
        };

        const internalReclassifyCase = async (): Promise<boolean> => {
            let isValid: boolean = true;
            let newTempAdditionalDataValues: Array<TEmpAdditionalDataCaseViewModel> =
                [];
            let comments: string;
            let reclassificationComments: string;

            // comments = classificationCommentsEditorRef.current.getContent();
            comments = editorRef.current.props.value
                ? editorRef.current.props.value.trim()
                : null;
            // comments = (comments === null ? "" : comments.trim());
            comments = TPGlobal.TPSanitizeWithoutLinks(comments);

            // reclassificationComments = editorRef.current.getContent();
            reclassificationComments = classificationCommentsEditorRef.current.props
                .value
                ? classificationCommentsEditorRef.current.props.value.trim()
                : null;
            // reclassificationComments = (reclassificationComments === null ? "" : reclassificationComments.trim());
            reclassificationComments = TPGlobal.TPSanitizeWithoutLinks(
                reclassificationComments
            ).trim();

            newTempAdditionalDataValues = getFinalAdditonalDataValues();
            let recordInputDTO: ReclassifyCaseInputDTO = {
                caseId: caseId,
                clientId: screenState.currentCustomerId,
                subsidiaryId: screenState.currentSubsidiaryId,
                subsidiaryOrganizationId: screenState.currentSubsidiaryOrgId,
                baseLevelId: screenState.baseLevelId,
                classify1BranchId: screenState.currentBranch1,
                classify2BranchId: screenState.currentBranch2,
                classify3BranchId: screenState.currentBranch3,
                classify4BranchId: screenState.currentBranch4,
                classify5BranchId: screenState.currentBranch5,
                comments: TPGlobal.stringToUTF8(comments).toString(), //editorRef.current.getContent({ format: "text" }),
                reclassificationComments: TPGlobal.stringToUTF8(
                    reclassificationComments
                ).toString(),
                reclassificationReasonId: screenState.reclassificationReasonId,
                reclassificationOptionId: screenState.reclassificationOptionId,
                reclassificationOptionSameWorkflowId:
                    screenState.reclassificationOptionSameWorkflow,
                typistGuidUser: TPGlobal.currentUserGuid,
                tEmpAdditionalDataCase: newTempAdditionalDataValues,
                guidCase: screenState.caseGuid,
                useRPCMethod: true,
            };
            let inputDTOValidator = new ReclassifyCaseInputDTOValidator();
            let resultValidator = inputDTOValidator.validate(recordInputDTO);
            if (!TPGlobal.TPIsEmpty(resultValidator)) {
                isValid = false;
                var listPropertyNames = Object.keys(resultValidator);
                if (listPropertyNames) {
                    for (let index = 0; index < listPropertyNames.length; index++) {
                        const element = listPropertyNames[index];
                        let payload: any = new Object();
                        if (resultValidator[element]) {
                            payload.propertyName = element + "ErrorMessage";
                            payload.newPropertyValue = await TPI18N.GetResource(
                                resultValidator[element] as string
                            );
                        } else {
                            payload.propertyName = element + "ErrorMessage";
                            payload.newPropertyValue = "";
                        }
                        let command: commandType = {
                            type: commandsEnum.change_property_value,
                            payload: payload,
                        };
                        dispatch(command);
                    }
                }
            }
            //validate additional data
            if (!validateAdditonalData()) {
                isValid = false;
            }
            if (!isValid) {
                return false;
            }
            //Call endpoint to reclassify case
            let serviceClient = new CaseService();
            let expectedCodes: Array<number> = [200];
            try {
                setIsLoadingScreen(true);

                let responseRequest = await serviceClient.reclassifyCase(
                    recordInputDTO,
                    true,
                    true,
                    expectedCodes
                );
                if (responseRequest.responseResult) {
                    setIsLoadingScreen(false);
                    return true;
                } else {
                    //todo logs
                    setIsLoadingScreen(false);
                    return false;
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} internalReclassifyCase ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} internalReclassifyCase ex`);
                setIsLoadingScreen(false);
                return false;
            }
        };

        // get additionaldata value from local additional data case list (arrAdditionalDataCase)
        const getAdditionalDataCaseValue = (additionalDataId: string) => {
            let addaValue: string = "";
            try {
                let arrResult = arrAdditionalDataCase?.filter(
                    (x: AdditionalDataCaseViewModel) =>
                        x.additionalDataId.toUpperCase() === additionalDataId.toUpperCase()
                );
                if (
                    arrResult !== null &&
                    arrResult !== undefined &&
                    arrResult?.length > 0
                ) {
                    addaValue = arrResult[0].value;
                }
            } catch {
                return addaValue;
            }

            return addaValue;
        };

        //check if there is any additional data visible
        //additonal data can appear and dissapear if you change classificators
        //previously enterered values are preserved but hidden if not exist for current classification
        const hasAnyVisibleAdditionalData = () => {
            for (
                let i: number = 0;
                i <= screenState.additionalDataValues.length - 1;
                i++
            ) {
                if ((screenState.additionalDataValues[i].value3 as boolean) === true) {
                    return true;
                }
            }
            return false;
        };

        //handle additional data change
        const handleChangeAditionalDataValue = (
            idControl: string,
            newValue: any,
            additionalDataId: string
        ) => {
            let command1: commandType = {
                type: commandsEnum.change_additional_data_value,
                payload: {
                    newValue: newValue,
                    additionalDataId: additionalDataId,
                },
            };
            dispatch(command1);
        };

        //called before reclassify case to get additional data values
        const getFinalAdditonalDataValues =
            (): Array<TEmpAdditionalDataCaseViewModel> => {
                let result: Array<TEmpAdditionalDataCaseViewModel> = [];
                for (
                    let i: number = 0;
                    i <= screenState.additionalDataValues.length - 1;
                    i++
                ) {
                    let element: TPKeyValue;
                    element = screenState.additionalDataValues[i];
                    //key: additional data id
                    //value: addtional data value
                    //value2: other properties
                    //value3: should show

                    if (element.value3) {
                        if (childRefArray.current) {
                            const keys = Object.keys(childRefArray.current);
                            if (
                                keys &&
                                keys.findIndex((x: any) => x === element.key) !== -1
                            ) {
                                let controlref: any = childRefArray.current[element.key];

                                let stringValue: string;
                                stringValue = controlref.getValueFromParent();
                                if (stringValue) {
                                    let oneAddData: TEmpAdditionalDataCaseViewModel;
                                    oneAddData = {
                                        additionalDataId: element.key,
                                        value: stringValue,
                                        isMandatory: element.value2.isMandatory,
                                    };
                                    result.push(oneAddData);
                                }
                            }
                        }
                    }
                }
                return result;
            };

        //called before save case to validate additional data
        const validateAdditonalData = (): boolean => {
            let atLeastOneError: boolean = false;
            for (
                let i: number = 0;
                i <= screenState.additionalDataValues.length - 1;
                i++
            ) {
                let element: TPKeyValue;
                element = screenState.additionalDataValues[i];
                //key: additional data id
                //value: addtional data value
                //value2: other properties
                //value3: should show

                if (element.value3) {
                    if (childRefArray.current) {
                        const keys = Object.keys(childRefArray.current);
                        if (keys && keys.findIndex((x: any) => x === element.key) !== -1) {
                            let controlref: any = childRefArray.current[element.key];
                            if (!controlref.validateFromParent()) {
                                atLeastOneError = true;
                            }
                        }
                    }
                }
            }
            if (atLeastOneError) {
                return false;
            }

            return true;
        };

        //#endRegion end functions

        //Reducer definition
        const reducer = (state: screenStateType, action: commandType) => {
            let newScreenState: screenStateType;
            let found: boolean;
            newScreenState = { ...state };
            switch (action.type) {
                case commandsEnum.change_CurrentCustomer:
                    newScreenState.currentCustomerId =
                        action.payload.newCurrentCustomerId;
                    newScreenState.currentSubsidiaryId =
                        action.payload.newCurrentSubsidiaryId;
                    newScreenState.currentSubsidiaryOrgId =
                        action.payload.newCurrentSubsidiaryOrgId;
                    newScreenState.selectedContactName = "";
                    return newScreenState;
                case commandsEnum.change_property_value:
                    newScreenState[action.payload.propertyName] =
                        action.payload.newPropertyValue;
                    newScreenState[`${action.payload.propertyName}ErrorMessage`] = "";
                    if (
                        newScreenState["reclassificationComments"] &&
                        newScreenState["reclassificationComments"].replace(/<[^>]*>/g, "")
                            .length > 0
                    ) {
                        newScreenState["reclassificationCommentsErrorMessage"] = "";
                    }
                    if (
                        newScreenState["messageComments"] &&
                        newScreenState["reclassificationComments"].replace(/<[^>]*>/g, "")
                            .length > 0
                    ) {
                        newScreenState["commentsErrorMessage"] = "";
                    }
                    return newScreenState;
                case commandsEnum.change_current_organization_relation:
                   
                    newScreenState.baseLevelId = action.payload.newBaseLevelId;
                    newScreenState.currentTree1 = action.payload.newTree1;
                    newScreenState.currentTree2 = action.payload.newTree2;
                    newScreenState.currentTree3 = action.payload.newTree3;
                    newScreenState.currentTree4 = action.payload.newTree4;
                    newScreenState.currentTree5 = action.payload.newTree5;

                    newScreenState.mustSelectLastLevelBranch1 =
                        action.payload.newMustSelectLastLevelBranch1;
                    newScreenState.mustSelectLastLevelBranch2 =
                        action.payload.newMustSelectLastLevelBranch2;
                    newScreenState.mustSelectLastLevelBranch3 =
                        action.payload.newMustSelectLastLevelBranch3;
                    newScreenState.mustSelectLastLevelBranch4 =
                        action.payload.newMustSelectLastLevelBranch4;
                    newScreenState.mustSelectLastLevelBranch5 =
                        action.payload.newMustSelectLastLevelBranch5;

                    newScreenState.currentLabelTree1 = action.payload.newLabelTree1;
                    newScreenState.currentLabelTree2 = action.payload.newLabelTree2;
                    newScreenState.currentLabelTree3 = action.payload.newLabelTree3;
                    newScreenState.currentLabelTree4 = action.payload.newLabelTree4;
                    newScreenState.currentLabelTree5 = action.payload.newLabelTree5;
                    newScreenState.currentWorkflowType = "";
                    newScreenState.baseLevelIdErrorMessage = "";
          
                    return newScreenState;
                case commandsEnum.change_current_branch:
                    // let found: boolean;
                    switch (action.payload.treeNumber) {
                        case 1:
                            newScreenState.currentBranch1 = action.payload.newBranch;
                            newScreenState.currentDescriptionBranch1 =
                                action.payload.newDescriptionBranch;
                            newScreenState.defaultBranchHierarchyDescription1 =
                                action.payload.newBranchHierachyDescription;
                            newScreenState.classify1BranchIdErrorMessage = "";
                            break;
                        case 2:
                            newScreenState.currentBranch2 = action.payload.newBranch;
                            newScreenState.currentDescriptionBranch2 =
                                action.payload.newDescriptionBranch;
                            newScreenState.defaultBranchHierarchyDescription2 =
                                action.payload.newBranchHierachyDescription;
                            newScreenState.classify2BranchIdErrorMessage = "";
                            break;
                        case 3:
                            newScreenState.currentBranch3 = action.payload.newBranch;
                            newScreenState.currentDescriptionBranch3 =
                                action.payload.newDescriptionBranch;
                            newScreenState.defaultBranchHierarchyDescription3 =
                                action.payload.newBranchHierachyDescription;
                            newScreenState.classify3BranchIdErrorMessage = "";
                            break;
                        case 4:
                            newScreenState.currentBranch4 = action.payload.newBranch;
                            newScreenState.currentDescriptionBranch4 =
                                action.payload.newDescriptionBranch;
                            newScreenState.defaultBranchHierarchyDescription4 =
                                action.payload.newBranchHierachyDescription;
                            newScreenState.classify4BranchIdErrorMessage = "";
                            break;
                        case 5:
                            newScreenState.currentBranch5 = action.payload.newBranch;
                            newScreenState.currentDescriptionBranch5 =
                                action.payload.newDescriptionBranch;
                            newScreenState.defaultBranchHierarchyDescription5 =
                                action.payload.newBranchHierachyDescription;
                            newScreenState.classify5BranchIdErrorMessage = "";
                            break;
                    }
                    //workflow
                    if (action.payload.newWorkflowType !== "") {
                        newScreenState.currentWorkflowType = action.payload.newWorkflowType;
                        newScreenState.currentWorkflowDescription =
                            action.payload.newWorkflowDescription;
                        //newScreenState.currentWorkflowImage = "";
                    } else {
                        // if (newScreenState.currentTab === currentTabsEnum.workflow) {
                        //   newScreenState.currentTab = currentTabsEnum.comments;
                        // }
                        newScreenState.currentWorkflowType = "";
                        newScreenState.currentWorkflowDescription = "";
                        //newScreenState.currentWorkflowImage = "";
                    }

                    //additionalData
                    if (action.payload.newAdditionalDataList.length >= 1) {
                        newScreenState.showAdditionalData = true;
                        //insert new additonal data. Found new elementes that exists on command.payload.newAdditionalDataList
                        //that not exists on newAdminState.additionalDataValues
                        for (
                            let i: number = 0;
                            i <= action.payload.newAdditionalDataList.length - 1;
                            i++
                        ) {
                            found = false;
                            for (
                                let j: number = 0;
                                j <= newScreenState.additionalDataValues.length - 1;
                                j++
                            ) {
                                if (
                                    action.payload.newAdditionalDataList[i].id.toUpperCase() ===
                                    newScreenState.additionalDataValues[j].key.toUpperCase()
                                ) {
                                    found = true;
                                    newScreenState.additionalDataValues[j].value3 = true;
                                    break;
                                }
                            }
                            if (!found) {
                                newScreenState.additionalDataValues.push({
                                    key: action.payload.newAdditionalDataList[i].id,
                                    value: getAdditionalDataCaseValue(
                                        action.payload.newAdditionalDataList[i].id
                                    ),
                                    value2: action.payload.newAdditionalDataList[i],
                                    value3: true,
                                });
                            }
                        }
                        //change value3 to false for elements from newAdminState.additionalDataValues that does not exists on
                        //command.payload.newAdditionalDataList
                        for (
                            let i: number = 0;
                            i <= newScreenState.additionalDataValues.length - 1;
                            i++
                        ) {
                            found = false;
                            for (
                                let j: number = 0;
                                j <= action.payload.newAdditionalDataList.length - 1;
                                j++
                            ) {
                                if (
                                    newScreenState.additionalDataValues[i].key.toUpperCase() ===
                                    action.payload.newAdditionalDataList[j].id.toUpperCase()
                                ) {
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                newScreenState.additionalDataValues[i].value3 = false;
                            }
                        }
                        //todo order newAdminState.additionalDataValues by order in value2
                    } else {
                        newScreenState.showAdditionalData = false;
                        //dont clear newAdminState.additionalDataValues to cache previously gathered
                        //values but hide everythingscreenState.currentTree1
                        for (
                            let i: number = 0;
                            i <= newScreenState.additionalDataValues.length - 1;
                            i++
                        ) {
                            newScreenState.additionalDataValues[i].value3 = false;
                        }
                    }

                    return newScreenState;
                case commandsEnum.organization_relation_clear:
                    newScreenState.baseLevelId = "";
                    newScreenState.currentTree1 = "";
                    newScreenState.currentTree2 = "";
                    newScreenState.currentTree3 = "";
                    newScreenState.currentTree4 = "";
                    newScreenState.currentTree5 = "";

                    newScreenState.currentBranch1 = "";
                    newScreenState.currentBranch2 = "";
                    newScreenState.currentBranch3 = "";
                    newScreenState.currentBranch4 = "";
                   
                    newScreenState.reclassificationComments = "";
                    newScreenState.messageComments = "";

                    newScreenState.mustSelectLastLevelBranch1 = false;
                    newScreenState.mustSelectLastLevelBranch2 = false;
                    newScreenState.mustSelectLastLevelBranch3 = false;
                    newScreenState.mustSelectLastLevelBranch4 = false;
                    newScreenState.mustSelectLastLevelBranch5 = false;

                    newScreenState.currentLabelTree1 = "";
                    newScreenState.currentLabelTree2 = "";
                    newScreenState.currentLabelTree3 = "";
                    newScreenState.currentLabelTree4 = "";
                    newScreenState.currentLabelTree5 = "";
                    return newScreenState;
                case commandsEnum.change_quick_classifier:
                    newScreenState.quickClassifierId =
                        action.payload.newQuickClassifierId;
                    newScreenState.currentBranch1 = action.payload.newbranch1;
                    newScreenState.currentBranch2 = action.payload.newbranch2;
                    newScreenState.currentBranch3 = action.payload.newbranch3;
                    newScreenState.currentBranch4 = action.payload.newbranch4;
                    newScreenState.currentBranch5 = action.payload.newbranch5;
                    newScreenState.currentDescriptionBranch1 =
                        action.payload.newDescriptionBranch1;
                    newScreenState.currentDescriptionBranch2 =
                        action.payload.newDescriptionBranch2;
                    newScreenState.currentDescriptionBranch3 =
                        action.payload.newDescriptionBranch3;
                    newScreenState.currentDescriptionBranch4 =
                        action.payload.newDescriptionBranch4;
                    newScreenState.currentDescriptionBranch5 =
                        action.payload.newDescriptionBranch5;
                    newScreenState.defaultBranchHierarchyDescription1 =
                        action.payload.newHierarchyDescriptionBranch1;
                    newScreenState.defaultBranchHierarchyDescription2 =
                        action.payload.newHierarchyDescriptionBranch2;
                    newScreenState.defaultBranchHierarchyDescription3 =
                        action.payload.newHierarchyDescriptionBranch3;
                    newScreenState.defaultBranchHierarchyDescription4 =
                        action.payload.newHierarchyDescriptionBranch4;
                    newScreenState.defaultBranchHierarchyDescription5 =
                        action.payload.newHierarchyDescriptionBranch5;
                    newScreenState.classify1BranchIdErrorMessage =
                        action.payload.newBranch1ErrorMessage;
                    newScreenState.classify2BranchIdErrorMessage =
                        action.payload.newBranch2ErrorMessage;
                    newScreenState.classify3BranchIdErrorMessage =
                        action.payload.newBranch3ErrorMessage;
                    newScreenState.classify4BranchIdErrorMessage =
                        action.payload.newBranch4ErrorMessage;
                    newScreenState.classify5BranchIdErrorMessage =
                        action.payload.newBranch5ErrorMessage;

                    if (action.payload.newWorkflowType !== "") {
                        newScreenState.currentWorkflowType = action.payload.newWorkflowType;
                        newScreenState.currentWorkflowDescription =
                            action.payload.newWorkflowDescription;
                        //newScreenState.currentWorkflowImage = "";
                    } else {
                        // if (newScreenState.currentTab === currentTabsEnum.workflow) {
                        //   newScreenState.currentTab = currentTabsEnum.comments;
                        // }
                        newScreenState.currentWorkflowType = "";
                        newScreenState.currentWorkflowDescription = "";
                        //newScreenState.currentWorkflowImage = "";
                    }

                    //additionalData
                    if (action.payload.newAdditionalDataList.length >= 1) {
                        newScreenState.showAdditionalData = true;
                        //insert new additonal data. Found new elementes that exists on command.payload.newAdditionalDataList
                        //that not exists on newAdminState.additionalDataValues
                        for (
                            let i: number = 0;
                            i <= action.payload.newAdditionalDataList.length - 1;
                            i++
                        ) {
                            found = false;
                            for (
                                let j: number = 0;
                                j <= newScreenState.additionalDataValues.length - 1;
                                j++
                            ) {
                                if (
                                    action.payload.newAdditionalDataList[i].id.toUpperCase() ===
                                    newScreenState.additionalDataValues[j].key.toUpperCase()
                                ) {
                                    found = true;
                                    newScreenState.additionalDataValues[j].value3 = true;
                                    break;
                                }
                            }
                            if (!found) {
                                newScreenState.additionalDataValues.push({
                                    key: action.payload.newAdditionalDataList[i].id,
                                    value: getAdditionalDataCaseValue(
                                        action.payload.newAdditionalDataList[i].id
                                    ),
                                    value2: action.payload.newAdditionalDataList[i],
                                    value3: true,
                                });
                            }
                        }
                        //change value3 to false for elements from newAdminState.additionalDataValues that does not exists on
                        //command.payload.newAdditionalDataList
                        for (
                            let i: number = 0;
                            i <= newScreenState.additionalDataValues.length - 1;
                            i++
                        ) {
                            found = false;
                            for (
                                let j: number = 0;
                                j <= action.payload.newAdditionalDataList.length - 1;
                                j++
                            ) {
                                if (
                                    newScreenState.additionalDataValues[i].key.toUpperCase() ===
                                    action.payload.newAdditionalDataList[j].id.toUpperCase()
                                ) {
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                newScreenState.additionalDataValues[i].value3 = false;
                            }
                        }
                        //todo order newAdminState.additionalDataValues by order in value2
                    } else {
                        newScreenState.showAdditionalData = false;
                        //dont clear newAdminState.additionalDataValues to cache previously gathered
                        //values but hide everything
                        for (
                            let i: number = 0;
                            i <= newScreenState.additionalDataValues.length - 1;
                            i++
                        ) {
                            newScreenState.additionalDataValues[i].value3 = false;
                        }
                    }

                    if (branch1SelectorRef.current) {
                        branch1SelectorRef.current.resetData();
                    }
                    if (branch2SelectorRef.current) {
                        branch2SelectorRef.current.resetData();
                    }
                    if (branch3SelectorRef.current) {
                        branch3SelectorRef.current.resetData();
                    }
                    if (branch4SelectorRef.current) {
                        branch4SelectorRef.current.resetData();
                    }
                    if (branch5SelectorRef.current) {
                        branch5SelectorRef.current.resetData();
                    }
                    return newScreenState;
                case commandsEnum.change_additional_data_value:
                    for (
                        let i: number = 0;
                        i <= newScreenState.additionalDataValues.length - 1;
                        i++
                    ) {
                        if (
                            newScreenState.additionalDataValues[i].key.toUpperCase() ===
                            action.payload.additionalDataId.toUpperCase()
                        ) {
                            newScreenState.additionalDataValues[i].value =
                                action.payload.newValue;
                            break;
                        }
                    }
                    return newScreenState;
                default:
                    return state;
            }
        };

        const [screenState, dispatch] = React.useReducer(
            reducer,
            initialScreenState
        );

        const handleReclassificationComments = (value: any) => {
            dispatch({
                type: commandsEnum.change_property_value,
                payload: {
                    propertyName: "reclassificationComments",
                    // propertyName: "commentsErrorMessage",
                    newPropertyValue: value,
                },
            });
        };

        const handleMessageComments = (value: any) => {
            dispatch({
                type: commandsEnum.change_property_value,
                payload: {
                    propertyName: "messageComments",
                    // propertyName: "commentsErrorMessage",
                    newPropertyValue: value,
                },
            });
        };
        useEffect(() => {
            loadResourcesAndOrganizationAndRelation();
        }, []);
        return (
            <div className="container-fluid">
                <TPLoadingOverlay active={isLoadingScreen}>
                    <div
                        className={`tab-pane fade ${"show active"}`}
                        id="nav-case"
                        role="tabpanel"
                        aria-labelledby="nav-case-tab"
                        style={{
                            minHeight: "600px",
                            border: "1px solid #dee2e6",
                            padding: "10px",
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}
                    >
                        {/* accordions zone */}
                        <div
                            style={{
                                border: "1px solid  #dee2e6",
                                borderTop: "none",
                                height: "auto",
                            }}
                        >
                            <div className="accordion">
                                {/* Customer information */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button`}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseCustomerInfo"
                                            onClick={() => TPGlobal.foo()}
                                        >
                                            {customerInformationLabel}
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseCustomerInfo"
                                        className="accordion-collapse collapse"
                                    >
                                        <div className={`accordion-body show`}>
                                            <div className="row">
                                                {/* Inicio customer search */}
                                                <div className="col-md-5">
                                                    <div className="col-md-12">
                                                        <div className="col-md-12 text-center">
                                                            {/* <img src={buddyImg} width={80}></img> */}
                                                            <DefaultAvatarIcon
                                                                style={{ cursor: "pointer" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {!isAnonymousCustomer && (
                                                        <div className="row mt-2 d-flex align-items-center justify-content-between">
                                                            <div className="col-7">
                                                                <TPPageTitle style={{ fontSize: "20px" }}>
                                                                    {"CUSTOMER SEARCH"}
                                                                </TPPageTitle>
                                                            </div>
                                                            <div className="col d-flex justify-content-end">
                                                                {canCreateAnyCustomer && (
                                                                    <TPButton
                                                                        type={TPButtonTypes.toolbar}
                                                                        onClick={() => handleNewCustomerClick()}
                                                                    >
                                                                        <NewCustomerIcon />
                                                                        {newCustomerButtonLabel}
                                                                    </TPButton>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {currentSearchCriteria !==
                                                        searchCiteriaEnum.additionaldata &&
                                                        searchCriteriaList.length > 0 && (
                                                            <>
                                                                <div className="row mt-2">
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <TPSelect
                                                                                id="IdSelect"
                                                                                isMandatory={true}
                                                                                labelText={searchCriteriaLabel}
                                                                                onChange={
                                                                                    handleCustomerSearchCriteriaChange
                                                                                }
                                                                                dataSource={searchCriteriaList}
                                                                                value={currentSearchCriteria}
                                                                                disabled={isAnonymousCustomer}
                                                                                errorMessage={
                                                                                    selectSearchCriteriaErrorMessage
                                                                                }
                                                                            ></TPSelect>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <TPTextBox
                                                                                id="IdTextBox"
                                                                                onChange={(e: any) => {
                                                                                    handleChangeCustormerSearchValue(e);
                                                                                }}
                                                                                value={currentSearchCustomerValue}
                                                                                labelText={searchCriteriaValueLabel}
                                                                                isMandatory={true}
                                                                                disabled={isAnonymousCustomer}
                                                                                errorMessage={
                                                                                    selectSearchValueErrorMessage
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    <div className="row mt-2">
                                                        <div className="col-6">
                                                            <TPCheckBox
                                                                id="IdCheckBox"
                                                                className="mt-2"
                                                                checked={isAnonymousCustomer}
                                                                labelText={anonymousCustomerLabel}
                                                                onChange={handleAnonymousOnChange}
                                                            />
                                                        </div>
                                                        <div className="col-3">
                                                            {cancelAlreadyHaveCustomer && (
                                                                <TPButton
                                                                    type={TPButtonTypes.link}
                                                                    onClick={handleCancelCustomerOnClick}
                                                                >
                                                                    {cancelButtonLabel}
                                                                </TPButton>
                                                            )}
                                                        </div>
                                                        <div className="col-3">
                                                            {!isAnonymousCustomer && (
                                                                <TPButton
                                                                    text=""
                                                                    children={<span>{searchCustomerLabel}</span>}
                                                                    onClick={handleSearchOnClick}
                                                                    type={TPButtonTypes.primary}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Customer information FORM  */}
                                                <div
                                                    className="col-md-7"
                                                    style={{
                                                        display: alreadyHaveCustomer ? "block" : "none",
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <TPTextBox
                                                                id="IdTextBox"
                                                                onChange={() => {
                                                                    TPGlobal.foo();
                                                                }}
                                                                value={dataCustomerResultsType}
                                                                labelText={customerResultsTypeLabel}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <TPTextBox
                                                                id="IdTextBox"
                                                                onChange={() => {
                                                                    TPGlobal.foo();
                                                                }}
                                                                value={dataCustomerResultsNames}
                                                                labelText={customerResultsNamesLabel}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <TPTextBox
                                                                id="IdTextBox"
                                                                onChange={() => {
                                                                    TPGlobal.foo();
                                                                }}
                                                                value={dataCustomerResultsDocumentType}
                                                                labelText={customerResultsDocumentTypeLabel}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <TPTextBox
                                                                id="IdTextBox"
                                                                onChange={() => {
                                                                    TPGlobal.foo();
                                                                }}
                                                                value={dataCustomerResultsDocument}
                                                                labelText={customerResultsDocumentLabel}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <TPTextBox
                                                                id="IdTextBox"
                                                                onChange={() => {
                                                                    TPGlobal.foo();
                                                                }}
                                                                value={dataCustomerResultsEmail}
                                                                labelText={customerResultsEmailLabel}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <TPTextBox
                                                                id="IdTextBox"
                                                                onChange={() => {
                                                                    TPGlobal.foo();
                                                                }}
                                                                value={dataCustomerResultsPhone}
                                                                labelText={customerResultsPhoneLabel}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <TPTextBox
                                                                id="IdTextBox"
                                                                onChange={() => {
                                                                    TPGlobal.foo();
                                                                }}
                                                                value={dataCustomerResultsInternalCode}
                                                                labelText={customerResultsInternalCodeLabel}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        {/* <div className="col-md-6" style={{ marginTop: "30px" }}>
                              {enableEditCustomer && (
                                <TPButton
                                  text=""
                                  children={<span>{editCustomerButtonLabel}</span>}
                                  onClick={() => {
                                    handleEditCustomerOnClick();
                                  }}
                                  type={TPButtonTypes.primary}
                                />
                              )}
                            </div> */}
                                                    </div>
                                                    {/* <div className="row mt-2">
                            <div className="col-12">
                              {adminState.selectedContactAsReporter !== 0 && (
                                <>
                                  <span style={{ color: "red", fontWeight: "600" }}>
                                    {personWhoReportsLabel}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "14px" }}>
                                    {adminState.selectedContactName}
                                  </span>
                                </>
                              )}
                            </div>
                          </div> */}
                                                    <div className="row mt-2">
                                                        <div className="col-12">
                                                            <TPButton
                                                                children={
                                                                    <span>{searchAgainCustomerButtonLabel}</span>
                                                                }
                                                                onClick={handleSearchAgainOnClick}
                                                                type={TPButtonTypes.link}
                                                                style={{ padding: "0" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Customer accordion */}

                                <div className="accordion-item">{children}</div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button`}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseReclassify2"
                                            onClick={() => TPGlobal.foo()}
                                        >
                                            {newReclassificationCaseLabel}
                                        </button>
                                    </h2>
                                    <div id="collapseReclassify2" className="accordion-collapse">
                                        <div className={`accordion-body show`}>
                                            <div className="row">
                                                <div className="col-4">                                              
                                                    <TPSelect
                                                        id="IdSelect"
                                                        onChange={handleOrganizationRelationChange}
                                                        dataSource={organizationsRelationsKeyValue}
                                                        value={screenState.baseLevelId}
                                                        labelText={organizationsRelationsLabel}
                                                        isMandatory={true}
                                                        errorMessage={screenState.baseLevelIdErrorMessage}
                                                    ></TPSelect>
                                                </div>

                                                <div className="col-4">
                                                    {quickClassifiersListKeyValue.length >= 1 && (
                                                        <TPSelect
                                                            id="IdSelect"
                                                            onChange={handleQuickClassifierOnChange}
                                                            dataSource={quickClassifiersListKeyValue}
                                                            value={screenState.quickClassifierId}
                                                            labelText={quickClassfierLabel}
                                                        ></TPSelect>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="row">
                                                {/* Classificator 1 */}
                                                {screenState.baseLevelId === "" &&
                                                    screenState.currentTree1 !==
                                                    TPGlobal.Tree.NA_TreeCode ? (
                                                    <>
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <span className="tpcircle-text">1</span>&nbsp;
                                                                {"-"}
                                                                &nbsp;
                                                                <TPTextBox
                                                                    id="IdTextBox"
                                                                    onChange={() => {
                                                                        TPGlobal.foo();
                                                                    }}
                                                                    value=""
                                                                    disabled={true}
                                                                    labelText={dummyClassfierLabel}
                                                                    isMandatory={true}
                                                                    errorMessage={
                                                                        screenState.classify1BranchIdErrorMessage
                                                                    }
                                                                    withCircleText
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {screenState.currentTree1 !==
                                                            TPGlobal.Tree.NA_TreeCode && (
                                                                <div className="col-4">
                                                                    <span className="tpcircle-text">1</span>
                                                                    &nbsp;
                                                                    {"-"}
                                                                    &nbsp;
                                                                    <TPBranchSelection2
                                                                        ref={branch1SelectorRef}
                                                                        labelText={screenState.currentLabelTree1}
                                                                        treeId={screenState.currentTree1}
                                                                        modalAcceptLabel={modalAcceptLabel}
                                                                        modalCancelLabel={modalCancelLabel}
                                                                        modalSelectedBranchLabel={
                                                                            modalSelectedBranchLabel
                                                                        }
                                                                        modalTitle={modalTitleLabel}
                                                                        mustSelectLastLevelBranch={
                                                                            screenState.mustSelectLastLevelBranch1
                                                                        }
                                                                        value={{
                                                                            branchId: screenState.currentBranch1,
                                                                            branchDescription:
                                                                                screenState.currentDescriptionBranch1,
                                                                            branchHierarchyDescription:
                                                                                screenState.defaultBranchHierarchyDescription1,
                                                                        }}
                                                                        onChange={(
                                                                            branchId: string,
                                                                            branchDescription: string,
                                                                            branchHierachyDescription: string
                                                                        ) => {
                                                                            handleOnBranchChange(
                                                                                1,
                                                                                branchId,
                                                                                branchDescription,
                                                                                branchHierachyDescription
                                                                            );
                                                                        }}
                                                                        emptyLabel={emptyLabel}
                                                                        errorMessage={
                                                                            screenState.classify1BranchIdErrorMessage
                                                                        }
                                                                        autoCloseTreeModalWhenSelect={true}
                                                                        onIsLoadingProgress={(value: boolean) => {
                                                                            setIsLoadingScreen(value);
                                                                        }}
                                                                        isMandatory={true}
                                                                    ></TPBranchSelection2>
                                                                </div>
                                                            )}
                                                    </>
                                                )}
                                                {/* Classificator 2 */}
                                                {screenState.baseLevelId === "" &&
                                                    screenState.currentTree2 !==
                                                    TPGlobal.Tree.NA_TreeCode ? (
                                                    <>
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <span className="tpcircle-text">2</span>&nbsp;
                                                                {"-"}
                                                                &nbsp;
                                                                <TPTextBox
                                                                    id="IdTextBox"
                                                                    onChange={() => {
                                                                        TPGlobal.foo();
                                                                    }}
                                                                    value=""
                                                                    disabled={true}
                                                                    labelText={dummyClassfierLabel}
                                                                    isMandatory={true}
                                                                    errorMessage={
                                                                        screenState.classify2BranchIdErrorMessage
                                                                    }
                                                                    withCircleText
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {screenState.currentTree2 !==
                                                            TPGlobal.Tree.NA_TreeCode && (
                                                                <div className="col-4">
                                                                    <span className="tpcircle-text">2</span>
                                                                    &nbsp;
                                                                    {"-"}
                                                                    &nbsp;
                                                                    <TPBranchSelection2
                                                                        ref={branch2SelectorRef}
                                                                        labelText={screenState.currentLabelTree2}
                                                                        treeId={screenState.currentTree2}
                                                                        modalAcceptLabel={modalAcceptLabel}
                                                                        modalCancelLabel={modalCancelLabel}
                                                                        modalSelectedBranchLabel={
                                                                            modalSelectedBranchLabel
                                                                        }
                                                                        modalTitle={modalTitleLabel}
                                                                        mustSelectLastLevelBranch={
                                                                            screenState.mustSelectLastLevelBranch2
                                                                        }
                                                                        value={{
                                                                            branchId: screenState.currentBranch2,
                                                                            branchDescription:
                                                                                screenState.currentDescriptionBranch2,
                                                                            branchHierarchyDescription:
                                                                                screenState.defaultBranchHierarchyDescription2,
                                                                        }}
                                                                        onChange={(
                                                                            branchId: string,
                                                                            branchDescription: string,
                                                                            branchHierachyDescription: string
                                                                        ) => {
                                                                            handleOnBranchChange(
                                                                                2,
                                                                                branchId,
                                                                                branchDescription,
                                                                                branchHierachyDescription
                                                                            );
                                                                        }}
                                                                        emptyLabel={emptyLabel}
                                                                        errorMessage={
                                                                            screenState.classify2BranchIdErrorMessage
                                                                        }
                                                                        autoCloseTreeModalWhenSelect={true}
                                                                        onIsLoadingProgress={(value: boolean) => {
                                                                            setIsLoadingScreen(value);
                                                                        }}
                                                                        isMandatory={true}
                                                                    ></TPBranchSelection2>
                                                                </div>
                                                            )}
                                                    </>
                                                )}
                                                {/* Classificator 3 */}
                                                {screenState.baseLevelId === "" &&
                                                    screenState.currentTree3 !==
                                                    TPGlobal.Tree.NA_TreeCode ? (
                                                    <>
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <span className="tpcircle-text">3</span>&nbsp;
                                                                {"-"}
                                                                &nbsp;
                                                                <TPTextBox
                                                                    id="IdTextBox"
                                                                    onChange={() => {
                                                                        TPGlobal.foo();
                                                                    }}
                                                                    value=""
                                                                    disabled={true}
                                                                    labelText={dummyClassfierLabel}
                                                                    isMandatory={true}
                                                                    errorMessage={
                                                                        screenState.classify3BranchIdErrorMessage
                                                                    }
                                                                    withCircleText
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {screenState.currentTree3 !==
                                                            TPGlobal.Tree.NA_TreeCode && (
                                                                <div className="col-4">
                                                                    <span className="tpcircle-text">3</span>
                                                                    &nbsp;
                                                                    {"-"}
                                                                    &nbsp;
                                                                    <TPBranchSelection2
                                                                        ref={branch3SelectorRef}
                                                                        labelText={screenState.currentLabelTree3}
                                                                        treeId={screenState.currentTree3}
                                                                        modalAcceptLabel={modalAcceptLabel}
                                                                        modalCancelLabel={modalCancelLabel}
                                                                        modalSelectedBranchLabel={
                                                                            modalSelectedBranchLabel
                                                                        }
                                                                        modalTitle={modalTitleLabel}
                                                                        mustSelectLastLevelBranch={
                                                                            screenState.mustSelectLastLevelBranch3
                                                                        }
                                                                        value={{
                                                                            branchId: screenState.currentBranch3,
                                                                            branchDescription:
                                                                                screenState.currentDescriptionBranch3,
                                                                            branchHierarchyDescription:
                                                                                screenState.defaultBranchHierarchyDescription3,
                                                                        }}
                                                                        onChange={(
                                                                            branchId: string,
                                                                            branchDescription: string,
                                                                            branchHierachyDescription: string
                                                                        ) => {
                                                                            handleOnBranchChange(
                                                                                3,
                                                                                branchId,
                                                                                branchDescription,
                                                                                branchHierachyDescription
                                                                            );
                                                                        }}
                                                                        emptyLabel={emptyLabel}
                                                                        errorMessage={
                                                                            screenState.classify3BranchIdErrorMessage
                                                                        }
                                                                        autoCloseTreeModalWhenSelect={true}
                                                                        onIsLoadingProgress={(value: boolean) => {
                                                                            setIsLoadingScreen(value);
                                                                        }}
                                                                        isMandatory={true}
                                                                    ></TPBranchSelection2>
                                                                </div>
                                                            )}
                                                    </>
                                                )}
                                            </div>
                                            <div className="row">
                                                {/* Classificator 4 */}
                                                {screenState.baseLevelId === "" &&
                                                    screenState.currentTree4 !==
                                                    TPGlobal.Tree.NA_TreeCode ? (
                                                    <>
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <span className="tpcircle-text">4</span>&nbsp;
                                                                {"-"}
                                                                &nbsp;
                                                                <TPTextBox
                                                                    id="IdTextBox"
                                                                    onChange={() => {
                                                                        TPGlobal.foo();
                                                                    }}
                                                                    value=""
                                                                    disabled={true}
                                                                    labelText={dummyClassfierLabel}
                                                                    isMandatory={true}
                                                                    errorMessage={
                                                                        screenState.classify4BranchIdErrorMessage
                                                                    }
                                                                    withCircleText
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {screenState.currentTree4 !==
                                                            TPGlobal.Tree.NA_TreeCode && (
                                                                <div className="col-4">
                                                                    <span className="tpcircle-text">4</span>
                                                                    &nbsp;
                                                                    {"-"}
                                                                    &nbsp;
                                                                    <TPBranchSelection2
                                                                        ref={branch4SelectorRef}
                                                                        labelText={screenState.currentLabelTree4}
                                                                        treeId={screenState.currentTree4}
                                                                        modalAcceptLabel={modalAcceptLabel}
                                                                        modalCancelLabel={modalCancelLabel}
                                                                        modalSelectedBranchLabel={
                                                                            modalSelectedBranchLabel
                                                                        }
                                                                        modalTitle={modalTitleLabel}
                                                                        mustSelectLastLevelBranch={
                                                                            screenState.mustSelectLastLevelBranch4
                                                                        }
                                                                        value={{
                                                                            branchId: screenState.currentBranch4,
                                                                            branchDescription:
                                                                                screenState.currentDescriptionBranch4,
                                                                            branchHierarchyDescription:
                                                                                screenState.defaultBranchHierarchyDescription4,
                                                                        }}
                                                                        onChange={(
                                                                            branchId: string,
                                                                            branchDescription: string,
                                                                            branchHierachyDescription: string
                                                                        ) => {
                                                                            handleOnBranchChange(
                                                                                4,
                                                                                branchId,
                                                                                branchDescription,
                                                                                branchHierachyDescription
                                                                            );
                                                                        }}
                                                                        emptyLabel={emptyLabel}
                                                                        errorMessage={
                                                                            screenState.classify4BranchIdErrorMessage
                                                                        }
                                                                        autoCloseTreeModalWhenSelect={true}
                                                                        onIsLoadingProgress={(value: boolean) => {
                                                                            setIsLoadingScreen(value);
                                                                        }}
                                                                        isMandatory={true}
                                                                    ></TPBranchSelection2>
                                                                </div>
                                                            )}
                                                    </>
                                                )}
                                                {/* Classificator 5 */}
                                                {screenState.baseLevelId === "" &&
                                                    screenState.currentTree4 !==
                                                    TPGlobal.Tree.NA_TreeCode ? (
                                                    <>
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <span className="tpcircle-text">5</span>&nbsp;
                                                                {"-"}
                                                                &nbsp;
                                                                <TPTextBox
                                                                    id="IdTextBox"
                                                                    onChange={() => {
                                                                        TPGlobal.foo();
                                                                    }}
                                                                    value=""
                                                                    disabled={true}
                                                                    labelText={dummyClassfierLabel}
                                                                    isMandatory={true}
                                                                    errorMessage={
                                                                        screenState.classify5BranchIdErrorMessage
                                                                    }
                                                                    withCircleText
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {screenState.currentTree5 !==
                                                            TPGlobal.Tree.NA_TreeCode && (
                                                                <div className="col-4">
                                                                    <span className="tpcircle-text">5</span>
                                                                    &nbsp;
                                                                    {"-"}
                                                                    &nbsp;
                                                                    <TPBranchSelection2
                                                                        ref={branch5SelectorRef}
                                                                        labelText={screenState.currentLabelTree5}
                                                                        treeId={screenState.currentTree5}
                                                                        modalAcceptLabel={modalAcceptLabel}
                                                                        modalCancelLabel={modalCancelLabel}
                                                                        modalSelectedBranchLabel={
                                                                            modalSelectedBranchLabel
                                                                        }
                                                                        modalTitle={modalTitleLabel}
                                                                        mustSelectLastLevelBranch={
                                                                            screenState.mustSelectLastLevelBranch5
                                                                        }
                                                                        value={{
                                                                            branchId: screenState.currentBranch5,
                                                                            branchDescription:
                                                                                screenState.currentDescriptionBranch5,
                                                                            branchHierarchyDescription:
                                                                                screenState.defaultBranchHierarchyDescription5,
                                                                        }}
                                                                        onChange={(
                                                                            branchId: string,
                                                                            branchDescription: string,
                                                                            branchHierachyDescription: string
                                                                        ) => {
                                                                            handleOnBranchChange(
                                                                                5,
                                                                                branchId,
                                                                                branchDescription,
                                                                                branchHierachyDescription
                                                                            );
                                                                        }}
                                                                        emptyLabel={emptyLabel}
                                                                        errorMessage={
                                                                            screenState.classify5BranchIdErrorMessage
                                                                        }
                                                                        autoCloseTreeModalWhenSelect={true}
                                                                        onIsLoadingProgress={(value: boolean) => {
                                                                            setIsLoadingScreen(value);
                                                                        }}
                                                                        isMandatory={true}
                                                                    ></TPBranchSelection2>
                                                                </div>
                                                            )}
                                                    </>
                                                )}
                                                <div className="col-4">
                                                    <TPSelect
                                                        id="IdSelect"
                                                        isMandatory={true}
                                                        onChange={handleReclassificationReasonOnChange}
                                                        dataSource={reclassificationReasonKeyValue}
                                                        value={screenState.reclassificationReasonId}
                                                        labelText={
                                                            <TPLanguage
                                                                resourceSet={resourceSet}
                                                                resourceId={"ReclasificationReasonlabel"}
                                                            />
                                                        }
                                                        errorMessage={
                                                            screenState.reclassificationReasonIdErrorMessage
                                                        }
                                                    ></TPSelect>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <TPRadioGroup
                                                        id="IdRadioGroup"
                                                        labelText={selectOptionToApplyLabel}
                                                        value={screenState.reclassificationOptionId}
                                                        source={reclassificationOptionsKeyValue}
                                                        onChange={(e: any) =>
                                                            dispatch({
                                                                type: commandsEnum.change_property_value,
                                                                payload: {
                                                                    propertyName: "reclassificationOptionId",
                                                                    newPropertyValue: e.target.value,
                                                                },
                                                            })
                                                        }
                                                        errorMessage={
                                                            screenState.reclassificationOptionIdErrorMessage
                                                        }
                                                    />
                                                </div>
                                                {(screenState.reclassificationOptionId ===
                                                    "S_RECLAOPTKEW" ||
                                                    screenState.reclassificationOptionId ===
                                                    "S_RECLAOPTRW") && (
                                                        <div className="col-4">
                                                            <TPRadioGroup
                                                                id="IdRadioGroup"
                                                                labelText={ifSameWorkflowLabel}
                                                                value={
                                                                    screenState.reclassificationOptionSameWorkflow
                                                                }
                                                                source={sameWorkflowOptionsKeyValue}
                                                                onChange={(e: any) =>
                                                                    dispatch({
                                                                        type: commandsEnum.change_property_value,
                                                                        payload: {
                                                                            propertyName:
                                                                                "reclassificationOptionSameWorkflow",
                                                                            newPropertyValue: e.target.value,
                                                                        },
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div>
                                                        <TPLabel
                                                            isMandatory={true}
                                                            labelText={
                                                                <TPLanguage
                                                                    resourceSet={resourceSet}
                                                                    resourceId={"ReclassificationCommentsLabel"}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                    <TPEditor
                                                        referece={(editor: any) =>
                                                            (editorRef.current = editor)
                                                        }
                                                        placeholder=""
                                                        onChange={handleReclassificationComments}
                                                        value={screenState.reclassificationComments}
                                                        initialValue=""
                                                        isVisible={false}
                                                    />
                                                    <span style={{ color: "#dc3545", fontSize: "14px" }}>
                                                        {screenState.reclassificationCommentsErrorMessage}
                                                    </span>
                                                </div>
                                                <div className="col-6">
                                                    <div>
                                                        <TPLabel
                                                            isMandatory={true}
                                                            labelText={
                                                                <TPLanguage
                                                                    resourceSet={resourceSet}
                                                                    resourceId={"MessageLabel"}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                    <TPEditor
                                                        referece={(editor: any) =>
                                                            (classificationCommentsEditorRef.current = editor)
                                                        }
                                                        placeholder=""
                                                        onChange={handleMessageComments}
                                                        value={screenState.messageComments}
                                                        initialValue={caseInfo.comments}
                                                        isVisible={false}
                                                    />
                                                    <span style={{ color: "#dc3545", fontSize: "14px" }}>
                                                        {screenState.commentsErrorMessage}
                                                    </span>
                                                </div>
                                            </div>
                                            {hasAnyVisibleAdditionalData() && (
                                                <>
                                                    <div className="row mt-1">
                                                        <div className="col-12">
                                                            {hasAnyVisibleAdditionalData() && (
                                                                <h2 className="tpbold tpfont16">
                                                                    <TPLanguage
                                                                        resourceSet={resourceSetNewCase}
                                                                        resourceId={"AdditionalDataLabel"}
                                                                    />
                                                                </h2>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row new-case__additional_data_container">
                                                        {screenState.additionalDataValues.map(
                                                            function (item) {
                                                                let elementJsx;
                                                                let index: number;
                                                                let value: any;
                                                                if (item.value3 === true) {
                                                                    index =
                                                                        screenState.additionalDataValues.findIndex(
                                                                            (x: TPKeyValue) => x.key == item.key
                                                                        );
                                                                    value =
                                                                        screenState.additionalDataValues[index]
                                                                            .value;
                                                                    elementJsx = (
                                                                        <div
                                                                            className="col-4 mb-2"
                                                                            key={
                                                                                "adda" + screenState.caseGuid + item.key
                                                                            }
                                                                        >
                                                                            <TPRenderAdditionalDataSwitch
                                                                                guidControl={screenState.caseGuid}
                                                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                                                defaultValue={value}
                                                                                itemToRender={item}
                                                                                ref={(element: any) => {
                                                                                    childRefArray.current[item.key] =
                                                                                        element;
                                                                                }}
                                                                                onChange={(
                                                                                    idControl: any,
                                                                                    newValue: string,
                                                                                    addtionalDataId: string
                                                                                ) =>
                                                                                    handleChangeAditionalDataValue(
                                                                                        idControl,
                                                                                        newValue,
                                                                                        item.key
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    elementJsx = null;
                                                                }
                                                                return elementJsx;
                                                            }
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            <div className="row mt-1">
                                                <div className="col-12 d-flex justify-content-between align-items-center gap-4 ">
                                                    <div className="new-case__footer_buttons-actions">
                                                        {screenState.currentWorkflowType != "" && (
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span
                                                                    className="tpbold"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    <TPLanguage
                                                                        resourceSet={resourceSetNewCase}
                                                                        resourceId={"WorkflowTypeLabel"}
                                                                    />
                                                                    :
                                                                </span>

                                                                <a
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        textDecoration: "underline",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => handleViewDiagram()}
                                                                >
                                                                    {screenState.currentWorkflowDescription}
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Customer Search */}
                    <TPModalSearchClient
                        title={searchClientModalTitle}
                        acceptLabel={okLabel}
                        cancelLabel={cancelLabel}
                        callBackAnswer={callBackModalCustomerSearch}
                        initialState={modalSearchCustomerState}
                    />
                </TPLoadingOverlay>
                <div
                    className={diagramModal.classModalDiagram}
                    tabIndex={-1}
                    data-bs-backdrop={diagramModal.backdropClass}
                    style={diagramModal.styleModalDiagram}
                >
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header justify-content-between">
                                <h5 className="modal-title">{diagramModal.title}</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        setDiagramModal(initialDiagramModal);
                                    }}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-4"></div>
                                    <div className="col-4">
                                        {currentWorkflowImage && (
                                            <div className="tpw100per">
                                                <img
                                                    src={"data:image/png;base64," + currentWorkflowImage}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                TPClient Copyright &copy; {TPGlobal.currentYear}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default ReclassifyCase;
