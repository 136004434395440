import { useEffect, DragEvent, useState } from "react";
import { LoaderRing, LoaderWrapper, StyledDragFile, StyledDragText, StyledFileList, StyledLoadFileDialog } from "../../modules/core/design-system/design-system-styles";
import { v4 as uuidv4 } from "uuid";

import { DragAndDropFileLabels } from "../../modules/core/design-system/design-system.model";
import { AttachmentLabels } from "../../models/EmailTemplates/EmailTemplateModels";
import TPGlobal from "../../helpers/TPGlobal";
import { TemporaryAttachmentService } from "../../services/TemporaryAttachments";
import { TPIconTypes } from "../../models/Global/TPGlobalEnums";
import TPIcon from "../../components/bootstrap/extend/TPIcons/TPIcon";
import TPButton from "../../components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "../../components/bootstrap/components/buttons/tpButtonStyles";
import { TPI18N } from "../../services/I18nService";
import { acceptedExt } from "../../components/TPDragAndDropUploadFile/utils";

type DragAndDropFilesProps = {
    accept?: string[];
    fileName?: string;
    selectFileLabel?: string;
    maxSizeLabel?: string;
    maxMBFileSize?: number;
    style?: React.CSSProperties;
    onFilesChange: (files: File) => void;
    onCancelChange: () => void;
};


const UploadFile = ({
    maxMBFileSize = 1,
    selectFileLabel,
    accept,
    style,
    onFilesChange,
    onCancelChange,
    fileName }: DragAndDropFilesProps) => {
    const resourceSet: string = "UploadFileImages";
    const service = new TemporaryAttachmentService();
    const inputId: string = `files-input-${uuidv4()}_${Date.now()}`;

    const [filesType, setFilesType] = useState<string>("");
    const [formatIsNotAllowed, setFormatIsNotAllowed] = useState<string>("");
    const [fileSuccess, setFileSuccess] = useState<string>("");

    const loadResources = async () => {
        setFilesType(await TPI18N.GetText(TPGlobal.globalResourceSet, "SelectOrdrop"));
        setFormatIsNotAllowed(await TPI18N.GetText(TPGlobal.globalResourceSet, "FormatIsNotAllowed"));
        setFileSuccess(await TPI18N.GetText("DragFileModal", "FileSuccess"));
    }

    const [file, setFile] = useState<File | null>();
    const [dragging, setDragging] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFailed, setIsFailed] = useState<boolean>(false);

    const setFilesToLoad = (file: File): void => {
        if (!isValidFile(file)) {
            setIsFailed(true);
        } else {
            onFilesChange(file);
        }
        setFile(file);
        setIsLoading(false);
    };

    const isValidFile = (file: File): boolean => {
        return isValidFormatFile(file) && isValidSizeFile(file);
    };

    const isValidSizeFile = (file: File): boolean => {
        const fileSizeInMB = file.size / (1024 * 1024);
        return fileSizeInMB <= maxMBFileSize;
    };

    const isValidFormatFile = (file: File): boolean => {
        let status = false;
        if (accept) {
            accept.forEach((e: string) => {
                if (file.type.split("/").length > 0)
                    if (e.toLocaleLowerCase().includes(file.type.split("/")[1].toLocaleLowerCase())) {
                        status = true;
                    }
            });
        }

        return status;

    };

    const getResumedFileExtension = (fileName: string): string => {
        const parts = fileName.split(".");
        return parts[parts.length - 1].toLocaleLowerCase();
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(true);
    };

    const getBlobId = (): string => {
        return `blob${uuidv4().replaceAll("-", "")}`;
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        setIsLoading(true);
        e.preventDefault();
        setDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setFilesToLoad(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
        }
    };

    const onFilesSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        if (event.target.files) {
            setFilesToLoad(event.target.files[0]);
            event.target.value = "";
        }
    };

    const getSizeFormatBy = (file: File): string => {
        let bytes = file.size;
        if (bytes < 1024) return bytes + " bytes";
        if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(2) + " KB";
        return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const openFileExplorer = () => {
        const input = document.getElementById(inputId) as HTMLInputElement;
        if (input) {
            input.click();
        }
    };

    const getCurrentFilesStatus = (): string => {
        //if (!files || files.length === 0) {
        //    return baseLabels.noFiles;
        //}

        //const loadingCount = files.filter(file => file.isLoading && !file.completed && !file.failed).length;
        //const completedCount = files.filter(file => file.completed).length;
        //const errorCount = files.filter(file => file.failed || file.isErrorFile).length;

        //return `${baseLabels.loading} (${loadingCount}) ${baseLabels.completed} (${completedCount}) ${baseLabels.error} (${errorCount})`;

        return "...";
    }

    const getFileMessageBy = (): string => {
        return isFailed ? formatIsNotAllowed : fileSuccess;
    };

    const removeFileBy = () => {
        setIsFailed(false);
        setFile(null);
        if (onCancelChange) onCancelChange();
    };

    useEffect(() => {
        loadResources();
    }, []);

    return (
        <StyledLoadFileDialog style={style ? style : { gap: 0, minWidth: "100%", marginBottom: "0px" }}>
            {!file && !fileName  &&
                <StyledDragFile
                    dragging={true}
                    fileExist={false}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    style={{
                        minWidth: "100%"
                    }}
                >
                    <StyledDragText>
                        <TPIcon iconType={TPIconTypes.cloud} />
                        <div className="text-container">
                            <p className="drop-label">{filesType + " (" + acceptedExt(accept) + ")"}</p>
                            <p className="size-label">{`${selectFileLabel} ${maxMBFileSize} MB`}</p>
                        </div>
                    </StyledDragText>

                    <input
                        type="file"
                        id={inputId}
                        name={inputId}
                        accept={acceptedExt(accept)}
                        onChange={onFilesSelected}
                        style={{ display: "none" }}
                        multiple
                    />

                    <div className="buttons-container">
                        <TPButton
                            id="select-file"
                            isDesignSystem
                            customType={ButtonCustomType.secondary}
                            onClick={openFileExplorer}
                            style={{ padding: "1px 18px" }}
                        >
                            {selectFileLabel}
                        </TPButton>
                    </div>
                </StyledDragFile>
            }

            {(isLoading || file || fileName) &&
                <>
                    <div className="file-list">
                        <StyledFileList
                            isErrorFile={isFailed || false}
                            className="file-item"
                            key={"id-file"}
                            style={{ minWidth: "100%", marginBottom: "0px" }}
                        >
                            <div className="file-info">
                                <div className="status-icons">
                                    {isLoading && !file ? (
                                        <LoaderWrapper>
                                            <LoaderRing />
                                        </LoaderWrapper>
                                    ) : (
                                        <TPIcon
                                            iconType={
                                                isFailed ? TPIconTypes.close : TPIconTypes.done
                                            }
                                        />
                                    )}
                                </div>
                            {(file || fileName) &&
                                    <div className="text-container">
                                    <p className="drop-label">{`${file ? file.name : fileName} ${file ? ("(" + getSizeFormatBy(file) +")"):""}`}</p>
                                        <p className="status-label">
                                            {getFileMessageBy()}
                                        </p>
                                    </div>
                                }
                            </div>
                            {(file || fileName) &&
                                <TPIcon
                                    className="cancel-btn"
                                    id="cancel-file"
                                    onClick={() => !isLoading && removeFileBy()}
                                    iconType={TPIconTypes.close}
                                />
                            }
                        </StyledFileList>


                    </div>
                </>
            }

        </StyledLoadFileDialog>
    );
};

export default UploadFile;
