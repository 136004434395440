
import React, { forwardRef, useEffect, useReducer, useState } from "react";
import { LocationLanguagesAdministrationUsersStation, LocationNameGlobal, LocationNameTable } from "./AdministrationUsersStationLocalization";
import { RightRowContainerStyle } from "../FormDesigner/StyleFromDesigner";
import TPGlobal from "../../helpers/TPGlobal";
import { TPActiveOptions, TPButtonTypes, TPIconTypes, TPQuickSelectDatesEnum } from "../../models/Global/TPGlobalEnums";
import DynamicTable, { ColumnStyles, CustomColumnNames } from "../../modules/core/components/dynamic-table/DynamicTable";
import { TPPageTitle } from "../../components/TPPage/tpPageStyles";
import { useStatusAdministrationUsersStation } from "./useStatusAdministrationUsersStation";
import TPTextBox from "../../components/bootstrap/forms/textbox/TPTextBox";
import { AdministrationUsersStationService } from "../../services/AdministrationUsersStationService";
import { TPLog, TPLogType } from "../../helpers/TPLog";
import TPLoadingOverlay from "../../components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { UserStation, UserStationConcept } from "../../models/AdministrationUsersStation/AdministrationUsersStation";
import TPButton from "../../components/bootstrap/components/buttons/TPButton";
import TPIcon from "../../components/bootstrap/extend/TPIcons/TPIcon";
import { MinorOption } from "../../modules/core/components/dynamic-table/TableActionItem";
import TPLabel from "../../components/bootstrap/forms/TPLabel/TPLabel";
import TPModalQuestion from "../../layouts/ModalQuestion/TPModalQuestion";


type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<any>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};
type commandType = {
    type: commandsEnum;
    payload: any;
};
const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: ''
};

enum commandsEnum {
    'set_filterIsLoaded' = 0,
    'setup_grid_columns' = 1,
    'reload_grid' = 2,
    'change_selectedFilter' = 3,
    'change_search_pattern' = 4
}
interface Model {
    callBackCommands: Function;
}

enum pageEnum {
    filter,
    List
}
enum pageContainerEnum {
    Calendar
}

const AdministrationUsersStation = forwardRef(
    ({ callBackCommands }: Model, ref) => {

        const [location, setLocation] = useState(new LocationNameTable);
        const [locationGlobal, setlocationGlobal] = useState(new LocationNameGlobal);
        const [listTempUserStation, setListTempUserStation] = useState(new Array<UserStationConcept>);

        const [query, setQuery] = useState("");
        const [nameDelete, setNameDelete] = useState("");
        const [idDelete, setIdDelete] = useState("");
        const [modalAlert, setModalAlert] = useState(false);

        const loadResourcesAndLoadInfo = async () => {
            let temp = new LocationLanguagesAdministrationUsersStation();
            await temp.handlelocationTable(resourceSet);
            setLocation(temp.getLocationNameTable);

            let tempGlobal = new LocationLanguagesAdministrationUsersStation();
            await tempGlobal.handlelocationGlobal(TPGlobal.globalResourceSet);
            setlocationGlobal(tempGlobal.getLocationNameGlobal);

            setLoading(false);
        }

        const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                handleGetTableData();
            }
        };

        const handleIconClick = (event: TPIconTypes) => {
            if (event === TPIconTypes.loop) handleGetTableData();
        };

        const handleGetTableData = async () => {
            setLoading(true);
            let tasktypeService = new AdministrationUsersStationService();
            let expectedCodes: Array<number> = [200, 404];

            try {
                //Load users by search
                let responseRequest =
                    await tasktypeService.getByIdOfCase(
                        query,
                        false,
                        true,
                        expectedCodes
                    );


                let data = { ...adminState };

                if (responseRequest != undefined && Array.isArray(responseRequest.data)) {
                    setListTempUserStation(responseRequest.data);
                    let list = responseRequest.data.map(({
                        userLogin,
                        ipAddress,
                        connectionId

                    }: UserStation) => {
                        return {
                            userLogin,
                            ipAddress,
                            connectionId
                        }
                    });

                   
                    let command1: commandType = {
                        type: commandsEnum.reload_grid,
                        payload: list
                    };
                    dispatchCommand(command1);
                }


                setLoading(false);

            }
            catch (error) {

                TPLog.Log(
                    `Error ${resourceSet} handleGetGroupList ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${resourceSet} handleGetGroupList ex`
                );
                setLoading(false);
                return [];
            }


        };      


        const handleData = (itemp: any) : string => {
            let temp = [...listTempUserStation];
   
            if (Array.isArray(temp)) {
                if (temp) {              
                    let data = temp.find(s => s.connectionId == itemp.connectionId);                   
                    if (data) return data.connectionId;
                }
            }
            return "";
        }


        const columnStyles: ColumnStyles<UserStation> = {
            userLogin: ({ value, item }) =>
            (
                <div style={{ display: "flex", alignItems: "center", gap:"10px" }}>
                    <TPIcon iconType={TPIconTypes.delete} style={{ color: "red" }} onClick={() => {
                        setModalAlert(true);
                        setNameDelete(item.userLogin);  
                        setIdDelete(item.connectionId);              
                    }} /> {value}
                </div>
            ),

        };


        const handleDelete = async (id: string) => {          
            setLoading(true);
            let tasktypeService = new AdministrationUsersStationService();
            let expectedCodes: Array<number> = [200, 404];
            setIdDelete(id);
            try {
                //Load users by search
                let responseRequest =
                    await tasktypeService.deleteById(
                        id,
                        true,
                        true,
                        expectedCodes
                    );

                if (responseRequest) {

                    handleGetTableData();
                }

                setNameDelete("");
                setIdDelete("");
                setLoading(false);

            }
            catch (error) {

                TPLog.Log(
                    `Error ${resourceSet} handleGetGroupList ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${resourceSet} handleGetGroupList ex`
                );
                setLoading(false);
                return [];
            }

        };


        const columnsNames: CustomColumnNames<UserStation> = {
            ipAddress: location.ColumnLogin,
            userLogin: location.ColumnIpAddress
        };


        const value = useStatusAdministrationUsersStation();
        const {
            adminState,
            resourceSet,
            setLoading,
            dispatchCommand,
            loading
        } = value;

        useEffect(() => {
            setLoading(true);
            loadResourcesAndLoadInfo();
        }, []);

        return (
            <TPLoadingOverlay active={loading}>
                <>                 
                    <TPModalQuestion
                        title={locationGlobal.confirmdeleteLabel}
                        yesLabel={locationGlobal.okAlertButton}
                        noLabel={locationGlobal.cancelAlertButton}
                        question={locationGlobal.questionConfirmDelete + " " + nameDelete}
                        callBackData={(e:any) => {
                           
                        }}
                        isShown={modalAlert}
                        callBackAnswer={(e: any) => {
                            if (e == false) {
                                setModalAlert(false);
                                setNameDelete("");
                                setIdDelete("");
                            }
                            else if (e) {
                                setModalAlert(false);
                                handleDelete(idDelete);
                            }
                        }}
                    ></TPModalQuestion>


                    <RightRowContainerStyle>
                        <TPPageTitle> {location.title}</TPPageTitle>
                    </RightRowContainerStyle>
                    <hr />
                    <div style={{ display: "flex", justifyContent: "start", width: "100%", height: "40px", gap: "5px", alignItems: "center", paddingLeft:"3px"}} >
                        <TPLabel labelText={location.stationIdAdmin} />

                        <div style={{ width: "250px" }} >
                            <TPTextBox
                                id="CaseCreationStatsTitleStationId"
                                type="number"
                                icon={TPIconTypes.search}
                                withIcon={true}
                                value={query}
                                placeholder={locationGlobal.search}
                                onChange={(e: any) => {
                                    setQuery(e.target.value)
                                }
                                }
                                onKeyPress={handleKeyPress}
                                iconClick={() => { handleGetTableData() }}
                                isHorizontal={true}
                               
                            />
                        </div>
                    </div>
                    <br></br>
                    <DynamicTable
                        hideAllTableOnNoData
                        id="station-id-admin"
                        data={adminState.gridData}
                        withPreferences={true}
                        columnStyles={columnStyles }
                        columnNames={columnsNames}
                        hiddenColumns={['connectionId']}
                        onIconClicked={(e: any) => { handleIconClick(e); }}
                    />
                </>
            </TPLoadingOverlay>

        );
    }
);

export default AdministrationUsersStation;
