import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue"
import TPModal from "@/layouts/TPModal/TPModal";
import { FC, useEffect, useState } from "react";
import TPTextBox from "../bootstrap/forms/textbox/TPTextBox";
import { LocalizedTextValue } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";

interface TPLocalizedNamesModalProperties {
  onAction: (data: LocalizedTextValue[] | null) => void;
  isOpen: boolean;
  title?: string;
  genericLabel?: string;
  initialValues?: LocalizedTextValue[];
}

export const TPLocalizedNamesModal: FC<TPLocalizedNamesModalProperties> = function ({
  onAction,
  isOpen,
  title,
  genericLabel,
  initialValues
}) {

  const [localizedValues, setLocalizedValues] = useState<LocalizedTextValue[]>(
    TPGlobal.TPClientAvailableLanguages
      .map((l, idx) => ({ 
        languageId: l.id, 
        localizedValue: initialValues?.find(i => i.languageId === l.id)?.localizedValue || "",
        order: 0
      }))
  );

  const [saveLabel, setSaveLabel] = useState("");
  const [cancelLabel, setCancelLabel] = useState("");
  const [localizedValuesLabel, setLocalizedValuesLabel] = useState("");

  const loadResources = async function() {
    setSaveLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"));
    setCancelLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"));
    setLocalizedValuesLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "LocalizedValues"));
  }

  const handleValueChange = function (languageId: string, newValue: string) {
    setLocalizedValues([
      ...localizedValues.map((l, idx) => {
        return l.languageId === languageId ?
          { languageId: l.languageId, localizedValue: newValue, order: 0 } as LocalizedTextValue
          :
          l
      })
    ])
  }

  useEffect(() => {
    loadResources();
  }, [])

  return (
    <>
      <TPModal
        modalState={{
          id: "tp-modal-localized-values",
          acceptLabel: saveLabel,
          callBackAnswer: (saved: boolean) => onAction(saved ? localizedValues : null),
          isShown: isOpen,
          titleModal: title || localizedValuesLabel,
          cancelLabel: cancelLabel
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {TPGlobal.TPClientAvailableLanguages.filter(l => l.id !== TPGlobal.language).map(l => (
            <div key={l.id}>
              <TPTextBox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleValueChange(l.id, e.target.value)}
                value={localizedValues.find(loc => loc.languageId === l.id)?.localizedValue || ""}
                labelText={String(genericLabel || "_value").concat(` (${l.name})`)}
              />
            </div>
          ))}
        </div>
      </TPModal>
    </>
  )
}