import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { taskStatusEnum, TPActiveOptions, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { FC, forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useState } from "react";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { RemindersService } from "@/services/MyRemindersService";
import { useDispatch } from "react-redux";
import { LocationLanguagesReminders, LocationNameReminders } from "./MyRemindersLocation";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { ReminderModel } from "@/models/MyReminders/MyReminders";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import { MyRemindersSlice, MyRemindersSliceModel } from "./MyRemindersSlice";
import { TaskReminderModal } from "@/modules/core/components/task-reminder/TaskReminderModal";
import { TPConfirmationModal } from "@/components/TPModalAlert/ConfirmationModal/TPConfirmationModal";
import { useTaskReminderLabels } from "@/modules/core/components/task-reminder/resources/TaskReminderLabels";
import { showToast, TPToastTypes } from "@/components/bootstrap/components/toasts/TPToast";

interface InputModel {
    callBackCommands: Function;
    active: boolean;
}

const resourceSet = "MyReminders";

type StatusColor = {
    [x in taskStatusEnum]: string;
}

type StatusIcon = {
    [x in taskStatusEnum]: string;
}

const statusColors: StatusColor = {
    "SO": "#B1F2D7",
    "PE": "#FFE4B3",
    "EX": "#FFB3B3",
    "LA": "#efffb3",
    "AL": "",
    "IH": "",
    "OH": "",
    "TS": ""
}

const statusIcons: StatusIcon = {
    "SO": "solved",
    "PE": "pending",
    "EX": "expired",
    "LA": "lag",
    "AL": "",
    "IH": "",
    "OH": "",
    "TS": ""
}

const getStatusIcon = function( status: taskStatusEnum ) {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                aspectRatio: 1,
                width: "22px",
                height: "22px",
                borderRadius: "50%",
                backgroundColor: statusColors[status],
            }}
        >
            <TPIcon 
                iconType={statusIcons[status] as TPIconTypes}
                style={{
                    display: "flex",
                    fontSize: "16px", 
                    alignItems: "center", 
                    justifyContent: "center"
                }}
            />
        </div>
    )
}

const MyRemindersList = forwardRef(({ callBackCommands, active }: InputModel, ref) => {

    const { reminders, remindersLoaded: contentLoaded } = useSelector((s: StoreModel) => s[MyRemindersSlice.name]) as MyRemindersSliceModel;
    const dispatch = useDispatch();
    const casecontext: any = useContext(TPCaseViewerContext);
    const [location, setLocation] = useState(new LocationNameReminders);
    const [chosenReminderId, setChosenReminderId] = useState<number | null>(null);
    const [chosenCaseId, setChosenCaseId] = useState<number | null>(null);
    const [chosenTaskId, setChosenTaskId] = useState<number | null>(null);
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");
    const { labels } = useTaskReminderLabels();

    const loadResources = async () => {
        let temp = new LocationLanguagesReminders();
        await temp.handleLocationNameReminders(resourceSet);
        setLocation(temp.getLocationNameReminders);
        let newFilterKeyValue: Array<TPKeyValue> = [];
        newFilterKeyValue.push({
            key: TPActiveOptions.ALL.toString(),
            value: await TPI18N.GetText(resourceSet, "All"),
        });
        newFilterKeyValue.push({
            key: TPActiveOptions.ACTIVE.toString(),
            value: await TPI18N.GetText(resourceSet, "Active"),
        });
        newFilterKeyValue.push({
            key: TPActiveOptions.INACTIVE.toString(),
            value: await TPI18N.GetText(resourceSet, "Inactive"),
        });
        setDeleteLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"));
        setUpdateLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"));
    }

    const deleteReminder = function (reminderId: string | number) {
        setChosenReminderId(null);
        RemindersService.deleteReminder(reminderId, true, true, [200])
            .catch(err => console.error(err))
            .finally(() => dispatch(MyRemindersSlice.actions.setContentLoaded(false)))
    }

    useEffect(() => {
        loadResources();
    }, [])

    return (
        <>
            <TPConfirmationModal
                message={labels.DeleteConfirm}
                onAccept={() => chosenReminderId && deleteReminder(chosenReminderId)}
                onCancel={() => setChosenReminderId(null)}
                hideTitle
                visible={chosenReminderId !== null}
            />
            {chosenCaseId && chosenTaskId && (
                <TaskReminderModal
                    caseId={chosenCaseId}
                    onAction={() => {
                        setChosenCaseId(null);
                        setChosenTaskId(null);
                    }}
                    taskId={chosenTaskId}
                />
            )}
            {active &&
                <div id="reminders-list-view">
                    <DynamicTable
                        id="my-reminders"
                        data={reminders.map(r => (
                            {
                                tareId: r.tareId,
                                taskId: r.taskId,
                                statusId: r.statusId,
                                customStateId: r.customStateId,
                                caseId: r.caseId,
                                customerTypeLocalizedDescription: r.customerTypeLocalizedDescription,
                                clientName: r.clientName,
                                comments: r.comments,
                                localDate: r.localDate,
                                description: r.description
                            } as ReminderModel
                        ))}
                        onIconClicked={icon => {
                            if (icon == TPIconTypes.refresh || icon == TPIconTypes.loop)
                                dispatch(MyRemindersSlice.actions.setContentLoaded(false));
                        }}
                        hiddenColumns={["taskId", "tareId"]}
                        withPreferences
                        minorOptions={[
                            {
                                key: deleteLabel,
                                onOptionChange: (reminder) => setChosenReminderId(reminder.tareId),
                                type: "delete",
                                icon: TPIconTypes.delete
                            },
                            {
                                key: updateLabel,
                                onOptionChange: (reminder) => {
                                    setChosenCaseId(reminder.caseId);
                                    setChosenTaskId(reminder.taskId);
                                },
                                type: "update",
                                icon: TPIconTypes.edit
                            }
                        ]}
                        columnStyles={{
                            statusId: (row) => {
                                if (!row.item.statusId) return <></>; 
                                return getStatusIcon(row.item.statusId as taskStatusEnum);
                            },
                            caseId: (row) => (
                                <>
                                    <button
                                        type="button"
                                        style={{
                                            color: "purple",
                                            border: "none",
                                            background: "none"
                                        }}
                                        onClick={() => casecontext.handleAddNewCaseViewerCallBack(row.item.caseId)}
                                    >
                                        <b><u>{row.item.caseId}</u></b>
                                    </button>
                                </>
                            )
                        }}
                        columnNames={{
                            statusId: location.stateColumn,
                            customStateId: location.customerStateColumn,
                            caseId: location.caseNumberColumn,
                            customerTypeLocalizedDescription: location.customerTypeColumn,
                            clientName: location.customerColumn,
                            comments: location.commentsColumn,
                            localDate: location.reminderDateColumn,
                            description: location.reminderColumn
                        }}
                    />
                </div>}
        </>
    );
}
);

export default MyRemindersList;
