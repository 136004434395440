import { TPKeyValue } from "@/helpers/TPKeyValue";
import { LocalizedValue } from "../ListAdministration/ListAdministrationModels";
import { LocalizedTextValue } from "../Global/TPGlobalEnums";

export interface MenuItemViewModelForRender {
  menuItemId: string;
  menuItemName: string;
  menuItemParent: string;
  menuItemType: string;
  menuItemLink: string;
  menuItemLinkParameters: string;
  menuItemImage: string;
  menuItemWidth: string;
  menuItemHeight: string;
  menuItemExternalPublicKey: string;
  isAction: boolean;
  isSection: boolean;
  menuItemOrder: number;
  subMenus: MenuItemViewModelForRender[];
  //callBackMethod: Action<MenuItemViewModel>;
  isExpanded: boolean;
  localizedDescription: string;
  order: number;
}

export interface MenuItemViewModel {
  id: string;
  description: string;
  parent: string;
  type: string;
  urlLink: string;
  urlLinkParameters: string;
  image: string;
  width: string;
  height: string;
  externalPublicKey: string;
  isActive: boolean;
  isSection: boolean | null;
  localizedDescription: string;
}

export interface ProfileMenuItemViewModel {
  profileMenuItemDetail: any;
}

// New models

export enum MenuTypes {
  item = "ITEM",
  group = "GROUP",
  section = "SECTION",
}

export enum MenuLevels {
  ITEM = "ITEM",
  GROUP = "GROUP",
  SECTION = "SECTION",
}

export interface MenuDefinitionModel {
  id: string;
  name: string;
  localizedNames: LocalizedTextValue[];
  level: MenuLevels;
  parentId: string;
  itemType: string;
  url: string;
  publicKeyExternalApp: string;
  parameters: string;
  isActive: boolean;
  icon: string;
  profiles: Array<string>;
  order?: string;
}

export interface MenuDefinitionCreationModel {
  id: string;
  description: string;
  descriptionLocalizedValues: LocalizedTextValue[];
  level: MenuLevels;
  order: string;
  parent: string | null;
  type: string;
  urlLink: string;
  externalPublicKey: string;
  urlLinkParameters: string;
  isActive: boolean;
  image: string;
  profiles: Array<{ Id_PROF: string; Id_MEIT: string; IsActive_PRMI: boolean }>;
}

export interface MenuDefinitionMenuResponse {
  id: string
  description: string
  parent: string
  type: string
  urlLink: string
  urlLinkParameters: string
  image: any
  width: any
  height: any
  externalPublicKey: any
  isActive: boolean
  localizedDescription: LocalizedTextValue[]
  level: string
  order: number
  profiles: Array<{ id_PROF: string; name: string; isActive_PRMI: boolean }>;
}

export interface MenuDefinitionListResponseModel {
  id: string;
  isActive: boolean;
  order: number;
  section: string;
  group: string;
  item: string;
  urlLink: string;
  image: string;
  profiles: MenuProfile[];
  parentId: string;
}

export interface MenuProfile {
  id_PROF: string;
  name: string;
  isActive_PRMI: boolean;
}

export interface MenuOnLevel {
  id_MEIT: string;
  localizedDescription_MEIT: string;
}

export enum MenuItemType {
  Iframe = "IFRAME",
  JSFunction = "JSFUNC",
  ModalPage = "MODAL",
  Navigate = "NAVIG",
}

export interface ComponentResponse {
  id: string;
  description: string;
  component: string;
  componentTypeId: any;
  isActive: boolean;
  localizedDescription: string;
}

export interface MenuHierarchy {
  id: string;
  name: string;
  order: number;
  displayOrder: number;
  children: MenuHierarchy[];
  icon?: string;
}

export interface MenuReorderModel {
  id: string;
  parent: string;
  order: number;
}