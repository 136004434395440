import { FC, ReactElement } from "react";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import deleteIcon from "@/assets/images/error.svg";

type TPModalDeleteProps = {
  title?: string; // Título ahora es opcional
  bodyContent: string; // Ahora aceptará un string HTML
  yesLabel: string;
  noLabel: string;
  isShown: boolean;
  callBackData: any;
  callBackAnswer: Function;
};

const TPModalDelete: FC<TPModalDeleteProps> = ({
  title,
  bodyContent,
  yesLabel,
  noLabel,
  isShown,
  callBackData,
  callBackAnswer,
}): ReactElement => {
  const classModal = isShown ? "modal show" : "modal";
  const styleModal = isShown ? { display: "block" } : { display: "none" };
  const backdropClass = isShown ? "modal-backdrop show" : "";

  return (
    <>
      <div
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <img src={deleteIcon} alt="Delete Icon" width={50} height={50} />
            </div>
            <div className="modal-body text-center">
              {title && <h5 className="modal-title tpbold mb-3">{title}</h5>}
              <div
                className="tpbody"
                dangerouslySetInnerHTML={{ __html: bodyContent }} // Renderiza el HTML pasado en bodyContent
              />
            </div>
            <div className="modal-footer justify-content-center">
              <TPButton
                id="cancel-action"
                type={TPButtonTypes.link}
                onClick={() => callBackAnswer(false, callBackData)}
                isDesignSystem
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  backgroundColor: "white",
                  color: "purple",
                  border: "1px solid purple",
                }}
              >
                {noLabel}
              </TPButton>
              <TPButton
                id="confirm-action"
                type={TPButtonTypes.primary}
                onClick={() => callBackAnswer(true, callBackData)}
                isDesignSystem
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {yesLabel}
              </TPButton>
            </div>
          </div>
        </div>
      </div>
      <div className={backdropClass}></div>
    </>
  );
};

export default TPModalDelete;
