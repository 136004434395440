import TPGlobal from '@/helpers/TPGlobal';
import { TPKeyValue } from '@/helpers/TPKeyValue';
import {FC, ReactElement, useEffect, useReducer, useRef, useState} from 'react';
import TPButton from '../../components/bootstrap/components/buttons/TPButton';
import TPLoadingOverlay from '../../components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay';
import TPCheckBox from '../../components/bootstrap/forms/checkbox/TPCheckBox';
import TPTextBox from '../../components/bootstrap/forms/textbox/TPTextBox';
import { TPPageAcceptCancelButtonsContainer, TPPageSection, TPPageSectionTitle, TPPageTitle } from '../../components/TPPage/tpPageStyles';
import { TPLog, TPLogType } from '../../helpers/TPLog';
import TPModalLanguageList from '../../layouts/TPModalLanguageList/TPModalLanguageList';
import { useModal } from '../../layouts/TPModalLanguageList/useModal';
import { ContentVerticalTabInsertUpdateStyled } from '../../layouts/VerticalTabs/menuVerticalTabStyled';
import { SequenceGeneratorSequencesNameEnum, TPButtonTypes, TPIconTypes } from '../../models/Global/TPGlobalEnums';
import { LanguagesViewModel } from '../../models/Languages/LanguagesViewModel';
import { MultilanguageFieldValueViewModel } from '../../models/multilanguage/MultilanguageFieldValueModel';
import { QuickClassifierCloneModel } from '../../models/QuickClassifier/QuickClassifierViewModel';
import { WorkflowTypeInputDTO, WorkflowTypeInputDTOValidator } from '../../models/Workflow/WorkflowTypeInputDTO';
import { TPI18N } from '../../services/I18nService';
import { QuickClassifierService } from '../../services/QuickClassifierService';
import { SequenceService } from '../../services/SequenceService';
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";
import {MultilingualTextBoxEvents} from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";

type quickClassifiersInsertUpdateProps = {
    mode: string;
    recordId: string;
    tabId: string;
    callBackResult: Function;
};



enum asideMenuItemTypes {
    "general" = 0,
    "details" = 1,
}
interface asideMenuItemsModel {
    itemLabel: string;
    itemType: asideMenuItemTypes;
    isActive: boolean;
    isEnabled: boolean;
    isVisible: boolean;
    onClickHandler: Function;
}


type commandType = {
    type: commandsEnum;
    payload: any;
};

enum commandsEnum {
    "create_general" = 0,
    "create_details_and_change_mode" = 1,
    "set_active_general" = 2,
    "set_active_details" = 3,
    "clone" = 4
}

type InsertUpdateStateType = {
    id: string;
    recordLanguageList: Array<TPKeyValue>;
    promiseLimit: string;
    isDirectSolution: boolean;
    isActive: boolean;
    //validator
    idErrorMessage: string;
    promiseLimitErrorMessage: string;
    promiseLimitUnitErrorMessage: string;
    descriptionErrorMessages: Array<string>;
};






const QuickClassifiersClone: FC<quickClassifiersInsertUpdateProps> = ({
    mode,
    recordId,
    tabId,
    callBackResult
}): ReactElement => {
    //#region  Init
    const resourceSet: string = 'QuickClassifiersInsertCloneComponent';
    const resourceSetLabel: string = 'WorkflowTypeCloneComponent';


    const insertUpdateInitialState: InsertUpdateStateType = {
        id: recordId,
        promiseLimit: "",
        isDirectSolution: false,
        isActive: true,
        recordLanguageList: [],
        idErrorMessage: "",
        descriptionErrorMessages: [],
        promiseLimitUnitErrorMessage: "",
        promiseLimitErrorMessage: "",
    };

    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [realMode, setRealMode] = useState(mode);
    const [realRecordId, setRealRecordId] = useState(recordId);
    const [cloneState, setCloneState] = useState<InsertUpdateStateType>(insertUpdateInitialState);

    const [titleLabel, setTitleLabel] = useState("");
    const [idLabel, setIdLabel] = useState("");
    const [newIdLabel, setnewIdLabel] = useState("");
    const [descriptionLabel, setDescriptionLabel] = useState("");
    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [isActiveLabel, setIsActiveLabel] = useState("");


    const [selectedPromiseLimitUnit, setSelectedPromiseLimitUnit] = useState("");
    const [tempIdValue, setTempIdValue] = useState("");
    const [tempDescription, setTempDescription] = useState("");
    const [referenceId, setReferenceId] = useState("");
    const [languageListLabel, setLanguageListLabel] = useState("");
    const [parametersSectionLabel, setParametersSectionLabel] = useState("");
    const [descriptionErrorMessages, setDescriptionErrorMessages] = useState("");
    const [requiereInput, setRequiereInput] = useState("");
    const nameInputRef = useRef<MultilingualTextBoxEvents>();



    const loadResourcesAndLoadInfo = async () => {
        let i: number;
        //resources state
        setTitleLabel(await TPI18N.GetText(resourceSet, "TitleCloneLabel"));
        setIdLabel(await TPI18N.GetText(resourceSetLabel, "IdCloneLabel"));
        setnewIdLabel(await TPI18N.GetText(resourceSetLabel, "NewIdCloneLabel"));
        setDescriptionLabel(await TPI18N.GetText(resourceSetLabel, "DescriptionCloneLabel"));
        setIsActiveLabel(await TPI18N.GetText(resourceSetLabel, "IsActiveCloneLabel"));
        setSaveButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton")
        );
        setCancelButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
        );
        setLanguageListLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList")
        );
        setParametersSectionLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "ParametersSectionLabel")
        );
        setRequiereInput(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired")
        );
        if (realMode == "Clone") {
            await getById(realRecordId);
        }

    }


    let initialState: Array<asideMenuItemsModel> = [];
    const [asideMenuState, dispatchCommand] = useReducer(
        doCommand,
        initialState
    );

    function doCommand(
        prevState: Array<asideMenuItemsModel>,
        command: commandType
    ) {
        switch (command.type) {
            case commandsEnum.create_general:
                return prevState


        }
        return prevState;
    }

    const handleOnIdChange = (newId: string) => {
        let newInsertUpdateState = { ...cloneState };
        newInsertUpdateState.id = newId;
        newInsertUpdateState.idErrorMessage = "";
        setCloneState(newInsertUpdateState);
    };

    const handleLanguageChange = (index: number, newName: string) => {
        let newInsertUpdateState = { ...cloneState };
        newInsertUpdateState.recordLanguageList[index].value = newName;
        newInsertUpdateState.descriptionErrorMessages[index] = "";
        setCloneState(newInsertUpdateState);
    };

    const {
        isOpen: isOpenModalLanguageList,
        openModal: handleOpenModalLanguageList,
        closeModal: handleCloseModalLanguageList,
        saveChanges: handleSaveChangesModalLanguageList,
    } = useModal(false);


    const getById = async (pRecordId: string) => {
        let serviceClient = new QuickClassifierService();
        let expectedCodes: Array<number> = [200];
        type NewType = MultilanguageFieldValueViewModel;

        let recordLanguagesList: Array<NewType> = [];
        let i: number;
        let j: number;
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.getQuickClassifierById(
                pRecordId,
                false,
                true,
                expectedCodes
            );

            let recordInfo: any;
            recordInfo = { ...responseRequest };

            setTempIdValue(pRecordId);
            setTempDescription(recordInfo.description);

            let newInsertUpdateState = { ...cloneState };

            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }

            newInsertUpdateState.id = "";
            setCloneState(newInsertUpdateState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${resourceSet} getFunctionById ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${resourceSet} getFunctionById ex`);
            setIsLoadingScreen(false);
        }
    };

    const handleOkButtonClick = async () => {
        if (nameInputRef.current?.isInvalid()) {
            nameInputRef.current?.markAsTouched();
            return;
        }

        const languages = nameInputRef.current?.getValue();
        const mainLanguage = languages?.find(({id}) => id === TPGlobal.TPClientDefaultLanguage);
        let isInValid: boolean = false;
        let i: number;
        let n: number;

        let newInsertUpdateState = { ...cloneState };
        let tempCount = newInsertUpdateState.recordLanguageList[0];
        if (!tempCount || !tempCount.value || tempCount.value.trim() == "") {
            setDescriptionErrorMessages(requiereInput);
            return "";
        }
        else setDescriptionErrorMessages("");


        let recordInputDTO: WorkflowTypeInputDTO = {
            // id: cloneState.id,
            id: referenceId,
            promiseLimit: +cloneState.promiseLimit ?? null,
            promiseLimitUnit: selectedPromiseLimitUnit
                ? selectedPromiseLimitUnit
                : null,
            isDirectSolution: cloneState.isDirectSolution,
            isActive: cloneState.isActive,
            otherLocalizedValues: [
                {
                    languageId: mainLanguage?.id!,
                    localizedValue: mainLanguage?.value ?? "",
                    order: 1
                },
                ...languages!
                    .filter(({id}) => id !== TPGlobal.TPClientDefaultLanguage)
                    .map(({id, value}, order) => ({languageId: id!, localizedValue: value ?? "", order: order + 2}))
            ],
            description: mainLanguage?.value!,
        };

        let inputDTOValidator = new WorkflowTypeInputDTOValidator();
        let resultValidator = inputDTOValidator.validate(recordInputDTO);

        if (!TPGlobal.TPIsEmpty(resultValidator)) {
            let newInsertUpdateState = { ...cloneState };

            if (resultValidator.id) {
                newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
                    resultValidator.id
                );
            } else {
                newInsertUpdateState.idErrorMessage = "";
            }

            setCloneState(newInsertUpdateState);
            isInValid = true;
        }

        if (!recordInputDTO.promiseLimitUnit) {
            recordInputDTO.promiseLimit = null;
        }

        if (!isInValid) {
            if (realMode == "Clone") {
                await clone(recordInputDTO);
            }
        }
    };

    const clone = async (inputDTO: WorkflowTypeInputDTO) => {
        let serviceClient = new QuickClassifierService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let cloneData = new QuickClassifierCloneModel();
            cloneData.baseId = recordId;
            cloneData.newId = referenceId;
            cloneData.isActive = inputDTO.isActive;
            cloneData.description = inputDTO.description;
            cloneData.otherLocalizedValues = inputDTO.otherLocalizedValues.map((e: any) => {
                return {
                    languageId: e.languageId,
                    localizedValue: e.localizedValue
                }
            });

            let language = TPGlobal.language;

            let responseRequest = await serviceClient.cloneQuickClassifier(
                cloneData,
                true,
                true,
                expectedCodes
            );          

            if (responseRequest.responseResult) {    
                callBackResult({
                    result: "ChangeCloneToUpdateMode",
                    recordId: referenceId,
                    tabId: tabId,
                    language: language
                });
            }

        } catch (error) {
            TPLog.Log(`Error ${resourceSet} insert ex`, TPLogType.ERROR, error);
            console.error(`Error ${resourceSet} insert ex`);
        }
        setIsLoadingScreen(false);
    };

    const handleCancelButtonClick = () => {
        callBackResult({ result: "cancel_general", recordId: recordId });
    };

    const handleIsActiveChange = () => {
        let newInsertUpdateState = { ...cloneState };
        newInsertUpdateState.isActive = !cloneState.isActive;
        setCloneState(newInsertUpdateState);
    };


    const generalAutomaticId = async () => {
        let serviceClient = new SequenceService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.generalAutomaticId(
                false,
                true,
                expectedCodes,
                SequenceGeneratorSequencesNameEnum.SQWSCL
            );

            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                // callBackResult({ result: "OK", recordId: recordId });
                let result = responseRequest?.responseData?.data[0]?.sequenceCode;
                setReferenceId(result);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${resourceSet} updatetFunction ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${resourceSet} updatetFunction ex`);
            setIsLoadingScreen(false);
        }
    };

    const handleCallBackCommands = (theCommand: any) => {
        if (theCommand.result === "clone_general_insert_and_change_mode") {

            let language = TPGlobal.language;

            callBackResult({
                result: "ChangeCloneToUpdateMode",
                recordId: theCommand.payload,
                tabId: tabId,
                language: language
            });

            //onDetailsAsideItemClick();
            return;
        }

        if (theCommand.result === "cancel_general") {
            callBackResult({ result: "CANCEL", recordId: theCommand.recordId });
            return;
        }
    };

    const handleOnExitClick = () => {
        callBackResult({ result: "CANCEL", recordId: "" });
    };

    useEffect(() => {
        loadResourcesAndLoadInfo();
        generalAutomaticId();
    }, []);


    //#region  Render
    return (
        <>
            <ContentVerticalTabInsertUpdateStyled>
                <TPLoadingOverlay active={isLoadingScreen}>
                    <div className="row">
                        <div className="col-10">
                            <TPPageTitle>{titleLabel}</TPPageTitle>
                            <hr />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-10">
                            <TPPageSection>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <TPTextBox
                                                id="IdOldTextBox"
                                                labelText={idLabel}
                                                isMandatory={false}
                                                value={tempDescription + " (" + tempIdValue + ")"}
                                                onChange={() => { }}
                                                maxLength={20}
                                                disabled={true}
                                                errorMessage={""}
                                            />

                                            <TPTextBox
                                                id="IdTextBox"
                                                labelText={newIdLabel}
                                                isMandatory={true}
                                                // value={cloneState.id}
                                                value={referenceId}
                                                onChange={(e: any) => handleOnIdChange(e.target.value)}
                                                maxLength={20}
                                                disabled={true}
                                                errorMessage={cloneState.idErrorMessage}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <MultilingualTextBox
                                    ref={nameInputRef}
                                    style={{ width: "calc(50% + 35px)" }}
                                    value={cloneState.recordLanguageList?.map(({key, value}) => ({id: key, value}))}
                                />
                            </TPPageSection>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <TPPageSection>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <TPCheckBox
                                                id="IdCheckBox"
                                                labelText={isActiveLabel}
                                                checked={cloneState.isActive}
                                                onChange={(e: any) => handleIsActiveChange()}
                                            ></TPCheckBox>
                                        </div>
                                    </div>
                                </div>
                            </TPPageSection>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-6">
                            <div style={{display:'flex', justifyContent:'flex-end', gap:'8px', alignItems:'center'}}>
                                <TPButton
                                    id="IdButton"
                                    type={TPButtonTypes.link}
                                    onClick={handleCancelButtonClick}
                                    className={"ms-2"}
                                    isDesignSystem
                                    style={{
                                        backgroundColor:'white',
                                        color:'purple',
                                        paddingLeft: '16px',
                                        paddingRight: '16px'
                                    }}
                                >
                                    {cancelButtonLabel}
                                </TPButton>
                                <TPButton
                                    id="IdButton"
                                    type={TPButtonTypes.primary}
                                    onClick={handleOkButtonClick}
                                    isDesignSystem
                                    style={{
                                        paddingLeft: '16px',
                                        paddingRight: '16px'
                                    }}
                                >
                                    {saveButtonLabel}
                                </TPButton>
                            </div>
                        </div>
                    </div>
                </TPLoadingOverlay>
            </ContentVerticalTabInsertUpdateStyled>
        </>
    );


    //#endregion
};

export default QuickClassifiersClone;
