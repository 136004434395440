import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
    CIMTitleSection,
    TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import {
    TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import React, {
    useEffect,
    useState
} from "react";
import DataTable from "react-data-table-component";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import ContainerFolder from "./ImagesAdminContainerFolder";
import ContainerFiles from "./ImagesAdminContainerFiles";
import {
    AppProviderImagesAdmin,
    useAppConctextImageAdmin,
} from "./ImagesAdminContextImagesAdmin";
import ImagesAdminContainerFiles from "./ImagesAdminContainerFiles";
import {
    ContainerImageAdminDivStyle,
    ContainerVerticalDivStyle,
} from "./StyleImageAdmin";
import UploadImage from "./imageAdminUploadImage";
import { OperationMode } from "./useStatusImagesAdmin";

type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<TreeViewModel>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};

type ContainerFolderArgs = {
    mode?: string;
    startFolder?: string;
    callBackCommands?: Function;
};
interface ImagesAdminInterface {
    modeSelectFolder: boolean;
    folderIdActive: number;
    ChangeImageSelection?: Function;
    mode?: string;
    startFolder?: string;
    callBackCommands?: Function;
}

enum commandsEnum {
    "set_filterIsLoaded" = 0,
    "setup_grid_columns" = 1,
    "reload_grid" = 2,
    "change_selectedFilter" = 3,
    "change_search_pattern" = 4,
}
type commandType = {
    type: commandsEnum;
    payload: any;
};

const ImagesAdmin = ({ modeSelectFolder, folderIdActive, ChangeImageSelection, mode, startFolder, callBackCommands }: ImagesAdminInterface) => {
    const componentFileName: string = "ImagesAdmin.tsx";
    const resourceSet: string = "ImagesAdmin";
    const [titleLabel, setTitleLabel] = useState("");
    const [uploadLabel, setUploadLabel] = useState("");
    const [btnSelectLabel, setBtnSelectLabell] = useState("");



    const [activeUpload, setActiveUpload] = useState(false);
    const [statusFolderActive, setStatusFolderActive] = useState<boolean>(false);
    const value = useAppConctextImageAdmin();
    const {
        modeSelect,
        listFolderModel,
        setModeSelect,
        handleSelected,
        handleInitializeFilesModeSelect,
        selectedFolderParent,
        listTempFolder,
        handleInitializeFolders
    } = value;

    const loadResources = async () => {
        setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
        setUploadLabel(await TPI18N.GetText(resourceSet, "UploadImgLabel"));
        setBtnSelectLabell(await TPI18N.GetText(resourceSet, "SelectedImageLabel"));

    }

    // You can call callBackCommands here if needed to execute specific actions
    const executeCommand = (command: commandType) => {
        if (callBackCommands) {
            callBackCommands(command);
        }
    };

    const handleNewClick = () => {
        if (selectedFolderParent && selectedFolderParent.id > 0)
            setActiveUpload(true);
    };

    useEffect(() => {
        setModeSelect(modeSelectFolder);
        if (modeSelectFolder && !statusFolderActive && listTempFolder.length > 0) {
            handleInitializeFilesModeSelect(folderIdActive);
            let temp = listFolderModel.find(s => s.id == folderIdActive);
            if (temp)
                handleSelected(temp, OperationMode.SelectFolder);
            setStatusFolderActive(true);
        }

    }, [listTempFolder]);


    useEffect(() => {
        loadResources();
        handleInitializeFolders(folderIdActive, modeSelectFolder);
    }, []);

    return (
        <div style={{ position: "relative", height: "100%", padding: "14px" }} >
            <CIMTitleSection style={{ maxWidth:"100%" }} >
                {mode == "edit" &&
                    <>
                        <TPPageTitle style={{ margin: "0", marginLeft:"5px" }}>{titleLabel}</TPPageTitle>
                        <TPButton
                            id="new-upload"
                            isDesignSystem={true}
                            onClick={handleNewClick}
                            withIcon={TPIconTypes.add}
                            orientationIcon="left"
                            style={{ padding: "1px 18px",marginRight:"5px" }}
                            disabled={(!selectedFolderParent || selectedFolderParent.id <= 0) ? true : false}
                        >
                            {uploadLabel}
                        </TPButton>
                    </>
                }
            </CIMTitleSection>
            <ContainerImageAdminDivStyle>
                <div style={{ height: "610px", backgroundColor: " rgb(245, 245, 245)", borderRadius: "10px", marginLeft: "2px", padding:"16px" }} >
                    <ContainerFolder
                        mode={mode} startFolder={startFolder}></ContainerFolder>
                </div>
                <ContainerVerticalDivStyle>                  
                    <UploadImage activeUpload={activeUpload} handleActive={() => { setActiveUpload(false) }}  ></UploadImage>
                    <ImagesAdminContainerFiles ChangeImageSelection={ChangeImageSelection}
                        mode={mode}
                        startFolder={startFolder}
                        btnSelectLabel={btnSelectLabel}                        
                    ></ImagesAdminContainerFiles>
                </ContainerVerticalDivStyle>
            </ContainerImageAdminDivStyle>
        </div >
    );
};

export default ImagesAdmin;
