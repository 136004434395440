import { ReactElement, useRef, useEffect, useId, useState } from "react";
import { TPTextAreaStyled } from "./tpTextAreaStyles";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPFormControlContainerStyled } from "@/helpers/generalStyles";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPI18N } from "@/services/I18nService";
import TPGlobal from "@/helpers/TPGlobal";
import { handleInvalidCharacters } from "@/helpers/TPFunctions";

interface TPTextAreaInterface {
  onChange: Function;
  className?: string;
  value: string;
  disabled?: boolean;
  maxLength?: number;
  hasFocus?: boolean;
  labelText?: string | JSX.Element;
  isMandatory?: boolean;
  errorMessage?: string;
  isHorizontal?: boolean;
  placeholder?: string;
  withIcon?: boolean;
  icon?: TPIconTypes;
  rows: number;
  columns?: number;
  containerStyle?: any;
  labelStyle?: any;
  textAreaStyle?: any;
  id?: string;
  isInvalidChars?: boolean;
}

const TPTextArea = ({
  onChange,
  className = "",
  value,
  disabled,
  maxLength,
  hasFocus,
  labelText,
  isMandatory = false,
  errorMessage = "",
  isHorizontal = false,
  placeholder = "",
  withIcon = false,
  icon = TPIconTypes.default,
  rows,
  columns = 40,
  containerStyle,
  labelStyle,
  textAreaStyle,
  id,
  isInvalidChars = true,
}: TPTextAreaInterface): ReactElement => {
  const inputRef = useRef(null);
  const theId = `textarea-${useId()}`;
  const [localErrorMessage, setLocalErrorMessage] = useState("");
  const [inputValue, setInputValue] = useState(value || "");
  const [invalidCharacterLabel, setInvalidCharacterLabel] = useState('');

  useEffect(() => {
    const fetchText = async () => {
      const invalidCharacter = await TPI18N.GetText(TPGlobal.globalResourceSet, 'InvalidCharacter');
      setInvalidCharacterLabel(invalidCharacter);
    };
    fetchText();
  }, []);

  useEffect(() => {
    setInputValue(value || "");
  }, [value])

  const handleOnChange = (e: any) => {
    const newValue = e.target.value;
  
    // Define a regex for the specified invalid characters
    const invalidCharRegex = new RegExp(`[${TPGlobal.TPClientInvalidCharRegex}]`, 'g');
  
    // Check for invalid characters if validation is enabled
    if (isInvalidChars) {
      handleInvalidCharacters(
        newValue,
        invalidCharRegex,
        invalidCharacterLabel,
        setLocalErrorMessage,
        setInputValue,
        onChange,
        e
      );
    } else {
      // If no validation is required, update the input normally
      setInputValue(newValue);
      onChange && onChange({ ...e, target: { ...e.target, value: newValue } });
    }
  };

  const theInput = (
    <TPTextAreaStyled
      placeholder={placeholder}
      id={id ? id : theId}
      ref={inputRef}
      value={inputValue}
      className={`form-control ${className} 
        ${(localErrorMessage || errorMessage) !== "" ? "is-invalid" : ""} 
        ${withIcon ? "border-end-0" : ""}`}
      onChange={handleOnChange}
      disabled={disabled}
      maxLength={maxLength}
      autoCorrect={"off"}
      autoComplete={"off"}
      rows={rows}
      cols={columns}
      style={textAreaStyle}
    />
  );

  useEffect(() => {
    let inputElement: any;
    if (hasFocus) {
      if (inputRef.current) {
        inputElement = inputRef.current;
        inputElement.focus();
      }
    }
  }, [hasFocus]);

  return (
    <TPFormControlContainerStyled
      isHorizontal={isHorizontal}
      className={`${withIcon ? "input-group" : ""}`}
      style={containerStyle}
    >
      {labelText &&
      <TPLabel
        htmlFor={id ? id : theId}
        isMandatory={isMandatory}
        labelText={labelText}
        style={labelStyle}
        />}
      {theInput}
      {withIcon && (
        <span className="input-group-append">
          <TPIcon iconType={icon} />
        </span>
      )}

      {/* Display the localErrorMessage or errorMessage */}
      {(localErrorMessage || errorMessage) !== "" && (
        <div className="invalid-feedback">
          {localErrorMessage || errorMessage}
        </div>
      )}
    </TPFormControlContainerStyled>
  );
};

export default TPTextArea;
