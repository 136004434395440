import { useDispatch } from "react-redux";
import TPLoadingOverlay from "../../components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPKeyValue } from "../../helpers/TPKeyValue";
import TPModalQuestion, { TPModalQuestionState } from "../../layouts/ModalQuestion/TPModalQuestion";
import { TPActiveOptions, TPIconTypes } from "../../models/Global/TPGlobalEnums";
import { TPI18N } from "../../services/I18nService";
import TPGlobal from "../../helpers/TPGlobal";
import DynamicTable, { ColumnStyles, CustomColumnNames } from "../../modules/core/components/dynamic-table/DynamicTable";
import { Data } from "../../models/ImagesAdmin/ImagesAdminModel";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import { TPLog, TPLogType } from "../../helpers/TPLog";
import TableChip from "../../modules/core/utils/table-micro-components/TableChip";
import TablePrimaryItem from "../../modules/core/utils/table-micro-components/TablePrimaryItem";
import { CIMTitleSection, TPPageTitle } from "../../components/TPPage/tpPageStyles";
import TPButton from "../../components/bootstrap/components/buttons/TPButton";

interface InputModel {
    callBackCommands: Function;
}

type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<any>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};

//State grid and current filter
const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: "",
};

enum commandsEnum {
    "set_filterIsLoaded" = 0,
    "setup_grid_columns" = 1,
    "reload_grid" = 2,
    "change_selectedFilter" = 3,
    "change_search_pattern" = 4,
}

type commandType = {
    type: commandsEnum;
    payload: any;
};

//State modal
let modalQuestionInitialState: TPModalQuestionState = {
    isShown: false,
    callBackData: {},
};

//State filter dropdown
let initialStateFilter: Array<TPKeyValue> = [];

const ImagesAdminTable = forwardRef(
    ({ callBackCommands }: InputModel, ref) => {
        const dispatch = useDispatch();

        const resourceSet: string = "ImagesAdmin";
     
        const [modalQuestionState, setModalQuestionState] = useState(
            modalQuestionInitialState
        );

        //Screen resources
        const [titleModalDeleteLabel, setTitleModalDeleteLabel] = useState("");
        const [nameLabel, setNameLabel] = useState("");
        const [idLabel, setIdLabel] = useState("");
        const [okAlertButtonLabel, setOkAlertButtonLabel] = useState("");
        const [cancelAlertButtonLabel, setCancelAlertButtonLabel] = useState("");
        const [titleLabel, setTitleLabel] = useState("");
        const [uploadImg, setUploadImg] = useState("");
        const [thereAreNoRecordsToShowLabel, setThereAreNoRecordsToShowLabel] = useState("");

        const [yesLabel, setYesLabel] = useState("");
        const [noLabel, setNoLabel] = useState("");
        const [deleteLabelQuestion, setDeleteLabelQuestion] = useState("");
        const [isLoadingScreen, setIsLoadingScreen] = useState(true);
     
        const loadResources = async () => {

            setYesLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
            );
            setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));
            setNameLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Name"));
            setIdLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Id"));
            setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
            setUploadImg(await TPI18N.GetText(resourceSet, "UploadImgLabel"));
            setDeleteLabelQuestion(await TPI18N.GetText("AdditionalDataRelateComponent", "RecordDeleteConfirm"));
            setTitleModalDeleteLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"));
            setOkAlertButtonLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"));
            setCancelAlertButtonLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"));
            setThereAreNoRecordsToShowLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "thereAreNoRecordsToShowLabel"));


            //Filter
            let newFilterKeyValue: Array<TPKeyValue> = [];
            newFilterKeyValue.push({
                key: TPActiveOptions.ALL.toString(),
                value: await TPI18N.GetText(resourceSet, "All"),
            });
            newFilterKeyValue.push({
                key: TPActiveOptions.ACTIVE.toString(),
                value: await TPI18N.GetText(resourceSet, "Active"),
            });
            newFilterKeyValue.push({
                key: TPActiveOptions.INACTIVE.toString(),
                value: await TPI18N.GetText(resourceSet, "Inactive"),
            });
           
        }

        const setupGridColumns = (prevState: AdminStateType) => {
            let newState: AdminStateType = { ...prevState };
            newState.gridColumns = [prevState.gridData];
            return newState;
        };
        function doCommand(prevState: AdminStateType, command: commandType) {
            switch (command.type) {
                case commandsEnum.set_filterIsLoaded:
                    let newStateFilter: AdminStateType;
                    newStateFilter = { ...prevState };
                    newStateFilter.filterIsLoaded = true;
                    return newStateFilter;
                case commandsEnum.setup_grid_columns:
                    let newStateColumns: AdminStateType = setupGridColumns(prevState);
                    newStateColumns.columnsAreLoaded = true;
                    return newStateColumns;
                case commandsEnum.reload_grid:
                    let newStateGrid: AdminStateType;
                    newStateGrid = { ...prevState };
                    newStateGrid.gridData = command.payload;
                    return newStateGrid;
                case commandsEnum.change_selectedFilter:
                    let newStateChangeFilter: AdminStateType;
                    newStateChangeFilter = { ...prevState };
                    newStateChangeFilter.selectedFilter = command.payload;
                    return newStateChangeFilter;
                case commandsEnum.change_search_pattern:
                    let newStatePattern: AdminStateType;
                    newStatePattern = { ...prevState };
                    newStatePattern.searchPattern = command.payload;
                    return newStatePattern;
                default:
                    return prevState;
            }
        }

        const handleUpdateClick = (id: number) => {
            let command: any = { command: "update", recordId: id+"" };
            callBackCommands(command);
        };

        //Modal Question to delete CustomerType
        const handleDeleteClick = (id: string) => {
            let newModalQuestionState: TPModalQuestionState;
            newModalQuestionState = { ...modalQuestionState };
            newModalQuestionState.isShown = true;
            newModalQuestionState.callBackData = { recordId: id };
            setModalQuestionState(newModalQuestionState);
        };

        //Delete function after question confirmation
        const handleCallBackModal = async (
            confirmDelete: boolean,
            callBackData: any
        ) => {

        };

        //Command
        const handleNewClick = () => {
            let command: any = { command: "new" };
            callBackCommands(command);
        };

        function loadData() {            
            handleInitializeFolders(0, false);
        }

        //Refresh
        const handleRefreshClick = () => {
            loadData();
        };

        const calendarTypeColumns: CustomColumnNames<Data> = {
            id: idLabel,
            folderName: nameLabel
        };

        const customColumns: ColumnStyles<Data> = {
            id: ({ value, item }) => (
                <TablePrimaryItem
                    value={value}
                    onClick={() => handleUpdateClick(value)}
                />
            ),
            isActive: ({ value }) => (
                <TableChip
                    value={value}
                    onLabel={yesLabel}
                    offLabel={noLabel}
                    justify="flex-start"                
                />
            ),

        };

        const handleIconClick = (event: TPIconTypes) => {
            if (event === TPIconTypes.loop || event === TPIconTypes.refresh) handleRefreshClick();
        };

        const value = useAppConctextImageAdmin();
        const {
            listTempFolder,
            handleInitializeFolders
        } = value;

        useEffect(() => {
            loadResources()
                .then(function () {
                    loadData();
                    setIsLoadingScreen(false);
                })
                .catch(function (error) {
                    TPLog.Log(
                        `Error ${resourceSet} loadResources ex`,
                        TPLogType.ERROR,
                        error
                    );
                    console.error(
                        `Error ${resourceSet} loadResources ex`
                    );
                });

            loadData();

        }, []);
     
        useImperativeHandle(ref, () => ({
            refreshGridFromParent() {
                loadData();
            },
        }));

        return (
            <>
                <TPModalQuestion
                    id="IdModalQuestion"
                    title={titleModalDeleteLabel}
                    yesLabel={okAlertButtonLabel}
                    noLabel={cancelAlertButtonLabel}
                    question={deleteLabelQuestion}
                    callBackData={modalQuestionState.callBackData}
                    isShown={modalQuestionState.isShown}
                    callBackAnswer={handleCallBackModal}
                ></TPModalQuestion>
                <TPLoadingOverlay active={isLoadingScreen}>
                    <div className="row">
                        <div className="col">
                            <CIMTitleSection>
                                <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                                <TPButton
                                    id="new-customer-type"
                                    isDesignSystem={true}
                                    onClick={handleNewClick}
                                    withIcon={TPIconTypes.add}
                                    orientationIcon="left"
                                    style={{ padding: "1px 18px" }}
                                >
                                    {uploadImg}
                                </TPButton>
                            </CIMTitleSection>
                            <DynamicTable
                                id="images-admin"
                                data={listTempFolder}
                                columnNames={calendarTypeColumns}
                                columnStyles={customColumns}
                                hiddenColumns={["localizedFolderName","dateTimeUpload", "dateUpload", "folderCode", "type", "parent", "extension", "dateUpload", "userUpload", "keywords", "size", "dimensions", "blobId", "imageUrl", "thumbnailUrl", "fileName", "thumbnail", "folderNameLocalizedValues"]}
                                noDataMessage={`${thereAreNoRecordsToShowLabel}.`}
                                onIconClicked={(event: any) => handleIconClick(event)}
                            />
                        </div>
                    </div>
                </TPLoadingOverlay>

            </>
        );
    }
);

export default ImagesAdminTable;



