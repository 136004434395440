import React, {FC, useState} from "react";
import {ModalSizeEnum, TPButtonTypes, TPIconTypes} from "@/models/Global/TPGlobalEnums";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPModal from "@/layouts/TPModal/TPModal";
import AdditionalDataInsertUpdate from "@/pages/AdditionalData/AdditionalDataInsertUpdate";
import {boolean} from "yup";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";


interface AdditionalDataPopUpProps {

    className?: string;
    callBackResult: Function;

}

const AdditionalDataPopUp: FC<AdditionalDataPopUpProps> = ({
                                                               className = '',

                                                               callBackResult,

                                                           }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleCallBackResult2 = (result: { success: boolean; data: any }) => {
        handleModalAnswer('accept', result.data)
    };

    const handleModalAnswer = (action: 'accept' | 'cancel' | 'close', data?: any) => {
        setIsModalVisible(false);
        if (action === 'accept' && data) {
            callBackResult({succes: boolean, data});
        }

    };

    const buttonStyle : React.CSSProperties = {
        justifyContent: "space-between",
    }
    const labelsButton ={
        backButton: "Back",
        createButton: "Create",
    }

    const componentStyle: React.CSSProperties = {
        position: 'absolute' as 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflow: 'auto' as 'auto',
        height: '1300px',
        maxHeight: "100%",
    };
    return (
        <>
                    <TPButton
                        onClick={() => setIsModalVisible(true)}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "30px",
                            height: "30px",
                            color: "purple",
                            backgroundColor: 'white',
                            fontSize: '30px',
                            border: '2px solid purple',
                            borderRadius: '5px'
                        }}
                        isDesignSystem={true}
                   >
                        <TPIcon iconType={TPIconTypes.plusGo}
                          style={{
                              width: "15px",
                              height: "15px",
                          }}/>
                    </TPButton>


            {isModalVisible && (
                <TPModal
                    modalState={{
                        titleModal: "",
                        acceptLabel: undefined,
                        cancelLabel: undefined,
                        callBackAnswer: handleModalAnswer,
                        isShown: isModalVisible,
                        modalWidth: ModalSizeEnum.MODALXL,
                        hiddenHeader: true,
                        hideFooterButtons: true,
                    }}

                >
                    <div
                        style={{
                            position: 'relative',
                            height: "max-content",
                            minHeight: "630px",
                            maxHeight: "100%",
                            overflow: 'hidden',
                            alignItems: 'center'
                        }}
                    >
                        <AdditionalDataInsertUpdate
                            mode="Insert"
                            recordId=""
                            labelsButton={labelsButton}
                            buttonStyle={Object.keys(buttonStyle).length ? buttonStyle : {}}
                            style={Object.keys(componentStyle).length ? componentStyle : {}}
                            callBackResult={(success: false) => handleModalAnswer("cancel")}
                            callBackResult2={handleCallBackResult2}
                        />
                    </div>
                </TPModal>
            )}
        </>
    );
};

export default AdditionalDataPopUp;






