import { useEffect, useState } from "react";
import ChildrenFolder from "./ImagesAdminChildrenFolder";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import { ImagesAdminPopupMenu } from "./ImagesAdminPopupMenu";

import {
    ImagesAdminFolderDivStyle,
    IcoFolderIStyle,
    MenuItemDivStyle,
    NameFolderPStyle,
    NivelDivStyle,
    NameFolderInputStyle,
    IcoMenuIStyle,
} from "./StyleImageAdmin";
import React from "react";
import { name } from "@azure/msal-browser/dist/packageMetadata";
import { DataType, OperationMode } from "./useStatusImagesAdmin";
import Select from "react-select/dist/declarations/src/Select";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";
import TPLabel from "../../components/bootstrap/forms/TPLabel/TPLabel";

import { TPKeyValue } from "../../helpers/TPKeyValue";
import { RecordLocalizedModel } from "../../models/Global/TPGlobalModels";
import { LanguagesViewModel } from "../../models/Languages/LanguagesViewModel";
import TPGlobal from "../../helpers/TPGlobal";
import { MultilanguageFieldValueViewModel } from "../../models/multilanguage/MultilanguageFieldValueModel";
import { MultilanguageFieldValueService } from "../../services/MultilanguageFieldValueService";
import { TPLog, TPLogType } from "../../helpers/TPLog";
import { TPI18N } from "../../services/I18nService";
import InputLanguages from "./InputLanguages";
import TPIcon from "../../components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "../../models/Global/TPGlobalEnums";

type ContainerFolderProps = {
    folders: RecursiveRoutes;
};

const ParentFolder: React.FC<ContainerFolderProps> = ({ folders }) => {
    const resourceSet: string = "ImagesAdminComponent";
    const [activeAction, setActiveAction] = React.useState<Boolean>(false);

    const [active, setActive] = useState(false);

    const [recordLocalizedModel, setRecordLocalizedModel] = useState<Array<RecordLocalizedModel>>([]);
    const [baseLanguageOptions, setBaseLanguageOptions] = useState<Array<TPKeyValue>>([]);

    const multilanguageTableName: String = "IMAGes";
    const multilanguageFieldName: String = "FolderName_IMAG";

    const value = useAppConctextImageAdmin();
    const {
        handleLoading,
        handleMultiSelectedFileClear,
        handleSelected,
        selectedFolderParent
    } = value;

    const handleActivate = () => {
        setActive(!activeAction);
        Cancel();
        if (!activeAction) handleLoading(true);
        setActiveAction(!activeAction);
        if (!activeAction) handleSelected(folders, OperationMode.SelectFolder);

    };

    const loadLanguages = () => {
        let i: number;
        let expectedCodes: Array<number> = [200];

        let listLanguages: Array<TPKeyValue> = [];
        for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
            let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
            let keyValueElement: TPKeyValue = { key: item.id, value: item.name };
            listLanguages.push(keyValueElement);
        }

        if (!recordLocalizedModel)
            setRecordLocalizedModel(new Array<RecordLocalizedModel>);

        let listFormName = [...recordLocalizedModel];
        for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
            let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
            let keyValueElement: RecordLocalizedModel = {
                languageId: item.id,
                localizedValue: "",
                order: i,
            };
            listFormName.push(keyValueElement);
        }
        setRecordLocalizedModel(listFormName);

        if (listLanguages) {
            listLanguages.unshift({ key: "", value: "--" });
            let language = TPGlobal.language;
            //setBaseLanguageOptions(listLanguages);
        }
    };

    const getRecordLanguageList = async (
        pRecordId: String,
    ): Promise<Array<MultilanguageFieldValueViewModel>> => {
        let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest =
                await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
                    multilanguageTableName,
                    multilanguageFieldName,
                    pRecordId,
                    false,
                    true,
                    expectedCodes,
                );


            let recordLocalizedModelTemp = [...recordLocalizedModel];

            recordLocalizedModelTemp.forEach((r) => {
                if (responseRequest) {
                    const foundRecord = responseRequest.find(s => s.languageId == r.languageId);

                    if (foundRecord) {
                        r.languageId = foundRecord.languageId || "";
                        r.localizedValue = foundRecord.recordDescription || "";
                    }
                }
            });

            setRecordLocalizedModel(recordLocalizedModelTemp);

            let recordInfo: Array<MultilanguageFieldValueViewModel>;
            recordInfo = [...responseRequest];

            return recordInfo;
        } catch (error) {
            TPLog.Log(
                `Error ParentFolder getRecordLanguageList ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ParentFolder getRecordLanguageList ex`);
            return [];
        }
    };

    const Cancel = () => {
        setActiveAction(false);
        handleSelected(new RecursiveRoutes(), OperationMode.Defaul);
        handleMultiSelectedFileClear();
    };

    useEffect(() => {
        loadLanguages();
        setActive(selectedFolderParent.id == folders.id);
    }, []);


    return (
        <>
            <div style={folders.isActive ?
                selectedFolderParent.id == folders.id ?
                    {
                        backgroundColor: "#7719a9",
                        padding: "5px",
                        color: "white",
                        borderRadius: "4px",
                        width: "100%"
                    } : {}
                : {
                    backgroundColor: "rgb(201 201 201)",
                    padding: "5px",
                    color: "white",
                    borderRadius: "4px",
                    width: "100%"
                }} >
                <ImagesAdminFolderDivStyle >
                    <div style={{ transform: "rotate(230deg)", fontSize: "22px" }} >
                        {/*<TPIcon*/}
                        {/*    iconType={TPIconTypes.outlineUndo}*/}
                        {/*    onClick={handleActivate}*/}
                        {/*/>*/}
                    </div>
                    <TPIcon
                        iconType={TPIconTypes.folderCopy}
                        onClick={handleActivate}
                        style={{ fontSize: "22px" }}
                    />
                    <NameFolderPStyle onClick={handleActivate}>
                        {" "}
                        {(folders.folderName ? folders.folderName : folders.localizedFolderName)}
                    </NameFolderPStyle>
                </ImagesAdminFolderDivStyle>
            </div>
            {active
                ? folders.childrens.map((folder) => (                  
                    <NivelDivStyle>
                        <ChildrenFolder folders={folder} key={folder.id}></ChildrenFolder>
                    </NivelDivStyle>
                ))
                : null}

        </>
    );
};

export default ParentFolder;

