import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { DataTableContainer, TableContainer, tableStyles } from "@/components/bootstrap/content/tables/tpTableStyles";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPPageFirstRow, TPPageSearchContainer } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPModalQuestionState } from "@/layouts/ModalQuestion/TPModalQuestion";
import { SecurityType, taskStatusEnum, TPActiveOptions, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import * as FileSaver from "file-saver";
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import { GroupDashboardDataService } from "@/services/GroupDashboardService";
import { RightRowContainerStyle, RowConfigStyle } from "../FormDesigner/StyleFromDesigner";
import { useAppGroupDashboard } from "./ContextGroupDashboard";
import { LocationLanguagesGroupDashboard, LocationNameGlobal, LocationNameTableUser } from "./GroupDashboardLocalization";
import TPLabel from "../../components/bootstrap/forms/TPLabel/TPLabel";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { CaseService } from "@/services/CaseService";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TableChip from "../../modules/core/utils/table-micro-components/TableChip";
import CockpitInfoCard from "../SupervisorCockpit/utils/CockpitInfoCard";
import { TeamActivityTickets } from "../SupervisorCockpit/ActiveTeamMembers/ActiveTeamMembersTickets";
import { InfoCardLabels, InfoCardTypes } from "../SupervisorCockpit/supervisor-cockpit.model";

interface InputModel {
    callBackCommands: Function;
    callBackCount: Function;
    mode: string;
    recordId: string;
    active: boolean;
}

type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<any>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};

const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: "",
};

enum commandsEnum {
    "set_filterIsLoaded" = 0,
    "setup_grid_columns" = 1,
    "reload_grid" = 2,
    "change_selectedFilter" = 3,
    "change_search_pattern" = 4,
}

const componentFileName = "GroupDashboardUserAdmin.tsx";

type commandType = {
    type: commandsEnum;
    payload: any;
};

let modalQuestionInitialState: TPModalQuestionState = {
    isShown: false,
    callBackData: {},
};

let initialStateFilter: Array<TPKeyValue> = [];

const GroupDashboardUserAdmin = forwardRef(
    ({ callBackCommands, callBackCount, active }: InputModel, ref) => {
        const casecontext: any = React.useContext(TPCaseViewerContext);
        const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
        const [location, setLocation] = useState(new LocationNameTableUser);
        const [locationGlobal, setLocationGlobal] = useState(new LocationNameGlobal);
        const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);
        const [exportLabel, setExportLabel] = useState("");
        const [isLoading, setIsLoading] = useState(false);
        const [infoCardLabels, setInfoCardLabels] = useState<InfoCardLabels>({} as InfoCardLabels);

        const loadResourcesAndCustomerTypeFilter = async () => {
            let temp = new LocationLanguagesGroupDashboard();
            await temp.handleLocationUser(resourceSet);
            setLocation(temp.getLocationNamUser);
            setExportLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'ExportLabel'));

            await temp.locationGlobal(TPGlobal.globalResourceSet);
            setLocationGlobal(temp.getLocationNameGlobal);

            let newFilterKeyValue: Array<TPKeyValue> = [];
            newFilterKeyValue.push({
                key: TPActiveOptions.ALL.toString(),
                value: await TPI18N.GetText(resourceSet, "All"),
            });
            newFilterKeyValue.push({
                key: TPActiveOptions.ACTIVE.toString(),
                value: await TPI18N.GetText(resourceSet, "Active"),
            });
            newFilterKeyValue.push({
                key: TPActiveOptions.INACTIVE.toString(),
                value: await TPI18N.GetText(resourceSet, "Inactive"),
            });
            setFilterKeyValue(newFilterKeyValue);
        }

        const setupGridColumns = (prevState: AdminStateType) => {
            try {
                let newState: AdminStateType;
                newState = { ...prevState };
                let newColumns: Array<any> = [];
                newColumns.push({
                    width: "180px",
                    center: true,
                    name: location.user,
                    cell: (row: { [x: string]: any }) => {
                        return row["fullname"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });
                newColumns.push({
                    width: "89px",
                    center: true,
                    name: location.status,
                    cell: (row: { [x: string]: any }) => {
                        switch (row["status"]) {
                            case TPGlobal.StatusUser.ONLINE:
                                return (
                                    <div style={{ backgroundColor: "green", height: "30px", width: "30px", borderRadius: "100%", boxShadow: "0px -1px 10px black inset" }} ></div>
                                );
                            case TPGlobal.StatusUser.OFFLINE:
                                return (
                                    <div style={{ backgroundColor: "green", height: "30px", width: "30px", borderRadius: "100%", boxShadow: "0px -1px 10px black inset" }} ></div>
                                );
                        }
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });
                newColumns.push({
                    center: true,
                    width: "autto",
                    name: location.timeLogged,
                    cell: (row: { [x: string]: any }) => {
                        return row["timeLogged"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });
                newColumns.push({
                    center: true,
                    width: "autto",
                    name: location.closeCase,
                    cell: (row: { [x: string]: any }) => {
                        return row["closedCases"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });

                newColumns.push({
                    center: true,
                    width: "autto",
                    name: location.timeReady,
                    cell: (row: { [x: string]: any }) => {
                        return row["timeReady"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });
                newColumns.push({
                    center: true,
                    width: "autto",
                    name: location.workingTimeInCase,
                    cell: (row: { [x: string]: any }) => {
                        return row["workingCaseTime"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });
                newColumns.push({
                    center: true,
                    width: "autto",
                    name: location.description,
                    cell: (row: { [x: string]: any }) => {
                        return row["localizedDescription"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });

                newState.gridColumns = [...newColumns];
                return newState;
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} setupGridColumns ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${resourceSet} setupGridColumns ex`);
                return prevState;
            }
        };

        const handleRowsPerPageChanged = (e: any) => {
            const recordSize = fixedHeaderScrollHeight / 10;
            const newRecordSize = recordSize * e;
            setFixedHeaderScrollHeight(newRecordSize);
        };

        const [adminState, dispatchCommand] = useReducer(
            doCommand,
            initialStateBLL
        );

        function doCommand(prevState: AdminStateType, command: commandType) {
            switch (command.type) {
                case commandsEnum.set_filterIsLoaded:
                    let newStateFilter: AdminStateType;
                    newStateFilter = { ...prevState };
                    newStateFilter.filterIsLoaded = true;
                    return newStateFilter;
                case commandsEnum.setup_grid_columns:
                    let newStateColumns: AdminStateType = setupGridColumns(prevState);
                    newStateColumns.columnsAreLoaded = true;
                    return newStateColumns;
                case commandsEnum.reload_grid:
                    let newStateGrid: AdminStateType;
                    newStateGrid = { ...prevState };
                    newStateGrid.gridData = command.payload;
                    return newStateGrid;
                case commandsEnum.change_selectedFilter:
                    let newStateChangeFilter: AdminStateType;
                    newStateChangeFilter = { ...prevState };
                    newStateChangeFilter.selectedFilter = command.payload;
                    return newStateChangeFilter;
                case commandsEnum.change_search_pattern:
                    let newStatePattern: AdminStateType;
                    newStatePattern = { ...prevState };
                    newStatePattern.searchPattern = command.payload;
                    return newStatePattern;
                default:
                    return prevState;
            }
        }

        const filteredData = () => {
            let searcheableColumns: Array<string> = [
                "id",
                "description",
                "fullname",
                "isActive",
            ];
            let i: number;
            let search: string;
            search = adminState.searchPattern.trim();
            if (adminState.searchPattern == null || adminState.searchPattern == undefined || !adminState.searchPattern) return adminState.gridData;
            return adminState.gridData.filter(function (item) {
                if (search == "" || search.length <= 2) {
                    return item;
                }
                for (i = 0; i <= searcheableColumns.length - 1; i++) {
                    let itemany: any;
                    itemany = item;
                    if (
                        itemany[searcheableColumns[i]] &&
                        itemany[searcheableColumns[i]]
                            .toString()
                            .toLowerCase()
                            .includes(search.toLowerCase())
                    ) {
                        return item;
                    }
                }
            });
        };

        const handleSearchPatternChange = (newValue: string) => {
            let command1: commandType = {
                type: commandsEnum.change_search_pattern,
                payload: newValue,
            };
            dispatchCommand(command1);

        };

        const exportToCSV = (apiData = filteredData(), fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8") => {
            const ws = XLSX.utils.json_to_sheet(apiData);
            XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
            FileSaver.saveAs(data, fileName + ".xlsx");
        };

        const dataDownloadFormat = () => {
            const data = filteredData();
            let newData: any = [];
            data.forEach((item) => {
                newData.push(
                    {
                        ["name"]: item.name,
                        ["fullname"]: item.fullname,
                        ["status"]: item.status,
                        ["workingCaseId"]: item.workingCaseId,
                        ["workingTimeInCases"]: item.workingTimeInCases,
                        ["workingTaskId"]: item.workingTaskId,
                        ["workingTaskTime"]: item.workingTaskTime,
                        ["localizedDescription"]: item.localizedDescription,
                        ["workedCases"]: item.workedCases,
                        ["closedCases"]: item.closedCases,
                        ["timeLogged"]: item.timeLogged,
                        ["timeReady"]: item.timeReady,
                        ["workingTime"]: item.workingTime

                    }
                );
            })
            return newData;
        }

        const handleLoad = () => {
            let command1: commandType = {
                type: commandsEnum.setup_grid_columns,
                payload: null,
            };
            dispatchCommand(command1);
        }

        useImperativeHandle(ref, () => ({
            refreshGridFromParent() {
            },
        }));

        function loadData(id: string) {
            setLoading(true);
            const { getUserByFilter } = new GroupDashboardDataService();
            let expectedCodes: Array<number> = [200];
            getUserByFilter(id, false, false, expectedCodes)
                .then((data: any) => {
                    setLoading(false);
                    if (data) {
                        callBackCount(data.length);
                        dispatchCommand({
                            type: commandsEnum.reload_grid,
                            payload: data,
                        } as commandType);
                    }
                })
        }

        const value = useAppGroupDashboard();
        const { resourceSet, groupSelected, setLoading } = value;

        useEffect(() => {
            loadResourcesAndCustomerTypeFilter()
                .then(function () {
                    let command1: commandType = {
                        type: commandsEnum.set_filterIsLoaded,
                        payload: null,
                    };
                    dispatchCommand(command1);
                })
                .catch(function (error) {
                    TPLog.Log(
                        `Error ${resourceSet} loadResourcesAndCustomerTypeFilter ex`,
                        TPLogType.ERROR,
                        error
                    );
                    console.error(
                        `Error ${resourceSet} loadResourcesAndCustomerTypeFilter ex`
                    );
                });
        }, []);

        useEffect(() => {
            if (adminState.filterIsLoaded) {
                handleLoad();
            }
        }, [adminState.filterIsLoaded]);

        useEffect(() => {
            handleLoad();
            if (groupSelected && groupSelected.key != "") {
                loadData(groupSelected.key);
            }
        }, [groupSelected]);

        const getCaseById = async function (caseId: number) {
            let serviceClient = new CaseService();
            let expectedCodes: Array<number> = [200];
            setIsLoading(true);
            try {
                let isValidCaseNumber: boolean = await serviceClient.checkCaseById(
                    caseId,
                    false,
                    true,
                    expectedCodes,
                );

                if (isValidCaseNumber) {
                    casecontext.handleAddNewCaseViewerCallBack(caseId);
                }
            } catch (err) {
                TPLog.Log(
                    `Error ${componentFileName} getCaseById ex`,
                    TPLogType.ERROR,
                    err,
                );
                console.error(`Error ${componentFileName} getCaseById ex`);
            } finally {
                setIsLoading(false);
            }
        }

        const getInfoCardType = (item: string) => {
            let type: InfoCardTypes = "offline";
            item == "ONLINE" && (type = "online");
            item == "OFFLINE" && (type = "offline");
            return type;
        }

        const getInfoCardValue = (item: string) => {
            let type: string = "offline";
            item == "ONLINE" && (type = "online");
            item == "OFFLINE" && (type = "offline");
            return type;
        }



        return (
            <>
                {active &&
                    <TPLoadingOverlay active={isLoading}>
                        <div>
                            <div className="row">
                                <div className="col">
                                    <DynamicTable
                                        id="Group_stats_users"
                                        data={filteredData().map(row => {
                                            return {
                                                fullname: row.fullname,
                                                status: row.status,
                                                workingCaseId: row.workingCaseId,
                                                workingCaseTime: row.workingCaseTime,
                                                workingTaskId: row.workingTaskId,
                                                workingTaskTime: row.workingTaskTime,
                                                workedCases: row.workedCases,
                                                closedCases: row.closedCases,
                                                timeLogged: row.timeLogged == -1 ? 0 : row.timeLogged,
                                                timeReady: row.timeReady,
                                                workingTime: row.workingTime
                                            }
                                        })}
                                        columnNames={{
                                            fullname: location.user?.toUpperCase(),
                                            status: location.status?.toUpperCase(),
                                            workingCaseId: location.currenCase?.toUpperCase(),
                                            workingCaseTime: location.timeinCurrenCase?.toUpperCase(),
                                            workingTaskId: location.currenTask?.toUpperCase(),
                                            workingTaskTime: location.timeInCurrentTask?.toUpperCase(),
                                            workedCases: location.workedCase?.toUpperCase(),
                                            closedCases: location.closeCase?.toUpperCase(),
                                            timeLogged: location.timeLogged?.toUpperCase(),
                                            timeReady: location.timeReady?.toUpperCase(),
                                            workingTime: location.workingTimeInCase?.toUpperCase()
                                        }}
                                        onIconClicked={(icon) => {
                                            if (icon == TPIconTypes.loop || icon == TPIconTypes.refresh)
                                                loadData(groupSelected.key);
                                        }}
                                        columnStyles={{
                                            workingCaseId: (row, value) => (
                                                <button
                                                    type="button"
                                                    onClick={() => getCaseById(Number(row.item.workingCaseId) || 0)}
                                                    style={{
                                                        border: 'none',
                                                        background: 'none',
                                                        color: 'purple'
                                                    }}
                                                >
                                                    <b><u>{row.item.workingCaseId}</u></b>
                                                </button>
                                            ),
                                            status: (row) => (
                                                <CockpitInfoCard type={getInfoCardType(row.value)} label={getInfoCardValue(row.value)} isSmallCard />
                                            )

                                        }}
                                        hiddenColumns={["workingTaskId", "workingTaskTime","timeReady"] }
                                    />
                                </div>
                            </div>
                        </div>
                    </TPLoadingOverlay>}
            </>
        );
    }
);

export default GroupDashboardUserAdmin;
