import { StoreModel } from "@/redux/store";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { MenuDefinitionSlice, MenuDefinitionSliceModel } from "./Assets/MenuDefinitionSlice";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import DynamicTable, { CustomColumnHeaderElements, HeaderAlignment } from "@/modules/core/components/dynamic-table/DynamicTable";
import "./Assets/MenuDefinitionStyles.css";
import TPGlobal from "@/helpers/TPGlobal";
import { MenuDefinitionListResponseModel, MenuLevels, MenuTypes } from "@/models/MenuItems/MenuItemsModels";
import { MenuDefinitionService } from "@/services/MenuDefinitionService";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import { TPChip } from "@/components/TPChip/TPChip";
import allThemes from "@/assets/styles/theme";
import { ColumnStyles, CustomColumnNames } from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import { useDispatch } from "react-redux";
import { MenuDefinitionReorderModal } from "./Components/MenuDefinitionReorderModal";
import { useMenuDefinitionLabels } from "./Assets/MenuDefinitionLabels";
import * as availableIcons from "react-icons/md";
import { IconType } from "react-icons";
import { ProfileService } from "@/services/ProfileService";
import { Tooltip } from "@mui/material";
import { showToast, TPToastTypes } from "@/components/bootstrap/components/toasts/TPToast";
import { TPConfirmationModal } from "@/components/TPModalAlert/ConfirmationModal/TPConfirmationModal";

interface MenuDefinitionAdminProperties {
  verticalTabCallback: Function;
}

interface ListMenuModel extends Omit<MenuDefinitionListResponseModel, 'profiles'> {
  [p: string]: any;
}

const getIcon = function (key: string): ReactNode {
  if (Object.values(TPIconTypes).includes(key as TPIconTypes)) 
    return (
      <TPIcon
        iconType={TPIconTypes[key as keyof typeof TPIconTypes]}
        style={{ cursor: "default" }}
      />
    )
  if (Boolean(availableIcons[key as keyof IconType] as IconType))
    return (availableIcons[key as keyof IconType] as IconType)({ fontSize: "24px", color: "#2E2E2E" });
  return <></>;
}

export const MenuDefinitionHome: FC<MenuDefinitionAdminProperties> = function ({ verticalTabCallback }) {
  const dispatch = useDispatch();
  const { contentLoaded, availableProfiles, menus, hierarchy } = useSelector((s: StoreModel) => s[MenuDefinitionSlice.name]) as MenuDefinitionSliceModel;
  const [floatingMenuVisible, setFloatingMenuVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profilesLoading, setProfilesLoading] = useState(false);
  const [reorderModalVisible, setReorderModalVisible] = useState(false);
  const [menuList, setMenuList] = useState<ListMenuModel[]>([]);
  const [previewProfile, setPreviewProfile] = useState<string | null>(null);
  const [menuToDelete, setMenuToDelete] = useState<ListMenuModel | null>(null);
  const { labels } = useMenuDefinitionLabels();
  const dropdownRef = useRef<any>(null);

  const handleClickOutside = function (e: MouseEvent) {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setFloatingMenuVisible(false);
    }
  }

  const getBooleanChip = function (isTrue: boolean): ReactNode {
    return (
      <TPChip
        backgroundColor={isTrue ? allThemes.base.trueGreen : allThemes.base.falseRed}
        label={isTrue ? labels.Yes : labels.No}
      />
    )
  }

  const getIdLinkStyle = function (actualId: string, displayValue: string) {
    return (
      <button
        type="button"
        className="menu-def-link-button"
        onClick={() => addNewVerticalTab(actualId, displayValue)}
      >
        <u>{displayValue}</u>
      </button>
    );
  }

  const applyRowStyles = function (): ColumnStyles<ListMenuModel> {
    const newRow: ColumnStyles<ListMenuModel> = {
      isActive: (row) => (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <TPCheckBox
            checked={row.item.isActive}
            onChange={() => { }}
          />
        </div>
      ),
      image: (row) => (<>{getIcon(row.item.image || "")}</>),
      item: (row) => (<>{getIdLinkStyle(row.item.id, row.item.item)}</>),
      group: (row) => (<>{getIdLinkStyle(row.item.id, row.item.group)}</>),
      section: (row) => (<>{getIdLinkStyle(row.item.id, row.item.section)}</>),
      urlLink: (row) => (
        <Tooltip title={row.item.urlLink}>
          <div>{row.item.urlLink?.trim().length > 50 ? row.item.urlLink.slice(0,50) + "..." : row.item.urlLink }</div>
        </Tooltip>
      )
    };
    availableProfiles.forEach(p => newRow[p.key] = (row) => (
      <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {getBooleanChip(row.item[p.key] || false)}
      </div>
    ))
    return newRow;
  }

  const getHeaderAlignments = function(): HeaderAlignment<ListMenuModel> {
    const headersAlignments: HeaderAlignment<ListMenuModel> = {
      isActive: "center",
    };
    availableProfiles.forEach(p => headersAlignments[p.key] = "center");
    return headersAlignments;
  }

  const addNewVerticalTab = function (menuId: string, recordDescription?: string) {
    switch (menuId) {
      case MenuTypes.item:
        verticalTabCallback({ command: 'new', payload: { recordId: menuId, subTitle: labels.Item } });
        break;
      case MenuTypes.group:
        verticalTabCallback({ command: 'new', payload: { recordId: menuId, subTitle: labels.Group } });
        break;
      case MenuTypes.section:
        verticalTabCallback({ command: 'new', payload: { recordId: menuId, subTitle: labels.Section } });
        break;
      default:
        verticalTabCallback({
          command: 'update',
          recordId: menuId,
          recordDescription: recordDescription || "",
          languageId: TPGlobal.language
        });
    }
  }

  const deleteMenu = async function(menu: ListMenuModel) {
    setLoading(true);
    setMenuToDelete(null);
    MenuDefinitionService.deleteById(menu.id, false, false, [200, 404])
    .then(r => {
      if (r.responseData.responseCode === 200) {
        let msg = "";
        if (menu.section) msg = labels.DeleteSectionSuccess;
        if (menu.group) msg = labels.DeleteGroupSuccess;
        if (menu.item) msg = labels.DeleteItemSuccess;
        showToast(msg, TPToastTypes.success);
        dispatch(MenuDefinitionSlice.actions.setContentLoaded(false));
        verticalTabCallback({
          command: 'delete',
          recordId: menu.id
        })
      } else {
        let msg = "";
        if (menu.section) msg = labels.DeleteSectionError;
        if (menu.group) msg = labels.DeleteGroupError;
        if (menu.item) msg = labels.DeleteItemError;
        showToast(msg, TPToastTypes.error);
      }
    })
    .catch(err => {
      console.error(err);
      let msg = "";
      if (menu.section) msg = labels.DeleteSectionError;
      if (menu.group) msg = labels.DeleteGroupError;
      if (menu.item) msg = labels.DeleteItemError;
      showToast(msg, TPToastTypes.error);
    })
    .finally(() => setLoading(false))
  }

  const convertMenus = function (originalMenus: MenuDefinitionListResponseModel[]): ListMenuModel[] {
    if (!originalMenus) return [];
    const menusCopy = [...originalMenus];

    const newMenus: ListMenuModel[] = [];
    const sortedMenus = [...menusCopy];
    sortedMenus.forEach((m) => {
      const newMenu: ListMenuModel = {
        ...m,
        urlLink: m.urlLink || "",
        image: m.image || ""
      };
      availableProfiles.forEach(p =>
        newMenu[p.key] = m.profiles.find(originalProf => originalProf.id_PROF === p.key)?.isActive_PRMI || false);
      newMenus.push(newMenu);
    })

    return newMenus;
  }

  const getMenus = function (): void {
    setLoading(true);
    MenuDefinitionService.getAllMenus(false, true, [200, 404])
      .then(retrievedMenus => {
        if (retrievedMenus) {
          // dispatch(MenuDefinitionSlice.actions.setMenus(retrievedMenus));
          dispatch(MenuDefinitionSlice.actions.calculateHierarchy(retrievedMenus));
          dispatch(MenuDefinitionSlice.actions.setContentLoaded(true));
        }
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  const getAvailableProfiles = async function() {
    setLoading(true);
    const profileServiceInstance = new ProfileService();
    profileServiceInstance.getProfilesByFilter("1", false, true, [200, 404])
    .then(profiles => {
      if (profiles) dispatch(MenuDefinitionSlice.actions.setAvailableProfiles(
        profiles.map(p => ({ key: p.id, value: p.localizedDescription || p.description }))
      ))
    })
    .catch(err => console.error(err))
    .finally(() => setProfilesLoading(false))
  }

  const getColumnNames = function(): CustomColumnNames<ListMenuModel> {
    const mainObj: CustomColumnNames<ListMenuModel> = {
      isActive: labels.Active,
      group: labels.Group,
      id: labels.Id,
      image: labels.Image,
      item: labels.Item,
      order: labels.Order,
      section: labels.Section,
      urlLink: labels.URLLink
    }
    availableProfiles.forEach(p => {
      mainObj[p.key] = p.value
    })
    return mainObj;
  }

  const getColumnHeaderButtons = function(): CustomColumnHeaderElements<ListMenuModel> {
   const mainObj: CustomColumnHeaderElements<ListMenuModel> = {};

   availableProfiles.forEach(p => {
    mainObj[p.key] = (
      <button 
        className="menu-def-header-preview-button"
        onClick={(e) => {
          setReorderModalVisible(true);
          setPreviewProfile(p.key);
          e.stopPropagation();
        }}
      >
        {availableIcons["MdSearch"]({fontSize: "20px"})}
      </button>
    )
  })

   return mainObj;
  }

  useEffect(() => {
    getMenus();
    getAvailableProfiles();
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [])

  useEffect(() => {
    setMenuList(convertMenus(menus));
  }, [menus, availableProfiles])

  useEffect(() => {
    if (!contentLoaded) getMenus();
  }, [contentLoaded])

  return (
    <>
      <TPConfirmationModal 
        message={labels.DeleteConfirm}
        onAccept={() => menuToDelete && deleteMenu(menuToDelete)}
        onCancel={() => setMenuToDelete(null)}
        visible={menuToDelete !== null}
      />
      {reorderModalVisible && (
        <MenuDefinitionReorderModal
          visible
          onClose={() => {
            setReorderModalVisible(false)
            setPreviewProfile(null);
          }}
          isPreview={(previewProfile != null) && (previewProfile?.trim().length > 0)}
          profile={previewProfile}
          profileName={availableProfiles.find(p => p.key === previewProfile)?.value}
        />)}
      <TPLoadingOverlay active={loading || profilesLoading}>
        <div className="row">
          <div className="col">
            <CIMTitleSection style={{ overflow: 'visible' }}>
              <TPPageTitle style={{ margin: "0" }}>{labels.MenuDefAdmin}</TPPageTitle>
              <div ref={dropdownRef}>
                <TPButton
                  id="new-menu-button"
                  style={{ padding: "1px 16px", gap: "8px" }}
                  onClick={() => setFloatingMenuVisible(!floatingMenuVisible)}
                  isDesignSystem
                >
                  <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                    <TPIcon iconType={TPIconTypes.add} />
                    {labels.AddNew}
                    <TPIcon
                      iconType={floatingMenuVisible ? TPIconTypes.caretUp : TPIconTypes.caretDown}
                      style={{
                        fontSize: "18px",
                        alignItems: "center",
                        justifyContent: "center",
                        pointerEvents: 'none'
                      }}
                    />
                  </div>
                </TPButton>
                <div className={`menu-def-new-select ${floatingMenuVisible ? 'shown' : ''}`} >
                  <button
                    className="menu-def-new-select-button"
                    onClick={() => addNewVerticalTab(MenuTypes.item)}
                  >
                    {labels.NewItem}
                  </button>
                  <button
                    className="menu-def-new-select-button"
                    onClick={() => addNewVerticalTab(MenuTypes.group)}
                  >
                    {labels.NewGroup}
                  </button>
                  <button
                    className="menu-def-new-select-button"
                    onClick={() => addNewVerticalTab(MenuTypes.section)}
                  >
                    {labels.NewSection}
                  </button>
                </div>
              </div>
            </CIMTitleSection>
            <DynamicTable
              id="menu-definition"
              data={menuList}
              withPreferences
              minorOptions={[
                {
                  key: labels.Edit,
                  onOptionChange: (row) => addNewVerticalTab(row.id, row.section || row.group || row.item || ""),
                  type: "edit",
                  icon: TPIconTypes.edit
                },
                {
                  key: labels.Delete,
                  onOptionChange: (row) => setMenuToDelete(row),
                  type: "delete",
                  icon: TPIconTypes.delete
                },
              ]}
              columnStyles={{ ...applyRowStyles() }}
              onIconClicked={icon => {
                if (icon === TPIconTypes.loop || icon === TPIconTypes.refresh) getMenus();
              }}
              headerAlignment={{ ...getHeaderAlignments() }}
              hiddenColumns={["profiles", "id", "parentId"]}
              childrenPosition="into"
              columnNames={getColumnNames()}
              children={(
                <button
                  className="menu-def-link-button"
                  onClick={() => setReorderModalVisible(true)}
                >
                  {labels.ReorderMenu}
                </button>
              )}
              customHeaderElements={getColumnHeaderButtons()}
            />
          </div>
        </div>
      </TPLoadingOverlay>
    </>
  )
}