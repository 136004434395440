import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { FC, useEffect, useState } from "react";
import '../../Assets/styles.css';
import { ScriptAdditionalData, ScriptAdditionalDataLogic, ScriptLogicStep, ScriptStep } from "@/models/Scripts/ScriptModel";
import { extractTextFromHTML } from "@/helpers/ExtractTextFromHTML";
import { useScriptsLabels } from "../../Assets/labelling";

interface ScriptYesNoLogicItemProperties {
  availableNextSteps: ScriptStep[];
  onCompletion: (logicSteps: ScriptAdditionalDataLogic[]) => void;
  logic?: ScriptAdditionalDataLogic[];
}

const ScriptYesNoLogicItem: FC<ScriptYesNoLogicItemProperties> = function ({ availableNextSteps, onCompletion, logic }) {
  const [yesValue, setYesValue] = useState<string>("");
  const [noValue, setNoValue] = useState<string>("");
  const {labels} = useScriptsLabels();

  useEffect(() => {
    if (yesValue || noValue)
      onCompletion([
        {
          key_SSDA: 'yes',
          nextStepId: yesValue,
          value: labels.Yes
        },
        {
          key_SSDA: 'no',
          nextStepId: noValue,
          value: labels.No
        },
      ])
  }, [yesValue, noValue, labels.Yes, labels.No])

  useEffect(() => {
    if (logic) {
      const foundYes = logic.find(logic => logic.key_SSDA === "yes")?.nextStepId;
      if (foundYes) setYesValue(foundYes);
      const foundNo = logic.find(logic => logic.key_SSDA === "no")?.nextStepId;
      if (foundNo) setNoValue(foundNo);
    }
  }, [logic, availableNextSteps])
  return (
    <>
      <div style={{ display: 'flex', gap: '16px', justifyContent: 'space-between', alignItems: 'center' }}>
        <label style={{ width: '10%' }}>{labels.Yes}</label>
        <TPSelect
          dataSource={
            availableNextSteps.map(step => {
              return {
                key: String(step.id),
                value: step.order == -1 ?
                  extractTextFromHTML(step.description)
                  :
                  String(step.order).concat('. ', extractTextFromHTML(step.description) || '')
              }
            })
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setYesValue(e.target.value)
          }
          value={yesValue}
          containerStyle={{ width: '100%' }}
          placeholder={labels.NextStep}
        />
      </div>
      <div style={{ display: 'flex', gap: '16px', justifyContent: 'space-between', alignItems: 'center' }}>
        <label style={{ width: '10%' }}>{labels.No}</label>
        <TPSelect
          dataSource={
            availableNextSteps.map(step => {
              return {
                key: String(step.id),
                value: step.order == -1 ?
                  extractTextFromHTML(step.description)
                  :
                  String(step.order).concat('. ', extractTextFromHTML(step.description) || '')
              }
            })
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNoValue(e.target.value)
          }
          value={noValue}
          containerStyle={{ width: '100%' }}
          placeholder={labels.NextStep}
        />
      </div>
    </>
  )
}

export default ScriptYesNoLogicItem;