import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPTableCondensedContainer } from "@/components/bootstrap/content/tables/tpTableStyles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import {
    TPPageAcceptCancelButtonsContainer,
    TPPageSection,
    TPPageSectionTitle
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalSuccess, {
    TPModalSuccessStateType
} from "@/layouts/TPModalSuccess/TPModalSuccess";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import { FunctionsViewModel } from "@/models/Functions/FunctionsModels";
import {
    SystemParametersEnum,
    TPActiveOptions,
    TPButtonTypes,
    TPIconTypes,
    TaskTypeComponentEnum
} from "@/models/Global/TPGlobalEnums";
import { TaskTypeViewModel } from "@/models/TaskType/TaskTypeModels";
import {
    WorkflowDetailInputDTO,
    WorkflowDetailInputDTOValidator
} from "@/models/Workflow/WorkflowDetailInputDTO";
import {
    WorkflowDetailPredecessorInputDTO,
    WorkflowDetailPredecessorMultipleInputDTO,
    WorkflowDetailPredecessorMultipleInputDTOValidator
} from "@/models/Workflow/WorkflowDetailPredecessorInputDTO";
import {
    WorkflowDetailReminderInputDTO,
    WorkflowDetailReminderInputDTOValidator
} from "@/models/Workflow/WorkflowDetailReminderInputDTO";
import {
    WorkflowDetailPredecessorViewModel,
    WorkflowDetailReminderViewModel,
    WorkflowDetailViewModel,
    WorkflowTypeViewModel
} from "@/models/Workflow/WorkflowTypeModels";
import { AdditionalDataService } from "@/services/AdditionalDataService";
import { FunctionsService } from "@/services/FunctionsService";
import { TPI18N } from "@/services/I18nService";
import { ParametersService } from "@/services/ParametersService";
import { TaskTypeService } from "@/services/TaskTypeService";
import { WorkflowDetailPredecessorService } from "@/services/WorkflowDetailPredecessorService";
import { WorkflowDetailReminderService } from "@/services/WorkflowDetailReminderService";
import { WorkflowDetailService } from "@/services/WorkflowDetailService";
import { WorkflowTypeService } from "@/services/WorkflowTypeService";
import { FC, ReactElement, useEffect, useState } from "react";
import { DetermineResponsibleStructure } from "./WorkflowDetailAdmin";
import { TPButtonPredecessors } from "./workflowDetailStyles";

type InsertUpdateStateType = {
    order: number | null;
    term: number | null;
    reminderTerm: string;
    definesResponsible: boolean;
    closesCustomer: boolean;
    requiresAllPredecessors: boolean;
    isActive: boolean;
    //validator
    orderErrorMessage: string;
    taskTypeIdErrorMessage: string;
    termErrorMessage: string;
    termUnitErrorMessage: string;
    writerErrorMessage: string;
    readerErrorMessage: string;
};

type ReminderStateType = {
    reminderTerm: number;
    reminderTermUnit: string;
    //Validator
    termReminderErrorMessage: string;
    termReminderUnitErrorMessage: string;
};

type PredecessorStateType = {
    successorId: string;
    predecessorId: string;
    lag: number | null;
    lagUnit: string;
    dateField: string;
    conditionalPath: string;
    reopen: boolean;
    selectedAvailableListValue: string;
    selectedSelectedListValue: string;
    //Validations
    lagErrorMessage: string;
    termErrorMessage: string;
    lagUnitErrorMessage: string;
    dateBasedOnErrorMessage: string;
};

type InsertUpdateProps = {
    onCancelClick: Function;
    onSaveClick: Function;
    mode: string;
    idWorkflowType: string;
    idWorkflowDetail: string;
    responsibleStructure: DetermineResponsibleStructure;
};

const WorkflowDetailInsertUpdate: FC<InsertUpdateProps> = ({
    onCancelClick,
    onSaveClick,
    mode,
    idWorkflowType,
    idWorkflowDetail,
    responsibleStructure,
}): ReactElement => {
    const componentFileName: string = "WorkflowDetailInsertUpdate.tsx";
    //Screen resources
    const resourceSet: string = "WorkflowDetailInsertUpdateComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [parametersSectionLabel, setParametersSectionLabel] = useState("");
    const [orderLabel, setOrderLabel] = useState("");
    const [workflowTypeLabel, setWorkflowTypeLabel] = useState("");
    const [taskTypeLabel, setTaskTypeLabel] = useState("");
    const [termLabel, setTermLabel] = useState("");
    const [writerLabel, setWriterLabel] = useState("");
    const [readerLabel, setReaderLabel] = useState("");
    const [generalErrorMessage, setGeneralErrorMessage] = useState("");
    const [unitTimeLabel, setUnitTimeLabel] = useState("");

    const [definesResponsibleLabel, setDefinesResponsibleLabel] = useState("");
    const [closesCustomerLabel, setClosesCustomerLabel] = useState("");
    const [requiresAllPredecessorsLabel, setRequiresAllPredecessorsLabel] =
        useState("");
    const [isActiveLabel, setIsActiveLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [emptyLabel, setEmptyLabel] = useState("");
    const [reminderLimitLabel, setReminderLimitLabel] = useState("");
    //current mode
    const [currentMode, setCurrentMode] = useState(mode);
    //Select TaskType
    const [tasktypeList, setTaskTypeListState] = useState<Array<TPKeyValue>>([]);
    const [selectedTaskType, setSelectedTaskType] = useState<Array<TPKeyValue>>(
        [],
    );
    const [autocompleteTaskTypeOptions, setAutocompleteTaskTypeOptions] =
        useState<Array<TPKeyValue>>([]);
    const [autocompleteTaskTypeTopNOptions, setAutocompleteTaskTypeTopNOptions] =
        useState<Array<TPKeyValue>>([]);
    //Select Term Unit
    const [termUnitList, setTermUnitListState] = useState<Array<TPKeyValue>>([]);
    const [selectedTermUnit, setSelectedTermUnit] = useState("");
    //Select Writer
    const [writerList, setWriterListState] = useState<Array<TPKeyValue>>([]);
    const [selectedWriter, setSelectedWriter] = useState<Array<TPKeyValue>>([]);
    const [autocompleteWriterOptions, setAutocompleteWriterOptions] = useState<
        Array<TPKeyValue>
    >([]);
    const [autocompleteWriterTopNOptions, setAutocompleteWriterTopNOptions] =
        useState<Array<TPKeyValue>>([]);
    //Selected Reader
    const [selectedReader, setSelectedReader] = useState<Array<TPKeyValue>>([]);
    const [autocompleteReaderOptions, setAutocompleteReaderOptions] = useState<
        Array<TPKeyValue>
    >([]);
    const [autocompleteReaderTopNOptions, setAutocompleteReaderTopNOptions] =
        useState<Array<TPKeyValue>>([]);
    //Reminders
    const [reminderLabel, setReminderLabel] = useState("");

    //modal success
    const initialModalSuccess: TPModalSuccessStateType = {
        isShown: false,
        title: "",
        successMessage: "",
        yesLabel: "",
        callBackFunction: TPGlobal.foo,
    };
    const [modalSuccessState, setModalSuccessState] =
        useState<TPModalSuccessStateType>(initialModalSuccess);

    //detail State
    let insertUpdateInitialState: InsertUpdateStateType = {
        order: 0,
        term: 0,
        reminderTerm: "",
        definesResponsible: false,
        closesCustomer: false,
        requiresAllPredecessors: false,
        isActive: true,
        orderErrorMessage: "",
        taskTypeIdErrorMessage: "",
        termErrorMessage: "",
        termUnitErrorMessage: "",
        writerErrorMessage: "",
        readerErrorMessage: "",
    };

    const [insertUpdateState, setInsertUpdateState] = useState(
        insertUpdateInitialState,
    );

    const [workflowTypeValueState, setWorkflowTypeValueState] =
        useState<WorkflowTypeViewModel | null>(null);

    //Reminders State
    let reminderInitialState: ReminderStateType = {
        reminderTerm: 0,
        reminderTermUnit: "",
        termReminderErrorMessage: "",
        termReminderUnitErrorMessage: "",
    };

    const [reminderState, setReminderState] = useState(reminderInitialState);

    //Screen resources Predecessor
    const resourceSetPredecessor: string = "WorkflowDetailPredecessorComponent";
    const [predecessorSectionLabel, setPredecessorSectionLabel] = useState("");
    const [lagLabel, setLagLabel] = useState("");
    const [dateFieldLabel, setDateFieldLabel] = useState("");
    const [availableLabel, setAvailableLabel] = useState("");
    const [selectedLabel, setSelectedLabel] = useState("");
    const [startLabel, setStartLabel] = useState("");
    const [activeYesLabel, setActiveYesLabel] = useState("");
    const [activeNoLabel, setActiveNoLabel] = useState("");
    const [orderColumnLabel, setOrderColumnLabel] = useState("");
    const [taskColumnLabel, setTaskColumnLabel] = useState("");
    const [pathColumnLabel, setPathColumnLabel] = useState("");
    const [lagColumnLabel, setLagColumnLabel] = useState("");
    const [selectLagTypeLabel, setSelectLagTypeLabel] = useState("");

    useState("");
    let workflowDetailPredecessorInitialState: PredecessorStateType = {
        successorId: "",
        predecessorId: "",
        lag: null,
        lagUnit: "d",
        dateField: "",
        conditionalPath: "",
        reopen: false,
        selectedAvailableListValue: "",
        selectedSelectedListValue: "",
        termErrorMessage: "",
        lagUnitErrorMessage: "",
        lagErrorMessage: "",
        dateBasedOnErrorMessage: "",
    };
    const [workflowDetailPredecessorState, setWorkflowDetailPredecessorState] =
        useState(workflowDetailPredecessorInitialState);
    //Lag Unit
    const [lagUnitList, setLagUnitListState] = useState<Array<TPKeyValue>>([]);
    //Additional Data
    const [additionalDataList, setAdditionalDataListState] = useState<
        Array<TPKeyValue>
    >([]);
    //Available List
    const [availableList, setAvailableListState] = useState<Array<TPKeyValue>>(
        [],
    );

    //Selected List
    const [selectedList, setselectedListState] = useState<Array<TPKeyValue>>([]);

    let workflowDetailPredecessorListData: Array<WorkflowDetailPredecessorViewModel>;
    let workflowDetailPotentialPredecessorsListData: Array<WorkflowDetailPredecessorViewModel>;
    let [thereIsDetemrineResponsible, setThereIsDetemrineResponsible] =
        useState(false);
    let [thereIsCloseCustomer, setThereIsCloseCustomer] = useState(false);
    var selectStart: boolean = true;
    var allowAdd: string = "1";
    const [allowStart, setAllowStart] = useState("1");

    //ScreenMessages
    const [selectOrder, setSelectOrder] = useState("");
    const [selectExisting, setSelectExisting] = useState("");
    const [selectLagUnits, setSelectLagUnits] = useState("");
    const [errorMinuteTerm, setErrorMinuteTerm] = useState("");
    const [selectLatOrDate, setSelectLagOrDate] = useState("");
    const [noDecisionRetryTask, setNoDecisionRetryTask] = useState("");
    const [startNotAllowed, setStartNotAllowed] = useState("");
    const [addNotAllowed, setAddNotAllowed] = useState("");
    const [reopenWorkflowLabel, setReopenWorkflowLabel] = useState("");

    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [modeLagSelected, setModeLagSelected] = useState("withoutLag");
    const [modeLagList, setModeLagList] = useState<Array<TPKeyValue>>([]);

    const loadResourcesAndLoadInfo = async () => {
        //todo estado setWorkflowDetailPredecessorState tiene dos migraciones que pueden presentar problemas
        //todo sebas

        setTitleLabel(
            await TPI18N.GetText(resourceSet, "Title" + currentMode + "Label"),
        );
        setParametersSectionLabel(
            await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "ParametersSectionLabel",
            ),
        );
        setWorkflowTypeLabel(
            await TPI18N.GetText(resourceSet, "WorkflowTypeLabel"),
        );
        setOrderLabel(await TPI18N.GetText(resourceSet, "OrderLabel"));
        setTaskTypeLabel(await TPI18N.GetText(resourceSet, "TaskTypeLabel"));
        setTermLabel(await TPI18N.GetText(resourceSet, "TermLabel"));
        setWriterLabel(await TPI18N.GetText(resourceSet, "WriterLabel"));
        setReaderLabel(await TPI18N.GetText(resourceSet, "ReaderLabel"));
        setReminderLimitLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "ReminderLimitLabel"),
        );
        setDefinesResponsibleLabel(
            await TPI18N.GetText(resourceSet, "DefinesResponsibleLabel"),
        );
        setClosesCustomerLabel(
            await TPI18N.GetText(resourceSet, "ClosesCustomerLabel"),
        );
        setUnitTimeLabel(await TPI18N.GetText(resourceSet, "UnitTimeLabel"));
        setRequiresAllPredecessorsLabel(
            await TPI18N.GetText(resourceSet, "RequiresAllPredecessorsLabel"),
        );
        setIsActiveLabel(await TPI18N.GetText(resourceSet, "IsActiveLabel"));

        setSaveButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
        );
        setCancelButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        );
        //Reminders
        setReminderLabel(await TPI18N.GetText(resourceSet, "ReminderLabel"));

        setEmptyLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel"),
        );

        await getTermUnitList();
        //Load Organization List
        await getTaskTypeList();
        //Load Term Unit List
        await getTermUnitList();
        //Load Writer List
        await getWriterList();

        //Predecessors
        let itemText: string;
        let itemValue: string;
        let newAvailableList: Array<TPKeyValue> = [];
        let newSelectedList: Array<TPKeyValue> = [];

        setPredecessorSectionLabel(
            await TPI18N.GetText(resourceSet, "PredecessorSectionLabel"),
        );
        setLagLabel(await TPI18N.GetText(resourceSetPredecessor, "LagLabel"));
        setDateFieldLabel(
            await TPI18N.GetText(resourceSetPredecessor, "DateFieldLabel"),
        );
        setAvailableLabel(
            await TPI18N.GetText(resourceSetPredecessor, "AvailableLabel"),
        );
        setSelectedLabel(
            await TPI18N.GetText(resourceSetPredecessor, "SelectedLabel"),
        );
        setStartLabel(await TPI18N.GetText(resourceSetPredecessor, "startLabel"));
        setActiveYesLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveYes"),
        );
        setActiveNoLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveNo"),
        );
        setOrderColumnLabel(
            await TPI18N.GetText(resourceSetPredecessor, "OrderColumnLabel"),
        );
        setTaskColumnLabel(
            await TPI18N.GetText(resourceSetPredecessor, "TaskColumnLabel"),
        );
        setPathColumnLabel(
            await TPI18N.GetText(resourceSetPredecessor, "PathColumnLabel"),
        );
        setLagColumnLabel(
            await TPI18N.GetText(resourceSetPredecessor, "LagColumnLabel"),
        );
        setSelectLagTypeLabel(
            await TPI18N.GetText(resourceSetPredecessor, "SelectLagTypeLabel"),
        );

        let newModeLagList: Array<TPKeyValue> = [...modeLagList];
        newModeLagList.push({
            key: "withoutLag",
            value: await TPI18N.GetText(
                resourceSetPredecessor,
                "SelectLagWithoutLagLabel",
            ),
        });
        newModeLagList.push({
            key: "withLag",
            value: await TPI18N.GetText(
                resourceSetPredecessor,
                "SelectLagWithLagLabel",
            ),
        });
        newModeLagList.push({
            key: "withDate",
            value: await TPI18N.GetText(
                resourceSetPredecessor,
                "SelectLagWithLagDateLabel",
            ),
        });

        setModeLagList(newModeLagList);
        //Screen Messages
        setSelectOrder(await TPI18N.GetText(resourceSet, "SelectOrder"));
        setSelectExisting(await TPI18N.GetText(resourceSet, "SelectExisting"));
        setSelectLagUnits(await TPI18N.GetText(resourceSet, "SelectLagUnits"));
        setErrorMinuteTerm(await TPI18N.GetText(resourceSet, "ErrorMinuteTerm"));
        setNoDecisionRetryTask(
            await TPI18N.GetText(resourceSet, "NoDecisionRetryTaskLabel"),
        );
        setStartNotAllowed(await TPI18N.GetText(resourceSet, "startNotAllowed"));
        setAddNotAllowed(await TPI18N.GetText(resourceSet, "addNotAllowed"));
        setReopenWorkflowLabel(
            await TPI18N.GetText(resourceSet, "ReopenWorkflowLabel"),
        );

        //Load Lag Unit List
        await getLagUnitList();

        await getWorkflowType();

        //Load Predecessor List
        await getAdditionalDataList();

        if (mode == "Insert") {
            await getWorkflowDetailPredecessors("-1");
            await getworkflowDetailPotentialPredecessors("0");
        } else {
            await getWorkflowDetailById(idWorkflowDetail);
            await getWorkflowDetailReminderById(idWorkflowDetail);
            await getWorkflowDetailPredecessors(idWorkflowDetail);
            await getworkflowDetailPotentialPredecessors(idWorkflowDetail);
        }

        if (
            workflowDetailPredecessorListData &&
            workflowDetailPredecessorListData.length > 0
        ) {
            for (let i = 0; i < workflowDetailPredecessorListData.length; i++) {
                const element = workflowDetailPredecessorListData[i];
                if (element.predecessorId === TPGlobal.initialWorkflowDetail) {
                    itemText = await TPI18N.GetText(resourceSetPredecessor, "startLabel");
                    selectStart = false;
                    allowAdd = "0";
                } else {
                    itemText = element.order.toString().replace(",", ".");
                    itemText += "-" + element.taskTypeLocalizedDescription;
                    setAllowStart("0");
                }
                if (element.conditionalPath) {
                    if (element.conditionalPath.toUpperCase() === "Y") {
                        itemText +=
                            "-" +
                            (await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveYes"));
                    } else if (element.conditionalPath.toUpperCase() === "N") {
                        itemText +=
                            "-" +
                            (await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveNo"));
                    } else {
                        itemText += "-NA";
                    }
                } else {
                    itemText += "-" + "NA";
                }
                if (element.lag === 0) {
                    if (element.dateField === "") {
                        itemText += "-0";
                    } else {
                        itemText += "-" + element.dateField;
                    }
                } else {
                    //itemText += "-" + WorkflowDetailPredecessors(i).Lag_WDPR.ToString + WorkflowDetailPredecessors(i).LagUnit_Id_BRAN.ToString
                    itemText += `-${!element.lag ? "0" : element.lag}${element.lagUnit != null ? element.lagUnit : ""}`;
                    //itemText += "-" + !element.lag ? "0" : element.lag + element.lagUnit;
                }

                itemValue = "";
                itemValue += element.order + "|";
                itemValue += element.successorId + "|";
                itemValue += element.predecessorId + "|";
                itemValue += element.lag + "|";
                itemValue += element.lagUnit + "|";
                itemValue += element.dateField + "|";
                itemValue +=
                    (element.conditionalPath != null ? element.conditionalPath : "") +
                    "|";
                itemValue += element.taskTypeLocalizedDescription;

                let keyvalue: TPKeyValue = {
                    key: itemValue,
                    value: itemText,
                };
                newSelectedList.push(keyvalue);
            }
            let newWorkflowDetailPredecessorState = {
                ...workflowDetailPredecessorState,
            };
            newWorkflowDetailPredecessorState.selectedSelectedListValue =
                newSelectedList[0].key;
            setWorkflowDetailPredecessorState(newWorkflowDetailPredecessorState);
            setselectedListState(newSelectedList);
        }

        if (
            workflowDetailPotentialPredecessorsListData &&
            workflowDetailPotentialPredecessorsListData.length > 0
        ) {
            for (
                let i = 0;
                i < workflowDetailPotentialPredecessorsListData.length;
                i++
            ) {
                const element = workflowDetailPotentialPredecessorsListData[i];

                itemText = element.order.toString().replace(",", ".");
                itemText += "-" + element.taskTypeLocalizedDescription;
                if (element.conditionalPath) {
                    if (element.conditionalPath.toUpperCase() === "Y") {
                        itemText +=
                            "-" +
                            (await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveYes"));
                    } else if (element.conditionalPath.toUpperCase() === "N") {
                        itemText +=
                            "-" +
                            (await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveNo"));
                    } else {
                        itemText += "";
                    }
                } else {
                    itemText += "";
                }
                itemValue = "";
                itemValue += element.order ? `${element.order}|` : "|";
                itemValue +=
                    element.successorId !== 0
                        ? `${element.successorId.toString()}|`
                        : "0|";
                itemValue += element.predecessorId ? `${element.predecessorId}|` : "|";
                itemValue += element.lag ? `${element.lag}|` : "|";
                itemValue += element.lagUnit ? `${element.lagUnit}|` : "|";
                itemValue += element.dateField ? `${element.dateField}|` : "|";
                itemValue += element.conditionalPath
                    ? `${element.conditionalPath}|`
                    : "|";
                itemValue += element.taskTypeLocalizedDescription
                    ? `${element.taskTypeLocalizedDescription}|`
                    : "";
                let keyvalue: TPKeyValue = {
                    key: itemValue,
                    value: itemText,
                };
                newAvailableList.push(keyvalue);
            }
        }
        if (selectStart) {
            itemText = await TPI18N.GetText(resourceSetPredecessor, "startLabel");
            itemValue = "";
            itemValue += "0|"; //.Order_Predecessor;
            itemValue += idWorkflowDetail == "-1" ? "" + "|" : idWorkflowDetail + "|"; //.Successor_Id_WODE
            itemValue += TPGlobal.initialWorkflowDetail + "|"; //.Predecessor_Id_WODE
            itemValue += "0|"; //.Lag_WDPR
            itemValue += "|"; //.LagUnit_Id_BRAN
            itemValue += "|"; //.DateField_Id_ADDA
            itemValue += "|"; //.ConditionalPath_WDPR
            itemValue += ""; //.TaskDescription
            let keyvalue: TPKeyValue = {
                key: itemValue,
                value: itemText,
            };
            newAvailableList.unshift(keyvalue);
        }
        let newWorkflowDetailPredecessorState = {
            ...workflowDetailPredecessorState,
        };
        if (newAvailableList && newAvailableList.length > 0) {
            newWorkflowDetailPredecessorState.selectedAvailableListValue =
                newAvailableList[0].key;
            setWorkflowDetailPredecessorState(newWorkflowDetailPredecessorState);    
            setAvailableListState(newAvailableList);
        }

        if (responsibleStructure.determinesResponsible) {
            if (responsibleStructure.idWorkflowDetail) {
                if (
                    responsibleStructure.idWorkflowDetail.toString() !==
                    idWorkflowDetail.toString()
                ) {
                    setThereIsDetemrineResponsible(true);
                }
            }
        }

        if (responsibleStructure.closeCustomer) {
            if (responsibleStructure.idWorkflowDetailCloseCustomer) {
                if (
                    responsibleStructure.idWorkflowDetailCloseCustomer.toString() !==
                    idWorkflowDetail.toString()
                ) {
                    setThereIsCloseCustomer(true);
                }
            }
        }

        setIsLoadingScreen(false);
    };

    const getWorkflowType = async () => {
        let serviceClient = new WorkflowTypeService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.getById(
                idWorkflowType,
                false,
                true,
                expectedCodes,
            );

            let workflowtype: WorkflowTypeViewModel;
            workflowtype = { ...responseRequest };

            setWorkflowTypeValueState(workflowtype);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getWorkflowType ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getWorkflowType ex`);
            setIsLoadingScreen(false);
        }
    };

    const getworkflowDetailByWorkflowType = async () => {
        let serviceClient = new WorkflowDetailService();
        let expectedCodes: Array<number> = [200, 404];

        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.getByWorkflowType(
                idWorkflowType,
                false,
                true,
                expectedCodes,
            );
            setIsLoadingScreen(false);
            return [...responseRequest];
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getworkflowDetailByWorkflowType ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(
                `Error ${componentFileName} getworkflowDetailByWorkflowType ex`,
            );
            setIsLoadingScreen(false);
            return [];
        }
    };

    const getWorkflowDetailPredecessors = async (pIdWorkflowDetail: string) => {
        let serviceClient = new WorkflowDetailPredecessorService();
        let expectedCodes: Array<number> = [200, 404];
        let i: number;
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getPredecessorByWorkflowDetail(
                pIdWorkflowDetail,
                false,
                true,
                expectedCodes,
            );
            let newWorkflowDetailPredecessorListState: Array<TPKeyValue> = [];
            workflowDetailPredecessorListData = responseRequest;
            for (i = 0; i <= workflowDetailPredecessorListData.length - 1; i++) {
                let keyvalue: TPKeyValue = {
                    key: workflowDetailPredecessorListData[i].predecessorId.toString(),
                    value: workflowDetailPredecessorListData[i].conditionalPath,
                };
                newWorkflowDetailPredecessorListState.push(keyvalue);
            }
            let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
            newWorkflowDetailPredecessorListState.unshift(intitialKeyValue);

            let newWorkflowDetailPredecessorState = {
                ...workflowDetailPredecessorState,
            };
            newWorkflowDetailPredecessorState.selectedAvailableListValue = "";
            setWorkflowDetailPredecessorState(newWorkflowDetailPredecessorState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getWorkflowDetailPredecessors ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(
                `Error ${componentFileName} getWorkflowDetailPredecessors ex`,
            );
            setIsLoadingScreen(false);
        }
    };

    const getworkflowDetailPotentialPredecessors = async (
        pIdWorkflowDetail: string,
    ) => {
        let serviceClient = new WorkflowDetailPredecessorService();
        let expectedCodes: Array<number> = [200, 404];
        let i: number;
        try {
            //123
            setIsLoadingScreen(true);
            let responseRequest =
                await serviceClient.getAvailablesByWorkflowDetailAndWorkflowType(
                    pIdWorkflowDetail,
                    idWorkflowType,
                    false,
                    true,
                    expectedCodes,
                );
            let newWorkflowDetailPredecessorListState: Array<TPKeyValue> = [];
            workflowDetailPotentialPredecessorsListData = responseRequest;           
            let itemText: string;
            let itemValue: string;
            for (
                i = 0;
                i <= workflowDetailPotentialPredecessorsListData.length - 1;
                i++
            ) {
                //TODO revisar el formato

                itemText = workflowDetailPotentialPredecessorsListData[i].order
                    .toString()
                    .replace(",", ".");
                itemText +=
                    "-" +
                    workflowDetailPotentialPredecessorsListData[i]
                        .taskTypeLocalizedDescription;
                if (workflowDetailPotentialPredecessorsListData[i].conditionalPath) {
                    if (
                        workflowDetailPotentialPredecessorsListData[
                            i
                        ].conditionalPath.toUpperCase() === "Y"
                    ) {
                        itemText +=
                            "-" +
                            (await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveYes"));
                    } else if (
                        workflowDetailPotentialPredecessorsListData[
                            i
                        ].conditionalPath.toUpperCase() === "N"
                    ) {
                        itemText +=
                            "-" +
                            (await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveNo"));
                    } else {
                        itemText += "-NA";
                    }
                }
                itemValue = "";
                itemValue += workflowDetailPotentialPredecessorsListData[i].order + "|";
                itemValue +=
                    workflowDetailPotentialPredecessorsListData[i].successorId + "|";
                itemValue +=
                    workflowDetailPotentialPredecessorsListData[i].predecessorId + "|";
                itemValue +=
                    workflowDetailPotentialPredecessorsListData[i].lag?.toString() + "|";
                itemValue +=
                    workflowDetailPotentialPredecessorsListData[i].lagUnit + "|";
                itemValue +=
                    workflowDetailPotentialPredecessorsListData[i].dateField + "|";
                itemValue +=
                    workflowDetailPotentialPredecessorsListData[i].conditionalPath + "|";
                itemValue +=
                    workflowDetailPotentialPredecessorsListData[i]
                        .taskTypeLocalizedDescription;
                let keyvalue: TPKeyValue = {
                    key: itemValue,
                    value: itemText,
                };
                newWorkflowDetailPredecessorListState.push(keyvalue);
            }
            setAvailableListState(newWorkflowDetailPredecessorListState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getWorkflowDetailPredecessors ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(
                `Error ${componentFileName} getWorkflowDetailPredecessors ex`,
            );
            setIsLoadingScreen(false);
        }
    };

    const getAdditionalDataList = async () => {
        let serviceClient = new AdditionalDataService();
        let expectedCodes: Array<number> = [200, 404];
        let i: number;
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getByTypeId(
                TPGlobal.AdditionalDataTypes.DATE,
                false,
                true,
                expectedCodes,
            );
            let newAdditionalDataListState: Array<TPKeyValue> = [];

            let additionalDataListData: Array<AdditionalDataViewModel>;
            additionalDataListData = responseRequest;
            for (i = 0; i <= additionalDataListData.length - 1; i++) {
                let keyvalue: TPKeyValue = {
                    key: additionalDataListData[i].id,
                    value: additionalDataListData[i].localizedDescription,
                };
                newAdditionalDataListState.push(keyvalue);
            }
            let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
            newAdditionalDataListState.unshift(intitialKeyValue);

            setAdditionalDataListState(newAdditionalDataListState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getAdditionalDataList ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getAdditionalDataList ex`);
            setIsLoadingScreen(false);
        }
    };

    const getLagUnitList = async () => {
        let newLagUnitListState = [...lagUnitList];
        newLagUnitListState.push({
            key: "MI",
            value: await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "MinutesItemLabel",
            ),
        });
        newLagUnitListState.push({
            key: "h",
            value: await TPI18N.GetText(TPGlobal.globalResourceSet, "HoursItemLabel"),
        });
        newLagUnitListState.push({
            key: "d",
            value: await TPI18N.GetText(TPGlobal.globalResourceSet, "DaysItemLabel"),
        });
        newLagUnitListState.push({
            key: "w",
            value: await TPI18N.GetText(TPGlobal.globalResourceSet, "WeeksItemLabel"),
        });
        newLagUnitListState.push({
            key: "M",
            value: await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "MonthsItemLabel",
            ),
        });
        let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
        newLagUnitListState.unshift(intitialKeyValue);
        setLagUnitListState(newLagUnitListState);
    };

    const GetWriterSelected = () => {
        if (selectedWriter?.length > 0) {
            return selectedWriter[0].key;
        }
        return "";
    };

    const GetReaderSelected = () => {
        if (selectedReader?.length > 0) {
            return selectedReader[0].key;
        }
        return "";
    };

    const GetTaskTypeSelected = () => {
        if (selectedTaskType?.length > 0) {
            return selectedTaskType[0].key;
        }
        return "";
    };

    const GetTaskTypeRecordById = async (idTaskType: string) => {
        let taskType: Array<TPKeyValue> = [];
        if (idTaskType === null || idTaskType.trim() === "") return taskType;

        let serviceClient = new TaskTypeService();
        let expectedCodes: Array<number> = [200];

        try {
            let responseRequest = await serviceClient.getTaskTypeById(
                idTaskType,
                false,
                true,
                expectedCodes,
            );

            let recordInfo: TaskTypeViewModel;
            recordInfo = { ...responseRequest };

            let keyvalue: TPKeyValue = {
                key: recordInfo.id,
                value: recordInfo.localizedDescription,
            };
            taskType.push(keyvalue);

            return taskType;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} GetTaskTypeRecordById ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} GetTaskTypeRecordById ex`);
            return [];
        }
    };

    const GetFunctionRecordById = async (idFuction: string) => {
        let functionRecord: Array<TPKeyValue> = [];
        if (idFuction === null || idFuction.trim() === "") return functionRecord;

        let serviceClient = new FunctionsService();
        let expectedCodes: Array<number> = [200];

        try {
            let responseRequest = await serviceClient.getFunctionById(
                idFuction,
                false,
                true,
                expectedCodes,
            );

            let recordInfo: FunctionsViewModel;
            recordInfo = { ...responseRequest };

            let keyvalue: TPKeyValue = {
                key: recordInfo.id,
                value: recordInfo.name,
            };
            functionRecord.push(keyvalue);

            return functionRecord;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} GetFunctionRecordById ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} GetFunctionRecordById ex`);
            return [];
        }
    };

    const getWorkflowDetailById = async (recordId: string) => {
        let serviceClient = new WorkflowDetailService();
        let expectedCodes: Array<number> = [200];
        let i: number;
        let j: number;
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.getById(
                recordId,
                false,
                true,
                expectedCodes,
            );

            let recordInfo: WorkflowDetailViewModel;
            recordInfo = { ...responseRequest };
            let newInsertUpdateState = { ...insertUpdateState };
            newInsertUpdateState.order = recordInfo.order;
            setSelectedTaskType(await GetTaskTypeRecordById(recordInfo.taskTypeId));
            newInsertUpdateState.term = recordInfo.term;
            setSelectedTermUnit(recordInfo.termUnit);
            setSelectedWriter(await GetFunctionRecordById(recordInfo.writerId));
            setSelectedReader(await GetFunctionRecordById(recordInfo.readerId));
            newInsertUpdateState.definesResponsible = recordInfo.definesResponsible;
            newInsertUpdateState.closesCustomer = recordInfo.closesCustomer;
            newInsertUpdateState.requiresAllPredecessors =
                recordInfo.requiresAllPredecessors;
            newInsertUpdateState.isActive = recordInfo.isActive;

            const workflowDetails = await getworkflowDetailByWorkflowType();
            if (workflowDetails && workflowDetails.length > 0) {
                for (let i = 0; i < workflowDetails.length; i++) {
                    const element = workflowDetails[i];
                    if (element.closesCustomer && element.id !== +idWorkflowDetail) {
                        newInsertUpdateState.closesCustomer = false;
                    }
                    if (element.definesResponsible && element.id !== +idWorkflowDetail) {
                        newInsertUpdateState.definesResponsible = false;
                    }
                }

                // if (newInsertUpdateState.closesCustomer) {
                //   newInsertUpdateState.closesCustomerDisabled = true;
                // }

                setInsertUpdateState(newInsertUpdateState);
            }
            setInsertUpdateState(newInsertUpdateState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getWorkflowDetailById ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getWorkflowDetailById ex`);
            setIsLoadingScreen(false);
        }
    };
    const getWorkflowDetailReminderById = async (recordId: string) => {
        let serviceClient = new WorkflowDetailReminderService();
        let expectedCodes: Array<number> = [200, 404];
        let i: number;
        let j: number;
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.getById(
                recordId,
                false,
                true,
                expectedCodes,
            );

            let recordInfo: WorkflowDetailReminderViewModel;
            recordInfo = { ...responseRequest };

            let newReminderState = { ...reminderState };
            newReminderState.reminderTerm = recordInfo.term;
            newReminderState.reminderTermUnit = recordInfo.reminderTermUnitIdBranch;
            setReminderState(newReminderState);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getWorkflowDetailReminderById ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(
                `Error ${componentFileName} getWorkflowDetailReminderById ex`,
            );
            setIsLoadingScreen(false);
        }
    };
    const getTaskTypeList = async () => {
        let serviceClient = new TaskTypeService();
        let expectedCodes: Array<number> = [200];
        let i: number;
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getTaskTypeByFilter(
                TPActiveOptions.ACTIVE.toString(),
                false,
                true,
                expectedCodes,
            );
            let newTaskTypeListState: Array<TPKeyValue> = [];

            let taskTypeListData: Array<TaskTypeViewModel>;
            taskTypeListData = responseRequest;
            for (i = 0; i <= taskTypeListData.length - 1; i++) {
                let keyvalue: TPKeyValue = {
                    key: taskTypeListData[i].id,
                    value: taskTypeListData[i].localizedDescription,
                    value2: taskTypeListData[i].componentId,
                };
                newTaskTypeListState.push(keyvalue);
            }
            let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
            newTaskTypeListState.unshift(intitialKeyValue);

            setSelectedTaskType([]);
            setTaskTypeListState(newTaskTypeListState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getTaskTypeList ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getTaskTypeList ex`);
            setIsLoadingScreen(false);
        }
    };

    const getTermUnitList = async () => {
        let parametersService = new ParametersService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest =
                await parametersService.getByParentIdAndFilterIsActive(
                    SystemParametersEnum.TEUNI,
                    TPActiveOptions.ACTIVE.toString(),
                    false,
                    true,
                    expectedCodes,
                );

            let newTypeKeyValueList: Array<TPKeyValue> = responseRequest.map(
                function (item) {
                    return {
                        key: item.id,
                        value: item.localizedDescription,
                    };
                },
            );
            newTypeKeyValueList.unshift({ key: "", value: "--" });
            setTermUnitListState(newTypeKeyValueList);
            setSelectedTermUnit("");
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getUnitTermFromParameters ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getUnitTermFromParameters ex`);
            return;
        }
    };

    const getWriterList = async () => {
        let serviceClient = new FunctionsService();
        let expectedCodes: Array<number> = [200];
        let i: number;
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getFunctionsByFilter(
                TPActiveOptions.ACTIVE.toString(),
                false,
                true,
                expectedCodes,
            );
            let newFunctionsListState: Array<TPKeyValue> = [];

            let functionsListData: Array<FunctionsViewModel>;
            functionsListData = responseRequest;
            for (i = 0; i <= functionsListData.length - 1; i++) {
                let keyvalue: TPKeyValue = {
                    key: functionsListData[i].id,
                    value: functionsListData[i].localizedDescription,
                };
                newFunctionsListState.push(keyvalue);
            }
            let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
            newFunctionsListState.unshift(intitialKeyValue);

            setSelectedWriter([]);
            setWriterListState(newFunctionsListState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getWriterList ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getWriterList ex`);
            setIsLoadingScreen(false);
        }
    };

    const SearchQueryAutocomplete = (query: string, listValues: TPKeyValue[]) => {
        let newKeyValueList: Array<TPKeyValue>;

        query = query.toLowerCase();

        newKeyValueList = listValues.filter((x) =>
            `${x.value}`.toLowerCase().includes(query),
        );

        return newKeyValueList;
    };

    const IsValidOnKeyDown = (event: any) => {
        return (
            event.keyCode != 9 && //tab
            event.keyCode != 37 && //left arrow
            event.keyCode != 38 && //down arrow
            event.keyCode != 39 && //right arrow
            event.keyCode != 40 && //up arrow
            event.keyCode != 13 && //enter
            event.keyCode != 35 && //home
            event.keyCode != 36 //end
        );
    };

    const handleTaskTypeOnAutocompleteQuery = async (query: string) => {
        let newKeyValueList: Array<TPKeyValue>;
        newKeyValueList = SearchQueryAutocomplete(query, tasktypeList);

        setAutocompleteTaskTypeOptions(newKeyValueList);
        return newKeyValueList;
    };

    const handleAutoCompleteTopNTaskTypeText = (text: string) => {
        if (!text) {
            setAutocompleteTaskTypeOptions(tasktypeList);
            handleTaskTypeChange([]);
        }
    };

    const handleTaskTypeOnAutocompleteKeyDown = (event: any) => {
        // if (IsValidOnKeyDown(event)) {
        //   setAutocompleteTaskTypeOptions([]);
        //   handleTaskTypeChange([])
        // }
    };

    const handleAutoCompleteTopNTaskTypeClick = async () => {
        try {
            let newTopNOptions: Array<TPKeyValue> = [];
            if (autocompleteTaskTypeTopNOptions.length === 0) {
                newTopNOptions = await handleTaskTypeOnAutocompleteQuery("");

                if (newTopNOptions.length >= 1) {
                    //save on cache
                    setAutocompleteTaskTypeTopNOptions([...newTopNOptions]);
                    setAutocompleteTaskTypeOptions([...newTopNOptions]);
                    handleTaskTypeChange([]);
                }
            } else {
                //use cached values;
                setAutocompleteTaskTypeTopNOptions([
                    ...autocompleteTaskTypeTopNOptions,
                ]);
                handleTaskTypeChange([]);
            }
        } catch (error) {
            console.error(`Error handleAutoCompleteTopNTaskTypeClick =>`, error);
        }
    };

    const handleReaderOnAutocompleteQuery = async (query: string) => {
        let newKeyValueList: Array<TPKeyValue>;
        newKeyValueList = SearchQueryAutocomplete(query, writerList);

        setAutocompleteReaderOptions(newKeyValueList);
        return newKeyValueList;
    };

    const handleReaderOnAutocompleteKeyDown = (event: any) => {
        if (IsValidOnKeyDown(event)) {
            setAutocompleteReaderOptions([]);
            handleReaderChange([]);
        }
    };

    const handleAutoCompleteTopNReaderClick = async () => {
        let newTopNOptions: Array<TPKeyValue> = [];
        if (autocompleteReaderTopNOptions.length === 0) {
            newTopNOptions = await handleReaderOnAutocompleteQuery("");

            if (newTopNOptions.length >= 1) {
                //save on cache
                setAutocompleteReaderTopNOptions([...newTopNOptions]);
                setAutocompleteReaderOptions([...newTopNOptions]);
                handleReaderChange([]);
            }
        } else {
            //use cached values;
            setAutocompleteReaderTopNOptions([...autocompleteReaderTopNOptions]);
            handleReaderChange([]);
        }
    };

    const handleWriterOnAutocompleteQuery = async (query: string) => {
        let newKeyValueList: Array<TPKeyValue>;
        newKeyValueList = SearchQueryAutocomplete(query, writerList);

        setAutocompleteWriterOptions(newKeyValueList);
        return newKeyValueList;
    };

    const handleWriterOnAutocompleteKeyDown = (event: any) => {
        if (IsValidOnKeyDown(event)) {
            setAutocompleteWriterOptions([]);
            handleWriterChange([]);
        }
    };

    const handleAutoCompleteTopNWriterClick = async () => {
        let newTopNOptions: Array<TPKeyValue> = [];
        if (autocompleteWriterTopNOptions.length === 0) {
            newTopNOptions = await handleWriterOnAutocompleteQuery("");

            if (newTopNOptions.length >= 1) {
                //save on cache
                setAutocompleteWriterTopNOptions([...newTopNOptions]);
                setAutocompleteWriterOptions([...newTopNOptions]);
                handleWriterChange([]);
            }
        } else {
            //use cached values;
            setAutocompleteWriterTopNOptions([...autocompleteWriterTopNOptions]);
            handleWriterChange([]);
        }
    };

    const handleModifyAssignments_OnClick = async (type: string) => {
        let itemText: string = "";
        let itemValue: string;
        let selectedValue: Array<string>;
        let keyvalue: TPKeyValue;
        let lagErrorMessage: string;
        let lagUnitErrorMessage: string;
        let dateBasedOnErrorMessage: string;

        switch (type) {
            case "insertone":
                if (!insertUpdateState.order) {
                    setGeneralErrorMessage(selectOrder);
                    return;
                }
                if (
                    workflowDetailPredecessorState.selectedAvailableListValue.trim() ===
                    ""
                ) {
                    setGeneralErrorMessage(selectExisting);
                    return;
                }

                lagErrorMessage = "";
                lagUnitErrorMessage = "";
                dateBasedOnErrorMessage = "";
                if (modeLagSelected !== "withoutLag") {
                    switch (modeLagSelected) {
                        case "withLag":
                            if (!workflowDetailPredecessorState.lag) {
                                lagErrorMessage = await TPI18N.GetText(
                                    resourceSet,
                                    "starLagInputDTOEmpty",
                                );
                            } else {
                                if (workflowDetailPredecessorState.lag <= 0) {
                                    lagErrorMessage = await TPI18N.GetText(
                                        resourceSet,
                                        "InputDTOInvalidLag",
                                    );
                                }
                                if (workflowDetailPredecessorState.lagUnit === "") {
                                    lagUnitErrorMessage = selectLagUnits;
                                }
                                if (
                                    workflowDetailPredecessorState.lagUnit.toUpperCase() === "MI"
                                ) {
                                    if (+workflowDetailPredecessorState.lag < 15) {
                                        lagErrorMessage = errorMinuteTerm;
                                    }
                                }
                            }
                            break;
                        case "withDate":
                            if (workflowDetailPredecessorState.dateField == "") {
                                dateBasedOnErrorMessage = await TPI18N.GetText(
                                    resourceSet,
                                    "SelectLagOrDate",
                                );
                            }
                            break;
                        default:
                            break;
                    }

                    setWorkflowDetailPredecessorState({
                        ...workflowDetailPredecessorState,
                        lagErrorMessage: lagErrorMessage,
                        lagUnitErrorMessage: lagUnitErrorMessage,
                        dateBasedOnErrorMessage: dateBasedOnErrorMessage,
                    });

                    if (
                        lagErrorMessage !== "" ||
                        lagUnitErrorMessage !== "" ||
                        dateBasedOnErrorMessage !== ""
                    )
                        return;

                    // if (
                    //   workflowDetailPredecessorState.lag !== "" &&
                    //   +workflowDetailPredecessorState.lag > 0
                    // ) {
                    //   if (workflowDetailPredecessorState.lagUnit === "") {
                    //     setGeneralErrorMessage(selectLagUnits);
                    //     return;
                    //   }
                    //   if (workflowDetailPredecessorState.lagUnit.toUpperCase() === "MI") {
                    //     if (+workflowDetailPredecessorState.lag < 15) {
                    //       setGeneralErrorMessage(errorMinuteTerm);
                    //       return;
                    //     }
                    //   }
                    // } else {
                    //   if (workflowDetailPredecessorState.dateField == "") {
                    //     setGeneralErrorMessage(selectLatOrDate);
                    //     return;
                    //   }
                    // }
                }

                selectedValue =
                    workflowDetailPredecessorState.selectedAvailableListValue.split("|");
                if (+selectedValue[0] >= insertUpdateState.order) {
                    const result = await getTaskTypeByWorkflowId(selectedValue[2]);
                    if (!result) {
                        setGeneralErrorMessage(noDecisionRetryTask);
                        return;
                    }
                }
                if (selectedValue[2] === TPGlobal.initialWorkflowDetail.toString()) {
                    if (
                        (workflowDetailPredecessorState?.lag ?? 0) <= 0 ||
                        allowStart === "0"
                    ) {
                        setGeneralErrorMessage(startNotAllowed);
                        return;
                    }
                    itemText = startLabel;
                    allowAdd = "0";
                } else {
                    if (allowAdd == "0") {
                        setGeneralErrorMessage(addNotAllowed);
                        return;
                    }
                    itemText = selectedValue[0].toString().replace(",", ".");
                    itemText += "-" + selectedValue[7];
                    setAllowStart("0");
                }
                if (selectedValue[6].trim()) {
                    if (selectedValue[6].toUpperCase() === "Y") {
                        itemText += "-" + activeYesLabel;
                    } else {
                        itemText += "-" + activeNoLabel;
                    }
                } else {
                    itemText += "-" + "NA";
                }
                if (workflowDetailPredecessorState.dateField != "") {
                    //datebasedon
                    itemText += "-" + workflowDetailPredecessorState.dateField;
                } else {
                    itemText += workflowDetailPredecessorState.lag
                        ? "-" +
                        workflowDetailPredecessorState.lag +
                        workflowDetailPredecessorState.lagUnit
                        : "";
                }
                itemValue = "";
                itemValue += selectedValue[0] + "|";
                itemValue += idWorkflowDetail == "-1" ? "|" : idWorkflowDetail + "|";
                itemValue += selectedValue[2] + "|";
                itemValue += workflowDetailPredecessorState.lag + "|";
                itemValue += workflowDetailPredecessorState.lagUnit.toUpperCase() + "|";
                itemValue += workflowDetailPredecessorState.dateField + "|";
                itemValue += (selectedValue[6] != null ? selectedValue[6] : "") + "|";
                itemValue += selectedValue[7] != null ? selectedValue[7] : "";

                keyvalue = {
                    key: itemValue,
                    value: itemText,
                };
                let newSelectedList1 = [...selectedList];
                newSelectedList1.push(keyvalue);
                setselectedListState(newSelectedList1);

                // Removing the specified element by value from the array
                for (let i: number = 0; i < availableList.length; i++) {
                    if (
                        availableList[i].key ===
                        workflowDetailPredecessorState.selectedAvailableListValue
                    ) {
                        let newAvailableList1 = [...availableList];
                        newAvailableList1.splice(i, 1);
                        setAvailableListState(newAvailableList1);

                        break;
                    }
                }
                let newWorkflowDetailPredecessorState = {
                    ...workflowDetailPredecessorState,
                };
                // newWorkflowDetailPredecessorState.lag = "";
                // newWorkflowDetailPredecessorState.lagUnit = "";
                // newWorkflowDetailPredecessorState.dateField = "";
                newWorkflowDetailPredecessorState.selectedAvailableListValue = "";
                newWorkflowDetailPredecessorState.selectedSelectedListValue =
                    newSelectedList1[0].key;
                setWorkflowDetailPredecessorState(newWorkflowDetailPredecessorState);

                //Check order
                if (+selectedValue[0] >= insertUpdateState.order) {
                    let msgReopenLabel = reopenWorkflowLabel.replaceAll(
                        "{0}",
                        selectedValue[0],
                    );
                    let newModalSuccessState: TPModalSuccessStateType;
                    newModalSuccessState = {
                        callBackFunction: callBackModalSuccess,
                        title: "", //await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalSuccessTitle"),
                        yesLabel: await TPI18N.GetText(
                            TPGlobal.globalResourceSet,
                            "ModalAcceptLabel",
                        ),
                        successMessage: msgReopenLabel,
                        isShown: true,
                    };
                    setModalSuccessState(newModalSuccessState);
                }
                break;
            case "deallocateone":
                var selectedValalueKey: string;
                if (selectedList.length == 1) {
                    selectedValalueKey = selectedList[0].key;
                    selectedValue = selectedList[0].key.split("|");
                } else {
                    selectedValalueKey =
                        workflowDetailPredecessorState.selectedSelectedListValue;
                    selectedValue =
                        workflowDetailPredecessorState.selectedSelectedListValue.split("|");
                }

                if (selectedValue[2] === TPGlobal.initialWorkflowDetail.toString()) {
                    itemText = startLabel;
                    allowAdd = "1";
                } else {
                    itemText = selectedValue[0].replaceAll(",", ".");
                    itemText += "-" + selectedValue[7]; //TaskDescription
                }
                if (selectedValue[6].trim() !== "") {
                    if (selectedValue[6].toUpperCase() === "Y") {
                        itemText += "-" + activeYesLabel;
                    } else {
                        itemText += "-" + activeNoLabel;
                    }
                } else {
                    itemText += "-NA";
                }
                itemValue = "";
                itemValue += selectedValue[0] + "|";
                itemValue += "|";
                itemValue += selectedValue[2] + "|";
                itemValue += "|";
                itemValue += "|";
                itemValue += "|";
                itemValue += (selectedValue[6] != null ? selectedValue[6] : "") + "|";
                itemValue += selectedValue[7] != null ? selectedValue[7] : "";

                keyvalue = {
                    key: itemValue,
                    value: itemText,
                };

                let newAvailableList2 = [...availableList];
                newAvailableList2.push(keyvalue);
                setAvailableListState(newAvailableList2);

                // Removing the specified element by value from the array
                for (let i: number = 0; i < selectedList.length; i++) {
                    if (selectedList[i].key === selectedValalueKey) {
                        let newSelectedList2 = [...selectedList];
                        newSelectedList2.splice(i, 1);
                        setselectedListState(newSelectedList2);
                        break;
                    }
                }
                if (selectedList.length == 0) {
                    setAllowStart("1");
                }

                let newWorkflowDetailPredecessorState_ = {
                    ...workflowDetailPredecessorState,
                };
                // newWorkflowDetailPredecessorState_.lag = "";
                // newWorkflowDetailPredecessorState_.lagUnit = "";
                // newWorkflowDetailPredecessorState_.dateField = "";

                if (selectedList.length == 0) {
                    newWorkflowDetailPredecessorState_.selectedSelectedListValue = "";
                } else {
                    newWorkflowDetailPredecessorState_.selectedSelectedListValue =
                        selectedList[0].key;
                }
                newWorkflowDetailPredecessorState_.selectedAvailableListValue =
                    newAvailableList2[0].key;
                setWorkflowDetailPredecessorState(newWorkflowDetailPredecessorState_);
                setModeLagSelected(modeLagSelected);
                break;
        }
        setGeneralErrorMessage("");
    };

    //called after close modal success
    const callBackModalSuccess = () => {
        //hide modal and reset state
        //preserve callback function, title,and button level
        let newModalSuccessState: TPModalSuccessStateType = {
            ...modalSuccessState,
        };
        newModalSuccessState.successMessage = "";
        newModalSuccessState.isShown = false;
        setModalSuccessState(newModalSuccessState);
    };

    const getTaskTypeByWorkflowId = async (
        workflowDetailId: string,
    ): Promise<boolean> => {
        let serviceClient = new TaskTypeService();
        let expectedCodes: Array<number> = [200, 404];
        let i: number;
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getTaskTypeByWorkflowId(
                workflowDetailId,
                false,
                true,
                expectedCodes,
            );

            let taskTypeListData: Array<TaskTypeViewModel>;
            taskTypeListData = responseRequest;
            if (
                taskTypeListData[0].componentId.trim().toUpperCase() !==
                TPGlobal.TaskTypeComponent.CaseResponseDecisionTaskComponent.trim().toUpperCase() &&
                taskTypeListData[0].componentId.trim().toUpperCase() !==
                TPGlobal.TaskTypeComponent.CaseResponseDecisionRetryTaskComponent.trim().toUpperCase()
            ) {
                setIsLoadingScreen(false);
                return false;
            }

            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getTaskTypeList ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getTaskTypeList ex`);
            setIsLoadingScreen(false);
        }
        return true;
    };

    //Handlers
    const handleOnAdditionalDataChange = (e: any, keyValue: TPKeyValue) => {
        let newWorkflowDetailPredecessorState = {
            ...workflowDetailPredecessorState,
        };
        newWorkflowDetailPredecessorState.dateField = e.target.value;
        if (newWorkflowDetailPredecessorState.dateField !== "") {
            newWorkflowDetailPredecessorState.lag = null;
            newWorkflowDetailPredecessorState.lagUnit = "d";
        }
        newWorkflowDetailPredecessorState.dateBasedOnErrorMessage = "";
        setWorkflowDetailPredecessorState(newWorkflowDetailPredecessorState);
        setGeneralErrorMessage("");
    };

    const handleOnLagUnitChange = (e: any, keyValue: TPKeyValue) => {
        let newWorkflowDetailPredecessorState = {
            ...workflowDetailPredecessorState,
        };
        newWorkflowDetailPredecessorState.lagUnit = e.target.value;
        if (!e.target.value || e.target.value !== "") {
            newWorkflowDetailPredecessorState.dateField = "";
        }
        setWorkflowDetailPredecessorState(newWorkflowDetailPredecessorState);
        setGeneralErrorMessage("");
    };

    const handleOnLagChange = (newLag?: number) => {
        let newWorkflowDetailPredecessorState = {
            ...workflowDetailPredecessorState,
        };
        newWorkflowDetailPredecessorState.lag = newLag ? newLag : null;
        if (!newLag) {
            newWorkflowDetailPredecessorState.dateField = "";
        }
        newWorkflowDetailPredecessorState.lagErrorMessage = "";
        setWorkflowDetailPredecessorState(newWorkflowDetailPredecessorState);
        setGeneralErrorMessage("");
    };

    const handleOnOrderChange = (newOrder?: number) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.order = newOrder ? newOrder : null;
        newInsertUpdateState.orderErrorMessage = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleTaskTypeChange = (newSelectedValue: Array<TPKeyValue>) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.taskTypeIdErrorMessage = "";

        if (newSelectedValue && newSelectedValue[0] && newSelectedValue[0].key) {

            let system = writerList.find(s => s.key == TPGlobal.UserSystem.SYSTEM);

            let taskType = autocompleteTaskTypeOptions.find(s => s.key == newSelectedValue[0].key);

            let isSystem = false;
            if (taskType && taskType.value2 == TPGlobal.TaskTypeComponent.CaseResponseAnswerAutoTaskComponent) isSystem = true;
            if (taskType && taskType.value2 == TPGlobal.TaskTypeComponent.CaseResponseDecisionAutoTaskComponent) isSystem = true;
            if (taskType && taskType.value2 == TPGlobal.TaskTypeComponent.CaseResponseEmailAutoTaskComponent) isSystem = true;

            if (system && isSystem) {
                setSelectedWriter([system]);
            }
        } else {
            setSelectedWriter([]);
        }
        setInsertUpdateState(newInsertUpdateState);
        setSelectedTaskType(newSelectedValue);

    };

    const handleOnTermChange = (newTerm?: number) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.term = newTerm ? newTerm : null;
        newInsertUpdateState.termErrorMessage = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleOnTermUnitChange = (e: any, keyValue: TPKeyValue) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.termUnitErrorMessage = "";
        setInsertUpdateState(newInsertUpdateState);
        setSelectedTermUnit(e.target.value);
    };

    const handleWriterChange = (newSelectedValue: Array<TPKeyValue>) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.writerErrorMessage = "";
        setInsertUpdateState(newInsertUpdateState);
        setSelectedWriter(newSelectedValue);
    };

    const handleReaderChange = (newSelectedValue: Array<TPKeyValue>) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.writerErrorMessage = "";
        setInsertUpdateState(newInsertUpdateState);
        setSelectedReader(newSelectedValue);
    };

    const handleDefinesResponsibleChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.definesResponsible =
            !insertUpdateState.definesResponsible;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleClosesCustomerChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.closesCustomer = !insertUpdateState.closesCustomer;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleRequiresAllPredecessorsChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.requiresAllPredecessors =
            !insertUpdateState.requiresAllPredecessors;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleIsActiveChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.isActive = !insertUpdateState.isActive;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleCancelButtonClick = (e: any) => {
        onCancelClick(e);
    };

    const handleOkButtonClick = async (e: any) => {
        let recordInputDTO: WorkflowDetailInputDTO = {
            id: mode === "Insert" ? 0 : +idWorkflowDetail,
            workflowTypeId: idWorkflowType,
            writerId: GetWriterSelected(),
            readerId: GetReaderSelected(),
            taskTypeId: GetTaskTypeSelected(),
            term: insertUpdateState.term ? +insertUpdateState.term : null,
            termUnit: selectedTermUnit?.toUpperCase(),
            order: insertUpdateState.order ? +insertUpdateState.order : null,
            isActive: insertUpdateState.isActive,
            closesCustomer: insertUpdateState.closesCustomer,
            definesResponsible: insertUpdateState.definesResponsible,
            requiresAllPredecessors: insertUpdateState.requiresAllPredecessors,
        };

        //1.Save Detail
        //2.Save Predecesores
        //3.Save Reminders

        let inputDTOValidator = new WorkflowDetailInputDTOValidator();
        let resultValidator = inputDTOValidator.validate(recordInputDTO);
        if (!TPGlobal.TPIsEmpty(resultValidator)) {
            let newInsertUpdateState = { ...insertUpdateState };
            if (resultValidator.order) {
                newInsertUpdateState.orderErrorMessage = await TPI18N.GetResource(
                    resultValidator.order,
                );
            } else {
                newInsertUpdateState.orderErrorMessage = "";
            }
            if (resultValidator.writerId) {
                newInsertUpdateState.writerErrorMessage = await TPI18N.GetResource(
                    resultValidator.writerId,
                );
            } else {
                newInsertUpdateState.writerErrorMessage = "";
            }
            if (resultValidator.readerId) {
                newInsertUpdateState.readerErrorMessage = await TPI18N.GetResource(
                    resultValidator.readerId,
                );
            } else {
                newInsertUpdateState.readerErrorMessage = "";
            }
            if (resultValidator.taskTypeId) {
                newInsertUpdateState.taskTypeIdErrorMessage = await TPI18N.GetResource(
                    resultValidator.taskTypeId,
                );
            } else {
                newInsertUpdateState.taskTypeIdErrorMessage = "";
            }
            if (resultValidator.term) {
                newInsertUpdateState.termErrorMessage = await TPI18N.GetResource(
                    resultValidator.term,
                );
            } else {
                newInsertUpdateState.termErrorMessage = "";
            }
            if (resultValidator.termUnit) {
                newInsertUpdateState.termUnitErrorMessage = await TPI18N.GetResource(
                    resultValidator.termUnit,
                );
            } else {
                newInsertUpdateState.termUnitErrorMessage = "";
            }
            setInsertUpdateState(newInsertUpdateState);
            return;
        }
        if (mode == "Insert") {
            await insertWorkflowDetail(recordInputDTO);

            //TODO: Preguntar a quien corresponda:
            //bR = USerTRackingManager.CompareObjectsForTracking(objBeforeInfo, objAfterInfo, jsonCompare)
        } else {
            await updateWorkflowDetail(recordInputDTO);
        }
        //Save Predecessors
        if (!(await preSavePredecessors())) {
            return;
        }

        //Delete if exist Reminders
        if (!reminderState.reminderTerm || reminderState.reminderTerm == 0) {
            await deleteReminders();
        } else {
            if (
                reminderState.reminderTermUnit == "MI" &&
                reminderState.reminderTerm < 15
            ) {
                let newReminderState = { ...reminderState };
                newReminderState.termReminderErrorMessage = await TPI18N.GetText(
                    resourceSetPredecessor,
                    "ErrorMinuteTerm",
                );
                setReminderState(newReminderState);
                return;
            }
            let recordInputReminderDTO: WorkflowDetailReminderInputDTO = {
                id: +idWorkflowDetail,
                term: reminderState.reminderTerm,
                reminderTermUnitIdBranch: reminderState.reminderTermUnit,
            };
            let inputDTOReminderValidator =
                new WorkflowDetailReminderInputDTOValidator(reminderState.reminderTerm);
            let resultValidatorReminder = inputDTOReminderValidator.validate(
                recordInputReminderDTO,
            );
            if (!TPGlobal.TPIsEmpty(resultValidatorReminder)) {
                let newInsertUpdateState = { ...reminderState };
                if (resultValidatorReminder.reminderTermUnitIdBranch) {
                    newInsertUpdateState.termReminderUnitErrorMessage =
                        await TPI18N.GetResource(
                            resultValidatorReminder.reminderTermUnitIdBranch,
                        );
                } else {
                    newInsertUpdateState.termReminderUnitErrorMessage = "";
                }
                setReminderState(newInsertUpdateState);
                return;
            }
            await insertWorkflowDetailReminder(recordInputReminderDTO);
        }
        onSaveClick(e);
    };
    const preSavePredecessors = async (): Promise<boolean> => {
        var selectedValue: Array<string> = [];
        let recordInputPredecessorMultipleDTO: WorkflowDetailPredecessorMultipleInputDTO =
        {
            workflowDetailId: idWorkflowDetail,
            workflowDetailPredecessorList: [],
        };
        for (let index = 0; index < selectedList.length; index++) {
            const element = selectedList[index];
            let reopen_WDPR = false;
            //    0                   1                   2                3           4               5                 6              7
            //Order_Predecessor|Successor_Id_WODE|Predecessor_Id_WODE|Lag_WDPR|LagUnit_Id_BRAN|DateField_Id_ADDA|ConditionalPath_WDPR|TaskDescription
            selectedValue = element.key.split("|");

            if (selectedValue[1] == "-1" || selectedValue[1] == "") {
                //Sucessor = idworkflowDetail
                selectedValue[1] = idWorkflowDetail;
            }
            let realOrder: number = insertUpdateState.order
                ? insertUpdateState.order
                : 0;
            if (+selectedValue[0] >= realOrder) {
                reopen_WDPR = true;
            }
            let onePredesessor: WorkflowDetailPredecessorInputDTO = {
                successorId: +selectedValue[1],
                predecessorId: +selectedValue[2],
                lag: +selectedValue[3] || null,
                lagUnit: +selectedValue[3] ? selectedValue[4]?.toUpperCase() : null,
                dateField: selectedValue[5],
                conditionalPath: selectedValue[6],
                reopen: reopen_WDPR,
            };
            recordInputPredecessorMultipleDTO.workflowDetailPredecessorList.push(
                onePredesessor,
            );
        }
        let inputDTOValidatorPredecessor =
            new WorkflowDetailPredecessorMultipleInputDTOValidator();
        let resultValidatorPredecessor = inputDTOValidatorPredecessor.validate(
            recordInputPredecessorMultipleDTO,
        );
        if (!TPGlobal.TPIsEmpty(resultValidatorPredecessor)) {
            //TODO

            if (resultValidatorPredecessor.workflowDetailPredecessorList) {
                setGeneralErrorMessage(
                    await TPI18N.GetResource(
                        (
                            resultValidatorPredecessor.workflowDetailPredecessorList as Array<any>
                        ).filter((x) => x !== null)[0] as string,
                    ),
                );
            } else {
                setGeneralErrorMessage("");
            }
            return false;
        } else setGeneralErrorMessage("");

        await savePredecessors(recordInputPredecessorMultipleDTO);

        return true;
    };
    const savePredecessors = async (
        inputDTO: WorkflowDetailPredecessorMultipleInputDTO,
    ) => {
        let serviceClient = new WorkflowDetailPredecessorService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.insert(
                inputDTO,
                false,
                true,
                expectedCodes,
            );
            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                //callBackResult({ result: "OK", recordId: recordId });
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} savePredecessors ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} savePredecessors ex`);
            setIsLoadingScreen(false);
        }
    };
    const handleOnReminderTermChange = async (newReminderTerm: number) => {
        let newReminderState = { ...reminderState };
        let fixedNumber = Number(newReminderTerm);
        newReminderState.termReminderErrorMessage = "";
        if (isNaN(fixedNumber) || fixedNumber < 0 || Math.abs(fixedNumber) == 0) {
            fixedNumber = 0;
        }
        if (fixedNumber == 0) {
            newReminderState.reminderTermUnit = "";
        } else {
            if (fixedNumber < 15 && newReminderState.reminderTermUnit == "MI") {
                newReminderState.termReminderErrorMessage = await TPI18N.GetText(
                    resourceSetPredecessor,
                    "ErrorMinuteTerm",
                );
            }
        }
        newReminderState.reminderTerm = fixedNumber;
        setReminderState(newReminderState);
    };
    const handleOnTermReminderUnitChange = async (
        e: any,
        keyValue: TPKeyValue,
    ) => {
        let newReminderState = { ...reminderState };
        newReminderState.reminderTermUnit = e.target.value;
        newReminderState.termReminderUnitErrorMessage = "";
        if (
            newReminderState.reminderTermUnit == "MI" &&
            newReminderState.reminderTerm < 15
        ) {
            newReminderState.termReminderErrorMessage = await TPI18N.GetText(
                resourceSetPredecessor,
                "ErrorMinuteTerm",
            );
        } else {
            newReminderState.termReminderErrorMessage = "";
        }
        setReminderState(newReminderState);
    };
    const insertWorkflowDetailReminder = async (
        inputDTO: WorkflowDetailReminderInputDTO,
    ) => {
        let serviceClient = new WorkflowDetailReminderService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.insert(
                inputDTO,
                true,
                true,
                expectedCodes,
            );
            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                //callBackResult({ result: "OK", recordId: recordId });
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} insertWorkflowDetailReminder ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(
                `Error ${componentFileName} insertWorkflowDetailReminder ex`,
            );
            setIsLoadingScreen(false);
        }
    };
    const deleteReminders = async () => {
        let serviceClient = new WorkflowDetailReminderService();
        let expectedCodes: Array<number> = [200, 404];
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.delete(
                idWorkflowDetail,
                false,
                true,
                expectedCodes,
            );
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} deleteReminders ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} deleteReminders ex`);
            setIsLoadingScreen(false);
        }
    };
    const insertWorkflowDetail = async (inputDTO: WorkflowDetailInputDTO) => {
        let serviceClient = new WorkflowDetailService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.insert(
                inputDTO,
                true,
                true,
                expectedCodes,
            );
            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                idWorkflowDetail = responseRequest.responseData.keyList[0].value;
                //callBackResult({ result: "OK", recordId: recordId });
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} insertWorkflowDetail ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} insertWorkflowDetail ex`);
            setIsLoadingScreen(false);
        }
    };
    const updateWorkflowDetail = async (inputDTO_: WorkflowDetailInputDTO) => {
        let serviceClient = new WorkflowDetailService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.update(
                inputDTO_,
                true,
                true,
                expectedCodes,
            );

            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                // callBackResult({ result: "OK", recordId: recordId });
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} updatetFunction ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} updatetFunction ex`);
            setIsLoadingScreen(false);
        }
    };
    const handlerRowPotencialPredecessorClick = (selectedValue: string) => {
        let newWorkflowDetailPredecessorState = {
            ...workflowDetailPredecessorState,
        };
        newWorkflowDetailPredecessorState.selectedAvailableListValue =
            selectedValue;
        setWorkflowDetailPredecessorState(newWorkflowDetailPredecessorState);
    };
    const handlerRowPredecessorClick = (selectedValue: string) => {
        let newWorkflowDetailPredecessorState = {
            ...workflowDetailPredecessorState,
        };
        newWorkflowDetailPredecessorState.selectedSelectedListValue = selectedValue;
        setWorkflowDetailPredecessorState(newWorkflowDetailPredecessorState);
    };
    const handleLagModeOnchange = (event: any) => {
        setModeLagSelected(event.target.value);
    };
    useEffect(() => {
        loadResourcesAndLoadInfo();
    }, []);

    useEffect(() => {
        switch (modeLagSelected) {
            case "withoutLag":
                //modificar el estado
                let newWorkflowPredecessorState = {
                    ...workflowDetailPredecessorState,
                };
                newWorkflowPredecessorState.lag = 0;
                newWorkflowPredecessorState.lagUnit = "d";
                newWorkflowPredecessorState.dateField = "";
                setWorkflowDetailPredecessorState(newWorkflowPredecessorState);
                break;
            case "withLag":
                let newWorkflowPredecessorState2 = {
                    ...workflowDetailPredecessorState,
                };
                newWorkflowPredecessorState2.lag = 1;
                newWorkflowPredecessorState2.lagUnit = "d";
                newWorkflowPredecessorState2.dateField = "";
                setWorkflowDetailPredecessorState(newWorkflowPredecessorState2);
                break;
            case "withDate":
                let newWorkflowPredecessorState3 = {
                    ...workflowDetailPredecessorState,
                };
                newWorkflowPredecessorState3.lag = 0;
                newWorkflowPredecessorState3.lagUnit = "d";
                newWorkflowPredecessorState3.dateField = "";
                setWorkflowDetailPredecessorState(newWorkflowPredecessorState3);
                break;
            default:
                break;
        }
    }, [modeLagSelected]);
    return (
        <>
            <TPLoadingOverlay active={isLoadingScreen}>
                <div className="row">
                    <div className="col">
                        <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TPPageSection>
                            <div className="row">
                                <div className="col-5">
                                    <TPTextBox
                                        id="IdTextBox"
                                        labelText={workflowTypeLabel}
                                        value={
                                            workflowTypeValueState
                                                ? `${workflowTypeValueState.id} - ${workflowTypeValueState.description}`
                                                : ""
                                        }
                                        disabled={true}
                                        onChange={() => { }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <TPNumeric
                                        id="IdTPNumeric"
                                        isMandatory={true}
                                        onChange={(value: number) => {
                                            handleOnOrderChange(value);
                                        }}
                                        value={
                                            insertUpdateState.order ? insertUpdateState.order : null
                                        }
                                        labelText={orderLabel}
                                        errorMessage={insertUpdateState.orderErrorMessage}
                                        fixedDecimal={3}
                                    ></TPNumeric>
                                </div>
                                <div className="col-3">
                                    <TPAutoComplete
                                        isMandatory={true}
                                        labelText={taskTypeLabel}
                                        onValueChange={handleTaskTypeChange}
                                        onSearch={(query: string) => {
                                            handleTaskTypeOnAutocompleteQuery(query);
                                        }}
                                        onText={(text: string) => {
                                            handleAutoCompleteTopNTaskTypeText(text);
                                        }}
                                        isLoading={false}
                                        options={autocompleteTaskTypeOptions}
                                        withIcon={true}
                                        emptyLabel={emptyLabel}
                                        onKeyDown={handleTaskTypeOnAutocompleteKeyDown}
                                        selected={selectedTaskType}
                                        errorMessage={insertUpdateState.taskTypeIdErrorMessage}
                                        downArrowClick={handleAutoCompleteTopNTaskTypeClick}
                                    />
                                </div>
                                <div className="col-3">
                                    <TPNumeric
                                        id="IdTPNumeric"
                                        isMandatory={true}
                                        onChange={(value: number) => {
                                            handleOnTermChange(value);
                                        }}
                                        value={
                                            insertUpdateState.term ? insertUpdateState.term : null
                                        }
                                        labelText={termLabel}
                                        errorMessage={insertUpdateState.termErrorMessage}
                                        maxLength={3}
                                        decimalSeparator={""}
                                        fixedDecimal={0}
                                    ></TPNumeric>
                                </div>
                                <div className="col-3">
                                    <TPSelect
                                        id="IdSelect"
                                        isMandatory={true}
                                        onChange={(e: any, item: TPKeyValue) =>
                                            handleOnTermUnitChange(e, item)
                                        }
                                        dataSource={termUnitList}
                                        value={selectedTermUnit}
                                        labelText={unitTimeLabel}
                                        errorMessage={insertUpdateState.termUnitErrorMessage}
                                    ></TPSelect>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    {!(selectedWriter && selectedWriter[0] && selectedWriter[0].key && selectedWriter[0].key == TPGlobal.UserSystem.SYSTEM) ?
                                        <TPAutoComplete
                                            isMandatory={true}
                                            labelText={writerLabel}
                                            onValueChange={handleWriterChange}
                                            onSearch={(query: string) => {
                                                handleWriterOnAutocompleteQuery(query);
                                            }}
                                            isLoading={false}
                                            options={autocompleteWriterOptions}
                                            withIcon={true}
                                            emptyLabel={emptyLabel}
                                            onKeyDown={handleWriterOnAutocompleteKeyDown}
                                            selected={selectedWriter}
                                            errorMessage={insertUpdateState.writerErrorMessage}
                                            downArrowClick={handleAutoCompleteTopNWriterClick}
                                            disabled={false}

                                        />

                                        :
                                        <TPTextBox
                                            id="IdTextBox"
                                            labelText={writerLabel}
                                            value={
                                                selectedWriter ? selectedWriter[0].key : ""
                                            }
                                            disabled={true}
                                            onChange={() => { }}
                                        />
                                    }
                                </div>


                                <div className="col-5">
                                    <TPAutoComplete
                                        labelText={readerLabel}
                                        onValueChange={handleReaderChange}
                                        onSearch={(query: string) => {
                                            handleReaderOnAutocompleteQuery(query);
                                        }}
                                        isLoading={false}
                                        options={autocompleteReaderOptions}
                                        withIcon={true}
                                        emptyLabel={emptyLabel}
                                        onKeyDown={handleReaderOnAutocompleteKeyDown}
                                        selected={selectedReader}
                                        errorMessage={insertUpdateState.readerErrorMessage}
                                        downArrowClick={handleAutoCompleteTopNReaderClick}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <TPTextBox
                                        id="reminder-time-input"
                                        value={reminderState.reminderTerm}
                                        onChange={(event: any) => {
                                            handleOnReminderTermChange(Number(event.target.value));
                                        }}
                                        maxLength={20}
                                        labelText={reminderLabel}
                                        errorMessage={reminderState.termReminderErrorMessage}
                                    />
                                </div>
                                <div className="col-3">
                                    <TPSelect
                                        id="IdSelect"
                                        onChange={(e: any, item: TPKeyValue) =>
                                            handleOnTermReminderUnitChange(e, item)
                                        }
                                        dataSource={termUnitList}
                                        value={reminderState.reminderTermUnit}
                                        labelText={reminderLabel}
                                        errorMessage={reminderState.termReminderUnitErrorMessage}
                                    ></TPSelect>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <TPCheckBox
                                        id="IdCheckBox"
                                        checked={insertUpdateState.definesResponsible}
                                        onChange={(e: any) => handleDefinesResponsibleChange()}
                                        labelText={definesResponsibleLabel}
                                        disabled={thereIsDetemrineResponsible}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <TPCheckBox
                                        id="IdCheckBox"
                                        checked={insertUpdateState.closesCustomer}
                                        onChange={(e: any) => handleClosesCustomerChange()}
                                        labelText={closesCustomerLabel}
                                        disabled={thereIsCloseCustomer}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <TPCheckBox
                                        id="IdCheckBox"
                                        checked={insertUpdateState.requiresAllPredecessors}
                                        onChange={(e: any) => handleRequiresAllPredecessorsChange()}
                                        labelText={requiresAllPredecessorsLabel}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <TPCheckBox
                                        id="IdCheckBox"
                                        checked={insertUpdateState.isActive}
                                        onChange={(e: any) => handleIsActiveChange()}
                                        labelText={isActiveLabel}
                                    />
                                </div>
                            </div>
                        </TPPageSection>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <TPPageSectionTitle>{predecessorSectionLabel}</TPPageSectionTitle>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TPPageSection>
                            <div className="row">
                                <div className="col-12">
                                    {modeLagList.length > 0 && (
                                        <TPRadioGroup
                                            id="IdRadioGroup"
                                            value={modeLagSelected}
                                            labelText={selectLagTypeLabel}
                                            source={modeLagList}
                                            onChange={handleLagModeOnchange}
                                        ></TPRadioGroup>
                                    )}
                                </div>
                            </div>
                            {modeLagSelected === "withLag" && (
                                <div className="row">
                                    <div className="col-2">
                                        <TPNumeric
                                            id="IdTPNumeric"
                                            isMandatory={true}
                                            onChange={(value: number) => {
                                                handleOnLagChange(value);
                                            }}
                                            value={
                                                workflowDetailPredecessorState.lag
                                                    ? workflowDetailPredecessorState.lag
                                                    : null
                                            }
                                            labelText={lagLabel}
                                            errorMessage={
                                                workflowDetailPredecessorState.lagErrorMessage
                                            }
                                            maxLength={20}
                                            decimalSeparator={""}
                                            fixedDecimal={0}
                                        ></TPNumeric>
                                    </div>
                                    <div className="col-3">
                                        <TPSelect
                                            id="IdSelect"
                                            onChange={(e: any, item: TPKeyValue) =>
                                                handleOnLagUnitChange(e, item)
                                            }
                                            dataSource={lagUnitList}
                                            value={workflowDetailPredecessorState.lagUnit}
                                            labelText={""}
                                            errorMessage={
                                                workflowDetailPredecessorState.lagUnitErrorMessage
                                            }
                                        ></TPSelect>
                                    </div>
                                </div>
                            )}
                            {modeLagSelected === "withDate" && (
                                <div className="row mb-4">
                                    <div className="col-11">
                                        <TPSelect
                                            id="IdSelect"
                                            onChange={(e: any, item: TPKeyValue) =>
                                                handleOnAdditionalDataChange(e, item)
                                            }
                                            dataSource={additionalDataList}
                                            value={workflowDetailPredecessorState.dateField}
                                            labelText={dateFieldLabel}
                                            errorMessage={
                                                workflowDetailPredecessorState.dateBasedOnErrorMessage
                                            }
                                        ></TPSelect>
                                    </div>
                                </div>
                            )}

                            <div
                                className={`row ${modeLagSelected == "withoutLag" ? "mt-2" : ""}`}
                            >
                                <div className="col-5 mt-4">
                                    <div className="tptable-border-gray tpoverflow-y-auto tpheight-250">
                                        <TPTableCondensedContainer
                                            className={"table table-hover table-striped "}
                                        >
                                            <thead>
                                                <tr>
                                                    <th colSpan={4} className="text-center">
                                                        {availableLabel}
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>{orderColumnLabel}</th>
                                                    <th>{taskColumnLabel}</th>
                                                    <th>{pathColumnLabel}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {availableList.map((item, idx) => {
                                                    const columns = item.value.split("-");
                                                    return (
                                                        <tr
                                                            className={
                                                                item.key ===
                                                                    workflowDetailPredecessorState.selectedAvailableListValue
                                                                    ? "selected"
                                                                    : ""
                                                            }
                                                            onClick={() =>
                                                                handlerRowPotencialPredecessorClick(item.key)
                                                            }
                                                            key={`tr-${item.value}`}
                                                        >
                                                            <td>{columns[0]}</td>
                                                            <td>{columns[1]}</td>
                                                            <td>{columns[2]}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </TPTableCondensedContainer>
                                        {/* <TPSelect
                    onChange={(e: any, item: TPKeyValue) =>
                      handleOnAvailableListChange(e, item)
                    }
                    dataSource={availableList}
                    value={
                      workflowDetailPredecessorState.selectedAvailableListValue
                    }
                    labelText={availableLabel}
                    errorMessage={""}
                    size={7}
                  ></TPSelect> */}
                                    </div>
                                </div>
                                <div className="col-1">
                                    <TPButtonPredecessors>
                                        <TPButton
                                            id="IdButton"
                                            type={TPButtonTypes.icon}
                                            onClick={() =>
                                                handleModifyAssignments_OnClick("insertone")
                                            }
                                            icon={TPIconTypes.chevronRight}
                                            className="tp-big"
                                            iconStyle={{ fontSize: "50px" }}
                                        ></TPButton>
                                        <TPButton
                                            id="IdButton"
                                            type={TPButtonTypes.icon}
                                            onClick={() =>
                                                handleModifyAssignments_OnClick("deallocateone")
                                            }
                                            icon={TPIconTypes.chevronLeft}
                                            className="tp-big"
                                            iconStyle={{ fontSize: "50px" }}
                                        ></TPButton>
                                    </TPButtonPredecessors>
                                </div>

                                <div className="col-5 mt-4">
                                    <div className="tptable-border-gray tpoverflow-y-auto  tpheight-250">
                                        <TPTableCondensedContainer
                                            className={"table table-hover table-striped"}
                                        >
                                            <thead>
                                                <tr>
                                                    <th colSpan={4} className="text-center">
                                                        {selectedLabel}
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>{orderColumnLabel}</th>
                                                    <th>{taskColumnLabel}</th>
                                                    <th>{pathColumnLabel}</th>
                                                    <th>{lagColumnLabel}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedList.map((item, idx) => {
                                                    const columns = item.value.split("-");
                                                    return (
                                                        <tr
                                                            className={
                                                                item.key ===
                                                                    workflowDetailPredecessorState.selectedSelectedListValue
                                                                    ? "selected"
                                                                    : ""
                                                            }
                                                            onClick={() =>
                                                                handlerRowPredecessorClick(item.key)
                                                            }
                                                            key={`tr-${item.value}`}
                                                        >
                                                            <td>{columns[0]}</td>
                                                            <td>{columns[1]}</td>
                                                            <td>{columns[2]}</td>
                                                            <td>{columns[3]}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </TPTableCondensedContainer>
                                    </div>
                                </div>
                                {/* <div className="col-4">
                  <TPSelect
                    onChange={(e: any, item: TPKeyValue) =>
                      handleOnSelectedListChange(e, item)
                    }
                    dataSource={selectedList}
                    value={
                      workflowDetailPredecessorState.selectedSelectedListValue
                    }
                    labelText={selectedLabel}
                    errorMessage={""}
                    size={7}
                  ></TPSelect>
                </div> */}
                            </div>
                        </TPPageSection>
                    </div>
                </div>

                {generalErrorMessage && (
                    <div className="row mt-2">
                        <div className="col-12">
                            <TPPageSection>
                                <div className="row">
                                    <div className="col-12">
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {generalErrorMessage}
                                        </div>
                                    </div>
                                </div>
                            </TPPageSection>
                        </div>
                    </div>
                )}

                <div className="row mt-3">
                    {workflowTypeValueState?.isCasesUsed !== true && (
                        <div className="col-12">
                            <TPPageAcceptCancelButtonsContainer>
                                <TPButton
                                    id="IdButton"
                                    type={TPButtonTypes.primary}
                                    onClick={handleOkButtonClick}
                                >
                                    {saveButtonLabel}
                                </TPButton>
                                <TPButton
                                    id="IdButton"
                                    type={TPButtonTypes.link}
                                    onClick={handleCancelButtonClick}
                                    className={"ms-2"}
                                >
                                    {cancelButtonLabel}
                                </TPButton>
                            </TPPageAcceptCancelButtonsContainer>
                        </div>
                    )}
                </div>
                {modalSuccessState.isShown && (
                    <TPModalSuccess modalState={modalSuccessState} />
                )}
            </TPLoadingOverlay>
        </>
    );
};

export default WorkflowDetailInsertUpdate;
