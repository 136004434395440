import React from "react";
import TPSwitch from "@/components/bootstrap/forms/switch/TPSwitch";
import "./TabLabelWithSwitch.css";

interface TabLabelWithSwitchProps {
  title: string;
  isActive: boolean;
  onToggle: () => void;
  labelText: string;
}

const TabLabelWithSwitch: React.FC<TabLabelWithSwitchProps> = ({
  title,
  isActive,
  onToggle,
  labelText,
}) => {
  return (
    <div className="tab-label-container">
      <p className="tab-title">{title}</p>
      <div className="switch-container">
        <TPSwitch
          checked={isActive}
          onChange={onToggle}
          labelText={labelText}
        />
      </div>
    </div>
  );
};

export default TabLabelWithSwitch;
