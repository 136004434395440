import { ReactElement } from "react";

type TouchIconProps = {};

function TouchIcon({ }: TouchIconProps): ReactElement {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.44494 3.03896C11.3268 3.03896 12.8511 4.5632 12.8511 6.44507C12.8511 7.72237 12.1698 8.80381 11.148 9.39136V8.3525C11.6674 7.88416 11.9995 7.20293 11.9995 6.44507C11.9995 5.03154 10.8585 3.89049 9.44494 3.89049C8.0314 3.89049 6.89035 5.03154 6.89035 6.44507C6.89035 7.20293 7.22245 7.88416 7.74188 8.3525V9.39136C6.72005 8.80381 6.03882 7.72237 6.03882 6.44507C6.03882 4.5632 7.56306 3.03896 9.44494 3.03896ZM15.4056 16.2376C15.3801 16.9359 14.8266 17.4894 14.1283 17.5149H9.44494C9.12136 17.5149 8.81481 17.3872 8.59341 17.1488L5.18729 13.5724L5.81743 12.9167C5.97922 12.7379 6.20913 12.6442 6.46459 12.6442H6.63489L8.59341 14.1088V6.44507C8.59341 5.97673 8.9766 5.59354 9.44494 5.59354C9.91328 5.59354 10.2965 5.97673 10.2965 6.44507V10.2514L11.3268 10.3621L14.6818 12.227C15.1331 12.4313 15.4056 12.8826 15.4056 13.3765V16.2376ZM15.4056 0.484375H1.78118C0.8445 0.484375 0.078125 1.25075 0.078125 2.18743V8.99966C0.078125 9.45134 0.257554 9.88452 0.576939 10.2039C0.896324 10.5233 1.3295 10.7027 1.78118 10.7027H5.18729V8.99966H1.78118V2.18743H15.4056V8.99966H13.7026V10.7027H15.4056V10.6687L15.4397 10.7027C16.3679 10.7027 17.1087 9.92782 17.1087 8.99966V2.18743C17.1087 1.73575 16.9293 1.30257 16.6099 0.983189C16.2905 0.663804 15.8573 0.484375 15.4056 0.484375Z"
                fill="#A00095"
            />
        </svg>
    );
}

export default TouchIcon;
