import TagButton from "@/components/bootstrap/components/tagButton/TPTagButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPFormControlContainerStyled } from "@/helpers/generalStyles";
import { handleInvalidCharacters } from "@/helpers/TPFunctions";
import TPGlobal from "@/helpers/TPGlobal";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { MouseEventHandler, ReactElement, useEffect, useId, useState } from "react";
import { TPTextBoxStyled } from "./tpTextboxStyles";

interface TPTextBoxInterface {
  onClick?: Function;
  onKeyPress?: Function;
  onChange: Function;
  className?: string;
  value: string | number | undefined;
  disabled?: boolean;
  maxLength?: number;
  hasFocus?: boolean;
  labelText?: string | JSX.Element;
  isMandatory?: boolean;
  errorMessage?: string;
  isHorizontal?: boolean;
  placeholder?: string;
  withIcon?: boolean;
  icon?: TPIconTypes;
  type?: string;
  iconClick?: any;
  containerStyle?: any;
  labelStyle?: any;
  textStyle?: any;
  customIcon?: JSX.Element;
  isPrefixIcon?: boolean;
  inputRef?: any;
  isTag?: boolean;
  id?: string;
  tooltip?: string;
  withCircleText?: boolean;
  name?: string;
  tagHandler?: MouseEventHandler<HTMLButtonElement>;
  isInvalidChars?: boolean;
}

const TPTextBox = ({
  onClick,
  onKeyPress,
  onChange,
  className = "",
  value,
  disabled,
  maxLength,
  hasFocus,
  labelText,
  isMandatory = false,
  errorMessage = "",
  isHorizontal = false,
  placeholder = "",
  withIcon = false,
  icon = TPIconTypes.default,
  type = "text",
  iconClick = undefined,
  containerStyle,
  labelStyle,
  textStyle,
  customIcon,
  isPrefixIcon = false,
  inputRef,
  isTag = false,
  id,
  tooltip,
  withCircleText,
  name,
  tagHandler = undefined,
  isInvalidChars = true,
}: TPTextBoxInterface): ReactElement => {
  const theId = `textbox-${useId()}`;
  const [localErrorMessage, setLocalErrorMessage] = useState("");
  const [inputValue, setInputValue] = useState(value || "");
  const [invalidCharacterLabel, setInvalidCharacterLabel] = useState('');

  useEffect(() => {
    const fetchText = async () => {
      const invalidCharacter = await TPI18N.GetText(TPGlobal.globalResourceSet, 'InvalidCharacter');
      setInvalidCharacterLabel(invalidCharacter);
    };
    fetchText();
  }, []);

  const handleOnChange = (e: any) => {
    const newValue = e.target.value;

    // Define a regex for the specified invalid characters
    const invalidCharRegex = new RegExp(`[${TPGlobal.TPClientInvalidCharRegex}]`, 'g');

    // Check for invalid characters if validation is enabled
    if (isInvalidChars) {
      handleInvalidCharacters(
        newValue,
        invalidCharRegex,
        invalidCharacterLabel,
        setLocalErrorMessage,
        setInputValue,
        onChange,
        e
      );
    } else {
      // If no validation is required, update the input normally
      setInputValue(newValue);
      onChange && onChange({ ...e, target: { ...e.target, value: newValue } });
    }
  };

  const handleOnClick = (e: any) => {
    onClick && onClick(e);
  };

  const handleOnKeyPress = (e: any) => {
    onKeyPress && onKeyPress(e);
  };

  const theInput = (
    <TPTextBoxStyled
      name={name ?? ""}
      placeholder={placeholder}
      id={id ? id : theId}
      ref={inputRef}
      type={type}
      value={inputValue}
      className={`form-control ${className} ${(localErrorMessage || errorMessage) ? "is-invalid" : ""} ${withIcon ? "border-end-0 with-icon" : ""}`}
      onChange={handleOnChange}
      onClick={handleOnClick}
      onKeyPress={handleOnKeyPress}
      disabled={disabled}
      maxLength={maxLength}
      autoCorrect={"off"}
      autoComplete={"off"}
      style={textStyle}
    />
  );

  useEffect(() => {
    let inputElement: any;
    if (hasFocus) {
      if (inputRef.current) {
        inputElement = inputRef.current;
        inputElement.focus();
      }
    }
  }, [hasFocus, inputRef]);

  useEffect(() => {
    if ((value !== undefined) && (value !== null) && (value !== inputValue))
      setInputValue(value);
  }, [value, inputValue]);

  const drawLabel = () => {
    return (
      <TPLabel
        withCircleText={withCircleText}
        htmlFor={id ? id : theId}
        isMandatory={isMandatory}
        labelText={labelText}
        style={labelStyle}
      />
    );
  };

  const drawInvalidFeedBack = () => {
    const finalErrorMessage = localErrorMessage || errorMessage;
    return (
      finalErrorMessage && (
        <div
          className="invalid-feedback"
          style={{ display: isTag ? "block" : "" }}
        >
          {finalErrorMessage}
        </div>
      )
    );
  };

  return (
    <>
      <TPFormControlContainerStyled
        isHorizontal={isHorizontal}
        className={`${withIcon ? "input-group" : ""}`}
        style={containerStyle}
        isTag={isTag}
      >
        {labelText && drawLabel()}
        {withIcon && isPrefixIcon && (
          <span className="input-group-prepend">
            {icon === TPIconTypes.custom && <>{customIcon}</>}
            {icon != TPIconTypes.custom && (
              <TPIcon iconType={icon} onClick={iconClick} />
            )}
          </span>
        )}
        {theInput}
        {isTag && (
          <TagButton className="tag-btn" tagHandler={tagHandler}>
            {"[Tag]"}
          </TagButton>
        )}
        {withIcon && !isPrefixIcon && (
          <span className="input-group-append" title={tooltip}>
            {icon === TPIconTypes.custom && <>{customIcon}</>}
            {icon != TPIconTypes.custom && (
              <TPIcon iconType={icon} onClick={iconClick} />
            )}
          </span>
        )}

        {/* Use your provided error message rendering structure */}
        {!withIcon && (localErrorMessage || errorMessage) && !isTag && drawInvalidFeedBack()}
      </TPFormControlContainerStyled>

      {isTag && drawInvalidFeedBack()}

      {withIcon && (localErrorMessage || errorMessage) && (
        <div>
          <div className="is-invalid"></div>
          {drawInvalidFeedBack()}
        </div>
      )}
    </>
  );
};

export default TPTextBox;
