import { ReactElement, useState } from "react";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPSwitch from "../../forms/switch/TPSwitch";
import {
  TPCollapsableSectionTitle,
  TPCollapsableSectionContainer,
  TPCollapsableSectionHeader,
  TPCollapsableSectionContent,
  TPCollapsableSectionDescription,
  TPCollapsableSectionBody,
} from "./TPCollapsableSectionStyles";

interface TPCollapsableSectionProps {
  title: string | ReactElement;
  description?: string | ReactElement;
  children?: string | ReactElement;
  initialCollapsed?: boolean;
  enableSwitch?: boolean;
  switchState?: boolean;
  switchLabel?: string | ReactElement;
  onSwitchChange?: () => void;
}

const TPCollapsableSection = ({
  title,
  description,
  children,
  initialCollapsed = false,
  enableSwitch = false,
  switchState = false,
  switchLabel,
  onSwitchChange,
}: TPCollapsableSectionProps): ReactElement => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(initialCollapsed);

  return (
    <TPCollapsableSectionContainer style={{ position: "relative" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TPCollapsableSectionHeader
          onClick={() => setIsCollapsed((prev) => !prev)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TPCollapsableSectionTitle>{title}</TPCollapsableSectionTitle>

          <TPIcon
            iconType={
              isCollapsed ? TPIconTypes.expandMore : TPIconTypes.expandLess
            }
          />
        </TPCollapsableSectionHeader>

        {enableSwitch && (
          <TPSwitch
            checked={switchState}
            onChange={onSwitchChange}
            labelText={switchLabel}
          />
        )}
      </div>

      <TPCollapsableSectionContent collapsed={isCollapsed}>
        {description && (
          <TPCollapsableSectionDescription>
            {description}
          </TPCollapsableSectionDescription>
        )}

        {children && (
          <TPCollapsableSectionBody>{children}</TPCollapsableSectionBody>
        )}
      </TPCollapsableSectionContent>
    </TPCollapsableSectionContainer>
  );
};

export default TPCollapsableSection;
