import styled from "styled-components";
import { formControlGeneralStyle } from "@/helpers/generalStyles";

export const TPNumericStyled = styled.div`
  input {
    height: 35px;
    border-color: #ced4da;
    border-radius: 4px;
    ${formControlGeneralStyle}
  }
`;
