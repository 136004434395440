import { CSSProperties } from "react";

/**
 * STYLES
 */
export const styles = {
  container: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    placeContent: "flex-start",
    alignItems: "flex-start",
    gap: "10px",
  } as CSSProperties,
  inputContainer: {
    flex: "1 1 0%",
  } as CSSProperties,
  iconButton: {
    marginTop: "25px",
  } as CSSProperties,
  modalContent: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  } as CSSProperties,
};
/**
 * STYLES END
 */
