import { FC, useEffect, useState } from "react";
import "./TPIntegerSelectStyles.css";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";

interface TPIntegerSelectProps {
  value: number;
  onChange: (value: number) => void;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  inputWidth?: string;
  errorMessage?: string;
}

export const TPIntegerSelect: FC<TPIntegerSelectProps> = function({
  value,
  maxValue = 1000000,
  minValue = 0,
  onChange,
  disabled = false,
  inputWidth = "5em",
  errorMessage = ""
}) {
  const [count, setCount] = useState(String(value) || String(minValue));
  const handleCountChange = (value: string) => {
    if (disabled) return;
    if (Number.isNaN(Number(value)) && value != "-") return;
    if ((maxValue !== undefined) && (Number(value) > maxValue)) value = String(maxValue);
    if ((minValue !== undefined) && (Number(value) < minValue)) value = String(minValue);
    setCount(value);
    !Number.isNaN(Number(value)) && value.length > 0 && onChange(Number(value));
  }

  const handleBlur = () => {
    handleCountChange(String(value));
  }
  useEffect(() => {
    handleCountChange(String(value))
  }, [value])
  return (
    <div>
      <div 
        className="tp-integer-select-input-box"
        style={{
          border: errorMessage ? "1px solid red" : ""
        }}
      >
        <input
          placeholder={String(value)}
          className="tp-integer-select-input"
          value={count} 
          onChange={e => handleCountChange(e.target.value)} 
          onBlur={handleBlur}
          disabled={disabled}
          style={{
            width: inputWidth,
          }}
        />
        <div className="tp-integer-select-arrow-buttons">
          <button 
            type="button" 
            className="tp-integer-select-arrow-button"
            onClick={(e) => {
              e.stopPropagation();
              handleCountChange(String(Number(count)+1));
            }}
          >
            <TPIcon iconType={TPIconTypes.arrowDropUp} style={{position:"relative", top: "-25%"}} />
          </button>
          <button 
            type="button" 
            className="tp-integer-select-arrow-button"
            onClick={(e) => {
              e.stopPropagation();
              handleCountChange(String(Number(count)-1))
            }}
          >
            <TPIcon iconType={TPIconTypes.arrowDropDown} style={{position:"relative", bottom: "35%"}} />
          </button>
        </div>
        
      </div>
      {errorMessage && (
          <div className="invalid-feedback" style={{display:"block"}}>{errorMessage}</div>
        )}
    </div>
  )
}