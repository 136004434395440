import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";
import { useEffect, useState } from "react";

const RESOURCE_SET = 'CaseToolbar';

interface CaseToolbarLabels {

  CaseToolbar: string;
  NewItem: string;
  Item: string;
  CaseToolbarItemInformation: string;
  Description: string;
  ItemLocalizedDescriptions: string;
  Type: string;
  LinkComponent: string;
  SelectIcon: string;
  ApplyItemToProfiles: string;
  Edit: string;
  Delete: string;
  Yes: string;
  No: string;
  Save: string;
  Cancel: string;
  DescriptionExample: string;
  Modal: string;
  CaseToolbarPreview: string;
  Group: string;
  Items: string;
  Preview: string;
  Ok: string;
  ShowAsActive: string;
  DeleteConfirm: string;
  LanguageList: string;
}

export const useCaseToolbarLabels = function() {

  const [labels, setLabels] = useState<CaseToolbarLabels>({} as CaseToolbarLabels);

  const loadLabels = async function() {
    setLabels({
      ApplyItemToProfiles: await TPI18N.GetText(RESOURCE_SET, "ApplyItemToProfiles"),
      CaseToolbar: await TPI18N.GetText(RESOURCE_SET, "CaseToolbar"),
      CaseToolbarItemInformation: await TPI18N.GetText(RESOURCE_SET, "CaseToolbarItemInformation"),
      Description: await TPI18N.GetText(RESOURCE_SET, "Description"),
      Item: await TPI18N.GetText(RESOURCE_SET, "Item"),
      ItemLocalizedDescriptions: await TPI18N.GetText(RESOURCE_SET, "ItemLocalizedDescriptions"),
      LinkComponent: await TPI18N.GetText(RESOURCE_SET, "LinkComponent"),
      NewItem: await TPI18N.GetText(RESOURCE_SET, "NewItem"),
      SelectIcon: await TPI18N.GetText(RESOURCE_SET, "SelectIcon"),
      Type: await TPI18N.GetText(RESOURCE_SET, "Type"),
      Edit: await TPI18N.GetText(TPGlobal.globalResourceSet, "Edit"),
      Delete: await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      Yes: await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes"),
      No: await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"),
      Save: await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
      Cancel: await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      DescriptionExample: await TPI18N.GetText(RESOURCE_SET, "DescriptionExample"),
      CaseToolbarPreview: await TPI18N.GetText(RESOURCE_SET, "CaseToolbarPreview"),
      Group: await TPI18N.GetText(TPGlobal.globalResourceSet, "Group"),
      Items: await TPI18N.GetText(TPGlobal.globalResourceSet, "Items"),
      Modal: await TPI18N.GetText(TPGlobal.globalResourceSet, "Modal"),
      Ok: await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      Preview: await TPI18N.GetText(RESOURCE_SET, "Preview"),
      ShowAsActive: await TPI18N.GetText(RESOURCE_SET, "ShowAsActive"),
      DeleteConfirm: await TPI18N.GetText(RESOURCE_SET, "DeleteConfirm"),
      LanguageList: await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    })
  }

  useEffect(() => {
    loadLabels();
  }, [])

  return { labels }
}