import React, { FC, useEffect, useState } from "react";
import "./GrammarBoxStyles.css";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { GrammarBoxData, GrammarCheckWord } from "@/models/GrammarCheck/GrammarCheck";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";

interface GrammarBoxProps {
  data: GrammarBoxData;
  onCorrection: (newWord: string) => void;
  onClose: () => void;
  language: string;
  onLanguageSelect: (lang: string) => void;
  position: {top: number, left: number};
  onIgnore: () => void;
}

export const GrammarBox: FC<GrammarBoxProps> = function({ data, position, onClose, onCorrection, language, onLanguageSelect, onIgnore }) {
  const [iWriteInLabel, setIWriteInLabel] = useState("");
  const [ignoreLabel, setIgnoreLabel] = useState("");
  const loadResources = async function() {
    setIWriteInLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "IWriteIn"));
    setIgnoreLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Ignore"));
  }
  useEffect(() => {
    loadResources();
  }, [])
  return (
    <div className="grammar-box-gradient" style={{top: position.top, left:position.left}}>
        <div className="grammar-box">
          <div className="grammar-box-header">
            <TPIcon style={{fontSize: "20px"}} iconType={TPIconTypes.spellCheck} />
            {data.problem} - {data.errorDescription}
            <button 
              type="button" 
              onClick={onClose}
              style={{
                border: "none",
                background: "none"
              }}
            >
              <TPIcon style={{fontSize: "20px"}} iconType={TPIconTypes.cross} />
            </button>
          </div>
          <div className="grammar-box-suggestions">
            <div className="grammar-box-suggestions-inner">
              {data.wordSuggestions.map(word => (
                <button 
                  className="grammar-box-suggestion-option"
                  type="button"
                  onClick={() => onCorrection(word)}
                >
                  {word}
                </button>
              ))}
            </div>
            {/* <div style={{display:"flex", flexDirection:"row", gap: "8px", alignItems: "center"}}>
              {iWriteInLabel}
              <TPSelect 
                dataSource={TPGlobal.TPClientAvailableLanguages.map(l => ({ key: l.id, value: l.name }))}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onLanguageSelect(e.target.value)}
                value={language}
              />
            </div> */}
          </div>
          <button type="button" className="grammar-box-ignore-button" onClick={() => onIgnore()}>
            <u>{ignoreLabel}</u>
          </button>
          <div className="grammar-box-triangle" />
          <div className="grammar-box-triangle-border" />
        </div>
    </div>
  )
}