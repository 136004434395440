import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPRadio } from "@/components/TPRadio/TPRadio";
import TPSwitch from "@/components/TPSwitch/TPSwitch";
import { AdditionalDataCategoryEnum, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import React, { FC, useEffect, useState } from "react"
import { ScriptAdditionalData, ScriptAdditionalDataLogic, ScriptAvailableDataTypes, ScriptLogicStep, ScriptsMode, ScriptStep } from "@/models/Scripts/ScriptModel";
import TPCustomAutocomplete from "@/components/TPCustomAutocomplete/TPCustomAutocomplete";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import { AdditionalDataService } from "@/services/AdditionalDataService";
import { AdditionalDataOption } from "../Components/AdditionalDataOption";
import { areEqual } from "@/helpers/ValidateChanges";
import { LogicDeterminationContainer } from "../Components/LogicDeterminationContainer";
import { TPModalAlert } from "@/components/TPModalAlert/TPModalAlert";
import { BranchService } from "@/services/BranchService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { ScriptsService } from "@/services/Scripts/ScriptsService";
import { Label } from "recharts";
import { extractTextFromHTML } from "@/helpers/ExtractTextFromHTML";
import { useScriptsLabels } from "../Assets/labelling";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import AdditionalDataPopUp from "@/components/bootstrap/forms/aditionalDataPopUp/AditionalDataPopUp";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";

interface ScriptAdditionalDataDefinitionProperties {
  onSubmit: (saved: boolean, data?: ScriptAdditionalData) => void;
  onAdditionalDataChange: (data: ScriptAdditionalData) => void;
  step: ScriptStep;
  additionalData?: ScriptAdditionalData;
  takenOrders?: number[];
  availableSteps: ScriptStep[];
  dataId: string | number | null;
}

interface ScriptAdditionalDataDefinitionForm {
  additionalDataId: (value: string, label?: string) => void,
  isAddNewLine: (value: boolean) => void,
  isLogicDetermination: (value: boolean) => void,
  isMandatory: (value: boolean) => void,
  isSaveCase: (value: boolean) => void,
  isWhiteSpace: (value: boolean) => void,
  label: (value: string) => void,
  order: (value: number) => void,
  logics: (value: ScriptAdditionalDataLogic[]) => void
}

const formInitialState: ScriptAdditionalData = {
  id: "",
  additionalDataId: "",
  isAddNewLine: false,
  isLogicDetermination: false,
  isMandatory: false,
  isSaveCase: false,
  isWhiteSpace: false,
  label: "",
  order: 0,
  scriptStepId: "",
  logics: []
}

const autocompleteCooldown = 250;

export const ScriptAdditionalDataDefinition: FC<ScriptAdditionalDataDefinitionProperties> = function ({
  onSubmit,
  step,
  takenOrders,
  availableSteps,
  dataId
}) {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialForm, setInitialForm] = useState<ScriptAdditionalData>(structuredClone(formInitialState));
  const [currentForm, setCurrentForm] = useState<ScriptAdditionalData>(structuredClone(formInitialState));
  const [additionalDataResults, setAdditionalDataResults] = useState<AdditionalDataViewModel[]>([]);
  const [chosenAdditionalData, setChosenAdditionalData] = useState<AdditionalDataViewModel | null>();
  const [orderInput, setOrderInput] = useState('');
  const [orderError, setOrderError] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const { labels } = useScriptsLabels();
  const casecontext: any = React.useContext(TPCaseViewerContext);
  const [initialOrder, setInitialOrder] = useState(0);
  const [isLogicDeterminationValid, setIsLogicDeterminationValid] = useState(false);

  const updateForm: ScriptAdditionalDataDefinitionForm = {
    additionalDataId: (value, label) => setCurrentForm({
      ...currentForm,
      additionalDataId: value,
      label: label || currentForm.label || "",
      isLogicDetermination: false,
      logics: []
    }),
    isAddNewLine: (value) => setCurrentForm({
      ...currentForm,
      isAddNewLine: value
    }),
    isLogicDetermination: (value) => setCurrentForm({
      ...currentForm,
      isLogicDetermination: value
    }),
    isMandatory: (value) => setCurrentForm({
      ...currentForm,
      isMandatory: value
    }),
    isSaveCase: (value) => setCurrentForm({
      ...currentForm,
      isSaveCase: value
    }),
    isWhiteSpace: (value) => setCurrentForm({
      ...currentForm,
      isWhiteSpace: value
    }),
    label: (value) => setCurrentForm({
      ...currentForm,
      label: value
    }),
    order: (value) => setCurrentForm({
      ...currentForm,
      order: value
    }),
    logics: (value) => setCurrentForm({
      ...currentForm,
      logics: value
    }),
  }

  const getAdditionalDataById = function (dataId: string) {
    if (!dataId) return;
    setLoading(true);
    const serviceInstance = new AdditionalDataService();
    serviceInstance.getAdditionalDataById(dataId, false, true, [200])
      .then((response) => {
        if (response) {
          setChosenAdditionalData(response);
          setQuery(response.description);
        }
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  const saveAdditionalData = function () {
    setLoading(true);
    if (!dataId) {
      ScriptsService.createStepAdditionalData(
        {
          ...currentForm,
          order: Number(orderInput),
          scriptStepId: step.id,
          label: currentForm.isWhiteSpace ? "_" : currentForm.label
        }
        , true, true, [200])
        .then(() => onSubmit(true))
        .catch(err => console.error(err))
        .finally(() => setLoading(false))
    } else {
      ScriptsService.updateStepAdditionalData(
        {
          ...currentForm,
          order: Number(orderInput),
          scriptStepId: step.id,
          id: String(currentForm.id),
          label: currentForm.isWhiteSpace ? "_" : currentForm.label
        },
        true, true, [200]
      )
        .then(() => onSubmit(true))
        .catch(err => console.error(err))
        .finally(() => setLoading(false))
    }
  }

  const getStepAdditionalData = function () {
    if (!dataId) return;
    setLoading(true);
    ScriptsService.getStepAdditionalData(dataId, false, true, [200])
      .then((response) => {
        if (response) {
          setInitialForm({ ...response });
          setCurrentForm({ ...response });
          setOrderInput(String(response.order));
          getAdditionalDataById(response.additionalDataId);
          setInitialOrder(response.order);
        }
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const found = additionalDataResults.find(a => a.localizedDescription === query);
    if (additionalDataResults.length > 0) {
      if (found) {
        updateForm.additionalDataId(found.id, currentForm.label || found.localizedDescription);
        setChosenAdditionalData(found);
      } else  {
        updateForm.additionalDataId("", currentForm.label);
      }
    }
    
    const intervalId = setTimeout(
      () => {
        if (query.trim().length > 0) {
          const addDataServiceInstance = new AdditionalDataService();
          addDataServiceInstance.GetByParameters(
            query,
            AdditionalDataCategoryEnum.FC_BRANCH,
            "",
            false,
            false,
            [200, 404]
          )
            .then((results) => {
              if (results && results.length > 0) {
                setAdditionalDataResults(results);
              }
            })
            .catch(err => console.error(err));
        }
      }, autocompleteCooldown)

    return () => clearInterval(intervalId);
  }, [query])

  useEffect(() => {
    if (dataId) getStepAdditionalData();
  }, [])

  useEffect(() => {
    if (!currentForm.isWhiteSpace) updateForm.label("");
  }, [currentForm.isWhiteSpace])

  useEffect(() => {
    if (Number.isNaN(Number(orderInput))) {
      setOrderError(labels.ErrorOrderNumeric);
    } else if (Number(orderInput) <= 0) {
      setOrderError(labels.ErrorOrderPositive);
    } else if ((Number(orderInput) !== initialOrder) && takenOrders?.find(taken => taken == Number(orderInput))) {
      setOrderError(labels.ErrorOrderTaken);
    } else if (Number(orderInput) >= 10000) {
      setOrderError(labels.ErrorOrderTooBig);
    } else {
      setOrderError("");
    }
    updateForm.order(Number(orderInput));
  }, [orderInput])

  useEffect(() => {
    if (!currentForm.isLogicDetermination) updateForm.logics([]);
  }, [currentForm.isLogicDetermination])

  function asciiToJson(asciiString: string | null): string | null {
    if (!asciiString) {
      return null;
    }

    try {

      const jsonReadable = String.fromCharCode(...asciiString.split(',').map(Number)).trim();


      if (isValidJson(jsonReadable)) {
        return jsonReadable;
      } else {
        console.error(jsonReadable);
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  function isValidJson(jsonString: string): boolean {
    if (!jsonString) return false;
    try {
      JSON.parse(jsonString);
      return true;
    } catch {
      return false;
    }
  }

  function hasEmptyFields(jsonObject: any): boolean {
    return Object.values(jsonObject).some(value => value === "");
  }
  return (
    <>
      <TPModalAlert
        onDesicion={(confirmed) => {
          setModalVisible(false);
        }}
        visible={modalVisible}
        content={
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "16px" }}>
            <TPIcon iconType={TPIconTypes.alert} style={{ fontSize: "32px", color: "#780096" }} />
            <label>{labels.AnotherAddData}</label>
          </div>
        }
        acceptLabel={labels.Ok}
        hideCancelButton
      />
      <TPLoadingOverlay active={loading}>
      <div id='scripts-additional-data-definition' className="scripts-main-container">
        <div style={{ display: 'flex', flexDirection: 'column', gap: '32px', width: '75%' }}>
          <div className="script-admin-header">
              <TPPageTitle>{labels.AdditionalDataStepDefinition}</TPPageTitle>
          </div>
          <div style={{width: "50%"}}>
            <TPTextArea
              onChange={() => { }}
              rows={5}
                value={extractTextFromHTML(step.description) || ''}
              disabled
              textAreaStyle={{ maxHeight: '256px', minHeight: '128px' }}
                labelText={labels.StepDescription}
              isMandatory
            />
          </div>
          <div className="script-form-row">
            <div style={{ width: '50%' }}>
              <TPCustomAutocomplete
                input={query}
                onQuery={(text) => setQuery(text)}
                mandatory
                title={labels.AdditionalData}
                children={additionalDataResults.map((addData, idx) => (
                  <AdditionalDataOption
                    key={addData.localizedDescription}
                    addData={addData}
                    onClick={addData => {
                      updateForm.additionalDataId(addData.id, currentForm.label || addData.localizedDescription);
                      setChosenAdditionalData(addData);
                      setQuery(addData.localizedDescription);
                    }}
                  />
                ))}
              />
            </div>
              <div className={"col-1"}>
                  <AdditionalDataPopUp
                      callBackResult={(success: any, data: any) => {updateForm.additionalDataId(success.data.id, success.data.description);
                          setChosenAdditionalData(success.data);
                          setQuery(success.data.description);
                      }}
                  />
              </div>
          </div>
          <div className="script-form-row">
            <div style={{ width: '50%' }}>
              <TPTextBox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateForm.label(e.target.value)}
                value={currentForm.isWhiteSpace ? '' : currentForm.label}
                isMandatory
                  labelText={labels.Label}
                disabled={currentForm.isWhiteSpace}
              />
            </div>
            <TPCheckBox
              checked={currentForm.isWhiteSpace}
                labelText={labels.WhiteSpace}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateForm.isWhiteSpace(e.target.checked)}
            />
          </div>
          <div className="script-form-row">
            <div style={{ width: '50%' }}>
              <TPTextBox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                  setOrderInput(e.target.value)}
                value={orderInput}
                isMandatory
                errorMessage={orderError}
                  labelText={labels.Order}
              />
            </div>
          </div>
          <div className="script-form-row" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              {labels.SaveInCase}
            <div style={{ display: 'flex', gap: '16px' }}>
              <TPRadio
                checked={currentForm.isSaveCase}
                onClick={() => updateForm.isSaveCase(true)}
                  label={labels.Yes}
              />
              <TPRadio
                checked={!currentForm.isSaveCase}
                onClick={() => updateForm.isSaveCase(false)}
                  label={labels.No}
              />
            </div>
          </div>
          <div className="script-form-row" style={{ gap: '32px' }}>
            <TPCheckBox
              checked={currentForm.isMandatory}
                labelText={labels.Mandatory}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateForm.isMandatory(e.target.checked)}
            />
            <TPCheckBox
              checked={currentForm.isAddNewLine}
                labelText={labels.AddInNewLine}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateForm.isAddNewLine(e.target.checked)}
            />
          </div>
        </div>
        <div className="script-form-row" style={{ borderBottom: '1px solid #BFBFBF' }} />
        <div className="script-form-row">
            <b>{labels.LogicDetermination}</b>
          <TPSwitch
            checked={currentForm.isLogicDetermination}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateForm.isLogicDetermination(e.target.checked)}
              label={labels.Yes}
            disabled={
                !chosenAdditionalData ||
                chosenAdditionalData.additionalDataTypeId === ScriptAvailableDataTypes.ATTACHMENT ||
                (
                    chosenAdditionalData.additionalDataTypeId === ScriptAvailableDataTypes.LISTVALUE &&
                    Boolean(chosenAdditionalData.jsonParameters) &&
                    isValidJson(asciiToJson(chosenAdditionalData.jsonParameters ?? "") ?? "") &&
                    hasEmptyFields(JSON.parse(asciiToJson(chosenAdditionalData.jsonParameters ?? "") ?? "{}")) &&
                    JSON.parse(asciiToJson(chosenAdditionalData.jsonParameters ?? "") ?? "{}").renderMethod === "Checkbox"
                )
            }
          />
        </div>
          {currentForm.isLogicDetermination && chosenAdditionalData &&
        <LogicDeterminationContainer
          data={currentForm}
          title={step.description || ''}
          availableNextSteps={availableSteps}
          currentOrder={step?.order || -1}
            onLogicChange={(logics) => {
              setIsLogicDeterminationValid(logics.length > 0 && logics.every(l => l.nextStepId.length > 0));
              updateForm.logics(logics)
            }}
              chosenData={chosenAdditionalData}
            />}
        <div className="script-management-footer" style={{ border: 'none', paddingTop: 0 }}>
          <div className="script-footer-buttons-container">
            <TPButton
              onClick={() => onSubmit(false)}
              isDesignSystem
              style={{
                paddingLeft: '16px',
                paddingRight: '16px',
                backgroundColor: 'white',
                color: 'purple'
              }}
            >
                {labels.Cancel}
            </TPButton>
            <TPButton
              onClick={() => {
                if (
                  currentForm.isLogicDetermination &&
                  step.additionalData.find(d => d.isLogicDetermination && (d.order != currentForm.order))
                ) {
                  setModalVisible(true);
                } else {
                  saveAdditionalData();
                }
              }}
              isDesignSystem
              style={{
                paddingLeft: '16px',
                paddingRight: '16px'
              }}
              disabled={
                areEqual(currentForm, initialForm)
                ||
                (
                  currentForm.additionalDataId.trim().length == 0 ||
                  (!currentForm.isWhiteSpace && (currentForm.label.trim().length == 0)) ||
                  orderInput.trim().length == 0 ||
                  query.trim().length == 0 ||
                  // query.trim() != currentForm.additionalData.description.trim() ||
                  (currentForm.isLogicDetermination && !isLogicDeterminationValid) ||
                  (currentForm.isLogicDetermination && currentForm.logics.length == 0) ||
                  orderError?.length > 0 ||
                  Number.isNaN(Number(orderInput)) ||
                  Number(orderInput) < 0
                )
              }
            >
                {labels.Save}
            </TPButton>
          </div>
        </div>
      </div>
      </TPLoadingOverlay>
    </>
  )
}