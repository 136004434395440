import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { CaseToolbarItem, CaseToolbarItemOrder, CaseToolbarItemRequest, CaseToolbarReorderModel, CaseToolbarUserItemResponse } from "@/models/CaseToolbar/CaseToolbarModel";

export class CaseToolbarService {

  private static serviceFileName = "CaseToolbarService.ts";

  public static async getAllItems(
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<Array<CaseToolbarItem>> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/casetoolbaritem');
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} getAllItems exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to get all toolbar items: ${error}`);
    }
  }

  public static async getItemsByUserGuid(
    userGuid: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<Array<CaseToolbarUserItemResponse>> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/casetoolbaritem/by-user-guid/',userGuid);
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} getAllItems exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to get all toolbar items: ${error}`);
    }
  }

  public static async getItemOrdersByProfile(
    profileId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<Array<CaseToolbarItemOrder>> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/casetoolbaritem/getorder/',profileId);
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} getAllItems exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to get all toolbar items: ${error}`);
    }
  }

  public static async getItemById(
    itemId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<CaseToolbarItem> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/casetoolbaritem/', itemId);
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} getItemById exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to get item by id: ${error}`);
    }
  }

  public static async createItem(
    requestBody: CaseToolbarItemRequest,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ) : Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/casetoolbaritem');
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.postData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} createItem exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to create toolbar item: ${error}`);
    }
  }

  public static async updateItem(
    requestBody: CaseToolbarItemRequest,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ) : Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/casetoolbaritem');
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.putData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} updateItem exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to update toolbar item: ${error}`);
    }
  }

  public static async deleteItem(
    itemId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<CaseToolbarItem> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/casetoolbaritem/', itemId);
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.deleteData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} deleteItem exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to delete toolbar item: ${error}`);
    }
  }

  public static async reorderByProfile(
    requestBody: CaseToolbarReorderModel,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/casetoolbaritem/updateorder');
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      await httpClientInstance.putData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} reorderByProfile exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to reorder toolbar items: ${error}`);
    }
  }
}