import {
  TabsCaseViewerSlice,
  TabsCaseViewerStateModel,
} from "@/layouts/VerticalTabs/_redux/TabsCaseViewerSlice";
import moment from "moment";
import { useSelector } from "react-redux";
import { StoreModel } from "../../redux/store";
import TPGlobal from "@/helpers/TPGlobal";

const MINUTE = 60;
const HOUR = MINUTE * 60;

interface VerticalTabCaseTimeListenerProps {
  caseNumber: string;
  isCIM?: boolean;
}

export default function VerticalTabCaseTimeListener({
  caseNumber,
  isCIM = false,
}: VerticalTabCaseTimeListenerProps) {
  const { seconds } = useSelector(
    (s: StoreModel) => s[TabsCaseViewerSlice.name]
  ) as TabsCaseViewerStateModel;

  const timerStyles = {
    display: TPGlobal.DisplayCaseAverageHandleTime === "1" ? "block" : "none",
    fontSize: "14px",
    color: "black",
  };

  const cimTimerStyles = {
    display: TPGlobal.DisplayCaseAverageHandleTime === "1" ? "block" : "none",
    fontSize: "16px",
    color: "#A00095",
    fontWeight: "700",
  }

  return (
    <span style={isCIM ? cimTimerStyles : timerStyles}>
      {moment(moment().format("YYYY-MM-DD"))
        .add(seconds[caseNumber] || 0, "s")
        .format(seconds[caseNumber] >= HOUR || isCIM ? "HH:mm:ss" : "mm:ss")}
    </span>
  );
}
