import React, { useMemo } from "react";
import useTranslations from "../hooks/useTranslations";
import TPVerticalTabs from "@/components/TPTabs/TPVerticalTabs";
import TPDescription from "@/components/bootstrap/forms/TPDescription/TPDescription";
import { useConfiguration } from "../context/ConfigurationContext";
import TabLabelWithSwitch from "../common/TabLabelWithSwitch/TabLabelWithSwitch";
import { TPFieldContainer } from "../common/TPFieldContainer/TPFieldContainer";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";

const CustomerInformationComponent: React.FC = () => {
  const RESOURCE_SET = "ConfigurationGeneralComponent";
  const COMPONENT_NAME = "CustomerInformationComponent.tsx";
  const { changeValueForKey, saveFieldsConfiguration, keyValues } =
    useConfiguration();

  const initialText = useMemo(
    () => ({
      enableTitle: "EnableTitle",
      enableDescriptions: "EnableDescriptions",
      customerTitle: "CustomerTitle",
      customerDescriptions: "CustomerDescriptions",
      defaultFormCustomerTitle: "DefaultFormCustomerTitle",
      defaultFormCustomerDescriptions: "DefaultFormCustomerDescriptions",
      externalCustomerSearchTitle: "ExternalCustomerSearchTitle",
      externalCustomerSearchDescriptions: "ExternalCustomerSearchDescriptions",
      externalSourceURLLabel: "ExternalSourceURLLabel",
      externalSourceHeightLabel: "ExternalSourceHeightLabel",
      searchClientExternalSourceLabel: "SearchClientExternalSourceLabel",
      activeLabel: "ActiveLabel",
      saveButton: "SaveButton",
    }),
    []
  );

  const translationsText = useTranslations(
    initialText,
    RESOURCE_SET,
    COMPONENT_NAME
  );

  const tabConfigs = useMemo(
    () => [
      {
        key: "showAnonymousLinkCases",
        title: translationsText.enableTitle,
      },
      {
        key: "showCustomerContacts",
        title: translationsText.customerTitle,
      },
      {
        key: "defaultFormCustomerEnable",
        title: translationsText.defaultFormCustomerTitle,
      },
      {
        key: "externalCustomerSearchEnable",
        title: translationsText.externalCustomerSearchTitle,
      },
    ],
    [translationsText]
  );

  const tabLabels = useMemo(
    () =>
      tabConfigs.map(({ key, title }) => (
        <TabLabelWithSwitch
          key={key}
          title={title}
          isActive={keyValues[key]?.value === "1"}
          onToggle={async () => {
            const newValue = keyValues[key]?.value === "1" ? "0" : "1";
            changeValueForKey(key, newValue);
            await saveFieldsConfiguration({ [key]: newValue });
          }}
          labelText={translationsText.activeLabel}
        />
      )),
    [tabConfigs, keyValues, translationsText, changeValueForKey]
  );

  const tabContent = useMemo(
    () => [
      <EnableConfiguration key="enable" translationsText={translationsText} />,
      <CustomerConfiguration
        key="customer"
        translationsText={translationsText}
      />,
      <DefaultFormCustomerConfiguration
        key="defaultFormCustomer"
        translationsText={translationsText}
      />,
      <ExternalCustomerSearchConfiguration
        key="externalCustomerSearch"
        translationsText={translationsText}
      />,
    ],
    [translationsText]
  );

  return <TPVerticalTabs tabLabels={tabLabels} tabContent={tabContent} />;
};

export default CustomerInformationComponent;

const EnableConfiguration: React.FC<{ translationsText: any }> = ({
  translationsText,
}) => {
  return (
    <div className="col">
      <TPDescription text={translationsText.enableDescriptions} />
    </div>
  );
};

const CustomerConfiguration: React.FC<{ translationsText: any }> = ({
  translationsText,
}) => {
  return (
    <div className="col">
      <TPDescription text={translationsText.customerDescriptions} />
    </div>
  );
};

const DefaultFormCustomerConfiguration: React.FC<{
  translationsText: any;
}> = ({ translationsText }) => {
  return (
    <div className="col">
      <TPDescription text={translationsText.defaultFormCustomerDescriptions} />
    </div>
  );
};

const ExternalCustomerSearchConfiguration: React.FC<{
  translationsText: any;
}> = ({ translationsText }) => {
  const { changeValueForKey, handleSave, keyValues, someChange, errorState } =
    useConfiguration();

  return (
    <div className="col">
      <TPDescription
        text={translationsText.externalCustomerSearchDescriptions}
      />

      {keyValues["externalCustomerSearchEnable"]?.value === "1" && (
        <>
          <div className="row">
            <div className="col">
              <TPFieldContainer>
                <TPTextBox
                  id="externalSourceURL"
                  isMandatory={true}
                  onChange={(e: any) =>
                    changeValueForKey("externalSourceURL", e.target.value)
                  }
                  value={keyValues["externalSourceURL"]?.value}
                  labelText={translationsText.externalSourceURLLabel}
                  errorMessage={errorState.externalSourceURLErrorMessage}
                  isInvalidChars={false}
                />
              </TPFieldContainer>
            </div>

            <div className="col">
              <TPFieldContainer>
                <TPTextBox
                  id="externalSourceHeight"
                  isMandatory={true}
                  onChange={(e: any) =>
                    changeValueForKey("externalSourceHeight", e.target.value)
                  }
                  value={keyValues["externalSourceHeight"]?.value}
                  labelText={translationsText.externalSourceHeightLabel}
                  errorMessage={errorState.externalSourceHeightErrorMessage}
                />
              </TPFieldContainer>
            </div>
          </div>

          <div className="row" style={{ marginTop: 5 }}>
            <div className="col">
              <TPFieldContainer>
                <TPCheckBox
                  checked={
                    keyValues["searchClientExternalSource"]?.value === "1"
                  }
                  onChange={(e: any) => {
                    changeValueForKey(
                      "searchClientExternalSource",
                      e.target.checked ? "1" : "0"
                    );
                  }}
                  labelText={translationsText.searchClientExternalSourceLabel}
                />
              </TPFieldContainer>
            </div>
          </div>

          <TPButton
            id="IdButton"
            onClick={handleSave}
            type={TPButtonTypes.primary}
            className="fixed-save-button"
            disabled={!someChange}
            style={
              !someChange
                ? {
                    backgroundColor: "#e6e6e6",
                    borderColor: "#efefef",
                    color: "#989898",
                    cursor: "not-allowed",
                  }
                : {}
            }
          >
            {translationsText.saveButton}
          </TPButton>
        </>
      )}
    </div>
  );
};
