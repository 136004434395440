import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { AdditionalDataFormViewModel } from "@/models/AdditionalDataForm/AdditionalDataFormModel";
import { FormesignerInputDTO } from "@/models/FormDesigner/FormDesigner";
import { DistributionModel, DistributionUpdateModel, TableDistributionModel } from "@/models/DistributionListAdmin/DistributionListAdmin";



let list = [
  {
    id: "1",
    name: "Name 1",
    isActive: false,
    recordLanguageList:  []
  },
  {
    id: "2",
    name: "Name 2",
    isActive: true,
    recordLanguageList:  []
  }
];



export class DistributionListAdminService {
  serviceFileName: string = "DistributionListAdminService.ts";



  public async getByFiltersIsActive(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<Array<TableDistributionModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/distributionlist?FilterIsActive=${filterActive}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
 
      return  response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} GetByFiltersIsActive ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} GetByFiltersIsActive`);
      throw new Error(`Error ${this.serviceFileName} GetByFiltersIsActive`);
    }
  }


  public async insertDistributionListAdmin(
    inputDTO: DistributionModel,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/distributionlist";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertFormDesingner ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} insertFormDesingner`);
      throw new Error(`Error ${this.serviceFileName} insertFormDesingner`);
    }
  }

  public async updateDistributionListAdmin(
    inputDTO: DistributionUpdateModel,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/distributionlist";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateFormDesingner ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} updateFormDesingner`);
      throw new Error(`Error ${this.serviceFileName} updateFormDesingner`);
    }
  }



  public async getDistributionListAdminById(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/distributionlist/";
    url = url + id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getDistributionListAdminById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getDistributionListAdminById`);
      throw new Error(
        `Error ${this.serviceFileName} getDistributionListAdminById`
      );
    }
  }

  public async getDistributionListAddressByDistributionId(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/distributionlistaddress/get-by-distributionid/";
    url = url + id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getDistributionListAdminById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getDistributionListAdminById`);
      throw new Error(
        `Error ${this.serviceFileName} getDistributionListAdminById`
      );
    }
  }



  public async deleteDistributionListAdminById(
    Id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/distributionlist/" + Id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteDistributionListAdminById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${this.serviceFileName} deleteDistributionListAdminById`
      );
      throw new Error(
        `Error ${this.serviceFileName} deleteDistributionListAdminById`
      );
    }
  }


}
