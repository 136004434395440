import styled from "styled-components";

export const TPSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  cursor: pointer;
`;

export const TPSwitchStyled = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const TPSwitchSlider = styled.span<{ checked: boolean }>`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  background-color: ${(props) => (props.checked ? "#009A58" : "#ccc")};
  border-radius: 34px;
  cursor: pointer;
  transition: 0.4s;

  &:before {
    content: "";
    position: absolute;
    height: 14px;
    width: 14px;
    left: ${(props) => (props.checked ? "18px" : "2px")};
    bottom: 3px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }
`;

export const TPSwitchLabel = styled.label`
  all: unset; /* Reinicia todos los estilos heredados */
  font-size: 16px;
  color: #333;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;

  /* Necesario para asegurarse de que no herede estilos de subrayado */
  display: inline;
  text-decoration: none !important;
`;

