import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPGlobal from "@/helpers/TPGlobal";
import { AttachmentLabels } from "@/models/EmailTemplates/EmailTemplateModels";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
    LoaderRing,
    LoaderWrapper,
    StyledDragFile,
    StyledDragText,
    StyledFileList,
    StyledLoadFileDialog,
} from "@/modules/core/design-system/design-system-styles";
import { DragAndDropFileLabels } from "@/modules/core/design-system/design-system.model";
import { TemporaryAttachmentService } from "@/services/TemporaryAttachments";
import { DragEvent, ReactElement, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { FileResumed } from "./EmailAttachments";

type DragAndDropFilesProps = {
    componentLabels: DragAndDropFileLabels;
    baseLabels: AttachmentLabels;
    operationGuid: string;
    maxMBFileSize?: number;
    onFilesChange: (files: FileResumed[]) => void;
};

type FileItem = {
    blobId: string;
    file: File;
    formFile: FileForm;
    isErrorFile: boolean;
    isLoading: boolean;
    completed: boolean;
    failed: boolean;
};

export interface FileForm {
    fileData: File;
    operationGuid: string;
    blobId: string;
    filename: string;
    extensionName: string;
    sender: string;
    description: string;
    guid_USER: string;
}

function DragAndDropFiles({
    componentLabels,
    baseLabels,
    operationGuid,
    maxMBFileSize = 500,
    onFilesChange,
}: DragAndDropFilesProps): ReactElement {
    const service = new TemporaryAttachmentService();
    const inputId: string = `files-input-${uuidv4()}_${Date.now()}`;

    const [files, setFiles] = useState<FileItem[]>([]);
    const [dragging, setDragging] = useState<boolean>(false);

    const onFilesSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFilesToLoad(event.target.files);
            event.target.value = "";
        }
    };

    const setFilesToLoad = (files: FileList): void => {
        const fileItems: FileItem[] = Array.from(files).map((file: File) =>
            getFileResumed(file)
        );

        setFiles(fileItems);

        fileItems.forEach((fileItem, i) => {
            if (!fileItem.isErrorFile) uploadTemporaryFile(fileItem, i);
        });
    };

    const uploadTemporaryFile = async (
        file: FileItem,
        index: number
    ): Promise<void> => {
        const expectedCodes = [200, 404];
        const fileData: FileForm = file.formFile;

        setFiles((prevFiles) => {
            const updatedFiles = [...(prevFiles || [])];
            updatedFiles[index] = { ...updatedFiles[index], isLoading: true };
            return updatedFiles;
        });

        const response = await service.insertTemporaryAttachment(
            fileData,
            true,
            true,
            expectedCodes
        );

        if (response) {
            setFiles((prevFiles) => {
                const updatedFiles = [...(prevFiles || [])];
                updatedFiles[index] = {
                    ...updatedFiles[index],
                    completed: true,
                    isLoading: false,
                    failed: response.responseData.responseCode !== 200,
                };
                return updatedFiles;
            });
        }
    };

    const getResumedFileExtension = (fileName: string): string => {
        const parts = fileName.split(".");
        return parts[parts.length - 1].toLocaleLowerCase();
    };

    const getFileResumed = (file: File): FileItem => {
        const fileForm: FileForm = {
            fileData: file,
            operationGuid: operationGuid,
            blobId: getBlobId(),
            filename: file.name,
            extensionName: getResumedFileExtension(file.name),
            sender: TPGlobal.currentUserName,
            description: "",
            guid_USER: TPGlobal.currentUserGuid,
        };

        return {
            file: file,
            isErrorFile: !isValidFile(file),
            formFile: fileForm,
            blobId: fileForm.blobId,
            isLoading: false,
            completed: false,
            failed: false,
        };
    };

    const getBlobId = (): string => {
        return `caseblob${uuidv4().replaceAll("-", "")}`;
    };

    const getSizeFormatBy = (file: File): string => {
        let bytes = file.size;
        if (bytes < 1024) return bytes + " bytes";
        if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(2) + " KB";
        return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    };

    const isValidFormatFile = (file: File): boolean => {
        const validTypes = [
            "image/png",
            "image/jpeg",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/pdf",
        ];

        return validTypes.includes(file.type);
    };

    const isValidSizeFile = (file: File): boolean => {
        const fileSizeInMB = file.size / (1024 * 1024);
        return fileSizeInMB <= maxMBFileSize;
    };

    const isValidFile = (file: File): boolean => {
        return isValidFormatFile(file) && isValidSizeFile(file);
    };

    const removeFileBy = (blobId: string) => {
        const newFiles = files?.filter(
            (fileItem: FileItem) => fileItem.blobId != blobId
        );
        newFiles && setFiles(newFiles);
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setFilesToLoad(e.dataTransfer.files);
            e.dataTransfer.clearData();
        }
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const openFileExplorer = () => {
        const input = document.getElementById(inputId) as HTMLInputElement;
        if (input) {
            input.click();
        }
    };

    const getFileMessageBy = (isErrorFile: boolean, failed: boolean): string => {
        if (isErrorFile && !failed) {
            return isErrorFile
                ? componentLabels.fileError
                : componentLabels.fileSuccess;
        } else {
            return failed ? baseLabels.wrong : componentLabels.fileSuccess;
        }
    };

    const getCurrentFilesStatus = (): string => {
        if (!files || files.length === 0) {
            return baseLabels.noFiles;
        }

        const loadingCount = files.filter(file => file.isLoading && !file.completed && !file.failed).length;
        const completedCount = files.filter(file => file.completed).length;
        const errorCount = files.filter(file => file.failed || file.isErrorFile).length;

        return `${baseLabels.loading} (${loadingCount}) ${baseLabels.completed} (${completedCount}) ${baseLabels.error} (${errorCount})`;
    }

    useEffect(() => {
        if (files) {
            const completedFiles: FileItem[] = files.filter(file => file.completed && !file.isErrorFile && !file.failed);

            const filesResumed: FileResumed[] = completedFiles.map(file => {
                const { blobId, formFile } = file;
                const { filename, extensionName, sender, description, guid_USER } = formFile;
                return {
                    blobId,
                    filename,
                    extensionName,
                    sender,
                    description,
                    guid_USER,
                    saveInCase: false
                }
            })

            onFilesChange(filesResumed);
        }
    }, [files])

    return (
        <StyledLoadFileDialog style={{ gap: 0 }}>
            <StyledDragFile
                dragging={dragging}
                fileExist={false}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                style={{ marginBottom: "1em" }}
            >
                <StyledDragText>
                    <TPIcon iconType={TPIconTypes.cloud} />
                    <div className="text-container">
                        <p className="drop-label">{componentLabels.drop}</p>
                        <p className="size-label">{`${componentLabels.maxSize} ${maxMBFileSize} MB`}</p>
                    </div>
                </StyledDragText>

                <input
                    type="file"
                    id={inputId}
                    name={inputId}
                    accept=".png, .jpeg, .jpg, .docx, .pdf, .xlsx"
                    onChange={onFilesSelected}
                    style={{ display: "none" }}
                    multiple
                />

                <div className="buttons-container">
                    <TPButton
                        id="select-file"
                        isDesignSystem
                        customType={ButtonCustomType.secondary}
                        onClick={openFileExplorer}
                        style={{ padding: "1px 18px" }}
                    >
                        {componentLabels.selectFile}
                    </TPButton>
                </div>
            </StyledDragFile>

            {files.length > 0 && (
                <>
                    <p style={{ fontSize: "12px" }}><strong>{baseLabels.chargeState}: </strong>{getCurrentFilesStatus()}</p>
                    <div className="file-list">
                        {files.map(
                            ({ file, blobId, isLoading, isErrorFile, failed }: FileItem) => (
                                <StyledFileList
                                    isErrorFile={isErrorFile || failed}
                                    className="file-item"
                                    key={blobId}
                                >
                                    <div className="file-info">
                                        <div className="status-icons">
                                            {isLoading ? (
                                                <LoaderWrapper>
                                                    <LoaderRing />
                                                </LoaderWrapper>
                                            ) : (
                                                <TPIcon
                                                    iconType={
                                                        isErrorFile || failed ? TPIconTypes.close : TPIconTypes.done
                                                    }
                                                />
                                            )}
                                        </div>
                                        <div className="text-container">
                                            <p className="drop-label">{`${file.name} (${getSizeFormatBy(file)})`}</p>
                                            <p className="status-label">
                                                {getFileMessageBy(isErrorFile, failed)}
                                            </p>
                                        </div>
                                    </div>
                                    <TPIcon
                                        className="cancel-btn"
                                        id="cancel-file"
                                        onClick={() => !isLoading && removeFileBy(blobId)}
                                        iconType={TPIconTypes.close}
                                    />
                                </StyledFileList>
                            )
                        )}
                    </div>
                </>
            )}
        </StyledLoadFileDialog>
    );
}

export default DragAndDropFiles;
