import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import { AttachmentLabels } from "@/models/EmailTemplates/EmailTemplateModels";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { DragAndDropFileLabels } from "@/modules/core/design-system/design-system.model";
import { StyledAttachmentContent } from "@/pages/CaseViewer/case-viewer-styles";
import { TPI18N } from "@/services/I18nService";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import DragAndDropFiles, { FileForm } from "./DragAndDropFiles";

type EmailAttachmentsProps = {
    operationGuid: string;
    baseLabels: AttachmentLabels;
    onCheckChange: (attachment: AttachmentValues) => void;
    onFilesChange: (files: FileResumed[]) => void;
};

type AttachmentCheck = {
    id: string;
    name: string;
    label: string;
    checked: boolean;
    disabled: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export interface FileResumed
    extends Omit<FileForm, "fileData" | "operationGuid"> {
    saveInCase: boolean;
}

export interface AttachmentValues {
    all: boolean;
    sendCase: boolean;
    sendTask: boolean;
    saveInCase: boolean;
}

function EmailAttachments({
    operationGuid,
    baseLabels,
    onCheckChange,
    onFilesChange,
}: EmailAttachmentsProps): ReactElement {
    const component: string = "DragFileModal";

    const initialComponentLabels: DragAndDropFileLabels =
        {} as DragAndDropFileLabels;

    const [componentLabels, setComponentLabels] = useState<DragAndDropFileLabels>(
        initialComponentLabels
    );

    const [attachmentValues, setAttachmentValues] = useState<AttachmentValues>({
        all: false,
        sendCase: false,
        sendTask: false,
        saveInCase: false,
    });

    const attachmentCheckboxes: AttachmentCheck[] = [
        {
            id: "is-all-check",
            name: "all",
            label: baseLabels.all,
            checked: attachmentValues.all,
            disabled: false,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(event);
            },
        },
        {
            id: "is-send-case-check",
            name: "sendCase",
            label: baseLabels.sendCase,
            checked: attachmentValues.sendCase,
            disabled: false,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(event);
            },
        },
        {
            id: "is-send-task-check",
            name: "sendTask",
            label: baseLabels.sendTask,
            checked: attachmentValues.sendTask,
            disabled: false,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(event);
            },
        },
        {
            id: "is-save-in-case-check",
            name: "saveInCase",
            label: baseLabels.saveInCase,
            checked: attachmentValues.saveInCase,
            disabled: false,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(event);
            },
        },
    ];

    const loadUtilsResources = async () => {
        setComponentLabels({
            title: await TPI18N.GetText(component, "LoadNewFile"),
            downloadTemplate: await TPI18N.GetText(component, "DownloadTemplate"),
            selectFile: await TPI18N.GetText(component, "UploadFile"),
            selectNewFile: await TPI18N.GetText(component, "SelectNewFile"),
            drop: await TPI18N.GetText(component, "DropEmailFile"),
            maxSize: await TPI18N.GetText(component, "MaxSize"),
            fileError: await TPI18N.GetText(component, "FileError"),
            fileSuccess: await TPI18N.GetText(component, "FileSuccess"),
            validSuccess: await TPI18N.GetText(component, "ValidSuccess"),
            validError: await TPI18N.GetText(component, "ValidError"),
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;

        if (name === "all") {
            setAttachmentValues((prevForm) => ({
                ...prevForm,
                all: checked,
                sendCase: checked,
                sendTask: checked,
                saveInCase: checked,
            }));

            return;
        }

        setAttachmentValues((prevForm) => ({
            ...prevForm,
            [name]: checked,
        }));
    };

    useEffect(() => {
        loadUtilsResources().then();
    }, []);

    useEffect(() => {
        onCheckChange(attachmentValues);
    }, [attachmentValues]);

    return (
        <Accordion
            sx={{
                boxShadow: "none",
                width: "100%",
                margin: "0 !important",
                border: "none",
                "& .MuiAccordionSummary-content": {
                    margin: 0,
                },
                "&::before": {
                    display: "none",
                },
            }}
        >
            <AccordionSummary
                expandIcon={<TPIcon iconType={TPIconTypes.chevron} />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                    minHeight: "36px",
                    backgroundColor: "#F4F4F4",
                    borderRadius: "4px",
                    fontSize: "14px",
                    "& ::before": {
                        content: "none",
                    },
                    "& .MuiAccordionSummary-expandIconWrapper": {
                        fontSize: "24px",
                        width: "24px",
                        height: "24px",
                        color: "#2E2E2E",
                    },
                    "&.Mui-expanded": {
                        minHeight: "36px",
                    },
                }}
            >
                {baseLabels.title}
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "8px 0" }}>
                <StyledAttachmentContent>
                    <div className="check-options">
                        {attachmentCheckboxes.map((checkbox: AttachmentCheck) => (
                            <div className="email-checkbox" key={checkbox.id}>
                                <label htmlFor={checkbox.id}>
                                    <TPCheckBox
                                        id={checkbox.id}
                                        name={checkbox.name}
                                        checked={checkbox.checked}
                                        onChange={checkbox.onChange}
                                        disabled={checkbox.disabled}
                                    />
                                    <p>{checkbox.label}</p>
                                </label>
                            </div>
                        ))}
                    </div>

                    <DragAndDropFiles
                        operationGuid={operationGuid}
                        baseLabels={baseLabels}
                        componentLabels={componentLabels}
                        onFilesChange={onFilesChange}
                    />
                </StyledAttachmentContent>
            </AccordionDetails>
        </Accordion>
    );
}
export default EmailAttachments;
