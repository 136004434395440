import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { FC, ReactElement, useEffect, useState } from "react";

import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";


export type TPModalPDFViewerStateType = {
  isShown: boolean;
  title: string;
  pdfBase64: string;
  fileName: string;
  callBackFunction: Function;
};

type TPModalPDFViewerProps = {
  modalState: TPModalPDFViewerStateType;
};

type internalStateType = {
  fileBase64: string;
  pageNumber: number;
  maxPages: number;
};

const TPModalPDFViewer: FC<TPModalPDFViewerProps> = ({
  modalState,
}): ReactElement => {
  //#region init
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;

  const componentFileName: string = "TPModalPDFViewer.tsx";

  //Screen resources
  const resourceSet: string = "TPModalPDFViewerComponent";
  const [nextLabel, setNextLabel] = useState("");
  const [previousLabel, setPreviousLabel] = useState("");
  const [printLabel, setPrintLabel] = useState("");

  if (modalState.isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  const initialInternalState: internalStateType = {
    fileBase64: "",
    pageNumber: 1,
    maxPages: 1,
  };
  const [internalState, setInternalState] =
    useState<internalStateType>(initialInternalState);
  //#endregion init

  const loadResources = async () => {
    setNextLabel(await TPI18N.GetText(resourceSet, "NextLabel"));
    setPreviousLabel(await TPI18N.GetText(resourceSet, "PreviousLabel"));
    setPrintLabel(await TPI18N.GetText(resourceSet, "PrintLabel"));
  };

  const handleIncrementPageClick = () => {
    let newInternalState: internalStateType = { ...internalState };
    if (newInternalState.pageNumber + 1 > newInternalState.maxPages) {
      return;
    }
    newInternalState.pageNumber = newInternalState.pageNumber + 1;
    setInternalState(newInternalState);
  };

  const handleDecrementPageClick = () => {
    let newInternalState: internalStateType = { ...internalState };
    if (newInternalState.pageNumber - 1 <= 0) {
      return;
    }
    newInternalState.pageNumber = newInternalState.pageNumber - 1;
    setInternalState(newInternalState);
  };

  const handleOnloadSuccess = (e: any) => {
    let newInternalState: internalStateType = { ...internalState };
    newInternalState.maxPages = e.numPages;
    setInternalState(newInternalState);
  };
  const handleExportClick = () => {
    const tempLink = document.createElement("a");
    tempLink.href = `data:application/pdf;base64,${modalState.pdfBase64}`;
    tempLink.setAttribute("download", modalState.fileName);
    tempLink.click();
  };

  useEffect(() => {
    loadResources();
  }, []);

  useEffect(() => {
    let newInternalState: internalStateType = {
      fileBase64: modalState.pdfBase64,
      pageNumber: 1,
      maxPages: 1,
    };
    setInternalState(newInternalState);
  }, [modalState.pdfBase64]);


  return (
    <>
      <div
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop={backdropClass}
        style={styleModal}
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{modalState.title}</h5>
              <TPButton
                type={TPButtonTypes.empty}
                onClick={() => modalState.callBackFunction()}
                className={"text-end"}
              >
                X
              </TPButton>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4 d-flex justify-content-between">
                  <div>
                    <TPButton
                      onClick={handleExportClick}
                      type={TPButtonTypes.icon}
                      text={printLabel}
                      icon={TPIconTypes.print}
                    />
                  </div>
                  <div>
                    {internalState.maxPages == 1 ? null : (
                      <div className="d-flex gap-4 justify-content-end">
                        <TPButton
                          onClick={handleDecrementPageClick}
                          type={TPButtonTypes.icon}
                          text={previousLabel}
                          icon={TPIconTypes.chevronLeft}
                        />

                        <TPButton
                          onClick={handleIncrementPageClick}
                          type={TPButtonTypes.icon}
                          text={nextLabel}
                          icon={TPIconTypes.chevronRight}
                          iconAtRight={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-4"></div>
                <div className="col-4" style={{ border: "1px solid #3f3f3f" }}>
                  {internalState.fileBase64 ? (
                      <iframe
                          src={`data:application/pdf;base64,${internalState.fileBase64 }`}
                          width="100%"
                          height="1000px"
                      />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              TPClient Copyright &copy; {TPGlobal.currentYear}
            </div>
          </div>
        </div>
      </div>

      <div className={backdropClass}></div>
    </>
  );
};

export default TPModalPDFViewer;
