import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { commandsEnum as event } from "@/layouts/VerticalTabs/VerticalTabsAdminContainer";
import { SequenceGeneratorSequencesNameEnum, TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import {
    LanguageModel,
    ProjectCloneControl,
    ProjectCloneModel,
    ProjectCloneEnum as e,
} from "@/models/Project/Projects";
import { EventProjectService } from "@/services/EventProjectService";
import { TPI18N } from "@/services/I18nService";
import { SequenceService } from "@/services/SequenceService";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx";
import { useFormik } from "formik";
import {CSSProperties, useEffect, useRef, useState} from "react";
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";
import {MultilingualTextBoxEvents} from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";

interface ProjectCloneProps {
    tabId: string;
    element: { [ attribute: string ]: any };
    dispatch: Function;
}

export default function ProjectClone({tabId, element, dispatch}: ProjectCloneProps) {

    const formik = useFormik({
        initialValues: {
            ...ProjectCloneControl.initialValues,
            sourceProjectId: element.id,
        } as ProjectCloneModel,
        validationSchema: ProjectCloneControl.validationSchema,
        validateOnChange: false,
        onSubmit: () => {
        },
    });

    const [m, setMessages] = useState<any>({});
    const [languages, setLanguages] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const nameInputRef = useRef<MultilingualTextBoxEvents>();
    useEffect(() => {
        load().then(() => setLoading(false))
    }, []);

    async function load() {
        const messages = {...m};
        // title label
        messages[e.TitleLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.TitleLabel);
        messages[e.DescriptionLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.DescriptionLabel);
        // form inputs label
        messages[e.FormInputSourceIdLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.FormInputSourceIdLabel);
        messages[e.FormInputIdLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.FormInputIdLabel);
        messages[e.FormInputNameLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.FormInputNameLabel);
        messages[e.FormInputActiveLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.FormInputActiveLabel);
        // language modal label
        messages[e.LanguageButtonTooltipLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.LanguageButtonTooltipLabel);
        messages[e.LanguageModalTitleLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.LanguageModalTitleLabel);
        messages[e.LanguageModalOkButtonLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.LanguageModalOkButtonLabel);
        messages[e.LanguageModalCancelButtonLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.LanguageModalCancelButtonLabel);
        // form actions label
        messages[e.FormActionCancelLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.FormActionCancelLabel);
        messages[e.FormActionNewProjectLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.FormActionNewProjectLabel);

        setMessages(messages)

        if (element.id) {
            const {generalAutomaticId} = new SequenceService();
            generalAutomaticId(false, true, [200], SequenceGeneratorSequencesNameEnum.SQEVPJ)
                .then(({responseData}) => {
                    formik.setFieldValue("projectId", responseData?.data[0]?.sequenceCode)
                })
                .catch(error => console.error(error))
        }
    }

    function onClickSaveButtonHandler() {
        const {clone} = new EventProjectService()
        const languages = nameInputRef.current?.getValue();
        const mainLanguage = languages?.find(({id}) => id === TPGlobal.TPClientDefaultLanguage);
        const otherLocalizedValues = [
            {
                languageId: mainLanguage?.id!,
                localizedValue: mainLanguage?.value ?? "",
                order: 1
            },
            ...languages!
                .filter(({id}) => id !== TPGlobal.TPClientDefaultLanguage)
                .map(({id, value}, order) => ({languageId: id!, localizedValue: value ?? "", order: order + 2}))
        ]
        formik.values.nameLanguages = otherLocalizedValues;
        clone(formik.values)
            .then(project => {
                dispatch({type: event.vertical_tab_close, payload: tabId })
                dispatch({type: event.reloadGrid})
                dispatch({type: event.detail, payload: project })
            })
            .catch(error => console.error(error))
    }

    function onClickCancelButtonHandler() {
        dispatch({type: event.vertical_tab_close, payload: tabId })
        dispatch({type: event.reloadGrid})
    }

    return (
        <ContentVerticalTabInsertUpdateStyled>
            <TPLoadingOverlay active={loading}>
                <div className="row">
                    <div className="col-8">
                        <TPPageTitle style={styles.title}>{m?.[e.TitleLabel]}</TPPageTitle>

                        <form style={styles.form}>
                            <h6>{m?.[e.DescriptionLabel]}</h6>

                            <TPTextBox
                                id="sourceProjectId"
                                disabled={true}
                                labelText={m?.[e.FormInputSourceIdLabel]}
                                isMandatory={true}
                                value={`${element.name} (${element.id})`}
                                onChange={() => {
                                }}
                                errorMessage={formik.errors.sourceProjectId && m?.[formik.errors.sourceProjectId]}
                            />

                            <TPTextBox
                                id="projectId"
                                disabled={true}
                                labelText={m?.[e.FormInputIdLabel]}
                                isMandatory={true}
                                value={formik.values.projectId}
                                onChange={() => {
                                }}
                                errorMessage={formik.errors.projectId && m?.[formik.errors.projectId]}
                            />

                            <MultilingualTextBox
                                ref={nameInputRef}
                                style={{width: "calc(50% + 40px)"}}
                                value={formik.values.nameLanguages?.map(({languageId, localizedValue}) => ({
                                    id: languageId,
                                    value: localizedValue
                                }))}
                                onChange={(e: any) => {
                                        formik.setFieldValue("name", e || "");
                                }}
                            />

                            <TPCheckBox
                                id="isActive"
                                labelText={m?.[e.FormInputActiveLabel]}
                                checked={formik.values.isActive}
                                onChange={() => formik.setFieldValue("isActive", !formik.values.isActive)}
                            />
                        </form>

                        <Box sx={styles.actions}>
                            <TPButton
                                type={TPButtonTypes.link}
                                onClick={onClickCancelButtonHandler}
                            >
                                {m?.[e.FormActionCancelLabel]}
                            </TPButton>

                            <TPButton
                                type={TPButtonTypes.primary}
                                onClick={onClickSaveButtonHandler}
                            >
                                {m?.[e.FormActionNewProjectLabel]}
                            </TPButton>
                        </Box>
                    </div>
                </div>
            </TPLoadingOverlay>
        </ContentVerticalTabInsertUpdateStyled>
    );
}

const styles = {
    title: {
        textTransform: "none",
    } as CSSProperties,
    form: {
        marginBottom: "20px",
    } as CSSProperties,
    name: {
        marginBottom: "10px",
    } as CSSProperties,
    iconButton: {
        marginTop: "auto",
    } as CSSProperties,
    actions: {
        display: 'flex',
        flexDirection: 'row',
        boxSizing: "border-box",
        placeContent: "flex-end",
        alignItems: "flex-end",
    } as SxProps,
};
