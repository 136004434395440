import { useEffect, useRef, useState } from "react";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import {
    ButomUploadImageStyle,
    ContainerSliderStyle,
    ContainerVerticalDivStyle,
    UploadDivStyle,
    UploadLabelStyle,
} from "./StyleImageAdmin";
import TPGlobal from "@/helpers/TPGlobal";
import FileUploader from "@/components/TPDragAndDropUploadFile/FileUploader";
import { TPI18N } from "@/services/I18nService";
import {
    TPToastTypes,
    showToast,
} from "@/components/bootstrap/components/toasts/TPToast";
import TPButton from "../../components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes, TPIconTypes } from "../../models/Global/TPGlobalEnums";
import TPTextBox from "../../components/bootstrap/forms/textbox/TPTextBox";
import TPCheckBox from "../../components/bootstrap/forms/checkbox/TPCheckBox";
import { LoaderRing, LoaderWrapper, StyledFileStatus } from "../../modules/core/design-system/design-system-styles";
import TPIcon from "../../components/bootstrap/extend/TPIcons/TPIcon";
import TPLabel from "../../components/bootstrap/forms/TPLabel/TPLabel";
import UploadFile from "./UploadFile";

type ModelFile = {
    activeUpload: boolean;
    handleActive: Function;
};

export const UploadImage: React.FC<ModelFile> = ({ activeUpload, handleActive }) => {
    const componentFileName: string = "UploadImageComponent";
    const resourceSet: string = "ImagesAdminComponent";
    const resourceSetGeneral: string = "General";
    const resourceSetUpload: string = "DragFileModal";
    const resourceSetAdmin: string = "ImagesAdmin";
    // Label

    const [UploadLabel, setUploadLabel] = useState("");
    const [maxSizeLabel, setMaxSizeLabel] = useState("");
    const [nameFileLabel, setNameFileLabel] = useState("");
    const [keywordsLabel, setKeywordsLabel] = useState("");

    const [uploadButtonTextLabel, setUploadButtonTextLabel] = useState("");
    const [imageAlertNotAllowed, setImageAlertNotAllowed] = useState("");
    const [errorNameLabel, setErrorNameLabel] = useState("");
    const [inputDTORequired, setInputDTORequired] = useState("");
    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [isActivelabel, setIsActivelabel] = useState("");
    const [titleFile, setTitleFile] = useState("");
    const [fileUpload, setFileUpload] = useState<File | null>();

    const [activeFile, setActiveFile] = useState(false);

    const [imageWeightIsNotAllowed, setImageAlertWeightIsNotAllowedd] =
        useState("");

    const [isLoading, setIsLoading,] = useState("");

    const [fileName, setFileName] = useState("");
    const [keywords, setKeywords] = useState("");
    const [separateWordsWithEnter, setSeparateWordsWithEnter] = useState("");
    const [typeHere, setTypeHere] = useState("");
    const [isActiveFile, setIsActiveFile] = useState(true);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [dragAndDropFileLabels, setdragAndDropFileLabels] = useState();

    const value = useAppConctextImageAdmin();
    const {
        handleSelected,
        selectedItem,
        selectedFolderParent,
        handleUploadImage,
        modeSelect
    } = value;
    const [file, setFile] = useState(null);

    useEffect(() => {
        loadResources();
    }, []);

    const loadResources = async () => {
        setUploadLabel(await TPI18N.GetText(resourceSet, "NameUploadLabel"));
        setUploadButtonTextLabel(
            await TPI18N.GetText(resourceSetGeneral, "UploadFileButtonLabel"),
        );
        setImageAlertNotAllowed(
            await TPI18N.GetText(resourceSet, "ImageAlertNotAllowed"),
        );
        setImageAlertWeightIsNotAllowedd(
            await TPI18N.GetText(resourceSet, "ImageAlertWeightIsNotAllowed"),
        );
        setInputDTORequired(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),
        );
        setSaveButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
        );
        setCancelButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        );
        setNameFileLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "Name"),
        );
        setKeywordsLabel(
            await TPI18N.GetText(resourceSetAdmin, "KeywordsLabel"),
        );
        setIsActivelabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "Active"),
        );
        setTitleFile(
            await TPI18N.GetText(resourceSetAdmin, "TitleFileLabel"),
        );
        setSeparateWordsWithEnter(
            await TPI18N.GetText(resourceSetAdmin, "SeparateWordsWithEnterLabel"),
        );
        setTypeHere(
            await TPI18N.GetText(resourceSetAdmin, "TypeHereLabel"),
        );
        setMaxSizeLabel(
            await TPI18N.GetText(resourceSetUpload, "MaxSize"),
        );
    };

    const handleDrop = (data: any) => {
        if (data) {
            setFileName(data.name);
            setFileUpload(data);
        }
    };

    const ValidateFile = (): boolean => {
        let count = 0;
        if (!fileUpload) return false;

        let file = fileUpload;
        if (file && file.name != undefined && file.name != "") {
            let fileExtension = "";
            if (file.name != undefined)
                fileExtension = `.${file.name.split(".").pop()}`;

            if (fileExtension !== "") {
                let listType = TPGlobal.allowedImageExtensions.split("|");
                let existType = listType.filter(
                    (s) => s.toLocaleLowerCase() == fileExtension.toLocaleLowerCase(),
                );
                if (existType) count = 1;

                const fileSizeInKb = file.size / 1024

                if (TPGlobal.imageWeightAllowed >= fileSizeInKb && count == 1) count = 2;

            }
            if (count == 0) showToast(imageAlertNotAllowed, TPToastTypes.warning);
            if (count == 1) showToast(imageWeightIsNotAllowed, TPToastTypes.warning);
        } else {
            showToast(imageAlertNotAllowed, TPToastTypes.warning);
        }

        return count == 2;
    };

    const handleAcceptOnClick = () => {
        setErrorNameLabel("");
        if (fileName != "") {
            if (fileUpload) {
                let file = fileUpload;
                let fileTempName = fileName;
                let fileKeywords = keywords;
                let isActiveFileTemp = isActiveFile;
                handleUploadImage(file, fileTempName, fileKeywords, isActiveFileTemp, false);
                handleCancelOnClick();
            }
        } else {
            setErrorNameLabel(inputDTORequired);
        }
    };

    const capitalizeFirstLetter = (text: string): string => {
        if (!text) return "";
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    const handleCancelOnClick = () => {
        setErrorNameLabel("");
        setFileName("");
        setKeywords("");
        setIsActiveFile(true);
        setFileUpload(null);
        if (handleActive) handleActive(false);

    };

    const GetTypeImage = (): string[] => {
        let list: string[] = [];
        if (TPGlobal.allowedImageExtensions) {
            list = TPGlobal.allowedImageExtensions.split("|");
        }
        return list;
    };

    return (
        <>
            {activeUpload ? (
                <ContainerSliderStyle
                    style={{ paddingRight: "0px", position: "fixed" }}
                >
                    <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div style={{
                            backgroundColor: "white",
                            padding: "32px",
                            borderRadius: "5px",
                            height: "auto",
                            width: "768px",
                            display: "flex",
                            gap: "24px",
                        }} className="modal-content">
                            <div className="modal-header justify-content-between">
                                <TPLabel
                                    labelText={capitalizeFirstLetter(titleFile)}
                                    style={{
                                        fontSize: "20px",
                                        color: "rgba(160, 0, 149, 1)",
                                        fontWeight: "700"
                                    }}
                                />
                            </div>
                            <div className="modal-body"
                                style={{
                                    paddingTop: "1.5px",
                                    gap: "24px",
                                    display: "flex",
                                    flexDirection: "column"
                                }}>



                                <TPTextBox
                                    id="id-name-file-upload"
                                    isMandatory={true}
                                    labelText={nameFileLabel}
                                    value={fileName}
                                    placeholder={fileName}
                                    onChange={(e: any) => {
                                        setFileName(e.target.value);
                                    }}
                                    containerStyle={{ with: "90%" }}
                                    maxLength={100}
                                    disabled={false}
                                    errorMessage={errorNameLabel}
                                />
                                {/* placeholder={typeHere}*/}


                                <TPTextBox
                                    id="id-keywords-file-upload"
                                    isMandatory={false}
                                    labelText={keywordsLabel}
                                    value={keywords}
                                    onChange={(e: any) => {
                                        setKeywords(e.target.value);
                                    }}
                                    containerStyle={{ with: "90%" }}
                                    maxLength={100}
                                    placeholder={separateWordsWithEnter}
                                    errorMessage={""}

                                />


                                <UploadFile
                                    accept={GetTypeImage()}
                                    selectFileLabel={uploadButtonTextLabel}
                                    maxMBFileSize={Math.floor(TPGlobal.imageWeightAllowed / 1024)}
                                    style={{ marginTop: "0px", marginBottom: "0px" }}
                                    onFilesChange={(e: any) => handleDrop(e)}
                                    onCancelChange={
                                        () => {
                                            setFileName("");
                                            setErrorNameLabel("");
                                            setKeywords("");
                                            setFileUpload(null);
                                        }
                                    }
                                />


                                <div>
                                    <TPCheckBox
                                        id="activeFile"
                                        labelText={isActivelabel}
                                        checked={isActiveFile}
                                        onChange={(e: any) => { setIsActiveFile(!isActiveFile); }}
                                    ></TPCheckBox>
                                </div>

                            </div>

                            <div className="modal-footer">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            gap: "20px",
                                            alignItems: "center",

                                        }}
                                    >
                                        <  TPButton
                                            type={TPButtonTypes.empty}
                                            style={{
                                                border: "1px solid rgb(120, 0, 150)",
                                                color: "rgb(120, 0, 150)",
                                                padding: "5px",
                                                minWidth: "79px"
                                            }}
                                            onClick={() => handleCancelOnClick()}
                                        >
                                            {cancelButtonLabel}
                                        </TPButton>

                                        <TPButton
                                            type={TPButtonTypes.primary}
                                            isDesignSystem={true}
                                            onClick={() => handleAcceptOnClick()}
                                            disabled={(!ValidateFile())}
                                            style={{
                                                padding: "5px",
                                                minWidth: "65px"
                                            }}
                                        >
                                            {saveButtonLabel}
                                        </TPButton>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContainerSliderStyle >

            ) : null}


        </>
    );
};

export default UploadImage;
