import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { showToast, TPToastTypes, } from "@/components/bootstrap/components/toasts/TPToast";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPPageAcceptCancelButtonsContainer, TPPageTitle, } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { commandsEnum as event } from "@/layouts/VerticalTabs/VerticalTabsAdminContainer";
import { SequenceGeneratorSequencesNameEnum, SystemParametersEnum, TPActiveOptions, TPButtonTypes, } from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { ParametersViewModel } from "@/models/Parameters/ParametersModels";
import { StoreModel } from "@/redux/store";
import { AdditionalDataFormService } from "@/services/AdditionalDataFormService";
import { ParametersService } from "@/services/ParametersService";
import { SequenceService } from "@/services/SequenceService";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    FormDesignerExportImportFormSlice,
    FormDesignerExportImportFormStateModel,
} from "./_redux/FormDesignerExportImportFormSlice";
import FormDesignerExportImportFormInfo from "./FormDesignerExportImportFormInfo";
import FormDesignerExportImportFormTable from "./FormDesignerExportImportFormTable";
import e from "./models/FormDesignerExportImportFormEnum";

const { execExport, execImport } = new AdditionalDataFormService();

export interface ColumnModel {
    id?: string | number;
    label: string;
    colspan: number;
    readOnly?: boolean;
}

interface FormDesignerExportImportFormProps {
    tabId: string;
    element?: any;
    mode?: string;
    dispatchAction: Function;
}

export default function FormDesignerExportImportForm({
    tabId,
    element,
    dispatchAction,
    mode
}: FormDesignerExportImportFormProps) {
    const [types, setTypes] = useState<Array<ParametersViewModel>>([]);
    const dispatch = useDispatch();

    const { message: m, result: FormDesignerExportImportFormResult } =
        useSelector(
            (s: StoreModel) => s[FormDesignerExportImportFormSlice.name],
        ) as FormDesignerExportImportFormStateModel;

    const [languages, setLanguages] = useState<Array<LanguagesViewModel>>(
        TPGlobal.TPClientAvailableLanguages,
    );
    const [datasource, setDatasource] = useState<any>();
    const [data, setData] = useState<Array<Array<any>>>([]);
    const [columns, setColumns] = useState<Array<Array<ColumnModel>>>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(
            FormDesignerExportImportFormSlice.actions.message([
                e.TitleLabel,
                e.WarningTitleLabel,
                e.WarningDescriptionLabel,
                e.TableColumnIdLabel,
                e.TableColumnDescriptionsLabel,
                e.TableColumnFormTypeIdLabel,
                e.TableColumnLanguageIdsLabel,
                e.TableColumnFormTypeDescriptionsLabel,
                e.TableColumnJsonDesignerUT8Label,
                e.TableColumnLogoUT8Label,
                e.TableColumnApplyPersonalDataLabel,
                e.TableColumnIsActiveLabel,
                e.OkButtonLabel,
                e.NotificationDescriptionMessage,
                e.NotificationTypeMessage,
                e.NotificationTypeInvalidMessage,
                e.NotificationJsonRequiredMessage,
                e.NotificationJsonInvalidMessage,
                e.NotificationApplyPersonalDataInvalidMessage,
                e.NotificationIsActiveInvalidMessage,
            ]),
        );

        let { getByParentIdAndFilterIsActive } = new ParametersService();
        getByParentIdAndFilterIsActive(SystemParametersEnum.S_FORMTYPE, TPActiveOptions.ACTIVE.toString(), false, true, [200])
            .then(response => setTypes(response as Array<ParametersViewModel>))
            .catch(error => console.error(error));

        if (element) {
            load();
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (FormDesignerExportImportFormResult?.message) {
            showToast(
                FormDesignerExportImportFormResult.message,
                FormDesignerExportImportFormResult?.error
                    ? TPToastTypes.error
                    : TPToastTypes.success,
            );
        }
    }, [FormDesignerExportImportFormResult]);

    function load() {
        setLoading(true);
        execExport(element!.id)
            .then((response) => {
                setLoading(false);
                setDatasource(response);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    }

    async function onClickSaveButtonHandler() {
        setLoading(true);

        const l = languages.length;
        const descriptions = data[0]
            .slice(0, l)
            .map((v, i) => ({
                languageId: columns[1][i].id,
                localizedValue: v,
                order: i,
            }))
            .filter(({ localizedValue }) => !!localizedValue?.trim())

        if (descriptions.length === 0) {
            setLoading(false);
            showToast(m?.[e.NotificationDescriptionMessage], TPToastTypes.warning);
            return;
        }

        const languageIds = datasource?.languageIds ?? [{ languageId: TPGlobal.TPClientDefaultLanguage, localizedValue: TPGlobal.TPClientDefaultLanguage, order: 0 }]

        const formTypeId = data[0][l];

        if (!formTypeId) {
            setLoading(false);
            showToast(m?.[e.NotificationTypeMessage], TPToastTypes.warning);
            return;
        }

        if (types.find(({ id }) => `${id}`.toUpperCase() === `${formTypeId}`.toUpperCase()) === undefined) {
            setLoading(false);
            showToast(m?.[e.NotificationTypeInvalidMessage], TPToastTypes.warning);
            return;
        }

        let jsonDesignerUtf8 = data[0][l + 1];

        if (!jsonDesignerUtf8) {
            setLoading(false);
            showToast(m?.[e.NotificationJsonRequiredMessage], TPToastTypes.warning);
            return;
        }

        if (jsonDesignerUtf8[0] !== "{" && jsonDesignerUtf8[jsonDesignerUtf8.length - 1] !== "}") {
            setLoading(false);
            showToast(m?.[e.NotificationJsonInvalidMessage], TPToastTypes.warning);
            return;
        }

        try {
            const j = JSON.parse(jsonDesignerUtf8)
        } catch (error) {
            setLoading(false);
            console.error(error);
            showToast(m?.[e.NotificationJsonInvalidMessage], TPToastTypes.warning);
            return
        }

        try {
            jsonDesignerUtf8 = Array.from(TPGlobal.stringToUTF8(jsonDesignerUtf8) ?? []).join(",")
        } catch (error) {
            setLoading(false);
            console.error(error);
        }

        const applyPersonalData = !data[0][l + 2] || `${data[0][l + 2]}`.toLocaleLowerCase() === "false" ? false :
            `${data[0][l + 2]}`.toLocaleLowerCase() === "true" ? true :
                null

        if (applyPersonalData === null) {
            setLoading(false);
            showToast(m?.[e.NotificationApplyPersonalDataInvalidMessage], TPToastTypes.warning);
            return
        }

        const isActive = !data[0][l + 3] || `${data[0][l + 3]}`.toLocaleLowerCase() === "false" ? false :
            `${data[0][l + 3]}`.toLocaleLowerCase() === "true" ? true :
                null

        if (applyPersonalData === null) {
            setLoading(false);
            showToast(m?.[e.NotificationIsActiveInvalidMessage], TPToastTypes.warning);
            return
        }

        const { generalAutomaticId } = new SequenceService();
        const response = await generalAutomaticId(false, true, [200], SequenceGeneratorSequencesNameEnum.SQADFO);
        const id = response.responseData.data[0].sequenceCode;

        const element: any = {
            id,
            descriptions,
            languageIds,
            formTypeId: formTypeId.toUpperCase(),
            jsonDesignerUtf8,
            applyPersonalData,
            isActive,
        };

        execImport(element)
            .then((response) => {
                setLoading(false);
                dispatchAction({ type: event.reloadGrid });
                dispatchAction({ type: event.vertical_tab_close, payload: tabId });
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    }

    return (
        <TPLoadingOverlay active={loading}>
            <div className="row">
                <div className="col-11">
                    <TPPageTitle>{m?.[e.TitleLabel]}</TPPageTitle>
                    <hr />

                    <FormDesignerExportImportFormInfo />

                    <FormDesignerExportImportFormTable
                        datasource={datasource}
                        data={data}
                        setData={setData}
                        columns={columns}
                        setColumns={setColumns}
                        languages={languages}
                    />
                    <TPPageAcceptCancelButtonsContainer>
                        {mode == "IMPORT" &&
                            <TPButton
                                type={TPButtonTypes.primary}
                                onClick={onClickSaveButtonHandler}
                            >
                                {m?.[e.OkButtonLabel]}
                            </TPButton>
                        }
                    </TPPageAcceptCancelButtonsContainer>
                </div>
            </div>
        </TPLoadingOverlay>
    );
}
