import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { copyToClipboard } from "@/helpers/ClipboardManager";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  InboundTaskLabels,
  SentimentData,
  TrackTaskBase,
} from "@/models/EmailTemplates/EmailTemplateModels";
import { ActionTypeEnum, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
  ReplacementTranslationObject,
  TaskViewModel,
} from "@/models/Task/TaskModels";
import {
  InboundMailData,
  SentimentSentence,
} from "@/models/Task/TaskReportInputDTO";
import SearchSelect from "@/modules/core/design-system/selects/SearchSelect";
import {
  StyledEmailTask,
  StyledIAPopover,
  StyledKeyPointsPopOver,
  StyledToggle,
} from "@/pages/CaseViewer/case-viewer-styles";
import { TPI18N } from "@/services/I18nService";
import { TaskService } from "@/services/TaskService";
import { FormControlLabel, Typography } from "@mui/material";
import React, { FC, ReactElement, useEffect, useState } from "react";
import PreviewEmailModal from "./PreviewEmailModal";
import SentimentAnalysisSection, {
  Sentiment,
  SentimentsPercentage,
} from "./Sentiments/SentimentAnalysisSection";

enum taskType {
  receivedMail = "S_RECEMA",
}

type EmailToggle = {
  label: string;
  type: "translate" | "original";
  onchange: (e: any) => void;
};

type TranslateSelect = {
  key: string;
  placeholder: string;
  value: TPKeyValue | null;
  options: TPKeyValue[];
};

type InboundEmailViewProps = {
  inboundEmail: InboundMailData;
  currentTask: TaskViewModel | null;
  sentimentData: SentimentData;
  mailboxId: number;
  setLoading: Function;
  refreshTask: Function;
};

type TranslationItems = {
  subject: string;
  body: string;
};

const InboundEmailView: FC<InboundEmailViewProps> = React.memo(
  ({
    inboundEmail,
    currentTask,
    sentimentData,
    mailboxId,
    setLoading,
    refreshTask,
  }): ReactElement => {
    const component: string = "InboundEmailView";

    const taskService = new TaskService();

    const [resources, setResources] = useState<InboundTaskLabels>(
      {} as InboundTaskLabels
    );
    const [resourcesLoaded, setResourcesLoaded] = useState<boolean>(false);
    const [showOriginalEmail, setShowOriginalEmail] = useState<boolean>(false);
    const [isSentimentEnabled, setIsSentimentEnabled] =
      useState<boolean>(false);
    const [showTranslationControls, setShowTranslationControls] =
      useState<boolean>(false);
    const [showEmailView, setShowEmailView] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [fromLanguage, setFromLanguage] = useState<TPKeyValue | null>(null);
    const [toLanguage, setToLanguage] = useState<TPKeyValue | null>(null);
    const [translationLanguages, setTranslationLanguages] = useState<
      TPKeyValue[]
    >([]);
    const [translationItems, setTranslationItems] = useState<TranslationItems>({
      subject: "",
      body: "",
    });

    const panelOpen: boolean = Boolean(anchorEl);

    const emailToggles: EmailToggle[] = [
      {
        label: resources.translateToggle,
        type: "translate",
        onchange: (e: any) => setShowTranslationControls(e.target.checked),
      },
      {
        label: resources.originalToggle,
        type: "original",
        onchange: (e: any) => setShowOriginalEmail(e.target.checked),
      },
    ];

    const translateSelects: TranslateSelect[] = [
      {
        key: "from",
        placeholder: resources.from,
        value: fromLanguage,
        options: translationLanguages,
      },
      {
        key: "to",
        placeholder: resources.to,
        value: toLanguage,
        options: translationLanguages,
      },
    ];

    const loadUtilsResources = async () => {
      setResources({
        translateToggle: await TPI18N.GetText(component, "TranslateToggle"),
        originalToggle: await TPI18N.GetText(component, "OriginalToggle"),
        from: await TPI18N.GetText(component, "From"),
        to: await TPI18N.GetText(component, "To"),
        translateButton: await TPI18N.GetText(component, "TranslateButton"),
        viewKeyPoints: await TPI18N.GetText(component, "ViewKeyPoints"),
        sentiment: {
          title: await TPI18N.GetText("SentimentAnalysis", "Title"),
          positive: await TPI18N.GetText("SentimentAnalysis", "Positive"),
          neutral: await TPI18N.GetText("SentimentAnalysis", "Neutral"),
          negative: await TPI18N.GetText("SentimentAnalysis", "Negative"),
          textAnalysis: await TPI18N.GetText(
            "SentimentAnalysis",
            "TextAnalysis"
          ),
        },
        previewModal: {
          titlePreview: await TPI18N.GetText("PreviewModal", "Title"),
          inboundAlert: await TPI18N.GetText("PreviewModal", "InboundAlert"),
          outboundAlert: await TPI18N.GetText("PreviewModal", "OutboundAlert"),
          close: await TPI18N.GetText("PreviewModal", "Close"),
          saveAndSend: await TPI18N.GetText("PreviewModal", "SaveAndSend"),
          replace: await TPI18N.GetText("PreviewModal", "Replace"),
          cancel: await TPI18N.GetText("PreviewModal", "Cancel"),
        },
      });
    };

    const handleOpenPanel = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClosePanel = () => {
      setAnchorEl(null);
    };

    const getKeyPoints = (): any[] => {
      const keyPointsObject = JSON.parse(inboundEmail.tpGenAIKeyPoints);

      return keyPointsObject ? keyPointsObject.items : [];
    };

    const handleSelectChange = (item: TPKeyValue, key: string) => {
      if (key === "from") setFromLanguage(item);
      if (key === "to") setToLanguage(item);
    };

    const isTranslationAvailable = (): boolean => {
      return !(fromLanguage && toLanguage);
    };

    const encryptValue = (value: string): string => {
      return TPGlobal.stringToUTF8(value).toString();
    };

    const translateEmail = async (): Promise<void> => {
      const subject: string = encryptValue(inboundEmail.subject);
      const body: string = encryptValue(inboundEmail.htmlBody);
      const from: string = fromLanguage?.key ?? "";
      const to: string = toLanguage?.key ?? "";

      const taskData: TrackTaskBase = {
        caseId: currentTask?.caseId ?? 0,
        taskId: currentTask?.id ?? 0,
        inboundMailboxReadID: mailboxId,
        guid_USER: TPGlobal.currentUserGuid,
      };

      try {
        setLoading(true);

        const subjectTranslated = await taskService.translateEmail(
          from,
          to,
          subject,
          taskData,
          "mailsubject"
        );
        const bodyTranslated = await taskService.translateEmail(
          from,
          to,
          body,
          taskData
        );

        setTranslationItems({
          subject: subjectTranslated.translatedText,
          body: bodyTranslated.translatedText,
        });

        setShowEmailView(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        TPLog.Log(
          `Error ${component} translateEmail ex`,
          TPLogType.ERROR,
          error
        );
      }
    };

    const replaceTranslation = async (): Promise<any> => {
      const replacementData: ReplacementTranslationObject = {
        caseId: currentTask?.caseId ?? 0,
        taskId: currentTask?.id ?? 0,
        userId: TPGlobal.currentUserGuid,
        translatedLanguageFrom: fromLanguage?.key ?? "",
        translatedLanguageTo: toLanguage?.key ?? "",
        subjectTranslated: translationItems.subject,
        htmlBodyTranslated: translationItems.body,
      };

      try {
        setLoading(true);

        const response =
          await taskService.replaceEmailTranslation(replacementData);

        setLoading(false);
        return response;
      } catch (error) {
        setLoading(false);
        TPLog.Log(
          `Error ${component} replaceTranslation ex`,
          TPLogType.ERROR,
          error
        );
      }
    };

    const handleReplaceTranslation = (): void => {
      replaceTranslation().then(() => {
        refreshTask(ActionTypeEnum.Reassign);
      });
    };

    const handleSwitchSentiment = (e: any, checked: boolean) => {
      setIsSentimentEnabled(checked);
    };

    const calculateSentimentPercentages = (): SentimentsPercentage => {
      const sentences: SentimentSentence[] = sentimentData.sentences;

      const initialCount: Record<Sentiment, number> = {
        positive: 0,
        neutral: 0,
        negative: 0,
      };

      const sentimentCount = sentences.reduce((acc, sentence) => {
        const sentiment = sentence.sentiment as Sentiment;
        if (sentiment in acc) {
          acc[sentiment]++;
        }
        return acc;
      }, initialCount);

      const totalSentences = sentences.length;

      if (totalSentences === 0) {
        return {
          positive: "0",
          neutral: "0",
          negative: "0",
        };
      }

      const percentages: SentimentsPercentage = {
        positive: ((sentimentCount.positive / totalSentences) * 100).toFixed(0),
        neutral: ((sentimentCount.neutral / totalSentences) * 100).toFixed(0),
        negative: ((sentimentCount.negative / totalSentences) * 100).toFixed(0),
      };

      return percentages;
    };

    useEffect(() => {
      if (!resourcesLoaded) {
        loadUtilsResources().then(() => {
          if (translationLanguages.length === 0) {
            let languages: TPKeyValue[] =
              TPGlobal.StoryFaiAvailableLanguages.split("|").map((item) => {
                const [key, value] = item.split("$");
                return { key, value };
              });

            setTranslationLanguages(languages);
            setResourcesLoaded(true);
          }
        });
      }
    }, [resourcesLoaded]);

    return (
      <>
        {resourcesLoaded && (
          <>
            <PreviewEmailModal
              status={showEmailView}
              componentLabels={resources.previewModal}
              email={translationItems.body}
              handleModalClose={() => setShowEmailView(false)}
              handleCompleteAction={handleReplaceTranslation}
            />
            <StyledEmailTask>
              {currentTask?.report && (
                <>
                  <div className="header">
                    {emailToggles.map((toggle) => (
                      <>
                        {(currentTask?.taskTypeId === taskType.receivedMail || toggle.type === "original") && (
                          <FormControlLabel
                            onChange={toggle.onchange}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "14px",
                                marginBottom: "1px",
                                userSelect: "none",
                              },
                            }}
                            control={
                              <StyledToggle
                                sx={{
                                  padding: "0",
                                  height: "16px",
                                  width: "29px",
                                  marginRight: "6px",
                                }}
                              />
                            }
                            label={toggle.label}
                          />
                        )}
                      </>
                    ))}
                  </div>
                  {showTranslationControls && (
                    <div className="translate-controls">
                      {translateSelects.map((item) => (
                        <SearchSelect
                          key={item.key}
                          options={item.options}
                          optionSelected={item.value}
                          placeholder={item.placeholder}
                          handleChange={(e: TPKeyValue) =>
                            handleSelectChange(e, item.key)
                          }
                          width="120px"
                        />
                      ))}

                      <TPButton
                        id="translate-email"
                        customType={ButtonCustomType.secondary}
                        onClick={translateEmail}
                        style={{ padding: "0 16px" }}
                        disabled={isTranslationAvailable()}
                        isDesignSystem
                      >
                        {resources.translateButton}
                      </TPButton>
                    </div>
                  )}
                  {showOriginalEmail && (
                    <>
                      <div
                        className="email email-shadow"
                        dangerouslySetInnerHTML={{
                          __html: inboundEmail.htmlBodyFormatted,
                        }}
                      ></div>
                      <p className="text-shadow">...</p>
                    </>
                  )}
                  <div
                    className="email"
                    dangerouslySetInnerHTML={{
                      __html: isSentimentEnabled
                        ? sentimentData.sentimentMail
                        : currentTask?.report,
                    }}
                  ></div>
                  <div className="footer">
                    <SentimentAnalysisSection
                      componentLabels={resources.sentiment}
                      onToggleChange={handleSwitchSentiment}
                      percentages={calculateSentimentPercentages()}
                    />
                    {currentTask?.taskTypeId === taskType.receivedMail && (
                      <div className="key-points-content">
                        <TPButton
                          id="show-key-points"
                          customType={ButtonCustomType.tertiary}
                          onClick={handleOpenPanel}
                          withIcon={TPIconTypes.ia}
                          orientationIcon="right"
                          isDesignSystem
                        >
                          {resources.viewKeyPoints}
                        </TPButton>
                        <StyledIAPopover
                          id="keypoints-panel"
                          open={panelOpen}
                          anchorEl={anchorEl}
                          onClose={handleClosePanel}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                        >
                          <StyledKeyPointsPopOver>
                            <div className="key-points">
                              {getKeyPoints().map((key, i) => {
                                return (
                                  <Typography
                                    sx={{
                                      fontFamily: "Noto Sans",
                                      fontSize: "14px",
                                    }}
                                    key={`key-point-${i}`}
                                  >
                                    {key}
                                  </Typography>
                                );
                              })}
                            </div>
                            <TPIcon
                              iconType={TPIconTypes.clipboard}
                              onClick={() => {
                                copyToClipboard(getKeyPoints().join("\n"));
                                handleClosePanel();
                              }}
                            />
                          </StyledKeyPointsPopOver>
                        </StyledIAPopover>
                      </div>
                    )}
                  </div>
                </>
              )}
            </StyledEmailTask>
          </>
        )}
      </>
    );
  }
);

export default InboundEmailView;
