import { TPChip } from "@/components/TPChip/TPChip";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, { TPModalQuestionState } from "@/layouts/ModalQuestion/TPModalQuestion";
import { QueueModel, QueueTableModel } from "@/models/EventsManager/CreateQueueModels";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { GroupsService } from "@/services/GroupsService";
import React, { useEffect, useImperativeHandle, useState } from "react";
import './assets/Styles.css';
import { useQueuesData } from "./assets/controller";
import useQueueAdminLabels from "./assets/labels";
import { RecordTypes } from "./components/tabs/QueueAdministrationTab";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import { QueueAdminSlice, QueueAdminSliceModel } from "./QueueAdminSlice";
import { showToast, TPToastTypes } from "@/components/bootstrap/components/toasts/TPToast";

const componentFileName = "QueueAdminHome.tsx";

interface TabsCallbackAdditionalData {
  recordId: string | number;
  languageId: string | number;
  recordDescription: string;
}
interface TabsCallbackCommand {
  command: 'new' | 'update';
  payload?: TabsCallbackAdditionalData;
  recordId?: any;
  languageId?: any;
  recordDescription?: any;
}
interface QueueAdminHomeProperties {
  tabCallbackCommands: (command: TabsCallbackCommand) => void
}
const QueueAdminHome = React.forwardRef(({ tabCallbackCommands }: QueueAdminHomeProperties, ref) => {
  const { labels } = useQueueAdminLabels();
  const [contentLoaded, setContentLoaded] = useState<boolean>(false);
  const { queuesLoaded, setQueuesLoaded, queues } = useQueuesData();
  const [filteredQueuesData, setFilteredQueuesData] = useState<QueueModel[]>([]);
  let modalQuestionInitialState: TPModalQuestionState = {
    isShown: false,
    callBackData: {},
  };
  const [modalQuestionState, setModalQuestionState] = useState(modalQuestionInitialState);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    refreshGridFromParent() {
      setContentLoaded(false);
      setQueuesLoaded(false);
      dispatch(QueueAdminSlice.actions.setQueuesLoaded(false));
    }
  }))

  const handleDeleteClick = (id: string, groupIdDisplay: string) => {
    let newModalQuestionState: TPModalQuestionState;
    newModalQuestionState = { ...modalQuestionState };
    newModalQuestionState.isShown = true;
    newModalQuestionState.callBackData = { recordId: id, groupIdDisplay };
    setModalQuestionState(newModalQuestionState);
  };

  const handleCallBackModal = async (
    confirmDelete: boolean,
    callBackData: any
  ) => {
    let expectedCodes: Array<number> = [200];
    let serviceClient = new GroupsService();
    let newModalQuestionState: TPModalQuestionState;
    newModalQuestionState = { ...modalQuestionState };
    newModalQuestionState.isShown = false;
    newModalQuestionState.callBackData = {};
    setModalQuestionState(newModalQuestionState);
    if (confirmDelete) {
      try {
        setContentLoaded(false);
        const r = await serviceClient.deleteGroupById(
          callBackData.recordId,
          false,
          false,
          expectedCodes
        );
        if (r.responseData.responseCode === 200) {
          showToast(labels.QueueDeleted, TPToastTypes.success);
        } else {
          showToast(labels.QueueNotDeleted, TPToastTypes.error);
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} handleCallBackModal ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} handleCallBackModal ex`);
      } finally {
        setContentLoaded(false);
        setQueuesLoaded(false);
      }
    }
  };

  useEffect(() => {
    if (queuesLoaded) {
      setFilteredQueuesData(queues);
      setContentLoaded(true);
    }
  }, [queuesLoaded])

  return (
    <>
      <TPModalQuestion
        id="IdModalQuestion"
        title={labels.DeleteModalTitle}
        yesLabel={labels.Yes}
        noLabel={labels.No}
        question={labels.DeleteRecordConfirm?.replace(
          "{recordId}",
          modalQuestionState.callBackData.groupIdDisplay
        )}
        callBackData={modalQuestionState.callBackData}
        isShown={modalQuestionState.isShown}
        callBackAnswer={handleCallBackModal}
      />
      <TPLoadingOverlay active={!queuesLoaded && !contentLoaded}>
        <div className="row">
          <div className="col">
            <CIMTitleSection>
              <TPPageTitle style={{ margin: 0 }}>{labels?.QueueAdmin}</TPPageTitle>
              <TPButton
                onClick={() => {
                  tabCallbackCommands({ command: 'new' })
                }}
                type={TPButtonTypes.primary}
                style={{ paddingTop: '11px', paddingBottom: '11px', paddingLeft: '16px', paddingRight: '16px' }}
                isDesignSystem
              >
                <label style={{ display: 'flex', gap: '8px' }}><TPIcon iconType={TPIconTypes.add} /> {labels?.AddQueue}</label>
              </TPButton>
            </CIMTitleSection>
            <DynamicTable
              id="queues"
              data={filteredQueuesData.map(data => {
                return {
                  groupId: data.groupId,
                  name: data.name,
                  enventGroupType: data.enventGroupType,
                  clasificationOption: data.clasificationOption,
                  additionData: data.additionData,
                  isActive: data.isActive,
                  people: data.people
                } as QueueTableModel
              })}
              withPreferences
              minorOptions={[
                {
                  key: labels.Update,
                  onOptionChange: (item) => tabCallbackCommands({
                    command: 'update',
                    recordId: item.groupId,
                    recordDescription: item.name
                  }),
                  type: 'edit',
                  icon: TPIconTypes.edit
                },
                {
                  key: labels.Delete,
                  onOptionChange: (item) => handleDeleteClick(item.groupId, item.name),
                  type: 'delete',
                  icon: TPIconTypes.delete
                },
              ]}
              columnNames={{
                additionData: labels.AdditionalData,
                clasificationOption: labels.ClassificationOptions,
                enventGroupType: labels.Workmode,
                groupId: labels.ID,
                isActive: labels.Active,
                name: labels.Name,
                people: labels.People
              }}
              columnStyles={{
                groupId: ({ item, value }) => (
                  <button
                    style={{
                      border: 'none',
                      background: 'none'
                    }}
                    onClick={() => tabCallbackCommands({
                      command: 'update',
                      recordId: item.groupId,
                      recordDescription: item.name
                    })}
                  >
                    <b><u style={{ color: '#780096' }}>{value}</u></b>
                  </button>
                ),
                enventGroupType: ({ item, value }) => {
                  if (value === RecordTypes.front) {
                    return <TPChip backgroundColor="#E2D7FF" label={labels.FrontOffice} />
                  } else if (value === RecordTypes.back) {
                    return <TPChip backgroundColor="#B1EFF2" label={labels.BackOffice} />
                  } else {
                    return <TPChip backgroundColor="#c0c0c0" label={'...'} />
                  }
                },
                clasificationOption: ({ item, value }) => (
                  <TPChip
                    backgroundColor={value === 'Yes' ? "#B1F2D7" : "#FFD7DD"}
                    label={value === 'Yes' ? labels.Yes : labels.No}
                  />
                ),
                additionData: ({ item, value }) => (
                  <TPChip
                    backgroundColor={value === 'Yes' ? "#B1F2D7" : "#FFD7DD"}
                    label={value === 'Yes' ? labels.Yes : labels.No}
                  />
                ),
                isActive: ({ item, value }) => (
                  <TPChip
                    backgroundColor={value === 'Yes' ? "#B1F2D7" : "#FFD7DD"}
                    label={value === 'Yes' ? labels.Yes : labels.No}
                  />
                ),
              }}
              onIconClicked={(icon) => {
                if (icon == TPIconTypes.loop || icon == TPIconTypes.refresh) {
                  setContentLoaded(false);
                  setQueuesLoaded(false);
                }
              }}
            />
          </div>
        </div>
      </TPLoadingOverlay>
    </>
  )
})
export default QueueAdminHome;