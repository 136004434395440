import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModal from "@/layouts/TPModal/TPModal";
import {
    ActionTypeEnum,
    ModalSizeEnum,
    SystemParametersEnum,
    TPActiveOptions,
    TPButtonTypes,
    taskStatusEnum
} from "@/models/Global/TPGlobalEnums";
import { CalculateTaskResponsibleInputDTO } from "@/models/Task/CalculateTaskResponsibleInputDTO";
import {
    ReassignTaskInputDTO,
    ReassignTaskInputDTOValidator,
} from "@/models/Task/ReassignTaskInputDTO";
import { TaskViewModel } from "@/models/Task/TaskModels";
import {
    TaskRejectInputDTO,
    TaskRejectInputDTOValidator,
} from "@/models/Task/TaskRejectInputDTO";
import {
    TEmpAdditionalDataTaskViewModel
} from "@/models/Task/TaskReportInputDTO";
import { TaskTypeViewModel } from "@/models/TaskType/TaskTypeModels";
import { TPI18N } from "@/services/I18nService";
import { ParametersService } from "@/services/ParametersService";
import { TaskService } from "@/services/TaskService";
import { TaskTypeService } from "@/services/TaskTypeService";
import { UserService } from "@/services/UserService";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TaskFormView from "../../pages/CaseViewer/TaskFormView";
import TPButton from "../bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "../bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "../bootstrap/forms/select/TPSelect";
import TPTextArea from "../bootstrap/forms/textArea/TPTextArea";
import TPAutoComplete from "../bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { TPAddtionalDataUIModes } from "../TPAdditionalData/TPAdditionalDataUImodes";
import TPRenderAdditionalDataSwitch from "../TPAdditionalData/TPRenderAdditionalDataSwitch";
import {
    TPPageTitle
} from "../TPPage/tpPageStyles";
import TPRenderKeyValues from "../TPRenderKeyValues/TPRenderKeyValues";

interface TPYesNoTaskAutomaticInterface {
    caseNumber: number;
    taskIdToView: number;
    mode: string;
    isWorkflowTask: boolean;
    closeTaskCallBack: Function;
    saveTaskCallBack: Function;
    actionsTaskCallBack?: Function;
}

type modalReassignScreenType = {
    newResponsibleGuidUser: Array<TPKeyValue>;
    report: string;
    newResponsibleGuidUserErrorMessage: string;
    reportErrorMessage: string;
    [x: string]: any;
};

type modalRejectScreenType = {
    reasonId: string;
    comments: string;
    reasonIdErrorMessage: string;
    commentsErrorMessage: string;
    [x: string]: any;
};

export const TPYesNoTaskAutomatic = React.forwardRef(
    (
        {
            caseNumber,
            taskIdToView,
            mode,
            isWorkflowTask,
            closeTaskCallBack,
            saveTaskCallBack,
            actionsTaskCallBack,
        }: TPYesNoTaskAutomaticInterface,
        ref,
    ) => {
        //function called from parent
        useImperativeHandle(ref, () => ({
            refreshTaskFromParent() {
                loadTaskInfo();
            },
            canClose() {
                return !hasBeenModified;
            },
            clearHasBeenModified() {
                setHasBeenModified(false);
            },
            refreshAdditionalData: (
                newAdditionaData: Array<TEmpAdditionalDataTaskViewModel>,
            ) => {
                realRefreshAdditionalData(newAdditionaData);
            },
        }));

        //screen loading
        const [isLoadingScreen, setIsLoadingScreen] = useState(true);
        const [isLoadingModalScreen, setIsLoadingModalScreen] = useState(true);
        const componentFileName: string = "TPYesNoTask.tsx";
        const resourceSet = "YesNoTaskComponent";
        const resourceSetReassignModal: string = "ReassignTaskModal";
        const resourceSetRejectModal: string = "RejectTaskModal";
        const resourceSetTaskViewer = "TaskViewerComponent";

        const editorRef = useRef<any>(null);

        const [responsibleLabel, setResponsibleLabel] = useState("");
        const [creationDateLabel, setCreationDateLabel] = useState("");
        const [startDateLabel, setStartDateLabel] = useState("");
        const [dueToDateLabel, setDueToDateLabel] = useState("");
        const [readerLabel, setReaderLabel] = useState("");
        const [reportSummaryLabel, setReportSummaryLabel] = useState("");

        const [
            calculateTaskResponsibleButtonLabel,
            setCalculateTaskResponsibleButtonLabel,
        ] = useState("");
        const [currentTask, setCurrentTask] = useState<TaskViewModel | null>(null);
        const [currentTaskType, setCurrentTaskType] =
            useState<TaskTypeViewModel | null>(null);
        const [hasBeenModified, setHasBeenModified] = useState(false);
        const [currentActionType, setCurrentActionType] = useState<ActionTypeEnum>(
            ActionTypeEnum.Reassign,
        );

        const [autocompleteToOptions, setAutocompleteToOptions] = useState<
            Array<TPKeyValue>
        >([]);

        //top n options for arrow icon
        const [autocompleteToTopNOptions, setAutocompleteToTopNOptions] = useState<
            Array<TPKeyValue>
        >([]);

        //Form View
        const [isFormActive, setIsFormActive] = useState("");
        const [inputTryingToSave, setInputTryingToSave] = useState(false);
        const [formReadyToSave, setFormReadyToSave] = useState(false);

        const [emptyLabel, setEmptyLabel] = useState("");
        const [isShownActionModal, setIsShownActionModal] = useState(false);
        const [toLabel, setToLabel] = useState("");
        const [taskTitleModal, setTaskTitleModal] = useState("");
        const [modalAcceptLabel, setModalAcceptLabel] = useState("");
        const [modalCancelLabel, setModalCancelLabel] = useState("");

        const [commentsLabel, setCommentsLabel] = useState("");
        const [reasonsLabel, setReasonsLabel] = useState("");

        //reassign
        const initialModalReassignScreenState: modalReassignScreenType = {
            newResponsibleGuidUser: [],
            report: "",
            newResponsibleGuidUserErrorMessage: "",
            reportErrorMessage: "",
        };
        const [modalReassignScreenState, setModalReassignScreenState] =
            useState<modalReassignScreenType>(initialModalReassignScreenState);

        //reject
        const initialModalRejectScreenState: modalRejectScreenType = {
            reasonId: "",
            comments: "",
            reasonIdErrorMessage: "",
            commentsErrorMessage: "",
        };
        const [modalRejectScreenState, setModalRejectScreenState] =
            useState<modalRejectScreenType>(initialModalRejectScreenState);

        const [reasonList, setReasonList] = useState<Array<TPKeyValue>>([]);

        //additionalData
        //key: additional data id
        //value: addtional data value
        //value2: other properties
        //value3: should show
        const [additionalDataValues, setAdditionalDataValues] = useState<
            Array<TPKeyValue>
        >([]);
        const childRefArray: any = useRef([]);
        const taskguid: string = uuidv4();

        const [currentPath, setCurrentPath] = useState("");
        const [currentPathErrorMessage, setCurrentPathErrorMessage] = useState("");

        const loadResourcesAndTaskInfo = async () => {
            setResponsibleLabel(
                await TPI18N.GetText(resourceSetTaskViewer, "ResponsibleLabel"),
            );
            setCreationDateLabel(
                await TPI18N.GetText(resourceSetTaskViewer, "CreationDateLabel"),
            );
            setStartDateLabel(
                await TPI18N.GetText(resourceSetTaskViewer, "StartDateLabel"),
            );
            setDueToDateLabel(
                await TPI18N.GetText(resourceSetTaskViewer, "DueToDateLabel"),
            );
            setReaderLabel(
                await TPI18N.GetText(resourceSetTaskViewer, "ReaderLabel"),
            );
            setReportSummaryLabel(
                await TPI18N.GetText(resourceSetTaskViewer, "ReportSummaryLabel"),
            );

            // setSaveButtonLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButtonLabel"));
            setCalculateTaskResponsibleButtonLabel(
                await TPI18N.GetText(
                    TPGlobal.globalResourceSet,
                    "CalculateTaskResponsibleButtonLabel",
                ),
            );

            setToLabel(await TPI18N.GetText(resourceSetReassignModal, "ToLabel"));
            setModalAcceptLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
            );
            setModalCancelLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
            );
            setCommentsLabel(
                await TPI18N.GetText(resourceSetReassignModal, "CommentsLabel"),
            );
            setReasonsLabel(
                await TPI18N.GetText(resourceSetRejectModal, "ReasonsLabel"),
            );
            //get task info by id
            setIsLoadingScreen(true);
            await loadTaskInfo();

            //solve automatic task
            if (currentTask?.taskStatusId != taskStatusEnum.Solved)
            await solveAutomaticTask();
            setIsLoadingScreen(false);
        };

        const loadTaskInfo = async () => {
            let currentTaskElement: TaskViewModel | null;
            let currentTaskTypeElement: TaskTypeViewModel | null;
            try {
                currentTaskElement = await getTaskInforById(taskIdToView);
                if (currentTaskElement) {
                    currentTaskElement.report = TPGlobal.TPReplaceEnter(
                        currentTaskElement.report,
                    );
                    setCurrentTask(currentTaskElement);

                    // Loading task type information
                    currentTaskTypeElement = await getTaskTypeInformationById(
                        currentTaskElement.taskTypeId,
                    );
                    if (currentTaskTypeElement) {
                        setCurrentTaskType(currentTaskTypeElement);
                    } else {
                        //todo logs
                    }

                    if (currentTaskTypeElement && currentTaskTypeElement.formTypeId && currentTaskTypeElement.formTypeId != "") {
                        setIsFormActive(currentTaskTypeElement.formTypeId);
                    }

                } else {
                    //todo logs
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} loadTaskInfo ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${componentFileName} loadTaskInfo ex`);
                setIsLoadingScreen(false);
            }
        };

        const solveAutomaticTask = async () => {
            let taskService = new TaskService();
            let expectedCodes: Array<number> = [200];

            try {
                let responseRequest = await taskService.AutomaticTask(
                    { taskid: taskIdToView, typistGuidUser: TPGlobal.currentUserGuid },
                    false,
                    true,
                    expectedCodes,
                );
                if (responseRequest) {
                    return responseRequest;
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} solveAutomaticTask ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${componentFileName} solveAutomaticTask ex`);
                setIsLoadingScreen(false);
            }
        };

        const getTaskTypeInformationById = async (idTaskType: string) => {
            let taskTypeService = new TaskTypeService();
            let expectedCodes: Array<number> = [200];

            try {
                let responseRequest = await taskTypeService.getTaskTypeById(
                    idTaskType,
                    false,
                    true,
                    expectedCodes,
                );
                if (responseRequest) {
                    return responseRequest;
                } else {
                    //todo logs
                    return null;
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getTaskTypeInforById ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${componentFileName} getTaskTypeInforById ex`);
                return null;
            }
        };

        const getTaskInforById = async (idTask: number) => {
            let taskService = new TaskService();
            let expectedCodes: Array<number> = [200];

            try {
                let responseRequest = await taskService.getTaskById(
                    idTask,
                    false,
                    true,
                    expectedCodes,
                );
                if (responseRequest && responseRequest.length >= 1) {
                    return responseRequest[0];
                } else {
                    //todo logs
                    return null;
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getTaskInforById ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${componentFileName} getTaskInforById ex`);
                return null;
            }
        };

        const handleCloseTask = () => {
            console.log(mode);
            if (mode === "EDIT") {
                if (hasBeenModified) {
                    closeTaskCallBack(taskIdToView, true);
                    return;
                }
            }
            closeTaskCallBack(taskIdToView, false);
        };
        const handleCalculateTaskResponsible = async () => {
            let inputDTO: CalculateTaskResponsibleInputDTO = {
                taskId: taskIdToView,
                typistGuidUser: TPGlobal.currentUserGuid,
            };

            let serviceClient = new TaskService();
            let expectedCodes: Array<number> = [200];
            try {
                let responseRequest = await serviceClient.CalculateTaskResponsible(
                    inputDTO,
                    false,
                    true,
                    expectedCodes,
                );

                if (responseRequest && responseRequest.length >= 1) {
                    let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
                        function (item) {
                            return {
                                key: item.userGuid,
                                value: `${item.firstName} ${item.lastName}`,
                            };
                        },
                    );

                    let newmodalReassignScreenState = { ...modalReassignScreenState };
                    newmodalReassignScreenState.newResponsibleGuidUser =
                        newToKeyValueList;
                    newmodalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
                    setModalReassignScreenState(newmodalReassignScreenState);
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} handleCalculateTaskResponsible ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(
                    `Error ${componentFileName} handleCalculateTaskResponsible ex`,
                );
            }
        };

        const renderCollectMode = () => {
            let jsxElement: any;
            if (!currentTask) {
                return null;
            }
            jsxElement = (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col d-flex justify-content-end">
                            <span
                                style={{ fontSize: "25px", cursor: "pointer" }}
                                onClick={() => handleCloseTask()}
                            >
                                &times;
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div>
                                    <TPPageTitle style={{ margin: "10px 10px 10px 0px" }}>
                                        {currentTask.taskTypeLocalizedDescription}
                                    </TPPageTitle>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
            return jsxElement;
        };

        const renderViewMode = () => {
            let jsxElement: any;
            if (!currentTask) {
                return null;
            }
            jsxElement = (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col d-flex justify-content-end">
                            <span
                                style={{ fontSize: "22px", cursor: "pointer" }}
                                onClick={() => handleCloseTask()}
                            >
                                &times;
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div>
                                    <TPPageTitle style={{ margin: "10px 10px 10px 0px" }}>
                                        {currentTask.taskTypeLocalizedDescription}
                                    </TPPageTitle>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div>{reportSummaryLabel}</div>
                            <div
                                className="form-control"
                                style={{
                                    minHeight: "100px",
                                    maxHeight: "200px",
                                    backgroundColor: "#e9ecef",
                                    overflowY: "auto",
                                }}
                                dangerouslySetInnerHTML={{ __html: currentTask.report }}
                            ></div>
                        </div>
                    </div>
                    {isFormActive == "" &&
                        <div
                            className="row"
                            style={{
                                marginTop: "5px",
                                marginBottom: "5px",
                                borderRadius: "5px",
                            }}
                        >
                            {renderAdditionalDataList(TPAddtionalDataUIModes.View1)}
                        </div>
                    }

                </div>
            );
            return jsxElement;
        };

        const renderAdditionalDataList = (mode: TPAddtionalDataUIModes) => {
            return additionalDataValues.map(function (item) {
                let elementJsx;
                let index: number;
                let value: any;

                index = additionalDataValues.findIndex(
                    (x: TPKeyValue) => x.key == item.key,
                );
                value = additionalDataValues[index].value;
                if (mode == TPAddtionalDataUIModes.Collect) {
                    elementJsx = (
                        <div className="col-6 mb-2" key={"adda" + item.key}>
                            <TPRenderAdditionalDataSwitch
                                guidControl={taskguid}
                                modeUI={mode}
                                defaultValue={value}
                                itemToRender={item}
                                ref={(element: any) => {
                                    childRefArray.current[item.key] = element;
                                }}
                                onChange={(
                                    idControl: any,
                                    newValue: string,
                                    addtionalDataId: string,
                                ) =>
                                    handleChangeAditionalDataValue(idControl, newValue, item.key)
                                }
                            />
                        </div>
                    );
                } else {
                    elementJsx = (
                        <div className="col-6 mb-2" key={"adda" + item.key}>
                            <TPRenderAdditionalDataSwitch
                                guidControl={taskguid}
                                modeUI={mode}
                                defaultValue={value}
                                itemToRender={item}
                                ref={(element: any) => {
                                    childRefArray.current[item.key] = element;
                                }}
                            />
                        </div>
                    );
                }

                return elementJsx;
            });
        };

        const getHeaderValues = () => {
            let headerValues: Array<TPKeyValue> = [];
            if (currentTask) {
                headerValues.push({
                    key: responsibleLabel,
                    value: currentTask?.writerUserName,
                });
                headerValues.push({
                    key: creationDateLabel,
                    value: currentTask?.creationDateFormatted,
                });
                headerValues.push({
                    key: startDateLabel,
                    value: currentTask?.startDateFormatted,
                });
                headerValues.push({
                    key: dueToDateLabel,
                    value: currentTask?.limitDateFormatted,
                    value2: true,
                });
                headerValues.push({
                    key: readerLabel,
                    value: currentTask?.readerUserName,
                });
            }

            return headerValues;
        };

        const renderHeader = () => {
            if (!currentTask) {
                return null;
            }
            return (
                <TPRenderKeyValues
                    items={getHeaderValues()}
                    size={200}
                ></TPRenderKeyValues>
            );
        };

        //handle additional data change
        const handleChangeAditionalDataValue = (
            idControl: string,
            newValue: any,
            additionalDataId: string,
        ) => {
            let newAddtionalDataValues: Array<TPKeyValue> = [...additionalDataValues];
            //mutate state
            for (let i = 0; i <= newAddtionalDataValues.length - 1; i++) {
                if (newAddtionalDataValues[i].key === additionalDataId) {
                    newAddtionalDataValues[i].value = newValue;
                    setHasBeenModified(true);
                    break;
                }
            }
            setAdditionalDataValues(newAddtionalDataValues);
        };

        const realRefreshAdditionalData = (
            newAdditionaDataToCheck: Array<TEmpAdditionalDataTaskViewModel>,
        ) => {
            let newAdditionalDataValues: Array<TPKeyValue> = [
                ...additionalDataValues,
            ];
            let found: boolean = false;
            for (let i = 0; i <= newAdditionaDataToCheck.length - 1; i++) {
                for (let j = 0; j <= newAdditionalDataValues.length - 1; j++) {
                    if (
                        newAdditionaDataToCheck[i].additionalDataId.toUpperCase() ===
                        newAdditionalDataValues[j].key.toUpperCase()
                    ) {
                        newAdditionalDataValues[j].value = newAdditionaDataToCheck[i].value;
                        found = true;
                    }
                }
            }
            if (found) {
                setAdditionalDataValues(newAdditionalDataValues);
            }
        };
        let alreadyLoadReasons: boolean = false;

        const getReasonsDatalist = async () => {
            let parametersService = new ParametersService();
            let expectedCodes: Array<number> = [200];

            try {
                let responseRequest =
                    await parametersService.getByParentIdAndFilterIsActive(
                        SystemParametersEnum.REFORETA,
                        TPActiveOptions.ACTIVE.toString(),
                        false,
                        true,
                        expectedCodes,
                    );
                if (responseRequest) {
                    let newReasonList: Array<TPKeyValue> = responseRequest.map(
                        function (item) {
                            return { key: item.id, value: item.localizedDescription };
                        },
                    );
                    newReasonList.unshift({ key: "", value: "--" });
                    setReasonList(newReasonList);
                } else {
                    //todo logs
                    return null;
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getReasonsDatalist ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${componentFileName} getReasonsDatalist ex`);
                return null;
            }
        };

        const handleCallbackAnserModal = async (confirm: boolean, data: any) => {
            if (confirm) {
                switch (currentActionType) {
                    case ActionTypeEnum.Reassign:
                        let recordInputDTO: ReassignTaskInputDTO = {
                            taskId: taskIdToView,
                            newResponsibleGuidUser:
                                modalReassignScreenState.newResponsibleGuidUser.length > 0
                                    ? modalReassignScreenState.newResponsibleGuidUser[0].key
                                    : "",
                            report: modalReassignScreenState.report,
                        };
                        let inputDTOValidator = new ReassignTaskInputDTOValidator();
                        let resultValidator = inputDTOValidator.validate(recordInputDTO);
                        if (!TPGlobal.TPIsEmpty(resultValidator)) {
                            let newModalReassignScreenState = { ...modalReassignScreenState };
                            var listPropertyNames = Object.keys(resultValidator);
                            if (listPropertyNames) {
                                for (let index = 0; index < listPropertyNames.length; index++) {
                                    const element = listPropertyNames[index];
                                    if (resultValidator[element]) {
                                        newModalReassignScreenState[element + "ErrorMessage"] =
                                            await TPI18N.GetResource(
                                                resultValidator[element] as string,
                                            );
                                    } else {
                                        newModalReassignScreenState[element + "ErrorMessage"] = "";
                                    }
                                }
                            }
                            setModalReassignScreenState(newModalReassignScreenState);
                            return;
                        }

                        if (await reassignTask(recordInputDTO)) {
                            if (actionsTaskCallBack) {
                                actionsTaskCallBack(currentActionType);
                            }
                        }
                        break;
                    case ActionTypeEnum.Reject:
                        let recordInputRejectTaskDTO: TaskRejectInputDTO = {
                            id: taskIdToView,
                            reasonId: modalRejectScreenState.reasonId,
                            comments: modalRejectScreenState.comments,
                            typistGuidUser: TPGlobal.currentUserGuid,
                        };
                        let inputDTORejectTaskValidator = new TaskRejectInputDTOValidator();
                        let resultValidatorRejectTask =
                            inputDTORejectTaskValidator.validate(recordInputRejectTaskDTO);
                        if (!TPGlobal.TPIsEmpty(resultValidatorRejectTask)) {
                            let newModalRejectScreenState = { ...modalRejectScreenState };
                            var listPropertyNamesRejectTask = Object.keys(
                                resultValidatorRejectTask,
                            );
                            if (listPropertyNamesRejectTask) {
                                for (
                                    let index = 0;
                                    index < listPropertyNamesRejectTask.length;
                                    index++
                                ) {
                                    const element = listPropertyNamesRejectTask[index];
                                    if (resultValidatorRejectTask[element]) {
                                        newModalRejectScreenState[element + "ErrorMessage"] =
                                            await TPI18N.GetResource(
                                                resultValidatorRejectTask[element] as string,
                                            );
                                    } else {
                                        newModalRejectScreenState[element + "ErrorMessage"] = "";
                                    }
                                }
                            }
                            setModalRejectScreenState(newModalRejectScreenState);
                            return;
                        }

                        if (await rejectTask(recordInputRejectTaskDTO)) {
                            if (actionsTaskCallBack) {
                                actionsTaskCallBack(currentActionType);
                            }
                        }
                        break;
                }
            }
            setIsShownActionModal(false);
            setModalReassignScreenState(initialModalReassignScreenState);
            setModalRejectScreenState(initialModalRejectScreenState);
        };

        const reassignTask = async (
            inputDTO: ReassignTaskInputDTO,
        ): Promise<boolean> => {
            let serviceClient = new TaskService();
            let expectedCodes: Array<number> = [200];

            try {
                setIsLoadingModalScreen(true);

                let responseRequest = await serviceClient.reassignTaskResponsible(
                    inputDTO,
                    true,
                    true,
                    expectedCodes,
                );
                setIsLoadingModalScreen(false);
                if (responseRequest.responseResult) {
                    return true;
                }
                return true;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} reassignTask ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${componentFileName} reassignTask ex`);
                setIsLoadingModalScreen(false);
                return false;
            }
        };

        const rejectTask = async (
            inputDTO: TaskRejectInputDTO,
        ): Promise<boolean> => {
            let serviceClient = new TaskService();
            let expectedCodes: Array<number> = [200];

            try {
                setIsLoadingModalScreen(true);

                let responseRequest = await serviceClient.rejectTask(
                    inputDTO,
                    true,
                    true,
                    expectedCodes,
                );
                setIsLoadingModalScreen(false);
                if (responseRequest.responseResult) {
                    return true;
                }
                return false;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} rejectTask ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${componentFileName} rejectTask ex`);
                setIsLoadingModalScreen(false);
                return false;
            }
        };

        const handleToChange = (newSelectedValue: Array<TPKeyValue>) => {
            let newmodalReassignScreenState = { ...modalReassignScreenState };
            newmodalReassignScreenState.newResponsibleGuidUser = newSelectedValue;
            newmodalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
            setModalReassignScreenState(newmodalReassignScreenState);
        };

        const handleToOnAutocompleteQuery = async (query: string) => {
            let tasktypeService = new UserService();
            let expectedCodes: Array<number> = [200, 404];

            try {
                //Load users by search
                let responseRequest =
                    await tasktypeService.getActiveUsersBySearchParameter(
                        query,
                        false,
                        true,
                        expectedCodes,
                    );
                let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
                    function (item) {
                        return {
                            key: item.userGuid,
                            value: `${item.name}`,
                        };
                    },
                );
                setAutocompleteToOptions(newToKeyValueList);
                return newToKeyValueList;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(
                    `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
                );
                return [];
            }
        };

        const handleToOnAutocompleteKeyDown = (event: any) => {
            //left arrow 37
            //right arror 39
            //enter 13
            //home 36
            //end  35
            // console.log("hii this is checking searching tpyesnotask");
            // if (
            //   event.keyCode != 37 &&
            //   event.keyCode != 39 &&
            //   event.keyCode != 13 &&
            //   event.keyCode != 35 &&
            //   event.keyCode != 36
            // ) {
            //   setAutocompleteToOptions([]);
            //   let newModalReassignScreenState = { ...modalReassignScreenState };
            //   newModalReassignScreenState.newResponsibleGuidUser = [];
            //   newModalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
            //   setModalReassignScreenState(newModalReassignScreenState);
            // }
            const inputValue = event.target.value;
            if (inputValue.length === 1) {
                handleToOnAutocompleteQuery("");
            }
        };

        const handleAutoCompleteTopNClick = async () => {
            let newTopNOptions: Array<TPKeyValue> = [];
            if (autocompleteToTopNOptions.length === 0) {
                newTopNOptions = await handleToOnAutocompleteQuery("");

                if (newTopNOptions.length >= 1) {
                    //save on cache
                    setAutocompleteToTopNOptions([...newTopNOptions]);
                    setAutocompleteToOptions([...newTopNOptions]);
                    let newModalReassignScreenState = { ...modalReassignScreenState };
                    newModalReassignScreenState.newResponsibleGuidUser = [];
                    newModalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
                    setModalReassignScreenState(newModalReassignScreenState);
                }
            } else {
                //use cached values;
                setAutocompleteToOptions([...autocompleteToTopNOptions]);
                let newModalReassignScreenState = { ...modalReassignScreenState };
                newModalReassignScreenState.newResponsibleGuidUser = [];
                newModalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
                setModalReassignScreenState(newModalReassignScreenState);
            }
        };

        const handleReassignsCommentOnChange = (newValue: string) => {
            let newModalReassignScreenState = { ...modalReassignScreenState };
            newModalReassignScreenState.report = newValue;
            newModalReassignScreenState.reportErrorMessage = "";
            setModalReassignScreenState(newModalReassignScreenState);
        };

        const handleRejectCommentOnChange = (newValue: string) => {
            let newModalRejectScreenState = { ...modalRejectScreenState };
            newModalRejectScreenState.comments = newValue;
            newModalRejectScreenState.commentsErrorMessage = "";
            setModalRejectScreenState(newModalRejectScreenState);
        };

        const handleOnReasonIdChange = (e: any) => {
            let newModalRejectScreenState = { ...modalRejectScreenState };
            newModalRejectScreenState.reasonId = e.target.value;
            newModalRejectScreenState.reasonIdErrorMessage = "";
            setModalRejectScreenState(newModalRejectScreenState);
        };

        useEffect(() => {
            childRefArray.current = [];
            loadResourcesAndTaskInfo();
        }, []);

        return (
            <>
                <TPModal
                    modalState={{
                        titleModal: `${taskTitleModal} - ${currentTask?.taskTypeLocalizedDescription}`,
                        acceptLabel: modalAcceptLabel,
                        cancelLabel: modalCancelLabel,
                        callBackAnswer: handleCallbackAnserModal,
                        callBackData: null,
                        isShown: isShownActionModal,
                        modalWidth: ModalSizeEnum.MODALLG,
                    }}
                >
                    <TPLoadingOverlay active={isLoadingModalScreen}>
                        {currentActionType === ActionTypeEnum.Reassign && (
                            <>
                                <div className="row">
                                    <div className="col-8">
                                        <TPAutoComplete
                                            isMandatory={true}
                                            labelText={toLabel}
                                            onValueChange={handleToChange}
                                            onSearch={(query: string) => {
                                                handleToOnAutocompleteQuery(query);
                                            }}
                                            isLoading={false}
                                            options={autocompleteToOptions}
                                            withIcon={true}
                                            emptyLabel={emptyLabel}
                                            onKeyDown={handleToOnAutocompleteKeyDown}
                                            selected={modalReassignScreenState.newResponsibleGuidUser}
                                            errorMessage={
                                                modalReassignScreenState.newResponsibleGuidUserErrorMessage
                                            }
                                            downArrowClick={handleAutoCompleteTopNClick}
                                        ></TPAutoComplete>
                                    </div>
                                    <div className="col-4 mt-4">
                                        <TPButton
                                            type={TPButtonTypes.primary}
                                            onClick={handleCalculateTaskResponsible}
                                        >
                                            {calculateTaskResponsibleButtonLabel}
                                        </TPButton>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <TPTextArea
                                            labelText={commentsLabel}
                                            isMandatory={true}
                                            onChange={(e: any) =>
                                                handleReassignsCommentOnChange(e.target.value)
                                            }
                                            value={modalReassignScreenState.report}
                                            rows={7}
                                            errorMessage={modalReassignScreenState.reportErrorMessage}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {currentActionType === ActionTypeEnum.Reject && (
                            <>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <TPSelect
                                                isMandatory={true}
                                                labelText={reasonsLabel}
                                                onChange={handleOnReasonIdChange}
                                                dataSource={reasonList}
                                                value={modalRejectScreenState.reasonId}
                                                errorMessage={
                                                    modalRejectScreenState.reasonIdErrorMessage
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <TPTextArea
                                            labelText={commentsLabel}
                                            isMandatory={true}
                                            onChange={(e: any) =>
                                                handleRejectCommentOnChange(e.target.value)
                                            }
                                            value={modalRejectScreenState.comments}
                                            rows={7}
                                            errorMessage={modalRejectScreenState.commentsErrorMessage}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </TPLoadingOverlay>
                </TPModal>
                <TPLoadingOverlay active={isLoadingScreen}>
                    <div>
                        {mode.toUpperCase() === "EDIT"
                            ? renderCollectMode()
                            : renderViewMode()}

                        <TaskFormView
                            additionalDataTask={additionalDataValues}
                            onChangedDatForm={(data: any) => {
                                setAdditionalDataValues(data);
                            }}
                            onChangedReadyForm={(e: any) => { setFormReadyToSave(e); }}
                            formId={isFormActive}
                            inputTryingToSave={inputTryingToSave}
                            modeEdit={mode.toUpperCase()}
                            visible={isFormActive != ""}
                        />
                        <div
                            className="row"
                            style={{
                                border: "1px solid #dee2e6",
                                marginTop: "5px",
                                marginBottom: "5px",
                            }}
                        ></div>

                    </div>
                </TPLoadingOverlay>
            </>
        );
    },
);
