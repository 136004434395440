import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { copyToClipboard } from "@/helpers/ClipboardManager";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ComposeModalLabels } from "@/models/EmailTemplates/EmailTemplateModels";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
    TPGenAIMail2SummaryRequest,
    TPGenAISummaryResponse,
} from "@/models/TPGenAI/TPGenAIModel";
import { StyledDialogContent } from "@/modules/core/design-system/design-system-styles";
import SearchSelect from "@/modules/core/design-system/selects/SearchSelect";
import { TPGenAIService } from "@/services/TPGenAIService";
import { Dialog, DialogProps } from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";
type ComposeEmailModalProps = {
    status: boolean;
    keyPoints: string;
    baseEmail: string;
    componentLabels: ComposeModalLabels;
    handleModalClose: Function;
};

function ComposeEmailModal({
    status,
    keyPoints,
    baseEmail,
    componentLabels,
    handleModalClose,
}: ComposeEmailModalProps): ReactElement {
    const resource: string = "ComposeEmailModal";

    const genAIService = new TPGenAIService();

    const keypointsRef = useRef<HTMLTextAreaElement>(null);
    const instructionsRef = useRef<HTMLTextAreaElement>(null);

    const [open, setOpen] = useState<boolean>(false);
    const [isScreenLoading, setIsScreenLoading] = useState<boolean>(false);
    const [keyPointsText, setKeyPointsText] = useState<string>("");
    const [instructions, setInstructions] = useState<string>("");
    const [composition, setComposition] = useState<string>("");
    const [languages, setLanguages] = useState<TPKeyValue[]>([]);
    const [language, setLanguage] = useState<TPKeyValue | null>(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose: DialogProps["onClose"] = (_event, reason) => {
        if (reason && reason === "backdropClick") return;

        setOpen(false);
        handleModalClose(composition);
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
        isKeypoints: boolean = true
    ) => {
        const value = e.target.value;

        if (isKeypoints) {
            const insert: string =
                keyPointsText === "" && value.trim() !== "" ? `- ${value}` : value;
            setKeyPointsText(insert);
        } else {
            const insert: string =
                instructions === "" && value.trim() !== "" ? `- ${value}` : value;
            setInstructions(insert);
        }
    };

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLTextAreaElement>,
        isKeypoints: boolean = true
    ) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const cursorPosition = e.currentTarget.selectionStart;
            const beforeCursor = isKeypoints ? keyPointsText.slice(0, cursorPosition) : instructions.slice(0, cursorPosition);
            const afterCursor = isKeypoints ? keyPointsText.slice(cursorPosition) : instructions.slice(cursorPosition);
            const updatedContent = `${beforeCursor}\n- ${afterCursor}`;
            isKeypoints ? setKeyPointsText(updatedContent) : setInstructions(updatedContent);

            setTimeout(() => {
                const textarea = isKeypoints ? keypointsRef.current : instructionsRef.current;
                if (textarea) {
                    textarea.setSelectionRange(cursorPosition + 3, cursorPosition + 3);
                    textarea.scrollTop = textarea.scrollHeight;
                }
            }, 0);
        }
    };

    const composeEmail = async (): Promise<TPGenAISummaryResponse> => {
        const composeObject: TPGenAIMail2SummaryRequest = {
            email: baseEmail == "" || !baseEmail ? "-" : baseEmail,
            keypoints: instructions,
            language: language?.key ?? ""
        };

        try {
            setIsScreenLoading(true);
            return genAIService.keyPointsToMail(
                composeObject,
                [200, 404],
                false,
                true
            );
        } catch (error) {
            setIsScreenLoading(false);
            TPLog.Log(`Error ${resource} composeEmail ex`, TPLogType.ERROR, error);
            return {} as TPGenAISummaryResponse;
        }
    };

    const handleComposeEmail = (): void => {
        composeEmail().then((res) => {
            if (res && res.output) {
                setComposition(res.output);
            }
            setIsScreenLoading(false);
        });
    };

    const isCompositionEnable = (): boolean => {
        return instructions.trim() !== "" && language !== null;
    }

    useEffect(() => {
        setOpen(status);
        status ? handleClickOpen() : handleModalClose();
    }, [status]);

    useEffect(() => {
        setKeyPointsText(keyPoints);
    }, [keyPoints]);

    useEffect(() => {
        if (languages.length === 0) {
            let languages: TPKeyValue[] = TPGlobal.TPGenAIAvailableLanguages.map(
                (item) => {
                    return {
                        key: item.languageCode,
                        value: item.languageName,
                    };
                }
            );
            setLanguages(languages);
        }
    });

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{ fontFamily: "Noto Sans" }}
            maxWidth="md"
            aria-labelledby="case-status-dialog"
        >
            <StyledDialogContent>
                <TPLoadingOverlay active={isScreenLoading} isModal>

                </TPLoadingOverlay>
                <div className="email-content" style={{ marginLeft: 0 }}>
                    <div className="outbound-header">
                        <TPIcon iconType={TPIconTypes.ia} />
                        <p className="title">{componentLabels.title}</p>
                    </div>
                    <div className="composition-areas">
                        <div className="area">
                            <TPLabel htmlFor="key-points-area" labelText={componentLabels.keyPoints} />
                            <TPIcon className="copy-text" iconType={TPIconTypes.clipboard} onClick={() => copyToClipboard(keyPointsText)} />
                            <textarea
                                ref={keypointsRef}
                                name="key-points-area"
                                id="key-points-area"
                                className="text-area-out"
                                placeholder={componentLabels.keyPointsPH}
                                value={keyPointsText}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                rows={6}
                            />
                        </div>
                        <div className="area">
                            <TPLabel
                                htmlFor="instruction-area"
                                labelText={componentLabels.composition}
                                isMandatory
                            />
                            <textarea
                                ref={instructionsRef}
                                name="instruction-area"
                                id="instruction-area"
                                className="text-area-out"
                                placeholder={componentLabels.compositionPH}
                                value={instructions}
                                onChange={(e) => handleChange(e, false)}
                                onKeyDown={(e) => handleKeyDown(e, false)}
                                rows={4}
                            />
                        </div>
                        <div className="language-selection">
                            <SearchSelect
                                options={languages}
                                optionSelected={language}
                                handleChange={(e: TPKeyValue) => setLanguage(e)}
                                label={componentLabels.select}
                                placeholder={componentLabels.selectPH}
                                width="344px"
                                orientation="vertical"
                                isMandatory
                            />
                            <TPButton
                                id="process-instructions"
                                onClick={handleComposeEmail}
                                style={{ padding: "0 16px" }}
                                disabled={!isCompositionEnable()}
                                isDesignSystem
                            >
                                {componentLabels.process}
                            </TPButton>
                        </div>
                        <div className="area">
                            <TPLabel
                                htmlFor="composition-result-area"
                                labelText={componentLabels.newComposition}
                            />
                            <TPIcon className="copy-text" iconType={TPIconTypes.clipboard} onClick={() => copyToClipboard(composition)} />
                            <textarea
                                name="composition-result-area"
                                id="composition-result-area"
                                className="text-area-out"
                                placeholder={componentLabels.newCompositionPH}
                                value={composition}
                                onChange={(e) => setComposition(e.target.value)}
                                rows={6}
                            />
                        </div>
                    </div>
                    <div className="actions">
                        <TPButton
                            id="cancel-process"
                            customType={ButtonCustomType.secondary}
                            onClick={() => handleModalClose()}
                            style={{ padding: "0 16px" }}
                            isDesignSystem
                        >
                            {componentLabels.cancel}
                        </TPButton>
                        <TPButton
                            id="replace-email"
                            onClick={handleClose}
                            style={{ padding: "0 16px" }}
                            disabled={composition === ""}
                            isDesignSystem
                        >
                            {componentLabels.pasteAndClose}
                        </TPButton>
                    </div>
                </div>
            </StyledDialogContent>
        </Dialog>
    );
}

export default ComposeEmailModal;
