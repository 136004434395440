import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { forwardRef, useEffect, useState } from "react";

import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { CaseService } from "@/services/CaseService";

import React from "react";
import { ContainerTabStyle, RightRowContainerStyle, TabActiveStyle } from "../FormDesigner/StyleFromDesigner";
import { LocationLanguagesGroupDashboard, LocationNameGlobal, LocationNameTableCase } from "./GroupDashboardLocalization";
import { useAppGroupDashboard } from "./ContextGroupDashboard";
import GroupDashboardTable from "./GroupDashboardAdmin";
import GroupDashboardUserAdmin from "./GroupDashboardUserAdmin";
import TPLabel from "../../components/bootstrap/forms/TPLabel/TPLabel";
import TPAutoComplete from "../../components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { GroupDashboardDataService } from "../../services/GroupDashboardService";


interface InputModel {
    callBackCommands: Function;
}

enum commandsEnum {
    "change_case_number",
    "change_error_message",
}

enum PageEnum {
    user,
    case
}

const GroupDashboard = forwardRef(
    ({ callBackCommands }: InputModel, ref) => {

        const casecontext: any = React.useContext(TPCaseViewerContext);

        const [baseLanguageOptionsSelect, setBaseLanguageOptionsSelect] = useState<TPKeyValue>({ key: "", value: "" });
        const [baseLanguageOptions, setBaseLanguageOptions] = useState<Array<TPKeyValue>>([]);
        const [recordLocalizedModel, setRecordLocalizedModel] = useState(new Array<RecordLocalizedModel>());
        const [listTempGroup, sertListTempGroup] = useState<Array<TPKeyValue>>([]);
        const [autocompleteToOptions, setAutocompleteToOptions] = useState<Array<TPKeyValue>>([]);
        const [selectedAutocompleteTaskTypeOption, setSelectedAutocompleteTaskTypeOption] = useState<Array<TPKeyValue>>([]);
        const [currenCaseManagerName, setCurrenCaseManagerName] = useState("");
        const [erroNewResponsable, setErroNewResponsable] = useState("");


        //Screen resources
        const [location, setLocation] = useState(new LocationNameTableCase);
        const [locationGlobal, setLocationGlobal] = useState(new LocationNameGlobal);
        const [page, setPage] = useState(PageEnum.case);

        const [countCaseData, setCountCaseData] = useState(0);
        const [countUserData, setCountUserData] = useState(0);



        const loadData = async () => {
            let temp = new LocationLanguagesGroupDashboard();
            await temp.handlelocationCase(resourceSet);
            setLocation(temp.getLocationNameTable);

            await temp.locationGlobal(TPGlobal.globalResourceSet);
            setLocationGlobal(temp.getLocationNameGlobal);
        }

        const loadLanguages = () => {

            let i: number;
            let expectedCodes: Array<number> = [200];

            let listLanguages: Array<TPKeyValue> = [];
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: item.name };
                listLanguages.push(keyValueElement)
            }


            let listFormName = [...recordLocalizedModel];
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: RecordLocalizedModel = { languageId: item.id, localizedValue: "", order: i };
                listFormName.push(keyValueElement);
            }

            setRecordLocalizedModel(recordLocalizedModel);

            if (listLanguages) {
                listLanguages.unshift({ key: "", value: "--" });
                let language = TPGlobal.language;
                setBaseLanguageOptions(listLanguages);
            }
        }


        const handleGetGroupList = async (query?: string) => {
            let tasktypeService = new GroupDashboardDataService();
            let expectedCodes: Array<number> = [200, 404];
            setBaseLanguageOptions(new Array<TPKeyValue>);

            if (query == undefined) query = "";
            try {
                //Load users by search
                let responseRequest =
                    await tasktypeService.getActiveGroup(
                        query,
                        false,
                        true,
                        expectedCodes
                    );
       
                let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
                    function (item: any) {
                        return {
                            key: item.userId,
                            value: `${item.userFirstName} ${item.userLastName}`,
                        };
                    }
                );

                setBaseLanguageOptions(newToKeyValueList);
                setLoading(false);
                return newToKeyValueList; // filter
            }
            catch (error) {
                setLoading(false);
                TPLog.Log(
                    `Error ${resourceSet} handleGetGroupList ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${resourceSet} handleGetGroupList ex`
                );
                return [];
            }

          
        };

        const handleSearchButtonClick = async (caseId: number) => {
            let serviceClient = new CaseService();
            let expectedCodes: Array<number> = [200];

            try {
                setLoading(true);
                let isValidCaseNumber: boolean = await serviceClient.checkCaseById(
                    caseId,
                    false,
                    true,
                    expectedCodes
                );
                if (isValidCaseNumber) {
                    casecontext.handleAddNewCaseViewerCallBack(
                        caseId
                    );
                }
                setLoading(false);
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} handleSearchButtonClick ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${resourceSet} handleSearchButtonClick ex`);
                setLoading(false);
            }
        };

        //SearchUser
        const handleToOnAutocompleteQuery = async (query: string) => {
            let tasktypeService = new GroupDashboardDataService();
            let expectedCodes: Array<number> = [200, 404];
            setAutocompleteToOptions(new Array<TPKeyValue>);
            setSelectedAutocompleteTaskTypeOption(new Array<TPKeyValue>);
            if (query == undefined) query = "";
            try {
                //Load users by search
                let responseRequest =
                    await tasktypeService.getActiveGroup(
                        query,
                        false,
                        true,
                        expectedCodes
                    );
                let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
                    function (item: any) {
                        return {
                            key: item.userId,
                            value: `${item.firstNameUser} ${item.lastNameUser}`,
                        };
                    }
                );

                let newToKeyValueList2: Array<TPKeyValue> = responseRequest.map(
                    function (item: any) {
                        return {
                            key: item.userId,
                            value: `${item.id} `,
                        };
                    }
                );

                sertListTempGroup(newToKeyValueList2);
              
                let filter = newToKeyValueList.filter(s => s.value.toString().trim().toLocaleLowerCase() != currenCaseManagerName.trim().toLocaleLowerCase());

                setAutocompleteToOptions(filter);

                return filter
            }
            catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} handleToOnAutocompleteQuery ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${resourceSet} handleToOnAutocompleteQuery ex`
                );
                return [];
            }
        };

        const handleOnAutocompleteKeyDown = (event: any) => {
            //tab 9
            //left arrow 37
            //right arror 39
            //enter 13
            //home 36
            //end  35
            //ArrowUp  38
            //ArrowDown  40
            if (
                event.keyCode != 9 &&
                event.keyCode != 37 &&
                event.keyCode != 39 &&
                event.keyCode != 13 &&
                event.keyCode != 35 &&
                event.keyCode != 36 &&
                event.keyCode != 38 &&
                event.keyCode != 40
            ) {

                setGroupSelected({ key: "", value: "" });
                setAutocompleteToOptions([]);
                setSelectedAutocompleteTaskTypeOption([]);
                handleToOnAutocompleteQuery("");
            }
        };

        const onValueChangeTPAutoComplete = (e: any) => {
            if (e != undefined && e[0] && e.length > 0) {
                setLoading(true);
                setSelectedAutocompleteTaskTypeOption(e);
                let temp = listTempGroup.find(s => s.key == e[0].key);
                if (temp)
                setGroupSelected(temp);
            }
        }



        const value = useAppGroupDashboard();
        const {
            resourceSet,
            setLoading,
            setGroupSelected
        } = value;


        useEffect(() => {
            setLoading(true);
            handleGetGroupList();
            loadData();
        }, []);


        return (
            <>
                <div>
                    <div className="row">
                        <div>
                            <RightRowContainerStyle
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}>
                                <TPPageTitle>{location.title}</TPPageTitle>
                            </RightRowContainerStyle>
                        </div>
                    </div>



                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} >

                        <div style={{
                            width: "auto",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px"
                        }} >


                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px", width: "380px" }}>
                                <TPLabel
                                    isMandatory={false}
                                    labelText={location.groupLabel}
                                />


                                <TPAutoComplete
                                    containerStyle={{ display: "flex", justifyContent: "center", aligItems: "center" }}
                                    isMandatory={true}
                                    labelText={""}
                                    onValueChange={(e: any) => { onValueChangeTPAutoComplete(e); }}
                                    onSearch={(event: any) => { handleToOnAutocompleteQuery(event); }} // handleToOnAutocompleteQuery(event)
                                    isLoading={false}
                                    options={autocompleteToOptions}
                                    withIcon={true}
                                    emptyLabel={""}
                                    onKeyDown={
                                        (event: any) => {
                                            handleOnAutocompleteKeyDown(event)
                                        }
                                    }
                                    selected={
                                        selectedAutocompleteTaskTypeOption
                                    }
                                    errorMessage={erroNewResponsable}
                                    downArrowClick={handleToOnAutocompleteQuery}
                                ></TPAutoComplete>
                            </div>


                        </div>
                    </div>



                    <ContainerTabStyle style={{ height: "44px" }}>
                        <TabActiveStyle
                            style={{ width: "150px" }}
                            onClick={() => {
                                setPage(PageEnum.case);
                            }}
                            activeBorder={page == PageEnum.case} >
                            <TPButton
                                style={{
                                    color: page == PageEnum.case ? "" : "gray"
                                }}
                                iconStyle={{ fontSize: "24px" }}
                                type={TPButtonTypes.empty}
                                text={""}
                                onClick={() => {
                                }}
                                icon={TPIconTypes.playlistAddCheck}
                            >
                                {location.tabCase + " " + countCaseData}
                            </TPButton>

                        </TabActiveStyle>
                        <TabActiveStyle
                            style={{ width: "150px" }}
                            onClick={() => {
                                setPage(PageEnum.user);
                            }}
                            activeBorder={page == PageEnum.user} >

                            <TPButton
                                style={{
                                    color: page == PageEnum.user ? "" : "gray"
                                }}
                                iconStyle={{ fontSize: "18px" }}
                                type={TPButtonTypes.empty}
                                text={""}
                                onClick={() => { }}
                                icon={TPIconTypes.calendar}
                            >
                                {location.tabUser + " " + countUserData}
                            </TPButton>
                        </TabActiveStyle>
                    </ContainerTabStyle>

                    <GroupDashboardTable callBackCount={(e: number) => { setCountCaseData(e) }} active={(page === PageEnum.case)} callBackCommands={handleSearchButtonClick} mode="" recordId="" />

                    <GroupDashboardUserAdmin callBackCount={(e: number) => { setCountUserData(e) }} active={(page === PageEnum.user)} callBackCommands={handleSearchButtonClick} mode="" recordId="" />

                </div>
            </>
        );
    }
);

export default GroupDashboard;
