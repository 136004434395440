import TPGlobal from '@/helpers/TPGlobal';
import { TPHTTPService } from '@/helpers/TPHttpService';
import { TPKeyValue } from '@/helpers/TPKeyValue';
import { TPLog, TPLogType } from '@/helpers/TPLog';
import { ListCloneModel, ListModel, LocalizedValueNoName } from '@/models/ListAdministration/ListAdministrationModels';

export class ListsServices {
	private static serviceFileName = 'ListServices.ts';
	public static async getAllLists(
		showSuccessMessage: boolean,
		showErrorMessage: boolean,
		expectedCodes: number[]
	): Promise<Array<ListModel>> {
		const httpClientInstance = new TPHTTPService();
		const url = TPGlobal.baseAPIGateway + 'api/tenant/' + TPGlobal.tenantHex + '/lists?filterIsActive=2&type=S_EVENTLISTTYPE';
		const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

		try {
			const response = await httpClientInstance.getData(
				url,
				expectedCodes,
				showSuccessMessage,
				showErrorMessage,
				headers
			);
			return response.responseData?.data || [];
		} catch (error) {
			TPLog.Log(`Error ${this.serviceFileName} getAllLists exception`, TPLogType.ERROR, error);
			console.error(error);
			throw new Error(`Unexpected error while trying to get all lists: ${error}`);
		}
	}

	public static async getListById(
		listId: string,
		showSuccessMessage: boolean,
		showErrorMessage: boolean,
		expectedCodes: number[]
	): Promise<ListModel> {
		const httpClientInstance = new TPHTTPService();
		const url = TPGlobal.baseAPIGateway + 'api/tenant/' + TPGlobal.tenantHex + '/lists/' + listId;
		const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

		try {
			const response = await httpClientInstance.getData(
				url,
				expectedCodes,
				showSuccessMessage,
				showErrorMessage,
				headers
			);
			return response.responseData.data[0];
		} catch (error) {
			console.error(error);
			TPLog.Log(`Error ${this.serviceFileName} getListById exception`, TPLogType.ERROR, error);
			throw new Error(`Unexpected error while trying to get all lists: ${error}`);
		}
	}

	public static async createList(
		listBody: ListModel,
		showSuccessMessage: boolean,
		showErrorMessage: boolean,
		expectedCodes: number[]
	): Promise<void> {
		const httpClientInstance = new TPHTTPService();
		const url = TPGlobal.baseAPIGateway + 'api/tenant/' + TPGlobal.tenantHex + '/lists';
		const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

		const fixedBody: ListModel = {
			...listBody,
			items: listBody.items.map(item => {
				return {
					...item,
					descriptionLocalizedValues: item.descriptionLocalizedValues
						.filter(val => val.localizedValue.trim().length > 0)
						.map(value => {
							return {
								languageId: value.languageId,
								localizedValue: value.localizedValue
							} as LocalizedValueNoName;
						})
				};
			}),
			descriptionLocalizedValues: listBody.descriptionLocalizedValues
				.filter(val => val.localizedValue.trim().length > 0)
				.map(value => {
					return {
						languageId: value.languageId,
						localizedValue: value.localizedValue
					} as LocalizedValueNoName;
				})
		};

		try {
			await httpClientInstance.postData(url, fixedBody, expectedCodes, showSuccessMessage, showErrorMessage, headers);
		} catch (error) {
			TPLog.Log(`Error ${this.serviceFileName} createList exception`, TPLogType.ERROR, error);
			console.error(error);
			throw new Error(`Unexpected error while trying to get all lists: ${error}`);
		}
	}

	public static async updateList(
		requestBody: ListModel,
		showSuccessMessage: boolean,
		showErrorMessage: boolean,
		expectedCodes: number[]
	): Promise<void> {
		const httpClientInstance = new TPHTTPService();
		const url = TPGlobal.baseAPIGateway + 'api/tenant/' + TPGlobal.tenantHex + '/lists';
		const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

		const fixedBody: ListModel = {
			...requestBody,
			items: requestBody.items.map(item => {
				return {
					...item,
					descriptionLocalizedValues: item.descriptionLocalizedValues
						.filter(val => val.localizedValue.trim().length > 0)
						.map(value => {
							return {
								languageId: value.languageId,
								localizedValue: value.localizedValue
							} as LocalizedValueNoName;
						})
				};
			}),
			descriptionLocalizedValues: requestBody.descriptionLocalizedValues
				.filter(val => val.localizedValue.trim().length > 0)
				.map(value => {
					return {
						languageId: value.languageId,
						localizedValue: value.localizedValue
					} as LocalizedValueNoName;
				})
		};

		try {
			await httpClientInstance.putData(url, fixedBody, expectedCodes, showSuccessMessage, showErrorMessage, headers);
		} catch (error) {
			TPLog.Log(`Error ${this.serviceFileName} updateList exception`, TPLogType.ERROR, error);
			console.error(error);
			throw new Error(`Unexpected error while trying to get all lists: ${error}`);
		}
	}

	public static async deleteList(
		listId: string,
		showSuccessMessage: boolean,
		showErrorMessage: boolean,
		expectedCodes: number[]
	): Promise<any> {
		const httpClientInstance = new TPHTTPService();
		const url = TPGlobal.baseAPIGateway + 'api/tenant/' + TPGlobal.tenantHex + '/lists/' + listId;
		const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

		try {
			return await httpClientInstance.deleteData(url, expectedCodes, showSuccessMessage, showErrorMessage, headers);
		} catch (error) {
			TPLog.Log(`Error ${this.serviceFileName} deleteList exception`, TPLogType.ERROR, error);
			console.error(error);
			throw new Error(`Unexpected error while trying to get all lists: ${error}`);
		}
	}

	public static async cloneList(
		requestBody: ListCloneModel,
		showSuccessMessage: boolean,
		showErrorMessage: boolean,
		expectedCodes: number[]
	): Promise<void> {
		const httpClientInstance = new TPHTTPService();
		const url = TPGlobal.baseAPIGateway + 'api/tenant/' + TPGlobal.tenantHex + '/lists/clone';
		const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

		try {
			await httpClientInstance.postData(url, requestBody, expectedCodes, showSuccessMessage, showErrorMessage, headers);
		} catch (error) {
			TPLog.Log(`Error ${this.serviceFileName} updateList exception`, TPLogType.ERROR, error);
			console.error(error);
			throw new Error(`Unexpected error while trying to get all lists: ${error}`);
		}
	}
}
