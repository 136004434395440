import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useTranslations from "../hooks/useTranslations";
import TPVerticalTabs from "@/components/TPTabs/TPVerticalTabs";
import TPDescription from "@/components/bootstrap/forms/TPDescription/TPDescription";
import { useConfiguration } from "../context/ConfigurationContext";
import TabLabelWithSwitch from "../common/TabLabelWithSwitch/TabLabelWithSwitch";
import { TPFieldContainer } from "../common/TPFieldContainer/TPFieldContainer";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { AdditionalDataFormService } from "@/services/AdditionalDataFormService";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TreeManagementProps } from "@/pages/ConfigurationValues/ConfigurationValuesNew";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import TPBranchSelection2 from "@/components/TPBranchSelection/TPBranchSelection2";
import { TreeService } from "@/services/TreeService";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import { BranchService } from "@/services/BranchService";
import TPCollapsableSection from "@/components/bootstrap/components/collapsable/TPCollapsableSection";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import FormView from "@/pages/FormDesigner/FormView/FormView";

const COMPONENT_NAME = "UsersComponent.tsx";

const UsersComponent: React.FC = () => {
  const RESOURCE_SET = "ConfigurationGeneralComponent";

  const { changeValueForKey, keyValues } = useConfiguration();

  const initialText = useMemo(
    () => ({
      userAdditionalDataTitle: "UserAdditionalDataTitle",
      userAdditionalDataDescriptions: "UserAdditionalDataDescriptions",
      userAddaFormLabel: "UserAddaFormLabel",
      classifierMappingTitle: "ClassifierMappingTitle",
      classifierMappingDescriptions: "ClassifierMappingDescriptions",
      activeLabel: "ActiveLabel",
      EnableUserTeamTreeLabel: "EnableUserTeamTreeLabel",
      TeamTreeMappingLabel: "TeamTreeMappingLabel",
      SubsetTypeLabel: "SubsetTypeLabel",
      TeamTreeParentBranchLabel: "TeamTreeParentBranchLabel",
      modalAcceptLabel: "ModalAcceptLabel",
      modalCancelLabel: "ModalCancelLabel",
      modalTitleLabel: "ModalTitleLabel",
      emptyLabel: "EmptyLabel",
      EnableUserDepartmentTreeLabel: "EnableUserDepartmentTreeLabel",
      DepartmentTreeMappingLabel: "DepartmentTreeMappingLabel",
      AreaTreeParentBranchLabel: "AreaTreeParentBranchLabel",
      WholeTreeItemLabel: "WholeTreeItemLabel",
      PartialTreeItemLabel: "PartialTreeItemLabel",
      EnableUserTeamTreeDescription: "EnableUserTeamTreeDescription",
      EnableUserDepartmentTreeDescription:
        "EnableUserDepartmentTreeDescription",
      saveButton: "SaveButton",
    }),
    []
  );

  const translationsText = useTranslations(
    initialText,
    RESOURCE_SET,
    COMPONENT_NAME
  );

  const tabLabels = useMemo(
    () => [
      translationsText.userAdditionalDataTitle,
      translationsText.classifierMappingTitle,
    ],
    [translationsText, keyValues, changeValueForKey]
  );

  const tabContent = useMemo(
    () => [
      <UserAdditionalDataConfiguration
        key="userAdditionalData"
        translationsText={translationsText}
      />,
      <ClassifierMappingConfiguration
        key="classifierMapping"
        translationsText={translationsText}
      />,
    ],
    [translationsText]
  );

  return <TPVerticalTabs tabLabels={tabLabels} tabContent={tabContent} />;
};

export default UsersComponent;

const UserAdditionalDataConfiguration: React.FC<{ translationsText: any }> = ({
  translationsText,
}) => {
  const { changeValueForKey, keyValues, errorState, someChange, handleSave } =
    useConfiguration();
  const [additionalDataList, setAdditionalDataList] = useState<
    Array<TPKeyValue>
  >([]);

  const [showPreview, setShowPreview] = useState(false);

  const handlePreview = () => {
    if (keyValues["userAddaForm"]?.value) setShowPreview(true);
  };

  const getAdditonalData = async () => {
    let formService = new AdditionalDataFormService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      let responseRequest = await formService.getByFiltersIsActive(
        "1",
        "S_USERSADD",
        false,
        true,
        expectedCodes
      );
      let newFormListState: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return { key: item.id, value: item.localizedDescription };
        }
      );
      newFormListState.unshift({ key: "", value: "--" });
      setAdditionalDataList(newFormListState);
    } catch (error) {
      TPLog.Log(
        `Error ${COMPONENT_NAME} loadResourcesAndLoadInfo ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${COMPONENT_NAME} loadResourcesAndLoadInfo ex`);
      return;
    }
  };

  useEffect(() => {
    getAdditonalData();
  }, []);

  return (
    <div className="col">
      <TPDescription text={translationsText.userAdditionalDataDescriptions} />

      <TPFieldContainer style={{ marginBottom: "20px" }}>
        <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
          <div style={{ flex: 1 }}>
            <TPSelect
              id="IdSelect"
              labelText={translationsText.userAddaFormLabel}
              isMandatory={true}
              value={keyValues["userAddaForm"]?.value}
              onChange={(e: any) =>
                changeValueForKey("userAddaForm", e.target.value)
              }
              errorMessage={errorState.userAddaFormErrorMessage}
              dataSource={additionalDataList}
            />
          </div>
          <div>
            <TPButton type={TPButtonTypes.link} onClick={handlePreview}>
              Preview
            </TPButton>
          </div>
        </div>
      </TPFieldContainer>

      {showPreview && !!keyValues["userAddaForm"]?.value && (
        <div
          style={{
            backgroundColor: "#f4f6fc",
            padding: "15px",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          {
            <FormView
              onChangedAllAdditionalJsonParameters={() => null}
              onChangedJson={() => null}
              onChangedReadyForm={() => null}
              inputFormId={keyValues["userAddaForm"]?.value}
              inputValueDefault={[]}
              inputTryingToSave={false}
              disable={true}
            />
          }
        </div>
      )}

      <TPButton
        id="IdButton"
        onClick={handleSave}
        type={TPButtonTypes.primary}
        className="fixed-save-button"
        disabled={!someChange}
        style={
          !someChange
            ? {
                backgroundColor: "#e6e6e6",
                borderColor: "#efefef",
                color: "#989898",
                cursor: "not-allowed",
              }
            : {}
        }
      >
        {translationsText.saveButton}
      </TPButton>
    </div>
  );
};

const initialTreeProps: TreeManagementProps = {
  userTreeBranchTeamMapping: "",
  userTreeBranchTeamMappingcurrentBranch: "",
  userTreeBranchTeamMappingcurrentDescriptionBranch: "",
  userTreeBranchTeamMappingdefaultBranchHierarchyDescription: "",
  userTreeBranchTeamMappingSubsetType: "WHOLETREE",
  userTreeBranchAreaMapping: "",
  userTreeBranchAreaMappingcurrentBranch: "",
  userTreeBranchAreaMappingcurrentDescriptionBranch: "",
  userTreeBranchAreaMappingdefaultBranchHierarchyDescription: "",
  userTreeBranchAreaMappingSubsetType: "WHOLETREE",
};

const ClassifierMappingConfiguration: React.FC<{ translationsText: any }> = ({
  translationsText,
}) => {
  const departmentRenderCount = useRef(0);
  const teamRenderCount = useRef(0);

  const [treeProps, setTreeProps] =
    useState<TreeManagementProps>(initialTreeProps);
  const [treeList, setTreeListState] = useState<Array<TPKeyValue>>([]);
  const [enableUserTeamTree, setEnabledUserTeamTree] = useState(false);
  const [enableUserDepartmentTree, setEnabledUserDepartmentTree] =
    useState(false);
  const [collapsedSections, setCollapsedSections] = useState<{
    [key: string]: boolean;
  }>({});

  const {
    changeValueForKey,
    keyValues,
    errorState,
    someChange,
    handleSave,
    setErrorState,
    setIsLoadingScreen,
  } = useConfiguration();

  const treeHandle = useMemo(
    () => [
      { key: "WHOLETREE", value: translationsText.WholeTreeItemLabel },
      { key: "PARTIAL", value: translationsText.PartialTreeItemLabel },
    ],
    [translationsText]
  );

  const getTreeIdList = useCallback(async () => {
    try {
      setIsLoadingScreen(true);
      const serviceClient = new TreeService();
      const responseRequest = await serviceClient.getTreesByFilter(
        TPActiveOptions.ACTIVE.toString(),
        false,
        true,
        [200, 404]
      );

      const newTreeListState: Array<TPKeyValue> = responseRequest.map(
        (tree: TreeViewModel) => ({
          key: tree.id,
          value: tree.localizedName,
        })
      );

      newTreeListState.unshift({ key: "", value: "--" });
      setTreeListState(newTreeListState);
    } catch (error) {
      console.error(`Error ${COMPONENT_NAME} getTreeIdList ex`, error);
    } finally {
      setIsLoadingScreen(false);
    }
  }, [setIsLoadingScreen]);

  const getBranchInfo = async (branchId: string) => {
    let serviceClient = new BranchService();
    let expectedCodes: Array<number> = [200, 404];
    let branchData: BranchViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getBranchById(
        branchId,
        false,
        true,
        expectedCodes
      );
      branchData = responseRequest;
      return branchData;
    } catch (error) {
      TPLog.Log(
        `Error ${COMPONENT_NAME} getBranchInfo ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${COMPONENT_NAME} getBranchInfo ex`);
      return null;
    }
  };

  const initialTreeData = useCallback(async () => {
    try {
      setIsLoadingScreen(true);

      const treeValuesCopy = { ...treeProps };
      let changeState: boolean = false;
      if (
        keyValues?.userTreeBranchAreaMapping &&
        keyValues?.userTreeBranchAreaMapping.value
      ) {
        let userTreeBranchAreaMappingJSON: any = JSON.parse(
          keyValues?.userTreeBranchAreaMapping.value
        );
        if (
          userTreeBranchAreaMappingJSON.ParentCode.toString().indexOf(
            "[Root:"
          ) > -1
        ) {
          treeValuesCopy.userTreeBranchAreaMapping =
            userTreeBranchAreaMappingJSON.TreeCode;
          treeValuesCopy.userTreeBranchAreaMappingSubsetType = "WHOLETREE";
        } else {
          treeValuesCopy.userTreeBranchAreaMappingSubsetType = "PARTIAL";
          treeValuesCopy.userTreeBranchAreaMapping =
            userTreeBranchAreaMappingJSON.TreeCode;
          let branchData: BranchViewModel | null = null;
          branchData = await getBranchInfo(
            userTreeBranchAreaMappingJSON.ParentCode
          );
          if (branchData == null) {
            return false;
          }
          treeValuesCopy.userTreeBranchAreaMappingcurrentBranch = branchData.id;
          treeValuesCopy.userTreeBranchAreaMappingdefaultBranchHierarchyDescription =
            branchData.hierarchyDescription;
        }

        setEnabledUserDepartmentTree(true);
        changeState = true;
      }
      if (
        keyValues?.userTreeBranchTeamMapping &&
        keyValues?.userTreeBranchTeamMapping.value
      ) {
        let userTreeBranchTeamMappingJSON: any = JSON.parse(
          keyValues?.userTreeBranchTeamMapping.value
        );

        if (
          userTreeBranchTeamMappingJSON.ParentCode.toString().indexOf(
            "[Root:"
          ) > -1
        ) {
          treeValuesCopy.userTreeBranchTeamMapping =
            userTreeBranchTeamMappingJSON.TreeCode;
          treeValuesCopy.userTreeBranchTeamMappingSubsetType = "WHOLETREE";
        } else {
          treeValuesCopy.userTreeBranchTeamMappingSubsetType = "PARTIAL";
          treeValuesCopy.userTreeBranchTeamMapping =
            userTreeBranchTeamMappingJSON.TreeCode;
          let branchData: BranchViewModel | null = null;
          branchData = await getBranchInfo(
            userTreeBranchTeamMappingJSON.ParentCode
          );
          if (branchData == null) {
            return false;
          }

          treeValuesCopy.userTreeBranchTeamMappingcurrentBranch = branchData.id;
          treeValuesCopy.userTreeBranchTeamMappingdefaultBranchHierarchyDescription =
            branchData.hierarchyDescription;
        }

        setEnabledUserTeamTree(true);
        changeState = true;
      }
      if (changeState) {
        setTreeProps(treeValuesCopy);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${COMPONENT_NAME} initialTreeData ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${COMPONENT_NAME} initialTreeData ex`);
    } finally {
      setIsLoadingScreen(false);
    }
  }, [keyValues]);

  const handleOnBranchChange = useCallback(
    async (
      branchId: string,
      branchDescription: string,
      branchHierarchyDescription: string,
      keyString: string
    ) => {
      setTreeProps((prevProps) => ({
        ...prevProps,
        [`${keyString}currentBranch`]: branchId,
        [`${keyString}currentDescriptionBranch`]: branchDescription,
        [`${keyString}defaultBranchHierarchyDescription`]:
          branchHierarchyDescription,
      }));

      setErrorState((prevState: any): any => {
        return {
          ...prevState,
          [`${keyString}ErrorMessage`]: branchId
            ? ""
            : prevState[`${keyString}ErrorMessage`],
        } as any;
      });
    },
    [setErrorState]
  );

  const changeTreeValueForKey = useCallback(
    (key: string, newValue: string) => {
      setTreeProps((prevProps) => {
        const newTreeValues = { ...prevProps, [key]: newValue };

        if (
          key === "userTreeBranchTeamMapping" ||
          key === "userTreeBranchAreaMapping"
        ) {
          newTreeValues[`${key}currentBranch`] = "";
          newTreeValues[`${key}currentDescriptionBranch`] = "";
          newTreeValues[`${key}defaultBranchHierarchyDescription`] = "";
          newTreeValues[`${key}SubsetType`] = "WHOLETREE";
        } else if (key === "userTreeBranchTeamMappingSubsetType") {
          newTreeValues.userTreeBranchTeamMappingcurrentBranch = "";
          newTreeValues.userTreeBranchTeamMappingcurrentDescriptionBranch = "";
          newTreeValues.userTreeBranchTeamMappingdefaultBranchHierarchyDescription =
            "";
        }

        return newTreeValues;
      });

      setErrorState((prevState: any) => ({
        ...prevState,
        [`${key}ErrorMessage`]: "",
      }));
    },
    [setErrorState]
  );

  useEffect(() => {
    getTreeIdList();
    initialTreeData();
  }, [initialTreeData]);

  useEffect(() => {
    departmentRenderCount.current += 1;
    if (departmentRenderCount.current <= 2) return;

    let realValue: string = "";
    if (enableUserDepartmentTree) {
      let objToSJON: any;
      if (treeProps["userTreeBranchAreaMappingSubsetType"] === "WHOLETREE") {
        objToSJON = {
          TreeCode: treeProps["userTreeBranchAreaMapping"],
          ParentCode: `[Root:${treeProps["userTreeBranchAreaMapping"]}]`,
        };
      } else {
        objToSJON = {
          TreeCode: treeProps["userTreeBranchAreaMapping"],
          ParentCode: treeProps["userTreeBranchAreaMappingcurrentBranch"],
        };
      }
      realValue = JSON.stringify(objToSJON);
    } else {
      realValue = "";
    }

    changeValueForKey("userTreeBranchAreaMapping", realValue);
  }, [
    enableUserDepartmentTree,
    treeProps.userTreeBranchAreaMappingSubsetType,
    treeProps.userTreeBranchAreaMapping,
    treeProps.userTreeBranchAreaMappingcurrentBranch,
    changeValueForKey,
  ]);

  useEffect(() => {
    teamRenderCount.current += 1;
    if (teamRenderCount.current <= 2) return;

    let realValue2: string = "";
    if (enableUserTeamTree) {
      let objToSJON: any;
      if (treeProps["userTreeBranchTeamMappingSubsetType"] === "WHOLETREE") {
        objToSJON = {
          TreeCode: treeProps["userTreeBranchTeamMapping"],
          ParentCode: `[Root:${treeProps["userTreeBranchTeamMapping"]}]`,
        };
      } else {
        objToSJON = {
          TreeCode: treeProps["userTreeBranchTeamMapping"],
          ParentCode: treeProps["userTreeBranchTeamMappingcurrentBranch"],
        };
      }
      realValue2 = JSON.stringify(objToSJON);
    } else {
      realValue2 = "";
    }

    changeValueForKey("userTreeBranchTeamMapping", realValue2);
  }, [
    enableUserTeamTree,
    treeProps.userTreeBranchTeamMappingSubsetType,
    treeProps.userTreeBranchTeamMapping,
    treeProps.userTreeBranchTeamMappingcurrentBranch,
    changeValueForKey,
  ]);

  return (
    <div className="col">
      <TPDescription text={translationsText.classifierMappingDescriptions} />

      <TPCollapsableSection
        title={translationsText.EnableUserTeamTreeLabel}
        description={translationsText.EnableUserTeamTreeDescription}
        initialCollapsed={collapsedSections["enableUserTeamTree"]}
        enableSwitch
        switchState={enableUserTeamTree}
        switchLabel={translationsText.activeLabel}
        onSwitchChange={() => {
          let newTreeProps = { ...treeProps };
          newTreeProps.userTreeBranchTeamMapping = "";
          newTreeProps.userTreeBranchTeamMappingcurrentBranch = "";
          newTreeProps.userTreeBranchTeamMappingcurrentDescriptionBranch = "";
          newTreeProps.userTreeBranchTeamMappingdefaultBranchHierarchyDescription =
            "";
          setTreeProps(newTreeProps);
          setEnabledUserTeamTree(!enableUserTeamTree);
        }}
      >
        {enableUserTeamTree ? (
          <>
            <TPFieldContainer>
              <TPSelect
                id="IdSelect"
                isMandatory={true}
                onChange={(e: any) =>
                  changeTreeValueForKey(
                    "userTreeBranchTeamMapping",
                    e.target.value
                  )
                }
                dataSource={treeList}
                value={treeProps["userTreeBranchTeamMapping"]}
                labelText={translationsText.TeamTreeMappingLabel}
                errorMessage={errorState.userTreeBranchTeamMappingErrorMessage}
                isHorizontal={false}
              />
            </TPFieldContainer>
            {treeProps["userTreeBranchTeamMapping"] && (
              <TPFieldContainer>
                <TPSelect
                  id="IdSelect"
                  isMandatory={true}
                  onChange={(e: any) =>
                    changeTreeValueForKey(
                      "userTreeBranchTeamMappingSubsetType",
                      e.target.value
                    )
                  }
                  dataSource={treeHandle}
                  value={treeProps["userTreeBranchTeamMappingSubsetType"]}
                  labelText={translationsText.SubsetTypeLabel}
                  errorMessage={
                    errorState.userTreeBranchTeamMappingSubsetTypeErrorMessage
                  }
                  isHorizontal={false}
                />
              </TPFieldContainer>
            )}
            {treeProps["userTreeBranchTeamMapping"] &&
              treeProps["userTreeBranchTeamMappingSubsetType"] ===
                "WHOLETREE" && (
                <TPFieldContainer>
                  <TPTextBox
                    id="IdTextBox"
                    onChange={TPGlobal.foo}
                    value={`[root:${treeProps["userTreeBranchTeamMapping"]}]`}
                    disabled={true}
                    labelText={translationsText.TeamTreeParentBranchLabel}
                  />
                </TPFieldContainer>
              )}
            {treeProps["userTreeBranchTeamMapping"] &&
              treeProps["userTreeBranchTeamMappingSubsetType"] ===
                "PARTIAL" && (
                <TPFieldContainer>
                  <TPBranchSelection2
                    labelText={translationsText.TeamTreeMappingLabel}
                    disabledTextBox={false}
                    treeId={treeProps["userTreeBranchTeamMapping"]}
                    modalAcceptLabel={translationsText.modalAcceptLabel}
                    modalCancelLabel={translationsText.modalCancelLabel}
                    modalSelectedBranchLabel={"modalSelectedBranchLabel"}
                    modalTitle={translationsText.modalTitleLabel}
                    mustSelectLastLevelBranch={false}
                    value={{
                      branchId:
                        treeProps[`userTreeBranchTeamMappingcurrentBranch`],
                      branchDescription:
                        treeProps[
                          `userTreeBranchTeamMappingcurrentDescriptionBranch`
                        ],
                      branchHierarchyDescription:
                        treeProps[
                          `userTreeBranchTeamMappingdefaultBranchHierarchyDescription`
                        ],
                    }}
                    onChange={(
                      branchId: string,
                      branchDescription: string,
                      branchHierachyDescription: string
                    ) => {
                      handleOnBranchChange(
                        branchId,
                        branchDescription,
                        branchHierachyDescription,
                        "userTreeBranchTeamMapping"
                      );
                    }}
                    emptyLabel={translationsText.emptyLabel}
                    errorMessage={
                      errorState.userBranchIdTeamMappingErrorMessage
                    }
                    onIsLoadingProgress={(value: boolean) => {
                      setIsLoadingScreen(value);
                    }}
                  />
                </TPFieldContainer>
              )}
          </>
        ) : (
          ""
        )}
      </TPCollapsableSection>

      <TPCollapsableSection
        title={translationsText.EnableUserDepartmentTreeLabel}
        description={translationsText.EnableUserDepartmentTreeDescription}
        initialCollapsed={collapsedSections["enableUserDepartmentTree"]}
        enableSwitch
        switchState={enableUserDepartmentTree}
        switchLabel={translationsText.activeLabel}
        onSwitchChange={() => {
          let newTreeProps = { ...treeProps };
          newTreeProps.userTreeBranchAreaMapping = "";
          newTreeProps.userTreeBranchAreaMappingcurrentBranch = "";
          newTreeProps.userTreeBranchAreaMappingcurrentDescriptionBranch = "";
          newTreeProps.userTreeBranchAreaMappingdefaultBranchHierarchyDescription =
            "";
          setTreeProps(newTreeProps);
          setEnabledUserDepartmentTree(!enableUserDepartmentTree);
        }}
      >
        {enableUserDepartmentTree ? (
          <>
            <TPFieldContainer>
              <TPSelect
                id="IdSelect"
                isMandatory={true}
                onChange={(e: any) =>
                  changeTreeValueForKey(
                    "userTreeBranchAreaMapping",
                    e.target.value
                  )
                }
                dataSource={treeList}
                value={treeProps["userTreeBranchAreaMapping"]}
                labelText={translationsText.DepartmentTreeMappingLabel}
                errorMessage={errorState.userTreeBranchAreaMappingErrorMessage}
                isHorizontal={false}
              />
            </TPFieldContainer>
            {treeProps["userTreeBranchAreaMapping"] && (
              <TPFieldContainer>
                <TPSelect
                  id="IdSelect"
                  isMandatory={true}
                  onChange={(e: any) =>
                    changeTreeValueForKey(
                      "userTreeBranchAreaMappingSubsetType",
                      e.target.value
                    )
                  }
                  dataSource={treeHandle}
                  value={treeProps["userTreeBranchAreaMappingSubsetType"]}
                  labelText={translationsText.SubsetTypeLabel}
                  errorMessage={
                    errorState.userTreeBranchAreaMappingSubsetTypeErrorMessage
                  }
                  isHorizontal={false}
                />
              </TPFieldContainer>
            )}
            {treeProps["userTreeBranchAreaMapping"] &&
              treeProps["userTreeBranchAreaMappingSubsetType"] ===
                "WHOLETREE" && (
                <TPFieldContainer>
                  <TPTextBox
                    id="IdTextBox"
                    onChange={TPGlobal.foo}
                    value={`[root:${treeProps["userTreeBranchAreaMapping"]}]`}
                    disabled={true}
                    labelText={translationsText.AreaTreeParentBranchLabel}
                  />
                </TPFieldContainer>
              )}
            {treeProps["userTreeBranchAreaMapping"] &&
              treeProps["userTreeBranchAreaMappingSubsetType"] ===
                "PARTIAL" && (
                <TPFieldContainer>
                  <TPBranchSelection2
                    labelText={translationsText.DepartmentTreeMappingLabel}
                    disabledTextBox={false}
                    treeId={treeProps["userTreeBranchAreaMapping"]}
                    modalAcceptLabel={translationsText.modalAcceptLabel}
                    modalCancelLabel={translationsText.modalCancelLabel}
                    modalSelectedBranchLabel={"modalSelectedBranchLabel"}
                    modalTitle={translationsText.modalTitleLabel}
                    mustSelectLastLevelBranch={false}
                    value={{
                      branchId:
                        treeProps[`userTreeBranchAreaMappingcurrentBranch`],
                      branchDescription:
                        treeProps[
                          `userTreeBranchAreaMappingcurrentDescriptionBranch`
                        ],
                      branchHierarchyDescription:
                        treeProps[
                          `userTreeBranchAreaMappingdefaultBranchHierarchyDescription`
                        ],
                    }}
                    onChange={(
                      branchId: string,
                      branchDescription: string,
                      branchHierachyDescription: string
                    ) => {
                      handleOnBranchChange(
                        branchId,
                        branchDescription,
                        branchHierachyDescription,
                        "userTreeBranchAreaMapping"
                      );
                    }}
                    emptyLabel={translationsText.emptyLabel}
                    errorMessage={
                      errorState.userBranchIdAreaMappingErrorMessage
                    }
                    onIsLoadingProgress={(value: boolean) => {
                      setIsLoadingScreen(value);
                    }}
                  />
                </TPFieldContainer>
              )}
          </>
        ) : (
          ""
        )}
      </TPCollapsableSection>

      <TPButton
        id="IdButton"
        onClick={handleSave}
        type={TPButtonTypes.primary}
        className="fixed-save-button"
        disabled={!someChange}
        style={
          !someChange
            ? {
                backgroundColor: "#e6e6e6",
                borderColor: "#efefef",
                color: "#989898",
                cursor: "not-allowed",
              }
            : {}
        }
      >
        {translationsText.saveButton}
      </TPButton>
    </div>
  );
};
