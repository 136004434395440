import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection,TPPageTitle} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {TPModalQuestionState} from "@/layouts/ModalQuestion/TPModalQuestion";
import { FunctionAssignmentsViewModel } from "@/models/Functions/FunctionAssignmentsModels";
import {TPActiveOptions,TPIconTypes} from "@/models/Global/TPGlobalEnums";
import DynamicTable, {
  ColumnStyles,
  CustomColumnNames,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import { FunctionAssignmentsService } from "@/services/FunctionAssignmentsService";
import { TPI18N } from "@/services/I18nService";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";

type AdminStateType = {
  organizationsRelationsAreLoaded: boolean;
  currentBaseLevel: string;
  gridColumns: Array<any>;
  gridData: Array<FunctionAssignmentsViewModel>;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface FunctionAssignmentsAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_OrganizationsRelationsAreLoaded" = 0,
  "setup_grid_columns" = 1,
  "change_CurrentBaseLevel" = 2,
  "reload_grid" = 3,
  "change_search_pattern" = 4,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const FunctionAssignmentsAdmin = React.forwardRef(
  ({ callBackCommands }: FunctionAssignmentsAdminInterface, ref) => {
    const componentFileName: string = "FunctionAssignmentsAdmin.tsx";

    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const resourceSet: string = "FunctionsAssignmentAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [organizationsRelationsLabel, setOrganizationsRelationsLabel] =
      useState("");
    const [newLabel, setNewLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");
    const [organizationsRelationsKeyValue, setOrganizationsRelationsKeyValue] =
      useState<Array<TPKeyValue>>([]);
    const [functionColumnLabel, setFunctionColumnLabel] = useState("");
    const [responsibleColumnLabel, setResponsibleColumnLabel] = useState("");
    useState("");
    const [
      classificatorBranchColumnLabel1,
      setClassificatorBranchColumnLabel1,
    ] = useState("");
    const [
      classificatorBranchColumnLabel2,
      setClassificatorBranchColumnLabel2,
    ] = useState("");
    const [
      classificatorBranchColumnLabel3,
      setClassificatorBranchColumnLabel3,
    ] = useState("");
    const [
      classificatorBranchColumnLabel4,
      setClassificatorBranchColumnLabel4,
    ] = useState("");
    const [
      classificatorBranchColumnLabel5,
      setClassificatorBranchColumnLabel5,
    ] = useState("");
    const [
      applyForAllOrganizationsRelationsColumnLabel,
      setApplyForAllOrganizationsRelationsColumnLabel,
    ] = useState("");
    const [weightColumnLabel, setWeightColumnLabel] = useState("");
    const [updateByColumnLabel, setUpdateByColumnLabel] = useState("");
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");
    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );

    const loadResourcesAndOrganizations = async () => {
      setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm"),
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setOrganizationsRelationsLabel(
        await TPI18N.GetText(resourceSet, "OrganizationRelation"),
      );
      setFunctionColumnLabel(await TPI18N.GetText(resourceSet, "Function"));
      setResponsibleColumnLabel(
        await TPI18N.GetText(resourceSet, "ResponsibleLabel"),
      );
      setClassificatorBranchColumnLabel1(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel1"),
      );
      setClassificatorBranchColumnLabel2(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel2"),
      );
      setClassificatorBranchColumnLabel3(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel3"),
      );
      setClassificatorBranchColumnLabel4(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel4"),
      );
      setClassificatorBranchColumnLabel5(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel5"),
      );
      setApplyForAllOrganizationsRelationsColumnLabel(
        await TPI18N.GetText(
          resourceSet,
          "ApplyForAllOrganizationsRelatiosnLabel",
        ),
      );
      setWeightColumnLabel(await TPI18N.GetText(resourceSet, "Weight"));
      setUpdateByColumnLabel(await TPI18N.GetText(resourceSet, "UpdatedBy"));

      setYesLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes"),
      );
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));

      await getOrganizationsRelationsList();
      let command1: commandType = {
        type: commandsEnum.set_OrganizationsRelationsAreLoaded,
        payload: null,
      };
      dispatchCommand(command1);
    };

    const getOrganizationsRelationsList = async () => {
      let serviceOrganizationRelationClient =
        new OrganizationsRelationsService();
      let expectedCodes: Array<number> = [200];
      let i: number;
      try {
        setIsLoadingScreen(true);
        let responseRequestOrgRela =
          await serviceOrganizationRelationClient.getOrganizationsRelationsByFilter(
            TPActiveOptions.ALL.toString(),
            false,
            true,
            expectedCodes,
          );

        let newOrganizationsRelationsListState: Array<TPKeyValue> = [];
        for (i = 0; i <= responseRequestOrgRela.length - 1; i++) {
          let keyvalue: TPKeyValue = {
            key: responseRequestOrgRela[i].id,
            value: responseRequestOrgRela[i].localizedDescription,
          };
          newOrganizationsRelationsListState.push(keyvalue);
        }

        let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
        newOrganizationsRelationsListState.unshift(intitialKeyValue);
        setOrganizationsRelationsKeyValue(newOrganizationsRelationsListState);
        setIsLoadingScreen(false);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getOrganizationsRelationsList ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getOrganizationsRelationsList ex`,
        );
        setIsLoadingScreen(false);
      }
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      let newState: AdminStateType = { ...prevState };
      newState.gridColumns = [prevState.gridData];
      return newState;
    };
    const handleOrganizationRelationChange = async (e: any) => {
      let newBaseLevel: string;
      newBaseLevel = e.target.value;
      let command1: commandType = {
        type: commandsEnum.change_CurrentBaseLevel,
        payload: { newBaseLevel: newBaseLevel },
      };
      dispatchCommand(command1);
    };

    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    const reloadDataGrid = async (baseLevelId: string) => {
      let serviceClient = new FunctionAssignmentsService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        if (baseLevelId !== "") {
          let responseRequest =
            await serviceClient.getFunctionAssignmentByBaseLevel(
              baseLevelId,
              false,
              true,
              expectedCodes,
            );
          setIsLoadingScreen(false);
          return [...responseRequest];
        } else {
          let responseRequest = await serviceClient.getAll(
            false,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          return [...responseRequest];
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.currentBaseLevel)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    const handleUpdateClick = (id: string) => {
      let command: any = {
        command: "update",
        recordId: id,
        recordDescription: "",
      };
      callBackCommands(command);
    };

    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new FunctionAssignmentsService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          await serviceClient.deleteFunctionAssignmentById(
              callBackData.recordId,
              true,
              true,
              expectedCodes,
            );
          setIsLoadingScreen(false);
          reloadGridCommand();
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    const initialStateBLL: AdminStateType = {
      organizationsRelationsAreLoaded: false,
      currentBaseLevel: "",
      columnsAreLoaded: false,
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      let newAdminState: AdminStateType;
      switch (command.type) {
        case commandsEnum.set_OrganizationsRelationsAreLoaded:
          newAdminState = { ...prevState };
          newAdminState.organizationsRelationsAreLoaded = true;
          return newAdminState;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.change_CurrentBaseLevel:
          newAdminState = { ...prevState };
          newAdminState.currentBaseLevel = command.payload.newBaseLevel;
          return newAdminState;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        default:
          return prevState;
      }
    }

    const customerTypeColumns: CustomColumnNames<FunctionAssignmentsViewModel> =
      {
        baseLevelSourceDescription: organizationsRelationsLabel,
        applyForAllOrganizationRelation: applyForAllOrganizationsRelationsColumnLabel,
        functionDescription: functionColumnLabel,
        userDescription: responsibleColumnLabel,
        branch1Description: classificatorBranchColumnLabel1,
        branch2Description: classificatorBranchColumnLabel2,
        branch3Description: classificatorBranchColumnLabel3,
        branch4Description: classificatorBranchColumnLabel4,
        branch5Description: classificatorBranchColumnLabel5,
        weight: weightColumnLabel,
        typistUserDescription: updateByColumnLabel,
      };
    const customColumns: ColumnStyles<FunctionAssignmentsViewModel> = {
      applyForAllOrganizationRelation: ({ value }) => (
        <TableChip
          value={value}
          onLabel={yesLabel}
          offLabel={noLabel}
          justify="flex-start"
        />
      ),
      branch1Description: row => row.value || "",
      branch2Description: row => row.value || "",
      branch3Description: row => row.value || "",
      branch4Description: row => row.value || "",
      branch5Description: row => row.value || "",
    };
    const minorOptions = [
      {
        key: updateLabel,
        type: "edit",
        icon: TPIconTypes.edit,
        onOptionChange: (e: any) => handleUpdateClick(e.guidId),
      },
      {
        key: deleteLabel,
        type: "delete",
        icon: TPIconTypes.delete,
        onOptionChange: (e: any) => handleDeleteClick(e.guidId),
      },
    ];

    const additionalFilters: AdditionalFilter[] = [
      {
        key: "org-relation-status",
        data: organizationsRelationsKeyValue,
        label: organizationsRelationsLabel,
        selectedValue: adminState.currentBaseLevel,
        onChange: handleOrganizationRelationChange,
      },
    ];

    const getHideenColumns = (): (keyof FunctionAssignmentsViewModel)[] => {
      const hiddenColumns: (keyof FunctionAssignmentsViewModel)[] = [
        "guidId",
        "functionId",
        "baseLevelDescription",
        "branchTree1Id",
        "branchTree2Id",
        "branchTree3Id",
        "branchTree4Id",
        "userGuid",
        "branchTree5Id",
        "userIsGroup",
        "baseLevelSourceId",
        "baseLevelId",
      ];
      return hiddenColumns;
    };

    const handleIconClick = (event: TPIconTypes) => {
      if (event === TPIconTypes.loop) handleRefreshClick();
    };

    useEffect(() => {
      loadResourcesAndOrganizations();
    }, []);

    useEffect(() => {
      if (adminState.organizationsRelationsAreLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.organizationsRelationsAreLoaded]);

    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.currentBaseLevel]);

    return (
      <>
        <TPModalQuestion
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        />
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <CIMTitleSection>
                <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                <TPButton
                  id="new-tree"
                  isDesignSystem={true}
                  onClick={handleNewClick}
                  withIcon={TPIconTypes.add}
                  orientationIcon="left"
                  style={{ padding: "1px 18px" }}
                >
                  {newLabel}
                </TPButton>
              </CIMTitleSection>
              <DynamicTable
                data={adminState.gridData}
                columnNames={customerTypeColumns}
                columnStyles={customColumns}
                minorOptions={minorOptions}
                additionalFilters={additionalFilters}
                hiddenColumns={getHideenColumns()}
                noDataMessage={`${thereAreNoRecordsToShow}.`}
                onIconClicked={(event) => handleIconClick(event)}
                exportNameFile={"Function Assignments"}
              />
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  },
);

export default FunctionAssignmentsAdmin;
