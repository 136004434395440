import { ReactElement } from "react";

type ReaderIconProps = {};

function ReaderIcon({}: ReaderIconProps): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.625 8C10.835 8 12.625 6.21 12.625 4C12.625 1.79 10.835 0 8.625 0C6.415 0 4.625 1.79 4.625 4C4.625 6.21 6.415 8 8.625 8ZM8.625 2C9.735 2 10.625 2.9 10.625 4C10.625 5.1 9.735 6 8.625 6C7.515 6 6.625 5.11 6.625 4C6.625 2.89 7.525 2 8.625 2ZM7.895 16H0.625V13C0.625 10.33 5.955 9 8.625 9C9.665 9 11.125 9.21 12.485 9.61C11.625 9.95 10.825 10.42 10.125 11C9.625 10.94 9.125 10.9 8.625 10.9C5.655 10.9 2.525 12.36 2.525 13V14.1H7.845C7.825 14.15 7.795 14.2 7.765 14.25L7.475 15L7.765 15.75C7.805 15.83 7.855 15.91 7.895 16ZM15.625 14C16.185 14 16.625 14.44 16.625 15C16.625 15.56 16.185 16 15.625 16C15.065 16 14.625 15.56 14.625 15C14.625 14.44 15.065 14 15.625 14ZM15.625 11C12.895 11 10.565 12.66 9.625 15C10.565 17.34 12.895 19 15.625 19C18.355 19 20.685 17.34 21.625 15C20.685 12.66 18.355 11 15.625 11ZM15.625 17.5C14.245 17.5 13.125 16.38 13.125 15C13.125 13.62 14.245 12.5 15.625 12.5C17.005 12.5 18.125 13.62 18.125 15C18.125 16.38 17.005 17.5 15.625 17.5Z"
        fill="#2E2E2E"
      />
    </svg>
  );
}

export default ReaderIcon;
