import { TPI18N } from "@/services/I18nService";
import { useEffect, useState } from "react";

const RESOURCE_SET = 'FileSelector';

interface TPFileSelectorLabels {
  SelectAFileOfType: string;
  Size: string;
  MaxSize: string;
  SelectFile: string;
  SelectAnother: string;
  Selected: string;
  FileRequired: string;
  FileSizeError: string;
}

export const useTPFileSeelctorLabels = function() {

  const [labels, setLabels] = useState<TPFileSelectorLabels>({} as TPFileSelectorLabels);
  const [labelsLoaded, setLabelsLoaded] = useState(false);

  const getLabels = async function() {
    setLabels({
      MaxSize: await TPI18N.GetText(RESOURCE_SET, 'MaxSize'),
      SelectAFileOfType: await TPI18N.GetText(RESOURCE_SET, 'SelectAFileOfType'),
      SelectAnother: await TPI18N.GetText(RESOURCE_SET, 'SelectAnother'),
      SelectFile: await TPI18N.GetText(RESOURCE_SET, 'SelectFile'),
      Size: await TPI18N.GetText(RESOURCE_SET, 'Size'),
      Selected: await TPI18N.GetText(RESOURCE_SET, 'Selected'),
      FileRequired: await TPI18N.GetText(RESOURCE_SET, 'FileRequired'),
      FileSizeError: await TPI18N.GetText(RESOURCE_SET, 'FileSizeError'),
    })
    setLabelsLoaded(true);
  }

  useEffect(() => {
    if (!labelsLoaded) getLabels();
  }, [])

  return { labels };
}