import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { ProjectModel } from "@/models/Project/Projects";
import SearchSelect from "@/modules/core/design-system/selects/SearchSelect";
import { throwAlertError } from "@/modules/core/utils/errors-management";
import { EventProjectService } from "@/services/EventProjectService";
import {
  CockpitDashboardService,
  EventRecordsProps,
  EventViewProps,
  EventViewResponse,
  QueuesProps,
} from "@/services/SupervisorCockpit/CockpitDashboardService";
import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import {
  CockpitDashboardLabels,
  EventGroup,
  GroupTableItem,
  InfoCardTypes,
  InfoCardValues,
  RankingUser,
  SelectedFilterItems,
  StatusModel,
  TeamMember,
  TeamMemberParsed,
} from "../../supervisor-cockpit.model";
import CockpitInfoCard from "../../utils/CockpitInfoCard";
import CockpitNoData from "../../utils/CockpitNoData";
import CockpitRefresh from "../../utils/CockpitRefresh";
import CockpitRemovableItem from "../../utils/CockpitRemovableItem";
import CockpitTeamActivityTable from "../../utils/CockpitTeamActivityTable";
import {
  StyledCardContainer,
  StyledDashboardContainer,
  StyledDashboardFilters,
  StyledDashboardTeamActivity,
  StyledFiltersHeader,
  StyledInformationDot,
  StyledInformationDotsContainer,
  StyledItemsSelected,
} from "../cockpit-dashboard-styles";
import DashboardTable from "./DashboardTable";
import EmployeeRanking from "./EmployeeRanking";

type DashboardEventViewProps = {
  componentLabels: CockpitDashboardLabels;
  setIsLoadingScreen: Function;
  callBackCommands: Function;
};

const DashboardEventView: FC<DashboardEventViewProps> = React.memo(
  ({ componentLabels, setIsLoadingScreen, callBackCommands }): ReactElement => {
    const eventService = new EventProjectService();
    const service = new CockpitDashboardService();

    const component: string = "DashboardEventView";

    const removeOptionReferences = {
      projects: useRef<(valueToRemove: string) => void | null>(null),
      queues: useRef<(valueToRemove: string) => void | null>(null),
    };

    const excludeCards: InfoCardTypes[] = [
      "event",
      "case",
      "chat",
      "mail",
      "expired",
      "pending",
      "inProcess",
      "processing",
      "closed",
      "history",
    ];

    let baseCards: InfoCardValues = {
      offline: 0,
      working: 0,
      online: 0,
      ready: 0,
      event: 0,
      case: 0,
      chat: 0,
      mail: 0,
      expired: 0,
      worked: 0,
      pending: 0,
      closed: 0,
      history: 0,
      inProcess: 0,
      processing: 0
    };
    const [infoCards, setInfoCards] = useState<InfoCardValues>(baseCards);

    const [projectsSelected, setProjectsSelected] = useState<TPKeyValue[]>([]);
    const [queuesSelected, setQueuesSelected] = useState<TPKeyValue[]>([]);
    const [showTable, setShowTable] = useState<boolean>(false);
    const [tableData, setTableData] = useState<TeamMemberParsed[]>([]);
    const [projects, setProjects] = useState<TPKeyValue[]>([]);
    const [queues, setQueues] = useState<TPKeyValue[]>([]);
    const [rankingEmployees, setRankingEmployees] = useState<RankingUser[]>([]);
    const [filterApplied, setFilterApplied] = useState<boolean>(false);
    const [groupTableData, setGroupTableData] = useState<GroupTableItem[]>([]);

    const handleProjectsSelectChange = async (values: TPKeyValue[]) => {
      setFilterApplied(false);
      setProjectsSelected(values);

      if (values.length === 0) {
        setQueues([]);
        setQueuesSelected([]);
        setShowTable(false);
        return;
      }

      const queuesLength = values.length - 1;

      const lastValue = values[queuesLength];

      setQueuesBy(lastValue);
    };

    const handleQueuesSelectChange = (values: TPKeyValue[]) => {
      setFilterApplied(false);
      setShowTable(false);
      setQueuesSelected(values);
    };

    const removeOption = (value: string, type: "projects" | "queues") => {
      removeOptionReferences[type].current?.(value);
    };

    const getSelectedItems = (): SelectedFilterItems[] => {
      let itemsSelected: SelectedFilterItems[] = [
        ...projectsSelected.map((item) => {
          return {
            ...item,
            type: "projects",
          } as SelectedFilterItems;
        }),
        ...queuesSelected.map((item) => {
          return {
            ...item,
            type: "queues",
          } as SelectedFilterItems;
        }),
      ];

      return itemsSelected;
    };

    const handleApplyFilters = () => {
      if (isUniqDashboardConsult()) {
        setEventViewData();
        return;
      }

      setEventRecordsData();
    };

    const getKeyBy = (queue: TPKeyValue, isQueue: boolean = true): string => {
      return queue.key.split("-")[isQueue ? 1 : 0];
    };

    const setEventRecordsData = () => {
      getEventRecordsData().then((res) => {
        let organizedGroups: GroupTableItem[] = res.map((item) => {
          return {
            projectId: item.projectId,
            projectName: item.projectName,
            groupId: item.groupId,
            groupName: item.groupName ?? "",
            totalCE: item.totalCE,
            readyStatus: item.readyStatus,
            onlineStatus: item.onlineStatus,
            workingStatus: item.workingStatus,
            offlineStatus: item.offlineStatus,
            totalTickets: item.type === "FRONTOFFICE" ? "" : item.totalTickets,
            workedTickets: item.workedTickets,
            pendingTickets:
              item.type === "FRONTOFFICE" ? "" : item.pendingTickets,
            type: item.type,
            onSchedule: item.onSchedule,
            offSchedule: item.offSchedule,
          };
        });

        setGroupTableData(organizedGroups);
        setIsLoadingScreen(false);
        setShowTable(res.length !== 0);
        setFilterApplied(res.length !== 0);
      });
    };

    const getRecordsBySelection = (): EventRecordsProps => {
      const queuesRecords = queuesSelected.map((queue) => ({
        projectId: getKeyBy(queue, false),
        groupId: getKeyBy(queue),
      }));

      const projectsRecords = projectsSelected.map((project) => ({
        projectId: project.key,
        groupId: "",
      }));

      return {
        records: [...queuesRecords, ...projectsRecords],
      };
    };

    const getEventRecordsData = async () => {
      const object: EventRecordsProps = getRecordsBySelection();

      try {
        setIsLoadingScreen(true);
        return await service.getEventRecordsBy(object);
      } catch (error) {
        setIsLoadingScreen(false);
        throwAlertError(error, component, "getEventRecordsData");
        return [];
      }
    };

    const isUniqDashboardConsult = (): boolean => {
      return (
        (projectsSelected.length === 1 && queuesSelected.length === 1) ||
        (projectsSelected.length === 1 && queuesSelected.length === 0)
      );
    };

    const getEventViewData = async () => {
      const object: EventViewProps = {
        projectId: projectsSelected[0].key,
        queueId:
          queuesSelected.length !== 0 ? getKeyBy(queuesSelected[0]) : null,
      };

      try {
        setIsLoadingScreen(true);
        return await service.getEventViewData(object);
      } catch (error) {
        setIsLoadingScreen(false);
        throwAlertError(error, component, "getEventViewData");
        return [];
      }
    };

    const setEventViewData = () => {
      getEventViewData().then((res) => {
        const response: EventViewResponse = res[0];

        setFilterApplied(!!response);

        if (res && response) {
          response.statusModels && setInfoCardsBy(response.statusModels);
          response.teamMembersModel &&
            setTableDataBy(response.teamMembersModel);
          response.rankingModel && setRankingEmployees(response.rankingModel);
        }

        setIsLoadingScreen(false);
      });
    };

    const setInfoCardsBy = (statusModels: StatusModel[]) => {
      let cards = baseCards;

      statusModels.forEach((item) => {
        let cardType: InfoCardTypes | undefined =
          item.status.toLowerCase() as InfoCardTypes;
        if (cardType) cards[cardType] = item.totalStatus;
      });

      setInfoCards(cards);
    };

    const setTableDataBy = (data: TeamMember[]) => {
      let parsedData: TeamMemberParsed[] = data.map((item) => {
        return {
          userName: item.userName,
          schedule:
            item.onSchedule === "ONSCHEDULE" ? "onSchedule" : "offSchedule",
          status: item.currentStatus.toLowerCase() as InfoCardTypes,
          duration: item.duration,
          pendingTickets: item.pendingTickets,
          queueName: item.queueName,
          userGuid: item.userGuid,
          userId: item.userId,
        };
      });

      setTableData(parsedData);
    };

    const getViewProjects = async (): Promise<ProjectModel[]> => {
      try {
        setIsLoadingScreen(true);
        return await eventService.getProjectsBySuperior(
          TPGlobal.currentUserGuid,
          false,
          true,
          [200]
        );
      } catch (error) {
        setIsLoadingScreen(false);
        throwAlertError(error, component, "getViewGroups");
        return [];
      }
    };

    const getViewQueuesBy = async (
      projectId: string
    ): Promise<EventGroup[]> => {
      const object: QueuesProps = {
        eventProjectsId: projectId,
      };

      try {
        setIsLoadingScreen(true);
        return await service.getQueuesByProject(object);
      } catch (error) {
        setIsLoadingScreen(false);
        throwAlertError(error, component, "getViewQueuesBy");
        return [];
      }
    };

    const setEventViewFilters = () => {
      getViewProjects().then((res) => {
        let projectList: TPKeyValue[] = res.map((item) => {
          return {
            key: item.id,
            value: item.name,
          } as TPKeyValue;
        });

        setProjects(projectList);
        setIsLoadingScreen(false);
      });
    };

    const setQueuesBy = (project: TPKeyValue) => {
      getViewQueuesBy(project.key).then((res) => {
        if (res.length > 0) {
          let queueList: TPKeyValue[] = res.map((gp) => {
            return {
              key: `${project.key}-${gp.groupId}`,
              value: gp.user.firstName,
            } as TPKeyValue;
          });

          setQueues((prev) => {
            return removeDuplicatedIn([...prev, ...queueList]);
          });
        }

        setIsLoadingScreen(false);
      });
    };

    const removeDuplicatedIn = (items: TPKeyValue[]): TPKeyValue[] => {
      const uniqValues = new Set();
      return items.filter((item) => {
        const valor = item.key;
        if (uniqValues.has(valor)) {
          return false;
        } else {
          uniqValues.add(valor);
          return true;
        }
      });
    };

    const goToProjectTab = (id: string) => {
      let command: any = {
        command: "new_horizontal_tab",
        recordId: id,
        component: "TPClientCloudMain.Client.Components.EventsManager.Projects",
      };
      callBackCommands(command);
    };

    const RenderSelectedItems = React.memo((): ReactElement => {
      const selectedItems: SelectedFilterItems[] = getSelectedItems();
      return (
        <>
          {selectedItems.length > 0 && (
            <StyledItemsSelected>
              {selectedItems.map((item, index) => {
                return (
                  <CockpitRemovableItem
                    key={index}
                    item={item}
                    onRemoveItem={() =>
                      removeOption(item.key, item.type as "projects" | "queues")
                    }
                  />
                );
              })}
            </StyledItemsSelected>
          )}
        </>
      );
    });

    const RenderInformationDots = React.memo((): ReactElement => {
      return (
        <StyledInformationDotsContainer>
          <StyledInformationDot>
            {`${componentLabels.totalProjects}: ${projectsSelected.length}`}
          </StyledInformationDot>
          <StyledInformationDot>
            {`${componentLabels.totalQueues}: ${queuesSelected.length}`}
          </StyledInformationDot>
        </StyledInformationDotsContainer>
      );
    });

    const RenderButtons = React.memo((): ReactElement => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          <TPButton
            id="apply-filters"
            onClick={() => handleApplyFilters()}
            style={{ padding: "0 15px" }}
            disabled={projectsSelected.length === 0}
            isDesignSystem
          >
            {componentLabels.apply}
          </TPButton>

          {projectsSelected.length === 1 && (
            <TPButton
              id="go-to-detail"
              onClick={() => goToProjectTab(projectsSelected[0].key)}
              customType={ButtonCustomType.tertiary}
              isDesignSystem
            >
              {componentLabels.goToDetail}
            </TPButton>
          )}

          {projectsSelected.length !== 0 && (
            <CockpitRefresh
              view="group"
              onClickIcon={handleApplyFilters}
              label={componentLabels.refreshData}
            />
          )}
        </div>
      );
    });

    const RenderInfoCards = React.memo((): ReactElement => {
      let allCards = Object.entries(infoCards).map(([key, value]) => {
        return {
          type: key as InfoCardTypes,
          value: value,
          label: componentLabels.infoCards[key as InfoCardTypes] ?? "",
        };
      });

      let filteredCards = allCards.filter(
        (item) => !excludeCards.includes(item.type)
      );

      return (
        <StyledCardContainer isWorked>
          {filteredCards.map((item, index) => {
            return (
              <CockpitInfoCard
                key={index}
                type={item.type}
                label={item.label}
                value={item.value}
              />
            );
          })}
        </StyledCardContainer>
      );
    });

    const getQueuesSelected = (): TPKeyValue[] => {
      return queuesSelected.map((item) => {
        return {
          key: getKeyBy(item),
          value: item.value,
        };
      });
    };

    useEffect(() => {
      setEventViewFilters();
    }, []);

    return (
      <>
        <StyledDashboardContainer>
          <StyledFiltersHeader>
            <StyledDashboardFilters>
              <SearchSelect
                id="cockpit-project"
                width="200px"
                options={projects}
                optionSelected={projectsSelected}
                label={componentLabels.project}
                placeholder={componentLabels.filterPlaceholder}
                handleMultiChange={handleProjectsSelectChange}
                removeSelectedOptionRef={removeOptionReferences.projects}
                orientation="vertical"
                isMandatory
                isMulti
              />
              <SearchSelect
                id="cockpit-queue"
                width="200px"
                options={queues}
                optionSelected={queuesSelected}
                label={componentLabels.queue}
                placeholder={componentLabels.filterPlaceholder}
                handleMultiChange={handleQueuesSelectChange}
                removeSelectedOptionRef={removeOptionReferences.queues}
                orientation="vertical"
                isMulti
              />
              <RenderButtons />
            </StyledDashboardFilters>
            <RenderInformationDots />
          </StyledFiltersHeader>
          <RenderSelectedItems />
        </StyledDashboardContainer>
        {!filterApplied ? (
          <CockpitNoData
            view={"event"}
            withAssignation={projects.length !== 0}
          />
        ) : (
          <>
            {showTable && filterApplied ? (
              <DashboardTable
                view="event"
                data={groupTableData}
                groups={getQueuesSelected()}
                componentLabels={componentLabels}
                setIsLoadingScreen={setIsLoadingScreen}
                goToProjectDetails={goToProjectTab}
              />
            ) : (
              <>
                <RenderInfoCards />
                <EmployeeRanking
                  rankingEmployees={rankingEmployees}
                  componentLabels={componentLabels}
                  callBackCommands={callBackCommands}
                />
                <StyledDashboardTeamActivity>
                  <CockpitTeamActivityTable
                    view={"event"}
                    data={tableData}
                    withChildren
                    groupId={queuesSelected[0] && +queuesSelected[0].key.split("-")[1]}
                    selectedProject={projectsSelected[0] && projectsSelected[0]}
                    queues={queues}
                  />
                </StyledDashboardTeamActivity>
              </>
            )}
          </>
        )}
      </>
    );
  }
);

export default DashboardEventView;
