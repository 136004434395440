import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable, {
  CustomColumnNames,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import UserProfile from "@/modules/core/design-system/user-data/UserProfile";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { ColumnStyles } from "@/pages/Projects/ProjectDetail/ProjectDetailDynamicTable";
import { TPI18N } from "@/services/I18nService";
import React, { FC, ReactElement, useEffect, useState } from "react";
import {
  StyledScheduleContent,
  StyledScheduleDot,
  StyledUserProfileCompleted,
} from "../supervisor-cockpit-styles";
import {
  CockpitTeamActivityTableLabels,
  CockpitView,
  TeamMemberParsed,
} from "../supervisor-cockpit.model";
import CockpitInfoCard from "./CockpitInfoCard";
import { TPKeyValue } from "@/helpers/TPKeyValue";

interface TeamActivityFilters {
  working: boolean;
  ready: boolean;
  online: boolean;
  offline: boolean;
}

type CockpitTeamActivityTableProps = {
  view?: CockpitView;
  withChildren?: boolean;
  filters?: TeamActivityFilters;
  callback?: (employee: TeamMemberParsed) => void;
  groupId?: number;
  data?: TeamMemberParsed[];
  reloadCallback?: () => void;
  selectedProject?: TPKeyValue;
  queues?: TPKeyValue[];
  fromTeamActivity?: boolean;
  selectedGroup?: TPKeyValue;
};

const CockpitTeamActivityTable: FC<CockpitTeamActivityTableProps> = React.memo(
  ({
    view = "team",
    withChildren,
    data = [],
    filters,
    callback,
    reloadCallback,
    groupId,
    selectedProject,
    queues,
    fromTeamActivity = false,
    selectedGroup,
  }): ReactElement => {
    const component: string = "CockpitTeamActivityTable";
    const parent: string = "CockpitDashboard";

    const caseContext: any = React.useContext(TPCaseViewerContext);
    const [componentLabels, setComponentLabels] =
      useState<CockpitTeamActivityTableLabels>(
        {} as CockpitTeamActivityTableLabels
      );
    const [isResourcesLoaded, setIsResourcesLoaded] = useState<boolean>(false);

    const loadUtilsResources = async () => {
      setComponentLabels({
        employee: await TPI18N.GetText(component, "EmployeeName"),
        schedule: await TPI18N.GetText(component, "Schedule"),
        status: await TPI18N.GetText(component, "Status"),
        duration: await TPI18N.GetText(component, "Duration"),
        pendingTickets: await TPI18N.GetText(component, "PendingTickets"),
        queue: await TPI18N.GetText(component, "Queue"),
        offSchedule: await TPI18N.GetText(component, "OffSchedule"),
        onSchedule: await TPI18N.GetText(component, "OnSchedule"),
        teamActivity: await TPI18N.GetText(component, "TeamActivity"),
        infoCards: {
          offline: await TPI18N.GetText(parent, "Offline"),
          working: await TPI18N.GetText(parent, "Working"),
          online: await TPI18N.GetText(parent, "Online"),
          ready: await TPI18N.GetText(parent, "Ready"),
          worked: await TPI18N.GetText(parent, "Worked"),
          case: await TPI18N.GetText(parent, "Case"),
          event: await TPI18N.GetText(parent, "Event"),
          chat: await TPI18N.GetText(parent, "Chat"),
          mail: await TPI18N.GetText(parent, "Mail"),
          expired: await TPI18N.GetText(parent, "Expired"),
          pending: await TPI18N.GetText(parent, "Pending"),
          inProcess: await TPI18N.GetText(parent, "InProgress"),
          closed: await TPI18N.GetText(parent, "Closed"),
          history: await TPI18N.GetText(parent, "History"),
          processing: await TPI18N.GetText(parent, "Processing"),
        },
      });
    };

    const RenderUserProfile = React.memo(
      ({ value }: { value: string }): ReactElement => {
        return (
          <StyledUserProfileCompleted>
            <UserProfile
              completeName={value}
              customSize={{ font: "14px", size: "34px" }}
            />
            <p className="user-name">{value}</p>
          </StyledUserProfileCompleted>
        );
      }
    );

    const RenderScheduleDot = React.memo(
      ({ value }: { value: string }): ReactElement => {
        return (
          <StyledScheduleContent>
            <StyledScheduleDot
              color={value === "onSchedule" ? "#A1D66D" : "#989898"}
            >
              <TPIcon
                iconType={
                  TPIconTypes[value === "onSchedule" ? "work" : "prohibited"]
                }
              />
            </StyledScheduleDot>
            <p>
              {value === "onSchedule"
                ? componentLabels.onSchedule
                : componentLabels.offSchedule}
            </p>
          </StyledScheduleContent>
        );
      }
    );

    const getQueueId = (item: TeamMemberParsed) => {
      let foundItem = queues?.find(s => s.value.trim() == item.queueName?.trim());

      if (foundItem) {
        return foundItem.key.split('-')[1];
      }
    }

    const customColumns: ColumnStyles<TeamMemberParsed> = {
      userName: ({ value }) => <RenderUserProfile value={value} />,
      schedule: ({ value }) => <RenderScheduleDot value={value} />,
      status: ({ value, item }) => {
        return (
          <CockpitInfoCard
            type={item.status}
            label={componentLabels.infoCards[item.status]}
            isSmallCard
          />
        );
      },
      pendingTickets: ({ item, value }) => {
        return (
          <>
            {value > 0 ? (
              <TablePrimaryItem
                value={value}
                onClick={() => {
                  callback?.(item);
                  !fromTeamActivity &&
                    caseContext.goToTeamActivity(true, item, view, groupId || getQueueId(item) || "", selectedProject, selectedGroup?.key);
                }}
              />
            ) : (
              <div>{value}</div>
            )}
          </>
        )
      }
    };

    const columnNames: CustomColumnNames<TeamMemberParsed> = {
      userName: componentLabels.employee,
      schedule: componentLabels.schedule,
      status: componentLabels.status,
      pendingTickets: componentLabels.pendingTickets,
      queueName: componentLabels.queue,
    };

    const getHiddenColumns = (): (keyof TeamMemberParsed)[] => {
      let baseColumns = ["userGuid", "userId"];

      if (view !== "event") baseColumns.push("queueName");

      return baseColumns as (keyof TeamMemberParsed)[];
    };

    const filterData = () => {
      let toFilter: TeamMemberParsed[] = [];

      if (data) {
        toFilter = data;
      }

      return toFilter.filter((item: TeamMemberParsed) => {
        if (!filters) {
          return true;
        }
        if (item.status === "worked") {
          return true;
        }
        return (
          (filters.working && item.status === "working") ||
          (filters.ready && item.status === "ready") ||
          (filters.online && item.status === "online") ||
          (filters.offline && item.status === "offline")
        );
      });
    };

    useEffect(() => {
      loadUtilsResources().then(() => setIsResourcesLoaded(true));
    }, []);

    return (
      <>
        {isResourcesLoaded && (
          <>
            <DynamicTable
              id={`team-activity${view && `-${view}`}`}
              searchPosition={withChildren ? "right" : "left"}
              data={filterData()}
              columnStyles={customColumns}
              columnNames={columnNames}
              hiddenColumns={getHiddenColumns()}
              childrenPosition={withChildren ? "left" : "right"}
              customHeight={withChildren ? "300px" : "auto"}
              maxWidthTableContainer="100%"
              withPreferences={!withChildren}
              hideRefresh={withChildren}
              hideExport
              hiddenSearch={filterData().length == 0}
              onIconClicked={(icon) => {
                if (icon == TPIconTypes.loop || icon == TPIconTypes.refresh) {
                  reloadCallback && reloadCallback();
                }
              }}
            >
              {withChildren && (
                <>
                  <TPButton
                    id="go-to-team-activity"
                    onClick={() => caseContext.goToTeamActivity(false)}
                    customType={ButtonCustomType.tertiary}
                    isDesignSystem
                  >
                    {componentLabels.teamActivity}
                  </TPButton>
                </>
              )}
            </DynamicTable>
          </>
        )}
      </>
    );
  }
);

export default CockpitTeamActivityTable;
