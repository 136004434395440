import {forwardRef, useEffect, useRef, useState} from "react";

import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPPageSection } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPModalImageSelectorState } from "@/layouts/TPModalImageSelector/TPModalImageSelector";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { FormesignerInputDTO } from "@/models/FormDesigner/FormDesigner";
import {
    SequenceGeneratorSequencesNameEnum,
    SystemParametersEnum,
    TPActiveOptions,
    TPButtonTypes,
    TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { AdditionalDataFormService } from "@/services/AdditionalDataFormService";
import { TPI18N } from "@/services/I18nService";
import { ParametersService } from "@/services/ParametersService";
import { SequenceService } from "../../services/SequenceService";
import { useAppConctextFormDesigner } from "./ContextFormDesignerAdmin";
import {
    ContainerNotification,
    ContainerPreferences,
} from "./StyleFromDesigner";
import FormView from "./FormView/FormView";
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";
import {MultilingualTextBoxEvents} from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";

interface FormDesignerTableModel {
    callBackCommands: Function;
    recordId: string;
    mode: string;
    tabId: string;
}

const FormDesignerPreferences = forwardRef(
    ({ callBackCommands, recordId, mode, tabId }: FormDesignerTableModel) => {
        const resourceSet: string = "FormDesignerComponent";

        const [baseLanguageOptions, setBaseLanguageOptions] = useState<
            Array<TPKeyValue>
        >([]);
        const [formTypeOptions, setformTypeOptions] = useState<Array<TPKeyValue>>(
            [],
        );

        //State modal
        let modalImageSelectorInitialState: TPModalImageSelectorState = {
            isShown: false,
            callBackData: {},
        };

        const [modalImageSelectorState, setModalImageSelectorState] = useState(
            modalImageSelectorInitialState,
        );
        //screen loading
        const [isLoadingScreen, setIsLoadingScreen] = useState(true);
        const [referenceId, setReferenceId] = useState("");

        const [titleLabel, setTitleLabel] = useState("");
        const [descriptionImageSectionLabel, setDescriptionImageSectionLabel] =
            useState("");

        const [primaryLanguageLabel, setPrimaryLanguageLabel] = useState("");
        const [idLabel, setIdLabel] = useState("");
        const [nameLabel, setNameLabel] = useState("");
        const [languageListLabel, setLanguageListLabel] = useState("");
        const [saveButtonModalLabel, setSaveButtonModalLabel] = useState("");
        const [cancelButtonModalLabel, setCancelButtonModalLabel] = useState("");
        const [formTypeLabel, setFormTypeLabel] = useState("");
        const [imageLabel, setImageLabel] = useState("");
        const [selectImageTitle, setSelectImageTitle] = useState("");
        const [acceptImageButtonLabel, setAcceptImageButtonLabel] = useState("");
        const [cancelImageButtonLabel, setCancelImageButtonLabel] = useState("");
        const [selectedImageLabel, setSelectedImageLabel] = useState("");
        const [saveLabel, setSaveLabel] = useState("");
        const [completeThisinformationLabel, setCompleteThisinformationLabel] =
            useState("");
        const [isHidePersonalDataLabel, setIsHidePersonalDataLabel] = useState("");
        const [showAsActiveLabel, setShowAsActiveLabel] = useState("");
        const [descriptionLabel, setDescriptionLabel] = useState("");

        const [baseLanguageOptionsError, setBaseLanguageOptionsError] = useState("");
        const [recordLocalizedModelError, setRecordLocalizedModelError] = useState("");
        const [formTypeError, setFormTypeError] = useState("");
        const [required, setRequired] = useState("");
        const nameInputRef = useRef<MultilingualTextBoxEvents>();
        const loadResourcesAndLoadInfo = async () => {
            setTitleLabel(
                await TPI18N.GetText(resourceSet, "TitleFormDesignerLabel"),
            );
            setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
            setPrimaryLanguageLabel(
                await TPI18N.GetText(resourceSet, "PrimaryLanguageLabel"),
            );
            setNameLabel(await TPI18N.GetText(resourceSet, "NameLabel"));
            setLanguageListLabel(
                await TPI18N.GetText(resourceSet, "setLanguageListLabel"),
            );
            setSaveButtonModalLabel(
                await TPI18N.GetText(resourceSet, "SaveButtonModalLabel"),
            );
            setCancelButtonModalLabel(
                await TPI18N.GetText(resourceSet, "CancelButtonModalLabel"),
            );
            setFormTypeLabel(await TPI18N.GetText(resourceSet, "FormTypeLabel"));
            setImageLabel(await TPI18N.GetText(resourceSet, "ImageLabel"));
            setSelectImageTitle(
                await TPI18N.GetText(resourceSet, "SelectImageTitle"),
            );
            setAcceptImageButtonLabel(
                await TPI18N.GetText(resourceSet, "AcceptImageButtonLabel"),
            );
            setSelectedImageLabel(
                await TPI18N.GetText(resourceSet, "SelectedImageLabel"),
            );
            setDescriptionImageSectionLabel(
                await TPI18N.GetText(resourceSet, "DescriptionImageSectionLabel"),
            );
            setSaveLabel(await TPI18N.GetText(resourceSet, "SaveLabel"));
            setCompleteThisinformationLabel(
                await TPI18N.GetText(resourceSet, "CompleteThisinformationLabel"),
            );
            setShowAsActiveLabel(
                await TPI18N.GetText(resourceSet, "ShowAsActiveLabel"),
            );
            setIsHidePersonalDataLabel(
                await TPI18N.GetText(resourceSet, "IsHidePersonalDataLabel"),
            );
            setDescriptionLabel(
                await TPI18N.GetText(resourceSet, "DescriptionLabel"),
            );
            setRequired(await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),);

        };

        const callBackVerticalTabTemp = () => {
            let command: any = { command: "new", recordId: 0 };
            callBackCommands(command);
        };

        const value = useAppConctextFormDesigner();
        const {
            rowsFormDesingner,
            isUpdate,
            handleEditForm,
            setStatusLoading,
            setIsUpdate,
        } = value;

        const handleShowModal = () => {
            let newTPModalImageSelectorState: TPModalImageSelectorState;
            newTPModalImageSelectorState = { ...modalImageSelectorState };
            newTPModalImageSelectorState.isShown = true;
            setModalImageSelectorState(newTPModalImageSelectorState);
        };

        const handleActiveSave = () => {
            let status = true;

            if (!rowsFormDesingner) return false;
            if (rowsFormDesingner.id == undefined || rowsFormDesingner.id == "")
                return false;
            if (!nameInputRef.current?.isInvalid) return false;          
            if (rowsFormDesingner.formType == undefined) return false;
            if (rowsFormDesingner.formType.key == "") return false;
            return status;
        };

        const handleCallBackModal = (
            confirmSelection: boolean,
            callBackData: any,
        ) => {
            let newTPModalImageSelectorState: TPModalImageSelectorState;
            newTPModalImageSelectorState = { ...modalImageSelectorState };
            newTPModalImageSelectorState.isShown = false;
            newTPModalImageSelectorState.callBackData = {};
            setModalImageSelectorState(newTPModalImageSelectorState);
            if (confirmSelection) {
                let newInsertUpdateState = { ...rowsFormDesingner };
                newInsertUpdateState.img = {
                    key: callBackData.selectedFile.hierarchyId,
                    value: callBackData.selectedFile.fileName,
                };

                handleEditForm(newInsertUpdateState);
            }
        };

        const SaveForm = async () => {
            if (nameInputRef.current?.isInvalid()) {
                nameInputRef.current?.markAsTouched();
                return;
            }

            const languages = nameInputRef.current?.getValue();
            const mainLanguage = languages?.find(({id}) => id === TPGlobal.TPClientDefaultLanguage);
            setStatusLoading(true);
            let insert = new FormesignerInputDTO();

            let serviceClient = new AdditionalDataFormService();
            let expectedCodes: Array<number> = [200];
            try {
                insert.id = rowsFormDesingner.id;
                insert.description = mainLanguage?.value!
                insert.languageId = mainLanguage?.id!
                insert.formTypeId = rowsFormDesingner.formType.key;
                insert.applyPersonalData = rowsFormDesingner.isHidePersonalData;
                insert.isActive = rowsFormDesingner.showAsActive;
                insert.logo =
                    rowsFormDesingner.img != undefined ? rowsFormDesingner.img.key : "";

                insert.jsonDesigner = new TextEncoder().encode(JSON.stringify(rowsFormDesingner))
                    .join(',');

                insert.descriptionLocalizedValues = [
                    {
                        languageId: mainLanguage?.id!,
                        localizedValue: mainLanguage?.value ?? "",
                        order: 1
                    },
                    ...languages!
                        .filter(({ id }) => id !== TPGlobal.TPClientDefaultLanguage)
                        .map(({ id, value }, order) => ({
                            languageId: id!,
                            localizedValue: value ?? "",
                            order: order + 2
                        }))
                ];

                insert.descriptionLocalizedValues = insert.descriptionLocalizedValues.filter(s => s.localizedValue!="");

            let responseRequest;
               
               
                if (!isUpdate) {                  
                    responseRequest = await serviceClient.insertFormDesingner(
                        insert,
                        true,
                        false,
                        expectedCodes,
                    );

                    if (responseRequest.responseResult) {
                        let language = TPGlobal.language;
                        callBackCommands({
                            result: "ChangeCloneToUpdateMode",
                            recordId: insert.id,
                            tabId: tabId,
                            language: language
                        });
                        callBackCommands({
                            result: "ReloadGrid",
                            recordId: insert.id,
                        });

                       
                    }
                } else {

                    responseRequest = await serviceClient.updateFormDesingner(
                        insert,
                        true,
                        false,
                        expectedCodes,
                    );

                    callBackCommands({
                        result: "ReloadGrid",
                        recordId: insert.id,
                    });
                }



            } catch (error) {
                TPLog.Log(`Error ${resourceSet} insert ex`, TPLogType.ERROR, error);
                console.error(`Error ${resourceSet} insert ex`);
            }

            setStatusLoading(false);
        };


        const Validate = async () => {
            let status = true;
            setBaseLanguageOptionsError("");
            setRecordLocalizedModelError("");
            setFormTypeError("");

            if (rowsFormDesingner.formType && rowsFormDesingner.formType.key == "") {
                status = false;
                setFormTypeError(required);
            }

            if (status) SaveForm();
        }



        const loadFormType = async () => {
            let parametersService = new ParametersService();
            let expectedCodes: Array<number> = [200];

            try {
                let responseRequest =
                    await parametersService.getByParentIdAndFilterIsActive(
                        SystemParametersEnum.S_FORMTYPE,
                        TPActiveOptions.ACTIVE.toString(),
                        false,
                        true,
                        expectedCodes,
                    );
                if (responseRequest) {
                    let typeForm: Array<TPKeyValue> = responseRequest.map(
                        function (item) {
                            return {
                                key: item.id,
                                value: item.localizedDescription
                                    ? item.localizedDescription
                                    : item.description,
                            };
                        },
                    );
                    typeForm.unshift({ key: "", value: "--" });
                    setformTypeOptions(typeForm.filter((s) => s.value + "" != ""));
                } else {
                    //todo logs
                    return null;
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} loadFormType ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${resourceSet} loadFormType ex`);
                return null;
            }
        };

        const generalAutomaticId = async () => {
            let serviceClient = new SequenceService();
            let expectedCodes: Array<number> = [200];

            try {
                setStatusLoading(true);
               
                let responseRequest = await serviceClient.generalAutomaticId(
                    false,
                    true,
                    expectedCodes,
                    SequenceGeneratorSequencesNameEnum.SQADFO,
                );

                setStatusLoading(false);
                if (responseRequest) {
                    let result = responseRequest?.responseData?.data[0]?.sequenceCode;
                    let data = { ...rowsFormDesingner };
                    data.id = result;                   
                    handleEditForm(data);
                    setReferenceId(result);
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} updatetFunction ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${resourceSet} updatetFunction ex`);
                setStatusLoading(false);
            }
        };

        const loadLanguages = () => {
            let i: number;
            let expectedCodes: Array<number> = [200];

            let listLanguages: Array<TPKeyValue> = [];
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: item.name };
                listLanguages.push(keyValueElement);
            }

            if (!rowsFormDesingner.recordLocalizedModel)
                rowsFormDesingner.recordLocalizedModel =
                    new Array<RecordLocalizedModel>();

            let listFormName = [...rowsFormDesingner.recordLocalizedModel];
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: RecordLocalizedModel = {
                    languageId: item.id,
                    localizedValue: "",
                    order: i,
                };
                listFormName.push(keyValueElement);
            }
            rowsFormDesingner.recordLocalizedModel = listFormName;
            handleEditForm(rowsFormDesingner);

            if (listLanguages) {
                listLanguages.unshift({ key: "", value: "--" });
                let language = TPGlobal.language;
                setBaseLanguageOptions(listLanguages);
            }
        };

        const {
            isOpen: isOpenModalLanguageList,
            openModal: handleOpenModalLanguageList,
            closeModal: handleCloseModalLanguageList,
            saveChanges: handleSaveChangesModalLanguageList,
        } = useModal(false);

        useEffect(() => {           
            if (mode == "Insert" && referenceId == "" && rowsFormDesingner.id == "") {
                generalAutomaticId();
                setIsUpdate(false);
            } else {
                setIsUpdate(true);
            }

            loadResourcesAndLoadInfo();
            loadLanguages();
            loadFormType();
        }, []);


        useEffect(() => {
            callBackCommands({
                result: "ReloadGrid",
                recordId: null,
            });          
        }, [rowsFormDesingner]);

       
        return (
            <>
                <ContainerPreferences>
                    <div style={{ height: "30px" }}>
                        <ContainerNotification color="#D3E1FC">
                            <TPIcon iconType={TPIconTypes.info} style={{}} />
                            <TPLabel
                                className="form-check-label"
                                labelText={completeThisinformationLabel}
                                style={{ cursor: "ponter" }}
                            />
                        </ContainerNotification>
                    </div>
                    <br></br>
                    <div style={{ width: "554px" }}>
                        <TPLabel
                            className="form-check-label"
                            style={{ textAlign: "left", width: "100%" }}
                            labelText={idLabel}
                        />
                       
                            <TPTextBox
                                id="IdTextTitle"
                                isInvalidChars={false}
                                disabled={true}
                                containerStyle={{ width: "550px", gap: "0px" }}
                                textStyle={{ width: "550px" }}
                                icon={TPIconTypes.refresh}
                                withIcon={false}
                                value={rowsFormDesingner.id}
                                placeholder={""}
                                onChange={(e: any) => {
                                    //let temp = { ...rowsFormDesingner };
                                    //temp.id = e.target.value;
                                    //handleEditForm(temp);
                                }}
                                isHorizontal={true}
                            />
                      
                    </div>

                    <MultilingualTextBox
                        ref={nameInputRef}
                        style={{ width: "calc(101% + 35px)" }}
                        value={rowsFormDesingner.recordLocalizedModel?.map(({languageId, localizedValue}) => ({id: languageId, value: localizedValue}))}
                    />

                    <TPSelect
                        id="IdSelect"
                        containerStyle={{ width: "550px", gap: "0px" }}
                        onChange={(e: any) => {
                            let temp = { ...rowsFormDesingner };
                            if (temp) temp.formType = { key: e.target.value, value: "" };
                            setFormTypeError("");
                            handleEditForm(temp);
                        }}
                        dataSource={formTypeOptions}
                        value={
                            rowsFormDesingner.formType && rowsFormDesingner.formType.key
                                ? rowsFormDesingner.formType.key
                                : ""
                        }
                        labelText={formTypeLabel}
                        isHorizontal={false}
                        isMandatory={true}
                        errorMessage={formTypeError}
                    ></TPSelect>

                    <TPPageSection style={{ display: "none" }}>
                        <div className="form-group">
                            <TPTextBox
                                id="IdTextBox"
                                labelText={imageLabel}
                                value={
                                    rowsFormDesingner.img && rowsFormDesingner.img.value
                                        ? rowsFormDesingner.img.value
                                        : ""
                                }
                                isMandatory={true}
                                errorMessage={""}
                                maxLength={20}
                                onChange={(e: any) => TPGlobal.foo()}
                                withIcon={true}
                                icon={TPIconTypes.image}
                                iconClick={handleShowModal}
                                disabled
                            />
                        </div>
                    </TPPageSection>

                    <TPCheckBox
                        id="IdIsMandatory"
                        labelText={showAsActiveLabel}
                        checked={rowsFormDesingner.showAsActive}
                        onChange={(e: any) => {
                            let temp = { ...rowsFormDesingner };
                            temp.showAsActive = !temp.showAsActive;
                            handleEditForm(temp);
                        }}
                    ></TPCheckBox>

                    <TPCheckBox
                        id="IdIsMandatory"
                        labelText={isHidePersonalDataLabel}
                        checked={rowsFormDesingner.isHidePersonalData}
                        onChange={(e: any) => {
                            let temp = { ...rowsFormDesingner };
                            temp.isHidePersonalData = !temp.isHidePersonalData;
                            handleEditForm(temp);
                        }}
                    ></TPCheckBox>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "50px",
                            width: "550px",
                            alignItems: "end",
                        }}
                    >
                        <TPButton
                            style={
                                !handleActiveSave()
                                    ? {
                                        backgroundColor: "#E6E6E6",
                                        borderRadius: "4px",
                                        marginRight: "10px",
                                        color: "#989898",
                                        padding: "5px",
                                    }
                                    : {
                                        backgroundColor: "#A00095",
                                        borderRadius: "4px",
                                        marginRight: "10px",
                                        color: "white",
                                        padding: "5px",
                                    }
                            }
                            type={TPButtonTypes.empty}
                            text={"1"}
                            onClick={(e: any) => {
                                Validate();
                            }}
                            icon={TPIconTypes.default}
                        >
                            {saveLabel}
                        </TPButton>
                    </div>
                </ContainerPreferences>
            </>
        );
    },
);

export default FormDesignerPreferences;
