import React, { useState, ReactNode } from "react";
import "./TPVerticalTabs.css";

interface TPVerticalTabsProps {
  tabLabels: ReactNode[];
  tabContent: ReactNode[];
}

const TPVerticalTabs: React.FC<TPVerticalTabsProps> = ({
  tabLabels,
  tabContent,
}) => {
  const [active, setActive] = useState(0);

  return (
    <div className="vertical-tabs">
      <div className="vertical-tabs__list">
        {tabLabels.map((label, index) => (
          <button
            key={index}
            className={`vertical-tabs__tab-button ${active === index ? "vertical-tabs__tab-button--active" : ""}`}
            onClick={() => setActive(index)}
          >
            {label}
          </button>
        ))}
      </div>
      <div className="vertical-tabs__content">{tabContent[active]}</div>
    </div>
  );
};

export default TPVerticalTabs;
