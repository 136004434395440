import React, { useEffect, useMemo, useState } from "react";
import useTranslations from "../hooks/useTranslations";
import TPVerticalTabs from "@/components/TPTabs/TPVerticalTabs";
import TPDescription from "@/components/bootstrap/forms/TPDescription/TPDescription";
import { useConfiguration } from "../context/ConfigurationContext";
import { OutboundMailAccountService } from "@/services/OutboundMailAccountService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPFieldContainer } from "../common/TPFieldContainer/TPFieldContainer";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";

const COMPONENT_NAME = "OutboundMailComponent.tsx";

const OutboundMailComponent: React.FC = () => {
  const RESOURCE_SET = "ConfigurationGeneralComponent";
  const { changeValueForKey, keyValues } = useConfiguration();

  const initialText = useMemo(
    () => ({
      accountTitle: "AccountTitle",
      defaultOutboundMailTitle: "DefaultOutboundMailTitle",
      accountDescriptions: "AccountDescriptions",
      defaultOutboundMailDescriptions: "DefaultOutboundMailDescriptions",
      activeLabel: "ActiveLabel",
      saveButton: "SaveButton",
    }),
    []
  );

  const translationsText = useTranslations(
    initialText,
    RESOURCE_SET,
    COMPONENT_NAME
  );

  const tabLabels = useMemo(
    () => [translationsText.defaultOutboundMailTitle],
    [keyValues, translationsText, changeValueForKey]
  );

  const tabContent = useMemo(
    () => [
      <DefaultOutboundMailConfiguration
        key="defaultOutboundMail"
        translationsText={translationsText}
      />,
    ],
    [translationsText]
  );

  return <TPVerticalTabs tabLabels={tabLabels} tabContent={tabContent} />;
};

export default OutboundMailComponent;

const DefaultOutboundMailConfiguration: React.FC<{ translationsText: any }> = ({
  translationsText,
}) => {
  const { keyValues, errorState, someChange, handleSave, changeValueForKey } =
    useConfiguration();

  const [outboundMailList, setOutboundMailList] = useState<Array<TPKeyValue>>(
    []
  );

  const loadFromInfo = async () => {
    let serviceClient = new OutboundMailAccountService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      let responseRequest = await serviceClient.getAllMailAccounts(
        false,
        true,
        expectedCodes
      );
      let newEmailAccountListState: Array<TPKeyValue> = [];
      responseRequest.forEach((item: any) => {
        newEmailAccountListState.push({
          key: item.idAccount,
          value: item.description,
          ...item,
        });
      });

      newEmailAccountListState.unshift({ key: "--", value: "--" });
      setOutboundMailList(newEmailAccountListState);
    } catch (error) {
      TPLog.Log(
        `Error ${COMPONENT_NAME} getAllMailAccounts ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${COMPONENT_NAME} getAllMailAccounts ex`);
    }
  };

  useEffect(() => {
    loadFromInfo();
  }, []);

  return (
    <div className="col">
      <TPDescription text={translationsText.defaultOutboundMailDescriptions} />

      <TPFieldContainer>
        <TPSelect
          id="IdSelect"
          labelText={translationsText.defaultOutboundMailAccountIdLabel}
          isMandatory={true}
          value={keyValues["defaultOutboundMailAccountId"]?.value}
          onChange={(e: any) =>
            changeValueForKey("defaultOutboundMailAccountId", e.target.value)
          }
          errorMessage={errorState.defaultOutboundMailAccountIdErrorMessage}
          dataSource={outboundMailList}
        />
      </TPFieldContainer>

      <TPButton
        id="IdButton"
        onClick={handleSave}
        type={TPButtonTypes.primary}
        className="fixed-save-button"
        disabled={!someChange}
        style={
          !someChange
            ? {
                backgroundColor: "#e6e6e6",
                borderColor: "#efefef",
                color: "#989898",
                cursor: "not-allowed",
              }
            : {}
        }
      >
        {translationsText.saveButton}
      </TPButton>
    </div>
  );
};
