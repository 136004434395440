import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";

export interface QuickClassifierInputDTO {
  id: string;
  description: string;
  emailTemplateHeaderId: string;
  baseLevelId: string;
  caseComments: string;
  classifier1Id: string;
  classifier2Id: string;
  classifier3Id: string;
  classifier4Id: string;
  classifier5Id: string;
  isActive: boolean;
  type: string;
  otherLocalizedValues: Array<RecordLocalizedModel>;
}

export class QuickClassifierInputDTOValidator extends Validator<QuickClassifierInputDTO> {
  constructor() {
    super();

    const resourceSet = "QuickClassifiersInsertUpdateComponent";

    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("description")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOEmptyDescription")
        .must((description) => {
          return TPGlobal.TPSanitize(description) == description;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .maxLength(200)
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      this.ruleFor("type")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTypeEmpty")
        .must((type) => {
          if (type == "--") {
            return false;
          } else {
            return true;
          }
        })
        .withMessage(resourceSet + "|InputDTOInvalidType")
        .must((type) => {
          return TPGlobal.TPSanitize(type) == type;
        })
        .withMessage(resourceSet + "|InputDTOInvalidType")
        .maxLength(1)
        .withMessage(resourceSet + "|InputDTOInvalidTypeLength");

      this.ruleForEach("otherLocalizedValues")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return x.localizedValue.length <= 200;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      //baselevel
      this.ruleFor("baseLevelId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelId")
        .must((baseLevelId) => {
          let check = TPGlobal.checkSpecialCharacters(baseLevelId);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelId")
        .must((baseLevelId) => {
          return TPGlobal.TPSanitize(baseLevelId) == baseLevelId;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelIdLength");

      //classifier1Id
      //even though it should be empty when you use it must pass a value
      //but remember to remove it before post it to server
      this.ruleFor("classifier1Id")
        .must((classifier1Id, dto) => {
          if (dto.type !== "Q" && (!classifier1Id || classifier1Id.trim() === "")) {
            return false;
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOInvalidEmpty")
        .must((classifier1Id) => {
          let check = TPGlobal.checkSpecialCharacters(classifier1Id);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassifier")
        .must((classifier1Id) => {
          return TPGlobal.TPSanitize(classifier1Id) == classifier1Id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidClassifier")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassifierLength");

      //classifier2Id
      //even though it should be empty when you use it must pass a value
      //but remember to remove it before post it to server
      this.ruleFor("classifier2Id")
        .must((classifier2Id, dto) => {
          if (dto.type !== "Q" && (!classifier2Id || classifier2Id.trim() === "")) {
            return false;
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOInvalidEmpty")
        .must((classifier2Id) => {
          let check = TPGlobal.checkSpecialCharacters(classifier2Id);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassifier")
        .must((classifier2Id) => {
          return TPGlobal.TPSanitize(classifier2Id) == classifier2Id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidClassifier")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassifierLength");

      //classifier3Id
      //even though it should be empty when you use it must pass a value
      //but remember to remove it before post it to server
      this.ruleFor("classifier3Id")
        .must((classifier3Id, dto) => {
          if (dto.type !== "Q" && (!classifier3Id || classifier3Id.trim() === "")) {
            return false;
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOInvalidEmpty")
        .must((classifier3Id) => {
          let check = TPGlobal.checkSpecialCharacters(classifier3Id);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassifier")
        .must((classifier3Id) => {
          return TPGlobal.TPSanitize(classifier3Id) == classifier3Id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidClassifier")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassifierLength");

      //classifier4Id
      //even though it should be empty when you use it must pass a value
      //but remember to remove it before post it to server
      this.ruleFor("classifier4Id")
        .must((classifier4Id, dto) => {
          if (dto.type !== "Q" && (!classifier4Id || classifier4Id.trim() === "")) {
            return false;
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOInvalidEmpty")
        .must((classifier4Id) => {
          let check = TPGlobal.checkSpecialCharacters(classifier4Id);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassifier")
        .must((classifier4Id) => {
          return TPGlobal.TPSanitize(classifier4Id) == classifier4Id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidClassifier")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassifierLength");

      //classifier5Id
      //even though it should be empty when you use it must pass a value
      //but remember to remove it before post it to server
      this.ruleFor("classifier5Id")
        .must((classifier5Id, dto) => {
          if (dto.type !== "Q" && (!classifier5Id || classifier5Id.trim() === "")) {
            return false;
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOInvalidEmpty")
        .must((classifier5Id) => {
          let check = TPGlobal.checkSpecialCharacters(classifier5Id);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassifier")
        .must((classifier5Id) => {
          return TPGlobal.TPSanitize(classifier5Id) == classifier5Id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidClassifier")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassifierLength");
    } catch (error) { }
  }
}
