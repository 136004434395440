import { InfoCardColor, InfoCardTypes } from "@/pages/SupervisorCockpit/supervisor-cockpit.model";
import { ProfileColor } from "../design-system/design-system.model";

export type BaseColors = {
    [K in InfoCardTypes]: InfoCardColor;
};

export const profileThemes: ProfileColor = {
    0: {
        color: "#E63946",
        variant: "#F8DCDC",
    },
    1: {
        color: "#F77F00",
        variant: "#FFE6CC",
    },
    2: {
        color: "#457B9D",
        variant: "#DCEFFF",
    },
    3: {
        color: "#2A9D8F",
        variant: "#D2F4EC",
    },
    4: {
        color: "#8D0801",
        variant: "#F4CCCC",
    },
};

export const baseColors: BaseColors = {
    offline: {
        color: "#989898",
        variant: "#E6E6E6",
    },
    working: {
        color: "#780096",
        variant: "#F4E0FE",
    },
    online: {
        color: "#3047B0",
        variant: "#D3EAFF",
    },
    ready: {
        color: "#009A58",
        variant: "#B1F2D7",
    },
    worked: {
        color: "#E16403",
        variant: "#FFE4B3",
    },
    case: {
        color: "#c30092",
        variant: "#fbebff"
    },
    event: {
        color: "#00826c",
        variant: "#e7fdf2"
    },
    chat: {
        color: "#E16403",
        variant: "#FFE4B3",
    },
    mail: {
        color: "#3047B0",
        variant: "#D3EAFF",
    },
    expired: {
        color: "#eb0027",
        variant: "#ffd7dd"
    },
    pending: {
        color: "#E16403",
        variant: "#FFE4B3",
    },
    inProcess: {
        color: "#009A58",
        variant: "#B1F2D7",
    },
    closed: {
        color: "#eb0027",
        variant: "#ffd7dd"
    },
    history: {
        color: "#989898",
        variant: "#E6E6E6",
    },
    processing: {
        color: "#780096",
        variant: "#F4E0FE",
    },
};