import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import AnimatedNumber from "@/modules/core/design-system/animations/AnimatedNumber";
import { baseColors } from "@/modules/core/utils/profile-status";
import React, { FC, ReactElement } from "react";
import {
  StyledInformationCard,
  StyledSmallInformationCard,
} from "../supervisor-cockpit-styles";
import {
  InfoCardColor,
  InfoCardTypes
} from "../supervisor-cockpit.model";

type CockpitInfoCardProps = {
  type: InfoCardTypes;
  label: string;
  value?: number;
  customStyle?: InfoCardColor;
  isSmallCard?: boolean;
};

const CockpitInfoCard: FC<CockpitInfoCardProps> = React.memo(
  ({ type, label, value, customStyle, isSmallCard }): ReactElement => {

    return (
      <>
        {isSmallCard ? (
          <StyledSmallInformationCard
            baseStyle={baseColors[type]}
            customStyle={customStyle}
          >
            <div className="form"></div>
            <p className="value">{label}</p>
          </StyledSmallInformationCard>
        ) : (
          <StyledInformationCard
            baseStyle={baseColors[type]}
            customStyle={customStyle}
          >
            <div className="card-info">
              <p className="label">{label}</p>
              <p className="value">
                <AnimatedNumber targetNumber={value ?? 0} />
              </p>
            </div>
            <div className="card-icon">
              <TPIcon iconType={type as TPIconTypes} />
            </div>
          </StyledInformationCard>
        )}
      </>
    );
  }
);

export default CockpitInfoCard;
