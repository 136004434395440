import TPButton from '@/components/bootstrap/components/buttons/TPButton';
import TPIcon from '@/components/bootstrap/extend/TPIcons/TPIcon';
import TPLoadingOverlay from '@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay';
import { TPChip } from '@/components/TPChip/TPChip';
import { CIMTitleSection, TPPageTitle } from '@/components/TPPage/tpPageStyles';
import TPGlobal from '@/helpers/TPGlobal';
import { TPButtonTypes, TPIconTypes } from '@/models/Global/TPGlobalEnums';
import { ListModel, ListTableModel } from '@/models/ListAdministration/ListAdministrationModels';
import DynamicTable from '@/modules/core/components/dynamic-table/DynamicTable';
import { ListsServices } from '@/services/EventsManager/ListsService';
import { FC, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import useListAdminLabels from './assets/Labeling';
import './assets/Styles.css';
import TPModal from '@/layouts/TPModal/TPModal';
import { showToast, TPToastTypes } from '@/components/bootstrap/components/toasts/TPToast';

interface ListAdminHomeProperties {
	verticalTabsCallback: Function;
}

const ListAdminHome = forwardRef(({ verticalTabsCallback }: ListAdminHomeProperties, ref) => {
	const { labels } = useListAdminLabels();
	const [lists, setLists] = useState<ListModel[]>([]);
	const [dataLoaded, setDataLoaded] = useState<boolean>(false);
	const [chosenListId, setChosenListId] = useState<string | null>(null);

	useImperativeHandle(ref, () => ({
		refreshGridFromParent() {
			fetchData();
		}
	}))

	const fetchData = function () {
		setDataLoaded(false);
		ListsServices.getAllLists(false, false, [200]).then(listsResponse => {
			setDataLoaded(true);
			setLists(listsResponse);
		});
	};

	const deleteList = async function (listId: string) {
		setDataLoaded(false);
		ListsServices.deleteList(listId, false, false, [200])
			.then(response => {
				if (response && 
					response.responseData &&
					response.responseData.responseMessage
				) {
					showToast(
						response.responseData.responseMessage.message, 
						response.responseData.responseMessage.code === 200 ? TPToastTypes.success : TPToastTypes.error);
				}
				fetchData();
			})
			.catch(err => {
				console.error(err);
				setDataLoaded(true);
			});
	}

	useEffect(() => {
		if (!dataLoaded) fetchData();
	}, []);

	const handleTableIconClick = function (icon: TPIconTypes) {
		if (icon == TPIconTypes.refresh || icon == TPIconTypes.loop) {
			fetchData();
		}
	};

	return (
		<>
			{chosenListId && (
				<TPModal
					modalState={{
						acceptLabel: "",
						callBackAnswer: (accepted: boolean) => accepted && chosenListId && deleteList(chosenListId),
						isShown: chosenListId != null,
						titleModal: "",
						hideFooterButtons: true,
						hideXButton: true,
						hiddenHeader: true,
						modalWidth: "464px"
					}}
				>
					<div className='delete-list-alert-modal'>
						<h3><b>{labels.SystemAlert}</b></h3>
						{labels.DeleteListConfirm}
						<div className='delete-list-alert-button-pair'>
							<TPButton
								onClick={() => setChosenListId(null)}
								isDesignSystem
								style={{
									backgroundColor: "white",
									color: "#780096",
									paddingLeft: "16px",
									paddingRight: "16px"
								}}
							>
								{labels.No}
							</TPButton>
							<TPButton
								onClick={() => {
									deleteList(chosenListId);
									setChosenListId(null);
								}}
								isDesignSystem
								style={{
									paddingLeft: "16px",
									paddingRight: "16px"
								}}
							>
								{labels.Yes}
							</TPButton>
						</div>
					</div>
				</TPModal>
			)}
			<TPLoadingOverlay active={!dataLoaded}>
			<div className='row'>
				<div className='col'>
					<CIMTitleSection>
						<TPPageTitle style={{ margin: 0 }}>{labels.ListAdministration}</TPPageTitle>
						<TPButton
							onClick={() =>
								verticalTabsCallback({
									command: 'new'
								})
							}
							type={TPButtonTypes.primary}
							isDesignSystem
							style={{ paddingTop: '11px', paddingBottom: '11px', paddingLeft: '16px', paddingRight: '16px' }}
						>
							<label style={{ display: "flex", gap: "8px" }}>
								<TPIcon iconType={TPIconTypes.add} />
								{labels.NewList}
							</label>
						</TPButton>
					</CIMTitleSection>
					<DynamicTable
						id="lists"
					data={lists.map(list => {
						return {
							id: list.id,
							description: list.description,
							createdBy: list.createdBy,
							alphabeticalOrder: list.alphabeticalOrder,
							isActive: list.isActive,
						} as ListTableModel;
					})}
					minorOptions={[
						{
							key: labels.Edit,
							onOptionChange: e =>
								verticalTabsCallback({
									command: 'update',
									recordId: e.id,
									languageId: TPGlobal.language,
									recordDescription: e.description
								}),
							type: 'edit',
							icon: TPIconTypes.edit
						},
						{
							key: labels.Delete,
							onOptionChange: e => setChosenListId(e.id),
							type: 'delete',
							icon: TPIconTypes.delete
						},
						{
							key: labels.Clone,
							onOptionChange: e =>
								verticalTabsCallback({
									command: 'clone',
									recordId: e.id,
									languageId: TPGlobal.language,
									recordDescription: e.description
								}),
							type: 'clone',
							icon: TPIconTypes.clone
						}
					]}
					withPreferences
					columnNames={{
						id: labels.Id,
						alphabeticalOrder: labels.AlphabeticalOrder,
						description: labels.Name,
						isActive: labels.Active,
						createdBy: labels.CreatedBy,
					}}
					columnStyles={{
						id: ({ item, value }) => (
							<b
								style={{ cursor: 'pointer', color: 'purple' }}
								onClick={() =>
									verticalTabsCallback({
										command: 'update',
										recordId: item.id,
										languageId: TPGlobal.language,
										recordDescription: item.description
									})
								}
							>
								<u>{value}</u>
							</b>
						),
						alphabeticalOrder: ({ item, value }) => (
							<TPChip backgroundColor={value ? '#B1F2D7' : '#FFD7DD'} label={value ? labels.Yes : labels.No} />
						),
						isActive: ({ item, value }) => (
							<TPChip backgroundColor={value ? '#B1F2D7' : '#FFD7DD'} label={value ? labels.Yes : labels.No} />
						)
					}}
					onIconClicked={handleTableIconClick}
				/>
				</div>
			</div>
		</TPLoadingOverlay>
		</>

	);
});

export default ListAdminHome;
