import React, { useCallback, useEffect, useMemo, useState } from "react";
import useTranslations from "../hooks/useTranslations";
import TPVerticalTabs from "@/components/TPTabs/TPVerticalTabs";
import TPDescription from "@/components/bootstrap/forms/TPDescription/TPDescription";
import { useConfiguration } from "../context/ConfigurationContext";
import TabLabelWithSwitch from "../common/TabLabelWithSwitch/TabLabelWithSwitch";
import { TPFieldContainer } from "../common/TPFieldContainer/TPFieldContainer";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import LanguageService from "@/services/LanguageService";
import {
  TPButtonTypes,
  TPLanguageFilterEnum,
} from "@/models/Global/TPGlobalEnums";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import LanguageSelector from "../common/TPLanguageSelector/TPLanguageSelector";
import TPCollapsableSection from "@/components/bootstrap/components/collapsable/TPCollapsableSection";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { TPChip } from "@/components/TPChip/TPChip";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";

const COMPONENT_NAME = "ServicesComponent.tsx";

const ServicesComponent: React.FC = () => {
  const RESOURCE_SET = "ConfigurationGeneralComponent";
  const { changeValueForKey, saveFieldsConfiguration, keyValues } =
    useConfiguration();

  const initialText = useMemo(
    () => ({
      storyfAITitle: "StoryfAITitle",
      storyfAIDescriptions: "StoryfAIDescriptions",
      storyfAIDescriptionsProcessed: "StoryfAIDescriptionsProcessed",
      aiPoweredTitle: "AIPoweredTitle",
      aiPoweredDescriptions: "AIPoweredDescriptions",
      activeLabel: "ActiveLabel",
      emptyLabel: "EmptyLabel",

      storyfAITranslationLabel: "StoryfAITranslationLabel",
      storyfAITranslationDescription: "StoryfAITranslationDescription",

      storyfAIGrammarCheckerLabel: "StoryfAIGrammarCheckerLabel",
      storyfAIGrammarCheckerDescription: "StoryfAIGrammarCheckerDescription",

      storyfAISentimentAnalysisLabel: "StoryfAISentimentAnalysisLabel",
      storyfAISentimentAnalysisDescription:
        "StoryfAISentimentAnalysisDescription",

      storyfAIPIILabel: "StoryfAIPIILabel",
      storyfAIPIIDescription: "StoryfAIPIIDescription",

      storyfAIAudioTranscriptionLabel: "StoryfAIAudioTranscriptionLabel",
      storyfAIAudioTranscriptionDescription:
        "StoryfAIAudioTranscriptionDescription",

      StoryFaiServiceUrl: "StoryFaiServiceUrl",
      StroyFaiApiKey: "StroyFaiApiKey",
      StoryFaiPreview: "StoryFaiPreview",
      StoryFaiAvailableLanguages: "StoryFaiAvailableLanguages",
      StoryFaiLanguageBase: "StoryFaiLanguageBase",
      storyfAIServiceProvider: "StoryfAIServiceProvider",
      storyFaiAvailableLanguagesLabel: "StoryFaiAvailableLanguagesLabel",
      storyfAIDefaultLanguageLabel: "StoryfAIDefaultLanguageLabel",

      storyfAIServiceURLLabel: "StoryfAIServiceURLLabel",
      storyfAIServiceApiKeyLabel: "StoryfAIServiceApiKeyLabel",
      processButton: "ProcessButton",
      translationProviderLabel: "TranslationProviderLabel",
      backButton: "BackButton",
      storyfAITranslateAvailableLanguagesInput:
        "StoryfAITranslateAvailableLanguagesInput",
      storyFaiLanguageBaseLabel: "StoryFaiLanguageBaseLabel",
      glossaryLabel: "GlossaryLabel",
      yesLabel: "YesLabel",
      noLabel: "NoLabel",

      TPGenAIServiceUrlInput: "Service URL AI-Powered",
      TPGenAIApiKeyInput: "Token AI-Powered",
      TPGenAIAvailableLanguagesInput: "Available Languages",
      TPGenAILanguageBaseInput: "Language Base AI-Powered",
      TPGenAIMinimumWordCountInput: "Minimum Word Count AI-Powered",
      saveButton: "SaveButton",
    }),
    []
  );

  const translationsText = useTranslations(
    initialText,
    RESOURCE_SET,
    COMPONENT_NAME
  );

  const tabConfigs = useMemo(
    () => [
      {
        key: "enableStoryFai",
        title: translationsText.storyfAITitle,
      },
      {
        key: "enableTPGenAI",
        title: translationsText.aiPoweredTitle,
      },
    ],
    [translationsText]
  );

  const tabLabels = useMemo(
    () =>
      tabConfigs.map(({ key, title }) => (
        <TabLabelWithSwitch
          key={key}
          title={title}
          isActive={keyValues[key]?.value === "1"}
          onToggle={async () => {
            const newValue = keyValues[key]?.value === "1" ? "0" : "1";
            changeValueForKey(key, newValue);
            await saveFieldsConfiguration({ [key]: newValue });
          }}
          labelText={translationsText.activeLabel}
        />
      )),
    [tabConfigs, keyValues, translationsText, changeValueForKey]
  );

  const tabContent = useMemo(
    () => [
      <StoryfAIConfiguration
        key="storyfAI"
        translationsText={translationsText}
      />,
      <AIPoweredConfiguration
        key="aiPowered"
        translationsText={translationsText}
      />,
    ],
    [translationsText]
  );

  return <TPVerticalTabs tabLabels={tabLabels} tabContent={tabContent} />;
};

export default ServicesComponent;

const StoryfAIConfiguration: React.FC<{ translationsText: any }> = ({
  translationsText,
}) => {
  const {
    keyValues,
    errorState,
    reloadData,
    changeValueForKey,
    setIsLoadingScreen,
    saveFieldsConfiguration,
    someChange,
    handleSave,
  } = useConfiguration();

  const [isProcessed, setIsProcessed] = useState<boolean>(false);
  const [selectedProviders, setSelectedProviders] = useState<string[]>(() =>
    JSON.parse(keyValues["storyfAITranslationProviders"]?.value || "[]")
  );
  const [languageData, setLanguageData] = useState<Array<TPKeyValue>>([]);
  const [audioLanguageData, setAudioLanguageData] = useState<Array<TPKeyValue>>(
    []
  );
  const [languages, setLanguages] = useState<TPKeyValue[]>([]);
  const [serviceUrl, setServiceUrl] = useState<string>("");
  const [apiKey, setApiKey] = useState<string>("");
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const categoriesData = useMemo(
    () => [
      {
        category: "Age",
        speechToText: true,
        translate: true,
        audioTranscription: false,
        realTimeTranscription: false,
        scripts: false,
      },
      {
        category: "Credit card",
        speechToText: true,
        translate: true,
        audioTranscription: false,
        realTimeTranscription: false,
        scripts: false,
      },
      {
        category: "Email",
        speechToText: true,
        translate: true,
        audioTranscription: false,
        realTimeTranscription: false,
        scripts: false,
      },
      {
        category: "Person",
        speechToText: true,
        translate: true,
        audioTranscription: false,
        realTimeTranscription: false,
        scripts: false,
      },
      {
        category: "URL",
        speechToText: true,
        translate: true,
        audioTranscription: false,
        realTimeTranscription: false,
        scripts: false,
      },
    ],
    []
  );

  const columnStyles = useMemo(() => {
    return Object.keys(categoriesData[0] || {})
      .filter((key) => key !== "category")
      .reduce(
        (acc, columnKey) => {
          acc[columnKey] = ({ value }: { value: boolean }) => (
            <TPChip
              backgroundColor={value ? "#B1F2D7" : "#FFD7DD"}
              label={value ? "Yes" : "No"}
            />
          );
          return acc;
        },
        {} as Record<string, (props: { value: boolean }) => JSX.Element>
      );
  }, [categoriesData]);

  const translationProviders = useMemo(
    () => [
      { key: "Microsoft", label: "Microsoft" },
      { key: "DeepL", label: "DeepL" },
      { key: "IbmWatson", label: "IbmWatson" },
      { key: "Yandex", label: "Yandex" },
      { key: "Google", label: "Google" },
      { key: "Baidu", label: "Baidu" },
    ],
    []
  );

  const servicesProvider: TPKeyValue[] = useMemo(
    () => [{ key: "azure", value: "AZURE" }],
    []
  );

  const handleProviderChange = useCallback(
    (provider: string) => {
      let updatedProviders;
      if (selectedProviders.includes(provider)) {
        updatedProviders = selectedProviders.filter((p) => p !== provider);
      } else {
        updatedProviders = [...selectedProviders, provider];
      }
      setSelectedProviders(updatedProviders);
      changeValueForKey(
        "storyfAITranslationProviders",
        JSON.stringify(updatedProviders)
      );
    },
    [selectedProviders, changeValueForKey]
  );

  const handleProcess = useCallback(async () => {
    try {
      setIsLoadingScreen(true);

      await saveFieldsConfiguration({
        storyFaiServiceUrl: serviceUrl,
        storyFaiApiKey: apiKey,
      });
      await reloadData();

      setIsDirty(false);
      setIsProcessed(true);
    } catch (error) {
      TPLog.Log(
        `Error ${COMPONENT_NAME} handleProcess ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${COMPONENT_NAME} handleProcess ex`);
    } finally {
      setIsLoadingScreen(false);
    }
  }, [setIsLoadingScreen, serviceUrl, apiKey]);

  const handleLanguageChange = useCallback(
    (languages: Array<TPKeyValue>) => {
      setLanguageData(languages);
      const compactFormat = languages
        .map(({ key, value }) => `${key}$${value}`)
        .join("|");

      changeValueForKey("storyFaiAvailableLanguages", compactFormat);
    },
    [changeValueForKey]
  );

  const handleAudioLanguageChange = useCallback(
    (languages: Array<TPKeyValue>) => {
      setAudioLanguageData(languages);
      const languagesJson = JSON.stringify(languages);
      changeValueForKey("storyFaiAudioAvailableLanguages", languagesJson);
    },
    [changeValueForKey]
  );

  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {
    setServiceUrl(keyValues["storyFaiServiceUrl"]?.value || "");
    setApiKey(keyValues["storyFaiApiKey"]?.value || "");
    setIsProcessed(
      keyValues["storyFaiApiKey"]?.value &&
        keyValues["storyFaiServiceUrl"]?.value
    );
  }, [keyValues]);

  useEffect(() => {
    setIsDirty(!!serviceUrl && !!apiKey);
  }, [serviceUrl, apiKey]);

  useEffect(() => {
    const parseAndSetState = (
      key: string,
      setState: React.Dispatch<React.SetStateAction<any[]>>
    ) => {
      const storedValue = keyValues[key]?.value;
      if (storedValue) {
        try {
          const parsedValue = JSON.parse(storedValue);
          if (Array.isArray(parsedValue)) {
            setState(parsedValue);
          }
        } catch (error) {
          console.error(`Error parsing ${key} from keyValues:`, error);
        }
      }
    };

    parseAndSetState("storyFaiAudioAvailableLanguages", setAudioLanguageData);
    parseAndSetState("storyfAITranslationProviders", setSelectedProviders);
  }, [keyValues]);

  useEffect(() => {
    const parseAndSetLanguages = (
      key: string,
      setState: React.Dispatch<React.SetStateAction<any[]>>
    ) => {
      const storedValue = keyValues[key]?.value;
      if (storedValue) {
        try {
          const parsedValue = storedValue
            .split("|")
            .map((entry: any) => {
              const [key, value] = entry.split("$");
              if (key && value) {
                return { key, value };
              }
              return null;
            })
            .filter(Boolean);

          setState(parsedValue);
        } catch (error) {
          console.error(`Error parsing ${key} from keyValues:`, error);
        }
      }
    };

    parseAndSetLanguages("storyFaiAvailableLanguages", setLanguageData);
  }, [keyValues]);

  const getLanguages = async () => {
    const languageClient = new LanguageService();
    const expectedCodes: Array<number> = [200, 404];
    const response = await languageClient.getLanguageByFilter(
      TPLanguageFilterEnum.ConfiguredForExternalServicesActive,
      false,
      true,
      expectedCodes
    );

    const languagesData = response.responseData.data;
    const languagesToSelectConverted: Array<TPKeyValue> = languagesData.map(
      (k: any) => ({ key: k.id, value: k.name })
    );
    languagesToSelectConverted.unshift({ key: "", value: "--" });
    setLanguages(languagesToSelectConverted);
  };

  return (
    <div className="col">
      {isProcessed ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TPDescription
            className="alert alert-info"
            text={translationsText.storyfAIDescriptionsProcessed}
          />

          <TPButton
            id="back"
            type={TPButtonTypes.link}
            onClick={() => setIsProcessed(false)}
            className={"ms-2"}
          >
            {translationsText.backButton}
          </TPButton>
        </div>
      ) : (
        <TPDescription text={translationsText.storyfAIDescriptions} />
      )}

      {keyValues["enableStoryFai"]?.value === "1" && !isProcessed && (
        <>
          <TPFieldContainer>
            <TPTextBox
              id="ServiceUrlTextBox"
              isMandatory={true}
              onChange={(e: any) => setServiceUrl(e.target.value)}
              value={serviceUrl}
              labelText={translationsText.storyFaiServiceUrlLabel}
              errorMessage={errorState.storyFaiServiceUrlErrorMessage}
              isInvalidChars={false}
            />
          </TPFieldContainer>

          <TPFieldContainer>
            <TPTextBox
              id="ApiKeyTextBox"
              isMandatory={true}
              onChange={(e: any) => setApiKey(e.target.value)}
              value={apiKey}
              labelText={translationsText.storyFaiApiKeyLabel}
              errorMessage={errorState.storyFaiApiKeyErrorMessage}
              isInvalidChars={false}
            />
          </TPFieldContainer>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <TPButton
              id="IdButton"
              onClick={handleProcess}
              type={TPButtonTypes.primary}
              disabled={!isDirty}
              style={
                !isDirty
                  ? {
                      backgroundColor: "#e6e6e6",
                      borderColor: "#efefef",
                      color: "#989898",
                      cursor: "not-allowed",
                    }
                  : {}
              }
            >
              {translationsText.processButton}
            </TPButton>
          </div>
        </>
      )}

      {keyValues["enableStoryFai"]?.value === "1" && isProcessed && (
        <>
          <TPCollapsableSection
            title={translationsText.storyfAITranslationLabel}
            description={translationsText.storyfAITranslationDescription}
            enableSwitch
            switchState={
              keyValues["storyfAITranslationModuleActive"]?.value === "1"
            }
            switchLabel={translationsText.activeLabel}
            onSwitchChange={() => {
              changeValueForKey(
                "storyfAITranslationModuleActive",
                keyValues["storyfAITranslationModuleActive"]?.value === "1"
                  ? "0"
                  : "1"
              );
            }}
          >
            <>
              {keyValues["storyfAITranslationModuleActive"]?.value === "1" && (
                <>
                  <TPFieldContainer>
                    <p>{translationsText.translationProviderLabel}</p>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: "10px",
                      }}
                    >
                      {translationProviders.map((provider) => (
                        <label
                          key={provider.key}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedProviders.includes(provider.key)}
                            onChange={() => handleProviderChange(provider.key)}
                            style={{
                              appearance: "none",
                              width: "16px",
                              height: "16px",
                              borderRadius: "50%",
                              border: "2px solid #7b7b7b",
                              display: "inline-block",
                              position: "relative",
                              outline: "none",
                              cursor: "pointer",
                              backgroundColor: selectedProviders.includes(
                                provider.key
                              )
                                ? "#7b7b7b"
                                : "#fff",
                              transition:
                                "background-color 0.3s, border-color 0.3s",
                            }}
                          />
                          {provider.label}
                        </label>
                      ))}
                    </div>
                  </TPFieldContainer>

                  <LanguageSelector
                    id="storyFaiTranslateAva"
                    labelText={
                      translationsText.storyfAITranslateAvailableLanguagesInput
                    }
                    emptyLabel={translationsText.emptyLabel}
                    selectedLanguages={languageData}
                    onChange={handleLanguageChange}
                    languageFilterType={
                      TPLanguageFilterEnum.ConfiguredForExternalServicesActive
                    }
                  />

                  <TPFieldContainer>
                    <TPSelect
                      id="IdSelect"
                      labelText={translationsText.storyFaiLanguageBaseLabel}
                      isMandatory={true}
                      value={keyValues["storyFaiLanguageBase"]?.value || ""}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        changeValueForKey(
                          "storyFaiLanguageBase",
                          e.target.value
                        )
                      }
                      errorMessage={errorState.storyFaiLanguageBaseErrorMessage}
                      dataSource={languageData}
                    />
                  </TPFieldContainer>

                  <TPFieldContainer>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <p style={{ margin: 0 }}>
                        {translationsText.glossaryLabel}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          alignItems: "center",
                        }}
                      >
                        {["1", "0"].map((value) => (
                          <label
                            key={value}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              type="radio"
                              name="glossary"
                              value={value}
                              checked={
                                keyValues["glossaryEnabled"]?.value === value
                              }
                              onChange={() =>
                                changeValueForKey("glossaryEnabled", value)
                              }
                              style={{
                                appearance: "none",
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                border: "2px solid #7b7b7b",
                                backgroundColor:
                                  keyValues["glossaryEnabled"]?.value === value
                                    ? "#7b7b7b"
                                    : "#fff",
                                cursor: "pointer",
                                transition:
                                  "background-color 0.3s, border-color 0.3s",
                              }}
                            />
                            {value === "1"
                              ? translationsText.yesLabel
                              : translationsText.noLabel}
                          </label>
                        ))}
                      </div>
                    </div>
                  </TPFieldContainer>
                </>
              )}
            </>
          </TPCollapsableSection>

          <TPCollapsableSection
            title={translationsText.storyfAIGrammarCheckerLabel}
            description={translationsText.storyfAIGrammarCheckerDescription}
            enableSwitch
            switchState={
              keyValues["storyfAIGrammarCheckerModuleActive"]?.value === "1"
            }
            switchLabel={translationsText.activeLabel}
            onSwitchChange={() => {
              changeValueForKey(
                "storyfAIGrammarCheckerModuleActive",
                keyValues["storyfAIGrammarCheckerModuleActive"]?.value === "1"
                  ? "0"
                  : "1"
              );
            }}
          ></TPCollapsableSection>

          <TPCollapsableSection
            title={translationsText.storyfAISentimentAnalysisLabel}
            description={translationsText.storyfAISentimentAnalysisDescription}
            enableSwitch
            switchState={
              keyValues["storyfAISentimentAnalysisModuleActive"]?.value === "1"
            }
            switchLabel={translationsText.activeLabel}
            onSwitchChange={() => {
              changeValueForKey(
                "storyfAISentimentAnalysisModuleActive",
                keyValues["storyfAISentimentAnalysisModuleActive"]?.value ===
                  "1"
                  ? "0"
                  : "1"
              );
            }}
          ></TPCollapsableSection>

          <TPCollapsableSection
            title={translationsText.storyfAIPIILabel}
            description={translationsText.storyfAIPIIDescription}
            enableSwitch
            switchState={keyValues["storyfAIPIIModuleActive"]?.value === "1"}
            switchLabel={translationsText.activeLabel}
            onSwitchChange={() => {
              changeValueForKey(
                "storyfAIPIIModuleActive",
                keyValues["storyfAIPIIModuleActive"]?.value === "1" ? "0" : "1"
              );
            }}
          >
            <DynamicTable
              data={categoriesData}
              columnStyles={columnStyles}
              exportNameFile={translationsText.pageTitle}
              withPreferences
            />
          </TPCollapsableSection>

          <TPCollapsableSection
            title={translationsText.storyfAIAudioTranscriptionLabel}
            description={translationsText.storyfAIAudioTranscriptionDescription}
            enableSwitch
            switchState={
              keyValues["storyfAIAudioTranscriptionModuleActive"]?.value === "1"
            }
            switchLabel={translationsText.activeLabel}
            onSwitchChange={() => {
              changeValueForKey(
                "storyfAIAudioTranscriptionModuleActive",
                keyValues["storyfAIAudioTranscriptionModuleActive"]?.value ===
                  "1"
                  ? "0"
                  : "1"
              );
            }}
          >
            <>
              <TPSelect
                id="IdSelect"
                labelText={translationsText.storyfAIServiceProvider}
                isMandatory={true}
                value={keyValues["storyfAIServiceProvider"]?.value}
                onChange={(e: any) =>
                  changeValueForKey("storyfAIServiceProvider", e.target.value)
                }
                errorMessage={errorState.storyfAIServiceProviderErrorMessage}
                dataSource={servicesProvider}
              />

              <LanguageSelector
                id="storyFaiAudioTranslateAva"
                labelText={translationsText.storyFaiAvailableLanguagesLabel}
                emptyLabel={translationsText.emptyLabel}
                selectedLanguages={audioLanguageData}
                onChange={handleAudioLanguageChange}
                languageFilterType={
                  TPLanguageFilterEnum.ConfiguredForExternalServicesActive
                }
              />

              <TPFieldContainer>
                <TPSelect
                  id="IdSelect"
                  labelText={translationsText.storyfAIDefaultLanguageLabel}
                  isMandatory={true}
                  value={keyValues["storyfAIDefaultLanguage"]?.value || ""}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    changeValueForKey("storyfAIDefaultLanguage", e.target.value)
                  }
                  errorMessage={errorState.storyfAIDefaultLanguageErrorMessage}
                  dataSource={audioLanguageData}
                />
              </TPFieldContainer>
            </>
          </TPCollapsableSection>

          <TPButton
            id="IdButton"
            onClick={handleSave}
            type={TPButtonTypes.primary}
            className="fixed-save-button"
            disabled={!someChange}
            style={
              !someChange
                ? {
                    backgroundColor: "#e6e6e6",
                    borderColor: "#efefef",
                    color: "#989898",
                    cursor: "not-allowed",
                  }
                : {}
            }
          >
            {translationsText.saveButton}
          </TPButton>
        </>
      )}
    </div>
  );
};

const AIPoweredConfiguration: React.FC<{ translationsText: any }> = ({
  translationsText,
}) => {
  const { keyValues, errorState, changeValueForKey, someChange, handleSave } =
    useConfiguration();

  const [languageData2, setLanguageData2] = useState<Array<TPKeyValue>>([]);

  const convertLanguagesToPipeFormat = (
    languages: Array<{ key: string; value: string }>
  ) => {
    return languages.map(({ key, value }) => `${key}$${value}`).join("|");
  };

  useEffect(() => {
    const parseAndSetLanguages = () => {
      const rawLanguages = keyValues["tPGenAIAvailableLanguages"]?.value;
      if (rawLanguages) {
        try {
          const parsedLanguages = rawLanguages
            .split("|")
            .map((entry: any) => {
              const [key, value] = entry.split("$");
              if (key && value) {
                return { key, value };
              }
              return null;
            })
            .filter(Boolean);

          if (Array.isArray(parsedLanguages)) {
            setLanguageData2(parsedLanguages);
          }
        } catch (error) {
          console.error(
            "Error parsing tPGenAIAvailableLanguages from keyValues:",
            error
          );
        }
      }
    };

    parseAndSetLanguages();
  }, [keyValues]);

  return (
    <div className="col">
      <TPDescription text={translationsText.aiPoweredDescriptions} />

      {keyValues["enableTPGenAI"]?.value === "1" && (
        <>
          <TPFieldContainer>
            <TPTextBox
              id="tPGenAIServiceUrl"
              isMandatory={true}
              onChange={(e: any) => {
                changeValueForKey("tPGenAIServiceUrl", e.target.value);
              }}
              value={keyValues["tPGenAIServiceUrl"]?.value}
              labelText={translationsText.TPGenAIServiceUrlInput}
              errorMessage={errorState.tPGenAIServiceUrlErrorMessage || ""}
              isInvalidChars={false}
            />
          </TPFieldContainer>

          <TPFieldContainer>
            <TPTextBox
              id="tPGenAIApiKey"
              isMandatory={true}
              onChange={(e: any) => {
                changeValueForKey("tPGenAIApiKey", e.target.value);
              }}
              value={keyValues["tPGenAIApiKey"]?.value}
              labelText={translationsText.TPGenAIApiKeyInput}
              errorMessage={errorState.tPGenAIApiKeyErrorMessage || ""}
              isInvalidChars={false}
            />
          </TPFieldContainer>

          <LanguageSelector
            id="aiPoweredTranslateAva"
            labelText={translationsText.TPGenAIAvailableLanguagesInput}
            emptyLabel={translationsText.emptyLabel}
            selectedLanguages={languageData2}
            onChange={(selected) => {
              changeValueForKey(
                "tPGenAIAvailableLanguages",
                convertLanguagesToPipeFormat(selected)
              );
            }}
            languageFilterType={
              TPLanguageFilterEnum.ConfiguredForExternalServicesActive
            }
          />

          <TPFieldContainer>
            <TPSelect
              id="tPGenAILanguageBase"
              labelText={translationsText.TPGenAILanguageBaseInput}
              isMandatory={true}
              value={keyValues["tPGenAILanguageBase"]?.value}
              onChange={(e: any) => {
                changeValueForKey("tPGenAILanguageBase", e.target.value);
              }}
              dataSource={languageData2}
              errorMessage={errorState.tPGenAILanguageBaseErrorMessage || ""}
            />
          </TPFieldContainer>

          <TPFieldContainer>
            <TPTextBox
              id="tPGenAIMinimumWordCount"
              isMandatory={true}
              onChange={(e: any) => {
                changeValueForKey("tPGenAIMinimumWordCount", e.target.value);
              }}
              value={keyValues["tPGenAIMinimumWordCount"]?.value}
              labelText={translationsText.TPGenAIMinimumWordCountInput}
              errorMessage={
                errorState.tPGenAIMinimumWordCountErrorMessage || ""
              }
              isInvalidChars={false}
            />
          </TPFieldContainer>

          <TPButton
            id="IdButton"
            onClick={handleSave}
            type={TPButtonTypes.primary}
            className="fixed-save-button"
            disabled={!someChange}
            style={
              !someChange
                ? {
                    backgroundColor: "#e6e6e6",
                    borderColor: "#efefef",
                    color: "#989898",
                    cursor: "not-allowed",
                  }
                : {}
            }
          >
            {translationsText.saveButton}
          </TPButton>
        </>
      )}
    </div>
  );
};
