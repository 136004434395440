import { FC, useEffect, useRef, useState } from "react";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import "./TPFileSelectorStyles.css";
import { useTPFileSeelctorLabels } from "./TPFileSelectorLabels";

interface TPFileSelectorProps {
  id: string;
  chooseText?: string;
  chosenText?: string;
  infoText?: string;
  maxSize?: number; // Bytes
  sizeUnit?: "B" | "KB" | "MB";
  disabled?: boolean;
  onClick?: Function;
  value?: File | null;
  onChange?: (file: File | null) => void;
  fileNameOverride?: string;
  hideSizeInfo?: boolean;
  overridePreview?: string;
  mandatory?: boolean;
  errorMessage?: string;
  selectedLabel?: string;
  isFileAlreadyChosen?: boolean;
  onFileRemoval?: Function;
}

export const TPFileSelector: FC<TPFileSelectorProps> = function({ 
  id, 
  chooseText,
  chosenText, 
  infoText, 
  maxSize = 500 * 1024 * 1024, 
  sizeUnit = "MB",
  disabled,
  onChange,
  onClick,
  value = null,
  fileNameOverride,
  hideSizeInfo = false,
  overridePreview = "",
  mandatory = false,
  errorMessage,
  selectedLabel,
  isFileAlreadyChosen = false,
  onFileRemoval
}) {
  const [file, setFile] = useState<File | null>(value);
  const [preview, setPreview] = useState<string | null>(overridePreview || null);
  const [fileSizeError, setFileSizeError] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const {labels} = useTPFileSeelctorLabels();

  const handleClick = function() {
    if (fileInputRef.current) fileInputRef.current.click();
    onClick && onClick();
  }

  const handleDrop = function(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    handleFileChange(e.dataTransfer.files![0]);
  }

  const handleFileChange = function(newFile: File | null) {
    if (disabled) return;
    if (!newFile) return;
    if (newFile.size > maxSize) {
      setFileSizeError(labels.FileSizeError);
      return;
    }

    setFile(newFile);
    setFileSizeError("");

    if (newFile.type.startsWith('image/')) {
      const imageUrl = URL.createObjectURL(newFile);
      setPreview(imageUrl);
    } else {
      setPreview(null);
    }
  }

  const removeFile = function(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();
    setFile(null);
    setPreview(null);
    onFileRemoval && onFileRemoval();
  }

  useEffect(() => {
    setFile(value);
  }, [value])

  return (
    <div 
      className="tp-file-selector"
      onDrop={handleDrop}
      onDragOver={e => e.preventDefault()}
      onClick={handleClick}
      style={{
        borderColor: errorMessage ? "red" : "#BFBFBF"
      }}
    >
      <input 
        ref={fileInputRef}
        id={id}
        type="file"
        accept="*/*"
        onChange={e => handleFileChange(e.target.files![0])}
        style={{display:"none"}}
        disabled={disabled}
      />
      <div className="tp-file-selector-left">
        {(overridePreview || preview) ? (
          <img src={overridePreview || preview || ""} alt="Selected" className="tp-file-selector-preview" />)
          :
          (<TPIcon iconType={TPIconTypes.cloud} style={{fontSize: "38px", color: "#989898"}} />)
        }
        <div className="tp-file-selector-info">
          <div>
            {fileNameOverride || file?.name || infoText || labels.SelectAFileOfType}
            {mandatory && (<span style={{color:"red"}}>*</span>)}
          </div>
          {!hideSizeInfo && (
            <div className="tp-file-selector-size-info">
            {file ? labels.Size : labels.MaxSize}:
            {" "}
            {sizeUnit === "B" && (file ? file.size : maxSize).toFixed(1)} 
            {sizeUnit === "KB" && ((file ? file.size : (maxSize))/1024).toFixed(1)} 
            {sizeUnit === "MB" && ((file ? file.size : (maxSize))/1024/1024).toFixed(1)}
            {" "}
            {sizeUnit}
          </div>)}
          {(file || isFileAlreadyChosen) && (
            <b style={{color:"#009A58", fontSize: "12px"}}>{selectedLabel || labels.Selected}</b>
          )}
          {fileSizeError && 
            <div className="invalid-feedback" style={{display:"block"}}>{fileSizeError}</div>}
          {errorMessage && 
            <div className="invalid-feedback" style={{display:"block"}}>{errorMessage}</div>}
        </div>
      </div>
      <div className="tp-file-selector-right">
        <label className="tp-file-selector-button" htmlFor={id}>
          {(file || isFileAlreadyChosen) ? (chosenText || labels.SelectAnother) : (chooseText || labels.SelectFile)}
        </label>
        {(file || isFileAlreadyChosen) && (
          <button type="button" onClick={removeFile} className="tp-file-selector-x-button">
            <TPIcon 
              iconType={TPIconTypes.cross}
              style={{fontSize: "24px"}} 
            />
          </button>)}
      </div>
    </div>
  )
}