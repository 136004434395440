import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { Validator } from "fluentvalidation-ts";
import { RecordLocalizedModel } from "../Global/TPGlobalModels";

export class FormesignerInputDTO {
  id: string = "";
  description: string = "";
  languageId: string = "";
  formTypeId: string = "";
  applyPersonalData: boolean = false;
  isActive: boolean = false;
  logo: string = "";
  jsonDesigner: string = "";
  descriptionLocalizedValues: RecordLocalizedModel[] =
    new Array<RecordLocalizedModel>();
}

export class FormCloneInputDTO {
  baseId: string = "";
  newId: string = "";
  description: string = "";
  // languageId: string = "";
  formTypeId: string = "";
  applyPersonalData: boolean = false;
  isActive: boolean = true;
  otherLocalizedValues: RecordLocalizedModel[] =
    new Array<RecordLocalizedModel>();
}

export class FormCloneInputDTOValidator extends Validator<FormCloneInputDTO> {
  constructor() {
    super();

    const resourceSet = "FormCloneInputDTO";

    try {
      this.ruleFor("baseId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOBaseIdEmpty")
        .must((baseId) => {
          let check = TPGlobal.checkSpecialCharacters(baseId);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidBaseIdChar")
        .must((baseId) => {
          return TPGlobal.TPSanitize(baseId) == baseId;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidBaseIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidBaseIdLength");

      this.ruleFor("description")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTODescriptionEmpty")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .maxLength(100)
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      // this.ruleFor("languageId")
      // .notEmpty()
      // .withMessage(resourceSet + "|InputDTOLanguageIdEmpty")
      //  .must((name) => {
      //   return TPGlobal.TPSanitize(name) == name;
      // })
      // .withMessage(resourceSet + "|InputDTOInvalidLanguageId")
      // .maxLength(20)
      // .withMessage(resourceSet + "|InputDTOInvalidLanguageIdLength");

      this.ruleForEach("otherLocalizedValues")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return x.localizedValue.length <= 100;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");
    } catch (error) {}
  }
}

export class FormDeignerGrid {
    id: string = "";
    description: string = "";
    languageId: string = "";
    formTypeLocalizedDescription: string = "";
    isActive: boolean = true;
}

export class FormDeignerModel {
  id: string = "";
  description: string = "";
  image: string = "";
  width: number = 0;
  height: number = 0;
  isActive: boolean = true;
  visibleForCustomer: boolean = true;
  userAuthentication: string = "";
  languageId: string = "";
  descriptions: RecordLocalizedModel[] = new Array<RecordLocalizedModel>();
  logo: string = "";
  jsonDesigner: string = "";
  formTypeLocalizedDescription: string="";
  localizedDescription: string="";
  formTypeId: string="";
  applyPersonalData: boolean = true;
}

export class FormModel {
  sections: Array<SectionModel> = new Array<SectionModel>();
  rows: Array<FormDesignerRowModel> = new Array<FormDesignerRowModel>();
  color: string = "#F9F9F9";
  title: string = "";
  id: string = "";
  description: string = "";
  showAsActive: boolean = false;
  isHidePersonalData: boolean = false;
  img: TPKeyValue = { key: "", value: "" };
  primaryLanguage: TPKeyValue = { key: "", value: "" };
  recordLocalizedModel: RecordLocalizedModel[] =
    new Array<RecordLocalizedModel>();

  descriptions: RecordLocalizedModel[] = new Array<RecordLocalizedModel>();
  formType: TPKeyValue = { key: "", value: "" };
}

export class SectionModel {
  id: string = "";
  title: string = "";
  order: number = -1;
}

export class FormDesignerRowModel {
  id: string = "";
  idSection: string = "";
  order: number = 0;
  numberOfColumns: number = 1;
  columnTypeIdentifier: number = 0;
  controls: Array<ItemRow> = new Array<ItemRow>();
}

export class ItemRow {
  id: string = "";
  idRow: string = "";
  idControl: string = "";
  title: string = "";
  aditionalDataName: string = "";
  isMandatory: boolean = false;
  isVisible: boolean = true;
  isSearchable: boolean = false;
  hidePersonalData: boolean = false;
  isReadOnly: boolean = false;
  parentId: boolean = false;
  placeholder: string = "  ";
  order: number = -1;
}

export class Detail {
  code: number = 0;
  columns: number = 0;
  //column:number =0;
}

class AdditionalDataViewModel {
  id: string = "";
  description: string = "";
  validationURL: string = "";
  comments: string = "";
  additionalDataTypeId: string = "";
  additionalDataTypeDescription: string = "";
  jsonParameters: string = "";
  categoryId: string = "";
  additionalDataCategoryDescription: string = "";
  enableEditingModifyBasicData: boolean = false;
  isSystemRecord: boolean = false;
  localizedDescription: string = "";
  order: number = 0;
  isMandatory: boolean = false;
}

