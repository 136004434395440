import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ReactElement } from "react";
import { ToastContainer, toast } from "react-toastify";
import TPIcon from "../../extend/TPIcons/TPIcon";
import { CSSProperties } from "styled-components";

export enum TPToastTypes {
  info = "info",
  success = "success",
  warning = "warning",
  error = "error",
}

export const showToast = (message: string, toastType: TPToastTypes, persistent?: boolean, icon?: TPIconTypes, customStyle?: CSSProperties) => {
  toast(message, { 
    type: toastType, 
    autoClose: !persistent ? 5000 : false, 
    icon: icon ? (<TPIcon iconType={icon} style={{fontSize: "20px"}} />) : true,
    style: customStyle
  });
};

const TPToast = (): ReactElement => {
  return <ToastContainer />;
};

export default TPToast;
