import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import TPModalImageSelector, {
    TPModalImageSelectorState,
} from "@/layouts/TPModalImageSelector/TPModalImageSelector";
import {FC, ReactElement, useEffect, useRef, useState} from "react";

import {
    TPPageAcceptCancelButtonsContainer,
    TPPageSection,
    TPPageSectionTitle,
    TPPageSubTitle,
    TPPageTitle,
} from "@/components/TPPage/tpPageStyles";

import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
    CustomerTypeInputDTO,
    CustomerTypeInputDTOValidator,
} from "@/models/CustomerType/CustomerTypeInputDTO";
import { CustomerTypeViewModel } from "@/models/CustomerType/CustomerTypeModels";
import {
    SequenceGeneratorSequencesNameEnum,
    TPButtonTypes,
    TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { AdditionalDataFormService } from "@/services/AdditionalDataFormService";
import { CustomerTypeService } from "@/services/CustomerTypeService";
import { HierarchyFolderTypeService } from "@/services/HierarchyFolderService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { SequenceService } from "@/services/SequenceService";
import TPSelect from "../../components/bootstrap/forms/select/TPSelect";
import TPLabel from "../../components/bootstrap/forms/TPLabel/TPLabel";
import FormView from "../FormDesigner/FormView/FormView";
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";
import {MultilingualTextBoxEvents} from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";
import TPIcon from "../../components/bootstrap/extend/TPIcons/TPIcon";
import { ImageService } from "@/services/ImageService";
import TPImagesAdmin from "@/components/TPImagesAdmin/TPImagesAdmin";
import { TPFileSelector } from "@/components/TPFileSelector/TPFileSelector";
import DragAndDropFile from "@/modules/core/design-system/drag-and-drop/DragAndDropTemplate";

type InsertUpdateProps = {
    mode: string;
    recordId: string;
    callBackResult: Function;
};

type InsertUpdateStateType = {
    id: string;
    recordLanguageList: Array<TPKeyValue>;
    customerFormId: string;
    isActive: boolean;
    canCreate: boolean;
    canUpdate: boolean;
    image: string | undefined;
    imageId: string;

    //validator
    idErrorMessage: string;
    descriptionErrorMessages: Array<string>;
    customerFormErrorMessage: string;
    imageIdErrorMessage: string;
};

type dataSourceXX = {
    key: string;
    value: string;
};

const styleActive = {
    color: "#780096",
    textDecoration: "underline",
    cursor: "pointer"
}

const styleDisable = {
    color: "darkgray",
    textDecoration: "underline"
}

const CustomerTypeInsertUpdate: FC<InsertUpdateProps> = ({
    mode,
    recordId,
    callBackResult,
}): ReactElement => {
    //#region  Init
    const componentFileName: string = "CustomerTypeInsertUpdate.tsx";

    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);

    //Screen resources
    const resourceSet: string = "CustomerTypeInsertUpdateComponent";
    const [activePreview, setActivePreview] = useState(false);
    const [titleLabel, setTitleLabel] = useState("");
    const [titlePreviewLabel, setTitlePreviewLabel] = useState("");
    const [exitPreviewLabel, setExitPreviewLabel] = useState("");
    const [subTitleLabel, setSubTitleLabel] = useState("");
    const [originalRecordDescription, setOriginalRecordDescription] =
        useState("");
    const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
    const [parametersSectionLabel, setParametersSectionLabel] = useState("");
    const [idLabel, setIdLabel] = useState("");
    const [descriptionLabel, setDescriptionLabel] = useState("");
    const [customerFormLabel, setCustomerFormLabel] = useState("");
    const [previewFormLabel, setPreviewFormLabel] = useState("");
    const [isActiveLabel, setIsActiveLabel] = useState("");
    const [canCreateLabel, setCanCreateLabel] = useState("");
    const [canUpdateLabel, setCanUpdateLabel] = useState("");
    const [imageLabel, setImageLabel] = useState("");
    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [acceptButtonLabel, setAcceptButtonLabel] = useState("");
    const [languageListLabel, setLanguageListLabel] = useState("");
    const [selectCustomerTypeImageTitle, setSelectCustomerTypeImageTitle] =
        useState("");
    const [selectedCustomerTypeImageLabel, setSelectedCustomerTypeImageLabel] =
        useState("");

    const [selectedImageLabel, setSelectedImageLabel] = useState("");
    const [chooseImageLabel, setChooseImageLabel] = useState("");
    const [chosenImageLabel, setChosenImageLabel] = useState("");

    const nameInputRef = useRef<MultilingualTextBoxEvents>();

    //State modal
    let modalImageSelectorInitialState: TPModalImageSelectorState = {
        isShown: false,
        callBackData: {},
    };

    const [modalImageSelectorState, setModalImageSelectorState] = useState(
        modalImageSelectorInitialState,
    );

    const [referenceId, setReferenceId] = useState("");

    //Screen state
    let initialErrorMessages: Array<string> = [];
    for (
        let i: number = 0;
        i <= TPGlobal.TPClientAvailableLanguages.length - 1;
        i++
    ) {
        initialErrorMessages.push("");
    }
    let insertUpdateInitialState: InsertUpdateStateType = {
        id: recordId,
        recordLanguageList: [],
        isActive: true,
        idErrorMessage: "",
        descriptionErrorMessages: initialErrorMessages,
        customerFormId: "",
        canCreate: false,
        canUpdate: false,
        image: "",
        imageId: "",
        imageIdErrorMessage: "",
        customerFormErrorMessage: "",
    };
    const [insertUpdateState, setInsertUpdateState] = useState(
        insertUpdateInitialState,
    );

    let initialStateFormList: Array<TPKeyValue> = [];
    const [formKeyValue, setFormKeyValue] = useState(initialStateFormList);

    //Multilanguage const
    const multilanguageTableName: String = "CUstomerTYpe";
    const multilanguageFieldName: String = "Description_CUTY";
    //#endregion

    const loadResourcesAndLoadFunctionInfo = async () => {
        let i: number;
        //resources state
        setTitleLabel(await TPI18N.GetText(resourceSet, "Title" + mode + "Label"));
        setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
        setDescriptionSectionLabel(
            await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "DescriptionSectionLabel",
            ),
        );
        setParametersSectionLabel(
            await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "ParametersSectionLabel",
            ),
        );
        setSaveButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
        );
        setCancelButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        );
        setAcceptButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
        );
        setLanguageListLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
        );
        setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
        setDescriptionLabel(await TPI18N.GetText(resourceSet, "DescriptionLabel"));
        setCustomerFormLabel(
            await TPI18N.GetText(resourceSet, "CustomerFormLabel"),
        );
        setPreviewFormLabel(
            await TPI18N.GetText(resourceSet, "PreviewFormLabel"),
        );
        setTitlePreviewLabel(
            await TPI18N.GetText(resourceSet, "SearchResultsLabel"),
        );
        setExitPreviewLabel(
            await TPI18N.GetText(resourceSet, "ExitPreviewLabel"),
        );
        setSelectedImageLabel(await TPI18N.GetText(resourceSet, "Selected"));
        setChooseImageLabel(await TPI18N.GetText(resourceSet, "SelectImage"));
        setChosenImageLabel(await TPI18N.GetText(resourceSet, "SelectAnotherImage"));
        setIsActiveLabel(await TPI18N.GetText(resourceSet, "IsActiveLabel"));
        setCanCreateLabel(await TPI18N.GetText(resourceSet, "CanCreateLabel"));
        setCanUpdateLabel(await TPI18N.GetText(resourceSet, "CanUpdateLabel"));
        setImageLabel(await TPI18N.GetText(resourceSet, "ImageLabel"));
        setSelectCustomerTypeImageTitle(
            await TPI18N.GetText(resourceSet, "SelectCustomerTypeImageTitle"),
        );
        setSelectedCustomerTypeImageLabel(
            await TPI18N.GetText(resourceSet, "SelectedCustomerTypeImageLabel"),
        );

        //Load Form List
        let formService = new AdditionalDataFormService();
        let expectedCodes: Array<number> = [200];
        try {
            //TODO Change constants
            let responseRequest = await formService.getByFiltersIsActive(
                "1",
                "S_FTCUSTOMER",
                false,
                true,
                expectedCodes,
            );

            let newFormListState: Array<TPKeyValue> = responseRequest.map(
                function (item) {
                    return { key: item.id, value: item.localizedDescription ? item.localizedDescription : item.description };
                },
            );
            newFormListState.unshift({ key: "", value: "--" });
            setFormKeyValue(newFormListState);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} loadResourcesAndLoadInfo ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} loadResourcesAndLoadInfo ex`);
            return;
        }

        //screen state

        if (mode == "Update") {
            await getCustomerTypeById(recordId);
        }
        if (mode == "Insert") {
            let newInsertUpdateState = { ...insertUpdateState };
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }
            setInsertUpdateState(newInsertUpdateState);
            setIsLoadingScreen(false);
        }
    };

    

    const handleOnIdCustomerTypeChange = (newId: string) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.id = newId;
        newInsertUpdateState.idErrorMessage = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleLanguageChange = (index: number, newName: string) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.recordLanguageList[index].value = newName;
        newInsertUpdateState.descriptionErrorMessages[index] = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleCustomerFormChange = (newCustomerFormID: string) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.customerFormId = newCustomerFormID;
        newInsertUpdateState.customerFormErrorMessage = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleIsActiveChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.isActive = !newInsertUpdateState.isActive;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleCanCreateChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.canCreate = !newInsertUpdateState.canCreate;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleCanUpdateChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.canUpdate = !newInsertUpdateState.canUpdate;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleChangeActivePreview = (mode: boolean) => {
        setActivePreview(mode);
    };



    useEffect(() => {
        const fetchData = async () => {
            let newInsertUpdateState = { ...insertUpdateState };
            if (insertUpdateState.imageId && insertUpdateState.imageId.length > 0) {
                newInsertUpdateState.imageIdErrorMessage = "";
            }
            // Update state after async operation is done
            setInsertUpdateState(newInsertUpdateState);
        };
        fetchData();
    }, [(insertUpdateState && insertUpdateState.imageId && insertUpdateState.imageId.length)]);

    const handleOkButtonClick = async () => {
        if (nameInputRef.current?.isInvalid()) {
            nameInputRef.current?.markAsTouched();
            return;
        }

        const languages = nameInputRef.current?.getValue();
        const mainLanguage = languages?.find(({id}) => id === TPGlobal.TPClientDefaultLanguage);
        let i: number;
        let n: number;
        let recordInputDTO: CustomerTypeInputDTO = {
            // id: insertUpdateState.id,
            id: mode === "Insert" ? referenceId : recordId,
            isActive: insertUpdateState.isActive,
            description: mainLanguage?.value!,
            otherLocalizedValues: [
                {
                    languageId: mainLanguage?.id!,
                    localizedValue: mainLanguage?.value ?? "",
                    order: 1
                },
                ...languages!
                    .filter(({id}) => id !== TPGlobal.TPClientDefaultLanguage)
                    .map(({id, value}, order) => ({languageId: id!, localizedValue: value ?? "", order: order + 2}))
            ],
            customerFormId: insertUpdateState.customerFormId,
            canCreate: insertUpdateState.canCreate,
            canUpdate: insertUpdateState.canUpdate,
            imageId: String(insertUpdateState.imageId),
        };

        let inputDTOValidator = new CustomerTypeInputDTOValidator();
        let resultValidator = inputDTOValidator.validate(recordInputDTO);

        if (!TPGlobal.TPIsEmpty(resultValidator)) {
            let newInsertUpdateState = { ...insertUpdateState };
            if (resultValidator.id) {
                newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
                    resultValidator.id,
                );
            } else {
                newInsertUpdateState.idErrorMessage = "";
            }
            if (resultValidator.description) {
                newInsertUpdateState.descriptionErrorMessages[0] =
                    await TPI18N.GetResource(resultValidator.description);
            } else {
                newInsertUpdateState.descriptionErrorMessages[0] = "";
            }
            if (resultValidator.otherLocalizedValues) {
                n = insertUpdateState.recordLanguageList.length;
                for (i = 1; i <= n - 1; i++) {
                    if (resultValidator.otherLocalizedValues[i] != null) {
                        newInsertUpdateState.descriptionErrorMessages[i] =
                            await TPI18N.GetResource(
                                String(resultValidator.otherLocalizedValues[i]),
                            );
                    }
                }
            } else {
                n = insertUpdateState.recordLanguageList.length;
                for (i = 1; i <= n - 1; i++) {
                    newInsertUpdateState.descriptionErrorMessages[i] = "";
                }
            }

            if (resultValidator.customerFormId) {
                newInsertUpdateState.customerFormErrorMessage =
                    await TPI18N.GetResource(resultValidator.customerFormId);
            } else {
                newInsertUpdateState.customerFormErrorMessage = "";
            }
            if (resultValidator.imageId) {
                newInsertUpdateState.imageIdErrorMessage = await TPI18N.GetResource(
                    resultValidator.imageId,
                );
            } else {
                newInsertUpdateState.imageIdErrorMessage = "";
            }

            setInsertUpdateState(newInsertUpdateState);
            return;
        }
        if (mode == "Insert") {
            await insertCustomerType(recordInputDTO);
        } else {
            await updateCustomerType(recordInputDTO);
        }
    };

    const insertCustomerType = async (inputDTO: CustomerTypeInputDTO) => {
        let serviceClient = new CustomerTypeService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.insertCustomerType(
                inputDTO,
                true,
                true,
                expectedCodes,
            );
            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                callBackResult({ result: "OK", recordId: recordId });
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} insertCustomerType ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} insertCustomerType ex`);
            setIsLoadingScreen(false);
        }
    };

    const updateCustomerType = async (inputDTO: CustomerTypeInputDTO) => {
        let serviceClient = new CustomerTypeService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.updateCustomerType(
                inputDTO,
                true,
                true,
                expectedCodes,
            );

            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                callBackResult({ result: "OK", recordId: recordId });
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} updateCustomerType ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} updateCustomerType ex`);
            setIsLoadingScreen(false);
        }
    };

    const handleCancelButtonClick = () => {
        callBackResult({ result: "CANCEL", recordId: recordId });
    };

    const getHierarchyById = async (hierarchyId: string) => {
        let serviceClient = new HierarchyFolderTypeService();
        let expectedCodes: Array<number> = [200];
        try {
            let file = await serviceClient.getHierarchyFolderById(
                hierarchyId,
                false,
                true,
                expectedCodes,
            );
            const fileName: string = file.filename || file.description;
            return fileName;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getHierarchyById ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getHierarchyById ex`);
        }
    };

    const getCustomerTypeById = async (pRecordId: string) => {
        let serviceClient = new CustomerTypeService();
        let expectedCodes: Array<number> = [200];
        let imageService = new ImageService(expectedCodes);
        let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
        let i: number;
        let j: number;
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.getCustomerTypeById(
                pRecordId,
                false,
                true,
                expectedCodes,
            );

            let recordInfo: CustomerTypeViewModel;
            recordInfo = { ...responseRequest };
            setOriginalRecordDescription(
                recordInfo.description.length <= 100
                    ? recordInfo.description
                    : recordInfo.description.substring(0, 100) + "...",
            );

            let newInsertUpdateState = { ...insertUpdateState };
            newInsertUpdateState.recordLanguageList = [];
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }
            newInsertUpdateState.customerFormId = recordInfo.customerFormId;
            newInsertUpdateState.isActive = recordInfo.isActive;
            newInsertUpdateState.canCreate = recordInfo.canCreate;
            newInsertUpdateState.canUpdate = recordInfo.canUpdate;
            newInsertUpdateState.imageId = recordInfo.imageId;
            if (recordInfo.imageId !== null) {
                let imageResponse = await imageService.getById(recordInfo.imageId);
                newInsertUpdateState.image = imageResponse[0].fileName;
                setImgPreview(imageResponse[0].thumbnail || "");
            }
            newInsertUpdateState.recordLanguageList[0].value = recordInfo.description;
            recordLanguagesList = await getRecordLanguageList(pRecordId);
            if (recordLanguagesList.length == 0) {
                TPLog.Log(
                    `Error ${componentFileName} getCustomerTypeById getRecordLanguageList`,
                    TPLogType.ERROR,
                    "recordLanguagesList is empty",
                );
                console.error(
                    `Error ${componentFileName} getCustomerTypeById getRecordLanguageList is empty`,
                );
                setIsLoadingScreen(false);
                return;
            }
            for (
                i = 0;
                i <= newInsertUpdateState.recordLanguageList.length - 1;
                i++
            ) {
                for (j = 0; j <= recordLanguagesList.length - 1; j++) {
                    if (
                        newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ==
                        recordLanguagesList[j].languageId.toLowerCase()
                    ) {
                        newInsertUpdateState.recordLanguageList[i].value =
                            recordLanguagesList[j].recordDescription;
                        break;
                    }
                }
            }
            setInsertUpdateState(newInsertUpdateState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getCustomerTypeById ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getCustomerTypeById ex`);
            setIsLoadingScreen(false);
        }
    };

    const getRecordLanguageList = async (
        pRecordId: String,
    ): Promise<Array<MultilanguageFieldValueViewModel>> => {
        let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest =
                await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
                    multilanguageTableName,
                    multilanguageFieldName,
                    pRecordId,
                    false,
                    true,
                    expectedCodes,
                );

            let recordInfo: Array<MultilanguageFieldValueViewModel>;
            recordInfo = [...responseRequest];
            return recordInfo;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getRecordLanguageList ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getRecordLanguageList ex`);
            return [];
        }
    };

    const handleShowModal = () => {
        let newTPModalImageSelectorState: TPModalImageSelectorState;
        newTPModalImageSelectorState = { ...modalImageSelectorState };
        newTPModalImageSelectorState.isShown = true;
        setModalImageSelectorState(newTPModalImageSelectorState);
    };

    const [imgPreview, setImgPreview] = useState<string>("");

    const handleCallBackModal = (
        confirmSelection: boolean,
        callBackData: any,
    ) => {
        let newTPModalImageSelectorState: TPModalImageSelectorState;
        newTPModalImageSelectorState = { ...modalImageSelectorState };
        newTPModalImageSelectorState.isShown = false;
        newTPModalImageSelectorState.callBackData = {};
        setModalImageSelectorState(newTPModalImageSelectorState);
        if (confirmSelection) {
            let newInsertUpdateState = { ...insertUpdateState };
            newInsertUpdateState.imageId = callBackData.selectedFile.hierarchyId;
            newInsertUpdateState.image = callBackData.selectedFile.fileName;
            setInsertUpdateState(newInsertUpdateState);
            setImgPreview(callBackData.selectedFile.thumbnail || "");
        }
    };

    const {
        isOpen: isOpenModalLanguageList,
        openModal: handleOpenModalLanguageList,
        closeModal: handleCloseModalLanguageList,
        saveChanges: handleSaveChangesModalLanguageList,
    } = useModal(false);

    const generalAutomaticId = async () => {
        let serviceClient = new SequenceService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.generalAutomaticId(
                false,
                true,
                expectedCodes,
                SequenceGeneratorSequencesNameEnum.SQCUTY,
            );

            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                let result = responseRequest?.responseData?.data[0]?.sequenceCode;
                setReferenceId(result);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} updatetFunction ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} updatetFunction ex`);
            setIsLoadingScreen(false);
        }
    };

    //Only once to set resources and load function in update mode
    useEffect(() => {
        loadResourcesAndLoadFunctionInfo();
        mode === "Insert" && generalAutomaticId();
    }, []);

    return (
        <>
            <TPModalImageSelector
                title={selectCustomerTypeImageTitle}
                isShown={modalImageSelectorState.isShown}
                callBackAnswer={handleCallBackModal}
                callBackData={modalImageSelectorState.callBackData}
                acceptLabel={acceptButtonLabel}
                cancelLabel={cancelButtonLabel}
                topic={"ImagesCuty"}
                selectedFileDescriptionLabel={selectedCustomerTypeImageLabel}
                startFolder={"7"}
            />
            {/* //#region  Render */}
            <ContentVerticalTabInsertUpdateStyled>
                <TPLoadingOverlay active={isLoadingScreen}>
                    <div className="row">
                        <div className="col-12">
                            {activePreview ? (
                                <div
                                    style={
                                        {
                                            width: "95%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }
                                    }
                                >
                                    <TPPageTitle>{titlePreviewLabel}</TPPageTitle>
                                    <div style={{
                                        width: "100px",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "5px"
                                    }}
                                        onClick={() => { handleChangeActivePreview(false) }}
                                    >
                                        <TPIcon style={{ color: "#780096" }} iconType={TPIconTypes.leftArrow} />
                                        <TPLabel style={styleActive} labelText={exitPreviewLabel} />
                                    </div>
                                </div>
                            )
                                :
                                <TPPageTitle>{titleLabel}</TPPageTitle>
                            }

                            {mode == "Update" && !activePreview && (
                                <TPPageSubTitle>
                                    {subTitleLabel}&nbsp;
                                    {recordId}&nbsp;/&nbsp;
                                    {originalRecordDescription}
                                </TPPageSubTitle>
                            )}
                            {/* <hr />*/}
                        </div>
                    </div>

                    {(insertUpdateState && insertUpdateState.customerFormId && insertUpdateState.customerFormId != "" && activePreview) &&
                        <div
                            style={{
                                maxWidth: "98%",
                                overflow: "hidden"
                            }}
                        >
                            <FormView
                                inputValueDefault={[]}
                                onChangedJson={(e: any) => { }}
                                onChangedReadyForm={(e: any) => { }}
                                inputFormId={insertUpdateState.customerFormId}
                                inputTryingToSave={false}
                            />
                        </div>
                    }

                    <div style={activePreview ? { display: "none" } : {}}>
                        <div className="row">
                            <div className="col-6">
                                <TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <TPPageSection>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <TPTextBox
                                                    id="IdTextBox"
                                                    labelText={idLabel}
                                                    isMandatory={true}
                                                    // value={insertUpdateState.id}
                                                    value={mode === "Insert" ? referenceId : recordId}
                                                    onChange={
                                                        mode === "Insert"
                                                            ? (e: any) =>
                                                                handleOnIdCustomerTypeChange(e.target.value)
                                                            : () => {
                                                                TPGlobal.foo();
                                                            }
                                                    }
                                                    maxLength={20}
                                                    // disabled={mode !== "Insert"}
                                                    disabled
                                                    errorMessage={insertUpdateState.idErrorMessage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <MultilingualTextBox
                                        ref={nameInputRef}
                                        maxLength={200}
                                        style={{ width: "calc(50% + 35px)" }}
                                        value={insertUpdateState.recordLanguageList?.map(({key, value}) => ({id: key, value}))}
                                    />
                                </TPPageSection>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
                            </div>
                        </div>
                        {/* todo remove */}
                        {/* due to form designer is not ready use a fixed form           */}
                        <div className="row">
                            <div className="col-6">
                                <TPPageSection>
                                    <div className="form-group">
                                        <TPSelect
                                            isMandatory={true}
                                            onChange={(e: any) =>
                                                handleCustomerFormChange(e.target.value)
                                            }
                                            dataSource={formKeyValue}
                                            value={insertUpdateState.customerFormId}
                                            labelText={customerFormLabel}
                                            errorMessage={insertUpdateState.customerFormErrorMessage}
                                        ></TPSelect>
                                    </div>
                                </TPPageSection>
                            </div>
                        </div>

                        <div id="preview-form" style={{ height: "50px", display: "flex", justifyContent: "start", alignItems: "center" }} >
                            <TPLabel
                                onClick={() => {
                                    if (insertUpdateState && insertUpdateState.customerFormId && insertUpdateState.customerFormId != "")
                                        handleChangeActivePreview(true)
                                }} labelText={previewFormLabel} style={((insertUpdateState && insertUpdateState.customerFormId && insertUpdateState.customerFormId != "") ? styleActive : styleDisable)} />
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <TPPageSection>
                                    <div className="form-group">
                                        <TPCheckBox
                                            id="IdCheckBox"
                                            labelText={isActiveLabel}
                                            checked={insertUpdateState.isActive}
                                            onChange={(e: any) => handleIsActiveChange()}
                                        ></TPCheckBox>
                                    </div>
                                </TPPageSection>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <TPPageSection>
                                    <div className="form-group">
                                        <TPCheckBox
                                            id="IdCheckBox"
                                            labelText={canCreateLabel}
                                            checked={insertUpdateState.canCreate}
                                            onChange={(e: any) => handleCanCreateChange()}
                                        ></TPCheckBox>
                                    </div>
                                </TPPageSection>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <TPPageSection>
                                    <div className="form-group">
                                        <TPCheckBox
                                            id="IdCheckBox"
                                            labelText={canUpdateLabel}
                                            checked={insertUpdateState.canUpdate}
                                            onChange={(e: any) => handleCanUpdateChange()}
                                        ></TPCheckBox>
                                    </div>
                                </TPPageSection>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <TPPageSection>
                                    <div className="form-group">
                                        <TPFileSelector 
                                            id="customer-type-image-selector"
                                            disabled
                                            onClick={handleShowModal}
                                            fileNameOverride={insertUpdateState.image}
                                            overridePreview={imgPreview}
                                            hideSizeInfo
                                            mandatory
                                            errorMessage={insertUpdateState.imageIdErrorMessage}
                                            chooseText={chooseImageLabel}
                                            chosenText={chosenImageLabel}
                                            selectedLabel={selectedImageLabel}
                                            isFileAlreadyChosen={Boolean(insertUpdateState.imageId && (insertUpdateState.imageId !== undefined))}
                                            onFileRemoval={() => handleCallBackModal(true, {
                                                selectedFile: {
                                                    hierarchyId: "",
                                                    fileName: "",
                                                    thumbnail: ""
                                                }
                                            })}
                                        />
                                        {/* <TPTextBox
                                            id="IdTextBox"
                                            labelText={imageLabel}
                                            value={insertUpdateState.image}
                                            isMandatory={true}
                                            errorMessage={insertUpdateState.imageIdErrorMessage}
                                            maxLength={20}
                                            onChange={(e: any) => TPGlobal.foo()}
                                            withIcon={true}
                                            icon={TPIconTypes.image}
                                            iconClick={handleShowModal}
                                            disabled
                                        /> */}
                                    </div>
                                </TPPageSection>
                            </div>
                        </div>
                        <div className="row mt-2" style={{ marginBottom: "20px" }}>
                            <div className="col-6">
                                <TPPageAcceptCancelButtonsContainer>
                                    <TPButton
                                        id="IdButton"
                                        type={TPButtonTypes.primary}
                                        onClick={handleOkButtonClick}
                                    >
                                        {saveButtonLabel}
                                    </TPButton>
                                    <TPButton
                                        id="IdButton"
                                        type={TPButtonTypes.link}
                                        onClick={handleCancelButtonClick}
                                        className={"ms-2"}
                                    >
                                        {cancelButtonLabel}
                                    </TPButton>
                                </TPPageAcceptCancelButtonsContainer>
                            </div>
                        </div>

                    </div>
                </TPLoadingOverlay>
            </ContentVerticalTabInsertUpdateStyled >
            {/* //#endregion */}
        </>
    );
};

export default CustomerTypeInsertUpdate;
