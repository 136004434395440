import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPRequired from "@/components/bootstrap/forms/TPRequired/TPRequired";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { ReactElement, useEffect, useState } from "react";
import Select, { components, MultiValue } from "react-select";
import AsyncSelect from 'react-select/async';
import {
  getCustomSelectStyles,
  OptionType,
} from "../../utils/search-select-styles";
import {
  StyledMultiItem,
  StyledMultiItemsContainer,
  StyledSearchSelect,
} from "../design-system-styles";

type SearchSelectProps = {
  options: TPKeyValue[];
  optionSelected?: TPKeyValue | TPKeyValue[] | null;
  style?: React.CSSProperties;
  width?: string;
  label?: string;
  orientation?: "vertical" | "horizontal";
  id?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  isMandatory?: boolean;
  isAsync?: boolean;
  placeholder?: string;
  noDataMessage?: string;
  handleChange?: (item: TPKeyValue) => void;
  handleMultiChange?: (items: TPKeyValue[]) => void;
  onClose?: () => void;
  loadOptions?: (inputValue: string) => Promise<OptionType[]>;
  removeSelectedOptionRef?: React.MutableRefObject<
    ((valueToRemove: string) => void) | null
  >;
};

const MultiValueContainer = (props: any) => {
  const { selectProps, data } = props;
  const values = selectProps.value || [];

  if (values.length > 1 && data === values[0]) {
    return (
      <StyledMultiItemsContainer>
        <StyledMultiItem>{values[0].label}</StyledMultiItem>
        <p className="extra-options"> (+{values.length - 1} others)</p>
      </StyledMultiItemsContainer>
    );
  }

  if (values.length === 1 || data === values[0]) {
    return <StyledMultiItem>{values[0].label}</StyledMultiItem>;
  }

  return null;
};

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <TPCheckBox checked={props.isSelected} onChange={() => null} />
      <label>{props.label}</label>
    </components.Option>
  );
};

function SearchSelect({
  options,
  optionSelected,
  style,
  label,
  orientation = "horizontal",
  id,
  isClearable,
  isSearchable,
  isDisabled,
  isLoading,
  isMulti = false,
  isMandatory,
  isAsync,
  placeholder,
  noDataMessage,
  handleChange,
  handleMultiChange,
  onClose,
  loadOptions,
  width = "inherit",
  removeSelectedOptionRef,
}: SearchSelectProps): ReactElement {
  const elementId: string = `${id && `${id}-${isMulti ? "multi-" : ""}`}tp-select`;

  const [state, setState] = useState<any>(null);

  const onSelectChange = (selected: any) => {
    setState(selected);

    if (isMulti) {
      const values = (selected as MultiValue<OptionType>).map((item) => ({
        key: item.value,
        value: item.label,
      }));
      handleMultiChange?.(values);
    } else {
      const { value, label } = selected as OptionType;
      handleChange?.({ key: value, value: label });
    }
  };

  const getDefaultOption = () => {
    if (!optionSelected) return null;

    if (isMulti && Array.isArray(optionSelected)) {
      return optionSelected.map((option) => ({
        value: option.key,
        label: option.value,
      }));
    }

    return {
      value: (optionSelected as TPKeyValue).key,
      label: (optionSelected as TPKeyValue).value,
    };
  };

  const removeSelectedOption = (valueToRemove: string) => {
    if (isMulti && Array.isArray(optionSelected)) {
      const updatedOptions = optionSelected.filter(
        (option) => option.key !== valueToRemove
      );
      handleMultiChange?.(updatedOptions);
    }
  };

  const getOptions = (): OptionType[] =>
    options.map((option) => ({
      value: option.key,
      label: option.value,
    }));

  if (removeSelectedOptionRef) {
    removeSelectedOptionRef.current = removeSelectedOption;
  }

  useEffect(() => {
    setState(getDefaultOption());
  }, [optionSelected, isMulti]);

  return (
    <StyledSearchSelect orientation={orientation} style={style}>
      {label && (
        <label htmlFor={elementId}>
          {label}
          {isMandatory && <TPRequired />}
          {orientation === "horizontal" && ":"}
        </label>
      )}
      {isAsync && loadOptions ? (
        <AsyncSelect
          id={id}
          styles={getCustomSelectStyles(width, isMulti)}
          value={state}
          onChange={onSelectChange}
          onMenuClose={onClose}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={!!isClearable}
          isSearchable={isSearchable}
          placeholder={placeholder}
          noOptionsMessage={() => noDataMessage}
          loadOptions={loadOptions}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={false}
          components={isMulti ? { MultiValueContainer, Option } : undefined}
          isMulti={isMulti}
        />
      ) : (
        <Select
          id={id}
          styles={getCustomSelectStyles(width, isMulti)}
          options={getOptions()}
          value={state}
          onChange={onSelectChange}
          onMenuClose={onClose}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={!!isClearable}
          isSearchable={isSearchable}
          placeholder={placeholder}
          noOptionsMessage={() => noDataMessage}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={false}
          components={isMulti ? { MultiValueContainer, Option } : undefined}
          isMulti={isMulti}
        />
      )}
    </StyledSearchSelect>
  );
}

export default SearchSelect;
