import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { GroupsViewModel } from "@/models/Groups/GroupsModels";
import SearchSelect from "@/modules/core/design-system/selects/SearchSelect";
import { throwAlertError } from "@/modules/core/utils/errors-management";
import { GroupsService } from "@/services/GroupsService";
import {
  CockpitDashboardService,
  GroupByIdsViewProps,
  GroupViewProps,
  TeamViewResponse,
} from "@/services/SupervisorCockpit/CockpitDashboardService";
import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import {
  CockpitDashboardLabels,
  GroupTableItem,
  InfoCardTypes,
  InfoCardValues,
  SelectedFilterItems,
  StatusModel,
  TeamMember,
  TeamMemberParsed,
} from "../../supervisor-cockpit.model";
import CockpitInfoCard from "../../utils/CockpitInfoCard";
import CockpitNoData from "../../utils/CockpitNoData";
import CockpitRefresh from "../../utils/CockpitRefresh";
import CockpitRemovableItem from "../../utils/CockpitRemovableItem";
import CockpitTeamActivityTable from "../../utils/CockpitTeamActivityTable";
import {
  StyledCardContainer,
  StyledDashboardContainer,
  StyledDashboardFilters,
  StyledDashboardTeamActivity,
  StyledFiltersHeader,
  StyledInformationDot,
  StyledInformationDotsContainer,
  StyledItemsSelected,
} from "../cockpit-dashboard-styles";
import DashboardTable from "./DashboardTable";

type DashboardGroupsViewProps = {
  componentLabels: CockpitDashboardLabels;
  setIsLoadingScreen: Function;
};

const DashboardGroupsView: FC<DashboardGroupsViewProps> = React.memo(
  ({ componentLabels, setIsLoadingScreen }): ReactElement => {
    const component: string = "DashboardGroupsView";
    const service = new CockpitDashboardService();
    const groupService = new GroupsService();

    const removeGroupRef = useRef<(valueToRemove: string) => void | null>(null);

    const excludeCards: InfoCardTypes[] = [
      "worked",
      "event",
      "case",
      "chat",
      "mail",
      "expired",
      "pending",
      "closed",
      "inProcess",
      "processing",
      "history"
    ];

    let baseCards: InfoCardValues = {
      offline: 0,
      working: 0,
      online: 0,
      ready: 0,
      event: 0,
      case: 0,
      chat: 0,
      mail: 0,
      expired: 0,
      worked: 0,
      pending: 0,
      inProcess: 0,
      closed: 0,
      history: 0,
      processing: 0
    };
    const [infoCards, setInfoCards] = useState<InfoCardValues>(baseCards);

    const [groupsSelected, setGroupsSelected] = useState<TPKeyValue[]>([]);
    const [showTable, setShowTable] = useState<boolean>(false);
    const [filterApplied, setFilterApplied] = useState<boolean>(false);
    const [tableData, setTableData] = useState<TeamMemberParsed[]>([]);
    const [groups, setGroups] = useState<TPKeyValue[]>([]);
    const [groupTableData, setGroupTableData] = useState<GroupTableItem[]>([]);

    const handleGroupsChange = (values: TPKeyValue[]) => {
      values.length === 1 && setShowTable(false);
      setFilterApplied(false);
      setGroupsSelected(values);
    };

    const handleApplyFilters = () => {
      if (groupsSelected.length > 1) {
        setGroupsTableData();
        return;
      }

      setGroupViewData();
    };

    const getViewGroups = async (): Promise<GroupsViewModel[]> => {
      try {
        setIsLoadingScreen(true);
        return await groupService.getGroupsBySuperior(
          TPGlobal.currentUserGuid,
          false,
          true,
          [200]
        );
      } catch (error) {
        setIsLoadingScreen(false);
        throwAlertError(error, component, "getViewGroups");
        return [];
      }
    };

    const setGroupViewFilter = () => {
      getViewGroups().then((res) => {
        let groupList: TPKeyValue[] = res.map((gp) => {
          return {
            key: `${gp.groupId}`,
            value: gp.user.firstName,
          } as TPKeyValue;
        });

        setGroups(groupList);
        setIsLoadingScreen(false);
      });
    };

    const setTableDataBy = (data: TeamMember[]) => {
      let parsedData: TeamMemberParsed[] = data.map((item) => {
        return {
          userName: item.userName,
          schedule:
            item.onSchedule === "ONSCHEDULE" ? "onSchedule" : "offSchedule",
          status: item.currentStatus.toLowerCase() as InfoCardTypes,
          duration: item.duration,
          pendingTickets: item.pendingTickets,
          queueName: item.queueName,
          userGuid: item.userGuid,
          userId: item.userId,
        };
      });

      setTableData(parsedData);
    };

    const setInfoCardsBy = (statusModels: StatusModel[]) => {
      let cards = baseCards;

      statusModels.forEach((item) => {
        let cardType: InfoCardTypes | undefined =
          item.status.toLowerCase() as InfoCardTypes;
        if (cardType) cards[cardType] = item.totalStatus;
      });

      setInfoCards(cards);
    };

    const getGroupViewData = async () => {
      if (!groupsSelected[0] || !groupsSelected[0].key) return;

      const object: GroupViewProps = {
        groupId: groupsSelected[0].key,
      };

      try {
        setIsLoadingScreen(true);
        return await service.getGroupViewData(object);
      } catch (error) {
        setIsLoadingScreen(false);
        throwAlertError(error, component, "getGroupViewData");
        return [];
      }
    };

    const setGroupViewData = () => {
      getGroupViewData().then((res) => {
        if (!res) return;

        const response: TeamViewResponse = res[0];

        setFilterApplied(!!response);

        if (res && response) {
          response.statusModels && setInfoCardsBy(response.statusModels);
          response.teamMembersModel &&
            setTableDataBy(response.teamMembersModel);
        }

        setIsLoadingScreen(false);
      });
    };

    const getGroupsDataByIds = async () => {
      const object: GroupByIdsViewProps = {
        groupIds: groupsSelected.map((item) => item.key).join(","),
      };

      try {
        setIsLoadingScreen(true);
        return await service.getGroupsBy(object);
      } catch (error) {
        setIsLoadingScreen(false);
        throwAlertError(error, component, "getGroupsDataByIds");
        return [];
      }
    };

    const setGroupsTableData = () => {
      getGroupsDataByIds().then((res) => {
        let organizedGroups: GroupTableItem[] = res.map((item) => {
          return {
            projectId: item.projectId,
            projectName: item.projectName,
            groupId: item.groupId,
            groupName: item.groupName,
            totalCE: item.totalCE,
            readyStatus: item.readyStatus,
            onlineStatus: item.onlineStatus,
            workingStatus: item.workingStatus,
            offlineStatus: item.offlineStatus,
            totalTickets: item.totalTickets,
            workedTickets: item.workedTickets,
            pendingTickets: item.pendingTickets,
            type: item.type,
            onSchedule: item.onSchedule,
            offSchedule: item.offSchedule,
          };
        });

        setGroupTableData(organizedGroups);
        setIsLoadingScreen(false);
        setShowTable(res.length !== 0);
        setFilterApplied(res.length !== 0);
      });
    };

    const RenderSelectedItems = React.memo((): ReactElement => {
      const selectedItems: SelectedFilterItems[] = groupsSelected.map(
        (item) => {
          return {
            key: item.key,
            value: item.value,
            type: "groups",
          };
        }
      );

      return (
        <>
          {selectedItems.length > 0 && (
            <StyledItemsSelected>
              {selectedItems.map((item, index) => {
                return (
                  <CockpitRemovableItem
                    key={index}
                    item={item}
                    onRemoveItem={() => removeGroupRef.current?.(item.key)}
                  />
                );
              })}
            </StyledItemsSelected>
          )}
        </>
      );
    });

    const RenderInformationDots = React.memo((): ReactElement => {
      return (
        <StyledInformationDotsContainer>
          <StyledInformationDot>
            {`${componentLabels.totalGroups}: ${groupsSelected.length}`}
          </StyledInformationDot>
        </StyledInformationDotsContainer>
      );
    });

    const RenderButtons = React.memo((): ReactElement => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "1em", height: "32px" }}>
          {groupsSelected.length !== 0 && (
            <CockpitRefresh view="group" onClickIcon={handleApplyFilters} label={componentLabels.refreshData} />
          )}
        </div>
      );
    });

    const RenderInfoCards = React.memo((): ReactElement => {
      let allCards = Object.entries(infoCards).map(([key, value]) => {
        return {
          type: key as InfoCardTypes,
          value: value,
          label: componentLabels.infoCards[key as InfoCardTypes] ?? "",
        };
      });

      let filteredCards = allCards.filter(
        (item) => !excludeCards.includes(item.type)
      );

      return (
        <StyledCardContainer>
          {filteredCards.map((item, index) => {
            return (
              <CockpitInfoCard
                key={index}
                type={item.type}
                label={item.label}
                value={item.value}
              />
            );
          })}
        </StyledCardContainer>
      );
    });

    useEffect(() => {
      setGroupViewFilter();
    }, []);

    useEffect(() => {
      handleApplyFilters();
    }, [groupsSelected]);

    return (
      <>
        <StyledDashboardContainer>
          <StyledFiltersHeader>
            <StyledDashboardFilters>
              <SearchSelect
                id="cockpit-groups"
                width="200px"
                options={groups}
                optionSelected={groupsSelected}
                label={componentLabels.group}
                placeholder={componentLabels.filterPlaceholder}
                handleMultiChange={handleGroupsChange}
                removeSelectedOptionRef={removeGroupRef}
                orientation="vertical"
                isMandatory
                isMulti
                isClearable
              />
              <RenderButtons />
            </StyledDashboardFilters>
            <RenderInformationDots />
          </StyledFiltersHeader>
          <RenderSelectedItems />
        </StyledDashboardContainer>
        {!filterApplied ? (
          <CockpitNoData view={"group"} withAssignation={groups.length !== 0} />
        ) : (
          <>
            {showTable && filterApplied ? (
              <DashboardTable
                data={groupTableData}
                groups={groups}
                componentLabels={componentLabels}
                setIsLoadingScreen={setIsLoadingScreen}
              />
            ) : (
              <>
                <RenderInfoCards />
                <StyledDashboardTeamActivity>
                  <CockpitTeamActivityTable
                    view={"group"}
                    data={tableData}
                    withChildren
                    selectedGroup={groupsSelected[0] ? groupsSelected[0] : undefined}  
                  />
                </StyledDashboardTeamActivity>
              </>
            )}
          </>
        )}
      </>
    );
  }
);

export default DashboardGroupsView;
