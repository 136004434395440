import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPRadio } from "@/components/TPRadio/TPRadio";
import { ModalSizeEnum, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { GroupsService } from "@/services/GroupsService";
import { FC, useEffect, useState } from "react";
import { useQueuesAdminSelectOptions } from "../../assets/controller";
import useQueueAdminLabels from "../../assets/labels";
import "../../assets/Styles.css";
import { showToast, TPToastTypes } from "@/components/bootstrap/components/toasts/TPToast";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import { TPTextBoxStyled } from "@/components/bootstrap/forms/textbox/tpTextboxStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import TPModal from "@/layouts/TPModal/TPModal";
import ListManagement from "@/pages/EventsManager/ListAdministration/ListManagement";

interface QueueGeneralInfoProperties {
  queueData?: any;
  groupId: number;
  tabCallback: Function;
  active: boolean;
  updateCallback: Function;
}

export enum RecordTypes {
  front = "Frontoffice",
  back = "Backoffice"
}

const QueueAdministrationTab: FC<QueueGeneralInfoProperties> = function ({ groupId, tabCallback, updateCallback, active }) {
  const { labels, labelsLoaded } = useQueueAdminLabels();
  const [classificationOptionsInput, setClassificationOptions] = useState<string>("");
  const [customFieldsInput, setCustomFields] = useState<string>("");
  const [interactionOutcomesInput, setInteractionOutcomes] = useState<string>("");
  const [recordType, setRecordType] = useState<RecordTypes>(RecordTypes.back);
  const [warningMinutes, setWarningMinutes] = useState<string>("0");
  const [warningSeconds, setWarningSeconds] = useState<string>("0");
  const [contentLoaded, setContentLoaded] = useState<boolean>(true);
  const [canSave, setCanSave] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState<"IO" | "CO" | "none">("none");
  const [newListId, setNewListId] = useState<string>("");

  const {
    optionsLoaded,
    classificationOptions,
    interactionOutcomes,
    customFields,
    setOptionsLoaded
  } = useQueuesAdminSelectOptions();

  const [interactionOutcomesAutoComplete, setInteractionOutcomesAutoComplete] = useState<TPKeyValue[]>([]);
  const [clasificationOptionsAutoComplete, setClasificationOptionsAutoComplete] = useState<TPKeyValue[]>([]);
  const [customFieldsAutoComplete, setCustomFieldsAutoComplete] = useState<TPKeyValue[]>([]);

  const fetchData = function() {
    setContentLoaded(false);
    const groupServiceInstance = new GroupsService();
    groupServiceInstance.getQueueGroupId(
      String(groupId), false, false, [200]
    )
    .then(queue => {
      if (queue) {
        setClassificationOptions(queue.clasificationOptions || "");
        setInteractionOutcomes(queue.interactionOutcomes);
        setCustomFields(queue.customFields || "");
        setWarningMinutes(String(queue.activateWarningAfterMinutes));
        setWarningSeconds(String(queue.activateWarningAfterSeconds));
        setRecordType(queue.enventGroupType as RecordTypes);
      }
      setContentLoaded(true);
    })
    .catch(error => console.error(error));
  }

  const handleSave = function () {
    if (!canSave) return;
    setCanSave(false);
    setContentLoaded(false);
    const groupServiceInstance = new GroupsService();    
    groupServiceInstance.updateQueueAdministration({
      groupId: groupId,
      interactionOutcomes: interactionOutcomesAutoComplete[0].key,
      clasificationOptions: clasificationOptionsAutoComplete[0]?.key.length > 0 ? clasificationOptionsAutoComplete[0].key : null,
      customFields: customFieldsAutoComplete[0]?.key.length > 0 ? customFieldsAutoComplete[0].key : null,
      activateWarningAfterMinutes: Number(warningMinutes),
      activateWarningAfterSeconds: Number(warningSeconds),
      enventGroupType: recordType
    }, false, true, [200])
      .then((response) => {
        if (response.responseData.responseCode === 200) {
          showToast(labels.UpdateSuccess, TPToastTypes.success);
          updateCallback({
            result: 'ReloadGrid'
          });
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setCanSave(true);
        setContentLoaded(true);
      })
  }

  const handleTimeInput = function (value: string, type: 'min' | 'sec') {
    let fixedValue: number = Number(value);
    if (isNaN(fixedValue) || (fixedValue < 0) || (value.length === 0)) { fixedValue = 0; }
    if (fixedValue > 59) { fixedValue = 59; }

    if (type === 'min') setWarningMinutes(p => String(fixedValue) !== p ? String(fixedValue) : p);
    if (type === 'sec') setWarningSeconds(String(fixedValue));
  }

  useEffect( () => {
    fetchData();
  }, [])

  useEffect(() => {
    if (customFields.length > 1 && customFieldsInput.length > 1) {
      customFields.find(customField => customField.key === customFieldsInput) && setCustomFieldsAutoComplete([
        {
          key: customFieldsInput,
          value: customFields.find(customField => customField.key === customFieldsInput)?.value
        }
      ])
    }
  }, [customFields, customFieldsInput])

  useEffect(() => {
    if (classificationOptions.length > 1 && classificationOptionsInput.length > 1 && mode === "none") {
      classificationOptions.find(classificationOption => classificationOption.key === classificationOptionsInput) && setClasificationOptionsAutoComplete([
        {
          key: classificationOptionsInput,
          value: classificationOptions.find(classificationOption => classificationOption.key === classificationOptionsInput)?.value
        }
      ])
    }
  }, [classificationOptions, classificationOptionsInput])

  useEffect(() => {
    if (interactionOutcomes.length > 1 && interactionOutcomesInput.length > 1 && mode === "none") {
      interactionOutcomes.find(interactionOutcome => interactionOutcome.key === interactionOutcomesInput) && setInteractionOutcomesAutoComplete([
        {
          key: interactionOutcomesInput,
          value: interactionOutcomes.find(interactionOutcome => interactionOutcome.key === interactionOutcomesInput)?.value
        }
      ])
    }
  }, [interactionOutcomes, interactionOutcomesInput])

  const newListCallback = (command: any) =>{
    setOpenModal(false); 
    
    if (command.command === "update") { 
      setNewListId(command.recordId);
      setOptionsLoaded(false);
    }
  }

  useEffect(() => {
    if (interactionOutcomes.length > 1 && classificationOptions.length > 1) {
      if (mode === "IO") {
        interactionOutcomes.find(interactionOutcome => interactionOutcome.key === newListId) && setInteractionOutcomesAutoComplete([
          {
            key: newListId,
            value: interactionOutcomes.find(interactionOutcome => interactionOutcome.key === newListId)?.value
          }
        ])
      } else if (mode === "CO") {
        classificationOptions.find(classificationOption => classificationOption.key === newListId) && setClasificationOptionsAutoComplete([
          {
            key: newListId,
            value: classificationOptions.find(classificationOption => classificationOption.key === newListId)?.value
          }
        ])
      }

      setMode("none");
    }
  }, [interactionOutcomes, classificationOptions]);

  return (
    <>
      <TPModal
        modalState={{
          titleModal: "",
          isShown: openModal,
          callBackAnswer: () => {},
          hiddenHeader: true,
          hideFooterButtons: true,
          hideXButton: true,
          modalWidth: ModalSizeEnum.MODALXL
        }} 
      >
        {openModal ? (
          <>
            <ListManagement
              mode="new"
              recordId="--"
              verticalTabCallback={newListCallback}
              updateCallback={() => {}}
            />
          </>
        ) : (
          <>
            <p>resseting modal</p>
          </>
        )}
        
      </TPModal>
      {active && <TPLoadingOverlay active={!(optionsLoaded && labelsLoaded && contentLoaded)}>
        <div id='queue-general-info-container' className="queue-admin-tab">
          <div><b>{labels.Description}</b></div>
          <div className="form-flex" style={{ justifyContent: 'space-between' }}>
            <div id='queue-admin-interaction-outcomes-input' style={{ display: "flex", flexDirection: 'row', gap: '16px', alignItems: 'flex-end', justifyContent: 'space-between', width: '47%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <TPAutoComplete
                  isMandatory
                  containerStyle={{ width: '100%' }}
                  labelText={labels.InteractionOutcomes}
                  onValueChange={(event: any) => setInteractionOutcomesAutoComplete(event)}
                  selected={interactionOutcomesAutoComplete}
                  options={interactionOutcomes}
                  icon={TPIconTypes.search}
                  withIcon
                  onSearch={(event: any) => {}}
                  downArrowClick={() => {
                  }}
                  onKeyDown={() => {
                  }}
                  isLoading={false}
                  emptyLabel=""
                />
              </div>
              <TPButton
                id="queue-admin-new-list"
                isDesignSystem
                style={{ padding: "5px", marginBottom: "2px" }}
                onClick={() => {setOpenModal(true); setMode("IO");}}
              >
                <TPIcon iconType={TPIconTypes.add} />
              </TPButton>
            </div>
            <div id='queue-admin-classification-options-input' style={{ display: "flex", flexDirection: 'row', gap: '16px', alignItems: 'flex-end', justifyContent: 'space-between', width: '47%' }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <TPAutoComplete
                  containerStyle={{ width: '100%' }}
                  labelText={labels.ClassificationOptions}
                  onValueChange={(event: any) => setClasificationOptionsAutoComplete(event)}
                  selected={clasificationOptionsAutoComplete}
                  options={classificationOptions}
                  icon={TPIconTypes.search}
                  withIcon
                  onSearch={(event: any) => {}}
                  downArrowClick={() => {
                  }}
                  onKeyDown={() => {
                  }}
                  isLoading={false}
                  emptyLabel=""
                />
              </div>
              <TPButton
                id="queue-admin-new-list"
                isDesignSystem
                style={{ padding: "5px", marginBottom: "2px" }}
                onClick={() => {setOpenModal(true); setMode("CO");}}
              >
                <TPIcon iconType={TPIconTypes.add} />
              </TPButton>
            </div>
          </div>
          <div className="form-flex">
            <div id='queue-admin-custom-fields-input' style={{ width: '47%' }}>
              <TPAutoComplete
                  containerStyle={{ width: '100%' }}
                  labelText={labels.CustomFields}
                  onValueChange={(event: any) => setCustomFieldsAutoComplete(event)}
                  selected={customFieldsAutoComplete}
                  options={customFields}
                  icon={TPIconTypes.search}
                  withIcon
                  onSearch={(event: any) => {}}
                  downArrowClick={() => {
                  }}
                  onKeyDown={() => {
                  }}
                  isLoading={false}
                  emptyLabel=""
                />
            </div>
          </div>
          <div className="form-grid3">
            <div id='queue-admin-warning-time-input'>
              <div><b>{labels.ActivateWarningAfter}</b></div>
              <div className="evenly-distributed" style={{ justifyContent: 'flex-start' }}>
                <b>({labels.Min} : {labels.Sec})</b>
                <TPTextBoxStyled 
                  type="text"
                  className="form-control"
                  value={warningMinutes}
                  onChange={(e) => handleTimeInput(e.target.value, 'min')}
                  style={{width: "64px"}}
                />
                <label>:</label>
                <TPTextBoxStyled 
                  type="text"
                  className="form-control"
                  value={warningSeconds}
                  onChange={(e) => handleTimeInput(e.target.value, 'sec')}
                  style={{width: "64px"}}
                />
              </div>
            </div>
            <div id='queue-admin-warning-time-input'>
              <div><b>{labels.RecordType}</b></div>
              <div className="evenly-distributed" style={{ justifyContent: 'flex-start', gap: '16px', textWrap: "nowrap" }}>
                <TPRadioGroup
                  source={[
                    {key: RecordTypes.front, value: labels.FrontOffice},
                    {key: RecordTypes.back, value: labels.BackOffice},
                  ]}
                  value={recordType}
                  onChange={(e: any) => setRecordType(e.target.value as RecordTypes)}
                />
              </div>
            </div>
          </div>
          <div className="option-button-pair">
            <TPButton
              onClick={() => {
                if (tabCallback) tabCallback({
                  command: 'delete',
                  recordId: String(groupId)
                })
              }}
              isDesignSystem
              style={{
                backgroundColor:'white',
                color:'purple',
                paddingTop:'11px',
                paddingBottom:'11px',
                paddingLeft:'16px',
                paddingRight:'16px'
              }}
            >
              {labels.Cancel}
            </TPButton>
            <TPButton
              onClick={() => handleSave()}
              type={TPButtonTypes.primary}
              disabled={interactionOutcomesAutoComplete.length == 0 || !canSave}
              isDesignSystem
              style={{
                paddingTop:'11px',
                paddingBottom:'11px',
                paddingLeft:'16px',
                paddingRight:'16px'
              }}
            >
              {labels.Save}
            </TPButton>
          </div>
        </div>
      </TPLoadingOverlay>}
    </>
  )
}

export default QueueAdministrationTab;