import TPGlobal from "@/helpers/TPGlobal";
import {TPHTTPService} from "@/helpers/TPHttpService";
import {TPKeyValue} from "@/helpers/TPKeyValue";
import {TPLog, TPLogType} from "@/helpers/TPLog";
import {HTTPResponse} from "@/helpers/TPStandardResponse";

export interface FieldModel {
    projectId: string,
    eventLoadStructureDetailId: number,
    field: string,
    type: string,
    operations: Array<string>
}

export class EventLoadStructureService {
    serviceFileName: string = "EventLoadStructureService.ts";

    public async find(params: { [attribute: string]: string }): Promise<Array<any>> {
        const {getData} = new TPHTTPService();
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        let url = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/eventloadstructure`;
        if (params) {
            const p = Object.entries(params)
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            url = `${url}?${p}`;
        }
        let response: HTTPResponse;
        try {
            response = await getData(url, [200, 404], false, false, headers);
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(`Error ${this.serviceFileName} find ex`, TPLogType.ERROR, error);
            console.error(`Error ${this.serviceFileName} find`);
            throw new Error(`Error ${this.serviceFileName} find`);
        }
    }

    public async findByGroupId(groupId: string): Promise<Array<FieldModel>> {
        const {getData} = new TPHTTPService();
        try {
            const response = await getData(
                `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/eventloadstructure/${groupId}/fieldconditions`,
                [200, 404],
                false,
                false,
                [...TPGlobal.standardHeaders]
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(`Error ${this.serviceFileName} findByGroupId ex`, TPLogType.ERROR, error);
            console.error(`Error ${this.serviceFileName} findByGroupId`);
            throw new Error(`Error ${this.serviceFileName} findByGroupId`);
        }
    }

}

