import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { FC, ReactElement } from "react";
import { StyledRemovableItem } from "../supervisor-cockpit-styles";
import { SelectedFilterItems } from "../supervisor-cockpit.model";

type CockpitRemovableItemProps = {
  item?: SelectedFilterItems;
  onRemoveItem?: (key: string, type: string) => void;
};

const CockpitRemovableItem: FC<CockpitRemovableItemProps> = ({
  item,
  onRemoveItem = () => { },
}): ReactElement => {
  return (
    <StyledRemovableItem>
      <p>{item?.value}</p>
      <TPIcon
        iconType={TPIconTypes.close}
        onClick={() => onRemoveItem(item?.key ?? "", item?.type ?? "")}
      />
    </StyledRemovableItem>
  );
};

export default CockpitRemovableItem;
