import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import TPBranchSelection2 from "@/components/TPBranchSelection/TPBranchSelection2";
import TPConditionsTable from "@/components/TPConditionsTable/TPConditionsTable";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPWizard from "@/components/TPWizard/TPWizard";
import TPWizardContent from "@/components/TPWizard/TPWizardContent";
import TPWizardStep from "@/components/TPWizard/TPWizardStep";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import TPModal from "@/layouts/TPModal/TPModal";
import TPModalError, {
  TPModalErrorStateType,
} from "@/layouts/TPModalError/TPModalError";
import TPModalSuccess from "@/layouts/TPModalSuccess/TPModalSuccess";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  CaseMassUpdateInputDTO,
  CaseMassUpdateInputDTOValidator,
  InactiveCasesClosedMasiveInputDTO,
} from "@/models/Cases/CaseMassUpdateInputDTO";
import {
  CasesSearchFiltersModel,
  CasesSearchInputDTO,
} from "@/models/Cases/CasesSearchInputDTO";
import {
  CaseSearchByFilterType,
  taskStatusEnum,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
  TPQuickSelectDatesEnum,
} from "@/models/Global/TPGlobalEnums";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { OrganizationsViewModel } from "@/models/Organizations/OrganizationsModels";
import { OrganizationsRelationsViewModel } from "@/models/OrganizationsRelations/OrganizationsRelationsModels";
import { RelationsWithRestrictionsViewModel } from "@/models/Relations/RelationsModels";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import { UserViewModel } from "@/models/Users/UserModels";
import { CaseMassUpdateService } from "@/services/CaseMassUpdateService";
import { CaseService } from "@/services/CaseService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";
import { OrganizationsService } from "@/services/OrganizationsService";
import { RelationsService } from "@/services/RelationsService";
import { TaskTypeService } from "@/services/TaskTypeService";
import { TimeZonesService } from "@/services/TimeZonesService";
import { TreeService } from "@/services/TreeService";
import { UserService } from "@/services/UserService";
import moment from "moment";
import React, {
  ReactElement,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import AddTaskForm from "./AddTaskform";
import CompletePendingTasks from "./CompletePendingTasks";
import ReclassifyMassive from "./ReclassifyMassive";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import {
  ColumnStyles,
  CustomColumnNames,
} from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { isEmpty } from "handsontable/helpers";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import SearchSelect from "@/modules/core/design-system/selects/SearchSelect";
import { SelectedFilterItems } from "../SupervisorCockpit/supervisor-cockpit.model";
import { StyledItemsSelected } from "../SupervisorCockpit/Dashboard/cockpit-dashboard-styles";
import CockpitRemovableItem from "../SupervisorCockpit/utils/CockpitRemovableItem";

enum commandsEnum {
  "change_CurrentOrganization" = 0,
  "change_CurrentRelation" = 1,
  "change_CurrentBranch" = 2,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

type ColumnProps = {
  columnLabel: string;
  columnId: string;
  visible: boolean;
  sort: boolean;
  type: string;
};

type PaginationGridType = {
  rowsPerPage: number;
  totalRows: number;
  currentPage: number;
};

type AdminStateType = {
  currentOrganization: string;
  currentRelation: string;

  currentBaseLevel: string;
  currentTree1: string;
  currentTree2: string;
  currentTree3: string;
  currentTree4: string;
  currentTree5: string;

  mustSelectLastLevelBranch1: boolean;
  mustSelectLastLevelBranch2: boolean;
  mustSelectLastLevelBranch3: boolean;
  mustSelectLastLevelBranch4: boolean;
  mustSelectLastLevelBranch5: boolean;

  currentLabelTree1: string;
  currentLabelTree2: string;
  currentLabelTree3: string;
  currentLabelTree4: string;
  currentLabelTree5: string;
  currentBranch1: string;
  currentBranch2: string;
  currentBranch3: string;
  currentBranch4: string;
  currentBranch5: string;
  currentDescriptionBranch1: string;
  currentDescriptionBranch2: string;
  currentDescriptionBranch3: string;
  currentDescriptionBranch4: string;
  currentDescriptionBranch5: string;
};

type ModalSettingsColumnsType = {
  isShown: boolean;
  localRowsPerPage: number;
};

type ColumnOrderProps = {
  currentOrderColum: string;
  currentOrderType: string;
  currentOrderDirection: string;
};

interface IResponseData {
  caseId: string;
  inactivated: boolean;
  message: string;
}

const CaseMassUpdate = () => {
  const casecontext: any = React.useContext(TPCaseViewerContext);
  const resourceSet: string = "CaseMassUpdateComponent";
  const componentFileName: string = "CaseMassUpdate.tsx";
  const resourceSetBaseLevel: string = "BaseLevelInsertUpdateComponent";
  const [reportColumns, setReportColumns] = useState<Array<ColumnProps>>([]);
  const [reportData, setReportData] = useState<Array<any>>([]);

  const addTaskRef = useRef<any>(null);
  const reclassifykRef = useRef<any>(null);
  const completePendingTaskRef = useRef<any>(null);

  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");

  // combo acctions four step
  const [addTaskInBatchLabel, setAddTaskInBatchLabel] = useState("");
  const [completePendingTasksLabel, setCompletePendingTasksLabel] =
    useState("");
  const [reclassifyCasesInBatchLabel, setReclassifyCasesInBatchLabel] =
    useState("");
  const [inactiveClosedCasesInBatchLabel, setInactiveClosedCasesInBatchLabel] =
    useState("");

  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  const branch1SelectorRef: any = useRef(null);
  const branch2SelectorRef: any = useRef(null);
  const branch3SelectorRef: any = useRef(null);
  const branch4SelectorRef: any = useRef(null);
  const branch5SelectorRef: any = useRef(null);

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [conditionQuantity, setConditionQuantity] = useState("");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [timezone, setTimezone] = useState(TPGlobal.currentUserTimeZoneId);
  const [timeZoneErrorMessage, setTimeZoneErrorMessage] = useState("");
  const [organizationErrorMessage, setOrganizationErrorMessage] = useState("");
  const [relationErrorMessage, setRelationErrorMessage] = useState("");
  const [quickSelectDates, setQuickSelectDates] = useState(
    TPQuickSelectDatesEnum.today,
  );
  const [dateType, setDateType] = useState("0");
  const [privateCase, setPrivateCase] = useState("2");

  /* Start SearchSelect  */
  const [casesStatus, setCasesStatus] = useState<TPKeyValue[]>([]);
  const [casesStatusSelected, setCasesStatusSelected] = useState<TPKeyValue[]>(
    [],
  );
  const [
    caseStatusChoosePlaceholderLabel,
    setCaseStatusChoosePlaceholderLabel,
  ] = useState("");
  const handleGroupsChange = (values: TPKeyValue[]) => {
    setCasesStatusSelected(values);
  };

  const removeGroupRef = useRef<(valueToRemove: string) => void | null>(null);

  const RenderSelectedItems = React.memo((): ReactElement => {
    const selectedItems: SelectedFilterItems[] = casesStatusSelected.map(
      (item) => {
        return {
          key: item.key,
          value: item.value,
          type: "groups",
        };
      },
    );

    return (
      <>
        {selectedItems.length > 0 && (
          <StyledItemsSelected>
            {selectedItems.map((item, index) => {
              return (
                <CockpitRemovableItem
                  key={index}
                  item={item}
                  onRemoveItem={() => removeGroupRef.current?.(item.key)}
                />
              );
            })}
          </StyledItemsSelected>
        )}
      </>
    );
  });

  /* End SearchSelect  */

  const [participatingUsersList, setParticipatingUsersList] = useState<
    Array<TPKeyValue>
  >([]);

  const initialModalSettingColumType: ModalSettingsColumnsType = {
    isShown: false,
    localRowsPerPage: 100,
  };

  const [modalSettingColumnsState, setModalSettingColumnsState] =
    useState<ModalSettingsColumnsType>(initialModalSettingColumType);

  const [
    selectedAutocompleteTaskTypeOption,
    setSelectedAutocompleteTaskTypeOption,
  ] = useState<Array<TPKeyValue>>([]);

  const [autocompleteTaskTypeOptions, setAutocompleteTaskTypeOptions] =
    useState<Array<TPKeyValue>>([]);

  //top n options for arrow icon
  const [autocompleteTaskTypeTopNOptions, setAutocompleteTaskTypeTopNOptions] =
    useState<Array<TPKeyValue>>([]);

  //#region Resources
  const [IsActiveAll, setIsActiveAll] = useState("");
  const [IsActiveYes, setIsActiveYes] = useState("");
  const [IsActiveNo, setIsActiveNo] = useState("");
  const [pageTitleLabel, setPageTitleLabel] = useState("");
  const [filterLabel, setFilterLabel] = useState("");
  const [otherFiltersLabel, setOthersFiltersLabel] = useState("");
  // const [summaryLabel, setSummaryLabel] = useState("");
  const [resultsLabel, setResultsLabel] = useState("");
  const [organizationRelationLabel, setOrganizationRelationLabel] =
    useState("");
  const [actionLabel, setActionLabel] = useState("");
  const [applyLabel, setApplyLabel] = useState("");
  const [organizationLabel, setOrganizationLabel] = useState("");
  const [relationLabel, setRelationLabel] = useState("");
  const [todayLabel, setTodayLabel] = useState("");
  const [yesterdayLabel, setYesterdayLabel] = useState("");
  const [currentWeekLabel, setCurrentWeekLabel] = useState("");
  const [lastWeekLabel, setLastWeekLabel] = useState("");
  const [currentMonthLabel, setCurrentMonthLabel] = useState("");
  const [lastMonthLabel, setLastMonthLabel] = useState("");
  const [currentYearLabel, setCurrentYearLabel] = useState("");
  const [lastTwelveMonthsLabel, setLastTwelveMonthsLabel] = useState("");
  const [startDateLabel, setStartDateLabel] = useState("");
  const [endDateLabel, setEndDateLabel] = useState("");
  const [moreFiltersLabel, setMoreFiltersLabel] = useState("");
  const [timezoneLabel, setTimezoneLabel] = useState("");
  const [quickSelectDatesLabel, setQuickSelectDatesLabel] = useState("");
  const [dateTypeLabel, setDateTypeLabel] = useState("");
  const [caseStatusLabel, setCaseStatusLabel] = useState("");
  const [privateCaseLabel, setPrivateCaseLabel] = useState("");
  const [participatingUserLabel, setParticipatingUserLabel] = useState("");
  const [taskTypeLabel, setTaskTypeLabel] = useState("");
  const [SearchButtonLabel, setSearchButtonLabel] = useState("");
  const [classificatorLabel, setClassificatorLabel] = useState("");
  const [createDateLabel, setcreateDateLabel] = useState("");
  const [updateDateLabel, setupdateDateLabel] = useState("");
  const [totalRowsLabel, setTotalRowsLabel] = useState("");
  const [okButtonLabel, setOkButtonLabel] = useState("");
  const [rowsPerPageLabel, setRowsPerPageLabel] = useState("");
  const [responseData, setResponseData] = useState<Array<IResponseData>>([]);

  const [modalActions, setModalActions] = useState(false);

  const [dummyClassfierLabel, setDummyClassfierLabel] = useState("");
  const [participatingUser, setParticipatingUser] = useState("");

  const [organizationListKeyValue, setOrganizationListKeyValue] = useState<
    Array<TPKeyValue>
  >([]);
  const [relationListKeyValue, setRelationListKeyValue] = useState<
    Array<TPKeyValue>
  >([]);
  const [timezonesToSelect, setTimeZonesToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [itemsReportSelected, setItemsReportSelected] = useState<Array<number>>(
    [],
  );

  //modal error
  const initialModalError: TPModalErrorStateType = {
    isShown: false,
    title: "",
    errorMessage: "",
    yesLabel: "",
    callBackFunction: TPGlobal.foo,
  };

  const [modalErrorState, setModalErrorState] =
    useState<TPModalErrorStateType>(initialModalError);

  const tasksTypeToCompletePendingAction =
    TPGlobal.taskTypeCanBeCompletedMassively.split("|");

  const actionsApplyOptions = [
    { key: "--", value: "--" },
    { key: "1", value: addTaskInBatchLabel },
    { key: "2", value: reclassifyCasesInBatchLabel },
    { key: "4", value: inactiveClosedCasesInBatchLabel },
  ];

  const getValuesComboActions = () => {
    let values: Array<TPKeyValue> = [...actionsApplyOptions];

    if (selectedAutocompleteTaskTypeOption.length === 1) {
      const result = tasksTypeToCompletePendingAction.some(
        (arrVal) => arrVal === selectedAutocompleteTaskTypeOption[0].value2,
      );
      if (result) {
        values.push({ key: "3", value: completePendingTasksLabel });
      }
    }

    return values;
  };

  let modalQuestionInitialState: TPModalQuestionState = {
    isShown: false,
    callBackData: {},
  };

  const [modalQuestionState, setModalQuestionState] = useState(
    modalQuestionInitialState,
  );

  const [modalQuestionChangeMassive, setModalQuestionChangeMassive] = useState(
    modalQuestionInitialState,
  );

  const [actionApplySelected, setActionApplySelected] = useState<number>();
  const [actionApplyErrorMessage, setActionApplyErrorMessage] = useState("");

  const initialPaginationGridState: PaginationGridType = {
    rowsPerPage: 10,
    totalRows: 100,
    currentPage: 1,
  };
  const [paginationGridState, setPaginationGridState] = useState(
    initialPaginationGridState,
  );

  const initialRowsPerPageList: Array<TPKeyValue> = [
    { key: "10", value: "10" },
    { key: "100", value: "100" },
    { key: "200", value: "200" },
    { key: "500", value: "500" },
    { key: "1000", value: "1000" },
    { key: "5000", value: "5000" },
  ];

  const [rowsPerPageList, setRowsPerPageList] = useState(
    initialRowsPerPageList,
  );

  const initialColumnOrderProps: ColumnOrderProps = {
    currentOrderColum: "caseId",
    currentOrderDirection: "DESC",
    currentOrderType: "string",
  };
  const [columnOrderPropsState, setColumnOrderPropsState] = useState(
    initialColumnOrderProps,
  );

  const [
    defaultBranchHierarchyDescription1,
    setDefaultBranchHierarchyDescription1,
  ] = useState("");
  const [
    defaultBranchHierarchyDescription2,
    setDefaultBranchHierarchyDescription2,
  ] = useState("");
  const [
    defaultBranchHierarchyDescription3,
    setDefaultBranchHierarchyDescription3,
  ] = useState("");
  const [
    defaultBranchHierarchyDescription4,
    setDefaultBranchHierarchyDescription4,
  ] = useState("");
  const [
    defaultBranchHierarchyDescription5,
    setDefaultBranchHierarchyDescription5,
  ] = useState("");

  const [filtersSelected, setFiltersSelected] = useState<any[]>([]);
  const [organizationSelected, setOrganizationSelected] = useState("");
  const [relationSelected, setRelationSelected] = useState("");
  const [otherSettingsLabel, setOtherSettingsLabel] = useState("");

  const [modalAcceptLabel, setModalAcceptLabel] = useState("");
  const [modalCancelLabel, setModalCancelLabel] = useState("");

  //branch select
  const [modalSelectedBranchLabel, setModalSelectedBranchLabel] = useState("");
  const [modalTitleLabel, setModalTitleLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");

  const [modalConfirmQuestion, setModalConfirmQuestion] = useState("");
  const [modalConfirmTitle, setModalConfirmTitle] = useState("");

  const [formattedLabel, setFormattedLabel] = useState("");

  // Modal success
  const resourceSetAddTaskForm: string = "AddTaskFormComponent";
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalConfirmQuestionMassive, setModalConfirmQuestionMassive] =
    useState("");
  const [modalSuccessTitle, setModalSuccessTitle] = useState("");
  const [selectedRowsLabel, setSelectedRowsLabel] = useState("");
  const [recordsProcessedLabel, setRecordsProcessedLabel] = useState("");
  const [recordsUnprocessedLabel, setRecordsUnprocessedLabel] = useState("");

  const [caseIdLabel, setCaseIdColumn] = useState("");
  const [caseStateLabel, setCaseStateDescription] = useState("");
  const [customerTypeLabel, setCustomerType] = useState("");
  const [customerNameLabel, setCustomerName] = useState("");
  const [clientServiceLabel, setClientServiece] = useState("");
  const [classificatorLabel1, setClassificator1] = useState("");
  const [classificatorLabel2, setClassificator2] = useState("");
  const [classificatorLabel3, setClassificator3] = useState("");
  const [classificatorLabel4, setClassificator4] = useState("");
  const [classificatorLabel5, setClassificator5] = useState("");
  const [clasificatorValue1, setClassificator1Value] = useState("");
  const [clasificatorValue2, setClassificator2Value] = useState("");
  const [clasificatorValue3, setClassificator3Value] = useState("");
  const [clasificatorValue4, setClassificator4Value] = useState("");
  const [clasificatorValue5, setClassificator5Value] = useState("");
  const [insertDateLabel, setInsertDateColumn] = useState("");
  const [closeDateLabel, setCloseDateCloumn] = useState("");
  const [lastModifiedLabel, setLastModifiedColumn] = useState("");

  //Load Resources
  const loadResourcesAndOrganizations = async () => {
    // modal confirm change massive
    setModalSuccessTitle(
      await TPI18N.GetText(resourceSetAddTaskForm, "ModalSuccessTitle"),
    );
    setSelectedRowsLabel(
      await TPI18N.GetText(resourceSetAddTaskForm, "SelectedRowsLabel"),
    );
    setRecordsProcessedLabel(
      await TPI18N.GetText(resourceSetAddTaskForm, "RecordsProcessedLabel"),
    );
    setRecordsUnprocessedLabel(
      await TPI18N.GetText(resourceSetAddTaskForm, "RecordsUnprocessedLabel"),
    );
    setModalConfirmQuestionMassive(
      await TPI18N.GetText(
        resourceSetAddTaskForm,
        "RecordModalConfirmQuestion",
      ),
    );

    //modal confirm action apply
    setModalConfirmQuestion(
      await TPI18N.GetText(resourceSet, "RecordModalConfirmQuestion"),
    );
    setModalConfirmTitle(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
    );

    setIsActiveAll(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveAll"),
    );
    setIsActiveYes(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveYes"),
    );
    setIsActiveNo(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveNo"),
    );
    setSearchButtonLabel(
      await TPI18N.GetText(resourceSet, "SearchButtonLabel"),
    );
    setClassificatorLabel(
      await TPI18N.GetText(resourceSet, "ClassificatorLabel"),
    );
    setcreateDateLabel(await TPI18N.GetText(resourceSet, "createDateLabel"));
    setupdateDateLabel(await TPI18N.GetText(resourceSet, "updateDateLabel"));
    setTaskTypeLabel(await TPI18N.GetText(resourceSet, "TaskTypeLabel"));

    setModalTitleLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalTitleLabel"),
    );

    setFilterLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "FilterLabel"),
    );
    setOthersFiltersLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "OtherFiltersLabel"),
    );
    // setSummaryLabel(await TPI18N.GetText(resourceSet, "Summarylabel"));
    setResultsLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ResultsLabel"),
    );
    setOrganizationRelationLabel(
      await TPI18N.GetText(resourceSet, "OrganizationRelationLabel"),
    );
    setOrganizationLabel(
      await TPI18N.GetText(resourceSet, "OrganizationLabel"),
    );

    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );

    setActionLabel(await TPI18N.GetText(resourceSet, "ActionLabel"));
    setApplyLabel(await TPI18N.GetText(resourceSet, "ApplyLabel"));
    setRelationLabel(await TPI18N.GetText(resourceSet, "RelationLabel"));
    setTodayLabel(await TPI18N.GetText(resourceSet, "TodayLabel"));
    setYesterdayLabel(await TPI18N.GetText(resourceSet, "YesterdayLabel"));
    setCurrentWeekLabel(await TPI18N.GetText(resourceSet, "CurrentWeekLabel"));
    setLastWeekLabel(await TPI18N.GetText(resourceSet, "LastWeekLabel"));
    setCurrentMonthLabel(
      await TPI18N.GetText(resourceSet, "CurrentMonthLabel"),
    );
    setLastMonthLabel(await TPI18N.GetText(resourceSet, "LastMonthLabel"));
    setCurrentYearLabel(await TPI18N.GetText(resourceSet, "CurrentYearLabel"));
    setLastTwelveMonthsLabel(
      await TPI18N.GetText(resourceSet, "LastTwelveMonthsLabel"),
    );
    setStartDateLabel(await TPI18N.GetText(resourceSet, "StartDateLabel"));
    setEndDateLabel(await TPI18N.GetText(resourceSet, "EndDateLabel"));
    setTimezoneLabel(await TPI18N.GetText(resourceSet, "TimezoneLabel"));
    setQuickSelectDatesLabel(
      await TPI18N.GetText(resourceSet, "QuickSelectDatesLabel"),
    );
    setDateTypeLabel(await TPI18N.GetText(resourceSet, "DateTypeLabel"));
    setMoreFiltersLabel(await TPI18N.GetText(resourceSet, "MoreFiltersLabel"));
    setCaseStatusLabel(await TPI18N.GetText(resourceSet, "CaseStatusLabel"));
    setPrivateCaseLabel(await TPI18N.GetText(resourceSet, "PrivateCaseLabel"));

    setCaseStatusChoosePlaceholderLabel(
      await TPI18N.GetText(
        resourceSet,
        "SectionFilterSelectChoosePlaceholderLabel",
      ),
    );

    setDummyClassfierLabel(
      await TPI18N.GetText(resourceSet, "ClassifierLabel"),
    );

    setParticipatingUserLabel(
      await TPI18N.GetText(resourceSet, "ParticipatingUserLabel"),
    );

    setPageTitleLabel(await TPI18N.GetText(resourceSet, "PageTitle"));

    setTotalRowsLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "TotalRowsLabel"),
    );
    setOtherSettingsLabel(
      await TPI18N.GetText(resourceSet, "OtherSettingsLabel"),
    );
    setOkButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setRowsPerPageLabel(await TPI18N.GetText(resourceSet, "RowsPerPageLabel"));

    setAddTaskInBatchLabel(
      await TPI18N.GetText(resourceSet, "AddTaskInBatchLabel"),
    );
    setCompletePendingTasksLabel(
      await TPI18N.GetText(resourceSet, "CompletePendingTasksLabel"),
    );
    setReclassifyCasesInBatchLabel(
      await TPI18N.GetText(resourceSet, "ReclassifyCasesInBatchLabel"),
    );
    setInactiveClosedCasesInBatchLabel(
      await TPI18N.GetText(resourceSet, "InactiveClosedCasesInBatchLabel"),
    );

    setCaseIdColumn(await TPI18N.GetText(resourceSet, "CaseIdColumnLabel"));
    setCaseStateDescription(
      await TPI18N.GetText(resourceSet, "CaseStatusDescriptionColumnLabel"),
    );
    setCustomerType(
      await TPI18N.GetText(resourceSet, "CustomerTypeDescriptionColumnLabel"),
    );
    setCustomerName(await TPI18N.GetText(resourceSet, "ClientNameColumnLabel"));
    setClientServiece(
      await TPI18N.GetText(resourceSet, "BaseLevelDescriptionColumnLabel"),
    );
    setClassificator1(
      await TPI18N.GetText(resourceSetBaseLevel, "ClassificationLabel1"),
    );
    setClassificator2(
      await TPI18N.GetText(resourceSetBaseLevel, "ClassificationLabel2"),
    );
    setClassificator3(
      await TPI18N.GetText(resourceSetBaseLevel, "ClassificationLabel3"),
    );
    setClassificator4(
      await TPI18N.GetText(resourceSetBaseLevel, "ClassificationLabel4"),
    );
    setClassificator5(
      await TPI18N.GetText(resourceSetBaseLevel, "ClassificationLabel5"),
    );
    setClassificator1Value(
      await TPI18N.GetText(resourceSet, "Classificator1Value"),
    );
    setClassificator2Value(
      await TPI18N.GetText(resourceSet, "Classificator2Value"),
    );
    setClassificator3Value(
      await TPI18N.GetText(resourceSet, "Classificator3Value"),
    );
    setClassificator4Value(
      await TPI18N.GetText(resourceSet, "Classificator4Value"),
    );
    setClassificator5Value(
      await TPI18N.GetText(resourceSet, "Classificator5Value"),
    );
    setInsertDateColumn(
      await TPI18N.GetText(resourceSet, "InsertDateColumnLabel"),
    );
    setCloseDateCloumn(
      await TPI18N.GetText(resourceSet, "CustomStateDescriptionColumnLabel"),
    );
    setLastModifiedColumn(
      await TPI18N.GetText(resourceSet, "LastModifiedDateColumnLabel"),
    );

    setModalSelectedBranchLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SelectedBranchLabel"),
    );

    setFormattedLabel(
      await TPI18N.GetText(resourceSet, "formattedColumnLabel"),
    );

    let newReportColumns: Array<ColumnProps> = [];

    newReportColumns.push({
      columnLabel: await TPI18N.GetText(
        resourceSet,
        "CaseStatusDescriptionColumnLabel",
      ),
      columnId: "caseStatusLocalizedDescription",
      visible: true,
      sort: true,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(
        resourceSet,
        "CustomStateDescriptionColumnLabel",
      ),
      columnId: "customStateLocalizedDescription",
      visible: true,
      sort: false,
      type: "string",
    });

    newReportColumns.push({
      columnLabel: await TPI18N.GetText(resourceSet, "CaseIdColumnLabel"),
      columnId: "caseId",
      visible: true,
      sort: true,
      type: "number",
    });

    newReportColumns.push({
      columnLabel: await TPI18N.GetText(
        resourceSet,
        "CustomerTypeIdColumnLabel",
      ),
      columnId: "customerTypeId",
      visible: false,
      sort: true,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(
        resourceSet,
        "CustomerTypeDescriptionColumnLabel",
      ),
      columnId: "customerTypeLocalizedDescription",
      visible: true,
      sort: true,
      type: "string",
    });

    newReportColumns.push({
      columnLabel: await TPI18N.GetText(resourceSet, "ClientNameColumnLabel"),
      columnId: "clientName",
      visible: true,
      sort: true,
      type: "string",
    });

    newReportColumns.push({
      columnLabel: await TPI18N.GetText(resourceSet, "commentsColumnLabel"),
      columnId: "comments",
      visible: true,
      sort: true,
      type: "string",
    });

    newReportColumns.push({
      columnLabel: await TPI18N.GetText(resourceSet, "BaseLevelIdColumnLabel"),
      columnId: "baseLevelId",
      visible: false,
      sort: false,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(
        resourceSet,
        "BaseLevelDescriptionColumnLabel",
      ),
      columnId: "baseLevelLocalizedDescription",
      visible: true,
      sort: true,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(
        resourceSet,
        "CanViewPrivateColumnLabel",
      ),
      columnId: "canViewPrivate",
      visible: false,
      sort: true,
      type: "string",
    });

    newReportColumns.push({
      columnLabel: await TPI18N.GetText(
        resourceSetBaseLevel,
        "ClassificationLabel1",
      ),
      columnId: "classifier1LocalizedDescription",
      visible: true,
      sort: false,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(resourceSet, "Classificator1Value"),
      columnId: "classify1BranchId",
      visible: true,
      sort: true,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(
        resourceSetBaseLevel,
        "ClassificationLabel2",
      ),
      columnId: "classifier2LocalizedDescription",
      visible: true,
      sort: false,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(resourceSet, "Classificator2Value"),
      columnId: "classify2BranchId",
      visible: true,
      sort: true,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(
        resourceSetBaseLevel,
        "ClassificationLabel3",
      ),
      columnId: "classifier3LocalizedDescription",
      visible: true,
      sort: false,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(resourceSet, "Classificator3Value"),
      columnId: "classify3BranchId",
      visible: true,
      sort: true,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(
        resourceSetBaseLevel,
        "ClassificationLabel4",
      ),
      columnId: "classifier4LocalizedDescription",
      visible: true,
      sort: false,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(resourceSet, "Classificator4Value"),
      columnId: "classify4BranchId",
      visible: true,
      sort: true,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(
        resourceSetBaseLevel,
        "ClassificationLabel5",
      ),
      columnId: "classifier5LocalizedDescription",
      visible: true,
      sort: false,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(resourceSet, "Classificator5Value"),
      columnId: "classify5BranchId",
      visible: true,
      sort: true,
      type: "string",
    });

    newReportColumns.push({
      columnLabel: await TPI18N.GetText(
        resourceSet,
        "HasProfileRestrictionColumnLabel",
      ),
      columnId: "hasProfileRestriction",
      visible: false,
      sort: false,
      type: "string",
    });

    newReportColumns.push({
      columnLabel: "Classificator 1 hierarchy xx",
      columnId: "hierarchyDescriptionClassify1",
      visible: false,
      sort: false,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: "Classificator 2 hierarchy xx",
      columnId: "hierarchyDescriptionClassify2",
      visible: false,
      sort: false,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: "Classificator 3 hierarchy xx",
      columnId: "hierarchyDescriptionClassify3",
      visible: false,
      sort: false,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: "Classificator 4 hierarchy xx",
      columnId: "hierarchyDescriptionClassify4",
      visible: false,
      sort: false,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: "Classificator 5 hierarchy xx",
      columnId: "hierarchyDescriptionClassify5",
      visible: false,
      sort: false,
      type: "string",
    });

    newReportColumns.push({
      columnLabel: await TPI18N.GetText(resourceSet, "InsertDateColumnLabel"),
      columnId: "insertDateFormatted",
      visible: true,
      sort: true,
      type: "date",
    });

    //TODO: Column Limit Date

    newReportColumns.push({
      columnLabel: await TPI18N.GetText(resourceSet, "CloseDateColumnLabel"),
      columnId: "closeDateFormatted",
      visible: true,
      sort: true,
      type: "date",
    });

    newReportColumns.push({
      columnLabel: await TPI18N.GetText(
        resourceSet,
        "LastModifiedDateColumnLabel",
      ),
      columnId: "lastModifiedDateFormatted",
      visible: true,
      sort: true,
      type: "date",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(resourceSet, "InterestCaseColumnLabel"),
      columnId: "interestCase",
      visible: false,
      sort: false,
      type: "string",
    });

    newReportColumns.push({
      columnLabel: await TPI18N.GetText(
        resourceSet,
        "CaseResponsibleColumnLabel",
      ),
      columnId: "caseResponsible",
      visible: true,
      sort: true,
      type: "string",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(resourceSet, "PromiseDateColumnLabel"),
      columnId: "promiseDateFormatted",
      visible: false,
      sort: true,
      type: "date",
    });
    newReportColumns.push({
      columnLabel: await TPI18N.GetText(resourceSet, "TotalRecordsColumnLabel"),
      columnId: "totalRecords",
      visible: false,
      sort: false,
      type: "number",
    });

    setReportColumns(newReportColumns);

    //load selects
    await getStatusesList();
    await getOrganizationsList();
    await getTimezones();
    await getParticipatingUserList();
  };

  const handleRefreshClick = () => {
    loadResourcesAndOrganizations();
  };

  const handleIconClick = (event: TPIconTypes) => {
    if (event === TPIconTypes.loop) handleRefreshClick();
  };
  //Initial State
  const initialStateBLL: AdminStateType = {
    currentOrganization: "",
    currentRelation: "",

    currentBaseLevel: "",

    currentTree1: "",
    currentTree2: "",
    currentTree3: "",
    currentTree4: "",
    currentTree5: "",

    mustSelectLastLevelBranch1: false,
    mustSelectLastLevelBranch2: false,
    mustSelectLastLevelBranch3: false,
    mustSelectLastLevelBranch4: false,
    mustSelectLastLevelBranch5: false,

    currentLabelTree1: "",
    currentLabelTree2: "",
    currentLabelTree3: "",
    currentLabelTree4: "",
    currentLabelTree5: "",

    currentBranch1: "",
    currentBranch2: "",
    currentBranch3: "",
    currentBranch4: "",
    currentBranch5: "",
    currentDescriptionBranch1: "",
    currentDescriptionBranch2: "",
    currentDescriptionBranch3: "",
    currentDescriptionBranch4: "",
    currentDescriptionBranch5: "",
  };

  // Define the columns, styles, and minor options for the DynamicTable
  const customerTypeColumns: CustomColumnNames<any> = {
    caseId: caseIdLabel,
    caseStatusLocalizedDescription: caseStateLabel,
    customerTypeLocalizedDescription: customerTypeLabel,
    clientName: customerNameLabel,
    baseLevelLocalizedDescription: clientServiceLabel,
    // hierarchyDescriptionClassify1: clasificatorValue1,
    classifier1LocalizedDescription: classificatorLabel1,
    classifier2LocalizedDescription: classificatorLabel2,
    classifier3LocalizedDescription: classificatorLabel3,
    classifier4LocalizedDescription: classificatorLabel4,
    classifier5LocalizedDescription: classificatorLabel5,
    classify1BranchId: clasificatorValue1,
    classify2BranchId: clasificatorValue2,
    classify3BranchId: clasificatorValue3,
    classify4BranchId: clasificatorValue4,
    classify5BranchId: clasificatorValue5,
    insertDate: insertDateLabel,
    insertDateFormatted: `${insertDateLabel}(${formattedLabel})`,
    closeDate: closeDateLabel,
    lastModifiedDateFormatted: lastModifiedLabel,
    caseCreator: actionLabel,
  };

  const columnStyles: ColumnStyles<any> = {
    caseId: ({ value, item }) => (
      <>
        <TablePrimaryItem
          value={value}
          onClick={() => {
            casecontext.handleAddNewCaseViewerCallBack(item.caseId);
          }}
        />
      </>
    ),
  };

  //reducer definition
  const [adminState, dispatchCommand] = useReducer(doCommand, initialStateBLL);
  function doCommand(prevState: AdminStateType, command: commandType) {
    let newAdminState: AdminStateType;
    switch (command.type) {
      case commandsEnum.change_CurrentOrganization:
        newAdminState = { ...prevState };
        newAdminState.currentOrganization = command.payload.newOrganization;
        newAdminState.currentTree1 = "";
        newAdminState.currentTree2 = "";
        newAdminState.currentTree3 = "";
        newAdminState.currentTree4 = "";
        newAdminState.currentTree5 = "";

        newAdminState.mustSelectLastLevelBranch1 = false;
        newAdminState.mustSelectLastLevelBranch2 = false;
        newAdminState.mustSelectLastLevelBranch3 = false;
        newAdminState.mustSelectLastLevelBranch4 = false;
        newAdminState.mustSelectLastLevelBranch5 = false;

        newAdminState.currentLabelTree1 = "";
        newAdminState.currentLabelTree2 = "";
        newAdminState.currentLabelTree3 = "";
        newAdminState.currentLabelTree4 = "";
        newAdminState.currentLabelTree5 = "";
        newAdminState.currentRelation = "";

        newAdminState.currentBaseLevel = "";

        newAdminState.currentBranch1 = "";
        newAdminState.currentBranch2 = "";
        newAdminState.currentBranch3 = "";
        newAdminState.currentBranch4 = "";
        newAdminState.currentBranch5 = "";
        newAdminState.currentDescriptionBranch1 = "";
        newAdminState.currentDescriptionBranch2 = "";
        newAdminState.currentDescriptionBranch3 = "";
        newAdminState.currentDescriptionBranch4 = "";
        newAdminState.currentDescriptionBranch5 = "";
        return newAdminState;
      case commandsEnum.change_CurrentRelation:
        newAdminState = { ...prevState };
        newAdminState.currentRelation = command.payload.newRelation;
        newAdminState.currentBaseLevel = command.payload.newBaseLevel;

        newAdminState.currentTree1 = command.payload.newTree1;
        newAdminState.currentTree2 = command.payload.newTree2;
        newAdminState.currentTree3 = command.payload.newTree3;
        newAdminState.currentTree4 = command.payload.newTree4;
        newAdminState.currentTree5 = command.payload.newTree5;

        newAdminState.mustSelectLastLevelBranch1 =
          command.payload.newMustSelectLastLevelBranch1;
        newAdminState.mustSelectLastLevelBranch2 =
          command.payload.newMustSelectLastLevelBranch2;
        newAdminState.mustSelectLastLevelBranch3 =
          command.payload.newMustSelectLastLevelBranch3;
        newAdminState.mustSelectLastLevelBranch4 =
          command.payload.newMustSelectLastLevelBranch4;
        newAdminState.mustSelectLastLevelBranch5 =
          command.payload.newMustSelectLastLevelBranch5;

        newAdminState.currentLabelTree1 = command.payload.newLabelTree1;
        newAdminState.currentLabelTree2 = command.payload.newLabelTree2;
        newAdminState.currentLabelTree3 = command.payload.newLabelTree3;
        newAdminState.currentLabelTree4 = command.payload.newLabelTree4;
        newAdminState.currentLabelTree5 = command.payload.newLabelTree5;
        newAdminState.currentBranch1 = "";
        newAdminState.currentBranch2 = "";
        newAdminState.currentBranch3 = "";
        newAdminState.currentBranch4 = "";
        newAdminState.currentBranch5 = "";
        newAdminState.currentDescriptionBranch1 = "";
        newAdminState.currentDescriptionBranch2 = "";
        newAdminState.currentDescriptionBranch3 = "";
        newAdminState.currentDescriptionBranch4 = "";
        newAdminState.currentDescriptionBranch5 = "";
        return newAdminState;
      case commandsEnum.change_CurrentBranch:
        newAdminState = { ...prevState };
        switch (command.payload.treeNumber) {
          case 1:
            newAdminState.currentBranch1 = command.payload.newBranch;
            newAdminState.currentDescriptionBranch1 =
              command.payload.newDescriptionBranch;
            break;
          case 2:
            newAdminState.currentBranch2 = command.payload.newBranch;
            newAdminState.currentDescriptionBranch2 =
              command.payload.newDescriptionBranch;
            break;
          case 3:
            newAdminState.currentBranch3 = command.payload.newBranch;
            newAdminState.currentDescriptionBranch3 =
              command.payload.newDescriptionBranch;
            break;
          case 4:
            newAdminState.currentBranch4 = command.payload.newBranch;
            newAdminState.currentDescriptionBranch4 =
              command.payload.newDescriptionBranch;
            break;
          case 5:
            newAdminState.currentBranch5 = command.payload.newBranch;
            newAdminState.currentDescriptionBranch5 =
              command.payload.newDescriptionBranch;
            break;
        }
        return newAdminState;
    }
  }

  const quickSelectDatesValue = [
    {
      key: TPQuickSelectDatesEnum.today,
      value: todayLabel,
    },
    {
      key: TPQuickSelectDatesEnum.yesterday,
      value: yesterdayLabel,
    },
    {
      key: TPQuickSelectDatesEnum.currentWeek,
      value: currentWeekLabel,
    },
    {
      key: TPQuickSelectDatesEnum.lastWeek,
      value: lastWeekLabel,
    },
    {
      key: TPQuickSelectDatesEnum.currentMonth,
      value: currentMonthLabel,
    },
    {
      key: TPQuickSelectDatesEnum.lastMonth,
      value: lastMonthLabel,
    },
    {
      key: TPQuickSelectDatesEnum.currentYear,
      value: currentYearLabel,
    },
    {
      key: TPQuickSelectDatesEnum.lastTwelveMonths,
      value: lastTwelveMonthsLabel,
    },
  ];

  const handleAddMassiveTask = async (acceptButton: boolean) => {
    if (acceptButton) {
      if (addTaskRef) {
        await addTaskRef.current.addMassiveTasks();
      }
    } else {
      setModalActions(false);
    }
  };

  const handleCompletePendingTasks = async (acceptButton: boolean) => {
    if (acceptButton) {
      if (completePendingTaskRef) {
        await completePendingTaskRef.current.completePendingTasks();
      }
    } else {
      setModalActions(false);
    }
  };

  const handlereclassifyMassiveTasks = async (acceptButton: boolean) => {
    if (acceptButton) {
      if (reclassifykRef) {
        await reclassifykRef.current.reclassifyMassiveTasks();
      }
    } else {
      setModalActions(false);
    }
  };

  const getParticipatingUserList = async () => {
    const userClient = new UserService();
    const expectedCodes: Array<number> = [200, 404];
    const users: Array<UserViewModel> = await userClient.getUsersByFilter(
      TPActiveOptions.ALL.toString(),
      false,
      true,
      expectedCodes,
    );
    const participantUserListConverted: Array<TPKeyValue> = users.map((k) => {
      return {
        key: k.userGuid,
        value: k.name,
      };
    });
    participantUserListConverted.unshift({ key: "", value: "--" });
    setParticipatingUsersList(participantUserListConverted);
  };

  const getOrganizationsList = async () => {
    let serviceClient = new OrganizationsService();
    let expectedCodes: Array<number> = [200];
    let i: number;
    try {
      setIsLoadingScreen(true);
      let responseRequest =
        await serviceClient.getOrganizationsByProfileAndRestrictions(
          "ADMIN",
          "S_ADWFRE",
          false,
          false,
          true,
          expectedCodes,
        );
      let newOrganizationsListState: Array<TPKeyValue> = [];

      let organizationsListData: Array<OrganizationsViewModel>;
      organizationsListData = responseRequest;
      for (i = 0; i <= organizationsListData.length - 1; i++) {
        let keyvalue: TPKeyValue = {
          key: organizationsListData[i].id,
          value: organizationsListData[i].localizedName,
        };
        newOrganizationsListState.push(keyvalue);
      }
      let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
      newOrganizationsListState.unshift(intitialKeyValue);
      setOrganizationListKeyValue(newOrganizationsListState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getOrganizationsList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getOrganizationsList ex`);
      setIsLoadingScreen(false);
    }
  };

  const getRelationsList = async (newOrganization: string) => {
    let serviceClient = new RelationsService();
    let expectedCodes: Array<number> = [200];
    let i: number;
    let newRelationsListState: Array<TPKeyValue> = [];
    try {
      setIsLoadingScreen(true);
      if (newOrganization === "") {
        setRelationListKeyValue(newRelationsListState);
        setIsLoadingScreen(false);
        return;
      }
      let responseRequest =
        await serviceClient.getRelationsByOrganizationIdAndProfileRestriction(
          newOrganization,
          "ADMIN",
          "S_ADWFRE",
          false,
          false,
          true,
          expectedCodes,
        );
      let relationsListData: Array<RelationsWithRestrictionsViewModel>;
      relationsListData = responseRequest;
      for (i = 0; i <= relationsListData.length - 1; i++) {
        let keyvalue: TPKeyValue = {
          key: relationsListData[i].baseLevelAndRelationlId,
          value: relationsListData[i].localizedName
            ? relationsListData[i].localizedName
            : relationsListData[i].name,
        };
        newRelationsListState.push(keyvalue);
      }
      let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
      newRelationsListState.unshift(intitialKeyValue);
      setRelationListKeyValue(newRelationsListState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRelationsList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRelationsList ex`);
      setIsLoadingScreen(false);
    }
  };

  const getBaseLevelInfo = async (BaseLevelId: string) => {
    let serviceClient = new OrganizationsRelationsService();
    let expectedCodes: Array<number> = [200];
    let baseLevelData: OrganizationsRelationsViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getById(
        BaseLevelId,
        false,
        true,
        expectedCodes,
      );

      baseLevelData = responseRequest;
      return baseLevelData;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getBaseLevelInfo ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getBaseLevelInfo ex`);
      return null;
    }
  };

  const getTreeInfo = async (treeId: string) => {
    let serviceClient = new TreeService();
    let expectedCodes: Array<number> = [200];
    let treeData: TreeViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getTreeById(
        treeId,
        false,
        true,
        expectedCodes,
      );
      treeData = responseRequest;
      return treeData;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getTreeInfo ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getTreeInfo ex`);
      return null;
    }
  };

  const handleChangePage = async (direction: string) => {
    let newPageNumber: number;

    if (direction === "FIRST" && paginationGridState.currentPage === 1) {
      return;
    }
    if (direction === "PREVIOUS" && paginationGridState.currentPage === 1) {
      return;
    }
    if (
      direction === "NEXT" &&
      paginationGridState.currentPage === calcMaxPages()
    ) {
      return;
    }
    if (
      direction === "LAST" &&
      paginationGridState.currentPage === calcMaxPages()
    ) {
      return;
    }
    newPageNumber = paginationGridState.currentPage;
    if (direction === "FIRST") {
      newPageNumber = 1;
    }
    if (direction === "PREVIOUS") {
      newPageNumber = newPageNumber - 1;
    }
    if (direction === "NEXT") {
      newPageNumber = newPageNumber + 1;
    }
    if (direction === "LAST") {
      newPageNumber = calcMaxPages();
    }

    let inputDTO: CasesSearchInputDTO | null;
    inputDTO = await getInputDTO();
    if (inputDTO) {
      inputDTO.startRecord =
        (newPageNumber - 1) * paginationGridState.rowsPerPage + 1;
      inputDTO.endRecord = newPageNumber * paginationGridState.rowsPerPage;
      setIsLoadingScreen(true);
      let data = await getCasesByFilter(inputDTO);
      setIsLoadingScreen(false);

      if (data && data.length > 0) {
        sortData(
          data,
          columnOrderPropsState.currentOrderColum,
          columnOrderPropsState.currentOrderType,
          columnOrderPropsState.currentOrderDirection,
        );
        let newPaginationGridState = { ...paginationGridState };
        newPaginationGridState.totalRows = data[0]["totalRecords"];
        newPaginationGridState.currentPage = newPageNumber;
        setPaginationGridState(newPaginationGridState);
      } else {
        setReportData([]);
      }
    }
  };

  const calcMaxPages = () => {
    var maxPages: number = 0;
    maxPages = Math.trunc(
      paginationGridState.totalRows / paginationGridState.rowsPerPage,
    );
    if (
      maxPages * paginationGridState.rowsPerPage <
      paginationGridState.totalRows
    ) {
      maxPages = maxPages + 1;
    }
    return maxPages;
  };

  const getClassificatorsConfiguration = async (
    newRelation: string,
    newBaseLevel: string,
  ) => {
    let newTree1: string;
    let newTree2: string;
    let newTree3: string;
    let newTree4: string;
    let newTree5: string;

    let mustSelectLastBranch1: boolean;
    let mustSelectLastBranch2: boolean;
    let mustSelectLastBranch3: boolean;
    let mustSelectLastBranch4: boolean;
    let mustSelectLastBranch5: boolean;

    let newLabelTree1: string | null;
    let newLabelTree2: string | null;
    let newLabelTree3: string | null;
    let newLabelTree4: string | null;
    let newLabelTree5: string | null;

    let baseLevelData: OrganizationsRelationsViewModel | null = null;
    let treeData: TreeViewModel | null = null;
    let localizedField: Array<MultilanguageFieldValueViewModel> = [];
    let i;
    let found: boolean;

    newTree1 = "";
    newTree2 = "";
    newTree3 = "";
    newTree4 = "";
    newTree5 = "";

    mustSelectLastBranch1 = false;
    mustSelectLastBranch2 = false;
    mustSelectLastBranch3 = false;
    mustSelectLastBranch4 = false;
    mustSelectLastBranch5 = false;

    newLabelTree1 = "";
    newLabelTree2 = "";
    newLabelTree3 = "";
    newLabelTree4 = "";
    newLabelTree5 = "";

    baseLevelData = await getBaseLevelInfo(newBaseLevel);
    if (baseLevelData === null) {
      //todo logs
      return null;
    }
    newTree1 = baseLevelData.tree1Id;
    newTree2 = baseLevelData.tree2Id;
    newTree3 = baseLevelData.tree3Id;
    newTree4 = baseLevelData.tree4Id;
    newTree5 = baseLevelData.tree5Id;
    //get tree Info 1
    if (newTree1 !== TPGlobal.Tree.NA_TreeCode) {
      treeData = await getTreeInfo(newTree1);
      if (treeData === null) {
        //todo logs
        return null;
      } else {
        mustSelectLastBranch1 = treeData.mustSelectLastBranch;
        //get label from multilanguage field value
        localizedField = await getRecordLanguageList(
          newRelation.split("|")[0] + "_C1",
          "BAseLEvel",
          "Tree1_Classify1Caption",
        );
        if (localizedField === null || localizedField.length === 0) {
          //todo logs
          return null;
        }
        found = false;
        for (i = 0; i <= localizedField.length - 1; i++) {
          if (localizedField[i].languageId === TPGlobal.language) {
            newLabelTree1 = localizedField[i].recordDescription;
            found = true;
            break;
          }
        }
        if (!found) {
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (
              localizedField[i].languageId ===
              TPGlobal.TPClientAvailableLanguages[0].id
            ) {
              newLabelTree1 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            //todo logs
            return null;
          }
        }
      }
    }
    if (newTree2 !== TPGlobal.Tree.NA_TreeCode) {
      //get tree Info 2
      treeData = await getTreeInfo(newTree2);
      if (treeData === null) {
        //todo logs
        return null;
      } else {
        mustSelectLastBranch2 = treeData.mustSelectLastBranch;
        //get label from multilanguage field value
        localizedField = await getRecordLanguageList(
          newRelation.split("|")[0] + "_C2",
          "BAseLEvel",
          "Tree2_Classify2Caption",
        );
        if (localizedField === null || localizedField.length === 0) {
          //todo logs
          return null;
        }
        found = false;
        for (i = 0; i <= localizedField.length - 1; i++) {
          if (localizedField[i].languageId === TPGlobal.language) {
            newLabelTree2 = localizedField[i].recordDescription;
            found = true;
            break;
          }
        }
        if (!found) {
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (
              localizedField[i].languageId ===
              TPGlobal.TPClientAvailableLanguages[0].id
            ) {
              newLabelTree2 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            //todo logs
            return null;
          }
        }
      }
    }
    if (newTree3 !== TPGlobal.Tree.NA_TreeCode) {
      //get tree Info 3
      treeData = await getTreeInfo(newTree3);
      if (treeData === null) {
        //todo logs
        return null;
      } else {
        mustSelectLastBranch3 = treeData.mustSelectLastBranch;
        //get label from multilanguage field value
        localizedField = await getRecordLanguageList(
          newRelation.split("|")[0] + "_C3",
          "BAseLEvel",
          "Tree3_Classify3Caption",
        );
        if (localizedField === null || localizedField.length === 0) {
          //todo logs
          return null;
        }
        found = false;
        for (i = 0; i <= localizedField.length - 1; i++) {
          if (localizedField[i].languageId === TPGlobal.language) {
            newLabelTree3 = localizedField[i].recordDescription;
            found = true;
            break;
          }
        }
        if (!found) {
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (
              localizedField[i].languageId ===
              TPGlobal.TPClientAvailableLanguages[0].id
            ) {
              newLabelTree3 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            //todo logs
            return null;
          }
        }
      }
    }
    if (newTree4 !== TPGlobal.Tree.NA_TreeCode) {
      //get tree Info 4
      treeData = await getTreeInfo(newTree4);
      if (treeData === null) {
        //todo logs
        return null;
      } else {
        mustSelectLastBranch4 = treeData.mustSelectLastBranch;
        //get label from multilanguage field value
        localizedField = await getRecordLanguageList(
          newRelation.split("|")[0] + "_C4",
          "BAseLEvel",
          "Tree4_Classify4Caption",
        );
        if (localizedField === null || localizedField.length === 0) {
          //todo logs
          return null;
        }
        found = false;
        for (i = 0; i <= localizedField.length - 1; i++) {
          if (localizedField[i].languageId === TPGlobal.language) {
            newLabelTree4 = localizedField[i].recordDescription;
            found = true;
            break;
          }
        }
        if (!found) {
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (
              localizedField[i].languageId ===
              TPGlobal.TPClientAvailableLanguages[0].id
            ) {
              newLabelTree4 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            //todo logs
            return null;
          }
        }
      }
    }
    if (newTree5 !== TPGlobal.Tree.NA_TreeCode) {
      //get tree Info 5
      treeData = await getTreeInfo(newTree5);
      if (treeData === null) {
        //todo logs
        return null;
      } else {
        mustSelectLastBranch5 = treeData.mustSelectLastBranch;
        //get label from multilanguage field value
        localizedField = await getRecordLanguageList(
          newRelation.split("|")[0] + "_C5",
          "BAseLEvel",
          "Tree5_Classify5Caption",
        );
        if (localizedField === null || localizedField.length === 0) {
          //todo logs
          return null;
        }
        found = false;
        for (i = 0; i <= localizedField.length - 1; i++) {
          if (localizedField[i].languageId === TPGlobal.language) {
            newLabelTree5 = localizedField[i].recordDescription;
            found = true;
            break;
          }
        }
        if (!found) {
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (
              localizedField[i].languageId ===
              TPGlobal.TPClientAvailableLanguages[0].id
            ) {
              newLabelTree5 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            //todo logs
            return null;
          }
        }
      }
    }

    return {
      newTree1,
      newTree2,
      newTree3,
      newTree4,
      newTree5,
      mustSelectLastBranch1,
      mustSelectLastBranch2,
      mustSelectLastBranch3,
      mustSelectLastBranch4,
      mustSelectLastBranch5,
      newLabelTree1,
      newLabelTree2,
      newLabelTree3,
      newLabelTree4,
      newLabelTree5,
    };
  };

  const handleModalAction = async () => {
    let recordInputDTO: CaseMassUpdateInputDTO = {
      actionApply: !!actionApplySelected ? actionApplySelected.toString() : "",
    };
    let inputDTOValidator = new CaseMassUpdateInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      if (resultValidator.actionApply) {
        setActionApplyErrorMessage(
          await TPI18N.GetResource(resultValidator.actionApply),
        );
      }
      return;
    } else {
      setActionApplyErrorMessage("");
    }

    if (itemsReportSelected.length < 1) {
      let newModalErrorState: TPModalErrorStateType = {
        ...modalErrorState,
      };
      newModalErrorState.errorMessage = await TPI18N.GetText(
        resourceSet,
        "ErrorNoSelectionItems",
      );
      newModalErrorState.isShown = true;
      setModalErrorState(newModalErrorState);
      return;
    }
    setModalActions(true);
  };

  const getTimezones = async () => {
    const TimeZoneClient = new TimeZonesService();
    const expectedCodes: Array<number> = [200, 404];
    const timezones = await TimeZoneClient.getTimeZonesCollection(
      false,
      true,
      expectedCodes,
    );
    const timezonesToSelectConverted: Array<TPKeyValue> = timezones.map((k) => {
      return {
        key: k.id,
        value: k.displayName,
      };
    });
    timezonesToSelectConverted.unshift({ key: "", value: "--" });
    setTimeZonesToSelect(timezonesToSelectConverted);
  };

  const getRecordLanguageList = async (
    recordId: string,
    multilanguageTableName: string,
    multilanguageFieldName: string,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          recordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const getStatusesList = async () => {
    let serviceClient = new CaseService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getCasesStatus(
        false,
        true,
        expectedCodes,
      );

      let caseStatusesListData: Array<TPKeyValue>;
      caseStatusesListData = responseRequest.map((status) => ({
        key: status.id,
        value: status.localizedDescription
          ? status.localizedDescription
          : status.description,
      }));
      setCasesStatus(caseStatusesListData);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getStatusesList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getStatusesList ex`);
      setIsLoadingScreen(false);
    }
  };

  const handlerOrganizationChange = async (e: any) => {
    setOrganizationErrorMessage("");
    let newOrganization: string = e.target.value;
    setOrganizationSelected(newOrganization);
    await getRelationsList(newOrganization);
    let command1: commandType = {
      type: commandsEnum.change_CurrentOrganization,
      payload: { newOrganization: newOrganization },
    };
    dispatchCommand(command1);
  };

  const handlerRelationChange = async (e: any) => {
    setRelationErrorMessage("");
    setDefaultBranchHierarchyDescription1("");
    setDefaultBranchHierarchyDescription2("");
    setDefaultBranchHierarchyDescription3("");
    setDefaultBranchHierarchyDescription4("");
    setDefaultBranchHierarchyDescription5("");
    let newRelation: string = e.target.value;
    setRelationSelected(newRelation);
    let newBaseLevel: string;
    let newTree1: string;
    let newTree2: string;
    let newTree3: string;
    let newTree4: string;
    let newTree5: string;
    let mustSelectLastBranch1: boolean;
    let mustSelectLastBranch2: boolean;
    let mustSelectLastBranch3: boolean;
    let mustSelectLastBranch4: boolean;
    let mustSelectLastBranch5: boolean;
    let newLabelTree1: string | null;
    let newLabelTree2: string | null;
    let newLabelTree3: string | null;
    let newLabelTree4: string | null;
    let newLabelTree5: string | null;
    let classificationData: any;
    newBaseLevel = "";
    newTree1 = "";
    newTree2 = "";
    newTree3 = "";
    newTree4 = "";
    newTree5 = "";
    mustSelectLastBranch1 = false;
    mustSelectLastBranch2 = false;
    mustSelectLastBranch3 = false;
    mustSelectLastBranch4 = false;
    mustSelectLastBranch5 = false;
    newLabelTree1 = "";
    newLabelTree2 = "";
    newLabelTree3 = "";
    newLabelTree4 = "";
    newLabelTree5 = "";
    setIsLoadingScreen(true);
    if (newRelation !== "") {
      //get base level info
      newBaseLevel = newRelation.split("|")[0];

      //clasificators
      classificationData = await getClassificatorsConfiguration(
        newRelation,
        newBaseLevel,
      );
      if (classificationData === null) {
        //todo logs
        return;
      }
      newTree1 = classificationData.newTree1;
      newTree2 = classificationData.newTree2;
      newTree3 = classificationData.newTree3;
      newTree4 = classificationData.newTree4;
      newTree5 = classificationData.newTree5;
      mustSelectLastBranch1 = classificationData.mustSelectLastBranch1;
      mustSelectLastBranch2 = classificationData.mustSelectLastBranch2;
      mustSelectLastBranch3 = classificationData.mustSelectLastBranch3;
      mustSelectLastBranch4 = classificationData.mustSelectLastBranch4;
      mustSelectLastBranch5 = classificationData.mustSelectLastBranch5;
      newLabelTree1 = classificationData.newLabelTree1;
      newLabelTree2 = classificationData.newLabelTree2;
      newLabelTree3 = classificationData.newLabelTree3;
      newLabelTree4 = classificationData.newLabelTree4;
      newLabelTree5 = classificationData.newLabelTree5;
    }
    let command1: commandType = {
      type: commandsEnum.change_CurrentRelation,
      payload: {
        newRelation: newRelation,
        newBaseLevel: newBaseLevel,
        newTree1: newTree1,
        newTree2: newTree2,
        newTree3: newTree3,
        newTree4: newTree4,
        newTree5: newTree5,
        newMustSelectLastLevelBranch1: mustSelectLastBranch1,
        newMustSelectLastLevelBranch2: mustSelectLastBranch2,
        newMustSelectLastLevelBranch3: mustSelectLastBranch3,
        newMustSelectLastLevelBranch4: mustSelectLastBranch4,
        newMustSelectLastLevelBranch5: mustSelectLastBranch5,
        newLabelTree1: newLabelTree1,
        newLabelTree2: newLabelTree2,
        newLabelTree3: newLabelTree3,
        newLabelTree4: newLabelTree4,
        newLabelTree5: newLabelTree5,
      },
    };
    dispatchCommand(command1);

    setIsLoadingScreen(false);
  };

  const getPreviousSunday = (date: any) => {
    const previousSunday = new Date();
    previousSunday.setDate(date.getDate() - date.getDay());
    return previousSunday;
  };

  const getPreviousMonday = (date: any) => {
    const previousMonday = new Date();
    previousMonday.setDate(date.getDate() - date.getDay() + 1);
    return previousMonday;
  };

  const getFirstDayOfTheMonth = (year: any, month: any) => {
    return new Date(year, month, 1);
  };

  const changeDateSelectorHandler = (value: any) => {
    setQuickSelectDates(value);
    const today = new Date();
    const thisYear = today.getFullYear();
    const thisMonth = today.getMonth();
    const previousMonday = getPreviousMonday(today);
    const previousSunday = getPreviousSunday(today);
    switch (value) {
      case TPQuickSelectDatesEnum.today:
        setStartDate(today);
        setEndDate(today);
        break;
      case TPQuickSelectDatesEnum.yesterday:
        const yesterdayMilliseconds = today.getTime() - 24 * 60 * 60 * 1000;
        const yesterdayDate = new Date(yesterdayMilliseconds);
        setStartDate(yesterdayDate);
        setEndDate(yesterdayDate);
        break;
      case TPQuickSelectDatesEnum.currentWeek:
        setStartDate(previousMonday);
        setEndDate(today);
        break;
      case TPQuickSelectDatesEnum.lastWeek:
        const mondayLastWeek =
          previousSunday.getTime() - 6 * 24 * 60 * 60 * 1000;
        const mondayLastWeekDate = new Date(mondayLastWeek);
        setStartDate(mondayLastWeekDate);
        setEndDate(previousSunday);
        break;
      case TPQuickSelectDatesEnum.currentMonth:
        const firstDayOfThisMonth = getFirstDayOfTheMonth(thisYear, thisMonth);
        setStartDate(firstDayOfThisMonth);
        setEndDate(today);
        break;
      case TPQuickSelectDatesEnum.lastMonth:
        const firstDayPreviousMonth = new Date(thisYear, thisMonth - 1, 1);
        setStartDate(firstDayPreviousMonth);
        const lastDayPreviousMonth = new Date(thisYear, thisMonth, 0);
        setEndDate(lastDayPreviousMonth);
        break;
      case TPQuickSelectDatesEnum.currentYear:
        const firstDayOfThisYear = new Date(thisYear, 0, 1);
        setStartDate(firstDayOfThisYear);
        setEndDate(today);
        break;
      case TPQuickSelectDatesEnum.lastTwelveMonths:
        const twelveMonthsAgo = new Date(thisYear - 1, thisMonth, 1);
        setStartDate(twelveMonthsAgo);
        setEndDate(today);
        break;
      default:
        break;
    }
  };

  const handleCallBackModalConfirmMassive = async (confirm: boolean) => {
    let newModalQuestionChangeMassive: TPModalQuestionState;
    newModalQuestionChangeMassive = { ...modalQuestionState };
    newModalQuestionChangeMassive.isShown = false;
    newModalQuestionChangeMassive.callBackData = {};

    if (confirm) {
      let recordInputDTO: InactiveCasesClosedMasiveInputDTO = {
        CaseListToInactivate: itemsReportSelected,
      };
      let serviceClient = new CaseMassUpdateService();
      let expectedCodes: Array<number> = [200];

      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.inactiveCasesclosedMassively(
          recordInputDTO,
          true,
          true,
          expectedCodes,
        );

        if (responseRequest.responseResult) {
          setResponseData(responseRequest.responseData.data);
        }
        setModalSuccess(true);
        setIsLoadingScreen(false);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} handleCallBackModalConfirmMassive ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} handleCallBackModalConfirmMassive ex`,
        );
        setIsLoadingScreen(false);
        setModalActions(false);
        return false;
      }
    }
    setModalQuestionChangeMassive(newModalQuestionChangeMassive);
  };

  const handleChangeStartDate = (date: Date) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date: Date) => {
    setEndDate(date);
  };

  const handleTaskTypeChange = (newSelectedValue: Array<TPKeyValue>) => {
    setSelectedAutocompleteTaskTypeOption(newSelectedValue);
  };

  const handleTaskTypeOnAutocompleteQuery = async (query: string) => {
    let tasktypeService = new TaskTypeService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      //Load additioanal data
      let responseRequest = await tasktypeService.getSearchTaskType(
        query,
        TPActiveOptions.ACTIVE,
        null,
        false,
        true,
        expectedCodes,
      );
      let newTaskTypeKeyValueList: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return {
            key: item.id,
            value: `${item.description}`,
            value2: item.componentId,
          };
        },
      );
      setAutocompleteTaskTypeOptions(newTaskTypeKeyValueList);
      return newTaskTypeKeyValueList;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleTaskTypeOnAutocompleteQuery ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} handleTaskTypeOnAutocompleteQuery ex`,
      );
      return [];
    }
  };

  const handleAutoCompleteTopNClick = async () => {
    let newTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteTaskTypeTopNOptions.length === 0) {
      newTopNOptions = await handleTaskTypeOnAutocompleteQuery("");

      if (newTopNOptions.length >= 1) {
        //save on cache
        setAutocompleteTaskTypeTopNOptions([...newTopNOptions]);
        setAutocompleteTaskTypeOptions([...newTopNOptions]);
        setSelectedAutocompleteTaskTypeOption([]);
      }
    } else {
      //use cached values;
      setAutocompleteTaskTypeOptions([...autocompleteTaskTypeTopNOptions]);
      setSelectedAutocompleteTaskTypeOption([]);
    }
  };

  const ValidateRequiredFields = async () => {
    let atLeastOneError: boolean = false;
    if (adminState.currentOrganization === "") {
      atLeastOneError = true;
      setOrganizationErrorMessage(
        await TPI18N.GetText(resourceSet, "InputDTOOrganizationEmpty"),
      );
    }

    if (adminState.currentRelation === "") {
      atLeastOneError = true;
      setRelationErrorMessage(
        await TPI18N.GetText(resourceSet, "InputDTORelationEmpty"),
      );
    }

    if (!timezone) {
      atLeastOneError = true;
      setTimeZoneErrorMessage(
        await TPI18N.GetText(resourceSet, "InputDTOTimezoneEmpty"),
      );
    }

    return atLeastOneError;
  };

  const getInputDTO = async (): Promise<CasesSearchInputDTO | null> => {
    //validations
    if (await ValidateRequiredFields()) {
      const tabFilter = document.querySelector(
        "#idtabFilters",
      ) as HTMLDivElement | null;
      if (!!tabFilter) tabFilter.click();
      return null;
    }

    let inputDTO: CasesSearchInputDTO = {
      userGuid: TPGlobal.currentUserGuid,
      startRecord: 1,
      endRecord: paginationGridState.rowsPerPage,
      startDate: moment(startDate).format("YYYY-MM-DDT05:00:00.000"),
      endDate: moment(endDate).format("YYYY-MM-DDT05:00:00.000"),
      dateType: +dateType,
      timeZoneId: timezone,
      tempTaskSearchFilter: [],
    };
    let baseLevelId: string = relationSelected.split("|")[0];
    if (baseLevelId) {
      let newDetailFilter: CasesSearchFiltersModel = {
        filterName: CaseSearchByFilterType.BaseLevelId,
        filterValue: baseLevelId,
        filterType: "string",
      };
      inputDTO.tempTaskSearchFilter.push(newDetailFilter);
    }
    if (casesStatusSelected.length > 0) {
      const casesStatusString = casesStatusSelected
        .map(({ key }) => key)
        .join(", ");

      let newDetailFilter: CasesSearchFiltersModel = {
        filterName: CaseSearchByFilterType.CaseStatus,
        filterValue: casesStatusString,
        filterType: "string",
      };
      inputDTO.tempTaskSearchFilter.push(newDetailFilter);
    }
    if (privateCase) {
      let newDetailFilter: CasesSearchFiltersModel = {
        filterName: CaseSearchByFilterType.PrivateCase,
        filterValue: privateCase,
        filterType: "bit",
      };
      inputDTO.tempTaskSearchFilter.push(newDetailFilter);
    }
    if (filtersSelected && filtersSelected.length > 0) {
      for (let i = 0; i < filtersSelected.length; i++) {
        const element = filtersSelected[i];
        let clasificatorType: string =
          CaseSearchByFilterType.Classificator.replace(
            "{id}",
            element.classificatorIdx.toString(),
          );
        let newDetailFilter: CasesSearchFiltersModel = {
          filterName: clasificatorType,
          filterValue: element.conditionCode,
          filterType: "string",
        };
        inputDTO.tempTaskSearchFilter.push(newDetailFilter);
      }
    }
    //TODO The backend is waiting this parameter as int, but it will be fix
    if (participatingUser) {
      let newDetailFilter: CasesSearchFiltersModel = {
        filterName: CaseSearchByFilterType.ParticipateUser,
        filterValue: participatingUser,
        filterType: "string",
      };
      inputDTO.tempTaskSearchFilter.push(newDetailFilter);
    }
    if (
      selectedAutocompleteTaskTypeOption &&
      selectedAutocompleteTaskTypeOption.length > 0
    ) {
      let newDetailFilter: CasesSearchFiltersModel = {
        filterName: CaseSearchByFilterType.TaskTypeFilter,
        filterValue: selectedAutocompleteTaskTypeOption[0].key,
        filterType: "string",
      };
      inputDTO.tempTaskSearchFilter.push(newDetailFilter);
    }
    return inputDTO;
  };

  const handleTaskTypeOnAutocompleteKeyDown = (event: any) => {
    if (
      event.keyCode !== 37 &&
      event.keyCode !== 39 &&
      event.keyCode !== 13 &&
      event.keyCode !== 35 &&
      event.keyCode !== 36
    ) {
      setAutocompleteTaskTypeOptions([]);
      setSelectedAutocompleteTaskTypeOption([]);
    }
  };

  const getCasesByFilter = async (inputDTO: CasesSearchInputDTO) => {
    let serviceClient = new CaseService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      let responseRequest = await serviceClient.getByFilters(
        inputDTO,
        false,
        false,
        expectedCodes,
      );
      if (
        responseRequest.responseData.data &&
        responseRequest.responseData.data.length >= 1
      ) {
        return [...responseRequest.responseData.data];
      } else {
        return [];
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCasesByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getCasesByFilter ex`);
      setIsLoadingScreen(false);
      return [];
    }
  };

  const handleSearchButtonClick = async () => {
    let inputDTO: CasesSearchInputDTO | null;
    let newColumnOrderPropsState = { ...columnOrderPropsState };
    newColumnOrderPropsState.currentOrderColum = "caseId";
    newColumnOrderPropsState.currentOrderDirection = "DESC";
    setColumnOrderPropsState(newColumnOrderPropsState);

    inputDTO = await getInputDTO();
    if (inputDTO) {
      setIsLoadingScreen(true);
      let data = await getCasesByFilter(inputDTO);
      setIsLoadingScreen(false);
      if (data && data.length > 0) {
        setReportData(data);
        let newPaginationGridState = { ...paginationGridState };
        newPaginationGridState.currentPage = 1;
        newPaginationGridState.totalRows = data[0]["totalRecords"];
        setPaginationGridState(newPaginationGridState);

        // this fragment code is to switch to the tab result when data fetch en resolve success
        const tabresult = document.querySelector(
          "#idtabResultsStep",
        ) as HTMLDivElement | null;
        if (!!tabresult) tabresult.click();
      } else {
        setReportData([]);
      }
    }
  };

  const handleDeleteConditionGroupClick = (index: number) => {
    const filterSelectedCopy = filtersSelected.filter(
      (k) => k.classificatorIdx !== index,
    );

    setFiltersSelected(filterSelectedCopy);

    handleClearConditionSelect(index);
  };

  const handleClearConditionSelect = (index: number) => {
    const command: commandType = {
      type: commandsEnum.change_CurrentBranch,
      payload: {
        treeNumber: index,
        newBranch: "",
        newDescriptionBranch: "",
      },
    };

    dispatchCommand(command);

    switch (index) {
      case 1:
        branch1SelectorRef?.current?.resetData();
        break;
      case 2:
        branch2SelectorRef?.current?.resetData();
        break;
      case 3:
        branch3SelectorRef?.current?.resetData();
        break;
      case 4:
        branch4SelectorRef?.current?.resetData();
        break;
      case 5:
        branch5SelectorRef?.current?.resetData();
        break;
    }
  };

  const handleOnBranchChange = async (
    label: string,
    index: number,
    branchId: string,
    branchDescription: string,
    branchHierachyDescription: string,
  ) => {
    const prevFilters: any[] = [...filtersSelected];

    if (branchId && branchDescription && branchHierachyDescription) {
      const newConsecutive = conditionQuantity + 1;
      const filtersToPush: any = {
        conditionCode: branchId,
        conditionText: branchDescription,
        filterType: label,
        id: newConsecutive,
        organizationRelation: `${organizationSelected} - ${relationSelected}`,
        classificatorIdx: index,
      };
      setConditionQuantity(newConsecutive.toString());
      prevFilters.push(filtersToPush);
      setFiltersSelected(prevFilters);
    }

    let branch1: string;
    let branch2: string;
    let branch3: string;
    let branch4: string;
    let branch5: string;
    switch (index) {
      case 1:
        setDefaultBranchHierarchyDescription1(branchHierachyDescription);
        break;
      case 2:
        setDefaultBranchHierarchyDescription2(branchHierachyDescription);
        break;
      case 3:
        setDefaultBranchHierarchyDescription3(branchHierachyDescription);
        break;
      case 4:
        setDefaultBranchHierarchyDescription4(branchHierachyDescription);
        break;
      case 5:
        setDefaultBranchHierarchyDescription5(branchHierachyDescription);
        break;
      default:
        break;
    }
    branch1 = "";
    branch2 = "";
    branch3 = "";
    branch4 = "";
    branch5 = "";

    if (adminState.currentTree1 !== TPGlobal.Tree.NA_TreeCode) {
      if (index === 1) {
        branch1 = branchId;
      } else {
        branch1 = adminState.currentBranch1;
      }
    }
    if (adminState.currentTree2 !== TPGlobal.Tree.NA_TreeCode) {
      if (index === 2) {
        branch2 = branchId;
      } else {
        branch2 = adminState.currentBranch2;
      }
    }
    if (adminState.currentTree3 !== TPGlobal.Tree.NA_TreeCode) {
      if (index === 3) {
        branch3 = branchId;
      } else {
        branch3 = adminState.currentBranch3;
      }
    }
    if (adminState.currentTree4 !== TPGlobal.Tree.NA_TreeCode) {
      if (index === 4) {
        branch4 = branchId;
      } else {
        branch4 = adminState.currentBranch4;
      }
    }
    if (adminState.currentTree5 !== TPGlobal.Tree.NA_TreeCode) {
      if (index === 5) {
        branch5 = branchId;
      } else {
        branch5 = adminState.currentBranch5;
      }
    }

    let command1: commandType = {
      type: commandsEnum.change_CurrentBranch,
      payload: {
        treeNumber: index,
        newBranch: branchId,
        newDescriptionBranch: branchDescription,
      },
    };
    dispatchCommand(command1);
  };

  const handleDeleteCondition = (conditionId: string) => {
    if (conditionId) {
      const filtersSelectedCopy = [...filtersSelected];
      const condition = filtersSelectedCopy.find((k) => k.id === conditionId);
      handleClearConditionSelect(condition?.classificatorIdx);
      const newState = filtersSelectedCopy.filter((k) => k.id !== conditionId);
      setFiltersSelected(newState);
    }
  };

  const handleOtherSettingsColumns = () => {
    let newModalSettingColumnsState: ModalSettingsColumnsType = {
      ...modalSettingColumnsState,
    };

    newModalSettingColumnsState.isShown = true;
    newModalSettingColumnsState.localRowsPerPage =
      paginationGridState.rowsPerPage;
    setModalSettingColumnsState(newModalSettingColumnsState);
  };

  const handleCheckBoxAll = (checked: boolean) => {
    const selectedAllItems: Array<number> = [];
    if (checked) {
      reportData.forEach((item) => {
        selectedAllItems.push(item.caseId);
      });
    }
    setItemsReportSelected(selectedAllItems);
    setIsCheckedAll(checked);
  };

  const isChecked = (caseId: number) =>
    itemsReportSelected.indexOf(caseId) >= 0;

  // const handleSelectItem = (checked: boolean, caseId: number) => {
  //   let data: Array<number> = [...itemsReportSelected];
  //   if (checked) {
  //     data.push(caseId);
  //   } else {
  //     data = itemsReportSelected.filter((item) => item !== caseId);
  //   }
  //   setItemsReportSelected(data);
  //   setIsCheckedAll(data.length === reportData.length ? true : false);
  // };
  const handleSelectItem = (selectedItems: any[]) => {
    const selectedCaseIds = selectedItems?.map((item) => item.caseId);
    setItemsReportSelected(selectedCaseIds);
    setIsCheckedAll(selectedCaseIds.length === reportData.length);
  };

  const sortData = (
    theData: Array<any>,
    columnToSort: string,
    columnType: string,
    orderBy: string,
  ): Array<any> => {
    let newSortData: Array<any> = [];
    switch (columnType) {
      case "string":
        if (orderBy === "ASC") {
          newSortData = [
            ...theData.sort((a, b) =>
              a[columnToSort].localeCompare(b[columnToSort]),
            ),
          ];
        } else {
          newSortData = [
            ...theData
              .sort((a, b) => a[columnToSort].localeCompare(b[columnToSort]))
              .reverse(),
          ];
        }
        break;
      case "number":
        if (orderBy === "ASC") {
          newSortData = [
            ...theData.sort((a, b) => a[columnToSort] - b[columnToSort]),
          ];
        } else {
          newSortData = [
            ...theData.sort((a, b) => b[columnToSort] - a[columnToSort]),
          ];
        }
        break;

      case "date":
        if (orderBy === "ASC") {
          newSortData = [
            ...theData.sort(function (left, right) {
              return moment
                .utc(left[columnToSort])
                .diff(moment.utc(right[columnToSort]));
            }),
          ];
        } else {
          newSortData = [
            ...theData
              .sort(function (left, right) {
                return moment(left[columnToSort]).diff(
                  moment(right[columnToSort]),
                );
              })
              .reverse(),
          ];
        }
        break;
      default:
        break;
    }

    setReportData(newSortData);
    return newSortData;
  };

  const handleCallbackAnserModal = async (status: boolean) => {
    let newModalSettingColumnsState: ModalSettingsColumnsType = {
      ...modalSettingColumnsState,
    };
    newModalSettingColumnsState.isShown = false;
    setModalSettingColumnsState(newModalSettingColumnsState);
    setModalActions(false);

    if (status) {
      let newPageNumber: number;
      newPageNumber = 1;
      let inputDTO: CasesSearchInputDTO | null;
      inputDTO = await getInputDTO();
      if (inputDTO) {
        inputDTO.startRecord =
          (newPageNumber - 1) * modalSettingColumnsState.localRowsPerPage + 1;
        inputDTO.endRecord =
          newPageNumber * modalSettingColumnsState.localRowsPerPage;
        setIsLoadingScreen(true);
        let data = await getCasesByFilter(inputDTO);
        setIsLoadingScreen(false);
        if (data && data.length > 0) {
          sortData(
            data,
            columnOrderPropsState.currentOrderColum,
            columnOrderPropsState.currentOrderType,
            columnOrderPropsState.currentOrderDirection,
          );

          let newPaginationGridState = { ...paginationGridState };
          newPaginationGridState.rowsPerPage =
            modalSettingColumnsState.localRowsPerPage;
          newPaginationGridState.totalRows = data[0]["totalRecords"];
          newPaginationGridState.currentPage = newPageNumber;
          setPaginationGridState(newPaginationGridState);
        } else {
          setReportData([]);
        }
      }
    }
  };

  const handleColumnOrderOnclick = (column: ColumnProps) => {
    let newColumnOrderPropsState = { ...columnOrderPropsState };
    newColumnOrderPropsState.currentOrderColum = column.columnId;
    newColumnOrderPropsState.currentOrderType = column.type;
    let realOrderDirection: string = "";
    if (columnOrderPropsState.currentOrderDirection === "ASC") {
      realOrderDirection = "DESC";
      newColumnOrderPropsState.currentOrderDirection = realOrderDirection;
    } else {
      realOrderDirection = "ASC";
      newColumnOrderPropsState.currentOrderDirection = realOrderDirection;
    }
    setColumnOrderPropsState(newColumnOrderPropsState);
    sortData(reportData, column.columnId, column.type, realOrderDirection);
  };
  const titleModalByAction = () => {
    let title = "";

    if (actionApplySelected) {
      const action: any = getValuesComboActions()
        .filter((item) => item.key === actionApplySelected.toString())
        .shift();

      title = action.value;
    }

    return title;
  };

  const handleCallbackModalActions = async (acceptButton: boolean) => {
    if (actionApplySelected) {
      if (+actionApplySelected === 1) {
        await handleAddMassiveTask(acceptButton);
      }
      if (+actionApplySelected === 2) {
        await handlereclassifyMassiveTasks(acceptButton);
      }
      if (+actionApplySelected === 3) {
        await handleCompletePendingTasks(acceptButton);
      }
      if (+actionApplySelected === 4) {
        if (acceptButton) {
          let newModalQuestionState: TPModalQuestionState;
          newModalQuestionState = { ...modalQuestionState };
          newModalQuestionState.isShown = true;
          setModalQuestionChangeMassive(newModalQuestionState);
        } else {
          setIsLoadingScreen(false);
          setModalActions(false);
        }
      }
    }
  };

  const transformedData = reportData?.map((item) => ({
    ...item,
    // classifier4LocalizedDescription:item.!==null ? item.:"",
    // classifier4LocalizedDescription:item.!==null ? item.:"",
    comments: item.comments.replace(/<\/?[^>]+(>|$)/g, ""),
  }));

  useEffect(() => {
    loadResourcesAndOrganizations();
  }, []);

  return (
    <ContentVerticalNoTabsStyled>
      <div className="container-fluid">
        <TPLoadingOverlay active={isLoadingScreen} isModal>
          <TPPageTitle>{pageTitleLabel}</TPPageTitle>
          <hr />

          <div className="row">
            <div className="col">
              <TPWizard>
                <TPWizardStep
                  isActive={true}
                  id="tabFiltersStep"
                  labelText={filterLabel}
                  numberText="1"
                  name="TPWizardStep"
                />
                <TPWizardContent
                  id="tabFiltersStep"
                  isActive={true}
                  name="TPWizardContent"
                >
                  <div className="row">
                    <div className="col">
                      <TPPageSectionTitle>
                        {organizationRelationLabel}
                      </TPPageSectionTitle>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-11">
                      <TPPageSection>
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <TPSelect
                                id="IdSelect"
                                onChange={handlerOrganizationChange}
                                dataSource={organizationListKeyValue}
                                value={adminState.currentOrganization}
                                labelText={organizationLabel}
                                isHorizontal={false}
                                isMandatory={true}
                                errorMessage={organizationErrorMessage}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <TPSelect
                                id="IdSelect"
                                onChange={handlerRelationChange}
                                dataSource={relationListKeyValue}
                                value={adminState.currentRelation}
                                labelText={relationLabel}
                                isHorizontal={false}
                                isMandatory={true}
                                errorMessage={relationErrorMessage}
                              />
                            </div>
                          </div>
                        </div>
                      </TPPageSection>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <TPPageSectionTitle>
                        {quickSelectDatesLabel}
                      </TPPageSectionTitle>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-11">
                      <TPPageSection>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <TPRadioGroup
                                id="IdRadioGroup"
                                onChange={(e: any) =>
                                  changeDateSelectorHandler(e.target.value)
                                }
                                value={quickSelectDates}
                                source={quickSelectDatesValue}
                              ></TPRadioGroup>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <TPDatePicker
                                selectedDate={startDate}
                                onChangeDate={(e: Date) =>
                                  handleChangeStartDate(e)
                                }
                                labelText={startDateLabel}
                                isHorizontal={false}
                                isMandatory={true}
                              ></TPDatePicker>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <TPDatePicker
                                selectedDate={endDate}
                                onChangeDate={(e: Date) =>
                                  handleChangeEndDate(e)
                                }
                                labelText={endDateLabel}
                                isHorizontal={false}
                                isMandatory={true}
                              ></TPDatePicker>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <TPSelect
                                id="IdSelect"
                                labelText={dateTypeLabel}
                                isMandatory={true}
                                value={dateType}
                                onChange={(e: any) => {
                                  setDateType(e.target.value);
                                }}
                                dataSource={[
                                  {
                                    key: "0",
                                    value: createDateLabel,
                                  },
                                  {
                                    key: "1",
                                    value: updateDateLabel,
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      </TPPageSection>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <TPPageSectionTitle>
                        {moreFiltersLabel}
                      </TPPageSectionTitle>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-11">
                      <TPPageSection>
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <TPSelect
                                    id="IdSelect"
                                    labelText={timezoneLabel}
                                    isMandatory={true}
                                    value={timezone}
                                    onChange={(e: any) => {
                                      setTimezone(e.target.value);
                                      setTimeZoneErrorMessage("");
                                    }}
                                    dataSource={timezonesToSelect}
                                    errorMessage={timeZoneErrorMessage}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <SearchSelect
                                    id="massUpdate-caseStatus"
                                    width="300px"
                                    options={casesStatus}
                                    optionSelected={casesStatusSelected}
                                    label={caseStatusLabel}
                                    placeholder={
                                      caseStatusChoosePlaceholderLabel
                                    }
                                    handleMultiChange={handleGroupsChange}
                                    removeSelectedOptionRef={removeGroupRef}
                                    orientation="vertical"
                                    isMandatory={false}
                                    isMulti
                                    isClearable
                                  />
                                  <RenderSelectedItems />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <TPSelect
                                  id="IdSelect"
                                  labelText={privateCaseLabel}
                                  isMandatory={true}
                                  value={privateCase}
                                  onChange={(e: any) => {
                                    setPrivateCase(e.target.value);
                                  }}
                                  dataSource={[
                                    {
                                      key: "2",
                                      value: IsActiveAll,
                                    },
                                    {
                                      key: "1",
                                      value: IsActiveYes,
                                    },
                                    {
                                      key: "0",
                                      value: IsActiveNo,
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <TPSelect
                                    id="IdSelect"
                                    labelText={participatingUserLabel}
                                    isMandatory={false}
                                    value={participatingUser}
                                    onChange={(e: any) => {
                                      setParticipatingUser(e.target.value);
                                    }}
                                    dataSource={participatingUsersList}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <TPAutoComplete
                                    isMandatory={false}
                                    labelText={taskTypeLabel}
                                    onValueChange={handleTaskTypeChange}
                                    onSearch={(query: string) => {
                                      handleTaskTypeOnAutocompleteQuery(query);
                                    }}
                                    isLoading={false}
                                    options={autocompleteTaskTypeOptions}
                                    withIcon={true}
                                    emptyLabel={emptyLabel}
                                    onKeyDown={
                                      handleTaskTypeOnAutocompleteKeyDown
                                    }
                                    selected={
                                      selectedAutocompleteTaskTypeOption
                                    }
                                    errorMessage={""}
                                    downArrowClick={handleAutoCompleteTopNClick}
                                  ></TPAutoComplete>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TPPageSection>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-11">
                      <TPPageAcceptCancelButtonsContainer>
                        <TPButton
                          id="IdButton"
                          type={TPButtonTypes.primary}
                          onClick={handleSearchButtonClick}
                        >
                          {SearchButtonLabel}
                        </TPButton>
                      </TPPageAcceptCancelButtonsContainer>
                    </div>
                  </div>
                </TPWizardContent>

                <TPWizardStep
                  isActive={false}
                  id="tabOtherFiltersStep"
                  labelText={otherFiltersLabel}
                  numberText="2"
                  name="TPWizardStep"
                />
                <TPWizardContent
                  id="tabOtherFiltersStep"
                  isActive={false}
                  name="TPWizardContent"
                >
                  <div className="row">
                    <div className="col">
                      <TPPageSectionTitle>
                        {classificatorLabel}
                      </TPPageSectionTitle>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-11">
                      <TPPageSection>
                        <div className="row">
                          <div className="col-6">
                            {/* Classificator 1 */}
                            {adminState.currentRelation === "" &&
                            adminState.currentTree1 !==
                              TPGlobal.Tree.NA_TreeCode ? (
                              <>
                                <div className="col-12 mt-2">
                                  <div className="form-group">
                                    <span className="tpcircle-text">1</span>
                                    &nbsp;
                                    {"-"}
                                    &nbsp;
                                    <TPTextBox
                                      id="IdTextBox"
                                      onChange={() => {
                                        TPGlobal.foo();
                                      }}
                                      value=""
                                      disabled={true}
                                      labelText={dummyClassfierLabel}
                                      withCircleText
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {adminState.currentTree1 !==
                                  TPGlobal.Tree.NA_TreeCode && (
                                  <div className="row">
                                    <div className="col-12">
                                      <TPBranchSelection2
                                        withDelete={true}
                                        onDeleteClick={(treeId: string) => {
                                          handleDeleteConditionGroupClick(1);
                                        }}
                                        ref={(element: any) => {
                                          branch1SelectorRef.current = element;
                                        }}
                                        labelText={adminState.currentLabelTree1}
                                        treeId={adminState.currentTree1}
                                        modalAcceptLabel={saveButtonLabel}
                                        modalCancelLabel={cancelButtonLabel}
                                        modalSelectedBranchLabel={
                                          modalSelectedBranchLabel
                                        }
                                        modalTitle={modalTitleLabel}
                                        mustSelectLastLevelBranch={
                                          adminState.mustSelectLastLevelBranch1
                                        }
                                        value={{
                                          branchId: adminState.currentBranch1,
                                          branchDescription:
                                            adminState.currentDescriptionBranch1,
                                          branchHierarchyDescription:
                                            defaultBranchHierarchyDescription1,
                                        }}
                                        onChange={(
                                          branchId: string,
                                          branchDescription: string,
                                          branchHierachyDescription: string,
                                        ) => {
                                          handleOnBranchChange(
                                            adminState.currentLabelTree1,
                                            1,
                                            branchId,
                                            branchDescription,
                                            branchHierachyDescription,
                                          );
                                        }}
                                        emptyLabel={emptyLabel}
                                        errorMessage="" //todo
                                        autoCloseTreeModalWhenSelect={true}
                                        onIsLoadingProgress={(
                                          value: boolean,
                                        ) => {
                                          setIsLoadingScreen(value);
                                        }}
                                      ></TPBranchSelection2>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div className="col-6">
                            {/* Classificator 2 */}
                            {adminState.currentRelation === "" &&
                            adminState.currentTree2 !==
                              TPGlobal.Tree.NA_TreeCode ? (
                              <>
                                <div className="col-12 mt-2">
                                  <div className="form-group">
                                    <span className="tpcircle-text">2</span>
                                    &nbsp;
                                    {"-"}
                                    &nbsp;
                                    <TPTextBox
                                      id="IdTextBox"
                                      onChange={() => {
                                        TPGlobal.foo();
                                      }}
                                      value=""
                                      disabled={true}
                                      labelText={dummyClassfierLabel}
                                      withCircleText
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {adminState.currentTree2 !==
                                  TPGlobal.Tree.NA_TreeCode && (
                                  <div className="row">
                                    <div className="col-12">
                                      <TPBranchSelection2
                                        withDelete={true}
                                        onDeleteClick={(treeId: string) => {
                                          handleDeleteConditionGroupClick(2);
                                        }}
                                        ref={(element: any) => {
                                          branch2SelectorRef.current = element;
                                        }}
                                        labelText={adminState.currentLabelTree2}
                                        treeId={adminState.currentTree2}
                                        modalAcceptLabel={saveButtonLabel}
                                        modalCancelLabel={cancelButtonLabel}
                                        modalSelectedBranchLabel={
                                          modalSelectedBranchLabel
                                        }
                                        modalTitle={modalTitleLabel}
                                        mustSelectLastLevelBranch={
                                          adminState.mustSelectLastLevelBranch2
                                        }
                                        value={{
                                          branchId: adminState.currentBranch2,
                                          branchDescription:
                                            adminState.currentDescriptionBranch2,
                                          branchHierarchyDescription:
                                            defaultBranchHierarchyDescription2,
                                        }}
                                        onChange={(
                                          branchId: string,
                                          branchDescription: string,
                                          branchHierachyDescription: string,
                                        ) => {
                                          handleOnBranchChange(
                                            adminState.currentLabelTree2,
                                            2,
                                            branchId,
                                            branchDescription,
                                            branchHierachyDescription,
                                          );
                                        }}
                                        emptyLabel={emptyLabel}
                                        errorMessage="" //todo
                                        autoCloseTreeModalWhenSelect={true}
                                        onIsLoadingProgress={(
                                          value: boolean,
                                        ) => {
                                          setIsLoadingScreen(value);
                                        }}
                                      ></TPBranchSelection2>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            {/* Classificator 3 */}
                            {adminState.currentRelation === "" &&
                            adminState.currentTree3 !==
                              TPGlobal.Tree.NA_TreeCode ? (
                              <>
                                <div className="col-12 mt-2">
                                  <div className="form-group">
                                    <span className="tpcircle-text">3</span>
                                    &nbsp;
                                    {"-"}
                                    &nbsp;
                                    <TPTextBox
                                      id="IdTextBox"
                                      onChange={() => {
                                        TPGlobal.foo();
                                      }}
                                      value=""
                                      disabled={true}
                                      labelText={dummyClassfierLabel}
                                      withCircleText
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {adminState.currentTree3 !==
                                  TPGlobal.Tree.NA_TreeCode && (
                                  <div className="row">
                                    <div className="col-12">
                                      <TPBranchSelection2
                                        withDelete={true}
                                        onDeleteClick={(treeId: string) => {
                                          handleDeleteConditionGroupClick(3);
                                        }}
                                        ref={(element: any) => {
                                          branch3SelectorRef.current = element;
                                        }}
                                        labelText={adminState.currentLabelTree3}
                                        treeId={adminState.currentTree3}
                                        modalAcceptLabel={saveButtonLabel}
                                        modalCancelLabel={cancelButtonLabel}
                                        modalSelectedBranchLabel={
                                          modalSelectedBranchLabel
                                        }
                                        modalTitle={modalTitleLabel}
                                        mustSelectLastLevelBranch={
                                          adminState.mustSelectLastLevelBranch3
                                        }
                                        value={{
                                          branchId: adminState.currentBranch3,
                                          branchDescription:
                                            adminState.currentDescriptionBranch3,
                                          branchHierarchyDescription:
                                            defaultBranchHierarchyDescription3,
                                        }}
                                        onChange={(
                                          branchId: string,
                                          branchDescription: string,
                                          branchHierachyDescription: string,
                                        ) => {
                                          handleOnBranchChange(
                                            adminState.currentLabelTree3,
                                            3,
                                            branchId,
                                            branchDescription,
                                            branchHierachyDescription,
                                          );
                                        }}
                                        emptyLabel={emptyLabel}
                                        errorMessage="" //todo
                                        autoCloseTreeModalWhenSelect={true}
                                        onIsLoadingProgress={(
                                          value: boolean,
                                        ) => {
                                          setIsLoadingScreen(value);
                                        }}
                                      ></TPBranchSelection2>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div className="col-6">
                            {/* Classificator 4 */}
                            {adminState.currentRelation === "" &&
                            adminState.currentTree4 !==
                              TPGlobal.Tree.NA_TreeCode ? (
                              <>
                                <div className="col-12 mt-2">
                                  <div className="form-group">
                                    <span className="tpcircle-text">4</span>
                                    &nbsp;
                                    {"-"}
                                    &nbsp;
                                    <TPTextBox
                                      id="IdTextBox"
                                      onChange={() => {
                                        TPGlobal.foo();
                                      }}
                                      value=""
                                      disabled={true}
                                      labelText={dummyClassfierLabel}
                                      withCircleText
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {adminState.currentTree4 !==
                                  TPGlobal.Tree.NA_TreeCode && (
                                  <div className="row">
                                    <div className="col-12">
                                      <TPBranchSelection2
                                        withDelete={true}
                                        onDeleteClick={(treeId: string) => {
                                          handleDeleteConditionGroupClick(4);
                                        }}
                                        ref={(element: any) => {
                                          branch4SelectorRef.current = element;
                                        }}
                                        labelText={adminState.currentLabelTree4}
                                        treeId={adminState.currentTree4}
                                        modalAcceptLabel={saveButtonLabel}
                                        modalCancelLabel={cancelButtonLabel}
                                        modalSelectedBranchLabel={
                                          modalSelectedBranchLabel
                                        }
                                        modalTitle={modalTitleLabel}
                                        mustSelectLastLevelBranch={
                                          adminState.mustSelectLastLevelBranch4
                                        }
                                        value={{
                                          branchId: adminState.currentBranch4,
                                          branchDescription:
                                            adminState.currentDescriptionBranch4,
                                          branchHierarchyDescription:
                                            defaultBranchHierarchyDescription4,
                                        }}
                                        onChange={(
                                          branchId: string,
                                          branchDescription: string,
                                          branchHierachyDescription: string,
                                        ) => {
                                          handleOnBranchChange(
                                            adminState.currentLabelTree4,
                                            4,
                                            branchId,
                                            branchDescription,
                                            branchHierachyDescription,
                                          );
                                        }}
                                        emptyLabel={emptyLabel}
                                        errorMessage="" //todo
                                        autoCloseTreeModalWhenSelect={true}
                                        onIsLoadingProgress={(
                                          value: boolean,
                                        ) => {
                                          setIsLoadingScreen(value);
                                        }}
                                      ></TPBranchSelection2>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            {/* Classificator 5 */}
                            {adminState.currentRelation === "" &&
                            adminState.currentTree5 !==
                              TPGlobal.Tree.NA_TreeCode ? (
                              <>
                                <div className="col-12 mt-2">
                                  <div className="form-group">
                                    <span className="tpcircle-text">5</span>
                                    &nbsp;
                                    {"-"}
                                    &nbsp;
                                    <TPTextBox
                                      id="IdTextBox"
                                      onChange={() => {
                                        TPGlobal.foo();
                                      }}
                                      value=""
                                      disabled={true}
                                      labelText={dummyClassfierLabel}
                                      withCircleText
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {adminState.currentTree5 !==
                                  TPGlobal.Tree.NA_TreeCode && (
                                  <div className="row">
                                    <div className="col-12">
                                      <TPBranchSelection2
                                        withDelete={true}
                                        onDeleteClick={(treeId: string) => {
                                          handleDeleteConditionGroupClick(5);
                                        }}
                                        ref={(element: any) => {
                                          branch5SelectorRef.current = element;
                                        }}
                                        labelText={adminState.currentLabelTree5}
                                        treeId={adminState.currentTree5}
                                        modalAcceptLabel={saveButtonLabel}
                                        modalCancelLabel={cancelButtonLabel}
                                        modalSelectedBranchLabel={
                                          modalSelectedBranchLabel
                                        }
                                        modalTitle={modalTitleLabel}
                                        mustSelectLastLevelBranch={
                                          adminState.mustSelectLastLevelBranch5
                                        }
                                        value={{
                                          branchId: adminState.currentBranch5,
                                          branchDescription:
                                            adminState.currentDescriptionBranch5,
                                          branchHierarchyDescription:
                                            defaultBranchHierarchyDescription5,
                                        }}
                                        onChange={(
                                          branchId: string,
                                          branchDescription: string,
                                          branchHierachyDescription: string,
                                        ) => {
                                          handleOnBranchChange(
                                            adminState.currentLabelTree5,
                                            5,
                                            branchId,
                                            branchDescription,
                                            branchHierachyDescription,
                                          );
                                        }}
                                        emptyLabel={emptyLabel}
                                        errorMessage=""
                                        autoCloseTreeModalWhenSelect={true}
                                        onIsLoadingProgress={(
                                          value: boolean,
                                        ) => {
                                          setIsLoadingScreen(value);
                                        }}
                                      ></TPBranchSelection2>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <TPConditionsTable
                            conditions={filtersSelected}
                            onDelete={(e: any) => handleDeleteCondition(e)}
                            deletable={true}
                            showQuery={true}
                          />
                        </div>
                      </TPPageSection>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-11">
                      <TPPageAcceptCancelButtonsContainer>
                        <TPButton
                          id="IdButton"
                          type={TPButtonTypes.primary}
                          onClick={handleSearchButtonClick}
                        >
                          {SearchButtonLabel}
                        </TPButton>
                      </TPPageAcceptCancelButtonsContainer>
                    </div>
                  </div>
                </TPWizardContent>

                <TPWizardStep
                  isActive={false}
                  id="tabResultsStep"
                  labelText={resultsLabel}
                  numberText="3"
                  name="TPWizardStep"
                />
                <TPWizardContent
                  id="tabResultsStep"
                  isActive={false}
                  name="TPWizardContent"
                >
                  {reportData && reportData.length > 0 && (
                    <div className="container-fluid">
                      <div className="row ">
                        <div className="col-2 px-0">
                          <TPSelect
                            id="IdSelect"
                            isMandatory={true}
                            labelText={actionLabel}
                            onChange={(e: any) => {
                              setActionApplySelected(e.target.value);
                              setActionApplyErrorMessage("");
                            }}
                            dataSource={getValuesComboActions()}
                            value={actionApplySelected}
                            errorMessage={actionApplyErrorMessage}
                          ></TPSelect>
                        </div>
                        <div className="col-8 mt-4">
                          <TPButton
                            id="IdButton"
                            type={TPButtonTypes.primary}
                            onClick={handleModalAction}
                          >
                            {applyLabel}
                          </TPButton>
                        </div>
                        <div className="col-2 mt-4">
                          <TPButton
                            id="IdButton"
                            type={TPButtonTypes.icon}
                            onClick={() => handleOtherSettingsColumns()}
                            text={otherSettingsLabel}
                            icon={TPIconTypes.settings}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row mt-4">
                    <div className="col">
                      <div className="table-responsive">
                        {/* <table className="tptable-report table table-striped table-sm table-bordered caption-top">
                          <thead>
                            <tr>
                              <th>
                                <TPCheckBox
                                  id="IdCheckBox"
                                  labelText={""}
                                  checked={isCheckedAll}
                                  onChange={(e: any) =>
                                    handleCheckBoxAll(e.target.checked)
                                  }
                                  className="d-flex justify-content-center"
                                ></TPCheckBox>
                              </th>
                              {reportColumns.map(function (item, idx) {
                                if (item.visible) {
                                  if (item.sort) {
                                    if (
                                      item.columnId ===
                                      columnOrderPropsState.currentOrderColum
                                    ) {
                                      return (
                                        <th
                                          key={`th-${item.columnId}`}
                                          style={{
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          <span>{item.columnLabel}</span>
                                          <div
                                            style={{
                                              display: "inline",
                                              width: "24px",
                                            }}
                                          >
                                            {" "}
                                            {columnOrderPropsState.currentOrderDirection ===
                                              "ASC" && (
                                              <TPIcon
                                                onClick={() =>
                                                  handleColumnOrderOnclick(item)
                                                }
                                                style={{ fontSize: "16px" }}
                                                iconType={
                                                  TPIconTypes.arrowDropDown
                                                }
                                                className="tp-rotate180"
                                                inline={true}
                                              />
                                            )}
                                            {columnOrderPropsState.currentOrderDirection ===
                                              "DESC" && (
                                              <TPIcon
                                                onClick={() =>
                                                  handleColumnOrderOnclick(item)
                                                }
                                                style={{ fontSize: "16px" }}
                                                iconType={
                                                  TPIconTypes.arrowDropUp
                                                }
                                                className="tp-rotate180"
                                                inline={true}
                                              />
                                            )}
                                          </div>
                                        </th>
                                      );
                                    } else {
                                      return (
                                        <th
                                          key={`th-${item.columnId}`}
                                          className="tp-onhovershow"
                                          style={{
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          <span>{item.columnLabel}</span>
                                          <div
                                            style={{
                                              display: "inline-block",
                                              minWidth: "24px",
                                              minHeight: "1px",
                                            }}
                                          >
                                            &nbsp;
                                            <TPIcon
                                              onClick={() =>
                                                handleColumnOrderOnclick(item)
                                              }
                                              style={{
                                                fontSize: "16px",
                                                cursor: "pointer",
                                              }}
                                              iconType={
                                                TPIconTypes.arrowDropDown
                                              }
                                              inline={true}
                                            />
                                          </div>
                                        </th>
                                      );
                                    }
                                  } else {
                                    return (
                                      <th
                                        key={`th-${item.columnId}`}
                                        style={{
                                          whiteSpace: "nowrap",
                                          width: "110%",
                                        }}
                                      >
                                        <span>{item.columnLabel}</span>
                                      </th>
                                    );
                                  }
                                }
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {reportData.map(function (row: any, idx: number) {
                              return (
                                <tr key={`tr-${idx}`}>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <TPCheckBox
                                      id="IdCheckBox"
                                      labelText={""}
                                      checked={isChecked(row.caseId)}
                                      onChange={(e: any) =>
                                        handleSelectItem(
                                          e.target.checked,
                                          row.caseId
                                        )
                                      }
                                    ></TPCheckBox>
                                  </td>
                                  {reportColumns
                                    .filter((item) => item.visible)
                                    .map(function (
                                      itemRow: ColumnProps,
                                      idx: number
                                    ) {
                                      if (idx === 0) {
                                        let imageStatus: any;
                                        switch (row["caseStatusId"]) {
                                          case taskStatusEnum.Solved:
                                            imageStatus = (
                                              <TPIcon
                                                iconType={
                                                  TPIconTypes.checkCircle
                                                }
                                                style={{ color: "#69c169" }}
                                                inline={true}
                                              />
                                            );
                                            break;
                                          case taskStatusEnum.Pending:
                                            imageStatus = (
                                              <TPIcon
                                                iconType={
                                                  TPIconTypes.watchLater
                                                }
                                                style={{
                                                  color: "rgb(11 122 187)",
                                                }}
                                                inline={true}
                                              />
                                            );
                                            //imageStatus = "image pending";
                                            break;
                                          case taskStatusEnum.Expired:
                                            imageStatus = (
                                              <TPIcon
                                                iconType={TPIconTypes.warning}
                                                style={{ color: "red" }}
                                                inline={true}
                                              />
                                            );
                                            break;
                                          case taskStatusEnum.Alert:
                                            imageStatus = (
                                              <TPIcon
                                                iconType={TPIconTypes.warning}
                                                style={{ color: "red" }}
                                                inline={true}
                                              />
                                            );
                                            break;
                                          case taskStatusEnum.Lag:
                                            imageStatus = (
                                              <TPIcon
                                                iconType={
                                                  TPIconTypes.hourglassTop
                                                }
                                                style={{ color: "#e57348" }}
                                                inline={true}
                                              />
                                            );
                                            break;
                                        }
                                        return (
                                          <td
                                            key={`td-${idx}`}
                                            style={{
                                              whiteSpace: "nowrap",
                                              textAlign: "center",
                                              fontSize: "18px",
                                            }}
                                          >
                                            {imageStatus}{" "}
                                            <span style={{ fontSize: "13px" }}>
                                              {
                                                row[
                                                  "caseStatusLocalizedDescription"
                                                ]
                                              }
                                            </span>
                                          </td>
                                        );
                                      }
                                      if (idx === 2) {
                                        return (
                                          <td
                                            key={`td-${idx}`}
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            <span
                                              onClick={() =>
                                                casecontext.handleAddNewCaseViewerCallBack(
                                                  row["caseId"]
                                                )
                                              }
                                              style={{
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {row["caseId"]}
                                            </span>
                                          </td>
                                        );
                                      }
                                      return (
                                        <td
                                          key={`td${idx}`}
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          {}
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: row[itemRow.columnId],
                                            }}
                                          ></span>
                                        </td>
                                      );
                                    })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table> */}
                      </div>
                      {/* {(!reportData || reportData.length === 0) && (
                        <span style={{ fontSize: "13px", marginLeft: "10px" }}>
                          <TPLanguage
                            resourceSet={TPGlobal.globalResourceSet}
                            resourceId="DataTableNoCurrentData"
                          />
                        </span>
                      )}
                      {reportData && reportData.length > 0 && (
                        <div
                          style={{ fontSize: "13px" }}
                          className="col d-flex justify-content-end align-items-center pt-2"
                        >
                          <div>
                            <span style={{ color: "gray" }}>
                              {(paginationGridState.currentPage - 1) *
                                paginationGridState.rowsPerPage +
                                1}
                              &nbsp;-&nbsp;
                              {(paginationGridState.currentPage - 1) *
                                paginationGridState.rowsPerPage +
                                paginationGridState.rowsPerPage <
                              paginationGridState.totalRows
                                ? (paginationGridState.currentPage - 1) *
                                    paginationGridState.rowsPerPage +
                                  paginationGridState.rowsPerPage
                                : paginationGridState.totalRows}
                              &nbsp;&nbsp;{totalRowsLabel}&nbsp;
                              {paginationGridState.totalRows}
                            </span>
                          </div>
                          <div>
                            <span
                              className="page-button"
                              style={
                                paginationGridState.currentPage === 1
                                  ? { color: "gray" }
                                  : { color: "#a00095" }
                              }
                            >
                              <TPIcon
                                iconType={TPIconTypes.firstPage}
                                style={{ fontSize: "25px", cursor: "pointer" }}
                                onClick={() => {
                                  if (paginationGridState.currentPage === 1) {
                                    return;
                                  }
                                  handleChangePage("FIRST");
                                }}
                              ></TPIcon>
                            </span>
                          </div>
                          <div>
                            <span
                              className="page-button"
                              style={
                                paginationGridState.currentPage === 1
                                  ? { color: "gray" }
                                  : { color: "#a00095" }
                              }
                            >
                              <TPIcon
                                onClick={() => {
                                  if (paginationGridState.currentPage === 1) {
                                    return;
                                  }
                                  handleChangePage("PREVIOUS");
                                }}
                                iconType={TPIconTypes.chevronLeft}
                                style={{ fontSize: "25px", cursor: "pointer" }}
                              />
                            </span>
                          </div>
                          <div>
                            <span
                              className="page-button"
                              style={
                                paginationGridState.currentPage < calcMaxPages()
                                  ? { color: "#a00095" }
                                  : { color: "gray" }
                              }
                            >
                              <TPIcon
                                onClick={() => {
                                  if (
                                    paginationGridState.currentPage ===
                                    calcMaxPages()
                                  ) {
                                    return;
                                  }
                                  handleChangePage("NEXT");
                                }}
                                iconType={TPIconTypes.chevronRight}
                                style={{ fontSize: "25px", cursor: "pointer" }}
                              />
                            </span>
                          </div>
                          <div>
                            <span
                              className="page-button"
                              style={
                                paginationGridState.currentPage < calcMaxPages()
                                  ? { color: "#a00095" }
                                  : { color: "gray" }
                              }
                            >
                              <TPIcon
                                iconType={TPIconTypes.lastPage}
                                style={{ fontSize: "25px", cursor: "pointer" }}
                                onClick={() => {
                                  if (
                                    paginationGridState.currentPage ===
                                    calcMaxPages()
                                  ) {
                                    return;
                                  }
                                  handleChangePage("LAST");
                                }}
                              ></TPIcon>
                            </span>
                          </div>
                        </div>
                      )} */}
                      <DynamicTable
                        id={"Table_" + resourceSet}
                        selectable={true}
                        selectedValues={transformedData.filter((d) =>
                          itemsReportSelected.includes(d.caseId),
                        )}
                        onSelectionChange={handleSelectItem}
                        hideAllTableOnNoData
                        data={transformedData}
                        withPreferences={false}
                        columnStyles={columnStyles}
                        columnNames={customerTypeColumns}
                        hiddenColumns={[
                          "caseResponsible",
                          "caseStatusId",
                          "customerTypeId",
                          "promiseDate",
                          "promiseDateFormatted",
                          "customStateLocalizedDescription",
                          "baseLevelId",
                          "hierarchyDescriptionClassify2",
                          "hierarchyDescriptionClassify3",
                          "hierarchyDescriptionClassify4",
                          "hierarchyDescriptionClassify5",
                          "closeDate",
                          "closeDateFormatted",
                          "lastModifiedDate",
                          "totalRecords",
                          "hasProfileRestriction",
                          "canViewPrivate",
                          "interestCase",
                          "caseCreator",
                          "hierarchyDescriptionClassify1",
                        ]}
                        onIconClicked={(event) => handleIconClick(event)}
                      />
                    </div>
                  </div>
                </TPWizardContent>
              </TPWizard>
              {/* setting number per page */}
              {modalSettingColumnsState.isShown && (
                <TPModal
                  modalState={{
                    titleModal: otherSettingsLabel,
                    acceptLabel: okButtonLabel,
                    cancelLabel: cancelButtonLabel,
                    callBackAnswer: handleCallbackAnserModal,
                    isShown: modalSettingColumnsState.isShown,
                  }}
                >
                  <TPPageSection>
                    <div className="form-group">
                      <TPSelect
                        id="IdSelect"
                        onChange={(e: any) => {
                          let newvalue: string;
                          newvalue = e.target.value;
                          let newModalSettingColumnsState: ModalSettingsColumnsType =
                            { ...modalSettingColumnsState };
                          newModalSettingColumnsState.localRowsPerPage =
                            parseInt(newvalue, 10);
                          setModalSettingColumnsState(
                            newModalSettingColumnsState,
                          );
                        }}
                        dataSource={rowsPerPageList}
                        value={modalSettingColumnsState.localRowsPerPage}
                        labelText={rowsPerPageLabel}
                      />
                    </div>
                  </TPPageSection>
                </TPModal>
              )}

              {modalActions && (
                <TPModal
                  modalState={{
                    titleModal: titleModalByAction(),
                    acceptLabel: saveButtonLabel,
                    cancelLabel: cancelButtonLabel,
                    callBackAnswer: handleCallbackModalActions,
                    isShown: modalActions,
                    modalWidth: actionApplySelected == 2 ? "modal-xl" : "",
                  }}
                >
                  {actionApplySelected && +actionApplySelected === 1 && (
                    <AddTaskForm
                      callBackSaveSuccess={() => {
                        setModalActions(false);
                        setItemsReportSelected([]);
                        setIsCheckedAll(false);
                      }}
                      CaseListToAddTask={itemsReportSelected}
                      ref={addTaskRef}
                    />
                  )}
                  {/*  reclasify */}
                  {actionApplySelected && +actionApplySelected === 2 && (
                    <ReclassifyMassive
                      callBackSaveSuccess={() => {
                        setModalActions(false);
                        setItemsReportSelected([]);
                        setIsCheckedAll(false);
                      }}
                      caseList={itemsReportSelected}
                      ref={reclassifykRef}
                    />
                  )}
                  {/** complete pending task */}
                  {actionApplySelected && +actionApplySelected === 3 && (
                    <CompletePendingTasks
                      taskType={
                        selectedAutocompleteTaskTypeOption[0]
                          ? selectedAutocompleteTaskTypeOption[0].value2
                          : ""
                      }
                      callBackSaveSuccess={() => {
                        setModalActions(false);
                        setItemsReportSelected([]);
                        setIsCheckedAll(false);
                      }}
                      caseList={itemsReportSelected}
                      ref={completePendingTaskRef}
                    />
                  )}
                  {actionApplySelected && +actionApplySelected === 4 && (
                    <div className="alert alert-info">
                      <TPIcon iconType={TPIconTypes.info}></TPIcon>{" "}
                      <p className="mb-0">
                        To Inactivate the cases requires that these be solved
                      </p>
                    </div>
                  )}
                  {modalSuccess && (
                    <TPModalSuccess
                      modalState={{
                        title: modalSuccessTitle,
                        yesLabel: okButtonLabel,
                        isShown: modalSuccess,
                        callBackFunction: () => {
                          setModalSuccess(false);
                          setModalActions(false);
                          setItemsReportSelected([]);
                          setResponseData([]);
                          setIsCheckedAll(false);
                        },
                      }}
                    >
                      <div>
                        <div>
                          {selectedRowsLabel} : {responseData.length}
                        </div>
                        <div>
                          {recordsProcessedLabel} :{" "}
                          {
                            responseData.filter((item) =>
                              Boolean(item.inactivated),
                            ).length
                          }
                        </div>
                        <div>
                          {recordsUnprocessedLabel} :{" "}
                          {
                            responseData.filter((item) =>
                              Boolean(!item.inactivated),
                            ).length
                          }
                        </div>
                      </div>
                    </TPModalSuccess>
                  )}
                  <TPModalQuestion
                    title={modalConfirmTitle}
                    yesLabel={okButtonLabel}
                    noLabel={cancelButtonLabel}
                    question={modalConfirmQuestion}
                    callBackData={modalQuestionChangeMassive.callBackData}
                    isShown={modalQuestionChangeMassive.isShown}
                    callBackAnswer={handleCallBackModalConfirmMassive}
                  ></TPModalQuestion>
                </TPModal>
              )}
            </div>
          </div>
          {modalErrorState.isShown && (
            <TPModalError
              modalState={{
                isShown: modalErrorState.isShown,
                title: modalErrorState.title,
                errorMessage: modalErrorState.errorMessage,
                yesLabel: okButtonLabel,
                callBackFunction: () => {
                  let newModalErrorState: TPModalErrorStateType = {
                    ...modalErrorState,
                  };
                  newModalErrorState.errorMessage = "";
                  newModalErrorState.isShown = false;
                  setModalErrorState(newModalErrorState);
                },
              }}
            />
          )}
        </TPLoadingOverlay>
      </div>
    </ContentVerticalNoTabsStyled>
  );
};

export default CaseMassUpdate;
