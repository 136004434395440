import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ReactElement } from "react";
import { StyledCaseCircleProps } from "./case-viewer-styles";

export type CaseStatusType = "solved" | "pending" | "expired" | "lag";

export enum CaseStatus {
    "solved" = "solved",
    "pending" = "pending",
    "expired" = "expired",
    "lag" = "lag",
}

export type CaseStatusResources = {
    solved: string;
    pending: string;
    expired: string;
    lag: string;
}

type StatusCircleProps = {
    type: CaseStatusType;
};

function StatusCircle({ type }: StatusCircleProps): ReactElement {
    const statusColor: CaseStatusResources = {
        solved: "#B1F2D7",
        pending: "#FFE4B3",
        expired: "#FFB3B3",
        lag: "#efffb3",
    };

    return (
        <StyledCaseCircleProps color={statusColor[type]}>
            <TPIcon iconType={type as TPIconTypes} />
        </StyledCaseCircleProps>
    );
}

export default StatusCircle;