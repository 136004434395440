import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPModalSearchCustomerState } from "@/layouts/TPModalSearchClient/TPModalSearchClient";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { ClientService } from "@/services/ClientService";
import { TPI18N } from "@/services/I18nService";
import { useEffect, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import { v4 as uuidv4 } from "uuid";
import {
  ColumnStyles,
  CustomColumnNames,
} from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { TPAvatar } from "@/components/TPAvatar/TPAvatar";

export enum searchCiteriaEnum {
  "none" = "none",
  "docnumber" = "docnumber",
  "internalcode" = "internalcode",
  "names" = "names",
  "email" = "email",
  "phone" = "phone",
  "additionaldata" = "additionaldata",
}

const initialStateBLL: AdminStateType = {
  filterIsLoaded: false,
  columnsAreLoaded: false,
  selectedFilter: TPActiveOptions.ALL.toString(),
  gridColumns: [],
  gridData: [],
  searchPattern: "",
};

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<any>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

enum commandsEnum {
  "set_griddata" = 0,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

type realSearchStateType = {
  realSearchCriteria: searchCiteriaEnum;
  realSearchValue: string;
  realAddtionaDataSearchId: string;
  flagSearch: boolean;
};

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}

const SearchCustomer = ({
  setCurrentComponent,
  setUserId,
  setSubsidaryId,
  setClientId,
  setIsEdit,
  setClientMode,
}: any) => {
  const resourceSet: string = "SearchCustomerComponent";
  const componentFileName: string = "SearchCustomer.tsx";

  const initialModalSearchState: TPModalSearchCustomerState = {
    isShown: false,
    initialSearchCriteria: searchCiteriaEnum.none,
    initialSearchValue: "",
    initialAddtionaDataSearchId: "",
  };

  const dummySearchState: realSearchStateType = {
    realSearchCriteria: searchCiteriaEnum.none,
    realSearchValue: "",
    realAddtionaDataSearchId: "",
    flagSearch: false,
  };

  const [realSearchState, setRealSearchState] =
    useState<realSearchStateType>(dummySearchState);

  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  // const [showTableData, setShowTableData] = useState(false);
  const [headingTitle, setHeadingTitle] = useState("");
  const [search, setSearch] = useState("");
  const [alreadyHaveCustomer, setAlreadyHaveCustomer] = useState(false);
  const [isAnonymousCustomer, setIsAnonymousCustomer] = useState(false);
  const [canCreateAnyCustomer, setCanCreateAnyCustomer] = useState(false);
  const [currentSearchCriteria, setCurrentSearchCriteria] =
    useState<searchCiteriaEnum>(searchCiteriaEnum.none);
  const [searchCriteriaList, setSearchCriteriaList] = useState<
    Array<TPKeyValue>
  >([]);
  const [searchCriteriaLabel, setSearchCriteriaLabel] = useState("");
  const [
    selectSearchCriteriaErrorMessage,
    setSelectSearchCriteriaErrorMessage,
  ] = useState("");
  const [currentSearchCustomerValue, setCurrentSearchCustomerValue] =
    useState("");
  const [searchCriteriaValueLabel, setSearchCriteriaValueLabel] = useState("");
  const [selectSearchValueErrorMessage, setSelectSearchValueErrorMessage] =
    useState("");
  const [searchCustomerLabel, setSearchCustomerLabel] = useState("");
  const [
    selectSearchCriteriaErrorMessageLabel,
    setSelectSearchCriteriaErrorMessageLabel,
  ] = useState("");
  const [
    selectSearchValueErrorMessageLabel,
    setSelectSearchValueErrorMessageLabel,
  ] = useState("");
  const [modalSearchCustomerState, setModalSearchCustomerState] =
    useState<TPModalSearchCustomerState>(initialModalSearchState);
  const [selectAdditionalDataLabel, setSelectAdditionalDataLabel] =
    useState("");
  const [titleLabel, setTitleLabel] = useState("");
  const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
  const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
  const [gridColumns, setGridColumns] = useState<Array<any>>([]);
  const [searchPattern, setSearchPattern] = useState("");
  const [showNoDataAlert, setShowNoDataAlert] = useState(false);

  //columns labels
  const [idLabel, setIdLabel] = useState("");
  const [documentTypeColumnLabel, setDocumentTypeColumnLabel] = useState("");
  const [documentNumberColumnLabel, setDocumentNumberColumnLabel] =
    useState("");
  const [namesColumnLabel, setNamesColumnLabel] = useState("");
  const [customerTypeColumnLabel, setCustomerTypeColumnLabel] = useState("");
  const [phoneColumnLabel, setPhoneColumnLabel] = useState("");
  const [emailColumnLabel, setEmailColumnLabel] = useState("");
  const [internalCodeColumnLabel, setInternalCodeColumnLabel] = useState("");

  //called to handle search customer
  const handleSearchOnClick = async () => {
    let bolExit: boolean = false;
    // clearCustomerData();
    //validate
    if (currentSearchCriteria === searchCiteriaEnum.none) {
      // setSelectSearchCriteriaErrorMessage(selectSearchCriteriaErrorMessageLabel);
      setSelectSearchCriteriaErrorMessage(
        await TPI18N.GetText(resourceSet, "SearchCriteriaErrorMessageLabel")
      );
      bolExit = true;
    }
    if (currentSearchCustomerValue.trim() === "") {
      // setSelectSearchValueErrorMessage(selectSearchValueErrorMessageLabel);
      setSelectSearchValueErrorMessage(
        await TPI18N.GetText(resourceSet, "SelectSearchValueErrorMessageLabel")
      );
      bolExit = true;
    }
    //todo add data
    if (bolExit) {
      return;
    }

    // //perform real search
    let newModalSearchCustomerState: TPModalSearchCustomerState = {
      isShown: true,
      initialSearchCriteria: currentSearchCriteria,
      initialSearchValue: currentSearchCustomerValue,
      initialAddtionaDataSearchId: "", //todo
    };
    setModalSearchCustomerState(newModalSearchCustomerState);
    await realSearch();
  };

  const handleRefreshClick = () => {
    handleSearchOnClick();
  };

  const handleIconClick = (event: TPIconTypes) => {
    if (event === TPIconTypes.loop) handleRefreshClick();
  };

  const realSearch = async () => {
    // setSearchPattern("");
    //todo include additional data value for search
    let serviceClient = new ClientService();
    let expectedCodes: Array<number> = [200, 404];
    let i: number;
    let responseRequest: any;
    try {
      setIsLoadingScreen(true);
      switch (modalSearchCustomerState.initialSearchCriteria) {
        case searchCiteriaEnum.email:
          responseRequest = await serviceClient.getClientByEmailBasic(
            modalSearchCustomerState.initialSearchValue,
            false,
            true,
            expectedCodes
          );
          break;
        case searchCiteriaEnum.docnumber:
          responseRequest = await serviceClient.getClientByDocumentBasic(
            modalSearchCustomerState.initialSearchValue,
            false,
            true,
            expectedCodes
          );
          break;
        case searchCiteriaEnum.phone:
          responseRequest = await serviceClient.getClientByPhoneBasic(
            modalSearchCustomerState.initialSearchValue,
            false,
            true,
            expectedCodes
          );
          break;
        case searchCiteriaEnum.names:
          responseRequest = await serviceClient.getClientByNamesBasic(
            modalSearchCustomerState.initialSearchValue,
            false,
            true,
            expectedCodes
          );
          break;
        case searchCiteriaEnum.internalcode:
          responseRequest = await serviceClient.getClientByInternalCodeBasic(
            modalSearchCustomerState.initialSearchValue,
            false,
            true,
            expectedCodes
          );
          break;
        case searchCiteriaEnum.additionaldata:
          //todo
          break;
      }
      if (responseRequest && responseRequest.length == 0) {
        let command1: commandType = {
          type: commandsEnum.set_griddata,
          payload: { newGridData: [] },
        };
        dispatchCommand(command1);
        setShowNoDataAlert(true);
      } else {
        if (!responseRequest) {
          setShowNoDataAlert(true);
          let command1: commandType = {
            type: commandsEnum.set_griddata,
            payload: { newGridData: [] },
          };
          dispatchCommand(command1);
        } else {
          //alter responseRequest to include selected row
          for (i = 0; i <= responseRequest.length - 1; i++) {
            if (responseRequest.length === 1) {
              responseRequest[i].tpselectedRow = true;
              responseRequest[i].tpshowbutton = true;
            } else {
              responseRequest[i].tpselectedRow = false;
              responseRequest[i].tpshowbutton = false;
            }
            responseRequest[i].tpguid =
              "tprowguid" + uuidv4().replaceAll("-", "");
          }

          let command11: commandType = {
            type: commandsEnum.reload_grid,
            payload: responseRequest,
          };
          dispatchCommand(command11);
        }
      }

      // setupGridColumns();
      setIsLoadingScreen(false);
      let newRealSearchState: realSearchStateType = { ...realSearchState };
      newRealSearchState.flagSearch = false;
      setRealSearchState(newRealSearchState);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} searchCustomerModal ex`,
        TPLogType.ERROR,
        error
      );

      setIsLoadingScreen(false);
      let newRealSearchState: realSearchStateType = { ...realSearchState };
      newRealSearchState.flagSearch = false;
      setRealSearchState(newRealSearchState);
      let command1: commandType = {
        type: commandsEnum.set_griddata,
        payload: { newGridData: [] },
      };
      dispatchCommand(command1);
      setShowNoDataAlert(true);
    }
  };

  const handleClickId = (id: any) => {
    setCurrentComponent("SearchResult");
    if (id) {
      setUserId(id);
    }
  };

  const handleSubsidaryId = (id: any) => {
    id && setSubsidaryId(id);
  };

  const handleClientId = (id: any) => {
    id && setClientId(id);
  };

  const handleNewCustomerClick = () => {
    setCurrentComponent("SearchResult");
    setClientMode("Insert");
    setIsEdit(true);
  };

  //called when change customer serch criteria
  const handleCustomerSearchCriteriaChange = (e: any) => {
    let newValue: string = e.target.value;
    setCurrentSearchCriteria(newValue as searchCiteriaEnum);
    setSelectSearchCriteriaErrorMessage("");
    setModalSearchCustomerState((prev: any) => ({
      ...prev,
      initialSearchCriteria: newValue,
    }));
    // clearCustomerData();
  };
  //called when change customer serch criteria
  const handleChangeCustormerSearchValue = (e: any) => {
    let newValue: string = e.target.value;
    setCurrentSearchCustomerValue(newValue);
    setSelectSearchValueErrorMessage("");
    setModalSearchCustomerState((prev: any) => ({
      ...prev,
      initialSearchValue: newValue,
    }));
  };

  const loadResourcesAndOrganizations = async () => {
    setThereAreNoRecordsToShow(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "DataTableNoCurrentData")
    );
    setSearchCriteriaLabel(
      await TPI18N.GetText(resourceSet, "SearchCriteriaLabel")
    );
    setSearchCriteriaValueLabel(
      await TPI18N.GetText(resourceSet, "SearchCriteriaValueLabel")
    );
    setSelectAdditionalDataLabel(
      await TPI18N.GetText(resourceSet, "SelectAdditionalDataLabel")
    );
    setHeadingTitle(await TPI18N.GetText(resourceSet, "HeadingDataLabel"));
    setSearch(await TPI18N.GetText(resourceSet, "SearchLabel"));
    setSearchCriteriaList([
      {
        key: searchCiteriaEnum.none,
        value: "--",
      },
      {
        key: searchCiteriaEnum.docnumber,
        value: await TPI18N.GetText(resourceSet, "SearchDocumentNumberLabel"),
      },
      {
        key: searchCiteriaEnum.internalcode,
        value: await TPI18N.GetText(resourceSet, "SearchInternalCodeLabel"),
      },
      {
        key: searchCiteriaEnum.names,
        value: await TPI18N.GetText(resourceSet, "SearchNameOrLastNameLabel"),
      },
      {
        key: searchCiteriaEnum.email,
        value: await TPI18N.GetText(resourceSet, "SearchEmailLabel"),
      },
      {
        key: searchCiteriaEnum.phone,
        value: await TPI18N.GetText(resourceSet, "SearchPhoneLabel"),
      }, //todo enable
      // {
      //   key: searchCiteriaEnum.additionaldata,
      //   value: await TPI18N.GetText(resourceSet, "SearchAdditionalDataLabel"),
      // },
    ]);

    setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
    setSelectSearchCriteriaErrorMessageLabel(
      await TPI18N.GetText(resourceSet, "SelectSearchCriteriaErrorMessageLabel")
    );
    setSelectSearchValueErrorMessageLabel(
      await TPI18N.GetText(resourceSet, "SelectSearchValueErrorMessageLabel")
    );
    // setAnonymousCustomerLabel(await TPI18N.GetText(resourceSet, "AnonymousCustomerLabel"));
    setSearchCustomerLabel(
      await TPI18N.GetText(resourceSet, "SearchCustomerLabel")
    );

    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setCustomerTypeColumnLabel(
      await TPI18N.GetText(resourceSet, "CustomerTypeColumnLabel")
    );
    setDocumentTypeColumnLabel(
      await TPI18N.GetText(resourceSet, "DocumentTypeColumnLabel")
    );
    setDocumentNumberColumnLabel(
      await TPI18N.GetText(resourceSet, "DocumentNumberColumnLabel")
    );
    setNamesColumnLabel(await TPI18N.GetText(resourceSet, "NamesColumnLabel"));
    setPhoneColumnLabel(await TPI18N.GetText(resourceSet, "PhoneColumnLabel"));
    setEmailColumnLabel(await TPI18N.GetText(resourceSet, "EmailColumnLabel"));
    setInternalCodeColumnLabel(
      await TPI18N.GetText(resourceSet, "InternalCodeColumnLabel")
    );
  };

  const setupGridColumns = (prevState: AdminStateType) => {
    let newState: AdminStateType = { ...prevState };
    newState.gridColumns = [prevState.gridData];
    return newState;
  };

  // Define the columns, styles, and minor options for the DynamicTable
  const customerTypeColumns: CustomColumnNames<any> = {
    thumbnail: "",
    customerTypeDescription: customerTypeColumnLabel,
    documentTypeDescription: documentTypeColumnLabel,
    name: namesColumnLabel,
    clientEmail: emailColumnLabel,
    clientPhone: phoneColumnLabel,
    internalCode: internalCodeColumnLabel,
  };

  const customColumns: ColumnStyles<any> = {
    id: ({ value, item }) => (
      <TablePrimaryItem
        value={value}
        isDisabled={item.isSystem}
        onClick={() => {
          handleClickId(item.subsidiaryOrganizationId); // Call the handleChange function
          handleSubsidaryId(item.subsidiaryId);
          handleClientId(item.id);
        }}
      />
    ),
    thumbnail: ({ value, item }) => (
      <TPAvatar
        src={item.thumbnail}
        size={30}
        circular={true}
        enableFullscreen={true}
      />
    ),
  };

  const [adminState, dispatchCommand] = useReducer(doCommand, initialStateBLL);

  function doCommand(prevState: AdminStateType, command: commandType) {
    switch (command.type) {
      case commandsEnum.set_filterIsLoaded:
        let newStateFilter: AdminStateType;
        newStateFilter = { ...prevState };
        newStateFilter.filterIsLoaded = true;
        return newStateFilter;
      case commandsEnum.setup_grid_columns:
        let newStateColumns: AdminStateType = setupGridColumns(prevState);
        newStateColumns.columnsAreLoaded = true;
        return newStateColumns;
      case commandsEnum.reload_grid:
        let newStateGrid: AdminStateType;
        newStateGrid = { ...prevState };
        newStateGrid.gridData = command.payload;
        return newStateGrid;
      case commandsEnum.change_selectedFilter:
        let newStateChangeFilter: AdminStateType;
        newStateChangeFilter = { ...prevState };
        newStateChangeFilter.selectedFilter = command.payload;
        return newStateChangeFilter;
      case commandsEnum.change_search_pattern:
        let newStatePattern: AdminStateType;
        newStatePattern = { ...prevState };
        newStatePattern.searchPattern = command.payload;
        return newStatePattern;
      default:
        return prevState;
    }
  }

  const transformedData = adminState.gridData.map((item) => ({
    ...item,
    clientPhone: item.clientPhone ? item.clientPhone : "",
    name: `${item.name} ${item.lastName}`,
    internalCode: item.internalCode ? item.internalCode : "",
  }));

  //Run only once when page is loaded
  useEffect(() => {
    setIsLoadingScreen(true);
    loadResourcesAndOrganizations()
      .then(function () {
        //set filter is loaded
        let command1: commandType = {
          type: commandsEnum.set_filterIsLoaded,
          payload: null,
        };
        dispatchCommand(command1);
      })
      .catch(function (error) {
        TPLog.Log(
          `Error ${componentFileName} loadResourcesAndOrganizationsRelationsFilter ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} loadResourcesAndOrganizationsRelationsFilter ex`
        );
      });
  }, []);

  //run it when flag search is changed to true
  useEffect(() => {
    if (adminState.filterIsLoaded) {
      let command1: commandType = {
        type: commandsEnum.setup_grid_columns,
        payload: null,
      };
      dispatchCommand(command1);
      setIsLoadingScreen(false);
    }
  }, [adminState.filterIsLoaded]);

  useEffect(() => {}, [searchCriteriaLabel]);

  return (
    <>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div
          className="container"
          style={{ display: alreadyHaveCustomer ? "none" : "block" }}
        >
          {!isAnonymousCustomer && (
            <div className="row mt-2 d-flex align-items-center justify-content-between">
              <div className="col-7">
                <TPPageTitle style={{ fontSize: "24px" }}>
                  {headingTitle}
                </TPPageTitle>
              </div>
              <div className="col-5"></div>
            </div>
          )}

          {currentSearchCriteria !== searchCiteriaEnum.additionaldata &&
            searchCriteriaList.length > 0 && (
              <>
                <div className="row mt-2">
                  <div className="col-4">
                    <div>
                      <TPSelect
                        id="IdSelect"
                        isMandatory={true}
                        labelText={searchCriteriaLabel}
                        onChange={handleCustomerSearchCriteriaChange}
                        dataSource={searchCriteriaList}
                        value={currentSearchCriteria}
                        disabled={isAnonymousCustomer}
                        errorMessage={selectSearchCriteriaErrorMessage}
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div>
                      <TPTextBox
                        id="IdTextBox"
                        onChange={(e: any) =>
                          handleChangeCustormerSearchValue(e)
                        }
                        value={currentSearchCustomerValue}
                        labelText={searchCriteriaValueLabel}
                        isMandatory={true}
                        disabled={isAnonymousCustomer}
                        errorMessage={selectSearchValueErrorMessage}
                      />
                    </div>
                  </div>

                  <div className="col-4" style={{ marginTop: "28px" }}>
                    <TPButton
                      text=""
                      children={<span>{search}</span>}
                      onClick={handleSearchOnClick}
                      type={TPButtonTypes.primary}
                    />
                  </div>
                </div>
              </>
            )}

          {true && (
            <div className="row mt-3">
              <div className="col">
                <DynamicTable
                  data={transformedData}
                  columnNames={customerTypeColumns}
                  columnStyles={customColumns}
                  hiddenColumns={[
                    "customerTypeId",
                    "documentType",
                    "geography",
                    "isActive",
                    "lastName",
                    "subsidiaryId",
                    "subsidiaryName",
                    "subsidiaryOrganizationId",
                    "tpguid",
                    "tpselectedRow",
                    "tpshowbutton",
                  ]}
                  noDataMessage={`${thereAreNoRecordsToShow}.`}
                  disableMinorOption={(item) => item.isSystem}
                  exportNameFile={"Customer Search data"}
                  hiddenSearch={!adminState.gridData.length}
                  onIconClicked={(event) => handleIconClick(event)}
                />
              </div>
            </div>
          )}
        </div>
      </TPLoadingOverlay>
    </>
  );
};

export default SearchCustomer;
