import { TPKeyValue } from "@/helpers/TPKeyValue";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CaseToolbarSliceModel {
  contentLoaded: boolean;
  availableProfiles: TPKeyValue[];
}

const initialState: CaseToolbarSliceModel = {
  contentLoaded: true,
  availableProfiles: []
}

export const CaseToolbarSlice = createSlice({
  initialState: initialState,
  name: "CaseToolbarSlice",
  reducers: {
    setContentLoaded: (state, action: PayloadAction<boolean>) => {
      state.contentLoaded = action.payload;
    },
    setAvailableProfiles: (state, action: PayloadAction<TPKeyValue[]>) => {
      state.availableProfiles = action.payload;
    },
  }
})
