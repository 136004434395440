import { ReactElement, useId } from "react";
import { TPSwitchContainer, TPSwitchLabel, TPSwitchSlider, TPSwitchStyled } from "./tpSwitchStyles";

interface TPSwitchInterface {
    onChange?: () => void;
    className?: string;
    checked: boolean;
    labelText?: string | JSX.Element;
    disabled?: boolean;
    labelStyle?: any;
    switchStyle?: any;
    id?: string;
}

const TPSwitch = ({
    onChange,
    className = "",
    checked,
    labelText,
    disabled,
    labelStyle,
    switchStyle,
    id
}: TPSwitchInterface): ReactElement => {
    const theId = `switch-${useId()}`;

    return (
        <TPSwitchContainer className={`form-switch ${className}`}>
            <label htmlFor={id ? id : theId} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                <TPSwitchStyled
                    checked={checked}
                    onChange={onChange}
                    type="checkbox"
                    id={id ? id : theId}
                    disabled={disabled}
                    style={switchStyle}
                />
                <TPSwitchSlider checked={checked} />
            </label>
            {labelText && (
                <TPSwitchLabel
                    htmlFor={id ? id : theId}
                    style={labelStyle}
                >
                    {labelText}
                </TPSwitchLabel>
            )}
        </TPSwitchContainer>
    );
};

export default TPSwitch;
