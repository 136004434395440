import React, { createContext, useContext, useState, useEffect } from "react";

interface MenuReloadContextType {
  reloadMenu: boolean;
  triggerMenuReload: () => void;
}

const MenuReloadContext = createContext<MenuReloadContextType | undefined>(
  undefined
);

export const MenuReloadProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [reloadMenu, setReloadMenu] = useState<boolean>(false);

  const triggerMenuReload = () => {
    setReloadMenu((prev) => !prev);
  };

  return (
    <MenuReloadContext.Provider value={{ reloadMenu, triggerMenuReload }}>
      {children}
    </MenuReloadContext.Provider>
  );
};

export const useMenuReload = () => {
  const context = useContext(MenuReloadContext);
  if (!context) {
    throw new Error("useMenuReload must be used within a MenuReloadProvider");
  }
  return context;
};
