import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import {
  HolidayInputDTO,
  HolidayInputDTOValidator,
} from "@/models/Calendars/Holidays/HolidayInputDTO";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { HolidayService } from "@/services/HolidayService";
import { TPI18N } from "@/services/I18nService";
import {FC, useEffect, useRef, useState} from "react";

import "react-datepicker/dist/react-datepicker.css";
import {MultilingualTextBoxEvents} from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";

type InsertUpdateProps = {
  recordId: string;
  callBackResult: Function;
};

type InsertUpdateStateType = {
  calendarId: string;
  idHoliday: string;
  recordLanguageList: Array<TPKeyValue>;
  //TODO change date
  holidayDate: Date;
  isHalfHoliday: boolean;
  //validator
  calendarIdErrorMessage: string;
  holidayDateErrorMessage: string;
  descriptionErrorMessages: Array<string>;
};

const HolidaysInsertUpdate: FC<InsertUpdateProps> = ({
  recordId,
  callBackResult,
}) => {
  const componentFileName: string = "HolidaysInsertUpdate.tsx";

  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //Screen resources
  const resourceSet: string = "HolidayInsertUpdateComponent";
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [holidayDateLabel, setHolidayDateLabel] = useState("");
  const [isHalfHolidayLabel, setIsHalfHolidayLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");
  const nameInputRef = useRef<MultilingualTextBoxEvents>();
  //Screen state
  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }
  let insertUpdateInitialState: InsertUpdateStateType = {
    calendarId: recordId,
    idHoliday: "-1",
    recordLanguageList: [],
    holidayDate: new Date(),
    isHalfHoliday: false,
    holidayDateErrorMessage: "",
    descriptionErrorMessages: initialErrorMessages,
    calendarIdErrorMessage: "",
  };
  const [insertUpdateState, setInsertUpdateState] = useState(
    insertUpdateInitialState,
  );

  //#endregion

  const loadResources = async () => {
    let i: number;
    //resources state
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel",
      ),
    );
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );

    setDescriptionLabel(await TPI18N.GetText(resourceSet, "DescriptionLabel"));
    setIsHalfHolidayLabel(
      await TPI18N.GetText(resourceSet, "IsHalfHolidayLabel"),
    );
    setHolidayDateLabel(await TPI18N.GetText(resourceSet, "HolidayDateLabel"));

    //screen state

    let newInsertUpdateState = { ...insertUpdateState };
    for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
      let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
      let keyValueElement: TPKeyValue = { key: item.id, value: "" };
      newInsertUpdateState.recordLanguageList.push(keyValueElement);
    }
    setInsertUpdateState(newInsertUpdateState);
    setIsLoadingScreen(false);
  };

  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.descriptionErrorMessages[index] = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleHolidayDateChange = (newHolidayDate: Date) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.holidayDate = newHolidayDate;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleIsHalfHolidayChange = () => {
    setInsertUpdateState({
      ...insertUpdateState,
      isHalfHoliday: !insertUpdateState.isHalfHoliday,
    });
  };

  const handleOkButtonClick = async () => {
    if (nameInputRef.current?.isInvalid()) {
      nameInputRef.current?.markAsTouched();
      return;
    }

    const languages = nameInputRef.current?.getValue();
    const mainLanguage = languages?.find(({id}) => id === TPGlobal.TPClientDefaultLanguage);
    let i: number;
    let n: number;
    let recordInputDTO: HolidayInputDTO = {
      calendarId: insertUpdateState.calendarId,
      holiday: insertUpdateState.holidayDate,
      isHalfHoliday: insertUpdateState.isHalfHoliday,
      description: mainLanguage?.value!,
      otherLocalizedValues: [{
        languageId: mainLanguage?.id!,
        localizedValue: mainLanguage?.value ?? "",
        order: 1
      },
        ...languages!
            .filter(({id}) => id !== TPGlobal.TPClientDefaultLanguage)
            .map(({id, value}, order) => ({languageId: id!, localizedValue: value ?? "", order: order + 2}))
      ],
    };

    let inputDTOValidator = new HolidayInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      if (resultValidator.calendarId) {
        newInsertUpdateState.calendarIdErrorMessage = await TPI18N.GetResource(
          resultValidator.calendarId,
        );
      } else {
        newInsertUpdateState.calendarIdErrorMessage = "";
      }

      setInsertUpdateState(newInsertUpdateState);
      return;
    }

    await insertHoliday(recordInputDTO);
  };

  const insertHoliday = async (inputDTO: HolidayInputDTO) => {
    let serviceClient = new HolidayService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertHoliday(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertHoliday ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} insertHoliday ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResources();
  }, []);

  return (
    //#region  Render

    <TPLoadingOverlay active={isLoadingScreen}>
      <div className="row">
        <div className="col-11">
          <TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
        </div>
      </div>

      <div className="row">
        <div className="col-11">
          <TPPageSection>
              <MultilingualTextBox
                  ref={nameInputRef}
                  style={{ width: "calc(50% + 35px)" }}
                  value={insertUpdateState.recordLanguageList?.map(({key, value}) => ({id: key, value}))}
              />
          </TPPageSection>
        </div>
      </div>

      <div className="row">
        <div className="col-11">
          <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
        </div>
      </div>
      <div className="row">
        <div className="col-11">
          <TPPageSection>
            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  <TPDatePicker
                    selectedDate={insertUpdateState.holidayDate}
                    onChangeDate={(e: any) => handleHolidayDateChange(e)}
                    labelText={holidayDateLabel}
                    isHorizontal={false}
                    isMandatory={true}
                  ></TPDatePicker>
                  {/* <TPTextBox
                labelText={holidayDateLabel}
                onChange={(e: any) => handleHolidayDateChange(e.target.value)}
                value={insertUpdateState.holidayDate}
              ></TPTextBox> */}
                </div>
              </div>
              <div className="col-8 mt-4">
                <div className="form-group">
                  <TPCheckBox
                    id="IdCheckBox"
                    labelText={isHalfHolidayLabel}
                    checked={insertUpdateState.isHalfHoliday}
                    onChange={(e: any) => handleIsHalfHolidayChange()}
                  ></TPCheckBox>
                </div>
              </div>
            </div>
          </TPPageSection>
        </div>
      </div>
      <div className="row"></div>
      <div className="row">
        <div className="col-11">
          <TPPageAcceptCancelButtonsContainer>
            <TPButton
              id="IdButton"
              type={TPButtonTypes.primary}
              onClick={handleOkButtonClick}
            >
              {saveButtonLabel}
            </TPButton>
            <TPButton
              id="IdButton"
              type={TPButtonTypes.link}
              onClick={handleCancelButtonClick}
              className={"ms-2"}
            >
              {cancelButtonLabel}
            </TPButton>
          </TPPageAcceptCancelButtonsContainer>
        </div>
      </div>
    </TPLoadingOverlay>

    //#endregion
  );
};

export default HolidaysInsertUpdate;
