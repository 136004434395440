import React, { useEffect, useState } from "react";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import {
    ButtonStyle,
    ContainerSliderStyle,
    ContainerViewDetail,
    ImageIcoStyle,
    ImageSliderStyle,
    ItemSliderStyle,
    TitleImageSlider,
} from "./StyleImageAdmin";
import styled from "styled-components";
import { OperationMode } from "./useStatusImagesAdmin";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";
import { TPIconTypes } from "../../models/Global/TPGlobalEnums";
import TPButton from "../../components/bootstrap/components/buttons/TPButton";
import { TPI18N } from "../../services/I18nService";
import TPIcon from "../../components/bootstrap/extend/TPIcons/TPIcon";

type ContainerFolderProps = {
    files: RecursiveRoutes[];
    mode?: string
    btnSelectLabel: string
};


const ImagesAdminSlider: React.FC<ContainerFolderProps> = ({ files, mode, btnSelectLabel }: ContainerFolderProps) => {
    const resourceSet: string = "ImagesAdmin.tsx";
    const [currentIndex, setCurrentIndex] = useState(0);

    const value = useAppConctextImageAdmin();
    const { handleSelected, selectedItem, selectedFolderParent, modeSelect } = value;

    const handleCancel = () => {
        setCurrentIndex(0);
        handleSelected(selectedFolderParent, OperationMode.ActiveFolder);
    };

    if (selectedItem && selectedItem.imageUrl) {
        return (
            <ContainerSliderStyle onClick={handleCancel} mode={mode}>
                <ItemSliderStyle onClick={(e) => e.stopPropagation()}>
                    {files.length > 0 ? (
                        <ImageSliderStyle
                            src={selectedItem.imageUrl}
                            alt={
                                selectedItem.fileName != undefined
                                    ? selectedItem.fileName
                                    : ""
                            }
                        />
                    ) : null}

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "-webkit-fill-available",
                            padding: "5px"
                        }}
                    >
                        <TPIcon iconType={TPIconTypes.fullScreenExit}
                            onClick={handleCancel}
                            style={mode != "view" ? {
                                backgroundColor: "transparent",
                                border: "none",
                                margin: "0px",
                                padding: "0px",
                                position: "fixed",
                                top: "150px",
                                right: "100px",
                                color: "white",
                                fontSize: "30px",
                                cursor: "pointer"

                            } :

                                {
                                    backgroundColor: "transparent",
                                    border: "none",
                                    margin: "0px",
                                    padding: "0px",
                                    position: "fixed",
                                    top: "180px",
                                    right: "22%",
                                    color: "white",
                                    fontSize: "30px",
                                    cursor: "pointer"
                                }} />

                    </div>

                </ItemSliderStyle>
            </ContainerSliderStyle>
        );
    } else {
        return null;
    }
};

export default ImagesAdminSlider;
