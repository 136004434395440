import { TPKeyValue } from "@/helpers/TPKeyValue";
import {
  MenuDefinitionListResponseModel,
  MenuDefinitionModel,
  MenuHierarchy,
} from "@/models/MenuItems/MenuItemsModels";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const sortByOrder = function(menus: MenuDefinitionListResponseModel[]) {
  return menus.sort((mA, mB) => {
    if (mA.order < mB.order) return -1;
    if (mA.order > mB.order) return 1;
    return 0;
  })
}

export interface MenuDefinitionSliceModel {
  contentLoaded: boolean;
  availableProfiles: Array<TPKeyValue>;
  menus: Array<MenuDefinitionListResponseModel>;
  hierarchy: MenuHierarchy[];
}

const initialState: MenuDefinitionSliceModel = {
  contentLoaded: true,
  availableProfiles: [],
  menus: [],
  hierarchy: [],
};


export const MenuDefinitionSlice = createSlice({
  initialState: initialState,
  name: "MenuDefinitionSlice",
  reducers: {
    setContentLoaded: (state, action: PayloadAction<boolean>) => {
      state.contentLoaded = action.payload;
    },
    setAvailableProfiles: (state, action: PayloadAction<Array<TPKeyValue>>) => {
      state.availableProfiles = action.payload;
    },
    setMenus: (
      state,
      action: PayloadAction<Array<MenuDefinitionListResponseModel>>
    ) => {
      state.menus = action.payload;
    },
    calculateHierarchy: (state, action: PayloadAction<Array<MenuDefinitionListResponseModel>>) => {
      const menus = action.payload;
      if (!menus || menus.length == 0) return;
      const sortedMenus: MenuDefinitionListResponseModel[] = [...sortByOrder(menus)];
      const fixedMenusList: MenuDefinitionListResponseModel[] = [];

      let o = 1;

      sortedMenus.filter(m => m.section).map(s => {
        s.order = o;
        o += 1;
        fixedMenusList.push(s);
        sortedMenus.filter(m => m.group && m.parentId === s.id).map(g => {
          g.order = o;
          o += 1;
          fixedMenusList.push(g);
          sortedMenus.filter(m => m.item && m.parentId === g.id).map(i => {
            i.order = o;
            o += 1;
            fixedMenusList.push(i);
          })
        })
      })

      state.menus = fixedMenusList;
    }
  },
});
