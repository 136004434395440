import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable, {
    CustomColumnNames
} from "@/modules/core/components/dynamic-table/DynamicTable";
import UserProfile from "@/modules/core/design-system/user-data/UserProfile";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { ColumnStyles } from "@/pages/Projects/ProjectDetail/ProjectDetailDynamicTable";
import { ActiveTeamMembersService } from "@/services/ActiveTeamMembersService";
import { EventProjectService } from "@/services/EventProjectService";
import { GroupsService } from "@/services/GroupsService";
import { TPI18N } from "@/services/I18nService";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { CockpitView, InfoCardLabels, InfoCardType, InfoCardTypes } from "../supervisor-cockpit.model";
import CockpitInfoCard from "../utils/CockpitInfoCard";
import ReassignModal from "./ConfirmationModal";

export interface TeamActivityTickets {
    id: number;
    ticket: "case" | "event" | "chat" | "mail";
    status: "PE" | "PR" | "EX" | "CL" | "IP" | "HI" | "AL" | "OH" | "RE" | "SO" | "RC";
    statusDescription: string;
    creationDate: string;
    customer: string;
    assignTo: string;
    idAssignTo: number;
    idGroup?: number;
}

type props = {
    view: CockpitView;
    changeTitle?: (title: string) => void;
    loadingCallback?: (status: boolean) => void;
}

const ActiveTeamMembersTickets: FC<props> = ({
    view,
    changeTitle,
    loadingCallback
}) => {

    const componentFileName = "ActiveTeamMembersTickets.tsx";

    const resourceSet = "ActiveTeamMembersTickets";

    const parent = "CockpitDashboard";

    const casecontext: any = React.useContext(TPCaseViewerContext);

    const [myTeamLabel, setMyTeamLabel] = useState<string>("")
    const [reassignCasesLabel, setReassignCasesLabel] = useState<string>("");
    const [selectGroupLabel, setSelectGroupLabel] = useState<string>("");
    const [projectLabel, setProjectLabel] = useState<string>("");
    const [queueLabel, setQueueLabel] = useState<string>("");
    const [searchLabel, setSearchLabel] = useState<string>("");

    const [selectStartDate, setSelectStartDate] = useState<string>("");
    const [selectEndtDate, setSelectEndDate] = useState<string>("");

    const [ticketLabel, setTicketLabel] = useState<string>("");
    const [statusLabel, setStatusLabel] = useState<string>("");
    const [creationDateLabel, setCreationDateLabel] = useState<string>("");
    const [customerLabel, setCustomerLabel] = useState<string>("");
    const [assignToLabel, setAssignToLabel] = useState<string>("");

    const [casesLabel, setCasesLabel] = useState<string>("");
    const [eventsLabel, setEventsLabel] = useState<string>("");
    const [emailsLabel, setEmailsLabel] = useState<string>("");
    const [chatsLabel, setChatsLabel] = useState<string>("");

    const [disabled, setDisabled] = useState<boolean>(false);

    const [selectedRows, setSelectedRows] = useState<Array<TeamActivityTickets>>([]);

    const [openModal, setOpenModal] = useState<boolean>(false);

    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());

    const [infoCardLabels, setInfoCardLabels] = useState<InfoCardLabels>({} as InfoCardLabels);

    const [nothingToseeYetLabel, setNothingToseeYetLabel] = useState<string>("");
    const [stillLabel, setStillLabel] = useState<string>("");
    const [noUsersConfiguredLabel, setNoUsersConfiguredLabel] = useState<string>("");
    const [noSelectedGroupLabel, setNoSelectedGroupLabel] = useState<string>("");
    const [noSelectedProjectLabel, setNoSelectedProjectLabel] = useState<string>("");

    const loadResources = async () => {
        setReassignCasesLabel(await TPI18N.GetText(resourceSet, "reassignCasesLabel"));
        setSelectGroupLabel(await TPI18N.GetText(resourceSet, "selectGroupLabel"));
        setProjectLabel(await TPI18N.GetText(resourceSet, "projectLabel"));
        setQueueLabel(await TPI18N.GetText(resourceSet, "queueLabel"));
        setSearchLabel(await TPI18N.GetText(resourceSet, "searchLabel"));
        setMyTeamLabel(await TPI18N.GetText(resourceSet, "myTeamLabel"));
        setSelectStartDate(await TPI18N.GetText(resourceSet, "startDateLabel"));
        setSelectEndDate(await TPI18N.GetText(resourceSet, "endDateLabel"));
        setTicketLabel(await TPI18N.GetText(resourceSet, "ticketLabel"));
        setStatusLabel(await TPI18N.GetText(resourceSet, "statusLabel"));
        setCreationDateLabel(await TPI18N.GetText(resourceSet, "creationDateLabel"));
        setCustomerLabel(await TPI18N.GetText(resourceSet, "customerLabel"));
        setAssignToLabel(await TPI18N.GetText(resourceSet, "assignToLabel"));
        setCasesLabel(await TPI18N.GetText(resourceSet, "casesLabel"));
        setEventsLabel(await TPI18N.GetText(resourceSet, "eventsLabel"));
        setEmailsLabel(await TPI18N.GetText(resourceSet, "emailsLabel"));
        setChatsLabel(await TPI18N.GetText(resourceSet, "chatsLabel"));
        setNothingToseeYetLabel(await TPI18N.GetText(resourceSet, "nothingToseeYetLabel"));
        setStillLabel(await TPI18N.GetText(resourceSet, "stillLabel"));
        setNoUsersConfiguredLabel(await TPI18N.GetText(resourceSet, "noUsersConfiguredLabel"));
        setNoSelectedGroupLabel(await TPI18N.GetText(resourceSet, "noSelectedGroupLabel"));
        setNoSelectedProjectLabel(await TPI18N.GetText(resourceSet, "noSelectedProjectLabel"));
        setInfoCardLabels({
            offline: await TPI18N.GetText(parent, "Offline"),
            working: await TPI18N.GetText(parent, "Working"),
            online: await TPI18N.GetText(parent, "Online"),
            ready: await TPI18N.GetText(parent, "Ready"),
            worked: await TPI18N.GetText(parent, "Worked"),
            case: await TPI18N.GetText(parent, "Case"),
            event: await TPI18N.GetText(parent, "Event"),
            chat: await TPI18N.GetText(parent, "Chat"),
            mail: await TPI18N.GetText(parent, "Mail"),
            expired: await TPI18N.GetText(parent, "Expired"),
            pending: await TPI18N.GetText(parent, "Pending"),
            inProcess: await TPI18N.GetText(parent, "InProgress"),
            closed: await TPI18N.GetText(parent, "Closed"),
            history: await TPI18N.GetText(parent, "History"),
            processing: await TPI18N.GetText(parent, "Processing"),
        })
    }

    const [groupData, setGroupData] = useState<Array<TPKeyValue>>([])
    const [projectData, setProjectData] = useState<Array<TPKeyValue>>([])
    const [queueData, setQueueData] = useState<Array<TPKeyValue>>([])

    const [teamTableData, setTeamTableData] = useState<Array<TeamActivityTickets>>([]);
    const [groupTableData, setGroupTableData] = useState<Array<TeamActivityTickets>>([]);
    const [eventTableData, setEventTableData] = useState<Array<TeamActivityTickets>>([]);

    const [groupValue, setGroupValue] = useState("");
    const [projectValue, setProjectValue] = useState("");
    const [queueValue, setQueueValue] = useState("");

    const [casesCheck, setCasesCheck] = useState<boolean>(true);
    const [eventsCheck, seteventsCheck] = useState<boolean>(true);
    const [chatCheck, setchatCheck] = useState<boolean>(true);
    const [mailCheck, setmailCheck] = useState<boolean>(true);


    const [groupIdEvents, setGroupIdEvents] = useState(0);


    const getProjects = async () => {
        loadingCallback && loadingCallback(true);
        const ATMService = new EventProjectService();

        let expectedCodes = [200, 404];

        try {
            let responseRequest = await ATMService.getProjectsBySuperior(
                TPGlobal.currentUserGuid,
                false,
                true,
                expectedCodes
            );

            if (responseRequest) {
                let Data = [];

                for (let i = 0; i < responseRequest.length; i++) {
                    Data.push({
                        key: responseRequest[i].id,
                        value: responseRequest[i].name
                    });
                }

                setProjectData(Data);
                setProjectValue(Data[0].key);
                setGroupIdEvents(responseRequest[0].conditionsOrQueues[0]?.groupId ?? 0);
            }

            loadingCallback && loadingCallback(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getProjects ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getProjects ex`);
            loadingCallback && loadingCallback(false);
        }
    }

    const getQueuesByProject = async () => {
        loadingCallback && loadingCallback(true);
        const ATMService = new ActiveTeamMembersService();

        let expectedCodes = [200];

        try {
            let responseRequest = await ATMService.getQueueByProjectId(
                projectValue,
                false,
                true,
                expectedCodes
            );

            if (responseRequest) {
                let Data: Array<TPKeyValue> = [];

                for (let i = 0; i < responseRequest.length; i++) {
                    Data.push({
                        key: responseRequest[i].groupId,
                        value: responseRequest[i].user.firstName
                    });
                }

                setQueueData(Data);
                setQueueValue(Data[0].key);
            }
            loadingCallback && loadingCallback(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getQueuesByProject ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getQueuesByProject ex`);
            loadingCallback && loadingCallback(false);
        }
    }

    const getQueues = async () => {
        loadingCallback && loadingCallback(true);
        const groupsService = new GroupsService();
        let expectedCodes = [200, 404];

        try {
            let responseRequest = await groupsService.getGroupsBySuperior(
                TPGlobal.currentUserGuid,
                false,
                true,
                expectedCodes
            )

            if (responseRequest) {
                let Data = [];

                for (let i = 0; i < responseRequest.length; i++) {
                    Data.push({
                        key: responseRequest[i].groupId,
                        value: responseRequest[i].user.firstName
                    });
                }

                setGroupData(Data);
                setGroupValue(Data[0].key);
            }
            loadingCallback && loadingCallback(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getQueues ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getQueues ex`);
            loadingCallback && loadingCallback(false);
        }
    }

    const getTeamTickets = async () => {
        loadingCallback && loadingCallback(true);
        let ATMService = new ActiveTeamMembersService();

        let expectedCodes = [200];

        let inputDTO = {
            guidUSER: TPGlobal.currentUserGuid,
            startDate: moment(startDate).format('MM/DD/YYYY'),
            endDate: moment(endDate).format('MM/DD/YYYY')
        }

        try {
            let responseRequest = await ATMService.getTeamTickets(
                inputDTO,
                false,
                true,
                expectedCodes
            );

            if (responseRequest) {
                let newData: TeamActivityTickets[] = [];

                for (let i = 0; i < responseRequest.length; i++) {
                    newData.push({
                        id: Number(responseRequest[i].ticketId),
                        ticket: responseRequest[i].ticket.toLowerCase() as "case" | "event" | "chat" | "mail",
                        status: responseRequest[i].statusId as "PE" | "PR" | "EX" | "CL" | "IP" | "HI" | "AL" | "OH" | "RE" | "SO" | "RC",
                        statusDescription: responseRequest[i].statusDescription,
                        creationDate: new Date(responseRequest[i].creationDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }),
                        customer: responseRequest[i].customer,
                        assignTo: responseRequest[i].userName,
                        idAssignTo: responseRequest[i].idUser,
                    })
                }

                setTeamTableData(newData);
                loadingCallback && loadingCallback(false);
            }

        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getTeamData ex`);
            loadingCallback && loadingCallback(false);
        }
    }

    const getGroupTickets = async () => {
        loadingCallback && loadingCallback(true);
        let ATMService = new ActiveTeamMembersService();

        let expectedCodes = [200];

        let inputDTO = {
            startDate: moment(startDate).format('MM/DD/YYYY'),
            endDate: moment(endDate).format('MM/DD/YYYY'),
            idGroup: groupValue
        }

        try {
            let responseRequest = await ATMService.getGroupTickets(
                inputDTO,
                false,
                true,
                expectedCodes
            )

            if (responseRequest) {
                let newData: TeamActivityTickets[] = [];

                for (let i = 0; i < responseRequest.length; i++) {
                    newData.push({
                        id: Number(responseRequest[i].ticketId),
                        ticket: responseRequest[i].ticket.toLowerCase() as "case" | "event" | "chat" | "mail",
                        status: responseRequest[i].statusId as "PE" | "PR" | "EX" | "CL" | "IP" | "HI" | "AL" | "OH" | "RE" | "SO" | "RC",
                        statusDescription: responseRequest[i].statusDescription,
                        creationDate: new Date(responseRequest[i].creationDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }),
                        customer: responseRequest[i].customer,
                        assignTo: responseRequest[i].userName,
                        idAssignTo: responseRequest[i].idUser,
                    })
                }

                setGroupTableData(newData);
                loadingCallback && loadingCallback(false);
            }

        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getTeamData ex`);
            loadingCallback && loadingCallback(false);
        }
    }

    const getEventTickets = async () => {
        loadingCallback && loadingCallback(true);
        let ATMService = new ActiveTeamMembersService();

        let expectedCodes = [200];

        let inputDTO = {
            idEventProject: projectValue,
            idQueue: queueValue == "" ? 0 : queueValue,
            startDate: moment(startDate).format('MM/DD/YYYY'),
            endDate: moment(endDate).format('MM/DD/YYYY')
        }

        try {
            let responseRequest = await ATMService.getEventTickets(
                inputDTO,
                false,
                false,
                expectedCodes
            )

            if (responseRequest) {
                let newData: TeamActivityTickets[] = [];
                for (let i = 0; i < responseRequest.length; i++) {
                    newData.push({
                        id: Number(responseRequest[i].ticketId),
                        ticket: responseRequest[i].ticket.toLowerCase() as "case" | "event" | "chat" | "mail",
                        status: responseRequest[i].statusId as "PE" | "PR" | "EX" | "CL" | "IP" | "HI" | "AL" | "OH" | "RE" | "SO" | "RC",
                        statusDescription: responseRequest[i].statusDescription,
                        creationDate: new Date(responseRequest[i].creationDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }),
                        customer: responseRequest[i].customer,
                        assignTo: responseRequest[i].userName,
                        idAssignTo: responseRequest[i].idUser,
                    })
                }

                setEventTableData(newData);
                loadingCallback && loadingCallback(false);
            }

        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getTeamData ex`);
            loadingCallback && loadingCallback(false);
        }
    }

    useEffect(() => {
        if (changeTitle && view == "group") {
            changeTitle(groupData.find(item => item.key == groupValue)?.value);
        }
    }, [groupValue]);

    useEffect(() => {
        projectValue !== "" && getQueuesByProject();
        if (changeTitle && view == "event") {
            changeTitle(projectData.find(item => item.key == projectValue)?.value);
        }
    }, [projectValue]);

    useEffect(() => {
        if (view == "group" && changeTitle) {
            changeTitle && changeTitle(groupData.find(item => item.key == groupValue)?.value);
        } else if (view == "team" && changeTitle) {
            changeTitle && changeTitle(myTeamLabel);
        } else if (view == "event" && changeTitle) {
            changeTitle && changeTitle(projectData.find(item => item.key == projectValue)?.value);
        }
    }, [view, myTeamLabel]);


    useEffect(() => {
        loadResources();
        getProjects();
        getQueues();
    }, [])

    useEffect(() => {
        selectedRows.length < 1 || selectedRows.some((row) => row.ticket === "event") ? setDisabled(true) : setDisabled(false);
    }, [selectedRows])

    const getInfoCardLabel = (item: TeamActivityTickets) => {
        if (item.ticket == "case") {
            return item.statusDescription;
        } else {
            let label = infoCardLabels["expired"];

            item.status == "PE" && (label = infoCardLabels["pending"]);
            item.status == "PR" && (label = infoCardLabels["processing"]);
            item.status == "IP" && (label = infoCardLabels["inProcess"]);
            item.status == "EX" && (label = infoCardLabels["expired"]);
            item.status == "CL" && (label = infoCardLabels["closed"]);
            item.status == "HI" && (label = infoCardLabels["history"]);

            return label;
        }
    }

    const getInfoCardType = (item: TeamActivityTickets) => {
        if (item.ticket == "case") {
            let type: InfoCardTypes = "offline";

            item.status == "AL" && (type = "case");
            item.status == "EX" && (type = "expired");
            item.status == "OH" && (type = "online");
            item.status == "PE" && (type = "pending");
            item.status == "RE" && (type = "offline");
            item.status == "SO" && (type = "ready");
            item.status == "RC" && (type = "working");

            return type;
        } else {
            let type: InfoCardTypes = "offline";
            const eventStatusEnums: { value: string, type: InfoCardTypes }[] = [
                {
                    value: "PE",
                    type: "pending"
                },
                {
                    value: "PR",
                    type: "processing"
                },
                {
                    value: "IP",
                    type: "inProcess"
                },
                {
                    value: "EX",
                    type: "expired"
                },
                {
                    value: "CL",
                    type: "closed"
                },
                {
                    value: "HI",
                    type: "history"
                }
            ]
    
            const status = eventStatusEnums.find(status => status.value == item.status);

            if (status) {
                type = status.type;
            }
    
            return type;
        }
    }

    const customColumns: ColumnStyles<TeamActivityTickets> = {
        id: ({ item, value }) => (
            <TablePrimaryItem
                value={value}
                onClick={() => item.ticket == "case" ? casecontext.handleAddNewCaseViewerCallBack(value) : (item.ticket == "event" && casecontext.showTakeNextQueue(view == "group" ? groupValue : queueValue, value, true))}
            />
        ),
        ticket: ({ value, item }) => <CockpitInfoCard type={value} label={infoCardLabels[item.ticket]} isSmallCard />,
        status: ({ item, value }) => <CockpitInfoCard type={getInfoCardType(item)} label={getInfoCardLabel(item)} isSmallCard />,
        customer: ({ value }) => value == "NULL" ? "" : value,
        assignTo: ({ value }) => (
            value && value.length > 0 && (
                <div style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
                    <UserProfile
                        completeName={value}
                        customSize={{ font: "16px", size: "34px" }}
                    />
                    <p style={{ margin: 0 }}>{value}</p>
                </div>
            )
        )

    }

    const handleClose = (status: boolean) => {
        setOpenModal(status);
    }

    const callback = () => {
        if (view == "team") {
            getTeamTickets()
        } else if (view == "group") {
            getGroupTickets()
        } else {
            getEventTickets()
        }
    }

    const filterData = () => {
        let toFilter;

        toFilter = view == "team" ? teamTableData : (view == "group" ? groupTableData : eventTableData);

        let filters = {
            cases: casesCheck,
            event: eventsCheck,
            mail: mailCheck,
            chat: chatCheck
        }

        return toFilter.filter((item: TeamActivityTickets) => {
            return (
                (filters.cases && item.ticket == "case") ||
                (filters.event && item.ticket == "event") ||
                (filters.mail && item.ticket == "mail") ||
                (filters.chat && item.ticket == "chat")
            );
        });
    };

    const showCheckboxes = () => {
        let data: TeamActivityTickets[];

        data = view == "team" ? teamTableData : (view == "group" ? groupTableData : eventTableData);

        if (data.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const columnNames: CustomColumnNames<TeamActivityTickets> = {
        ticket: ticketLabel,
        status: statusLabel,
        creationDate: creationDateLabel,
        customer: customerLabel,
        assignTo: assignToLabel,
    }

    return (
        <>
            <div style={{ marginTop: "20px", display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'flex-end' }}>
                {view == "group" && (
                    <div style={{ marginTop: "20px", width: "250px" }}>
                        <TPSelect
                            isMandatory={true}
                            value={groupValue}
                            labelText={selectGroupLabel}
                            onChange={(e: any) => {
                                setGroupValue(e.target.value);
                            }}
                            dataSource={groupData}
                        />
                    </div>
                )}
                {view == "event" && (
                    <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
                        <div style={{ marginTop: "20px", width: "250px" }}>
                            <TPSelect
                                isMandatory={true}
                                value={projectValue}
                                labelText={projectLabel}
                                onChange={(e: any) => setProjectValue(e.target.value)}
                                dataSource={projectData}
                            />
                        </div>
                        <div style={{ marginTop: "20px", width: "250px" }}>
                            <TPSelect
                                isMandatory={true}
                                value={queueValue}
                                labelText={queueLabel}
                                onChange={(e: any) => setQueueValue(e.target.value)}
                                dataSource={queueData}
                            />
                        </div>
                    </div>
                )}
                <TPDatePicker
                    labelText={selectStartDate}
                    isMandatory={false}
                    selectedDate={startDate}
                    onChangeDate={(e: Date) => setStartDate(e)}
                    maxDate={new Date()}
                />
                <TPDatePicker
                    labelText={selectEndtDate}
                    isMandatory={false}
                    selectedDate={endDate}
                    onChangeDate={(e: Date) => setEndDate(e)}
                    minDate={startDate}
                    maxDate={new Date()}
                />
                <TPButton
                    id='search-tickets'
                    isDesignSystem
                    onClick={() => { view == "team" ? getTeamTickets() : (view == "group" ? getGroupTickets() : getEventTickets()) }}
                    style={{ marginBottom: '3px', padding: "10px 18px" }}
                >
                    {searchLabel}
                </TPButton>
            </div>
            <br />
            <DynamicTable
                id={`active-team-members-tickets-${view}`}
                data={filterData()}
                selectable
                hideAllTableOnNoData
                noDataMessage={nothingToseeYetLabel + (view == "team" ? stillLabel : "")}
                secondaryNoDataMessage={view === "team" ? noUsersConfiguredLabel : (view === "group" ? noSelectedGroupLabel : noSelectedProjectLabel)}
                columnStyles={customColumns}
                columnNames={columnNames}
                hiddenColumns={["idAssignTo", "statusDescription"]}
                withPreferences
                hiddenSearch={filterData().length > 0 ? false : true}
                onSelectionChange={(e) => setSelectedRows(e)}
                disableSelectionCheckbox={(row) => row.ticket == "event" ? true : false}
                onIconClicked={icon => {
                    if (icon == TPIconTypes.loop || icon == TPIconTypes.refresh) callback();
                }}
                additionalCheckboxes={
                    view != "event" && showCheckboxes() ?
                        [
                            {
                                checked: casesCheck,
                                key: "cases",
                                label: casesLabel,
                                onChange: () => setCasesCheck(!casesCheck),
                            },
                            {
                                checked: eventsCheck,
                                key: "events",
                                label: eventsLabel,
                                onChange: () => seteventsCheck(!eventsCheck),
                            },
                            {
                                checked: mailCheck,
                                key: "mail",
                                label: emailsLabel,
                                onChange: () => setmailCheck(!mailCheck),
                            },
                            {
                                checked: chatCheck,
                                key: "chat",
                                label: chatsLabel,
                                onChange: () => setchatCheck(!chatCheck),
                            },
                        ] : []
                }
            />
            <div className="reassign-button-container">
                <TPButton
                    id="reassing-tickets"
                    isDesignSystem
                    customType={ButtonCustomType.secondary}
                    onClick={() => setOpenModal(true)}
                    disabled={disabled}
                    style={{ padding: "1px 18px" }}
                >
                    {reassignCasesLabel}
                </TPButton>
            </div>
            {openModal && <ReassignModal handleModalClose={handleClose} selectedRows={selectedRows} hasEvents={selectedRows.some((row) => row.ticket === "event")} callback={callback} />}
        </>
    )
}

export default ActiveTeamMembersTickets;