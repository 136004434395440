import React, { useEffect, useState } from "react";
import ParentFolder from "./ImagesAdminParentFolder";
import {
    IcoFolderIStyle,
    IcoMenuIStyle,
    ImagesAdminFolderDivStyle,
    MenuItemDivStyle,
    NameFolderInputStyle,
    NameFolderPStyle,
    NivelDivStyle,
} from "./StyleImageAdmin";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import { FollowUpService } from "@/services/FollowUpService";
import { OperationMode } from "./useStatusImagesAdmin";
import { ImagesAdminPopupMenu } from "./ImagesAdminPopupMenu";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";
import { RecordLocalizedModel } from "../../models/Global/TPGlobalModels";
import { TPKeyValue } from "../../helpers/TPKeyValue";
import TPGlobal from "../../helpers/TPGlobal";
import { LanguagesViewModel } from "../../models/Languages/LanguagesViewModel";
import TPLabel from "../../components/bootstrap/forms/TPLabel/TPLabel";
import { TPI18N } from "../../services/I18nService";
import { TPLog, TPLogType } from "../../helpers/TPLog";
import { MultilanguageFieldValueViewModel } from "../../models/multilanguage/MultilanguageFieldValueModel";
import { MultilanguageFieldValueService } from "../../services/MultilanguageFieldValueService";
import InputLanguages from "./InputLanguages";
import TPIcon from "../../components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "../../models/Global/TPGlobalEnums";

type ContainerFolderProps = {
    folders: RecursiveRoutes;
};

const ChildrenFolder: React.FC<ContainerFolderProps> = ({ folders }) => {
    const componentFileName: string = "ChildrenFolder.tsx";
    const resourceSet: string = "ImagesAdminComponent";

    const multilanguageTableName: String = "IMAGes";
    const multilanguageFieldName: String = "FolderName_IMAG";

    const [activeAction, setActiveAction] = React.useState<Boolean>(false);   
    const [recordLocalizedModel, setRecordLocalizedModel] = useState<Array<RecordLocalizedModel>>([]);
    const [baseLanguageOptions, setBaseLanguageOptions] = useState<Array<TPKeyValue>>([]);

    const [active, setActive] = useState(false);


    const loadResourcesAndLoadInfo = async () => {

        //setCodeFolderLabel(
        //    await TPI18N.GetText(resourceSet, "FolderCodeLabel"),
        //);
        //setNameFolderLabel(
        //    await TPI18N.GetText(resourceSet, "FolderNameLabel"),
        //);
    }


    const value = useAppConctextImageAdmin();
    const {
        selectedFolderParent,
        handleMultiSelectedFileClear,
        handleSelected,
        handleDisableFolder,
        handleLoading,
    } = value;

    const handleActivate = () => {
        Cancel();
       
        if (!activeAction) handleLoading(true);
        setActive(!activeAction);
        setActiveAction(!activeAction);
        if (!activeAction) handleSelected(folders, OperationMode.SelectFolder);

    };

    const Cancel = () => {
        setActiveAction(false);
        handleSelected(new RecursiveRoutes(), OperationMode.Defaul);
        handleMultiSelectedFileClear();
    };

    useEffect(() => {
        loadLanguages();
        loadResourcesAndLoadInfo();
    }, []);

    const loadLanguages = () => {
        let i: number;
        let expectedCodes: Array<number> = [200];

        let listLanguages: Array<TPKeyValue> = [];
        for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
            let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
            let keyValueElement: TPKeyValue = { key: item.id, value: item.name };
            listLanguages.push(keyValueElement);
        }

        if (!recordLocalizedModel)
            setRecordLocalizedModel(new Array<RecordLocalizedModel>);

        let listFormName = [...recordLocalizedModel];
        for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
            let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
            let keyValueElement: RecordLocalizedModel = {
                languageId: item.id,
                localizedValue: "",
                order: i,
            };
            listFormName.push(keyValueElement);
        }
        setRecordLocalizedModel(listFormName);

        if (listLanguages) {
            listLanguages.unshift({ key: "", value: "--" });
            let language = TPGlobal.language;           
        }
    };


    return (
        <>
            <div style={folders.isActive ?
                selectedFolderParent.id == folders.id ?
                    {
                        backgroundColor: "#7719a9",
                        padding: "5px",
                        color: "white",
                        borderRadius: "4px",
                        width: "100%"
                    } : {}
                : {
                    backgroundColor: "rgb(201 201 201)",
                    padding: "5px",
                    color: "white",
                    borderRadius: "4px",
                    width: "100%"
                }} >
                <ImagesAdminFolderDivStyle>
                    <div style={{ transform: "rotate(230deg)", fontSize: "22px" }} >                    <TPIcon
                        iconType={TPIconTypes.outlineUndo}
                        onClick={handleActivate}
                    />
                    </div>
                    <TPIcon
                        iconType={TPIconTypes.Folder}
                        onClick={handleActivate}
                        style={{ fontSize: "22px" }}
                    />
                    <NameFolderPStyle onClick={handleActivate}>
                        {" "}
                        {(folders.folderName ? folders.folderName : folders.localizedFolderName)}
                    </NameFolderPStyle>

                </ImagesAdminFolderDivStyle>
            </div>
            {active
                ? folders.childrens.map((folder) => (

                    <NivelDivStyle>
                        <ChildrenFolder folders={folder} key={folder.id}></ChildrenFolder>
                    </NivelDivStyle>

                ))
                : null}


        </>
    );
};

export default ChildrenFolder;
