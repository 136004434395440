import { Accordion, AccordionSummary, Popover, Switch } from "@mui/material";
import styled from "styled-components";

const flexBase = `
    display: flex;
    align-items: center;
`;

type TimelineProps = {
  isExpanded: boolean;
};

type CaseCircle = {
  color: string;
};

type AccordionProps = {
  isActive: boolean;
};

export const StyledTimelineHeader = styled.div<TimelineProps>(
  ({ theme, isExpanded }) => `
  ${flexBase}
  width: ${isExpanded ? "100" : "70"}%;
  height: 24px;
  justify-content: space-between;
  padding: 0;
  padding-right: ${isExpanded ? "20" : "16"}px;

  p {
    margin: 0;
  }

  p.title {
    color: #A00095;
    font-size: 20px;
    font-weight: 900;
  }

  .timeline-actions {
    ${flexBase}
    gap: 1.5em;

    .ui-icon {
        width: 24px;
        height: 24px;
        font-size: 24px;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }

        &:active {
            color: black;
        }
    }

    .actions-timer,
    .actions-icons,
    .actions-panel {
        ${flexBase} 
    }

    .actions-timer {
        gap: 0.2em;

        .ui-icon {
            color: #A00095;
            cursor: initial;

            &:hover {
                opacity: 1;
            }
        }
    }

    .actions-icons {
        gap: 0.3em;

        .ui-icon {
            color: #5D5D5D;
        }
    }

    .actions-panel {
        gap: 0.4em;
        cursor: pointer;
        
        p {
            color: #A00095;
            font-size: 13px;
            font-weight: 500;
        }

        &:hover {
            opacity: 0.8;
        }
    }
  }
`
);

export const StyledCaseCircleProps = styled.div<CaseCircle>(
  ({ theme, color }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${color};
`
);

export const StyledWorkflowContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    width: 100%;
`
);

export const StyledAccordion = styled(Accordion)<AccordionProps>(
  ({ theme, isActive }) => ({
    boxShadow: "none",
    border: `1px solid ${isActive ? "#780096" : "#BFBFBF"}`,
    margin: 0,
    "&:before": {
      display: "none",
    },
  })
);

export const StyledAccordionHeader = styled(AccordionSummary)({
  boxShadow: "none",
});

export const StyledAccordionHeaderOptions = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;

    p {
      margin: 0;
    }

    .principal,
    .secondary {
      display: flex;
      justify-content: space-between;
    }

    .principal {
      .task-init,
      .task-controls {
        display: flex;
        align-items: center;
      }

      .task-init {
        gap: 0.4em;
        
        p.task-title {
          font-size: 14px;
          font-weight: 600;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .task-controls {
        gap: 0.2em;

        .ui-icon {
            width: 24px;
            height: 24px;
            font-size: 24px;
            cursor: pointer;
            transition: all 0.1s ease-in-out;

            &:hover {
                opacity: 0.8;
            }

            &:active {
                color: black;
            }
        }

        .rotate {
            transform: rotate(90deg);
        }
      }
    }

    .secondary {
      .task-responsible,
      .task-extra {
        display: flex;
        align-items: center;
        gap: 0.5em;
        width: 50%;

        p.name,
        .ui-icon {
          color: #2E2E2E;
          line-height: 16px;
        }

        p.name {
          font-size: 12px;
          font-weight: 500;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ui-icon {
          width: 24px;
          height: 24px;
          font-size: 24px;
        }
      }

      .task-extra {
        justify-content: space-between;

        .task-calendar,
        .task {
          display: flex;
          align-items: center;
          gap: 0.5em;
        }
        
        .task-calendar {
          .name > strong,
          .ui-icon {
            color: #CF0000;
          }
        }
      }
    }
`
);

export const StyledTimelineContent = styled.div<TimelineProps>(
  ({ theme, isExpanded }) => `
    display: flex;
    align-items: flex-start;
    gap: 16px;
    margin-top: ${isExpanded ? "1em" : "0"};

    .task-index {
      background-color: #A00095;
      color: white;
      font-size: 13px;
      font-weight: 600;
      min-width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
`
);

export const StyledEmailTask = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 1.5em;

    .header {
      display: flex;
      margin-left: 11px;
      gap: 0.8em;
    }

    .translate-controls {
      display: flex;
      align-items: center;
      gap: 1em;
    }

    .email {
      max-height: 20em;
      overflow: auto;
      font-size: 14px;

      * {
        font-size: 14px !important;
      }

      div {
        margin-top: 1em;
      }
    }

    .email-shadow {
      opacity: 0.5;
      font-size: 14px;
    }

    .text-shadow {
      margin: 0;
      opacity: 0.5;
    }

    .footer {
      display: flex;
      justify-content: space-between;

      .key-points-content {
        button {
          background: linear-gradient(90deg, #780096, #8051FF);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: 6px;
            bottom: 7px;
            width: 75%;
            height: 1px;
            background: linear-gradient(90deg, #780096, #8051FF);
          }
        }
      }


      .key-points-container {
        width: 240px
        height: 580px;
      }
    }
`
);

export const StyledOutboundEmailTask = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 1.5em;

  .input-section,
  .input-section > .checkbox-section {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 1em;

    label,
    p {
      font-size: 12px;
      font-weight: 500;
      color: #2E2E2E;
      margin: 0;
    }

    p {
      font-size: 14px;
    }

    .email-checkbox {
      margin-bottom: 4px;

      label {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: fit-content;
        gap: 0.3em;
      }
  
      label > p {
        line-height: 1.3;
      }
  
      label.active > p {
        font-weight: 600;
      }
  
      label.disabled > p {
        color: #bfbfbf;
        cursor: default;
      }
    }
  }

  .additional-options {
    display: flex;
    justify-content: flex-end;

    .composition-content {

      button {
        background: linear-gradient(90deg, #780096, #8051FF);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          left: 6px;
          bottom: 7px;
          width: 83%;
          height: 1px;
          background: linear-gradient(90deg, #780096, #8051FF);
        }
      }
    }
  }

  .additional-options.with-translation {
    justify-content: space-between;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
  }

`)

export const StyledSentimentSection = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: 2em;

  .emotion {
    display: flex;
    gap: 0.5em;

    p {
      color: #2E2E2E;
      font-size: 14px;
      margin: 0;
    }

    .sentiments-container {
      display: flex;
      gap: 0.5em;
    }
  }
`)

export const StyledSentimentIcon = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: 0.2em;

  p.percentage {
    color: #2E2E2E;
    font-size: 10px;
    margin: 0;
  }
`)

export const StyledToggle = styled(Switch)(({ theme }) => ({
  "&:active .MuiSwitch-thumb": {
    width: 12,
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(13px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#00A859",
        opacity: 1,
        border: "none",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 12,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "#7B7B7B",
    opacity: 1,
  },
}));

export const StyledKeyPointsPopOver = styled.div(
  ({ theme }) => `
  display: flex;
  position: relative;
  z-index: 1;
  gap: 1em;
  min-height: 185px;
  max-height: 270px;
  width: 580px;
  padding: 1em;

  .key-points {
    display: flex;
    flex-direction: column;
    gap: 1em;
    overflow: auto;
    padding-right: 1em;
    scrollbar-width: thin;
  }

  .ui-icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        color: black;
    }
  }
`
);

export const StyledAttachmentContent = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 1em;
  
  .check-options {
    display: flex;
    gap: 1em;

    .email-checkbox {
      margin-bottom: 4px;

      label {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: fit-content;
        gap: 0.3em;
      }

      label,
      p {
        font-size: 12px;
        font-weight: 500;
        color: #2E2E2E;
        margin: 0;
      }

      label > p {
        line-height: 1.3;
      }

      label.active > p {
        font-weight: 600;
      }

      label.disabled > p {
        color: #bfbfbf;
        cursor: default;
      }
    }

  }

`)
export const StyledIAPopover = styled(Popover)`
  .MuiPaper-root {
    position: relative;
    padding: 16px;
    background-color: white;
    border-radius: 8px;
    display: inline-block;
    max-width: 90vw;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      padding: 2px;
      background: linear-gradient(45deg, #780096, #8051ff);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }
  }
`;
