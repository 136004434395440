import styled from "styled-components";

export const TPButtonStyled = styled.button(
  ({ theme }) => `
    &.btn{
        padding: 6px 25px;
        min-width: 139px;          
        min-height: 31px;
        font-size: 15px;
        
        &:focus{
            box-shadow: none;
        }
        &:active{
            box-shadow: none;
        }
        &.btn-primary{
            background-color: ${theme.colors.button.primary.backgroundColor};
            border-color: ${theme.colors.button.primary.borderColor}; 
            color: ${theme.colors.button.primary.fontColor}
        }

        &.btn-outline-primary{
            color: ${theme.colors.button.outlinePrimary.fontColor};
            border-color: ${theme.colors.button.outlinePrimary.borderColor};
            

            &:hover{
                color: ${theme.colors.button.outlinePrimary.hover.fontColor};
                background-color: ${theme.colors.button.outlinePrimary.hover.backgroundColor};
            }
        }

        &.btn-secondary{
            background-color: ${theme.colors.button.secondary.backgroundColor};
            border-color: ${theme.colors.button.secondary.borderColor};
        }

        &.btn-link{
            color: ${theme.colors.button.link.fontColor};
        }

        &.tp-btn-alt {
            border-color: ${theme.colors.button.primary.backgroundColor};
            color: ${theme.colors.button.primary.backgroundColor};
        }

        &.tp-btn-empty{
            padding: 0;
            margin: 0;
            background-color: ${theme.colors.button.empty.backgroundColor};
            border: none;
        }

        &.tp-btn-toolbar{
            display: flex;
            align-items: center;
            text-decoration: underline;
            gap: 5px;
            padding: 0;
        }

        &.tp-btn-icon{
            display: flex;
            align-items: center;
            padding: 0;
            margin: 0;
            min-width: 20px;
            font-size: 15px;
            gap: 2px;
            
            i{
                font-size: 16px;
                display: flex;
            }
            span{
                text-decoration: underline;
            }

            &.tp-big{
                font-size: 60px;
            }
        }
    }
`
);

export enum ButtonCustomType {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
}

interface ButtonProps {
  customType: ButtonCustomType;
  fontWeight?: string;
  orientationIcon?: "left" | "right";
}

export const StyledTPButton = styled.button<ButtonProps>(
  ({ theme, customType, fontWeight = "500", orientationIcon }) => `
    &.cim-button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: ${orientationIcon === "right" ? "row" : "row-reverse"};
        height: 32px;
        border-radius: 4px;
        border: 1px solid transparent;
        gap: 0.3em;

        &:active {
            box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.3), 
            inset 0px -2px 5px rgba(255, 255, 255, 0.3);
        }

        p {
            font-weight: ${fontWeight};
            font-family: 'Noto Sans', sans-serif;
            font-size: 14px;
        }

        .ui-icon {
            font-size: 20px;
            width: 20px;
            height: 19px;
        }

        &.${customType} {
            background-color: ${theme.colors.cim.button[customType].background};
            border-color: ${theme.colors.cim.button[customType].border};
            color: ${theme.colors.cim.button[customType].color};

            &:hover {
                background-color: ${theme.colors.cim.button[customType].hover.background};
                border-color: ${theme.colors.cim.button[customType].hover.border};
                color: ${theme.colors.cim.button[customType].hover.color};
            }
            
            &:disabled {
                background-color: ${theme.colors.cim.button[customType].disabled.background};
                border-color: ${theme.colors.cim.button[customType].disabled.border};
                color: ${theme.colors.cim.button[customType].disabled.color};

                &:active {
                    box-shadow: none;
                    background-color: ${theme.colors.cim.button[customType].disabled.background};
                    border-color: ${theme.colors.cim.button[customType].disabled.border};
                    color: ${theme.colors.cim.button[customType].disabled.color};
                }
            }
        }

        &.tertiary {
            p {
                text-decoration: underline;
            }

            &:active {
                color: ${theme.colors.cim.button.tertiary.color};
                box-shadow: none;
            }
        }
`
);
