import { Placement } from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { InfoCardTypes } from "@/pages/SupervisorCockpit/supervisor-cockpit.model";
import { Tooltip, Typography } from "@mui/material";
import React, { FC, ReactElement, useState } from "react";
import { baseColors, profileThemes } from "../../utils/profile-status";
import { camelCaseToId } from "../../utils/text-regex";
import {
  SizeItem,
  StyledUserProfile,
  UserProfileSize,
} from "../design-system-styles";
import { ProfileCategories } from "../design-system.model";

type UserProfileProps = {
  completeName: string;
  imageUrl?: string;
  size?: UserProfileSize;
  customSize?: SizeItem;
  enableTooltip?: boolean;
  tooltipPlacement?: Placement;
  withStatus?: boolean;
  status?: InfoCardTypes;
};

export type ThemeCase = 0 | 1 | 2 | 3 | 4;

const UserProfile: FC<UserProfileProps> = React.memo(
  ({
    completeName,
    imageUrl,
    size = "sm",
    customSize,
    enableTooltip,
    tooltipPlacement,
    withStatus,
    status = "offline"
  }): ReactElement => {
    const [imageWithError, setImageWithError] = useState<boolean>(false);
    const profileId: string = `${camelCaseToId(completeName)}-profile`;

    const categories: ProfileCategories = {
      0: ["a", "b", "c", "d", "e"],
      1: ["f", "g", "h", "i", "k"],
      2: ["j", "l", "o", "p", "q"],
      3: ["m", "n", "ñ", "r", "s"],
      4: ["t", "u", "v", "w", "x", "y", "z"],
    };

    const getInitials = (name: string): string => {
      if (!name) return "";

      const nameParts = name.trim().split(/\s+/);

      if (nameParts.length === 1) {
        return nameParts[0].charAt(0).toUpperCase();
      }

      const firstInitial = nameParts[0].charAt(0).toUpperCase();
      const lastInitial = nameParts[nameParts.length - 1]
        .charAt(0)
        .toUpperCase();

      return `${firstInitial}${lastInitial}`;
    };

    const getCategoryBy = (name: string): number => {
      const initial = name.charAt(0).toLowerCase();

      for (let category in categories) {
        if (categories[category].includes(initial)) {
          return parseInt(category);
        }
      }

      return 0;
    };

    const styledTooltipText = enableTooltip && (
      <Typography sx={{ fontFamily: "Noto Sans", fontSize: "10px" }}>
        {"completeName"}
      </Typography>
    );

    const handleError = () => {
      setImageWithError(true);
    };

    return (
      <Tooltip title={styledTooltipText} placement={tooltipPlacement}>
        <StyledUserProfile
          id={profileId}
          color={profileThemes[getCategoryBy(completeName)].color}
          variant={profileThemes[getCategoryBy(completeName)].variant}
          size={size}
          customSize={customSize}
        >
          {imageUrl && !imageWithError ? (
            <img src={imageUrl} alt={profileId} onError={handleError} />
          ) : (
            <>{getInitials(completeName)}</>
          )}
          {withStatus && (
            <div className="circle-status" style={{ backgroundColor: baseColors[status].color }}></div>
          )}
        </StyledUserProfile>
      </Tooltip>
    );
  }
);

export default UserProfile;
