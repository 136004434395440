import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { ToastMessagesDTO } from "@/models/ToastMessages/ToastMessagesDTO";
import { UserActiveInactiveInputDTO } from "@/models/Users/UserActiveInactiveInputDTO";
import { UserInputDTO } from "@/models/Users/UserInputDTO";
import { UserStatusModel, UserViewModel } from "@/models/Users/UserModels";

export class UserService {
  serviceFileName: string = "UserService.ts";

  public async getUserGuidFromToken(
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<UserViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/user/get-from-azuread-token";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getUserGuidFromToken ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getUserGuidFromToken`);
      throw new Error(`Error ${this.serviceFileName} getUserGuidFromToken`);
    }
  }

  public async getActiveUsersGroups(
    namePart: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<UserViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/user/search/all-active-users";
    if (namePart !== null && namePart.trim() !== "") {
      url = url + "/" + namePart;
    }

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getActiveUsersGroups ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getActiveUsersGroups`);
      throw new Error(`Error ${this.serviceFileName} getActiveUsersGroups`);
    }
  }

  public async getUserSubalterns(
    userId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/users/subalterns/" + userId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getUserSubalterns ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getUserSubalterns`);
      throw new Error(`Error ${this.serviceFileName} getUserSubalterns`);
    }
  }

  public async reassignSubalterns(
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/users/reassign-superior";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} reassignSubalterns ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} reassignSubalterns`);
      throw new Error(`Error ${this.serviceFileName} reassignSubalterns`);
    }
  }

  public async getUsersByFilter(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<UserViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/user?FilterIsActive=";
    url = url + filterActive;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getUsersByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getUsersByFilter`);
      throw new Error(`Error ${this.serviceFileName} getUsersByFilter`);
    }
  }

  public async getUserById(
    userId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<UserViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/user/";
    url = url + userId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getUserById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getFunctionById`);
      throw new Error(`Error ${this.serviceFileName} getFunctionById`);
    }
  }

  public async deleteUserById(
    userId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/user/" + userId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteFunctionById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteFunctionById`);
      throw new Error(`Error ${this.serviceFileName} deleteFunctionById`);
    }
  }

  public async insertUser(
    inputDTO: UserInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/user";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertUser ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertFunction`);
      throw new Error(`Error ${this.serviceFileName} insertFunction`);
    }
  }

  public async sendToastMessage(
    inputDTO: ToastMessagesDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/toastmessage/send-message";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} sendToastMessage ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertFunction`);
      throw new Error(`Error ${this.serviceFileName} insertFunction`);
    }
  }
  public async updateUser(
    inputDTO: UserInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/user";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateUser ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateUser`);
      throw new Error(`Error ${this.serviceFileName} updateUser`);
    }
  }

  // bulk user
  public async updateUsersBulk(
    inputData: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string = "/users/bulk";
    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputData,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      console.log("line 288>>>", response);
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateUsersBulk ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateUsersBulk`);
      throw new Error(`Error ${this.serviceFileName} updateUsersBulk`);
    }
  }

  public async getActiveUsersBySearchParameter(
    searchParameter: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<UserViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/user/search/all-active-users/${searchParameter}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getActiveUsersBySearchParameter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getActiveUsersBySearchParameter`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getActiveUsersBySearchParameter`,
      );
    }
  }

  public async getEmailTemplatesBySearch(
    words: string,
    filterIsActive: string,
    languageId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<UserViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/emailtemplate/get-search?words=${words}&FilterIsActive=${filterIsActive}&languageId=${languageId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getEmailTemplatesBySearch ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getEmailTemplatesBySearch`);
      throw new Error(
        `Error ${this.serviceFileName} getEmailTemplatesBySearch`,
      );
    }
  }

  public async activeInactiveUser(
    inputDTO: UserActiveInactiveInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/user/active-inactive";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} activate / inactivate user ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateUser`);
      throw new Error(`Error ${this.serviceFileName} updateUser`);
    }
  }

  public async findSupervisors(): Promise<Array<any>> {
    const { getData } = new TPHTTPService();
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    const url = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/user/supervisor`;
    let response: HTTPResponse;
    try {
      response = await getData(url, [200, 404], false, false, headers);
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} find ex`, TPLogType.ERROR, error);
      console.error(`Error ${this.serviceFileName} find`);
      throw new Error(`Error ${this.serviceFileName} find`);
    }
  }

  public async getCasesById(
    caseId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/cases/" + caseId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getCasesById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getCasesById`);
      throw new Error(`Error ${this.serviceFileName} getCasesById`);
    }
  }

  public async postUserPhotos(
    height: number,
    width: number,
    userLogins: Array<string>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/users/photos";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let body = {
      height: height,
      width: width,
      userLogins: userLogins
    };

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        body,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} postUserPhotos ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} postUserPhotos`);
      throw new Error(`Error ${this.serviceFileName} postUserPhotos`);
    }
  }

  public async getUserStatusById(
    userId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<UserStatusModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/user/status/";
    url = url + userId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getUserStatus ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getUserStatus`);
      throw new Error(`Error ${this.serviceFileName} getUserStatus`);
    }
  }


}
