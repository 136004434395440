import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { FrequentAnswerServices } from "@/services/FrequentAnswerServices";
import { TPI18N } from "@/services/I18nService";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IFolderType, IFrequentAnswer } from "./interfaces/IFrequentAnswer";
import {
  Divider,
  IconSection,
  LayoutContainer,
  MainContent,
  Sidebar,
  SidebarItem,
  StyledIcon,
} from "./styles/FrequentAnswersStyleComponents";
import DynamicTable, {
  ColumnStyles,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { v4 as uuidv4 } from "uuid";
import TPGlobal from "@/helpers/TPGlobal";
import TPModalDelete from "@/layouts/ModalDelete/TPModalDelete";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPModal from "@/layouts/TPModal/TPModal";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPEditor } from "@/components/TPEditor/TPEditor";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { EmailTemplateService } from "@/services/EmailTemplateService";
import TPHorizontalTabs from "@/components/TPTabs/TPHorizontalTabs";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";

const RESOURCE_SET: string = "FrequentAnswersComponent";
const COMPONENT_NAME: string = "FrequentAnswers.tsx";

type additionalDataAutoCompleteType = {
  additionalDataAutoComplete: Array<TPKeyValue>;
  additionalDataAutoCompleteErrorMessage: string;
  [x: string]: any;
};
const buildFolderTree = (folders: IFrequentAnswer[]) => {
  const folderMap = new Map<string, any>();
  const rootFolders: any[] = [];
  const orphans: any[] = [];

  folders.forEach((folder) => {
    folderMap.set(folder.guidId, { ...folder, children: [] });
  });

  folders.forEach((folder) => {
    if (folder.parent_GuidId) {
      const parentFolder = folderMap.get(folder.parent_GuidId);
      if (parentFolder) {
        parentFolder.children.push(folderMap.get(folder.guidId));
      } else {
        orphans.push(folderMap.get(folder.guidId));
      }
    } else {
      rootFolders.push(folderMap.get(folder.guidId));
    }
  });

  return [...rootFolders, ...orphans];
};

const generateRandomID = (): string => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const randomLetters = Array.from({
    length: Math.floor(Math.random() * 3) + 2,
  })
    .map(() => letters.charAt(Math.floor(Math.random() * letters.length)))
    .join("");
  const suffix = Array.from({ length: Math.floor(Math.random() * 5) + 3 })
    .map(() =>
      Math.random() > 0.5
        ? letters.charAt(Math.floor(Math.random() * letters.length))
        : numbers.charAt(Math.floor(Math.random() * numbers.length))
    )
    .join("");

  return `${randomLetters}4124${suffix}`;
};

const convertToFrequentAnswer = (folder: IFolderType): IFrequentAnswer => ({
  guidId: folder.GuidId, // Convertir a camelCase
  id: folder.Id, // Convertir a camelCase
  language: folder.Language, // Convertir a camelCase
  description: folder.Description, // Convertir a camelCase
  template: folder.Template, // Convertir a camelCase
  isActive: folder.IsActive, // Convertir a camelCase
  guid_user: folder.Guid_USER || null, // Convertir a camelCase
  parent_GuidId: folder.Parent_GuidId || null, // Convertir a camelCase
  type: folder.Type, // Convertir a camelCase
  isPublic: folder.IsPublic, // Convertir a camelCase
});

const FrequentAnswers = forwardRef(() => {
  const initialTexts = {
    pageTitle: "PageTitle",
    newPageTitle: "NewPageTitle",
    editPageTitle: "EditPageTitle",
    newFrequentAnswer: "NewFrequentAnswer",
    modalConfirmTitle: "ModalConfirmTitle",
    modalConfirmQuestion: "ModalConfirmQuestion",
    yesButtonLabel: "YesButtonLabel",
    offButtonLabel: "OffButtonLabel",
    okButtonLabel: "OkButtonLabel",
    cancelButtonLabel: "CancelButtonLabel",
    modalEditTitle: "ModalEditTitle",
    folderNameLabel: "FolderNameLabel",
    folderLocationLabel: "FolderLocationLabel",
    rootLabel: "RootLabel",
    deleteLabel: "DeleteLabel",
    cloneLabel: "CloneLabel",
    templateLabel: "TemplateLabel",
    modalConfirmQuestionDeleteAnswer: "ModalConfirmQuestionDeleteAnswer",
    modalConfirmQuestionCloneAnswer: "ModalConfirmQuestionCloneAnswer",
    backLabel: "BackLabel",
    editButtonLabel: "EditButtonLabel",
    saveButtonLabel: "SaveButtonLabel",
    folderLabel: "FolderLabel",
    languageLabel: "LanguageLabel",
    titleLabel: "TitleLabel",
    idLabel: "IdLabel",
    previewLabel: "Preview",
    generalInformationLabel: "General Information",
  };

  const initialadditionalDataAutoCompleteState: additionalDataAutoCompleteType =
    {
      additionalDataAutoComplete: [],
      additionalDataAutoCompleteErrorMessage: "",
    };

  const editorRef = useRef<any>(null);

  const [folderLocation, setFolderLocation] = useState<string>("en");
  const [preview, setPreview] = useState<string>("");
  const [caseType, setCaseType] = useState<"random" | "custom">("custom");
  const [caseNumber, setCaseNumber] = useState<string>("");
  const [activeTab, setActiveTab] = useState<number>(0);
  const [bodyGroup, setBodyGroup] = useState({ tag: "" });
  const [isOpenTagModal, setIsOpenTagModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [translations, setTranslations] = useState(initialTexts);
  const [folderData, setFolderData] = useState<IFrequentAnswer[]>([]);
  const [isAddingFolder, setIsAddingFolder] = useState<boolean>(false);
  const [isNewEditAnswer, setIsNewEditAnswer] = useState<boolean>(false);
  const [newFolderName, setNewFolderName] = useState<string>("");
  const [selectedAnswer, setSelectedAnswer] = useState<IFrequentAnswer | null>(
    null
  );
  const [selectedFolder, setSelectedFolder] = useState<IFrequentAnswer | null>(
    null
  );

  const [idErrorMessage, setIdErrorMessage] = useState("");
  const [titleErrorMessage, setTitleErrorMessage] = useState("");
  const [languageErrorMessage, setLanguageErrorMessage] = useState("");
  const [templateErrorMessage, setTemplateErrorMessage] = useState("");
  const [isTemplateError, setIsTemplateError] = useState<boolean>(false);

  const [modalQuestionState, setModalQuestionState] = useState<{
    isShown: boolean;
    callBackData: IFrequentAnswer | null;
  }>({
    isShown: false,
    callBackData: null,
  });
  const [modalEditState, setModalEditState] = useState<{
    isShown: boolean;
    callBackData: IFrequentAnswer | null;
    newName: string;
    newLocation: string | null;
  }>({
    isShown: false,
    callBackData: null,
    newName: "",
    newLocation: null,
  });
  const [templateModalState, setTemplateModalState] = useState<{
    isShown: boolean;
    title: string;
    content: string;
  }>({
    isShown: false,
    title: "",
    content: "",
  });
  const [modalState, setModalState] = useState<{
    isShown: boolean;
    title: string;
    bodyContent: string;
    actionType: string | null;
    data: IFrequentAnswer | null;
  }>({
    isShown: false,
    title: "",
    bodyContent: "",
    actionType: null,
    data: null,
  });
  const [formState, setFormState] = useState({
    id: "",
    title: "",
    language: "",
    folder: "",
    template: "",
    tag: "",
    tagGroup: "",
  });
  const [additionalDataAutoCompleteState, setadditionalDataAutoCompleteState] =
    useState<additionalDataAutoCompleteType>(
      initialadditionalDataAutoCompleteState
    );
  const [autocompleteToOptions, setAutocompleteToOptions] = useState<
    Array<TPKeyValue>
  >([]);
  const [tags, setTags] = useState<Array<TPKeyValue>>([]);
  const [tagGroups, setTagGroups] = useState<Array<TPKeyValue>>([]);
  const [autocompleteToTopNOptions, setAutocompleteToTopNOptions] = useState<
    Array<TPKeyValue>
  >([]);

  const folderFilterState = useMemo(() => 2, []); // 0-Inactive, 1-Active, 2-All

  const folderTreeData = useMemo(
    () => buildFolderTree(folderData),
    [folderData]
  );

  const tabLabels = useMemo(
    () => [translations.generalInformationLabel, translations.previewLabel],
    [translations]
  );

  const fetchTranslations = useCallback(async () => {
    try {
      const translatedTexts = Object.fromEntries(
        await Promise.all(
          Object.entries(initialTexts).map(async ([key, translationKey]) => {
            try {
              const translation = await TPI18N.GetText(
                RESOURCE_SET,
                translationKey
              );
              return [key, translation || translationKey];
            } catch (error) {
              TPLog.Log(
                `Error ${COMPONENT_NAME} fetching translation for key: ${translationKey}`,
                TPLogType.ERROR,
                error
              );
              return [key, translationKey];
            }
          })
        )
      );
      setTranslations(translatedTexts);
    } catch (error) {
      TPLog.Log(
        `Error ${COMPONENT_NAME} loading translations`,
        TPLogType.ERROR,
        error
      );
    }
  }, []);

  const fetchFolderData = useCallback(async () => {
    try {
      const service = new FrequentAnswerServices();
      const response = await service.getFrequentAnswersByFilterIsActive(
        folderFilterState,
        false,
        true,
        [200, 404]
      );

      const processedData = response.map((item: IFrequentAnswer) => ({
        ...item,
        template:
          item.type === "Content"
            ? TPGlobal.uint8ArrayToString(item.template) || item.template
            : item.template,
      }));

      setFolderData(processedData);
    } catch (error) {
      console.error(`Error fetching folder data:`, error);
    }
  }, [folderFilterState]);

  const getTagGroups = async () => {
    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoading(true);
      let responseRequest = await serviceClient.getTagGroups(
        false,
        true,
        expectedCodes
      );
      if (responseRequest) {
        let tagGroupOptions = responseRequest.map((tagGroup: any) => ({
          key: tagGroup.id,
          value: tagGroup.localizedDescription,
        }));
        tagGroupOptions.unshift({
          key: "--",
          value: "--",
        });

        setTagGroups(tagGroupOptions);
      }

      return;
    } catch (error) {
      TPLog.Log(
        `Error ${COMPONENT_NAME} getTagGroups ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${COMPONENT_NAME} getTagGroups ex`);
    } finally {
      setIsLoading(false);
    }
  };

  const getTagsByGroup = async (groupCode: string) => {
    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoading(true);
      let responseRequest = await serviceClient.getTagsByGroup(
        groupCode,
        false,
        true,
        expectedCodes
      );

      if (responseRequest) {
        let tagsOptions = responseRequest.map((tags: any) => ({
          key: tags.value,
          value: tags.localizedDescription,
        }));
        tagsOptions.unshift({
          key: "--",
          value: "--",
        });
        setTags(tagsOptions);
      }

      return;
    } catch (error) {
      TPLog.Log(
        `Error ${COMPONENT_NAME} getTagsByGroup ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${COMPONENT_NAME} getTagsByGroup ex`);
    } finally {
      setIsLoading(false);
    }
  };

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        fetchTranslations(),
        fetchFolderData(),
        getTagGroups(),
      ]);
    } catch (error) {
      TPLog.Log(`Error ${COMPONENT_NAME} loading data`, TPLogType.ERROR, error);
    } finally {
      setIsLoading(false);
    }
  }, [fetchTranslations, fetchFolderData]);

  const handleReset = useCallback(async () => {
    await loadData();
  }, [loadData]);

  const startAddingFolder = useCallback(() => {
    setIsAddingFolder(true);
    setNewFolderName("");
  }, []);

  const saveNewFolder = useCallback(async () => {
    if (newFolderName.trim()) {
      const service = new FrequentAnswerServices();

      const newFolder = {
        GuidId: uuidv4(),
        Id: generateRandomID(),
        Language: TPGlobal.language,
        Description: newFolderName,
        Template: "template folder",
        IsActive: true,
        IsPublic: true,
        Guid_USER: TPGlobal.currentUserGuid,
        Parent_GuidId: selectedFolder?.guidId || undefined,
        Type: "Folder",
      };

      try {
        setIsLoading(true);

        await service.insertFrequentAnswer(newFolder, true, true, [200, 201]);

        setIsAddingFolder(false);
        await handleReset();
      } catch (error) {
        console.error("Error saving folder:", error);
        TPLog.Log("Error saving folder", TPLogType.ERROR, error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [newFolderName, selectedFolder, generateRandomID, handleReset]);

  const handleFolderKeyDown = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        await saveNewFolder();
      }
    },
    [saveNewFolder]
  );

  const selectFolder = useCallback(
    (folder: IFrequentAnswer | null) => setSelectedFolder(folder),
    [setSelectedFolder]
  );

  const handleAnswerClick = useCallback(
    (answer: IFrequentAnswer) => {
      setSelectedAnswer(answer);
      setIsNewEditAnswer(true);
    },
    [setSelectedAnswer]
  );

  const handleEdit = useCallback(() => {
    setModalEditState({
      isShown: true,
      callBackData: selectedFolder,
      newName: selectedFolder?.description || "",
      newLocation: selectedFolder?.parent_GuidId || null,
    });
  }, [selectedFolder]);

  const handleTemplateClick = useCallback((title: string, content: string) => {
    setTemplateModalState({
      isShown: true,
      title,
      content,
    });
  }, []);

  const closeTemplateModal = useCallback(() => {
    setTemplateModalState({
      isShown: false,
      title: "",
      content: "",
    });
  }, []);

  const handleCallbackAnswerEditModal = useCallback(
    async (confirmed: boolean, data: IFrequentAnswer | null) => {
      setModalEditState({
        isShown: false,
        callBackData: null,
        newName: "",
        newLocation: null,
      });

      if (confirmed && data) {
        try {
          const service = new FrequentAnswerServices();
          setIsLoading(true);

          const updatedFolder: IFolderType = {
            GuidId: data.guidId,
            Id: data.id,
            Language: data.language,
            Description: modalEditState.newName,
            Template: TPGlobal.stringToUTF8(data.template).toString(),
            IsActive: data.isActive,
            Guid_USER: data.guid_user || undefined,
            Parent_GuidId: modalEditState.newLocation || undefined,
            Type: data.type,
            IsPublic: data.isPublic,
          };

          await service.updateFrequentAnswer(
            updatedFolder,
            true,
            true,
            [200, 204]
          );

          const convertedFolder = convertToFrequentAnswer(updatedFolder);
          setSelectedFolder(convertedFolder);
          await handleReset();
        } catch (error) {
          console.error("Error updating folder:", error);
          TPLog.Log("Error updating folder", TPLogType.ERROR, error);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [handleReset, modalEditState]
  );

  const handleDelete = useCallback(() => {
    setModalQuestionState({
      isShown: true,
      callBackData: selectedFolder,
    });
  }, [selectedFolder]);

  const handleCallBackModalConfirm = useCallback(
    async (confirmed: boolean, data: IFrequentAnswer) => {
      setModalQuestionState({ isShown: false, callBackData: null });

      if (confirmed && data) {
        const service = new FrequentAnswerServices();

        try {
          setIsLoading(true);

          await service.deleteFrequentAnswer(
            data.guidId,
            true,
            true,
            [200, 204]
          );

          await handleReset();
        } catch (error) {
          console.error("Error deleting folder:", error);
          TPLog.Log("Error deleting folder", TPLogType.ERROR, error);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [handleReset]
  );

  const handleDeleteOption = useCallback(
    (item: IFrequentAnswer) => {
      const bodyContent = translations.modalConfirmQuestionDeleteAnswer.replace(
        "[{{0}}]",
        `<b>${item.description}</b>`
      );

      setModalState({
        isShown: true,
        title: translations.modalConfirmTitle,
        bodyContent,
        actionType: "delete",
        data: item,
      });
    },
    [translations]
  );

  const handleCloneOption = useCallback(
    (item: IFrequentAnswer) => {
      const bodyContent = translations.modalConfirmQuestionCloneAnswer.replace(
        "[{{0}}]",
        `<b>${item.description}</b>`
      );

      setModalState({
        isShown: true,
        title: translations.modalConfirmTitle,
        bodyContent,
        actionType: "clone",
        data: item,
      });
    },
    [translations]
  );

  const deleteAnswer = useCallback(
    async (data: IFrequentAnswer) => {
      const service = new FrequentAnswerServices();

      try {
        setIsLoading(true);

        await service.deleteFrequentAnswer(data.guidId, true, true, [200, 204]);

        await handleReset();
      } catch (error) {
        console.error("Error deleting answer:", error);
        TPLog.Log("Error deleting answer", TPLogType.ERROR, error);
      } finally {
        setIsLoading(false);
      }
    },
    [handleReset]
  );

  const cloneAnswer = useCallback(
    async (data: IFrequentAnswer) => {
      const service = new FrequentAnswerServices();

      const clonedAnswer = {
        GuidId: uuidv4(),
        Id: generateRandomID(),
        Language: data.language,
        Description: data.description,
        Template: TPGlobal.stringToUTF8(data.template).toString(),
        IsActive: data.isActive,
        IsPublic: data.isPublic,
        Guid_USER: TPGlobal.currentUserGuid,
        Parent_GuidId: data.parent_GuidId || undefined,
        Type: data.type,
      };

      try {
        setIsLoading(true);

        await service.insertFrequentAnswer(
          clonedAnswer,
          true,
          true,
          [200, 201]
        );

        await handleReset();
      } catch (error) {
        console.error("Error cloning answer:", error);
        TPLog.Log("Error cloning answer", TPLogType.ERROR, error);
      } finally {
        setIsLoading(false);
      }
    },
    [generateRandomID, handleReset]
  );

  const handleModalConfirmation = useCallback(
    (confirmed: boolean) => {
      if (confirmed && modalState.data) {
        if (modalState.actionType === "delete") {
          deleteAnswer(modalState.data);
        } else if (modalState.actionType === "clone") {
          cloneAnswer(modalState.data);
        }
      }
      setModalState({
        isShown: false,
        title: "",
        bodyContent: "",
        actionType: null,
        data: null,
      });
    },
    [modalState]
  );

  const handleInputChange = async (field: string, value: string) => {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
    if (field === "id") setIdErrorMessage("");
    if (field === "title") setTitleErrorMessage("");
    if (field === "language") setLanguageErrorMessage("");
    if (field === "template") {
      setTemplateErrorMessage("");
      setIsTemplateError(false);
    }

    if (field === "tagGroup") {
      setFormState({
        ...formState,
        tagGroup: value,
      });
      await getTagsByGroup(value);
    }

    if (field === "tag") {
      setBodyGroup({
        tag: value,
      });
    }
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (selectedAnswer) {
      setFormState({
        id: selectedAnswer.id,
        title: selectedAnswer.description,
        language: selectedAnswer.language,
        folder: selectedAnswer.parent_GuidId || "",
        template: selectedAnswer.template,
        tag: "",
        tagGroup: "",
      });
    } else {
      setFormState({
        id: "",
        title: "",
        language: "",
        folder: "",
        template: "",
        tag: "",
        tagGroup: "",
      });
    }
  }, [selectedAnswer]);

  const saveAnswer = useCallback(async () => {
    const service = new FrequentAnswerServices();

    const newAnswer = {
      GuidId: uuidv4(),
      Id: formState.id,
      Language: formState.language,
      Description: formState.title,
      Template: TPGlobal.stringToUTF8(formState.template).toString(),
      IsActive: true,
      IsPublic: true,
      Guid_USER: TPGlobal.currentUserGuid,
      Parent_GuidId: formState.folder || undefined,
      Type: "Content",
    };

    try {
      setIsLoading(true);

      await service.insertFrequentAnswer(newAnswer, true, true, [200, 201]);

      setIsNewEditAnswer(false);
      await handleReset();
    } catch (error) {
      console.error("Error saving answer:", error);
      TPLog.Log("Error saving answer", TPLogType.ERROR, error);
    } finally {
      setIsLoading(false);
    }
  }, [formState, generateRandomID, handleReset]);

  const editAnswer = useCallback(async () => {
    if (!selectedAnswer) return;

    const service = new FrequentAnswerServices();

    const updatedAnswer = {
      GuidId: selectedAnswer.guidId,
      Id: selectedAnswer.id,
      Language: formState.language,
      Description: formState.title,
      Template: TPGlobal.stringToUTF8(formState.template).toString(),
      IsActive: selectedAnswer.isActive,
      IsPublic: selectedAnswer.isPublic,
      Guid_USER: TPGlobal.currentUserGuid,
      Parent_GuidId: formState.folder || undefined,
      Type: selectedAnswer.type,
    };

    try {
      setIsLoading(true);

      await service.updateFrequentAnswer(updatedAnswer, true, true, [200, 204]);

      setIsNewEditAnswer(false);
      setSelectedAnswer(null);
      await handleReset();
    } catch (error) {
      console.error("Error updating answer:", error);
      TPLog.Log("Error updating answer", TPLogType.ERROR, error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedAnswer, formState, handleReset]);

  const validateForm = async () => {
    let atLeastOneError = false;

    // Validación del campo `id`
    if (!formState.id.trim()) {
      setIdErrorMessage(await TPI18N.GetText(RESOURCE_SET, "ErrorIDMessage"));
      atLeastOneError = true;
    } else {
      setIdErrorMessage("");
    }

    // Validación del campo `title`
    if (!formState.title.trim()) {
      setTitleErrorMessage(
        await TPI18N.GetText(RESOURCE_SET, "ErrorTitleMessage")
      );
      atLeastOneError = true;
    } else {
      setTitleErrorMessage("");
    }

    // Validación del campo `language`
    if (!formState.language.trim()) {
      setLanguageErrorMessage(
        await TPI18N.GetText(RESOURCE_SET, "ErrorLanguageMessage")
      );
      atLeastOneError = true;
    } else {
      setLanguageErrorMessage("");
    }

    // Validación del campo `template`
    if (!formState.template.trim() || formState.template.trim() === "--") {
      setTemplateErrorMessage(
        await TPI18N.GetText(RESOURCE_SET, "ErrorTemplateMessage")
      );
      setIsTemplateError(true);
      atLeastOneError = true;
    } else {
      setTemplateErrorMessage("");
      setIsTemplateError(false);
    }

    return !atLeastOneError;
  };

  const handleSaveOrEdit = useCallback(async () => {
    const valid = await validateForm();
    if (!valid) return;
    if (selectedAnswer) {
      editAnswer();
    } else {
      saveAnswer();
    }
  }, [selectedAnswer, saveAnswer, editAnswer]);

  const renderFolderTree = useCallback(
    (nodes: any[], depth = 0) => {
      return nodes
        .filter(({ type }) => type === "Folder")
        .map((node) => (
          <div key={node.guidId}>
            <SidebarItem
              selected={selectedFolder?.guidId === node.guidId}
              onClick={() =>
                selectFolder(
                  selectedFolder?.guidId === node.guidId ? null : node
                )
              }
            >
              {Array.from({ length: depth }).map((_, idx) => (
                <TPIcon key={idx} iconType={TPIconTypes.doubleArrowRight} />
              ))}
              <TPIcon iconType={TPIconTypes.Folder} />
              {node.description}
            </SidebarItem>

            {isAddingFolder && selectedFolder?.guidId === node.guidId && (
              <SidebarItem selected={false}>
                {Array.from({ length: depth + 1 }).map((_, idx) => (
                  <TPIcon key={idx} iconType={TPIconTypes.doubleArrowRight} />
                ))}
                <TPIcon iconType={TPIconTypes.Folder} />
                <input
                  type="text"
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                  onKeyDown={handleFolderKeyDown}
                  placeholder="New folder name"
                  autoFocus
                  style={{
                    border: "none",
                    outline: "none",
                    fontSize: "14px",
                    fontWeight: "bold",
                    flex: 1,
                  }}
                />
              </SidebarItem>
            )}

            {node.children.length > 0 &&
              renderFolderTree(node.children, depth + 1)}
          </div>
        ));
    },
    [
      selectedFolder,
      selectFolder,
      isAddingFolder,
      newFolderName,
      handleFolderKeyDown,
    ]
  );

  const renderTemplateModal = useMemo(() => {
    if (!templateModalState.isShown) return null;

    return (
      <TPModal
        modalState={{
          titleModal: templateModalState.title,
          acceptLabel: translations.okButtonLabel,
          cancelLabel: translations.cancelButtonLabel,
          isShown: templateModalState.isShown,
          callBackAnswer: closeTemplateModal,
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: templateModalState.content }}
          style={{
            maxHeight: "400px",
            overflowY: "auto",
            padding: "1rem",
            backgroundColor: "#f9f9f9",
            border: "1px solid #ddd",
            borderRadius: "4px",
          }}
        />
      </TPModal>
    );
  }, [templateModalState, translations.okButtonLabel, closeTemplateModal]);

  const renderConfirmationModal = useMemo(() => {
    if (!modalState.isShown) return null;

    return (
      <TPModal
        modalState={{
          titleModal: modalState.title,
          acceptLabel: translations.okButtonLabel || "Yes",
          cancelLabel: translations.cancelButtonLabel || "No",
          isShown: modalState.isShown,
          callBackAnswer: handleModalConfirmation,
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: modalState.bodyContent }}
          style={{
            padding: "1rem",
            backgroundColor: "#f9f9f9",
            borderRadius: "4px",
          }}
        />
      </TPModal>
    );
  }, [modalState, translations, handleModalConfirmation]);

  const minorOptions = useMemo(
    () => [
      {
        key: translations.deleteLabel,
        type: "delete",
        icon: TPIconTypes.delete,
        onOptionChange: handleDeleteOption,
      },
      {
        key: translations.cloneLabel,
        type: "clone",
        icon: TPIconTypes.clone,
        onOptionChange: handleCloneOption,
      },
    ],
    [translations, handleDeleteOption, handleCloneOption]
  );

  const customColumns: ColumnStyles<IFrequentAnswer> = {
    id: ({ item, value }) => (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handleAnswerClick(item);
        }}
        style={{
          color: "purple",
        }}
      >
        {value}
      </a>
    ),
    template: ({ value }) => {
      const MAX_LENGTH = 50;

      const plainText = value.replace(/<[^>]*>/g, "");

      const isLongText = plainText.length > MAX_LENGTH;
      const displayText = isLongText
        ? `${plainText.substring(0, MAX_LENGTH)}...`
        : plainText;

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <p
            style={{
              margin: 0,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {displayText}
          </p>
          <TPIcon
            iconType={TPIconTypes.circleInfo}
            onClick={() => handleTemplateClick("Template", value)}
            style={{
              cursor: "pointer",
              marginLeft: "4px",
            }}
          />
        </div>
      );
    },
    isActive: ({ value }) => (
      <TableChip
        value={value}
        onLabel={translations.yesButtonLabel}
        offLabel={translations.offButtonLabel}
        justify="flex-start"
      />
    ),
    isPublic: ({ value }) => (
      <TableChip
        value={value}
        onLabel={translations.yesButtonLabel}
        offLabel={translations.offButtonLabel}
        justify="flex-start"
      />
    ),
  };

  const handleOpenTagPopup = () => {
    setFormState((prev) => ({
      ...prev,
      tag: "",
      tagGroup: "",
    }));

    setAutocompleteToOptions([]);
    let newAdditionalDataAutoCompleteState = {
      ...additionalDataAutoCompleteState,
    };
    newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
    newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
      "";
    setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);

    setIsOpenTagModal(true);
  };

  const handleAddTagForBody = () => {
    if (!editorRef.current) return;

    const quillEditor = editorRef.current.getEditor();

    const selection = quillEditor.getSelection();

    if (selection) {
      quillEditor.insertText(selection.index, " [" + bodyGroup.tag + "] ");
    } else {
      const editorLength = quillEditor.getLength();
      quillEditor.insertText(editorLength - 1, " [" + bodyGroup.tag + "] ");
    }

    setIsOpenTagModal(false);
  };

  const handleCallbackAnser = (status: boolean, callbackData: any) => {
    setFormState((prev) => ({
      ...prev,
      tag: "",
      tagGroup: "",
    }));

    setAutocompleteToOptions([]);
    let newAdditionalDataAutoCompleteState = {
      ...additionalDataAutoCompleteState,
    };
    newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
    newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
      "";
    setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);

    setIsOpenTagModal(false);
  };

  const handleToOnAutocompleteQuery = async (query: string) => {
    let tasktypeService = new EmailTemplateService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      let responseRequest = await tasktypeService.getAdditionalData(
        "S_CASEDET",
        query,
        false,
        true,
        expectedCodes
      );
      let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
        function (item: { id: any; localizedDescription: any }) {
          return {
            key: item.id,
            value: `${item.localizedDescription}`,
          };
        }
      );
      setAutocompleteToOptions(newToKeyValueList);
      return newToKeyValueList;
    } catch (error) {
      TPLog.Log(
        `Error ${COMPONENT_NAME} handleToOnAutocompleteQuery ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${COMPONENT_NAME} handleToOnAutocompleteQuery ex`);
      return [];
    }
  };

  const handleAutoCompleteTopNClick = async () => {
    let newTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteToTopNOptions.length === 0) {
      newTopNOptions = await handleToOnAutocompleteQuery("");

      if (newTopNOptions.length >= 1) {
        setAutocompleteToTopNOptions([...newTopNOptions]);
        setAutocompleteToOptions([...newTopNOptions]);
        let newAdditionalDataAutoCompleteState = {
          ...additionalDataAutoCompleteState,
        };
        newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
        newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
          "";
        setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);
      }
    } else {
      setAutocompleteToOptions([...autocompleteToTopNOptions]);
      let newAdditionalDataAutoCompleteState = {
        ...additionalDataAutoCompleteState,
      };
      newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
      newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
        "";
      setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);
    }
  };

  const handleToOnAutocompleteKeyDown = (event: any) => {
    //left arrow 37
    //right arror 39
    //enter 13
    //home 36
    //end  35
    if (
      event.keyCode != 37 &&
      event.keyCode != 39 &&
      event.keyCode != 13 &&
      event.keyCode != 35 &&
      event.keyCode != 36
    ) {
      setAutocompleteToOptions([]);
      let newAdditionalDataAutoCompleteState = {
        ...additionalDataAutoCompleteState,
      };
      newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
      newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
        "";
      setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);
    }
  };

  const handleToChange = (newSelectedValue: Array<TPKeyValue>) => {
    let newAdditionalDataAutoCompleteState = {
      ...additionalDataAutoCompleteState,
    };
    newAdditionalDataAutoCompleteState.additionalDataAutoComplete =
      newSelectedValue;
    newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
      "";
    setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);
  };

  const handleIconClick = (event: TPIconTypes) => {
    if (event === TPIconTypes.loop) handleReset();
  };

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setPreview("");
      setActiveTab(newValue);
    },
    []
  );

  const handlePreviewClick = async () => {
    const service = new FrequentAnswerServices();
    let caseId = "";

    try {
      setIsLoading(true);
      if (caseType === "random") {
        const randomCaseResponse = await service.getRandomCase(false, true, [
          200,
        ]);
        caseId = randomCaseResponse[0].caseId || "";
      } else if (caseType === "custom" && caseNumber.trim()) {
        caseId = caseNumber.trim();
      }

      if (caseId) {
        const renderResponse = await service.renderFrequentAnswerTemplate(
          {
            InputText: TPGlobal.stringToUTF8(formState.template).toString(),
            CaseId: String(caseId),
            LanguageId: folderLocation,
            UserSessionGuid: TPGlobal.currentUserGuid,
          },
          false,
          true,
          [200]
        );

        setPreview(renderResponse.renderedText);
      } else {
        showToast("Please enter a valid case number.", TPToastTypes.error);
        setPreview(formState.template);
      }
    } catch (error) {
      setPreview(formState.template);
      TPLog.Log(
        `Error ${COMPONENT_NAME} error handlePreviewClick ex `,
        TPLogType.ERROR,
        error
      );
      console.error("Error during preview:", error);
      showToast(
        "An unexpected error occurred while generating the preview. Please try again later.",
        TPToastTypes.error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setFormState({
      id: "",
      title: "",
      language: "",
      folder: "",
      template: "",
      tag: "",
      tagGroup: "",
    });
  };

  const resetErrorMessages = () => {
    setIdErrorMessage("");
    setTitleErrorMessage("");
    setLanguageErrorMessage("");
    setTemplateErrorMessage("");
    setIsTemplateError(false);
  };
  const resetFormAndErrors = () => {
    resetForm();
    resetErrorMessages();
  };

  return (
    <ContentVerticalNoTabsStyled>
      <TPLoadingOverlay active={isLoading}>
        <TPPageTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "15px",
          }}
        >
          <div>
            {!isNewEditAnswer
              ? translations.pageTitle
              : selectedAnswer
                ? translations.editPageTitle
                : translations.newPageTitle}
          </div>

          {isNewEditAnswer ? (
            <TPButton
              type={TPButtonTypes.link}
              onClick={() => {
                setIsNewEditAnswer(false);
                setSelectedAnswer(null);
                resetFormAndErrors();
              }}
            >
              {translations.backLabel}
            </TPButton>
          ) : (
            <TPSelect
              id="folderLocation"
              value={folderLocation}
              onChange={(e: any) => setFolderLocation(e.target.value)}
              dataSource={TPGlobal.TPClientAvailableLanguages.map(
                ({ id, name }) => ({
                  key: id,
                  value: name,
                })
              )}
            />
          )}
        </TPPageTitle>

        {isNewEditAnswer ? (
          <>
            <TPHorizontalTabs
              labels={tabLabels}
              activeTab={activeTab}
              onChange={handleTabChange}
            />
            <div className="container-fluid row col-8 mt-2">
              <>
                {activeTab === 0 && (
                  <>
                    <div className="form-group col-6">
                      <TPTextBox
                        id="IdTextBox"
                        onChange={(e: any) =>
                          handleInputChange("id", e.target.value)
                        }
                        value={formState.id}
                        isMandatory
                        labelText={translations.idLabel}
                        placeholder={translations.idLabel}
                        disabled={!!selectedAnswer}
                        errorMessage={idErrorMessage}
                      />
                    </div>

                    <div className="form-group col-6">
                      <TPTextBox
                        id="title"
                        onChange={(e: any) =>
                          handleInputChange("title", e.target.value)
                        }
                        value={formState.title}
                        isMandatory
                        labelText={translations.titleLabel}
                        placeholder={translations.titleLabel}
                        errorMessage={titleErrorMessage}
                      />
                    </div>

                    <div className="form-group col-12">
                      <TPSelect
                        id="language"
                        value={formState.language}
                        onChange={(e: any) =>
                          handleInputChange("language", e.target.value)
                        }
                        dataSource={TPGlobal.TPClientAvailableLanguages.map(
                          ({ id, name }) => ({
                            key: id,
                            value: name,
                          })
                        )}
                        placeholder={translations.languageLabel}
                        labelText={translations.languageLabel}
                        isMandatory
                        errorMessage={languageErrorMessage}
                      />
                    </div>

                    <div className="form-group col-12">
                      <TPSelect
                        id="folder"
                        value={formState.folder}
                        onChange={(e: any) =>
                          handleInputChange("folder", e.target.value)
                        }
                        dataSource={[
                          { key: "", value: `[${translations.rootLabel}]` },
                          ...folderData
                            .filter(({ type }) => type === "Folder")
                            .map(({ guidId, description }) => ({
                              key: guidId,
                              value: description,
                            })),
                        ]}
                        labelText={translations.folderLabel}
                      />
                    </div>

                    <div className="form-group col-12">
                      <TPLabel
                        labelText={translations.templateLabel}
                        isMandatory={true}
                      />
                      <TPEditor
                        referece={(editor: any) => (editorRef.current = editor)}
                        placeholder=""
                        value={formState.template}
                        popUpOpen={handleOpenTagPopup}
                        onChange={(value: any) =>
                          handleInputChange("template", value)
                        }
                        initialValue=""
                        isVisible={true}
                      />
                      {isTemplateError && (
                        <div
                          style={{
                            color: "#DC3545",
                            fontSize: "14px",
                            margin: "4px 0px 0px 0px",
                          }}
                        >
                          {templateErrorMessage}
                        </div>
                      )}
                    </div>

                    <div
                      className="form-group col-12 mt-3"
                      style={{ textAlign: "right" }}
                    >
                      <TPButton
                        type={TPButtonTypes.secondary}
                        onClick={() => {
                          setIsNewEditAnswer(false);
                          setSelectedAnswer(null);
                          resetFormAndErrors();
                        }}
                      >
                        {translations.cancelButtonLabel}
                      </TPButton>

                      <TPButton
                        type={TPButtonTypes.primary}
                        style={{ marginLeft: "10px" }}
                        onClick={handleSaveOrEdit}
                      >
                        {selectedAnswer
                          ? translations.editButtonLabel
                          : translations.saveButtonLabel}
                      </TPButton>
                    </div>
                  </>
                )}
              </>

              <>
                {activeTab === 1 && (
                  <>
                    <div className="form-group col-12">
                      <div
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          padding: "10px",
                          marginBottom: "15px",
                          backgroundColor: "#f9f9f9",
                          minHeight: "100px",
                          overflowY: "auto",
                          whiteSpace: "normal",
                          fontFamily: "inherit",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: preview || formState.template,
                        }}
                      />
                    </div>

                    <div className="form-group col-12">
                      <div style={{ marginBottom: "10px" }}>
                        <label>
                          <input
                            type="radio"
                            name="caseType"
                            value="random"
                            checked={caseType === "random"}
                            onChange={() => setCaseType("random")}
                            style={{ marginRight: "10px" }}
                          />
                          Random case
                        </label>
                      </div>
                      <div>
                        <label>
                          <input
                            type="radio"
                            name="caseType"
                            value="custom"
                            checked={caseType === "custom"}
                            onChange={() => setCaseType("custom")}
                            style={{ marginRight: "10px" }}
                          />
                          Case Number
                          <input
                            type="text"
                            placeholder="####"
                            value={caseNumber}
                            onChange={(e) => setCaseNumber(e.target.value)}
                            style={{
                              marginLeft: "10px",
                              padding: "5px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          />
                        </label>
                      </div>
                    </div>

                    <div
                      className="form-group col-12 mt-3"
                      style={{ textAlign: "right" }}
                    >
                      <TPButton
                        type={TPButtonTypes.secondary}
                        onClick={() => {
                          setIsNewEditAnswer(false);
                          setSelectedAnswer(null);
                          resetFormAndErrors();
                        }}
                      >
                        {translations.cancelButtonLabel}
                      </TPButton>

                      <TPButton
                        type={TPButtonTypes.primary}
                        style={{ marginLeft: "10px" }}
                        onClick={handlePreviewClick}
                      >
                        {translations.previewLabel}
                      </TPButton>
                    </div>
                  </>
                )}
              </>
            </div>
          </>
        ) : (
          <LayoutContainer>
            <Sidebar>
              <IconSection>
                <StyledIcon>
                  <TPIcon
                    iconType={TPIconTypes.AddFolder}
                    onClick={startAddingFolder}
                  />
                </StyledIcon>

                {!!selectedFolder && (
                  <>
                    <StyledIcon>
                      <TPIcon
                        iconType={TPIconTypes.edit}
                        onClick={handleEdit}
                      />
                    </StyledIcon>

                    <StyledIcon>
                      <TPIcon
                        iconType={TPIconTypes.delete}
                        onClick={handleDelete}
                      />
                    </StyledIcon>
                  </>
                )}
              </IconSection>

              <Divider />

              {renderFolderTree(folderTreeData)}

              {isAddingFolder && !selectedFolder && (
                <SidebarItem selected={false}>
                  <TPIcon iconType={TPIconTypes.Folder} />
                  <input
                    type="text"
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                    onKeyDown={handleFolderKeyDown}
                    placeholder="New folder name"
                    autoFocus
                    style={{
                      border: "none",
                      outline: "none",
                      fontSize: "14px",
                      fontWeight: "bold",
                      flex: 1,
                    }}
                  />
                </SidebarItem>
              )}
            </Sidebar>

            <MainContent>
              <DynamicTable
                data={folderData.filter(
                  ({ type, parent_GuidId }) =>
                    type === "Content" &&
                    (selectedFolder
                      ? selectedFolder?.guidId === parent_GuidId
                      : !parent_GuidId)
                )}
                hiddenColumns={["guidId", "language", "parent_GuidId", "type"]}
                minorOptions={minorOptions}
                columnStyles={customColumns}
                onIconClicked={(event) => handleIconClick(event)}
                withPreferences
              >
                <TPButton
                  onClick={() => {
                    setSelectedAnswer(null);
                    setIsNewEditAnswer(true);
                  }}
                  type={TPButtonTypes.primary}
                >
                  {translations.newFrequentAnswer}
                </TPButton>
              </DynamicTable>
            </MainContent>
          </LayoutContainer>
        )}
        <TPModalDelete
          bodyContent={translations.modalConfirmQuestion
            .replace("{{0}}", selectedFolder?.description || "this folder")
            .replace(
              "{{1}}",
              String(
                folderData.filter(
                  ({ type, parent_GuidId }) =>
                    type === "Content" &&
                    parent_GuidId === modalQuestionState.callBackData?.guidId
                ).length
              )
            )}
          yesLabel={translations.yesButtonLabel}
          noLabel={translations.cancelButtonLabel}
          isShown={modalQuestionState.isShown}
          callBackData={modalQuestionState.callBackData}
          callBackAnswer={handleCallBackModalConfirm}
        />

        <TPModal
          modalState={{
            titleModal: translations.modalEditTitle,
            acceptLabel: translations.yesButtonLabel,
            cancelLabel: translations.cancelButtonLabel,
            callBackAnswer: (confirmed: boolean) =>
              handleCallbackAnswerEditModal(
                confirmed,
                modalEditState.callBackData
              ),
            isShown: modalEditState.isShown,
          }}
        >
          <div className="form-group">
            <label htmlFor="folderName" className="tpbold">
              {translations.folderNameLabel}
            </label>
            <input
              id="folderName"
              type="text"
              className="form-control"
              value={modalEditState.newName}
              onChange={(e) =>
                setModalEditState((prev) => ({
                  ...prev,
                  newName: e.target.value,
                }))
              }
              placeholder="Enter folder name"
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="folderLocation" className="tpbold">
              {translations.folderLocationLabel}
            </label>
            <TPSelect
              id="folderLocation"
              value={modalEditState.newLocation || ""}
              onChange={(e: any) =>
                setModalEditState((prev) => ({
                  ...prev,
                  newLocation: e.target.value,
                }))
              }
              dataSource={[
                {
                  key: "",
                  value: `[${translations.rootLabel}]`,
                },
                ...folderData
                  .filter(
                    ({ type, guidId }) =>
                      type === "Folder" &&
                      guidId !== modalEditState.callBackData?.guidId
                  )
                  .map(({ guidId, description }) => ({
                    key: guidId,
                    value: description,
                  })),
              ]}
            />
          </div>
        </TPModal>

        {renderTemplateModal}

        {renderConfirmationModal}

        <TPModal
          modalState={{
            titleModal: `${"Available tags for selection"}`,
            acceptLabel: translations.okButtonLabel,
            hideFooterButtons: true,
            cancelLabel: translations.cancelButtonLabel,
            callBackAnswer: handleCallbackAnser,
            callBackData: TPGlobal.foo,
            isShown: isOpenTagModal,
            modalWidth: undefined,
          }}
        >
          <div style={{ height: "300px", overflow: "auto" }}>
            <TPSelect
              id="IdSelect"
              isMandatory={true}
              labelText={"Tag Group"}
              onChange={(e: any) =>
                handleInputChange("tagGroup", e.target.value)
              }
              dataSource={tagGroups}
              value={formState.tagGroup}
            />
            <TPSelect
              id="IdSelect"
              isMandatory={true}
              labelText={"Tag"}
              onChange={(e: any) => handleInputChange("tag", e.target.value)}
              dataSource={tags}
              value={formState.tag}
            />
            {formState.tagGroup === "S_TGCASE" &&
            formState.tag === "AdditionalData" ? (
              <TPAutoComplete
                isMandatory={false}
                labelText={"Select additional data"}
                onValueChange={handleToChange}
                onSearch={(query: string) => {
                  handleToOnAutocompleteQuery(query);
                }}
                isLoading={false}
                options={autocompleteToOptions}
                withIcon={true}
                emptyLabel={"emptyLabel"}
                onKeyDown={handleToOnAutocompleteKeyDown}
                selected={
                  additionalDataAutoCompleteState.additionalDataAutoComplete
                }
                errorMessage={
                  additionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage
                }
                downArrowClick={handleAutoCompleteTopNClick}
              />
            ) : (
              ""
            )}

            <div style={{ textAlign: "right", marginTop: "18px" }}>
              <TPButton
                type={TPButtonTypes.primary}
                onClick={handleAddTagForBody}
              >
                {"Select"}
              </TPButton>
            </div>
          </div>
        </TPModal>
      </TPLoadingOverlay>
    </ContentVerticalNoTabsStyled>
  );
});

export default FrequentAnswers;
