import { FC, useEffect, useState } from "react";
import TPCustomAutocomplete from "../TPCustomAutocomplete/TPCustomAutocomplete";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import { BranchService } from "@/services/BranchService";
import "./TPBranchSelectorStyles.css";
import TPModalTreeView from "@/layouts/TPModalTreeView/TPModalTreeView";
import { v4 as uuidv4 } from "uuid";
import { TPTreeViewDataModel } from "../TPTreeView/TPTreeView";

interface TPBranchSelectorBranch {
  id: string;
  description: string;
  hierarchyDescription: string;
}

interface TPBranchSelectorProps {
  value: TPBranchSelectorBranch;
  onChange: (branch: TPBranchSelectorBranch) => void;
  treeId: string;
  parentId?: string;
  label?: string | React.ReactElement;
  emptyLabel?: string;
  errorMessage?: string;
  isMandatory?: boolean;
  onTreeLoad?: (loading: boolean) => void;
  mustSelectLastLevelBranch?: boolean;
  autoCloseModalOnSelect?: boolean;
  placeholder?: string;
  modalTitle?: string;
  modalAcceptLabel?: string;
  modalCancelLabel?: string;
  disabled?: boolean;
}

export const TPBranchSelector: FC<TPBranchSelectorProps> = function({ 
  value, 
  onChange, 
  treeId,
  parentId,
  modalAcceptLabel,
  modalCancelLabel,
  modalTitle,
  mustSelectLastLevelBranch = false,
  autoCloseModalOnSelect = false,
  label,
  isMandatory = false,
  errorMessage,
  onTreeLoad,
  disabled,
  emptyLabel,
  placeholder
}) {
  const [selectedBranch, setSelectedBranch] = useState<TPBranchSelectorBranch>(value);
  const [branchOptions, setBranchOptions] = useState<BranchViewModel[]>([]);
  const [inputQuery, setInputQuery] = useState("");
  const [branchTreeModalVisible, setBranchTreeModalVisible] = useState(false);
  const [treeDataSource, setTreeDataSource] = useState<TPTreeViewDataModel[]>([]);

  const getBranchChildren = async function(branch: BranchViewModel) {
    if (branch.hasChild) {
      onTreeLoad && onTreeLoad(true);
      const branchServiceInstance = new BranchService();
      const newChildren = await branchServiceInstance
        .getChildBranches(branch.id,1,1,false,true,[200,404]);
    
      onTreeLoad && onTreeLoad(false);
      if (!newChildren) return;
      setBranchOptions(prevBranches => [...prevBranches, ...newChildren])
      newChildren.forEach(c => getBranchChildren(c))
    }
  }

  const getBranches = async function() {
    onTreeLoad && onTreeLoad(true);
    const branchServiceInstance = new BranchService();
    let newBranches: BranchViewModel[] = parentId ? 
      await branchServiceInstance.getChildBranches(parentId,1,1,false,true,[200, 404])
      :
      await branchServiceInstance.getFirstLevelBranches(treeId,1,1,false,true,[200, 404])

    setBranchOptions(newBranches);
    onTreeLoad && onTreeLoad(false);
    newBranches.forEach(b => getBranchChildren(b))
  }

  useEffect(() => {
    getBranches();
    if (value) setSelectedBranch(value);
  }, [])

  useEffect(() => {
    setTreeDataSource(branchOptions.map(b => ({
      hasChild: b.hasChild,
      hierarchyDescription: b.hierarchyDescription,
      hierarchyIds: b.hierarchyIds,
      id: b.id,
      isActive: b.isActive,
      isExpanded: false,
      isVisible: b.isVisible,
      label: b.localizedDescription || b.description || '???',
      parentId: b.parentId || "",
    })));
  }, [branchOptions])

  useEffect(() => {
    if (value.id)
      setSelectedBranch(value);
  }, [value])

  useEffect(() => {
    if (selectedBranch)
      setInputQuery(selectedBranch.hierarchyDescription);
  }, [selectedBranch])

  const handleSelectBranch = function(branch: TPBranchSelectorBranch) {
    setSelectedBranch(branch);
    onChange(branch);
    setInputQuery(branch.hierarchyDescription || "");
  }

  return (
    <div>
      <TPModalTreeView 
        acceptLabel={modalAcceptLabel || ""}
        cancelLabel={modalCancelLabel || ""}
        callBackAnswer={(confirmed: boolean, id: string, label: string, hierarchy: string) => {
          if (confirmed) {
            const fixedObject: TPBranchSelectorBranch = {
              description: label,
              hierarchyDescription: hierarchy,
              id: id
            }
            handleSelectBranch(fixedObject);
          }
          setBranchTreeModalVisible(false);
        }}
        autoCloseWhenSelect={autoCloseModalOnSelect}
        isShown={branchTreeModalVisible}
        selectedNodeIdPlusDescription={selectedBranch?.id || ""}
        title={modalTitle || ""}
        treeProps={{
          dataSource: treeDataSource,
          filterIsActive: 1,
          filterIsVisible: 1,
          guidTree: "tree" + uuidv4().replaceAll("-", ""),
          handleChangeNodeCallBack: () => {},
          mustSelectLastLevelBranch: mustSelectLastLevelBranch,
          renderParent: "",
          isAdminMode: false,
          selectedHierarchyLabel: selectedBranch?.hierarchyDescription || "",
          selectedLabel: selectedBranch?.description || "",
          selectedNode: selectedBranch?.id || "",
          setDataSource: (newDataSource: TPTreeViewDataModel[]) => setTreeDataSource(newDataSource),
          setIsLoading: () => {},
          setSelectedHierarchyLabel: () => {},
          setSelectedLabel: () => {},
          setSelectedNode: () => {},
          treeId: treeId
        }}
      />
      {label && (
        <div className="tp-branch-selector-label">
          {label}
          {isMandatory && (<b style={{color:"red", fontSize: "13px"}}>*</b>)}
        </div>
      )}
      <TPCustomAutocomplete
        input={inputQuery}
        onQuery={text => {
          if (selectedBranch.id) {
            handleSelectBranch({
              description: "",
              hierarchyDescription: "",
              id: ""
            });
          } else {
            setInputQuery(text);
          }
        }}
        icon={TPIconTypes.accountTree}
        onIconClick={() => setBranchTreeModalVisible(true)}
        errorMessage={errorMessage}
        placeholder={placeholder}
        disabled={disabled}
      >
        {branchOptions.map(option => (
          <button 
            key={option.id+option.hierarchyDescription}
            className="tp-branch-selector-option"
            onClick={(e) => {
              e.stopPropagation();
              handleSelectBranch({
                description: option.localizedDescription || option.description || "???",
                hierarchyDescription: option.hierarchyDescription || "???",
                id: option.id
              })
            }}
          >
            <div className="tp-branch-selector-option-alt">
              {option.localizedDescription || option.description}
            </div>
            {option.hierarchyDescription}
          </button>
        ))}
      </TPCustomAutocomplete>
    </div>
  )
}