import { TPFormControlContainerStyled } from "@/helpers/generalStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { FC, forwardRef, useId, useRef } from "react";
import { Input } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import TPLabel from "../bootstrap/forms/TPLabel/TPLabel";
import { InputStyled } from "./TPDatePickerStyles";
import { ComplementsRenderTP } from "../../helpers/TPKeyValue";

type TPDatePickerProps = {
    labelText: string | JSX.Element;
    isHorizontal?: boolean;
    isMandatory: boolean;
    selectedDate?: Date | null;
    onChangeDate?: Function | null;
    minDate?: Date | null | undefined;
    maxDate?: Date | null | undefined;
    customDateFormat?: string | null;
    disabled?: boolean;
    children?: JSX.Element;
    errorMessage?: string;
    containerStyle?: any;
    inputStyle?: React.CSSProperties;
    labelStyle?: any;
    isTextBefore?: boolean;
    complementsRenderTP?: ComplementsRenderTP;
};

const TPDatePicker: FC<TPDatePickerProps> = ({
    labelText,
    selectedDate = null,
    onChangeDate,
    isHorizontal = false,
    isMandatory,
    minDate = null,
    maxDate = null,
    disabled,
    customDateFormat = TPGlobal.dateFormat,
    children = null,
    errorMessage = "",
    containerStyle,
    inputStyle,
    labelStyle,
    isTextBefore = true,
    complementsRenderTP
}) => {
    const theId = `textbox-${useId()}`;
    const handleOnchange = (date: Date) => {
        if (onChangeDate) {
            onChangeDate(date);
        }
    };
    const DateCustomImput = forwardRef((props: any, ref) => {
        return (
            <>
                <TPFormControlContainerStyled
                    isHorizontal={isHorizontal}
                    className={`${true ? "input-group tp-padding-right-0" : ""} ${errorMessage != "" ? "is-invalid" : ""
                        }`}
                    style={containerStyle}
                >
                    <InputStyled
                        {...props}
                        className={`form-control ${errorMessage ? "is-invalid" : ""}`}
                        ref={ref}
                        style={{
                            ...inputStyle,
                            border: errorMessage ? "1px solid red" : "",
                        }}
                    />
                   
                    <div className="input-group-append" style={{ paddingRight: "5px" }}>
                        <span>
                            <TPIcon
                                iconType={TPIconTypes.calendarToday}
                                onClick={props.onClick}
                            />
                        </span>
                    </div>
                </TPFormControlContainerStyled>
            </>
        );
    });
    const inputRef = useRef(null);
    return (
        <>
            <TPFormControlContainerStyled
                isHorizontal={isHorizontal}
                style={containerStyle}
                className={"mt-0"}
            >
                {isTextBefore && labelText && (
                    <TPLabel
                        htmlFor={theId}
                        isMandatory={isMandatory}
                        labelText={labelText}
                        style={labelStyle}
                    />
                )}
                <div className={`${errorMessage != "" ? "is-invalid" : ""}`}>
                    <DatePicker
                        selected={selectedDate ?? null}
                        onChange={handleOnchange}
                        minDate={minDate}
                        maxDate={maxDate}
                        disabled={disabled}
                        dateFormat={customDateFormat ?? undefined}
                        customInput={<DateCustomImput ref={inputRef}></DateCustomImput>}
                        placeholderText={complementsRenderTP? complementsRenderTP?.placeholder:""}
                    >
                        {children ?? null}
                    </DatePicker>
                </div>

                {!isTextBefore && labelText && (
                    <TPLabel
                        htmlFor={theId}
                        isMandatory={isMandatory}
                        labelText={labelText}
                        style={labelStyle}
                    />
                )}
                {errorMessage != "" && (
                    <div className="invalid-feedback">{errorMessage}</div>
                )}
            </TPFormControlContainerStyled>
        </>
    );
};

export default TPDatePicker;
