import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";
import { useEffect, useState } from "react";

const RESOURCE_SET = "TaskReminder";

interface TaskReminderLabels {
  Create: string;
  Update: string;
  Date: string;
  Time: string;
  Comments: string;
  Cancel: string;
  Save: string;
  NewReminder: string;
  Reminder: string;
  AddNewReminder: string;
  EditReminder: string;
  DeleteReminder: string;
  DeleteConfirm: string;
  Yes: string;
  No: string;
  CantChoosePastDate: string;
}

export const useTaskReminderLabels = function () {

  const [labels, setLabels] = useState<TaskReminderLabels>({
    AddNewReminder: "",
    Cancel: "",
    CantChoosePastDate: "",
    Comments: "",
    Create: "",
    Date: "",
    DeleteConfirm: "",
    DeleteReminder: "",
    EditReminder: "",
    NewReminder: "",
    No: "",
    Reminder: "",
    Save: "",
    Time: "",
    Update: "",
    Yes: "",
  });

  const loadLabels = async function () {
    setLabels({
      Update: await TPI18N.GetText(RESOURCE_SET, "Update"),
      Cancel: await TPI18N.GetText(RESOURCE_SET, "Cancel"),
      Comments: await TPI18N.GetText(RESOURCE_SET, "Comments"),
      Create: await TPI18N.GetText(RESOURCE_SET, "Create"),
      Date: await TPI18N.GetText(RESOURCE_SET, "Date"),
      Save: await TPI18N.GetText(RESOURCE_SET, "Save"),
      Time: await TPI18N.GetText(RESOURCE_SET, "Time"),
      AddNewReminder: await TPI18N.GetText(RESOURCE_SET, "AddNewReminder"),
      DeleteConfirm: await TPI18N.GetText(RESOURCE_SET, "DeleteConfirm"),
      DeleteReminder: await TPI18N.GetText(RESOURCE_SET, "DeleteReminder"),
      EditReminder: await TPI18N.GetText(RESOURCE_SET, "EditReminder"),
      NewReminder: await TPI18N.GetText(RESOURCE_SET, "NewReminder"),
      No: await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"),
      Reminder: await TPI18N.GetText(RESOURCE_SET, "Reminder"),
      Yes: await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes"),
      CantChoosePastDate: await TPI18N.GetText(RESOURCE_SET, "CantChoosePastDate"),
    })
  }

  useEffect(() => {
    loadLabels();
  }, [])

  return { labels }
}