import { showToast } from "@/components/bootstrap/components/toasts/TPToast";
import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { ComponentResponse, MenuDefinitionCreationModel, MenuDefinitionListResponseModel, MenuDefinitionMenuResponse, MenuLevels, MenuOnLevel, MenuReorderModel } from "@/models/MenuItems/MenuItemsModels";
import { useMenuDefinitionLabels } from "@/pages/MenuDefinition/Assets/MenuDefinitionLabels";

export class MenuDefinitionService {

  private static serviceFileName = "MenuDefinitionService";

  public static async getAllMenus(
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<Array<MenuDefinitionListResponseModel>> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/menuitem/allmenuitemwithprofile');
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} getAllMenus exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to get all menus: ${error}`);
    }
  }

  public static async getAllMenusOnLevel(
    level: MenuLevels,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<Array<MenuOnLevel>> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/menuitem/allmenubylevel/', level);
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} getAllMenusOnLevel exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to get all menus on level ${level}: ${error}`);
    }
  }

  public static async getAllComponents(
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<Array<ComponentResponse>> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/component/all');
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} getAllComponents exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to get all menu components: ${error}`);
    }
  }

  public static async createMenu(
    requestBody: MenuDefinitionCreationModel,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<HTTPResponse> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/menuitem');
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      return await httpClientInstance.postData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} createMenu exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to create a menu: ${error}`);
    }
  }

  public static async getById(
    menuId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<MenuDefinitionMenuResponse> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/menuitem/menuitembyidwithprofiles/',menuId);
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} getAllComponents exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to get all menu components: ${error}`);
    }
  }

  public static async updateMenu(
    requestBody: MenuDefinitionCreationModel,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<HTTPResponse> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/menuitem');
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      return await httpClientInstance.putData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} updateMenu exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to update a menu: ${error}`);
    }
  }

  public static async reorderMenu(
    requestBody: MenuReorderModel,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<HTTPResponse> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/menuitem/updateorder');
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      return await httpClientInstance.putData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} reorderMenu exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to reorder a menu: ${error}`);
    }
  }

  public static async reorderMassiveMenus( // Scary!
    requestBody: { orders: MenuReorderModel[] },
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<HTTPResponse> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/menuitem/updateallorder');
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      return await httpClientInstance.putData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} reorderMenu exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to reorder a menu: ${error}`);
    }
  }

  public static async deleteById(
    menuId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<HTTPResponse> {
    const httpClientInstance = new TPHTTPService();
    const url = String(TPGlobal.baseAPIGateway)
      .concat('api/tenant/', TPGlobal.tenantHex, '/menuitem/',menuId);
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      return await httpClientInstance.deleteData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} deleteById exception`, TPLogType.ERROR, error);
      console.error(error);
      throw new Error(`Unexpected error while trying to delete a menu: ${error}`);
    }
  }
}