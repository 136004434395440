import { FC, useEffect, useRef, useState } from "react";
import * as availableIcons from "react-icons/md";
import "./TPIconStyledSelectStyles.css";
import { IconType } from "react-icons";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPTooltip } from "../TPTooltip/TPTooltip";
import { Tooltip } from "@mui/material";
import { TPI18N } from "@/services/I18nService";
import TPGlobal from "@/helpers/TPGlobal";

interface TPIconStyledSelectProperties {
  selectedIcon: string;
  onIconSelected: (icon: string) => void;
  width?: string | number;
  label?: string;
  placeholder?: string;
}

const MAX_INITIAL_ICON_DISPLAY = 250;

export const TPIconStyledSelect: FC<TPIconStyledSelectProperties> = function ({
  selectedIcon = "MdAddReaction",
  onIconSelected,
  width,
  label,
  placeholder
}) {
  const [query, setQuery] = useState("");
  const [chosenIcon, setChosenIcon] = useState<string>(selectedIcon);
  const iconMenuRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const [iconMenuVisible, setIconMenuVisible] = useState(false);
  const [searchLabel, setSearchLabel] = useState("");
  const [selectLabel, setSelectLabel] = useState("");

  const handleClickOutside = function (e: MouseEvent) {
    if (
      (iconMenuRef.current && !iconMenuRef.current.contains(e.target)) &&
      (inputRef.current && !inputRef.current.contains(e.target))) {
      setIconMenuVisible(false);
    }
  }

  const getResources = async function () {
    setSearchLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "SearchIcons"));
    setSelectLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Select"));
  }

  const clearIcon = function (e: React.MouseEvent) {
    e.stopPropagation();
    onIconSelected("");
    setChosenIcon("");
  }

  useEffect(() => {
    getResources();
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [])

  useEffect(() => {
    setChosenIcon(selectedIcon);
  }, [selectedIcon])

  return (
    <div
      style={{
        width: width || "100%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        position: "relative"
      }}
    >
      <div>
        {label && (<label style={{ fontSize: "12px", fontWeight: "500" }}>{label}</label>)}
        <div
          className="tp-icon-styled-select-main-input"
          style={{ width: "100%" }}
          ref={inputRef}
        >
          <button
            type="button"
            className="tp-icon-styled-select-main-input-button"
            onClick={() => setIconMenuVisible(!iconMenuVisible)}
          >
            {chosenIcon || placeholder || selectLabel}
            {Boolean(selectedIcon || chosenIcon) &&
              <>
                {Boolean(availableIcons[(selectedIcon || chosenIcon) as keyof IconType]) &&
                  (availableIcons[(selectedIcon || chosenIcon) as keyof IconType] as IconType)({ fontSize: "24px", color: "#2E2E2E" })}
                <button
                  className="tp-icon-styled-select-remove-button"
                  onClick={(e) => clearIcon(e)}
                >
                  <TPIcon iconType={TPIconTypes.cross} style={{ fontSize: "inherit" }} />
                </button>
              </>}
          </button>
        </div>
      </div>
      <div
        className={`tp-icon-styled-select-container ${iconMenuVisible ? 'shown' : ''}`}
        ref={iconMenuRef}
      >
        <div className="tp-icon-styled-select-query-input-container">
          <TPIcon iconType={TPIconTypes.search} style={{ fontSize: "24px" }} />
          <input
            type="text"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            className="tp-icon-styled-select-query-input"
            placeholder={searchLabel.replace('x', String(Object.keys(availableIcons).length))}
          />
        </div>
        <div className="tp-icon-styled-select-floating-menu">
          {Object.keys(availableIcons)
            .filter(k => query.length > 0 ? k.toLowerCase().includes(query.toLowerCase()) : true)
            .slice(0, MAX_INITIAL_ICON_DISPLAY)
            .map((key, idx) => {
              const icon = availableIcons[key as keyof IconType] as IconType;
              return (
                <Tooltip title={key}>
                  <button
                    key={idx}
                    type="button"
                    className="tp-icon-styled-select-icon-button"
                    onClick={() => {
                      onIconSelected(key);
                      setChosenIcon(key);
                      setIconMenuVisible(false);
                    }}
                  >
                    {icon({ fontSize: "28px" })}
                  </button>
                </Tooltip>
              );
            })}
        </div>
      </div>
    </div>
  )
}