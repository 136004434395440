import TPGlobal from "@/helpers/TPGlobal";
import { ComplementsRenderTP, TPKeyValue } from "@/helpers/TPKeyValue";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import {
    AdditionalDataDateTypeEnum,
    AdditionalDataOpenTextMultilineTypesEnum,
    AdditionalDataOpenTextTypesEnum,
    AdditionalDataTypeEnum,
} from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import userEvent from "@testing-library/user-event";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import TPAdditionalDataAttachment from "./TPAdditionalDataAttachment";
import TPAdditionalDataDate from "./TPAdditionalDataDate";
import TPAdditionalDataEmail from "./TPAdditionalDataEmail";
import TPAdditionalDataLabel from "./TPAdditionalDataLabel";
import TPAdditionalDataLink from "./TPAdditionalDataLink";
import TPAdditionalDataNumeric from "./TPAdditionalDataNumeric";
import TPAdditionalDataNumericList from "./TPAdditionalDataNumericList";
import TPAdditionalDataOpenText from "./TPAdditionalDataOpenText";
import TPAdditionalDataOpenTextMultiline from "./TPAdditionalDataOpenTextMultiline";
import TPAdditionalDataPhone from "./TPAdditionalDataPhone";
import { TPAddtionalDataUIModes } from "./TPAdditionalDataUImodes";
import TPAdditionalDataValueListBranch, {
    AdditionalDataValueListBranchRenderMethodEnum,
} from "./TPAdditionalDataValueListBranch";
import TPAdditionalDataYesNo, {
    AdditionalDataYesNoCaption,
    AdditionalDataYesNoRenderMethodEnum,
} from "./TPAdditionalDataYesNo";


type TPRenderAdditionalDataSwitchProps = {
    guidControl: string;
    itemToRender: TPKeyValue; //key:IdOfAdditionalData, value:ValueOfAdditionalData,value2:AdditionalDataViewModel
    defaultValue?: any;
    modeUI: TPAddtionalDataUIModes;
    complementsRenderTP?: ComplementsRenderTP;
    onChange?: Function;
};

const TPRenderAdditionalDataSwitch = React.forwardRef(
    (
        {
            guidControl,
            itemToRender,
            defaultValue = "",
            complementsRenderTP,
            modeUI,
            onChange,
        }: TPRenderAdditionalDataSwitchProps,
        ref,
    ) => {

        //#region Init
        const localRef: any = useRef(null);
        const [changed, setChanged] = useState(true);

        const [
            badConfigurationErrorMessageLabel,
            setBadConfigurationErrorMessageLabel,
        ] = useState("");

        useImperativeHandle(ref, () => ({
            getValueFromParent() {
                if (localRef) {
                    return localRef.current.getValueFromParent();
                } else {
                    return null;
                }
            },
            validateFromParent() {
                if (localRef.current) {
                    return localRef.current.validateFromParent();
                } else {
                    return null;
                }
            },
        }));

        //#endregion Init

        const loadResources = async () => {
            setBadConfigurationErrorMessageLabel(
                await TPI18N.GetText(
                    TPGlobal.globalResourceSet,
                    "BadConfigurationErrorMessage",
                ),
            );
        };

        const handleChangeAditionalDataValue = (
            idControl: string,
            newValue: any,
            additionalDataId: string,
        ) => {
            if (onChange) {
                onChange(idControl, newValue, additionalDataId);
            }
        };

        const renderAdditionalDataItem = (
            guid: string,
            item: TPKeyValue,
            value: string,
            mode: TPAddtionalDataUIModes
        ) => {          
            let itemAdditionalData: AdditionalDataViewModel;
            let jsxElement: any;
            let jsonData: any = {};
            let idControl: string;
            let isParameterTreeTemp: boolean = false;            
            itemAdditionalData = item.value2 as AdditionalDataViewModel;         
            idControl = "control" + guid + itemAdditionalData.id;
            if (itemAdditionalData.jsonParameters) {
                jsonData = JSON.parse(itemAdditionalData.jsonParameters);

                if (jsonData.treeId && jsonData.treeId === TPGlobal.AdditionalDataTypes.TEXTTREEE) {        
                    isParameterTreeTemp = true;
                }
            }
            switch (itemAdditionalData.additionalDataTypeId) {
                case AdditionalDataTypeEnum.attachment:
                    jsxElement = (
                        <TPAdditionalDataAttachment
                            isMandatory={itemAdditionalData.isMandatory}
                            idControl={idControl}
                            modeUI={mode}
                            labelText={
                                itemAdditionalData.localizedDescription
                                    ? itemAdditionalData.localizedDescription
                                    : itemAdditionalData.description
                            }
                            defaultValue={value}
                            ref={(element: any) => {
                                localRef.current = element;
                            }}
                            guidControl={guidControl}
                            onValueChange={(newValue: string, idControl: any) => {                               
                                if (mode === TPAddtionalDataUIModes.Collect) {
                                    handleChangeAditionalDataValue(idControl, newValue, item.key);
                                } else {
                                    TPGlobal.foo();
                                }
                            }}
                        />
                    );
                    return jsxElement;
                case AdditionalDataTypeEnum.textbox:
                    jsxElement = (
                        <TPAdditionalDataOpenText
                            subType={jsonData.type as AdditionalDataOpenTextTypesEnum}
                            isMandatory={itemAdditionalData.isMandatory}
                            idControl={idControl}
                            modeUI={mode}
                            labelText={
                                itemAdditionalData.localizedDescription
                                    ? itemAdditionalData.localizedDescription
                                    : itemAdditionalData.description
                            }
                            maxLength={jsonData.maxLength}
                            defaultValue={value}
                            ref={(element: any) => {
                                localRef.current = element;
                            }}
                            onValueChange={(newValue: string, idControl: any) => {
                                if (mode === TPAddtionalDataUIModes.Collect) {
                                    handleChangeAditionalDataValue(idControl, newValue, item.key);
                                } else {
                                    TPGlobal.foo();
                                }
                            }}
                            complementsRenderTP={complementsRenderTP}
                            whiteList={jsonData.whiteList}
                        />
                    );
                    return jsxElement;
                case AdditionalDataTypeEnum.textarea:
                    jsxElement = (
                        <TPAdditionalDataOpenTextMultiline
                            subType={
                                jsonData.type as AdditionalDataOpenTextMultilineTypesEnum
                            }
                            isMandatory={itemAdditionalData.isMandatory}
                            idControl={idControl}
                            modeUI={mode}
                            labelText={
                                itemAdditionalData.localizedDescription
                                    ? itemAdditionalData.localizedDescription
                                    : itemAdditionalData.description
                            }
                            maxLength={jsonData.maxLength}
                            defaultValue={value}
                            ref={(element: any) => {
                                localRef.current = element;
                            }}
                            onValueChange={(newValue: string, idControl: any) => {
                                if (mode === TPAddtionalDataUIModes.Collect) {
                                    handleChangeAditionalDataValue(idControl, newValue, item.key);
                                } else {
                                    TPGlobal.foo();
                                }
                            }}
                            complementsRenderTP={complementsRenderTP}
                            whiteList={jsonData.whiteList}
                            rows={3}
                            cols={7}
                        />
                    );
                    return jsxElement;
                case AdditionalDataTypeEnum.date:
                    jsxElement = (
                        <TPAdditionalDataDate
                            isMandatory={itemAdditionalData.isMandatory}
                            idControl={idControl}
                            modeUI={mode}
                            labelText={
                                itemAdditionalData.localizedDescription
                                    ? itemAdditionalData.localizedDescription
                                    : itemAdditionalData.description
                            }
                            type={
                                jsonData.type
                                    ? jsonData.type
                                    : AdditionalDataDateTypeEnum.dateRange
                            }
                            minDate={jsonData.minDate}
                            maxDate={jsonData.maxDate}
                            defaultValue={value}
                            ref={(element: any) => {
                                localRef.current = element;
                            }}
                            onValueChange={(newValue: Date, idControl: any) => {
                                if (mode === TPAddtionalDataUIModes.Collect) {
                                    handleChangeAditionalDataValue(idControl, newValue, item.key);
                                } else {
                                    TPGlobal.foo();
                                }
                            }}
                            complementsRenderTP={complementsRenderTP}
                        />
                    );
                    return jsxElement;
                case AdditionalDataTypeEnum.email:
                    jsxElement = (
                        <TPAdditionalDataEmail
                            isMandatory={itemAdditionalData.isMandatory}
                            idControl={idControl}
                            modeUI={mode}
                            labelText={
                                itemAdditionalData.localizedDescription
                                    ? itemAdditionalData.localizedDescription
                                    : itemAdditionalData.description
                            }
                            defaultValue={value}
                            ref={(element: any) => {
                                localRef.current = element;
                            }}
                            onValueChange={(newValue: Date, idControl: any) => {
                                if (mode === TPAddtionalDataUIModes.Collect) {
                                    handleChangeAditionalDataValue(idControl, newValue, item.key);
                                } else {
                                    TPGlobal.foo();
                                }
                            }}
                            complementsRenderTP={complementsRenderTP}
                        />
                    );
                    return jsxElement;
                case AdditionalDataTypeEnum.integerlist:
                    jsxElement = (
                        <TPAdditionalDataNumericList
                            isMandatory={itemAdditionalData.isMandatory}
                            idControl={idControl}
                            modeUI={mode}
                            labelText={
                                itemAdditionalData.localizedDescription
                                    ? itemAdditionalData.localizedDescription
                                    : itemAdditionalData.description
                            }
                            defaultValue={value}
                            ref={(element: any) => {
                                localRef.current = element;
                            }}
                            onValueChange={(newValue: Date, idControl: any) => {
                                if (mode === TPAddtionalDataUIModes.Collect) {
                                    handleChangeAditionalDataValue(idControl, newValue, item.key);
                                } else {
                                    TPGlobal.foo();
                                }
                            }}
                            complementsRenderTP={complementsRenderTP}
                            initValue={jsonData.initialValue}
                            endValue={jsonData.endValue}
                        />
                    );
                    return jsxElement;
                case AdditionalDataTypeEnum.label:
                    jsxElement = (
                        <TPAdditionalDataLabel
                            idControl={idControl}
                            modeUI={mode}
                            labelText={
                                itemAdditionalData.localizedDescription
                                    ? itemAdditionalData.localizedDescription
                                    : itemAdditionalData.description
                            }
                            ref={(element: any) => {
                                localRef.current = element;
                            }}
                        />
                    );
                    return jsxElement;
                case AdditionalDataTypeEnum.link:
                    jsxElement = (
                        <TPAdditionalDataLink
                            idControl={idControl}
                            modeUI={mode}
                            url={jsonData.url}
                            description={
                                itemAdditionalData.localizedDescription
                                    ? itemAdditionalData.localizedDescription
                                    : itemAdditionalData.description
                            }
                            ref={(element: any) => {
                                localRef.current = element;
                            }}
                        />
                    );
                    return jsxElement;
                case AdditionalDataTypeEnum.phone:
                    jsxElement = (
                        <TPAdditionalDataPhone
                            isMandatory={itemAdditionalData.isMandatory}
                            idControl={idControl}
                            modeUI={mode}
                            labelText={
                                itemAdditionalData.localizedDescription
                                    ? itemAdditionalData.localizedDescription
                                    : itemAdditionalData.description
                            }
                            defaultValue={value}
                            ref={(element: any) => {
                                localRef.current = element;
                            }}
                            onValueChange={(newValue: Date, idControl: any) => {
                                if (mode === TPAddtionalDataUIModes.Collect) {
                                    handleChangeAditionalDataValue(idControl, newValue, item.key);
                                } else {
                                    TPGlobal.foo();
                                }
                            }}
                            whiteList={jsonData.whiteList}
                            phoneValType={jsonData.phoneValType}
                            minLength={jsonData.minLength}
                            maxLength={jsonData.maxLength}
                            phoneRegExpre={jsonData.phoneRegEXPRE}
                            complementsRenderTP={complementsRenderTP}
                        />
                    );
                    return jsxElement;
                case AdditionalDataTypeEnum.tree:
                    jsxElement = (
                        <TPAdditionalDataValueListBranch
                            isMandatory={itemAdditionalData.isMandatory}
                            idControl={idControl}
                            modeUI={mode}
                            labelText={
                                itemAdditionalData.localizedDescription
                                    ? itemAdditionalData.localizedDescription
                                    : itemAdditionalData.description
                            }
                            defaultValue={value}
                            ref={(element: any) => {
                                localRef.current = element;
                            }}
                            onValueChange={(newValue: any, idControl: any) => {
                                if (mode === TPAddtionalDataUIModes.Collect) {
                                    handleChangeAditionalDataValue(idControl, newValue, item.key);
                                } else {
                                    TPGlobal.foo();
                                }
                            }}
                            callbackIsLoading={() => {
                                TPGlobal.foo();
                            }}
                            renderMethod={
                                AdditionalDataValueListBranchRenderMethodEnum.Combobox
                            }
                            parentId={jsonData.parentId}
                            multipleSelection={false}
                            isParameterTree={isParameterTreeTemp}
                        />
                    );
                    return jsxElement;
                case AdditionalDataTypeEnum.listvalue:
                    jsxElement = (
                        <TPAdditionalDataValueListBranch
                            isMandatory={itemAdditionalData.isMandatory}
                            idControl={idControl}
                            modeUI={mode}
                            labelText={
                                itemAdditionalData.localizedDescription
                                    ? itemAdditionalData.localizedDescription
                                    : itemAdditionalData.description
                            }
                            defaultValue={value}
                            ref={(element: any) => {
                                localRef.current = element;
                            }}
                            onValueChange={(newValue: Date, idControl: any) => {
                                if (mode === TPAddtionalDataUIModes.Collect) {
                                    handleChangeAditionalDataValue(idControl, newValue, item.key);
                                } else {
                                    TPGlobal.foo();
                                }
                            }}
                            callbackIsLoading={() => {
                                TPGlobal.foo();
                            }}
                            renderMethod={
                                jsonData.renderMethod as AdditionalDataValueListBranchRenderMethodEnum
                            }
                            parentId={jsonData.parentId}
                            isParameterTree={isParameterTreeTemp}
                            complementsRenderTP={complementsRenderTP}
                            multipleSelection={jsonData.allowMultipleSelection}
                        />
                    );
                    return jsxElement;
                case AdditionalDataTypeEnum.yesno:
                    jsxElement = (
                        <TPAdditionalDataYesNo
                            isMandatory={itemAdditionalData.isMandatory}
                            idControl={idControl}
                            modeUI={mode}
                            labelText={
                                itemAdditionalData.localizedDescription
                                    ? itemAdditionalData.localizedDescription
                                    : itemAdditionalData.description
                            }
                            defaultValue={value}
                            ref={(element: any) => {
                                localRef.current = element;
                            }}
                            onValueChange={(newValue: Date, idControl: any) => {
                                if (mode === TPAddtionalDataUIModes.Collect) {
                                    handleChangeAditionalDataValue(idControl, newValue, item.key);
                                } else {
                                    TPGlobal.foo();
                                }
                            }}
                            renderMethod={
                                jsonData.renderMethod as AdditionalDataYesNoRenderMethodEnum
                            }
                            caption={jsonData.caption as AdditionalDataYesNoCaption}
                        />
                    );
                    return jsxElement;
                case AdditionalDataTypeEnum.numeric:
                    jsxElement = (
                        <TPAdditionalDataNumeric
                            isMandatory={itemAdditionalData.isMandatory}
                            idControl={idControl}
                            modeUI={mode}
                            labelText={
                                itemAdditionalData.localizedDescription
                                    ? itemAdditionalData.localizedDescription
                                    : itemAdditionalData.description
                            }
                            defaultValue={value}
                            ref={(element: any) => {
                                localRef.current = element;
                            }}
                            onValueChange={(newValue: Date, idControl: any) => {
                                if (mode === TPAddtionalDataUIModes.Collect) {
                                    handleChangeAditionalDataValue(idControl, newValue, item.key);
                                } else {
                                    TPGlobal.foo();
                                }
                            }}
                            minValue={jsonData.minValue}
                            maxValue={jsonData.maxValue}
                            currencySymbol={
                                jsonData.percentage ? "%" : jsonData.currencySymbol
                            }
                            currencyPlacement={
                                jsonData.percentage ? "After" : jsonData.currencyPlacement
                            }
                            fixedDecimal={jsonData.fixedDecimal}
                            decimalSeparator={jsonData.decimalSeparator}
                            thousandSeparator={jsonData.thousandsSeparator}
                            complementsRenderTP={complementsRenderTP}
                        />
                    );
                    return jsxElement;
                default:
                    jsxElement = (
                        <div className="mt-4">
                            <span className="tpred">
                                {badConfigurationErrorMessageLabel}:
                                {itemAdditionalData.additionalDataTypeId}
                            </span>
                        </div>
                    );
                    return jsxElement;
            }
        };

        //run only once
        useEffect(() => {
            loadResources();          
        }, []);

      
        return (
            <>
                { renderAdditionalDataItem(
                    guidControl,
                    itemToRender,
                    defaultValue,
                    modeUI                    
                )}
            </>
        );
    },
);

export default TPRenderAdditionalDataSwitch;
