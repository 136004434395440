import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import TPModal from "@/layouts/TPModal/TPModal";
import { FC, useEffect, useState } from "react";
import "./resources/TaskReminderStyles.css";
import { useTaskReminderLabels } from "./resources/TaskReminderLabels";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { RemindersService } from "@/services/MyRemindersService";
import { MyRemindersSlice, MyRemindersSliceModel } from "@/pages/MyReminders/MyRemindersSlice";
import { useDispatch } from "react-redux";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import allThemes from "@/assets/styles/theme";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPIntegerSelect } from "@/components/TPIntegerSelect/TPIntegerSelect";
import { TPConfirmationModal } from "@/components/TPModalAlert/ConfirmationModal/TPConfirmationModal";

interface TaskReminderModalProperties {
  taskId: number;
  caseId: number;
  onAction: Function;
}

const minHours = 0;
const maxHours = 23;
const minMinutes = 0;
const maxMinutes = 59;

const hourOptions = Array.from({ length: maxHours - minHours + 1 }, (_, i) => {
  return { key: String(i), value: i < 10 ? String("0").concat(String(i)) : String(i) }
});
const minutesOptions = Array.from({ length: maxMinutes - minMinutes + 1 }, (_, i) => {
  return { key: String(i), value: i < 10 ? String("0").concat(String(i)) : String(i) }
})

export const TaskReminderModal: FC<TaskReminderModalProperties> = function ({ taskId, caseId, onAction }) {

  const { labels } = useTaskReminderLabels();
  const [date, setDate] = useState<Date>(new Date());
  const [hours, setHours] = useState("0");
  const [minutes, setMinutes] = useState("0");
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(false);
  const [reminderId, setReminderId] = useState<number | null>();
  const dispatch = useDispatch();
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [renderKey, setRenderKey] = useState(false);

  const getReminder = function () {
    if (taskId && caseId) {
      setLoading(true);
      RemindersService.getReminderByTaskId(taskId, false, false, [200, 404])
        .then(reminder => {
          if (reminder) {
            const retrievedDate = new Date(reminder.date);
            retrievedDate.setUTCDate(retrievedDate.getDate());
            retrievedDate.setUTCHours(retrievedDate.getHours());
            retrievedDate.setUTCMinutes(retrievedDate.getMinutes());
            setReminderId(reminder.id);
            setDate(retrievedDate);
            setHours(retrievedDate.getHours().toString());
            setMinutes(retrievedDate.getMinutes().toString());
            setComments(reminder.comments);
          }
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    }
  }

  const handleSave = function () {
    if (isDateInvalid()) {
      setRenderKey(!renderKey);
      return;
    }
    reminderId ? updateReminder() : saveReminder();
  }

  const saveReminder = function () {
    setLoading(true);
    const fixedDate = date;
    fixedDate.setHours(Number(hours));
    fixedDate.setMinutes(Number(minutes));
    fixedDate.setSeconds(0);
    RemindersService.createReminder({
      comments: comments,
      date: fixedDate,
      taskId: taskId,
      type: "M"
    }, true, true, [200])
      .then(() => {
        onAction();
        dispatch(MyRemindersSlice.actions.setContentLoaded(false));
      })
      .catch(e => console.error(e))
      .finally(() => setLoading(false));
  }

  const updateReminder = function () {
    if (!reminderId) return;
    setLoading(true);
    const fixedDate = date;
    fixedDate.setHours(Number(hours));
    fixedDate.setMinutes(Number(minutes));
    fixedDate.setSeconds(0);
    RemindersService.updateReminder({
      comments: comments,
      date: fixedDate,
      id: reminderId,
      type: "M",
      status: "P"
    }, true, true, [200])
      .then(() => {
        onAction();
        dispatch(MyRemindersSlice.actions.setContentLoaded(false));
      })
      .catch(e => console.error(e))
      .finally(() => setLoading(false));
  }

  const deleteReminder = async function() {
    if (!reminderId) return;
    setLoading(true);
    setDeleteConfirmVisible(false);
    RemindersService.deleteReminder(reminderId, true, true, [200])
      .then(() => onAction(false))
      .catch(err => console.error(err))
      .finally(() => dispatch(MyRemindersSlice.actions.setContentLoaded(false)))
  }

  const isDateInvalid = function() {
    const fullDate = date;
    fullDate.setHours(Number(hours));
    fullDate.setMinutes(Number(minutes));
    return fullDate < new Date();
  }

  useEffect(() => {
    getReminder();
  }, [])

  return (
    <>
      <TPConfirmationModal 
        message={labels.DeleteConfirm}
        onAccept={() => deleteReminder()}
        onCancel={() => setDeleteConfirmVisible(false)}
        hideTitle
        visible={deleteConfirmVisible}
      />
      <TPModal
        modalState={{
          acceptLabel: labels.Save,
          cancelLabel: labels.Cancel,
          callBackAnswer: (saved: boolean) => saved ? handleSave() : onAction(saved),
            isShown: true,
          titleModal: reminderId ? labels.Update : labels.Create,
          hideXButton: true,
          zIndex: 3,
          enableAcceptButton: Boolean(date) && comments.trim().length > 0,
          hiddenHeader: true,
          hideFooterButtons: true
        }}
      >
        <TPLoadingOverlay active={loading}>
          <div className="reminder-modal-content">
            <TPPageTitle style={{margin: 0}}>{reminderId ? labels.EditReminder : labels.AddNewReminder}</TPPageTitle>
            <div className="reminder-form-row">
              <div>
                <TPDatePicker
                  isMandatory
                  labelText={labels.Date}
                  minDate={new Date()}
                  onChangeDate={(d: Date) => setDate(d)}
                  selectedDate={date}
                  errorMessage={isDateInvalid() ? labels.CantChoosePastDate : ""}
                />
              </div>
              <div>
                <label style={{ fontSize: "13px" }}>{labels.Time}<b style={{ color: 'red' }}>*</b></label>
                <div className="reminder-form-row" style={{ gap: "8px" }}>
                  <TPIntegerSelect 
                    value={Number(hours) || 0}
                    onChange={value => setHours(String(value))}
                    maxValue={maxHours}
                    minValue={minHours}
                    inputWidth="2em"
                    errorMessage={isDateInvalid() ? "^" : ""}
                  />
                  <label>:</label>
                  <TPIntegerSelect 
                    value={Number(minutes) || 0}
                    onChange={value => setMinutes(String(value))}
                    maxValue={maxMinutes}
                    minValue={minMinutes}
                    inputWidth="2em"
                    errorMessage={isDateInvalid() ? "^" : ""}
                  />
                </div>

              </div>
            </div>
            <TPTextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComments(e.target.value)}
              rows={3}
              value={comments}
              isMandatory
              textAreaStyle={{ minHeight: "64px", maxHeight: "128px" }}
              labelText={labels.Comments}
            />
            <div className="task-reminder-modal-footer">
              {reminderId && (
                <button 
                  type="button" 
                  className="task-reminder-delete-button"
                  onClick={() => setDeleteConfirmVisible(true)}
                >
                  <TPIcon iconType={TPIconTypes.trash} style={{fontSize:"24px"}} />
                  <u>{labels.DeleteReminder}</u>
                </button>)}
              <div className="task-reminder-button-pair">
                <TPButton
                  isDesignSystem
                  style={{
                    backgroundColor: "white",
                    color: allThemes.base.purplePrimary,
                    padding: "11px 16px"
                  }}
                  onClick={() => onAction(false)}
                >
                  {labels.Cancel}
                </TPButton>
                <TPButton
                  isDesignSystem
                  style={{
                    padding: "11px 16px"
                  }}
                  onClick={() => handleSave()}
                  disabled={!Boolean(date) || comments.trim().length === 0 ||
                    Number.isNaN(Number(hours)) || Number.isNaN(Number(minutes)) || 
                    (Number(hours) > maxHours) || (Number(hours) < minHours) ||
                    isDateInvalid()
                  }
                >
                  {reminderId ? labels.Save : labels.Create}
                </TPButton>
              </div>
            </div>
          </div>
        </TPLoadingOverlay>
      </TPModal>
    </>
  )
}