import allThemes from "@/assets/styles/theme";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";
import { MultilingualTextBoxEvents } from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPPageAcceptCancelButtonsContainer, TPPageSection } from "@/components/TPPage/tpPageStyles";
import { TPRadio } from "@/components/TPRadio/TPRadio";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { SequenceGeneratorSequencesNameEnum, SystemParametersEnum, TPActiveOptions, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { StructureAdminInputDTO } from "@/models/StructureAdmin/StructureAdminInputDTO";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { ParametersService } from "@/services/ParametersService";
import { SequenceService } from "@/services/SequenceService";
import { StructureService } from "@/services/StructureService";
import { FC, useEffect, useRef, useState } from "react";

type InsertUpdateProps = {
    mode: string;
    recordId: string;
    verticalTabCallback: Function;
    updateCallback: Function;
    tabid?: string;
};

enum whenCustomerExistsEnum {
    ignoreData = "IGNOREDATA",
    addNewData = "ADDNEWDATAANDUPDATEEXISTS",
}

type InsertUpdateStateType = {
    id: string,
    recordLanguageList: Array<TPKeyValue>;
}

const StructureAdminUpdateInsert: FC<InsertUpdateProps> = ({
    mode,
    recordId,
    verticalTabCallback,
    updateCallback,
    tabid
}) => {
    const componentFileName: string = "StructureAdminUpdateInsert.tsx";

    const resourceSet: string = "StructureAdminClone";

    const [isLoading, setIsLoading] = useState(false);

    const [realMode, setRealMode] = useState(mode);
    const [realRecordId, setRealRecordId] = useState(recordId);

    const [structureIdLabel, setStructureIdLabel] = useState("");
    const [structureNameLabel, setStructureNameLabel] = useState("");

    const [structureNameValue, setStuctureNameValue] = useState("");
    const [typeValue, setTypeValue] = useState("--");
    const [referenceId, setReferenceId] = useState("");

    const [titleLabel, setTitleLabel] = useState("");
    const [toastInfo, setToastInfo] = useState("");
    const [structureTypeLabel, setStructureTypeLabel] = useState("");
    const [ignoreDataLabel, setIgnoreDataLabel] = useState("");
    const [addNewDataLabel, setAddNewDataLabel] = useState("");
    const [whenCustomerExistsLabel, setWhenCustomerExistsLabel] = useState("");
    const [showAsActiveLabel, setShowAsActiveLabel] = useState("");
    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [languageListLabel, setLanguageListLabel] = useState("");

    const [structureNameErrorMessage, setStructureNameErrorMessage] = useState("");
    const [typeErrorMessage, setTypeErrorMessage] = useState("");

    const [showAsActive, setShowAsActive] = useState(true);
    const nameInputRef = useRef<MultilingualTextBoxEvents>();

    const [customerExistPropsState, setCustomerExistPropsState] = useState<whenCustomerExistsEnum>(whenCustomerExistsEnum.ignoreData);

    const loadResourcesAndLoadStructureInfo = async () => {

        setStructureIdLabel(await TPI18N.GetText(resourceSet, "StructureIdLabel"));
        setStructureNameLabel(await TPI18N.GetText(resourceSet, "StructureNameLabel"));
        setTitleLabel(await TPI18N.GetText(resourceSet, "InsertTitleLabel"));
        setToastInfo(await TPI18N.GetText(resourceSet, "ToastInfo"));
        setStructureTypeLabel(await TPI18N.GetText(resourceSet, "StructureTypeLabel"));
        setIgnoreDataLabel(await TPI18N.GetText(resourceSet, "IgnoreDataLabel"));
        setAddNewDataLabel(await TPI18N.GetText(resourceSet, "AddNewDataLabel"));
        setWhenCustomerExistsLabel(await TPI18N.GetText(resourceSet, "WhenCustomerExistsLabel"));
        setShowAsActiveLabel(await TPI18N.GetText(resourceSet, "ShowAsActiveLabel"));
        setSaveButtonLabel(await TPI18N.GetText(resourceSet, "SaveButtonLabel"));
        setCancelButtonLabel(await TPI18N.GetText(resourceSet, "CancelButtonLabel"));
        setLanguageListLabel(await TPI18N.GetText(resourceSet, "LanguageListLabel"));

        if (realMode === "Insert") {
            let newInsertUpdateState = { ...insertUpdateState };
            newInsertUpdateState.recordLanguageList = [];
            for (let i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }

            setInsertUpdateState(newInsertUpdateState);
        } else if (realMode === "Update") {

            setTitleLabel(await TPI18N.GetText(resourceSet, "UpdateTitleLabel"));
            let newInsertUpdateState = { ...insertUpdateState };
            newInsertUpdateState.recordLanguageList = [];
            for (let i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }

            let info = await getStructureInfo();

            newInsertUpdateState.id = info.id
            newInsertUpdateState.recordLanguageList[0].value = info.localizedDescription;
            setInsertUpdateState(newInsertUpdateState);

            setTypeValue(info.typeIdParam);
            setShowAsActive(info?.isActive);

           
            let languageDescriptions = await getLanguageDescriptions(info.id);

            if (languageDescriptions) {
                for (let i = 1; i <= languageDescriptions.length - 1; i++) {
                    newInsertUpdateState.recordLanguageList[i].value = languageDescriptions[i].recordDescription;
                }
            }

            setCustomerExistPropsState(info.actionWhenClientExists)

            setIsLoading(false);

        }

        getStructureTypeList();
    }

    const [structureTypes, setStructureTypes] = useState<Array<any>>([])

    const getStructureTypeList = async () => {
        let parametersService = new ParametersService();
        let expectedCodes: Array<number> = [200];

        try {
            let responseRequest = await parametersService.getByParentIdAndFilterIsActive(
                SystemParametersEnum.STRUCTURETYPEPARENT,
                TPActiveOptions.ACTIVE.toString(),
                false,
                true,
                expectedCodes
            );
            if (responseRequest) {
                let structureTypeList = responseRequest.map(function (item) {
                    return { key: item.id, value: item.localizedDescription };
                })
                structureTypeList.unshift({ key: "", value: "--" });
                setStructureTypes(structureTypeList);
            } else {
                return null;
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getStructureTypeList ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getStructureTypeList ex`);
        }
    }

    let customerExistsList: Array<TPKeyValue> = [
        {
            key: whenCustomerExistsEnum.ignoreData,
            value: ignoreDataLabel
        },
        {
            key: whenCustomerExistsEnum.addNewData,
            value: addNewDataLabel
        }
    ]

    const getLanguageDescriptions = async (id: string) => {
        let serviceMultilanguageFieldValues = new MultilanguageFieldValueService();
        let expectedCodes: Array<number> = [200];

        try {
            let responseRequest = await serviceMultilanguageFieldValues.getTPClientUIByTableAndRecordIdAndFieldName(
                "EVentLoadStructure",
                "Description_EVLS",
                id,
                false,
                true,
                expectedCodes
            )

            return responseRequest;

        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getLanguageDescriptions ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getLanguageDescriptions ex`);
        }
    }

    const getStructureInfo = async () => {
        let serviceClient = new StructureService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoading(true);
            let responseRequest = await serviceClient.getStructureById(
                realRecordId,
                false,
                true,
                expectedCodes
            )

            return responseRequest[0];
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getStructureInfo ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getStructureInfo ex`);
        }
    }

    const generateAutomaticId = async () => {
        let serviceClient = new SequenceService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoading(true);

            let responseRequest = await serviceClient.generalAutomaticId(
                false,
                true,
                expectedCodes,
                SequenceGeneratorSequencesNameEnum.SQEVLS
            )

            setIsLoading(false);
            if (responseRequest.responseResult) {
                let result = responseRequest?.responseData?.data[0]?.sequenceCode;
                setReferenceId(result);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} generateAutomaticId ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} generateAutomaticId ex`);
        }
    }

    const insertStructure = async (inputDTO: any) => {
        let serviceClient = new StructureService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoading(true);

            let responseRequest = await serviceClient.insertStructure(inputDTO, true, true, expectedCodes);

            verticalTabCallback({
                command: 'delete',
                recordId: '--',
                languageId: TPGlobal.language,
                recordDescription: inputDTO.id
            })
            verticalTabCallback({
                command: 'update',
                recordId: inputDTO.id,
                languageId: TPGlobal.language,
                recordDescription: inputDTO.id
            });
            updateCallback({
                result: 'ReloadGrid',
            });

        } catch (error) {
            TPLog.Log(`Error ${componentFileName} insertEmailTemplate ex`, TPLogType.ERROR, error);
            console.error(`Error ${componentFileName} insertEmailTemplate ex`);
            setIsLoading(false);
        }
    }

    const updateStructure = async (inputDTO: any) => {
        let serviceClient = new StructureService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoading(true);

            let responseRequest = await serviceClient.updateStructure(inputDTO, true, true, expectedCodes);

            setIsLoading(false);

            updateCallback({
                result: 'save_general_update',
                recordId: inputDTO.id
            });
            return responseRequest;
        } catch (error) {
            TPLog.Log(`Error ${componentFileName} update ex`, TPLogType.ERROR, error);
            console.error(`Error ${componentFileName} update ex`);
            setIsLoading(false);
        }
    }

    const handleOnOkButtonClick = async () => {
        if (nameInputRef.current?.isInvalid()) {
            nameInputRef.current?.markAsTouched();
            return;
        }

        const languages = nameInputRef.current?.getValue();
        const mainLanguage = languages?.find(({id}) => id === TPGlobal.TPClientDefaultLanguage);
        let i: number;
        let n: number;
        let atLeastOneError = false;

        let RecordInputDTO: StructureAdminInputDTO = {
            id: realMode === 'Insert' ? referenceId : insertUpdateState.id,
            description: mainLanguage?.value!,
            typeIdParam: typeValue,
            actionWhenClientExists: customerExistPropsState,
            typistIdUser: TPGlobal.currentUserGuid,
            isActive: showAsActive,
            descriptionLocalizedValues: [
                {
                  languageId: mainLanguage?.id!,
                  localizedValue: mainLanguage?.value ?? "",
                  order: 1
                },
                ...languages!
                  .filter(({id}) => id !== TPGlobal.TPClientDefaultLanguage)
                  .map(({id, value}, order) => ({languageId: id!, localizedValue: value ?? "", order: order + 2}))
              ]
        }
        
        if (typeValue === "--") {
            atLeastOneError = true;
            setTypeErrorMessage(await TPI18N.GetText(resourceSet, "StructureTypeErrorMessage"));
        }


        if (!atLeastOneError) {
            if (realMode === "Insert" || realMode === "CloneEmail") {
                await insertStructure(RecordInputDTO);
            } else {
                await updateStructure(RecordInputDTO);
            }
        }

    }

    
    let insertUpdateInitialState: InsertUpdateStateType = {
        id: "",
        recordLanguageList: []
    }

    const [insertUpdateState, setInsertUpdateState] = useState(
        insertUpdateInitialState
    );

    const handleStructureTypeChange = (event: any) => {
        setTypeValue(event.target.value);
    }

    const handleSetAsActive = (event: any) => {
        setShowAsActive(!showAsActive);
    }

    const {
        isOpen: isOpenModalLanguageList,
        openModal: handleOpenModalLanguageList,
        closeModal: handleCloseModalLanguageList,
        saveChanges: handleSaveChangesModalLanguageList,
    } = useModal(false);

    const handleLanguageChange = (index: number, newName: string) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.recordLanguageList[index].value = newName;
        setInsertUpdateState(newInsertUpdateState);
        setStructureNameErrorMessage("");
    };

    const handleCancelButtonClick = () => {
        updateCallback({ result: 'cancel_general', recordId: realRecordId });
    };

    useEffect(() => {
        loadResourcesAndLoadStructureInfo();
        generateAutomaticId();
    }, [])


    return (
        <TPLoadingOverlay active={isLoading}>
            <div className="row" style={{ marginBottom: "10px" }}>
                <div className="col-10">
                    <TPPageSection>
                        <div className="row" style={{ marginBottom: "10px" }}>
                            <div className="col-8">
                                <div className="form-group">
                                    <TPTextBox
                                        id="IdTextBox"
                                        isMandatory={true}
                                        disabled
                                        labelText={structureIdLabel}
                                        value={realMode === "Insert" ? referenceId : realRecordId}
                                        onChange={(e: any) => setReferenceId(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <MultilingualTextBox
                            ref={nameInputRef}
                            style={{ width: "calc(50% + 35px)" }}
                            value={insertUpdateState.recordLanguageList?.map(({key, value}) => ({id: key, value}))}
                        />
                        
                        <div className="row">
                            <div className="col-8">
                                <div className="form-group">
                                    <TPSelect
                                        isMandatory={true}
                                        dataSource={structureTypes.map((item) => ({ key: item.key, value: item.value } as TPKeyValue))}
                                        value={typeValue}
                                        labelText={structureTypeLabel}
                                        onChange={handleStructureTypeChange}
                                        errorMessage={typeErrorMessage}
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                        <TPRadioGroup
                                            id="id-RadioGroup-structure"
                                            value={customerExistPropsState}
                                            source={customerExistsList}
                                            labelText={whenCustomerExistsLabel}
                                            onChange={(e: any) => {setCustomerExistPropsState(e.target.value)}}
                                        />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-8">
                                <div className="form-group">
                                    <TPCheckBox
                                        id="IdCheckBox"
                                        labelText={showAsActiveLabel}
                                        checked={showAsActive}
                                        onChange={handleSetAsActive}
                                    />
                                </div>
                            </div>
                        </div>
                    </TPPageSection>
                </div>
            </div>
            <div className="col-7" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: "20px"}}>
                    <TPButton
                        id="IdButton"
                        type={TPButtonTypes.link}
                        onClick={() => handleCancelButtonClick()}
                        isDesignSystem
                        style={{
                          paddingLeft: '16px', paddingRight: '16px',
                          backgroundColor: 'white', color: allThemes.base.primary
                        }}
                    >
                        {cancelButtonLabel}
                    </TPButton>
                    <TPButton
                        id="IdButton"
                        type={TPButtonTypes.primary}
                        onClick={() => handleOnOkButtonClick()}
                        isDesignSystem
                        style={{
                            paddingLeft: '16px', paddingRight: '16px'
                        }}
                    >
                        {saveButtonLabel}
                    </TPButton>
                </div>
        </TPLoadingOverlay>
    );
}

export default StructureAdminUpdateInsert;

